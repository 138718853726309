import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, startWith, map, takeUntil, Subject } from 'rxjs';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { IBatch } from 'src/app/models/batches/batch';
import { Tag } from 'src/app/models/tag';
import { BatchesService } from 'src/app/services/batches.services';
import { take } from 'lodash';
import { TagsService } from 'src/app/services/tags.service';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
})
export class TagsComponent {
  separatorKeysCodes: number[] = [ENTER, COMMA];
  fruitCtrl = new FormControl('');
  filteredTags: Observable<string[]>;
  allTags: string[] = [];

  private destroy$ = new Subject();
  @ViewChild('fruitInput') fruitInput!: ElementRef<HTMLInputElement>;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      entity: any;
      entityType: string;
      tags: Tag[];
    },
    public dialogRef: MatDialogRef<TagsComponent>,
    private batchesService: BatchesService,
    private tagsService: TagsService
  ) {
    this.allTags = this.data.tags.map(({ tag }) => tag);
    this.filteredTags = this.fruitCtrl.valueChanges.pipe(
      startWith(null),
      map((tag: string | null) =>
        tag ? this._filter(tag) : this.allTags.slice()
      )
    );
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    // Add our fruit
    const index = this.data.entity.tags.indexOf(value);
    console.log(index);
    if (value && index == -1) {
      this.tagsService
        .tag(this.data.entityType, this.data.entity.id!, value)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (result) => {
            if (result) {
              this.data.entity.tags = result;
            }
          },
        });
    }

    // Clear the input value
    event.chipInput!.clear();

    this.fruitCtrl.setValue(null);
  }

  remove(tag: string): void {
    const index = this.data.entity.tags.indexOf(tag);

    if (index >= 0) {
      this.tagsService
        .removeTag(this.data.entityType, this.data.entity.id!, tag)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (result) => {
            if (result) {
              this.data.entity.tags = result;
            }
          },
        });
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const index = this.data.entity.tags.indexOf(event.option.viewValue);
    if (index == -1) {
      this.tagsService
        .tag(this.data.entityType, this.data.entity.id!, event.option.viewValue)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (result) => {
            if (result) {
              this.data.entity.tags = result;
            }
          },
        });
    }
    this.fruitInput.nativeElement.value = '';
    this.fruitCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allTags.filter((tag) =>
      tag.toLowerCase().includes(filterValue)
    );
  }
}
