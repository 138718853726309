import { Component, ViewChild } from '@angular/core';
// Material Modules
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
// Services
import { BatchesService } from 'src/app/services/batches.services';
import { OrganizationsService } from 'src/app/services/organizations.service';
// Models
import { BatchNew } from 'src/app/models/batches/batchNew';
import { ValidationErrors } from 'src/app/models/validationErrors';
import { FormControl, Validators } from '@angular/forms';
import { IOrganization } from 'src/app/models/organizations/organization';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-batch-create',
  templateUrl: './batch-create.component.html',
  styleUrls: ['./batch-create.component.scss'],
})
export class BatchCreateComponent {
  private destroy$ = new Subject();
  errorState: Boolean = false;
  newBatch: BatchNew = new BatchNew();
  errorMessages: ValidationErrors[] = [];
  validatorProperties: string[] = [];
  nameFormControl: FormControl;
  jobNumberFormControl: FormControl;
  organizationFormControl: FormControl;

  organizations: IOrganization[] = [];
  organizationsSubscription: any;

  constructor(
    private BatchesService: BatchesService,
    private organizationsService: OrganizationsService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<BatchCreateComponent>,
    private _alert: MatSnackBar
  ) {
    this.nameFormControl = new FormControl('', [Validators.required]);
    this.jobNumberFormControl = new FormControl('', [Validators.required]);
    this.organizationFormControl = new FormControl('', [Validators.required]);
  }

  ngOnInit(): void {
    this.organizationsSubscription = this.organizationsService
      .listOrganizations()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (organizations) => {
          if (organizations) {
            this.organizations = organizations;
            console.log('Organizations Found: ', organizations);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  createBatch() {
    this.BatchesService.createBatch(this.newBatch)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          this._alert.open('Successfully Created Batch', 'close', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 2000,
            panelClass: ['success'],
          });
          this.dialogRef.close();
        },
        (error) => {
          this.errorState = true;
          if (error.status === 422) {
            this.errorMessages = [];
            this.validatorProperties = [];
            error.error.validationErrors.forEach((error: ValidationErrors) => {
              this.errorMessages.push(error);
              this.validatorProperties.push(error.property);
              this.nameFormControl.markAllAsTouched();
              if (error.property == 'Name') {
                this.nameFormControl.setErrors({
                  serverErrors: 'validation errors',
                });
              }
              this.jobNumberFormControl.markAllAsTouched();
              if (error.property == 'JobNumber') {
                this.jobNumberFormControl.setErrors({
                  serverErrors: 'validation errors',
                });
              }
              this.organizationFormControl.markAllAsTouched();
              if (error.property == 'OrganizationId') {
                this.organizationFormControl.setErrors({
                  serverErrors: 'validation errors',
                });
              }
            });
          }
        }
      );
  }

  validationMessage(property: string) {
    return this.errorMessages.find((error) => error.property == property)
      ?.message;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
