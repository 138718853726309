import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MasterSuiteSettingsVaraintDialogComponent } from 'src/app/components/shared/master-suite-settings-varaint-dialog/master-suite-settings-varaint-dialog.component';
import { WarningModalComponent } from 'src/app/components/shared/warning-modal/warning-modal.component';
import { IPreference } from 'src/app/models/users/userProfile';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-product-detail-item',
  templateUrl: './product-detail-item.component.html',
  styleUrls: ['./product-detail-item.component.scss'],
})
export class ProductDetailItemComponent implements OnInit {
  @Input() product!: any;
  @Input() config!: any;

  currentConfigDate!: IPreference;

  constructor(
    private authService: AuthService,
    public dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.authService.currentPreference.subscribe((res) => {
      this.currentConfigDate = {
        ...res,
        asAgo: false,
      };
    });
  }

  changeSettingsVariant() {
    const dialogRef = this.dialog.open(
      MasterSuiteSettingsVaraintDialogComponent,
      {
        data: {
          masterSuiteId: this.product.masterSuiteId,
          masterSuiteName: this.product.masterSuiteName,
          productIds: [this.product.id],
          settingsVariantId: this.product.settingsVariantId,
        },
      }
    );
    dialogRef.afterClosed().subscribe((res) => {
      console.log(res);
      if (res?.settingsVariants?.length === 0) {
        const dialogRef0 = this.dialog.open(WarningModalComponent, {
          data: {
            header: 'Warning',
            content:
              'Master suite has no settings variants, would you like to create one?',
            titleButtonOk: 'Yes',
            showBtnNo: true,
          },
        });
        dialogRef0.afterClosed().subscribe((res) => {
          if (res) {
            this.router.navigate(
              [`mastersuites/${this.product.masterSuiteId}/update`],
              {
                queryParams: {
                  applyAll: true,
                },
              }
            );
          }
        });
      } else if (res.settingsVariantSelected) {
        this.product.settingsVariantName = res.settingsVariantSelected.name;
        this.product.settingsVariantId = res.settingsVariantSelected.id;
      }
    });
  }

  viewMasterSuite() {
    var url = `mastersuites/${this.product.masterSuiteId}/update`;
    window.open(url, '_blank');
  }
}
