<div class="content">
  <div class="content-header">
    <div class="title">{{ product.barcode }} Receivals</div>
  </div>
  <div style="overflow-x: auto">
    <table
      *ngIf="product.productReceivals.length > 0"
      mat-table
      [dataSource]="productsReceivedDataSource"
      class="table"
    >
      <!-- Container Code Column -->
      <ng-container matColumnDef="containerId">
        <th mat-header-cell *matHeaderCellDef>Container Code</th>
        <td mat-cell *matCellDef="let productReceival">
          {{ productReceival.containerId }}
        </td>
      </ng-container>

      <!-- Condition Column -->
      <ng-container matColumnDef="condition">
        <th mat-header-cell *matHeaderCellDef>Condition</th>
        <td mat-cell *matCellDef="let product">
          <mat-select style="font-size: 14px" [(ngModel)]="product.condition">
            <mat-option value="Good">
              <mat-icon matTooltip="View" color="success"
                >receipt_long</mat-icon
              >
              <span>Good</span>
            </mat-option>
            <mat-option value="Okay">
              <mat-icon matTooltip="View" color="warning"
                >receipt_long</mat-icon
              >
              <span>Okay</span>
            </mat-option>
            <mat-option value="Poor">
              <mat-icon matTooltip="View" color="error">receipt_long</mat-icon>
              <span>Poor</span>
            </mat-option>
          </mat-select>
        </td>
      </ng-container>

      <!-- Quantity Received Column -->
      <ng-container matColumnDef="quantity">
        <th mat-header-cell *matHeaderCellDef>Quantity Received</th>
        <td mat-cell *matCellDef="let productReceival">
          <mat-form-field
            style="font-size: 14px; margin-right: 0px !important"
            appearance="outline"
            subscriptSizing="dynamic"
          >
            <input
              type="number"
              matInput
              [(ngModel)]="productReceival.quantityReceived"
            />
          </mat-form-field>
        </td>
      </ng-container>

      <!-- Receival Time Column -->
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>Receival Time</th>
        <td mat-cell *matCellDef="let productReceival">
          <div
            matTooltip="{{
              productReceival.createdAt
                | dateAgo : currentConfigDate : true
                | async
            }}"
            matTooltipPosition="before"
          >
            {{
              productReceival.createdAt
                | dateAgo : currentConfigDate : false
                | async
            }}
          </div>
        </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let productReceival">
          <button
            mat-raised-button
            color="buttonSuccess"
            class="action-button"
            (click)="updateProductReceival(productReceival)"
          >
            Update
          </button>
        </td>
      </ng-container>

      <!-- Delete Column -->
      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let productReceival">
          <button
            mat-icon-button
            matTooltip="Delete"
            color="error"
            class="mat-elevation-z0"
            (click)="deleteProductReceival(productReceival)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="productReceivalsColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: productReceivalsColumns"></tr>
    </table>
  </div>
  <div *ngIf="product.productReceivals.length == 0">
    Product Has Not Been Received
  </div>
</div>
