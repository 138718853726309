<div class="content dashboard">
  <h3 class="title-chart">Jobs Overview</h3>
  <div class="chart">
    <app-bar-chart></app-bar-chart>
  </div>
  <div *ngIf="isStaff">
    <ng-container *ngIf="recentlyShotList.length !== 0">
      <h3 class="title">Recently Shot</h3>
      <div class="product-list">
        <mat-grid-list
          [cols]="colPerScreen"
          [rowHeight]="'1:1'"
          gutterSize="10"
          style="flex: 1"
        >
          <ng-container
            *ngFor="
              let e of [].constructor(
                recentlyShotList.length < colPerScreen
                  ? recentlyShotList.length
                  : colPerScreen
              );
              let i = index
            "
          >
            <mat-grid-tile [colspan]="1">
              <div class="product-item" #pdItem>
                <app-dashboard-card
                  [data]="recentlyShotList[i]"
                ></app-dashboard-card>
              </div> </mat-grid-tile></ng-container
        ></mat-grid-list>
        <button
          mat-icon-button
          matTooltip="See More"
          (click)="viewMore('RecentlyShot')"
        >
          <mat-icon>more_horiz</mat-icon>
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="recentlyEnhancedList.length !== 0">
      <h3 class="title">Recently Enhanced</h3>
      <div class="product-list">
        <mat-grid-list
          [cols]="colPerScreen"
          [rowHeight]="'1:1'"
          gutterSize="10"
          style="flex: 1"
        >
          <ng-container
            *ngFor="
              let e of [].constructor(
                recentlyEnhancedList.length < colPerScreen
                  ? recentlyEnhancedList.length
                  : colPerScreen
              );
              let i = index
            "
          >
            <mat-grid-tile [colspan]="1">
              <div class="product-item" #pdItem>
                <app-dashboard-card
                  [data]="recentlyEnhancedList[i]"
                ></app-dashboard-card>
              </div> </mat-grid-tile></ng-container
        ></mat-grid-list>
        <button
          mat-icon-button
          matTooltip="See More"
          (click)="viewMore('RecentlyEnhanced')"
        >
          <mat-icon>more_horiz</mat-icon>
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="recentlyReviewedList.length !== 0">
      <h3 class="title">Recently Reviewed</h3>
      <div class="product-list">
        <mat-grid-list
          [cols]="colPerScreen"
          [rowHeight]="'1:1'"
          gutterSize="10"
          style="flex: 1"
        >
          <ng-container
            *ngFor="
              let e of [].constructor(
                recentlyReviewedList.length < colPerScreen
                  ? recentlyReviewedList.length
                  : colPerScreen
              );
              let i = index
            "
          >
            <mat-grid-tile [colspan]="1">
              <div class="product-item" #pdItem>
                <app-dashboard-card
                  [data]="recentlyReviewedList[i]"
                ></app-dashboard-card>
              </div> </mat-grid-tile></ng-container
        ></mat-grid-list>
        <button
          mat-icon-button
          matTooltip="See More"
          (click)="viewMore('RecentlyApprovedExternalEnhancement')"
        >
          <mat-icon>more_horiz</mat-icon>
        </button>
      </div>
    </ng-container>
  </div>
  <div *ngIf="!isStaff">
    <h3 class="title">Latest Output Collections</h3>
    <div
      class="outputs-collection"
      *ngFor="
        let e of [].constructor(
          latestCollcetions.length < 2 ? latestCollcetions.length : 2
        );
        let i = index
      "
    >
      <div class="header">
        <h4 style="padding: 0 6px; padding-top: 6px">
          {{ latestCollcetions[i].jobNumber }} ({{
            latestCollcetions[i].jobName
          }}) -
          <i
            >Collection {{ latestCollcetions[i].index + 1 }} - Product Count:
            {{ latestCollcetions[i].productIds.length }}, Asset Count:
            {{ latestCollcetions[i].outputAssetsCount }}</i
          >
        </h4>
        <span class="spacer"></span>
        <div
          class="status-text"
          matTooltipPosition="above"
          [ngClass]="{
                  complete: latestCollcetions[i].downloadArchiveUrl != null,
                  info: latestCollcetions[i].downloadArchiveUrl == null,
                }"
        >
          {{
            latestCollcetions[i].downloadArchiveUrl == null
              ? "Zipping Collection"
              : "Ready To Download"
          }}
        </div>
        <div style="padding: 8px">
          <button
            mat-icon-button
            matTooltip="Download Collection"
            [disabled]="latestCollcetions[i].downloadArchiveUrl == null"
            (click)="
              downloadCollection(
                latestCollcetions[i].downloadArchiveUrl,
                latestCollcetions[i].id!
              )
            "
          >
            <mat-icon>download</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="Products List"
            (click)="manageCollection(latestCollcetions[i].id!)"
          >
            <mat-icon color="info">view_list</mat-icon>
          </button>
        </div>
      </div>

      <div class="product-list">
        <mat-grid-list
          [cols]="colPerScreen"
          [rowHeight]="'1:1'"
          gutterSize="10"
          style="flex: 1"
        >
          <ng-container
            *ngFor="
              let e of [].constructor(
                latestCollcetions[i].products!.length < colPerScreen
                  ? latestCollcetions[i].products!.length
                  : colPerScreen
              );
              let j = index
            "
          >
            <mat-grid-tile [colspan]="1">
              <div class="product-item" #pdItem>
                <app-dashboard-card
                  [latestAction]="latestCollcetions[i].createdAt"
                  [data]="latestCollcetions[i]!.products![j]!"
                ></app-dashboard-card>
              </div> </mat-grid-tile></ng-container
        ></mat-grid-list>
      </div>
    </div>
  </div>
</div>
