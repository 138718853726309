import { LiveAnnouncer } from '@angular/cdk/a11y';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, ViewChild, inject, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { TokenFormat } from 'src/app/models/organizations/form-setting';
import { filenameToken } from '../../home/organizations/organization-update/organization.data';
@Component({
  selector: 'app-chip-autocomplete',
  templateUrl: './chip-autocomplete.component.html',
  styleUrls: ['./chip-autocomplete.component.scss'],
})
export class ChipAutocompleteComponent {
  separatorKeysCodes: number[] = [ENTER, COMMA];
  chipCtrl = new FormControl('', [Validators.pattern('^[A-Za-z0-9._.-]*$')]);
  currentChip?: TokenFormat;
  filenameToken = filenameToken;
  @Input() allChips: TokenFormat[] = [];
  @Input() formCtrl!: FormControl;

  @ViewChild('chipInput') chipInput!: ElementRef<HTMLInputElement>;
  announcer = inject(LiveAnnouncer);

  remove(chip: TokenFormat): void {
    const chips = this.formCtrl.value;
    const index = chips.indexOf(chip);
    if (index >= 0) {
      chips.splice(index, 1);
      this.announcer.announce(`Removed ${chip}`);
    }
    this.formCtrl.setValue(chips);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const chips = this.formCtrl.value;
    chips.push(event.option.value);
    this.chipInput.nativeElement.value = '';
    this.chipCtrl.setValue(null);
    this.formCtrl.setValue(chips);
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value && this.chipCtrl.valid) {
      const chips = this.formCtrl.value;
      const index = chips.findIndex(
        (c: TokenFormat) => c.value === this.currentChip?.value
      );
      if (index === -1) {
        chips.push({ label: value, value });
      } else {
        chips[index] = {
          label: value,
          value,
        };
      }
      this.formCtrl.setValue(chips);
      this.chipCtrl.setValue(null);
      this.chipInput.nativeElement.value = '';
      this.currentChip = undefined;
    }
  }

  selectedChip(chip: TokenFormat) {
    this.currentChip = chip;
    const findToken = this.filenameToken.find((f) => f.value === chip.value);
    this.currentChip = chip;
    if (!findToken) {
      this.chipInput.nativeElement.value = chip.value || '';
    }
  }
}
