import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ISettingsVariant } from 'src/app/models/mastersuites/mastersuite';
import { MasterSuitesService } from 'src/app/services/mastersuites.service';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-master-suite-settings-varaint-dialog',
  templateUrl: './master-suite-settings-varaint-dialog.component.html',
  styleUrls: ['./master-suite-settings-varaint-dialog.component.scss'],
})
export class MasterSuiteSettingsVaraintDialogComponent {
  settingsVariants!: ISettingsVariant[];
  isLoading = true;

  settingsVariantSelected?: ISettingsVariant;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dataDialog: any,
    private masterSuitesService: MasterSuitesService,
    private productsService: ProductsService,
    private router: Router,
    public dialogRef: MatDialogRef<MasterSuiteSettingsVaraintDialogComponent>,
    private _alert: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.masterSuitesService
      .listSettingsVariants(this.dataDialog.masterSuiteId)
      .subscribe({
        next: (res) => {
          this.settingsVariants = res;
          if (res.length === 0) {
            this.dialogRef.close({
              settingsVariants: [],
            });
            return;
          }
          if (res.length === 1) {
            this.settingsVariantSelected = res[0];
          }
          if (this.dataDialog.settingsVariantId) {
            this.settingsVariantSelected = this.settingsVariants.find(
              (en) => en.id === this.dataDialog.settingsVariantId
            );
          }
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
          this.dialogRef.close();
        },
      });
  }

  openMasterSuite() {
    this.dialogRef.close();
    this.router.navigate([
      `mastersuites/${this.dataDialog.masterSuiteId}/update`,
    ]);
  }

  onSave() {
    this.productsService
      .applySettingsVariantOnProducts({
        productIds: this.dataDialog.productIds,
        settingsVariantId: this.settingsVariantSelected?.id || '',
        version: 0,
      })
      .subscribe({
        next: (res) => {
          this._alert.open(
            `Settings Variant ${this.settingsVariantSelected?.name} applied!`,
            'close',
            {
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
              duration: 2000,
              panelClass: ['success'],
            }
          );
          this.dialogRef.close({
            settingsVariantSelected: this.settingsVariantSelected,
          });
        },
        error: (e) => {
          this._alert.open(e.message, 'close', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            panelClass: ['error'],
          });
          this.dialogRef.close();
        },
      });
  }
}
