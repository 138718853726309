import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IOrganization } from '../models/organizations/organization';
import { OrganizationCreateModel } from '../models/organizations/organization-create';
import { OrganizationUpdateModel } from '../models/organizations/organization-update';
import {
  OutputFormat,
  StillOutputFormat,
  VideoOutputFormat,
} from '../models/organizations/form-setting';
import { IAuthResultRequest } from '../components/home/organizations/shopify-auth-result/shopify-auth-result.component';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class OrganizationsService {
  constructor(private http: HttpClient) {}

  currentOrganization: IOrganization = {
    name: '',
    id: null,
    createdAt: '',
    updatedAt: '',
    s3Bucket: '',
    s3BucketUri: '',
    s3BucketDelivered: '',
    s3BucketDeliveredUri: '',
    cognitoGroupUsers: '',
    profileImageUrl: '',
    version: 0,
    shopifyHostDomain: '',
    lastSyncedAt: '',
  };

  setCurrentOrg(organization: IOrganization) {
    this.currentOrganization = organization;
  }
  getCurrentOrg() {
    return this.currentOrganization;
  }

  createOrganization(
    organizationCreate: OrganizationCreateModel
  ): Observable<IOrganization> {
    const url: string = `${environment.apiBase}/organizations`;
    return this.http.post<IOrganization>(url, organizationCreate, httpOptions);
  }
  listOrganizations(): Observable<IOrganization[]> {
    const url = `${environment.apiBase}/organizations`;
    console.log('Getting Organizations');
    return this.http.get<IOrganization[]>(url, httpOptions).pipe(
      map((organizations) =>
        organizations.sort((orgA: IOrganization, orgB: IOrganization) => {
          const nameA: string = orgA.name.toLowerCase();
          const nameB: string = orgB.name.toLowerCase();
          return nameA < nameB ? -1 : 1;
        })
      )
    );
  }

  getOrganization(id: string): Observable<IOrganization> {
    var url = `${environment.apiBase}/organizations/${id}`;
    return this.http.get<IOrganization>(url, httpOptions);
  }

  updateOrganizationProfileImage(
    id: string,
    file: File
  ): Observable<IOrganization> {
    const url: string = `${environment.apiBase}/organizations/ProfileImage/${id}`;
    let formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.put<IOrganization>(url, formData);
  }

  updateOrganization(
    id: string,
    organizationUpdate: OrganizationUpdateModel
  ): Observable<IOrganization> {
    const url: string = `${environment.apiBase}/organizations/${id}`;
    return this.http.put<IOrganization>(url, organizationUpdate);
  }

  createOutput(data: any, organizationId: string) {
    const url: string = `${environment.apiBase}/organization/${organizationId}/output-formats`;
    return this.http.post(url, data);
  }

  getOutputFormat(organizationId: string): Observable<OutputFormat[]> {
    const url: string = `${environment.apiBase}/organization/${organizationId}/output-formats`;
    return this.http.get<OutputFormat[]>(url);
  }

  deleteOutputFormat(settingId: string) {
    const url: string = `${environment.apiBase}/output-formats/${settingId}`;
    return this.http.delete(url);
  }
  updateOutputFormat(organizationId: string, settingId: string, data: any) {
    const url: string = `${environment.apiBase}/organization/${organizationId}/output-format/${settingId}`;
    return this.http.put(url, data);
  }

  shopifyLogin(id: string): Observable<string> {
    const url: string = `${environment.apiBase}/organization/${id}/shopify-login`;
    return this.http.get<string>(url);
  }

  shopifyAuthResult(request: IAuthResultRequest): Observable<IOrganization> {
    const url: string = `${environment.apiBase}/organization/shopify-auth-result`;
    return this.http.post<IOrganization>(url, request);
  }

  shopifyProductsSync(id: string): Observable<IOrganization> {
    const url: string = `${environment.apiBase}/organization/${id}/products/shopify-sync`;
    return this.http.get<IOrganization>(url);
  }

  public static textConfigStills(format: StillOutputFormat) {
    if (format == null) {
      return '';
    }
    return (
      '<b>STILLS: </b>' +
      format.format.toString().toUpperCase() +
      (format.width && !format.useLongEdge ? ' Width:' + format.width : '') +
      (format.height && !format.useLongEdge ? ' Height:' + format.height : '') +
      (format.longEdge && format.useLongEdge
        ? ' Long Edge:' + format.longEdge
        : '') +
      (format.shortEdge && format.useLongEdge
        ? ' Short Edge:' + format.shortEdge
        : '') +
      (format.cropSubject ? ' Cropped ' : '') +
      (format.whitespacePercent
        ? format.whitespacePercent + '% Whitespace'
        : '') +
      (format.dpi ? ' DPI:' + format.dpi : '') +
      (format.format === 'Jpg'
        ? ' Color Space:' +
          format.colorSpace +
          (format.quality ? ' Quality:' + format.quality : '')
        : '') +
      (format.format === 'WebP' && format.lossless
        ? ' Lossless'
        : ' Quality:' + format.quality) +
      ((format.format === 'Png' || format.format === 'Tiff') && format.bitDepth
        ? ' Bit-depth:' + format.bitDepth
        : '') +
      ', '
    );
  }

  public static textConfigVideos(format: VideoOutputFormat) {
    if (format == null) {
      return '';
    }
    return (
      '<b>VIDEOS: </b>' +
      format.format.toUpperCase() +
      (format.width ? ' Width:' + format.width : '') +
      (format.height ? ' Height:' + format.height : '')
    );
  }
}
