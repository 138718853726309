<div *ngIf="loaded" class="content">
  <div class="title">{{ currentBatch.name }} Overview</div>
  <mat-list>
    <!--
        Received Status Progress Bar
        -->
    <mat-list-item>
      <p matListItemLine>
        <span>Received</span>
        <span class="spacer"></span>
        <span
          >{{
            (
              (currentBatch.productsReceivedGoodCount /
                currentBatch.productCount) *
              100
            ).toFixed(0)
          }}% Complete (Good Conditon)</span
        >
      </p>
      <div class="progress-outer">
        <!-- Good View -->
        <div
          *ngIf="
            currentBatch.productsReceivedPoorCount == 0 &&
            currentBatch.productsReceivedOkayCount == 0
          "
          class="progress-inner good"
          matTooltip="{{ currentBatch.productsReceivedGoodCount }} / {{
            currentBatch.productCount
          }} Good"
          style="--percentage: {{
            (currentBatch.productsReceivedGoodCount /
              currentBatch.productCount) *
              100
          }}%;"
        ></div>

        <div
          *ngIf="
            currentBatch.productsReceivedPoorCount > 0 ||
            currentBatch.productsReceivedOkayCount > 0
          "
          class="progress-left-inner good"
          matTooltip="{{ currentBatch.productsReceivedGoodCount }} / {{
            currentBatch.productCount
          }} Good"
          style="--percentage: {{
            (currentBatch.productsReceivedGoodCount /
              currentBatch.productCount) *
              100
          }}%;"
        ></div>

        <!-- Okay View -->
        <div
          *ngIf="
            currentBatch.productsReceivedOkayCount > 0 &&
            currentBatch.productsReceivedGoodCount == 0 &&
            currentBatch.productsReceivedPoorCount == 0
          "
          class="progress-inner okay"
          matTooltip="{{ currentBatch.productsReceivedOkayCount }} / {{
            currentBatch.productCount
          }} Okay"
          style="--percentage: {{
            (currentBatch.productsReceivedOkayCount /
              currentBatch.productCount) *
              100
          }}%;"
        ></div>

        <div
          *ngIf="
            currentBatch.productsReceivedOkayCount > 0 &&
            currentBatch.productsReceivedGoodCount == 0 &&
            currentBatch.productsReceivedPoorCount > 0
          "
          class="progress-left-inner okay"
          matTooltip="{{ currentBatch.productsReceivedOkayCount }} / {{
            currentBatch.productCount
          }} Okay"
          style="--percentage: {{
            (currentBatch.productsReceivedOkayCount /
              currentBatch.productCount) *
              100
          }}%;"
        ></div>

        <div
          *ngIf="
            currentBatch.productsReceivedOkayCount > 0 &&
            currentBatch.productsReceivedGoodCount > 0 &&
            currentBatch.productsReceivedPoorCount > 0
          "
          class="progress-middle-inner okay"
          matTooltip="{{ currentBatch.productsReceivedOkayCount }} / {{
            currentBatch.productCount
          }} Okay"
          style="--percentage: {{
            (currentBatch.productsReceivedOkayCount /
              currentBatch.productCount) *
              100
          }}%;"
        ></div>

        <div
          *ngIf="
            currentBatch.productsReceivedOkayCount > 0 &&
            currentBatch.productsReceivedGoodCount > 0 &&
            currentBatch.productsReceivedPoorCount == 0
          "
          class="progress-right-inner okay"
          matTooltip="{{ currentBatch.productsReceivedOkayCount }} / {{
            currentBatch.productCount
          }} Okay"
          style="--percentage: {{
            (currentBatch.productsReceivedOkayCount /
              currentBatch.productCount) *
              100
          }}%;"
        ></div>

        <!-- Poor View -->
        <div
          *ngIf="
            currentBatch.productsReceivedOkayCount == 0 ||
            currentBatch.productsReceivedOkayCount == 0
          "
          class="progress-right-inner poor"
          matTooltip="{{ currentBatch.productsReceivedPoorCount }} / {{
            currentBatch.productCount
          }} Poor"
          style="--percentage: {{
            (currentBatch.productsReceivedPoorCount /
              currentBatch.productCount) *
              100
          }}%;"
        ></div>

        <div
          *ngIf="
            currentBatch.productsReceivedGoodCount > 0 ||
            currentBatch.productsReceivedOkayCount > 0
          "
          class="progress-right-inner poor"
          matTooltip="{{ currentBatch.productsReceivedPoorCount }} / {{
            currentBatch.productCount
          }} Poor"
          style="--percentage: {{
            (currentBatch.productsReceivedPoorCount /
              currentBatch.productCount) *
              100
          }}%;"
        ></div>
      </div>
    </mat-list-item>

    <!--
        Settings Status Progress Bar
        -->
    <mat-list-item>
      <p matListItemLine>
        <span>MasterSuites</span>
        <span class="spacer"></span>
        <span
          >{{
            (
              (currentBatch.productsAssignedMasterSuiteCount /
                currentBatch.productCount) *
              100
            ).toFixed(0)
          }}% Complete</span
        >
      </p>
      <div class="progress-outer">
        <div
          class="progress-inner good"
          matTooltip="{{ currentBatch.productsAssignedMasterSuiteCount }} / {{
            currentBatch.productCount
          }}"
          style="--percentage: {{
            (currentBatch.productsAssignedMasterSuiteCount /
              currentBatch.productCount) *
              100
          }}%;"
        ></div>
      </div>
    </mat-list-item>

    <!--
        Ingestion Status Progress Bar
        -->
    <mat-list-item>
      <p matListItemLine>
        <span>Ingestion</span>
        <span class="spacer"></span>
        <span
          >{{
            (
              (currentBatch.productsIngestedCount / currentBatch.productCount) *
              100
            ).toFixed(0)
          }}% Complete</span
        >
      </p>
      <div class="progress-outer">
        <div
          class="progress-inner good"
          matTooltip="{{ currentBatch.productsIngestedCount }} / {{
            currentBatch.productCount
          }}"
          style="--percentage: {{
            (currentBatch.productsIngestedCount / currentBatch.productCount) *
              100
          }}%;"
        ></div>
      </div>
    </mat-list-item>

    <!--
        Production Status Progress Bar
        -->
    <mat-list-item>
      <p matListItemLine>
        <span>Production</span>
        <span class="spacer"></span>
        <span
          >{{
            (
              (currentBatch.productsShotCount / currentBatch.productCount) *
              100
            ).toFixed(0)
          }}% Complete</span
        >
      </p>
      <div class="progress-outer">
        <div
          class="progress-inner good"
          matTooltip="{{ currentBatch.productsShotCount }} / {{
            currentBatch.productCount
          }}"
          style="--percentage: {{
            (currentBatch.productsShotCount / currentBatch.productCount) * 100
          }}%;"
        ></div>
      </div>
    </mat-list-item>

    <!--
        Post Production Status Progress Bar
        -->
    <mat-list-item>
      <p matListItemLine>
        <span>Enhancement</span>
        <span class="spacer"></span>
        <span
          >{{
            (
              (currentBatch.productsEnhancedCount / currentBatch.productCount) *
              100
            ).toFixed(0)
          }}% Complete</span
        >
      </p>
      <div class="progress-outer">
        <div
          class="progress-inner good"
          matTooltip="{{ currentBatch.productsEnhancedCount }} / {{
            currentBatch.productCount
          }}"
          style="--percentage: {{
            (currentBatch.productsEnhancedCount / currentBatch.productCount) *
              100
          }}%;"
        ></div>
      </div>
    </mat-list-item>

    <!--
        Received Status Progress Bar
        -->
    <mat-list-item>
      <p matListItemLine>
        <span>Reviewed</span>
        <span class="spacer"></span>
        <span
          >{{
            (
              (currentBatch.productsApprovedCount / currentBatch.productCount) *
              100
            ).toFixed(0)
          }}% Complete</span
        >
      </p>
      <div class="progress-outer">
        <div
          class="progress-inner good"
          matTooltip="{{ currentBatch.productsApprovedCount }} / {{
            currentBatch.productCount
          }}"
          style="--percentage: {{
            (currentBatch.productsApprovedCount / currentBatch.productCount) *
              100
          }}%;"
        ></div>
      </div>
    </mat-list-item>
  </mat-list>
  <button
    mat-raised-button
    color="primary"
    routerLink="/products"
    (click)="manageBatch(currentBatch)"
  >
    Manage
  </button>
</div>
