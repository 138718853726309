import { Location } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';

import { OrganizationsService } from 'src/app/services/organizations.service';
import { IOrganization } from 'src/app/models/organizations/organization';
import { Subject, takeUntil, forkJoin } from 'rxjs';
import { OrganizationUpdateModel } from 'src/app/models/organizations/organization-update';
import { OrganizationUpdateProfileImage } from 'src/app/models/organizations/organization-update-profile-image';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { FormSettingComponent } from './form-setting/form-setting.component';
import {
  OutputFormat,
  StillOutputFormat,
  TokenFormat,
  VideoOutputFormat,
} from 'src/app/models/organizations/form-setting';
import { filenameToken } from './organization.data';
import { DeleteModalComponent } from 'src/app/components/shared/delete-modal/delete-modal.component';
import { F } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-organization-update',
  templateUrl: './organization-update.component.html',
  styleUrls: ['./organization-update.component.scss'],
})
export class OrganizationUpdateComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  apiBase = environment.apiBase;
  loaded: boolean = false;
  organizationUpdateProfileImage: OrganizationUpdateProfileImage =
    new OrganizationUpdateProfileImage();
  organizationUpdate: OrganizationUpdateModel = new OrganizationUpdateModel();
  organization!: IOrganization;
  currentProfileImageUrl!: string | ArrayBuffer | null;
  file!: File;
  imageWidth: number = 400;
  imageHeight: number = 400;
  requestInProgress: boolean = false;
  validFile: boolean = false;
  nameFormControl: FormControl = new FormControl();
  shopifyFormControl: FormControl = new FormControl();
  profileImageFile!: string | ArrayBuffer | null;

  fileNameSettings: OutputFormat[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private location: Location,
    private organizationsService: OrganizationsService,
    private _alert: MatSnackBar,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getOrganization();
    this.getOutFormat();
  }

  getOutFormat() {
    const id: string = this.route.snapshot.paramMap.get('id')!;
    this.organizationsService.getOutputFormat(id).subscribe((res) => {
      this.fileNameSettings = res.map((outputFormat) => {
        if (outputFormat.stillFormat != null) {
          outputFormat.stillFormat.filenameFormats.forEach((f, index) => {
            f = {
              id: index,
              from: f.from,
              to: f.to,
              indexOffset: f.indexOffset,
              pattern: f.pattern,
              casing: f.casing == undefined ? 'match' : f.casing.toLowerCase(),
              tokenFormats: this.filenameToListPattern(f.pattern),
            };
          });
        }

        if (outputFormat.videoFormat != null) {
          outputFormat.videoFormat.filenameFormats.forEach((f, index) => {
            f = {
              id: index,
              from: f.from,
              to: f.to,
              indexOffset: f.indexOffset,
              pattern: f.pattern,
              casing: f.casing == undefined ? 'match' : f.casing.toLowerCase(),
              tokenFormats: this.filenameToListPattern(f.pattern),
            };
          });
        }

        return {
          ...outputFormat,
          configText:
            this.textStillsOutputDownload(outputFormat.stillFormat) +
            this.textVideosOutputDownload(outputFormat.videoFormat),
        };
      }) as OutputFormat[];
      console.log(this.fileNameSettings);
    });
  }

  filenameToListPattern(filename: string) {
    const regex = /({[^}]+}|[^{}]+)/g;
    const tmp: TokenFormat[] = [];
    const found = filename.match(regex);
    if (found) {
      for (const item of found) {
        const token = filenameToken.filter((f: TokenFormat) => {
          return f.value === item;
        });
        if (token.length !== 0) {
          tmp.push(token[0]);
        } else {
          tmp.push({
            label: item,
            value: item,
          });
        }
      }
    }
    return tmp;
  }

  getOrganization(): void {
    const id: string = this.route.snapshot.paramMap.get('id')!;
    this.organizationsService
      .getOrganization(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((organization) => {
        this.organization = organization;
        this.addExistingInfoToForm();
        this.addExistingImageToUI();
        this.loaded = true;
      });
  }
  addExistingInfoToForm() {
    this.nameFormControl.setValue(this.organization.name);
    this.shopifyFormControl.setValue(this.organization.shopifyHostDomain);
  }

  onFileChange(fileList: FileList | null): void {
    this.file = fileList![0];
    this.addFileToModel();
    this.extractImageFromFile();
    this.updateOrganizationProfileImage();
  }

  updateOrganizationProfileImage(): void {
    this.requestInProgress = true;
    // TODO pass through interceptor to remove request in progress
    this.organizationsService
      .updateOrganizationProfileImage(this.organization.id!, this.file!)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (result) => {
          console.log(result);
          this.requestInProgress = false;
          this.sendAlert('Successful update', 3000, 'success');
        },
        error: () => {
          this.requestInProgress = false;
          this.sendAlert('Failed update', 3000, 'error');
        },
      });
  }

  updateOrganization(): void {
    this.requestInProgress = true;
    // TODO pass through interceptor to remove request in progress
    this.organizationUpdate.version = this.organization.version;
    this.organizationUpdate.name = this.nameFormControl.value;
    this.organizationUpdate.shopifyHostDomain = this.shopifyFormControl.value;
    this.organizationsService
      .updateOrganization(this.organization.id!, this.organizationUpdate!)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (result) => {
          console.log(result);
          this.requestInProgress = false;
          this.sendAlert('Successful update', 3000, 'success');
        },
        error: () => {
          this.requestInProgress = false;
          this.sendAlert('Failed update', 3000, 'error');
        },
      });
  }

  addFileToModel(): void {
    this.organizationUpdateProfileImage.file = this.file!;
    console.log(this.organizationUpdateProfileImage.file);
  }

  extractImageFromFile(): void {
    let fileReader: FileReader = new FileReader();
    fileReader.readAsDataURL(this.file!);
    fileReader.addEventListener(
      'load',
      () => {
        this.currentProfileImageUrl = fileReader.result;
        console.log(this.currentProfileImageUrl);
      },
      false
    );
  }
  addExistingImageToUI(): void {
    this.currentProfileImageUrl = !!this.organization.profileImageUrl
      ? `${environment.apiBase}${this.organization.profileImageUrl}`
      : './assets/organization-placeholder.jpg';
  }

  sendAlert(message: string, duration: number, alertType: string): void {
    this._alert.open(message, 'close', {
      duration: duration,
      horizontalPosition: 'center',
      panelClass: [alertType],
    });
  }

  goBack(): void {
    this.router.navigate([`organizations`]);
  }

  onCreateNewOutputFormat() {
    let data = {
      isNew: true,
      organization: this.organization,
    };
    const navigationExtras: NavigationExtras = { state: { data: data } };
    this.router.navigate(
      [`organizations/${this.organization.id}/output-format/create`],
      navigationExtras
    );
  }

  onSelectConfig(file: OutputFormat) {
    if (file.stillFormat != null) {
      file.stillFormat.filenameFormats.map((f, index) => {
        f.tokenFormats = this.filenameToListPattern(f.pattern);
        return f;
      });
    }

    if (file.videoFormat != null) {
      file.videoFormat.filenameFormats.map((f, index) => {
        f.tokenFormats = this.filenameToListPattern(f.pattern);
        return f;
      });
    }

    let data = {
      isNew: false,
      data: file,
      organization: this.organization,
    };

    const navigationExtras: NavigationExtras = { state: { data: data } };
    console.log(navigationExtras);
    this.router.navigate(
      [`/organizations/${this.organization.id}/output-format/${data.data.id}`],
      navigationExtras
    );
  }
  onDelete(file: any) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      data: {
        header: `Delete output setting: ${file.name}`,
        message:
          'Are you sure you want to delete this output setting along with all information?',
      },
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.loaded = false;
          this.organizationsService.deleteOutputFormat(file.id).subscribe({
            next: () => {
              const i = this.fileNameSettings.findIndex(
                (f) => f.id === file.id
              );
              this.fileNameSettings.splice(i, 1);
              this.loaded = true;
            },
            error: () => {
              this.loaded = true;
            },
          });
        }
      });
  }

  textStillsOutputDownload(fileImage: StillOutputFormat) {
    return OrganizationsService.textConfigStills(fileImage);
  }
  textVideosOutputDownload(fileVideo: VideoOutputFormat) {
    return OrganizationsService.textConfigVideos(fileVideo);
  }

  shopifyLogin() {
    this.organizationsService
      .shopifyLogin(this.organization.id!)
      .subscribe((result) => {
        window.open(result, '_self');
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
