import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
//Services
import { OrganizationsService } from 'src/app/services/organizations.service';
// Models
import { OrganizationCreateModel } from '../../../../models/organizations/organization-create';

@Component({
  selector: 'app-organization-create',
  templateUrl: './organization-create.component.html',
  styleUrls: ['./organization-create.component.scss']
})
export class OrganizationCreateComponent implements OnInit{
  constructor(
    private formBuilder: FormBuilder,
    private location: Location,
    private organizationService: OrganizationsService,
    private _alert: MatSnackBar,
  ){}

  organizationCreate: OrganizationCreateModel = new OrganizationCreateModel;
  title : string = 'Create Organization'; 
  formInput1 : string = 'name';
  requestInProgress! : boolean;

  form = this.formBuilder.group({
    name: ['', {
      validators: [
        Validators.required,
      ],
      updateOn: 'change'}
    ],
  });

  get name() {
    return this.form.controls['name'];
  }    

  ngOnInit(): void {
    this.mapFormToModel();
  }

  createOrganization(): void{
    console.log(this.organizationCreate!)

    // TODO pass through interceptor to remove request in progress
    this.requestInProgress = true;
    this.organizationService.createOrganization(this.organizationCreate)
      //.subscribe(response => console.log(response));
      .subscribe(organization => {
        console.log(organization);
        this.requestInProgress = false;
        this.notifySuccessfulRequest();
        this.goBack();
        },
        error => {
          this.requestInProgress = false;
          this.notifyFailedRequest();
        }
      );
  }

  mapFormToModel(): void{    
    this.form.valueChanges.subscribe(value => {
      this.organizationCreate.name =  value.name!;
    })
  }

  notifySuccessfulRequest(): void{

    this._alert.open("Successful create","close", {
      duration: 3000,
      horizontalPosition: 'center',
      panelClass: ['success']
    });
  }
  notifyFailedRequest(): void{
    this._alert.open("Failed create", "close", {
      duration: 3000,
      panelClass: ['error']
    });
  }

  goBack(): void{
    this.location.back();
  }
  onSubmit(): void {}
}
