<button
  mat-icon-button
  [matMenuTriggerFor]="menu"
  matBadgeColor="warn"
  matBadgePosition="before"
  [matBadge]="unRead"
  [matBadgeHidden]="totalUnRead == 0"
  class="notification-btn"
>
  <mat-icon> notifications </mat-icon>
</button>
<mat-menu
  #menu="matMenu"
  yPosition="above"
  xPosition="after"
  class="notification-menu"
>
  <div class="notification-content">
    <div class="notification-content__head" (click)="onPrevent($event)">
      <div class="notification-content__label">Notifications</div>
      <div>
        Only show unread
        <mat-slide-toggle
          [(ngModel)]="isUnRead"
          class="notification-content__unread-filter"
          (click)="onUnReadFilter($event)"
        ></mat-slide-toggle>
      </div>
    </div>

    <div class="notification-content__sub" (click)="onPrevent($event)">
      <div>
        <mat-select
          *ngIf="isStaff"
          [(ngModel)]="category"
          placeholder="Category"
          class="notification-content__category"
          (click)="onCategoryClick($event)"
          (selectionChange)="onCategoryFilter()"
        >
          <mat-option
            *ngFor="let category of categoryList"
            [value]="category.value"
          >
            {{ category.label }}
          </mat-option>
        </mat-select>
      </div>
      <div>
        <button
          mat-button
          class="notification-content__mark-as-read"
          (click)="onMarkAsRead($event)"
        >
          Mark as read
        </button>
      </div>
    </div>

    <div class="notification-list" (scroll)="onScroll($event)">
      <div
        *ngFor="let notification of showingData"
        class="notification-item"
        (click)="notification.entityId != null ? onNavigate(notification) : ''"
      >
        <div class="flex-row">
          <mat-icon
            class="menu-icon notification-item__icon"
            [ngClass]="[notification.iconColor]"
            >{{ notification.icon }}</mat-icon
          >
          <div class="notification-item__label">
            {{ labelMapping[notification.type] }}
          </div>
          <div class="spacer"></div>
          <div
            *ngIf="notification.state === 'Unread'"
            class="notification-item__status"
          ></div>
        </div>
        <div class="notification-item__content">{{ notification.content }}</div>
        <div
          class="notification-item__time"
          matTooltip="{{
            notification.createdAt | dateAgo : currentConfigDate : true | async
          }}"
        >
          {{ notification.createdAt | dateAgo : currentConfigDate | async }}
        </div>
      </div>
      <ng-container *ngIf="isLoading">
        <div class="loading-overlay">
          <mat-spinner diameter="50"></mat-spinner>
        </div>
      </ng-container>
    </div>
  </div>
</mat-menu>
