import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { cloneDeep, isEmpty, isString } from 'lodash';
import { Subject, takeUntil } from 'rxjs';
import {
  ENotificationState,
  ENotificationType,
  INotification,
  INotificationResponse,
} from 'src/app/models/notification';
import { EAssetType, EStateAsset, IAsset } from 'src/app/models/products/asset';
import { IPreference } from 'src/app/models/users/userProfile';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ProductsService } from 'src/app/services/products.service';
import { SignalrService } from 'src/app/services/signalr.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  @Input() isStaff: boolean = false;
  private destroy$ = new Subject();
  unRead = '';
  totalUnRead = 0;
  category = '';
  isUnRead = false;
  categoryList = [
    {
      value: '',
      label: 'All',
    },
    {
      value: ENotificationType.DISCUSSION,
      label: 'Discussion',
    },
    {
      value: ENotificationType.PRODUCTION,
      label: 'Production',
    },
    {
      value: ENotificationType.POST_PRODUCTION,
      label: 'Post Production',
    },
    {
      value: ENotificationType.REVIEW,
      label: 'Review',
    },
    {
      value: ENotificationType.OUTPUTS,
      label: 'Output Assets',
    },
  ];
  notificationPaging: INotificationResponse = {
    pageNumber: 1,
    pageSize: 10,
    results: [],
    total: 0,
    totalUnRead: 0,
  };
  notificationData: INotification[] = [];
  showingData: INotification[] = [];
  isLoading = true;
  labelMapping: { [name: string]: string } = {
    [ENotificationType.DISCUSSION]: 'Discussion',
    [ENotificationType.PRODUCTION]: 'Production',
    [ENotificationType.POST_PRODUCTION]: 'Post Production',
    [ENotificationType.REVIEW]: 'Review',
    [ENotificationType.OUTPUTS]: 'Output Assets',
  };
  iconMapping: { [name: string]: any } = {
    [ENotificationType.DISCUSSION]: 'messenger_outline',
    [ENotificationType.PRODUCTION]: 'photo_camera',
    [ENotificationType.POST_PRODUCTION]: 'auto_awesome',
    [ENotificationType.REVIEW]: {
      [EStateAsset.APPROVED]: 'check_circle',
      [EStateAsset.REJECTED]: 'cancel',
    },
    [ENotificationType.OUTPUTS]: 'sync',
  };
  maxItem = 99;
  currentConfigDate!: IPreference;
  constructor(
    private productService: ProductsService,
    private signalrService: SignalrService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.notificationPaging = {
      pageNumber: 1,
      pageSize: 10,
      results: [],
      total: 0,
      totalUnRead: 0,
    };

    this.authService.currentPreference.subscribe((res) => {
      this.currentConfigDate = res;
      this.isLoading = false;
    });

    this.notificationData = [];
    this.showingData = [];
    this.signalrService.notificationData.subscribe({
      next: (data: INotification) => {
        if (isEmpty(data)) {
          return;
        }
        const idx = this.notificationData.findIndex(
          (el) => el.entityId === data.entityId
        );
        if (idx === -1 || data.count === 0) {
          this.notificationData.unshift(data);
          if (data.state === ENotificationState.UNREAD) {
            this.totalUnRead += 1;
            this.unRead = `${
              this.totalUnRead > 0
                ? Math.min(this.totalUnRead, this.maxItem)
                : ''
            }${this.totalUnRead > this.maxItem ? '+' : ''}`;
          }
          this.parseList();
        } else if (data.count && data.count > 0) {
          const tmp = cloneDeep(this.notificationData[idx]);
          this.notificationData.splice(idx, 1);
          this.notificationData.unshift(tmp);
        }
      },
    });
    this.getListNotification();
  }

  getListNotification() {
    this.isLoading = true;
    this.notificationService
      .getNotification({
        ...this.notificationPaging,
        state: this.isUnRead ? ENotificationState.UNREAD : undefined,
        type: this.category ? this.category : undefined,
      })
      .subscribe({
        next: (response: INotificationResponse) => {
          this.notificationPaging = {
            ...response,
            pageSize: this.notificationPaging.pageSize,
          };
          this.notificationData.push(...response.results);
          this.parseList();
          if (!response.totalUnRead) {
            this.totalUnRead = this.notificationData.filter(
              (el) => el.state === ENotificationState.UNREAD
            ).length;
          } else {
            this.totalUnRead = response.totalUnRead;
          }
          this.unRead = `${
            this.totalUnRead > 0 ? Math.min(this.totalUnRead, this.maxItem) : ''
          }${this.totalUnRead > this.maxItem ? '+' : ''}`;
        },
      });
  }

  parseList() {
    this.showingData = this.notificationData.map((el) => {
      const data = { ...el };
      if (el.type === ENotificationType.REVIEW) {
        data.icon =
          this.iconMapping[el.type][el.reviewState] || 'do_not_disturb_on';
        data.iconColor = !!el.reviewState
          ? el.reviewState === EStateAsset.APPROVED
            ? 'success'
            : 'error'
          : '';
      } else {
        data.icon = this.iconMapping[el.type];
        data.iconColor = '';
      }
      return data;
    });
  }

  onNavigate(notification: INotification) {
    const assetId = notification.entityId;
    const assetType =
      notification.entityKey == 'post-asset-internal'
        ? EAssetType.POST_ASSETS
        : notification.entityKey;
    this.productService
      .getAssetById(assetId, assetType)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (value: any) => {
          this.notificationService
            .markRead(notification.userNotificationId)
            .subscribe({
              next: (data: any) => {
                if (!data.isValid) {
                  console.log(
                    `Mark ${notification.userNotificationId} as read failed!`
                  );
                } else {
                  console.log(
                    `Mark ${notification.userNotificationId} as read success!`
                  );
                  const idx = this.notificationData.findIndex(
                    (el) =>
                      el.userNotificationId === notification.userNotificationId
                  );
                  if (
                    idx > -1 &&
                    this.notificationData[idx].state ===
                      ENotificationState.UNREAD
                  ) {
                    this.notificationData[idx].state = ENotificationState.READ;
                    this.totalUnRead--;
                    this.unRead = `${
                      this.totalUnRead > 0
                        ? Math.min(this.totalUnRead, this.maxItem)
                        : ''
                    }${this.totalUnRead > this.maxItem ? '+' : ''}`;
                    this.parseList();
                  }
                }
              },
            });
          var route = `batches/${value.batchId}/products/${value.productId}/productTouchAsset/${value.productTouchAssetId}/masterSuiteTouchAsset/${value.masterSuiteTouchAssetId}/${assetType}`;
          if (assetType == EAssetType.POST_ASSETS) {
            this.router.navigate([route], {
              queryParams: {
                channel:
                  notification.entityKey == 'post-asset-internal'
                    ? 'internal'
                    : 'external',
              },
            });
          } else {
            this.router.navigate([route]);
          }
        },
      });
  }

  onUnReadFilter(event: any) {
    event.stopPropagation();
    this.notificationPaging = {
      pageNumber: 1,
      pageSize: 10,
      results: [],
      total: 0,
      totalUnRead: 0,
    };
    this.notificationData = [];
    this.showingData = [];
    this.getListNotification();
  }
  onCategoryClick(event: any) {
    event.stopPropagation();
  }
  onCategoryFilter() {
    this.notificationPaging = {
      pageNumber: 1,
      pageSize: 10,
      results: [],
      total: 0,
      totalUnRead: 0,
    };
    this.notificationData = [];
    this.showingData = [];
    this.getListNotification();
  }
  onMarkAsRead(event: any) {
    event.stopPropagation();
    this.notificationService.markAllAsRead().subscribe({
      next: () => {
        this.notificationData = this.notificationData.map((el) => ({
          ...el,
          state: ENotificationState.READ,
        }));
        this.parseList();
        this.totalUnRead = 0;
        this.unRead = `${
          this.totalUnRead > 0 ? Math.min(this.totalUnRead, this.maxItem) : ''
        }${this.totalUnRead > this.maxItem ? '+' : ''}`;
      },
    });
  }

  onScroll(event: any) {
    if (
      !this.isLoading &&
      event.srcElement.scrollTop + event.srcElement.clientHeight >=
        event.srcElement.scrollHeight
    ) {
      if (
        this.notificationPaging.pageNumber * this.notificationPaging.pageSize <
        this.notificationPaging.total
      ) {
        this.notificationPaging.pageNumber++;
        this.getListNotification();
      }
    }
  }

  onPrevent(event: any) {
    event.stopPropagation();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
