<h1 mat-dialog-title style="font-size: 30px;">Product Creation</h1>
<mat-dialog-content class="mat-typography">
    <div>
        <mat-form-field appearance="outline" color="primary" class="dialog-input">
            <mat-label>Product Barcode</mat-label>
            <input matInput [formControl]="barcodeFormControl" [(ngModel)]="newProduct.barcode">
            <mat-error *ngIf="validatorProperties.includes('Barcode')">
                {{validationMessage('Barcode')}}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" color="primary" class="dialog-input">
            <mat-label>Reference</mat-label>
            <input matInput [formControl]="referenceFormControl" [(ngModel)]="newProduct.reference">
            <mat-error *ngIf="validatorProperties.includes('Reference')">
                {{validationMessage('Reference')}}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" color="primary" class="dialog-input">
            <mat-label>Description</mat-label>
            <input matInput [formControl]="descriptionFormControl" [(ngModel)]="newProduct.description">
            <mat-error *ngIf="validatorProperties.includes('Description')">
                {{validationMessage('Description')}}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" color="primary" class="dialog-input">
            <mat-label>Product Link</mat-label>
            <input matInput [(ngModel)]="newProduct.productLink">
        </mat-form-field> 
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button mat-button (click)="createProduct()" #create color="buttonSuccess">Create</button>
</mat-dialog-actions>