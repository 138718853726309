import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationExtras, Router } from '@angular/router';
import { DeleteModalComponent } from 'src/app/components/shared/delete-modal/delete-modal.component';
import { IMasterSuite } from 'src/app/models/mastersuites/mastersuite';
import { IOrganization } from 'src/app/models/organizations/organization';
import { MasterSuitesService } from 'src/app/services/mastersuites.service';
import { OrganizationsService } from 'src/app/services/organizations.service';
import { Observable, Subject, map, startWith, takeUntil } from 'rxjs';
import { FormControl } from '@angular/forms';
import { IPreference, IUserProfile } from 'src/app/models/users/userProfile';
import { AuthService } from 'src/app/services/auth.service';
import { SignalrService } from 'src/app/services/signalr.service';

@Component({
  selector: 'app-mastersuite-list',
  templateUrl: './mastersuite-list.component.html',
  styleUrls: ['./mastersuite-list.component.scss'],
})
export class MasterSuiteListComponent {
  private destroy$ = new Subject();
  constructor(
    private router: Router,
    private masterSuitesService: MasterSuitesService,
    private organizationsService: OrganizationsService,
    public dialog: MatDialog,
    public _alert: MatSnackBar,
    private authService: AuthService,
    private signalrService: SignalrService
  ) {
    this.initialData();
  }
  isAdmin: boolean = false;
  invalidOrganization: boolean = false;
  invalidOrganizationMessage: string = '';
  _currentOrganization: IOrganization | null = null;
  currentConfigDate!: IPreference;
  get currentOrganization() {
    return this._currentOrganization;
  }
  set currentOrganization(value: IOrganization | null) {
    if (value != null) {
      if (value.id != null) {
        this._currentOrganization = value;
        this.organizationsService.setCurrentOrg(value);
        this.organizationControl.markAsUntouched();
        this.signalrService.leaveGroup(this.listGroup);
        this.signalrService.connectState.subscribe({
          next: (data: boolean) => {
            if (data) {
              this.joinGroups(value.id!);
            }
          },
        });
        this.subscribeToData();

        this.listMasterSuites(value);
      } else {
        this.invalidOrganization = true;
        this.invalidOrganizationMessage = 'Invalid Organization';
        this.organizationControl.markAllAsTouched();
        this.organizationControl.setErrors({
          serverErrors: this.invalidOrganizationMessage,
        });
      }
    }
  }
  displayFn(option: IOrganization | null) {
    return option ? option.name : '';
  }
  private _filter(name: string): IOrganization[] {
    const filterValue = name.toLowerCase();
    return this.organizations.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }
  parseOrganizationValue(value: any) {
    const name = typeof value === 'string' ? value : value?.name;
    return name ? this._filter(name as string) : this.organizations.slice();
  }
  user!: IUserProfile | null;
  organizations: IOrganization[] = [];
  organizationControl = new FormControl();
  organizationFilteredOptions!: Observable<IOrganization[]>;
  organizationsSubscription: any;
  masterSuiteSubscription: any;
  masterSuites: IMasterSuite[] = [];
  masterSuitesFound: boolean = false;
  errorMessages: string[] = [];
  currentLoaded: boolean = false;
  displayedColumns: string[] = [
    'name',
    'touchCount',
    'variantCount',
    'enhancementVariantCount',
    'createdAt',
    'Actions',
  ];
  dataSource = new MatTableDataSource<IMasterSuite>();
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  serverErrorMessage: String = '';

  filter: any;
  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toUpperCase();
  }

  initialData() {
    this.isAdmin = this.authService.getRole('admin');
    this.currentOrganization = this.organizationsService.getCurrentOrg();
    this.currentLoaded = true;
  }

  ngOnInit(): void {
    this.user = this.authService.getAuthInformation();
    this.organizationsSubscription = this.organizationsService
      .listOrganizations()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (organizations: any) => {
          if (organizations) {
            this.organizations = organizations;
            console.log('Organizations Found: ', organizations);
            this.organizationFilteredOptions =
              this.organizationControl.valueChanges.pipe(
                startWith(''),
                map((value) => this.parseOrganizationValue(value))
              );
          }
        },
        (error) => {
          console.log(error);
        }
      );
    this.authService.currentPreference.subscribe((res) => {
      this.currentConfigDate = res;
    });
  }

  listMasterSuites(organization: IOrganization) {
    if (organization.id != null) {
      this.masterSuitesFound = true;
      this.organizationsService.setCurrentOrg(organization);
      this.masterSuiteSubscription = this.masterSuitesService
        .listMasterSuites(organization.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (mastersuites: any) => {
            if (mastersuites) {
              console.log('Master Suites Found: ', mastersuites);
              this.masterSuites = mastersuites;
              this.dataSource = new MatTableDataSource<IMasterSuite>(
                this.masterSuites
              );
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
            }
          },
          (error: any) => {
            this.masterSuitesFound = false;
            this.serverErrorMessage =
              'There was an error loading the Users from the server. Please restart the system and try again';
          }
        );
    } else {
      console.log('Fail');
    }
  }

  createMasterSuite() {
    const navigationExtras: NavigationExtras = {
      state: { orgId: this.currentOrganization!.id },
    };
    this.router.navigate(['mastersuites/create'], navigationExtras);
  }

  updateMasterSuite(masterSuite: IMasterSuite) {
    this.router.navigate([`mastersuites/${masterSuite.id}/update`]);
  }

  copyMasterSuite(masterSuiteOriginal: IMasterSuite) {
    this.masterSuiteSubscription = this.masterSuitesService
      .detailMasterSuite(masterSuiteOriginal.id!)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (masterSuite) => {
          if (masterSuite) {
            const navigationExtras: NavigationExtras = {
              state: {
                orgId: this.currentOrganization!.id,
                masterSuite: masterSuite,
              },
            };
            this.router.navigate(['mastersuites/create'], navigationExtras);
          }
        },
        (error: any) => {
          this.serverErrorMessage =
            'There was an error loading the Products from the server. Please restart the system and try again';
          console.log(error.message);
        }
      );
  }

  deleteMasterSuite(masterSuite: IMasterSuite) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      data: {
        header: `Delete Package: ${masterSuite.name}`,
        message:
          'Are you sure you want to delete this package along with all information?',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.masterSuitesService
            .deleteMasterSuite(masterSuite)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              (data) => {
                this._alert.open('Successfully Deleted', 'close', {
                  horizontalPosition: 'center',
                  verticalPosition: 'bottom',
                  duration: 2000,
                  panelClass: ['success'],
                });
                // Fetch New Products
                this.listMasterSuites(this.currentOrganization!);
              },
              (response: any) => {
                console.log(response);
                this._alert.open(
                  'Validation Failure: ' +
                    response.error.validationErrors[0].message,
                  'close',
                  {
                    horizontalPosition: 'center',
                    verticalPosition: 'bottom',
                    duration: 10000,
                    panelClass: ['error'],
                  }
                );
              }
            );
        }
      });
  }

  listGroup: string = '';
  joinGroups(organizationId: string) {
    this.signalrService
      .joinIdGroup('JoinMasterSuitesListGroup', organizationId)
      ?.then((group: string) => (this.listGroup = group));
  }

  subscribeToData() {
    this.signalrService.masterSuitesData.subscribe({
      next: (data: any) => {
        if (data.id) {
          var index = this.masterSuites.findIndex((p) => p.id == data.id);
          if (index >= 0) {
            this.masterSuites[index] = data;
            this.dataSource.data = this.masterSuites;
          } else {
            this.masterSuites.push(data);
            this.dataSource.data = this.masterSuites;
          }
        }
      },
    });
  }

  setPageSizePreference(event: any) {
    if (event.pageSize != this.currentConfigDate.pageSize) {
      this.currentConfigDate.pageSize = event.pageSize;
      let payload = {
        name: this.user?.name,
        preference: this.currentConfigDate,
      };
      this.authService.updatePreference(payload).subscribe((res) => {
        console.log(res);
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.signalrService.leaveGroup(this.listGroup);
  }
}
