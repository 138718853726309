<h1 mat-dialog-title class="title-warning">Discard Changes</h1>
<mat-dialog-content
  >Changes have been made, are you sure you want to discard these
  changes?</mat-dialog-content
>
<mat-dialog-actions style="justify-content: end">
  <button mat-dialog-close mat-stroked-button>No</button>
  <button
    mat-raised-button
    cdkFocusInitial
    color="buttonError"
    (click)="onOk()"
  >
    Discard
  </button>
</mat-dialog-actions>
