<div class="content" *ngIf="!loading">
  <div class="content-header">
    <div class="title">
      {{ outputsCollection.jobNumber }}:
      {{ outputsCollection.jobName }} (Collection {{ outputsCollection.index }})
    </div>
    <span class="spacer"></span>
    <form>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label>Search</mat-label>
        <input
          matInput
          type="text"
          (keyup)="applyFilter($event)"
          placeholder="Filter"
        />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </form>
  </div>
  <app-products-table
    [products]="products"
    [collectionId]="outputsCollection.id!"
    [searchFilter]="searchFilter.asObservable()"
  >
  </app-products-table>
</div>
