import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { IsoValues } from 'src/app/models/settings/consts/iso';
import { ShutterValues } from 'src/app/models/settings/consts/shutter';
import { KeyValue, Setting } from 'src/app/models/settings/setting';
import { SettingsService } from 'src/app/services/setting.service';
import { SettingValueStevie } from 'src/app/models/settings/settingNew';
import { ValidationErrors } from 'src/app/models/validationErrors';
import { Subject, takeUntil } from 'rxjs';
import { UnsavedChangesWarningComponent } from 'src/app/components/shared/unsaved-changes-warning/unsaved-changes-warning.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-setting-update',
  templateUrl: './setting-update.component.html',
  styleUrls: ['./setting-update.component.scss'],
})
export class SettingUpdateComponent {
  constructor(
    private router: Router,
    private settingsService: SettingsService,
    public _alert: MatSnackBar,
    private _location: Location,
    private route: ActivatedRoute,
    public dialog: MatDialog
  ) {
    this.initialData();
  }
  readonly: boolean = true;
  private destroy$ = new Subject();

  input: FormControl = new FormControl('', [Validators.required]);
  serverErrorMessage: String = '';
  errorMessages: ValidationErrors[] = [];
  validatorProperties: string[] = [];

  setting!: Setting;
  settingOriginal!: Setting;
  videoCheck: boolean = true;
  stillCheck: boolean = true;

  colourTemperatureControl: FormControl = new FormControl('', [
    Validators.max(9500),
    Validators.min(1700),
  ]);
  shutterValues = ShutterValues;
  isoValues = IsoValues;

  loaded: boolean = false;
  initialData() {
    this.readonly = true;
    this.getSettingDetail();
  }

  setupData() {
    if (!this.setting.video) {
      this.videoCheck = false;
      this.setting.video = new SettingValueStevie();
    } else {
      this.videoCheck = true;
    }
    if (!this.setting.still) {
      this.stillCheck = false;
      this.setting.still = new SettingValueStevie();
    } else {
      this.stillCheck = true;
    }
  }

  getSettingDetail() {
    const settingId = this.route.snapshot.paramMap.get('settingId');
    if (settingId) {
      this.settingsService
        .detailSetting(settingId)
        .pipe(takeUntil(this.destroy$))
        .subscribe((setting) => {
          this.setting = setting;
          this.settingOriginal = JSON.parse(JSON.stringify(this.setting));
          this.setupData();
          this.loaded = true;
        });
    }
  }

  updateSetting() {
    if (this.videoCheck == false) {
      this.setting.video = null;
    }
    if (this.stillCheck == false) {
      this.setting.still = null;
    }
    this.settingsService
      .updateSetting(this.setting)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          this._alert.open('Successfully Created Setting', 'close', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 2000,
            panelClass: ['success'],
          });
          this.initialData();
        },
        error: (error) => {
          if (error.status === 422) {
            this.errorMessages = [];
            this.validatorProperties = [];
            error.error.validationErrors.forEach((error: ValidationErrors) => {
              this.errorMessages.push(error);
              this.validatorProperties.push(error.property);
              this.input.markAllAsTouched();
              if (error.property == 'Name') {
                this.input.setErrors({ serverErrors: 'validation errors' });
              }
            });
          }
        },
      });
  }

  validateColourTemperature(keyValue: KeyValue) {
    if (typeof keyValue.value == 'number') {
      if (keyValue.value < 1700) {
        keyValue.value = 1700;
      } else if (keyValue.value > 9500) {
        keyValue.value = 9500;
      } else {
        if (keyValue.value % 100 != 0) {
          keyValue.value = 100 * Math.round(keyValue.value / 100 - 0.01);
        }
      }
    } else {
      console.log('Invalid Number Entered:' + keyValue.value);
      keyValue.value = 1700;
    }
  }

  validate(keyValue: KeyValue, min: number, max: number) {
    console.log(typeof keyValue.value);
    if (typeof keyValue.value == 'number') {
      if (keyValue.value < min) {
        keyValue.value = min;
      } else if (keyValue.value > max) {
        keyValue.value = max;
      } else {
        keyValue.value = Math.round(keyValue.value);
      }
    } else {
      console.log('Invalid Number Entered:' + keyValue.value);
      keyValue.value = min;
    }
  }

  validationMessage(property: string) {
    return this.errorMessages.find((error) => error.property == property)
      ?.message;
  }

  cancel() {
    if (this.checkChanges()) {
      const dialogRef = this.dialog.open(UnsavedChangesWarningComponent);
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.setting = JSON.parse(JSON.stringify(this.settingOriginal));
          this.setupData();
          this.readonly = true;
        }
      });
    } else {
      this.readonly = true;
    }
  }

  backClicked() {
    if (this.checkChanges()) {
      const dialogRef = this.dialog.open(UnsavedChangesWarningComponent);
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this._location.back();
        }
      });
    } else {
      this._location.back();
    }
  }

  checkChanges(): boolean {
    return (
      JSON.stringify(this.setting) != JSON.stringify(this.settingOriginal) ||
      (this.settingOriginal.video != null && this.videoCheck == false) ||
      (this.settingOriginal.still != null && this.stillCheck == false)
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
