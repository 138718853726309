import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private menuStatus = new BehaviorSubject<boolean>(false);
  public currentMenuStatus = this.menuStatus.asObservable();

  updateMenuStatus(status: boolean) {
    this.menuStatus.next(status);
  }
}
