<h1 mat-dialog-title>Master Suite Transfer</h1>
<mat-dialog-content>
  <mat-accordion class="example-headers-align">
    <mat-expansion-panel [expanded]="step === 0" hideToggle disabled="true">
      <mat-expansion-panel-header>
        <mat-panel-title> Step 1: </mat-panel-title>
        <mat-panel-description>
          Select Source and Destination Master Suites
        </mat-panel-description>
      </mat-expansion-panel-header>

      <app-select-mastersuites
        [products]="products"
        [masterSuites]="masterSuites"
        (selectedMasterSuiteSrc)="setMasterSuiteSrc($event)"
        (selectedMasterSuiteDst)="setMasterSuiteDst($event)"
        (selectedVaraintDst)="setVariantDst($event)"
      ></app-select-mastersuites>

      <mat-action-row>
        <div
          *ngIf="
            selectedMasterSuiteSrc?.id == selectedMasterSuiteDst?.id &&
            selectedMasterSuiteSrc != null
          "
          class="validation-message"
        >
          Source and Destination Master Suites cannot be the Same
        </div>
        <button mat-raised-button color="primary" (click)="nextStepProducts()">
          Next
        </button>
      </mat-action-row>
    </mat-expansion-panel>

    <mat-expansion-panel
      [expanded]="step === 1"
      hideToggle
      disabled="true"
      class="remove-mat-expansion-panel-padding"
    >
      <mat-expansion-panel-header>
        <mat-panel-title> Step 2: </mat-panel-title>
        <mat-panel-description>
          Select Products to Transfer
          <div *ngIf="selectedProducts.selected.length > 0">
            ({{ selectedProducts.selected.length }} Products Selected)
          </div>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <content>
        <mat-form-field
          appearance="outline"
          subscriptSizing="dynamic"
          style="width: 90% !important"
        >
          <mat-label>Search</mat-label>
          <input
            [formControl]="searchForm"
            matInput
            type="text"
            (keyup)="applyFilter($event)"
            placeholder="Filter"
          />
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
        <table
          mat-table
          [dataSource]="availableProductsDataSource"
          class="table"
        >
          <!-- Check Box Column -->
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox
                (change)="$event ? masterToggle() : null"
                [checked]="selectedProducts.hasValue() && isAllSelected()"
                [indeterminate]="
                  selectedProducts.hasValue() && !isAllSelected()
                "
                color="primary"
              >
              </mat-checkbox>
            </th>

            <td mat-cell *matCellDef="let product">
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? selectedProducts.toggle(product) : null"
                [checked]="selectedProducts.isSelected(product)"
                color="primary"
              >
              </mat-checkbox>
            </td>
          </ng-container>

          <!-- Barcode Column -->
          <ng-container matColumnDef="barcode">
            <th mat-header-cell *matHeaderCellDef>Barcode</th>
            <td mat-cell *matCellDef="let product">{{ product.barcode }}</td>
          </ng-container>

          <!-- Reference Column -->
          <ng-container matColumnDef="reference">
            <th mat-header-cell *matHeaderCellDef>Reference</th>
            <td mat-cell *matCellDef="let product">{{ product.reference }}</td>
          </ng-container>

          <!-- Description Column -->
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef>Description</th>
            <td mat-cell *matCellDef="let product">
              {{ product.description }}
            </td>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef style="width: 200px">
              Status
            </th>
            <td mat-cell *matCellDef="let product" class="icon-ctn">
              <div style="display: flex; justify-content: space-between">
                <mat-icon
                  color="{{ product.states.receivalState }}"
                  matTooltip="Received Quantity {{ product.receivedCount }}"
                  fontIcon="receipt_long"
                ></mat-icon>
                <mat-icon
                  color="{{ product.states.masterSuiteState }}"
                  matTooltip="MasterSuite {{
                    product.masterSuiteId != null ? 'Applied' : 'Pending'
                  }}"
                  fontIcon="lightbulb"
                ></mat-icon>
                <mat-icon
                  color="{{ product.states.ingestionState }}"
                  matTooltip="{{ product.ingestedTouchCount }}/{{
                    product.expectedTouchCount
                  }} Touches Ingested"
                  fontIcon="dataset"
                ></mat-icon>
                <mat-icon
                  color="{{ product.states.productionState }}"
                  matTooltip="{{ product.shotTouchCount }}/{{
                    product.expectedTouchCount
                  }} Touches Shot"
                  fontIcon="camera_alt"
                ></mat-icon>
                <mat-icon
                  color="{{ product.states.enhancementState }}"
                  matTooltip="{{ product.postAssetCount }}/{{
                    product.expectedPostAssetCount
                  }} Assets Enhanced"
                  fontIcon="palette"
                ></mat-icon>
                <mat-icon
                  mat-icon-button
                  color="{{ product.states.reviewState }}"
                  matTooltip="{{ product.approvedPostAssetCount }}/{{
                    product.expectedPostAssetCount
                  }} Assets Approved"
                  fontIcon="reviews"
                ></mat-icon>

                <mat-icon
                  fontIcon="delivery_dining"
                  color="{{
                    product.dispatchedCount > 0 ? 'success' : 'pending'
                  }}"
                  matTooltip="Dispatched Quantity {{ product.dispatchedCount }}"
                ></mat-icon>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            (click)="selectRow($event, row)"
          ></tr>
        </table>
        <mat-paginator
          *ngIf="availableProducts.length > 10"
          [pageSizeOptions]="[10, 20, 50, 100]"
          showFirstLastButtons
          aria-label="Select page of Products"
        >
        </mat-paginator>
      </content>
      <mat-action-row>
        <div
          *ngIf="selectedProducts.selected.length == 0"
          class="validation-message"
        >
          One or more Proucts Must be Selected
        </div>
        <button mat-button color="warn" (click)="prevStep()">Previous</button>
        <button
          mat-raised-button
          color="primary"
          (click)="validateSettingsTransfer()"
        >
          Next
        </button>
      </mat-action-row>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="step === 2" hideToggle disabled="true">
      <mat-expansion-panel-header>
        <mat-panel-title> Step 3: </mat-panel-title>
        <mat-panel-description>
          Assess and Confirm Transfer
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-validate-transfer
        *ngIf="validated"
        [settingsTransfer]="settingsTransfer"
        [masterSuiteDst]="selectedMasterSuiteDst!"
        (valid)="inValidateTransfer()"
      >
      </app-validate-transfer>
      <mat-action-row>
        <mat-spinner *ngIf="reValidating" [diameter]="36"></mat-spinner>
        <button mat-button color="warn" (click)="prevStep()">Previous</button>
        <button
          *ngIf="valid == true"
          mat-raised-button
          color="buttonSuccess"
          (click)="confirmSettingsTrasnfer()"
        >
          Confirm
        </button>
        <button
          *ngIf="valid != true"
          mat-raised-button
          color="primary"
          (click)="reValidate()"
        >
          Re-Validate
        </button>
      </mat-action-row>
    </mat-expansion-panel>
  </mat-accordion>
</mat-dialog-content>
<mat-dialog-actions style="justify-content: end">
  <button mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
