export enum ENotificationState {
  READ = 'Read',
  UNREAD = 'Unread',
}
export enum ENotificationType {
  DISCUSSION = 'Comment',
  PRODUCTION = 'NewProduction',
  POST_PRODUCTION = 'NewPostProduction',
  REVIEW = 'AssetReview',
  OUTPUTS='OutputAssets'
}

export interface INotification {
  id: string;
  createdAt: string;
  updatedAt: string;
  version: number;
  organizationId: string;
  authorUserId: string;
  authorUsername: string;
  type: string;
  content: string;
  reviewState: string;
  groupName: string;
  state: ENotificationState;
  userNotificationId: string;
  entityKey:string;
  entityId:string;
  icon?: string;
  iconColor?: string;
  url: string;
  count?: number;
}

export interface INotificationRequest {
  pageNumber: number;
  pageSize: number;
  type?: string;
  state?: ENotificationState;
}

export interface INotificationResponse {
  pageNumber: number;
  pageSize: number;
  results: INotification[];
  total: number;
  totalUnRead: number;
}
