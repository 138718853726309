<div class="content">
  <div class="content-header">
    <div class="title">Package Update</div>
    <span class="spacer"></span>
    <h4 class="router-link" (click)="backClicked()">Back to Packages</h4>
    <button
      [disabled]="!readonly"
      mat-icon-button
      matTooltip="Edit"
      [color]="readonly ? 'success' : 'disabled'"
      class="mat-elevation-z0"
      (click)="readonly = false"
    >
      <mat-icon>edit</mat-icon>
    </button>
  </div>
  <div class="sub-content" *ngIf="loaded">
    <mat-grid-list cols="12" rowHeight="100px">
      <mat-grid-tile colspan="7">
        <mat-form-field appearance="outline" color="primary">
          <mat-label>Package Name</mat-label>
          <input
            matInput
            required
            [formControl]="input"
            [(ngModel)]="package.name"
            [readonly]="readonly"
          />
          <mat-error *ngIf="validatorProperties.includes('Name')">
            {{ validationMessage("Name") }}
          </mat-error>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile colspan="2">
        <mat-form-field appearance="outline" class="action-form">
          <mat-label>Robot Position</mat-label>
          <input
            *ngIf="readonly"
            [readonly]="readonly"
            matInput
            [(ngModel)]="package.robotPosition"
          />
          <mat-select
            *ngIf="!readonly"
            [(ngModel)]="package.robotPosition"
            (selectionChange)="setConfig()"
          >
            <mat-option value="Front"> Front</mat-option>
            <mat-option value="SideLeft"> Side Left</mat-option>
          </mat-select>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile colspan="2">
        <mat-form-field appearance="outline" class="action-form">
          <mat-label>Camera Orientation</mat-label>
          <input
            *ngIf="readonly"
            [readonly]="readonly"
            matInput
            [(ngModel)]="package.cameraOrientation"
          />
          <mat-select *ngIf="!readonly" [(ngModel)]="package.cameraOrientation">
            <mat-option
              [disabled]="package.robotPosition == 'SideLeft'"
              value="Landscape"
            >
              Landscape
            </mat-option>
            <mat-option
              [disabled]="package.robotPosition == 'Front'"
              value="Portrait"
            >
              Portrait
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile colspan="1">
        <mat-checkbox [disabled]="readonly" color="primary" class="example-margin" [checked]="package.fixedPackage" [(ngModel)]="package.fixedPackage">
            Fixed
        </mat-checkbox>
      </mat-grid-tile>
    </mat-grid-list>
    <mat-grid-list cols="12" rowHeight="100px">
      <mat-grid-tile colspan="2">
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <mat-label>Stills</mat-label>
          <input
            [readonly]="true"
            type="number"
            min="0"
            matInput
            [(ngModel)]="stills.length"
          />
          <button
            matSuffix
            *ngIf="!readonly"
            mat-icon-button
            matTooltip="Add Still"
            color="success"
            class="mat-elevation-z0"
            (click)="addStill()"
          >
            <mat-icon>add_circle</mat-icon>
          </button>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile colspan="2">
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <mat-label>Videos</mat-label>
          <input
            [readonly]="true"
            type="number"
            min="0"
            matInput
            [(ngModel)]="videos.length"
          />
          <button
            matSuffix
            *ngIf="!readonly"
            mat-icon-button
            matTooltip="Add Video"
            color="success"
            class="mat-elevation-z0"
            (click)="addVideo()"
          >
            <mat-icon>add_circle</mat-icon>
          </button>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile colspan="4">
        <mat-form-field
          appearance="outline"
          subscriptSizing="dynamic"
          class="action-form"
        >
          <mat-label>Livepreview Angle</mat-label>
          <input
            *ngIf="readonly"
            [readonly]="readonly"
            matInput
            [(ngModel)]="package.livePreview.robotOutDegrees"
          />
          <mat-select
            *ngIf="!readonly"
            [(ngModel)]="package.livePreview.robotOutDegrees"
          >
            <mat-option
              *ngFor="let robotOutDegrees of config.robotOutDegreesOptions"
              [value]="robotOutDegrees"
            >
              {{ robotOutDegrees }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile colspan="4">
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <mat-label>Livepreview Table Position</mat-label>
          <input
            [readonly]="readonly"
            type="number"
            min="0"
            max="360"
            matInput
            [(ngModel)]="package.livePreview.tablePosition"
          />
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list>
    <div cdkDropList (cdkDropListDropped)="dragDropStill($event)" class="list">
      <mat-card
        *ngFor="let still of stills; let i = index"
        cdkDrag
        style="margin-bottom: 10px"
        class="box"
      >
        <mat-card-header style="align-items: center">
          <!-- Copied from Angualr material CDK Example -->
          <div *ngIf="!readonly" class="handle" cdkDragHandle>
            <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
              <path
                d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"
              ></path>
              <path d="M0 0h24v24H0z" fill="none"></path>
            </svg>
          </div>
          <mat-card-title class="asset-heading"
          >Still {{ i + 1 }}
          </mat-card-title
          >
          <span class="spacer"></span>
          <button
            *ngIf="!readonly"
            mat-icon-button
            matTooltip="Delete"
            color="error"
            class="mat-elevation-z0"
            (click)="removeStill(i)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </mat-card-header>
        <mat-card-content>
          <mat-grid-list cols="12" rowHeight="100px">
            <mat-grid-tile colspan="6">
              <mat-form-field
                appearance="outline"
                subscriptSizing="dynamic"
                class="action-form"
              >
                <mat-label>Robot Angle</mat-label>
                <input
                  *ngIf="readonly"
                  [readonly]="readonly"
                  matInput
                  [(ngModel)]="still.robotOutDegrees"
                />
                <mat-select
                  *ngIf="!readonly"
                  type="number"
                  [(ngModel)]="still.robotOutDegrees"
                >
                  <mat-option
                    *ngFor="
                      let robotOutDegrees of config.robotOutDegreesOptions
                    "
                    [value]="robotOutDegrees"
                  >
                    {{ robotOutDegrees }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile colspan="6">
              <mat-form-field appearance="outline" subscriptSizing="dynamic">
                <mat-label>Table Position</mat-label>
                <input
                  [readonly]="readonly"
                  type="number"
                  [min]="0"
                  [max]="360"
                  matInput
                  [(ngModel)]="still.tablePosition"
                />
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>
          <div class="card-actions">
            <mat-slide-toggle
              [disabled]="readonly"
              [(ngModel)]="still.offsets"
              color="primary"
            >Offsets
            </mat-slide-toggle
            >
          </div>
          <mat-grid-list *ngIf="still.offsets" cols="12" rowHeight="100px">
            <mat-grid-tile colspan="4">
              <mat-form-field appearance="outline" subscriptSizing="dynamic">
                <mat-label>Height Offset</mat-label>
                <input
                  [readonly]="readonly"
                  type="number"
                  step="0.1"
                  [min]="0"
                  [max]="2"
                  matInput
                  [(ngModel)]="still.heightOffset"
                />
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile colspan="4">
              <mat-form-field appearance="outline" subscriptSizing="dynamic">
                <mat-label>Distance Offset</mat-label>
                <input
                  [readonly]="readonly"
                  type="number"
                  step="0.1"
                  [min]="0"
                  [max]="2"
                  matInput
                  [(ngModel)]="still.distanceOffset"
                />
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile colspan="4">
              <mat-form-field appearance="outline" subscriptSizing="dynamic">
                <mat-label>Focus Offset</mat-label>
                <input
                  [readonly]="readonly"
                  type="number"
                  step="0.1"
                  [min]="-1"
                  [max]="1"
                  matInput
                  [(ngModel)]="still.focusOffset"
                />
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>
        </mat-card-content>
      </mat-card>
    </div>

    <div cdkDropList (cdkDropListDropped)="dragDropVideo($event)" class="list">
      <mat-card
        *ngFor="let video of videos; let i = index"
        style="margin-bottom: 10px"
      >
        <mat-card-header style="align-items: center">
          <!-- Copied from Angualr material CDK Example -->
          <div *ngIf="!readonly" class="handle" cdkDragHandle>
            <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
              <path
                d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"
              ></path>
              <path d="M0 0h24v24H0z" fill="none"></path>
            </svg>
          </div>
          <mat-card-title class="asset-heading"
          >Video {{ i + 1 }}
          </mat-card-title
          >
          <span class="spacer"></span>
          <button
            *ngIf="!readonly"
            mat-icon-button
            matTooltip="Delete"
            color="error"
            class="mat-elevation-z0"
            (click)="removeVideo(i)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </mat-card-header>
        <mat-card-content>
          <mat-grid-list cols="12" rowHeight="100px">
            <mat-grid-tile colspan="4">
              <mat-form-field
                appearance="outline"
                subscriptSizing="dynamic"
                class="action-form"
              >
                <mat-label>Robot Angle</mat-label>
                <input
                  *ngIf="readonly"
                  [readonly]="readonly"
                  matInput
                  [(ngModel)]="video.robotOutDegrees"
                />
                <mat-select
                  *ngIf="!readonly"
                  type="number"
                  [(ngModel)]="video.robotOutDegrees"
                >
                  <mat-option
                    *ngFor="
                      let robotOutDegrees of config.robotOutDegreesOptions
                    "
                    [value]="robotOutDegrees"
                  >
                    {{ robotOutDegrees }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile colspan="4">
              <mat-form-field appearance="outline" subscriptSizing="dynamic">
                <mat-label>Table Position</mat-label>
                <input
                  [readonly]="readonly"
                  type="number"
                  [min]="0"
                  [max]="360"
                  matInput
                  [(ngModel)]="video.tablePosition"
                />
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile colspan="4">
              <mat-form-field appearance="outline" subscriptSizing="dynamic">
                <mat-label>Table Spin Distance</mat-label>
                <input
                  [readonly]="readonly"
                  type="number"
                  [min]="0"
                  [max]="1080"
                  matInput
                  [(ngModel)]="video.tableSpinDistance"
                />
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>
          <div class="card-actions">
            <mat-slide-toggle
              [disabled]="readonly"
              [(ngModel)]="video.offsets"
              color="primary"
            >Offsets
            </mat-slide-toggle
            >
          </div>
          <mat-grid-list *ngIf="video.offsets" cols="12" rowHeight="100px">
            <mat-grid-tile colspan="4">
              <mat-form-field appearance="outline" subscriptSizing="dynamic">
                <mat-label>Height Offset</mat-label>
                <input
                  [readonly]="readonly"
                  type="number"
                  step="0.1"
                  [min]="0"
                  [max]="2"
                  matInput
                  [(ngModel)]="video.heightOffset"
                />
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile colspan="4">
              <mat-form-field appearance="outline" subscriptSizing="dynamic">
                <mat-label>Distance Offset</mat-label>
                <input
                  [readonly]="readonly"
                  type="number"
                  step="0.1"
                  [min]="0"
                  [max]="2"
                  matInput
                  [(ngModel)]="video.distanceOffset"
                />
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile colspan="4">
              <mat-form-field appearance="outline" subscriptSizing="dynamic">
                <mat-label>Focus Offset</mat-label>
                <input
                  [readonly]="readonly"
                  type="number"
                  step="0.1"
                  [min]="-1"
                  [max]="1"
                  matInput
                  [(ngModel)]="video.focusOffset"
                />
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class="content-actions" *ngIf="!readonly">
    <span class="spacer"></span>
    <button mat-stroked-button (click)="cancel()">Cancel</button>
    <button
      mat-button
      color="buttonSuccess"
      style="margin-left: 20px"
      (click)="updatePackage()"
    >
      Update
    </button>
  </div>
</div>
