<mat-card class="card-item">
  <div class="card-item__thumb">
    <div class="card-item__thumb-img" [ngClass]="{ blur: !data.id }">
      <img
        *ngIf="!data.id && !!data.thumbnailUri"
        class="blur"
        [src]="getLinkImage() | secure | async"
        [alt]="getLinkImage() | secure | async"
        [hidden]="!isImageLoaded"
        matTooltipPosition="above"
        (load)="checkImageLoaded()"
      />
      <img
        *ngIf="!!data.id && !!data.thumbnailUri"
        [ngClass]="{ blur: !data.id }"
        class="cursor-pointer"
        [src]="getLinkImage() | secure | async"
        [alt]="getLinkImage() | secure | async"
        [hidden]="!isImageLoaded"
        matTooltipPosition="above"
        (load)="checkImageLoaded()"
        (click)="open()"
      />
      <div
        *ngIf="!isImageLoaded || !data.thumbnailUri"
        class="card-item__no-img"
      >
        <img
          [src]="'assets/image-placeholder.jpg'"
          alt="Image Error"
          matTooltipPosition="above"
        />
      </div>
    </div>
  </div>
  <mat-card-content>
    <div class="content-item">
      <div>
        <div class="card-item__label" [title]="data.label">
          {{ data.label }}
        </div>
      </div>
      <i class="material-icons sparkle" *ngIf="data.enhancementFlag"
        >auto_awesome</i
      >
      <span class="spacer"></span>
      <div *ngIf="isShootAsset" style="display: flex">
        <div *ngIf="data.id && data.internalReviewState == null">
          <button
            color="error"
            mat-icon-button
            class="action_button"
            (click)="onReview(EStateAsset.REJECTED)"
          >
            <mat-icon>cancel</mat-icon>
          </button>
          <button
            color="success"
            mat-icon-button
            class="action_button"
            (click)="onReview(EStateAsset.APPROVED)"
          >
            <mat-icon>check_circle</mat-icon>
          </button>
        </div>
        <div
          *ngIf="data.internalReviewState != null"
          (click)="onReview(null)"
          class="card-item__status-text"
          [ngClass]="{
            approved: data.internalReviewState === EStateAsset.APPROVED,
            'in-review': data.internalReviewState === EStateAsset.IN_REVIEW,
            rejected: data.internalReviewState === EStateAsset.REJECTED
          }"
        >
          {{ statusMapping[data.internalReviewState] }}
        </div>
      </div>
      <div *ngIf="!isShootAsset" style="display: flex">
        <div *ngIf="data.id && data.clientReviewState == null">
          <button
            color="error"
            mat-icon-button
            class="action_button"
            (click)="onReview(EStateAsset.REJECTED)"
          >
            <mat-icon>cancel</mat-icon>
          </button>
          <button
            color="success"
            mat-icon-button
            class="action_button"
            (click)="onReview(EStateAsset.APPROVED)"
          >
            <mat-icon>check_circle</mat-icon>
          </button>
        </div>
        <div
          *ngIf="data.clientReviewState != null"
          (click)="onReview(null)"
          class="card-item__status-text"
          [ngClass]="{
            approved: data.clientReviewState === EStateAsset.APPROVED,
            'in-review': data.clientReviewState === EStateAsset.IN_REVIEW,
            rejected: data.clientReviewState === EStateAsset.REJECTED
          }"
        >
          {{ statusMapping[data.clientReviewState] }}
        </div>
      </div>
      <button
        [matMenuTriggerFor]="actionsMenu"
        *ngIf="
          (!!data.id || (data.variantTouchAssetId && data.productTouchId)) &&
          isStaff
        "
        mat-icon-button
        matTooltip="More"
        color="primary"
        class="action_button"
        style="margin: 0px !important"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #actionsMenu="matMenu">
        <button
          mat-menu-item
          (click)="getLinkDownload()"
          [disabled]="!data.thumbnailUri"
        >
          <mat-icon>download</mat-icon>
          <span>Download</span>
        </button>
        <button
          mat-menu-item
          (click)="onEdit()"
          [disabled]="!data.thumbnailUri"
        >
          <mat-icon>rate_review</mat-icon>
          <span>Review</span>
        </button>
      </mat-menu>
      <button
        *ngIf="!!data.id && !isStaff"
        mat-icon-button
        [disabled]="!data.thumbnailUri"
        (click)="onEdit()"
        matTooltip="Asset review"
        class="action_button"
      >
        <mat-icon>rate_review</mat-icon>
      </button>
      <div *ngIf="!data.id" class="card-item__description">Pending</div>
    </div>
  </mat-card-content>
</mat-card>
