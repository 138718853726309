<mat-card class="card-item">
  <div class="card-item__thumb">
    <div
      class="card-item__thumb-img"
      [ngClass]="{
        blur:
          data.shootAssetPlaceholderThumbnailUri != null &&
          data.postAssetPlaceholderThumbnailUri == null
      }"
    >
      <img
        *ngIf="!!data.id && getLinkImage() != null"
        [ngClass]="{
          'cursor-pointer': !readonly && data.id != null
        }"
        [attr.src]="getLinkImage()! | secure | async"
        [alt]=""
        [hidden]="!isImageLoaded"
        (load)="checkImageLoaded()"
        (click)="!readonly && data.id != null ? imageInput.click() : ''"
        [matTooltipDisabled]="readonly"
        matTooltipPosition="above"
        [matTooltip]="
          data.id == null
            ? 'Save Before Uploading Placeholder'
            : data.postAssetPlaceholderThumbnailUri == null
            ? 'Add Placeholder'
            : 'Change Placeholder'
        "
      />
      <input
        #imageInput
        hidden="true"
        type="file"
        onclick="this.value=null"
        accept=".jpg,.png"
        (change)="enhancementPlaceholderChange(imageInput.files!, data, select)"
      />
      <div
        *ngIf="!isImageLoaded || getLinkImage() == null"
        class="card-item__no-img"
      >
        <img
          (click)="!readonly && data.id != null ? imageInput.click() : ''"
          [ngClass]="!readonly && data.id != null ? 'cursor-pointer' : ''"
          [src]="'assets/image-placeholder.jpg'"
          alt="Image Error"
          [matTooltipDisabled]="readonly"
          matTooltipPosition="above"
          [matTooltip]="
            data.id == null
              ? 'Save Before Uploading Placeholder'
              : 'Add Placeholder'
          "
        />
      </div>
    </div>
  </div>
  <mat-card-content>
    <div>
      <div class="card-item__label">
        <div>
          {{ select }}
        </div>
      </div>
      <div class="card-item__description">
        {{ data.assetName }}
      </div>
    </div>
  </mat-card-content>
  <button
    *ngIf="!readonly"
    matTooltip="Remove Select"
    mat-icon-button
    color="error"
    class="action-btn"
    (click)="remove.emit()"
  >
    <mat-icon>close</mat-icon>
  </button>
</mat-card>
