<div [hidden]="view !== 'list'">
  <div style="overflow-x: auto">
    <table
      mat-table
      [dataSource]="dataSource"
      class="table"
      matSort
      matSortActive="barcode"
      matSortDirection="desc"
    >
      <!-- Check Box Column -->
      <ng-container matColumnDef="Select">
        <th mat-header-cell *matHeaderCellDef style="width: 30px">
          <mat-checkbox
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            color="primary"
          >
          </mat-checkbox>
        </th>

        <td mat-cell *matCellDef="let product">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(product) : null"
            [checked]="selection.isSelected(product)"
            color="primary"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- Barcode Column -->
      <ng-container matColumnDef="barcode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Barcode</th>
        <td mat-cell *matCellDef="let product">{{ product.barcode }}</td>
      </ng-container>

      <!-- Reference Column -->
      <ng-container matColumnDef="reference">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Reference</th>
        <td mat-cell *matCellDef="let product">{{ product.reference }}</td>
      </ng-container>

      <!-- Description Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
        <td mat-cell *matCellDef="let product">
          {{ product.description }}
        </td>
      </ng-container>

      <!-- Master Suite Column -->
      <ng-container matColumnDef="masterSuiteName" *ngIf="isStaff">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Mastersuite (Variant)
        </th>
        <td mat-cell *matCellDef="let product">
          <div *ngIf="product.masterSuiteId != null">
            {{ product.masterSuiteName }}
            {{
              product.settingsVariantName != null
                ? "(" + product.settingsVariantName + ")"
                : ""
            }}
          </div>
        </td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="states" *ngIf="isStaff">
        <th mat-header-cell *matHeaderCellDef style="width: 224px">Status</th>
        <td mat-cell *matCellDef="let product" class="icon-ctn">
          <div style="display: flex; justify-content: space-between">
            <mat-icon
              color="{{ product.states.receivalState }}"
              matTooltip="Received Quantity {{ product.receivedCount }}"
              fontIcon="receipt_long"
            ></mat-icon>
            <mat-icon
              color="{{ product.states.masterSuiteState }}"
              matTooltip="MasterSuite {{
                product.masterSuiteId != null ? 'Applied' : 'Pending'
              }}"
              fontIcon="lightbulb"
            ></mat-icon>
            <mat-icon
              color="{{ product.states.ingestionState }}"
              matTooltip="{{ product.ingestedTouchCount }}/{{
                product.expectedTouchCount
              }} Touches Ingested"
              fontIcon="dataset"
            ></mat-icon>
            <mat-icon
              color="{{ product.states.productionState }}"
              matTooltip="{{ product.shotTouchCount }}/{{
                product.expectedTouchCount
              }} Touches Shot"
              fontIcon="camera_alt"
            ></mat-icon>
            <mat-icon
              color="{{ product.states.enhancementState }}"
              matTooltip="{{ product.postAssetCount }}/{{
                product.expectedPostAssetCount
              }} Assets Enhanced"
              fontIcon="palette"
            ></mat-icon>
            <mat-icon
              mat-icon-button
              color="{{ product.states.reviewState }}"
              matTooltip="{{ product.approvedPostAssetCount }}/{{
                product.expectedPostAssetCount
              }} Assets Approved"
              fontIcon="reviews"
            ></mat-icon>
            <mat-icon
              mat-icon-button
              color="{{ product.states.outputsState }}"
              matTooltip="{{
                product.states.outputsState == progressState.INFO
                  ? 'Outputs Pending Release'
                  : product.releasedInCollection
                  ? 'Outputs Released ' +
                    (product.releasedAt | dateAgo : currentConfigDate | async)
                  : product.approvedOutputAssetCount +
                    '/' +
                    product.expectedOutputAssetCount +
                    ' Completed Outputs'
              }}"
              fontIcon="filter"
            ></mat-icon>
            <mat-icon
              fontIcon="delivery_dining"
              color="{{ product.dispatchedCount > 0 ? 'success' : 'pending' }}"
              matTooltip="Dispatched Quantity {{ product.dispatchedCount }}"
            ></mat-icon>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="status" *ngIf="!isStaff">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          style="width: 200px"
        >
          Status
        </th>
        <td mat-cell *matCellDef="let product">
          <div
            class="status-text"
            [ngClass]="{
                      complete: product.releasedInCollection && product.approvedOutputAssetCount == product.expectedOutputAssetCount && product.expectedOutputAssetCount > 0,
                    }"
          >
            <div class="external-status"></div>
            {{
              product.releasedInCollection &&
              product.approvedOutputAssetCount ==
                product.expectedOutputAssetCount &&
              product.expectedOutputAssetCount > 0
                ? "Complete"
                : "Pending"
            }}
          </div>
        </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef style="width: 150px"></th>
        <td mat-cell *matCellDef="let product">
          <a
            routerLinkActive="active-link"
            routerLink="/batches/{{ product.batchId }}/products/{{
              product.id
            }}"
            [routerLink]="[
              '/batches/' + product.batchId + '/products/' + product.id
            ]"
            [queryParams]="{ collectionId: collectionId }"
            rout
            ><button
              mat-icon-button
              class="mat-elevation-z0"
              matTooltip="Details"
              matTooltipPosition="below"
              color="info"
            >
              <mat-icon
                matBadge="!"
                matBadgeSize="small"
                [matBadgeHidden]="product.notes == null || !isStaff"
                >info</mat-icon
              >
            </button>
          </a>

          <button
            *ngIf="isStaff"
            [matMenuTriggerFor]="actionsMenu"
            mat-icon-button
            matTooltip="More"
            color="primary"
            matTooltipPosition="right"
            class="mat-elevation-z0"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #actionsMenu="matMenu">
            <button
              *ngIf="isStaff"
              mat-menu-item
              (click)="openProductUpdateDialog(product)"
            >
              <mat-icon matTooltip="Edit" color="success">edit</mat-icon>
              <span>Edit</span>
            </button>

            <button
              *ngIf="isStaff"
              [disabled]="product.receivedCount == 0"
              mat-menu-item
              (click)="openProductReceivals(product)"
            >
              <mat-icon matTooltip="View" color="success"
                >receipt_long</mat-icon
              >
              <span>Receivals</span>
            </button>

            <a
              *ngIf="isStaff"
              [disabled]="
                product.productLink == '' || product.productLink == null
              "
              mat-menu-item
              href="{{ product.productLink }}"
              target="_blank"
            >
              <mat-icon matTooltip="View" color="info">link</mat-icon>
              <span>Product Link</span>
            </a>
            <button
              *ngIf="isStaff"
              mat-menu-item
              (click)="openMasterSuite(product)"
              [disabled]="!product.masterSuiteId"
            >
              <mat-icon color="success">auto_fix_high</mat-icon>
              <span>Enhancement Variants</span>
            </button>

            <button
              *ngIf="isAdmin"
              mat-menu-item
              (click)="deleteProduct(product)"
            >
              <mat-icon matTooltip="Delete" color="error">delete</mat-icon>
              <span>Delete</span>
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <!-- Header row second group -->
      <ng-container matColumnDef="filters-row" *ngIf="isStaff">
        <th
          mat-header-cell
          *matHeaderCellDef
          [attr.colspan]="displayedColumns.length"
        >
          <div style="display: flex; align-items: center">
            <app-dynamic-chip-set
              *ngIf="view != 'grid'"
              style="width: 100%"
              [chips]="preDefinedFilters"
              [loading]="productsSubscription && !productsSubscription.closed"
              [selectedChipKey]="selectedfilter"
              (chipSelected)="onPreDefinedFilterChange($event)"
            ></app-dynamic-chip-set>
            <span class="spacer"></span>
            <button
              mat-icon-button
              [matMenuTriggerFor]="filtersMenu"
              matTooltip="Filters"
              matTooltipPosition="above"
              color="primary"
            >
              <mat-icon>{{
                allEmpty(filter) ? "filter_list_off" : "filter_list"
              }}</mat-icon>
            </button>
          </div>
        </th>
      </ng-container>

      <tr
        mat-header-row
        [style]="{
          display: isStaff ? 'contents' : 'none'
        }"
        *matHeaderRowDef="isStaff ? ['filters-row'] : []"
      ></tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="selectRow($event, row)"
      ></tr>
    </table>
  </div>
  <mat-paginator
    [pageSizeOptions]="[10, 20, 50, 100]"
    showFirstLastButtons
    aria-label="Select page of Products"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    (page)="pageNumberChange($event)"
  >
  </mat-paginator>
  <mat-progress-bar
    *ngIf="productsSubscription && !productsSubscription.closed"
    mode="query"
  ></mat-progress-bar>
</div>

<mat-menu #filtersMenu="matMenu" (closed)="filterProducts()">
  <div
    style="width: 350px; padding: 2px 10px !important"
    (click)="$event.stopPropagation()"
  >
    <div style="display: flex; align-items: center; margin: 4px">
      Status Filters
      <span class="spacer"></span>
      <button mat-button class="action-button" (click)="clearFilters()">
        Clear
      </button>
    </div>
    <div class="filter-item">
      <div class="label">Receivals</div>
      <span class="spacer"></span>
      <mat-button-toggle-group
        class="filter-toggle-group"
        [(ngModel)]="filter.receivalState"
        multiple
        hideMultipleSelectionIndicator
      >
        <mat-button-toggle [value]="progressState.PENDING">
          <mat-icon
            color="pending"
            matTooltip="Pending"
            matTooltipPosition="above"
            >receipt_long</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="progressState.ERROR">
          <mat-icon
            color="error"
            matTooltip="Poor Condition"
            matTooltipPosition="above"
            >receipt_long</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="progressState.ACTIVE">
          <mat-icon
            color="warning"
            matTooltip="Okay Condition"
            matTooltipPosition="above"
            >receipt_long</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="progressState.COMPLETE">
          <mat-icon
            color="success"
            matTooltip="Good Condition"
            matTooltipPosition="above"
            >receipt_long</mat-icon
          >
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="filter-item">
      <div class="label">Master Suite</div>
      <span class="spacer"></span>
      <mat-button-toggle-group
        class="filter-toggle-group"
        [(ngModel)]="filter.masterSuiteState"
        multiple
        hideMultipleSelectionIndicator
      >
        <mat-button-toggle [value]="progressState.PENDING">
          <mat-icon
            color="pending"
            matTooltip="Pending"
            matTooltipPosition="above"
            >lightbulb</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="progressState.ACTIVE">
          <mat-icon
            color="warning"
            matTooltip="Pending Settings"
            matTooltipPosition="above"
            >lightbulb</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="progressState.COMPLETE">
          <mat-icon
            color="success"
            matTooltip="Applied"
            matTooltipPosition="above"
            >lightbulb</mat-icon
          >
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="filter-item">
      <div class="label">Ingestion</div>
      <span class="spacer"></span>
      <mat-button-toggle-group
        class="filter-toggle-group"
        [(ngModel)]="filter.ingestionState"
        multiple
        hideMultipleSelectionIndicator
      >
        <mat-button-toggle [value]="progressState.PENDING">
          <mat-icon
            color="pending"
            matTooltip="Pending"
            matTooltipPosition="above"
            >dataset</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="progressState.ACTIVE">
          <mat-icon
            color="warning"
            matTooltip="Partially Ingested"
            matTooltipPosition="above"
            >dataset</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="progressState.COMPLETE">
          <mat-icon
            color="success"
            matTooltip="Ingested"
            matTooltipPosition="above"
            >dataset</mat-icon
          >
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="filter-item">
      <div class="label">Production</div>
      <span class="spacer"></span>
      <mat-button-toggle-group
        class="filter-toggle-group"
        [(ngModel)]="filter.productionState"
        multiple
        hideMultipleSelectionIndicator
      >
        <mat-button-toggle [value]="progressState.PENDING">
          <mat-icon
            color="pending"
            matTooltip="Pending"
            matTooltipPosition="above"
            >camera_alt</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="progressState.ACTIVE">
          <mat-icon
            color="warning"
            matTooltip="Partially Shot"
            matTooltipPosition="above"
            >camera_alt</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="progressState.COMPLETE">
          <mat-icon color="success" matTooltip="Shot" matTooltipPosition="above"
            >camera_alt</mat-icon
          >
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="filter-item">
      <div class="label">Raw Reviews</div>
      <span class="spacer"></span>
      <mat-button-toggle-group
        class="filter-toggle-group"
        [(ngModel)]="filter.productionReviewState"
        multiple
        hideMultipleSelectionIndicator
      >
        <mat-button-toggle [value]="progressState.PENDING">
          <mat-icon
            color="pending"
            matTooltip="Pending"
            matTooltipPosition="above"
            >camera_enhance</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="progressState.ERROR">
          <mat-icon
            color="error"
            matTooltip="Rejected"
            matTooltipPosition="above"
            >camera_enhance</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="progressState.ACTIVE">
          <mat-icon
            color="warning"
            matTooltip="Partially Approved"
            matTooltipPosition="above"
            >camera_enhance</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="progressState.COMPLETE">
          <mat-icon
            color="success"
            matTooltip="Approved"
            matTooltipPosition="above"
            >camera_enhance</mat-icon
          >
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="filter-item">
      <div class="label">Enhancement</div>
      <span class="spacer"></span>
      <mat-button-toggle-group
        class="filter-toggle-group"
        [(ngModel)]="filter.enhancementState"
        multiple
        hideMultipleSelectionIndicator
      >
        <mat-button-toggle [value]="progressState.PENDING">
          <mat-icon
            color="pending"
            matTooltip="Pending"
            matTooltipPosition="above"
            >palette</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="progressState.ERROR">
          <mat-icon
            color="error"
            matTooltip="Internally Rejected"
            matTooltipPosition="above"
            >palette</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="progressState.ACTIVE">
          <mat-icon
            color="warning"
            matTooltip="Partially Enhanced"
            matTooltipPosition="above"
            >palette</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="progressState.COMPLETE">
          <mat-icon
            color="success"
            matTooltip="Enhanced"
            matTooltipPosition="above"
            >palette</mat-icon
          >
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="filter-item">
      <div class="label">Reviews</div>
      <span class="spacer"></span>
      <mat-button-toggle-group
        class="filter-toggle-group"
        [(ngModel)]="filter.reviewState"
        multiple
        hideMultipleSelectionIndicator
      >
        <mat-button-toggle [value]="progressState.PENDING">
          <mat-icon
            color="pending"
            matTooltip="Pending"
            matTooltipPosition="above"
            >reviews</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="progressState.ERROR">
          <mat-icon
            color="error"
            matTooltip="Rejected"
            matTooltipPosition="above"
            >reviews</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="progressState.ACTIVE">
          <mat-icon
            color="warning"
            matTooltip="Partially Approved"
            matTooltipPosition="above"
            >reviews</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="progressState.COMPLETE">
          <mat-icon
            color="success"
            matTooltip="Approved"
            matTooltipPosition="above"
            >reviews</mat-icon
          >
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="filter-item">
      <div class="label">Outputs</div>
      <span class="spacer"></span>
      <mat-button-toggle-group
        class="filter-toggle-group"
        [(ngModel)]="filter.outputsState"
        multiple
        hideMultipleSelectionIndicator
      >
        <mat-button-toggle [value]="progressState.PENDING">
          <mat-icon
            color="pending"
            matTooltip="Pending"
            matTooltipPosition="above"
            >filter</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="progressState.ERROR">
          <mat-icon
            color="error"
            matTooltip="Rejected Assets"
            matTooltipPosition="above"
            >filter</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="progressState.ACTIVE">
          <mat-icon
            color="warning"
            matTooltip="Partially Approved"
            matTooltipPosition="above"
            >filter</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="progressState.COMPLETE">
          <mat-icon
            color="success"
            matTooltip="Approved"
            matTooltipPosition="above"
            >filter</mat-icon
          >
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="filter-item">
      <div class="label">Dispatched</div>
      <span class="spacer"></span>
      <mat-button-toggle-group
        class="filter-toggle-group"
        [(ngModel)]="filter.dispatchState"
        multiple
        hideMultipleSelectionIndicator
      >
        <mat-button-toggle [value]="progressState.PENDING">
          <mat-icon
            color="pending"
            matTooltip="Pending"
            matTooltipPosition="above"
            >delivery_dining</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="progressState.COMPLETE">
          <mat-icon
            color="success"
            matTooltip="Dispatched"
            matTooltipPosition="above"
            >delivery_dining</mat-icon
          >
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div style="display: flex; justify-content: end">
      <button
        mat-raised-button
        color="buttonSuccess"
        class="action-button"
        (click)="filterProducts()"
      >
        Apply
      </button>
    </div>
  </div>
</mat-menu>
