<h1 mat-dialog-title>Group Ingest</h1>
<mat-dialog-content>
    <div style="padding-bottom: 10px;">{{message}}</div>
    <div *ngIf="multipleProducts">
        <table mat-table [dataSource]="dataSource" class="table">
            <!-- Barcode Column -->
            <ng-container matColumnDef="barcode">
                <th mat-header-cell *matHeaderCellDef> Barcode </th>
                <td mat-cell *matCellDef="let product"> {{product.barcode}} </td>
            </ng-container>

            <!-- Reference Column -->
            <ng-container matColumnDef="reference">
                <th mat-header-cell *matHeaderCellDef> Reference </th>
                <td mat-cell *matCellDef="let product"> {{product.reference}} </td>
            </ng-container>

            <!-- Description Column -->
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef> Description </th>
                <td mat-cell *matCellDef="let product"> {{product.description}} </td>
            </ng-container>

            <!-- Description Column -->
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let product">
                    <button mat-raised-button color="primary" (click)="selectProductMaster(product)">
                        Select
                    </button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</mat-dialog-content>
<mat-dialog-actions style="justify-content: end;">
    <button mat-stroked-button (click)="onNoClick()">Cancel</button>
    <button mat-raised-button [disabled]="invalidSelection" (click)="onYesClick()" [color]="state">Yes</button>
</mat-dialog-actions>