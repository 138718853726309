<div class="content-list content">
  <div class="content-list__head">
    <div class="title">{{ title }}</div>
    <span class="spacer"></span>

    <mat-form-field appearance="outline" style="width: 20%">
      <mat-label>Search</mat-label>
      <input matInput type="text" (keyup)="applyFilter($event)" />
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
  </div>

  <div class="break">
    <button
      mat-fab
      extended
      [color]="'buttonSuccess'"
      routerLink="/organizations/create"
      class="button"
    >
      <mat-icon>add</mat-icon>
      Create
    </button>
  </div>
  <div class="content-container">
    <div class="content-list__list">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="profileImage">
          <th mat-header-cell *matHeaderCellDef style="width: 52px"></th>
          <td mat-cell *matCellDef="let organization">
            <div
              class="imageContainer"
              routerLink="/organizations/{{ organization.id }}/update"
            >
              <img
                class="image"
                *ngIf="organization.profileImageUrl !== null; else placeholder"
                [src]="apiBase + organization.profileImageUrl | secure | async"
                alt=""
              />
              <ng-template #placeholder>
                <img
                  class="image"
                  src="./assets/organization-placeholder.jpg"
                />
              </ng-template>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let organization">
            {{ organization.name }}
          </td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef style="width: 150px"></th>
          <td mat-cell *matCellDef="let organization">
            <button
              [matMenuTriggerFor]="actionsMenu"
              mat-icon-button
              matTooltip="More"
              color="primary"
              class="mat-elevation-z0"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #actionsMenu="matMenu">
              <button
                mat-menu-item
                routerLink="/organizations/{{ organization.id }}/update"
              >
                <mat-icon color="success">edit</mat-icon>
                <span>Edit</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <mat-paginator
      class="mat-paginator-sticky"
      [pageSizeOptions]="[10, 20, 50, 100]"
      showFirstLastButtons
      [pageSize]="currentConfigDate.pageSize"
      (page)="setPageSizePreference($event)"
    >
    </mat-paginator>
  </div>
</div>
