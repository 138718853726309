<mat-dialog-title class="title">Update Filename Format</mat-dialog-title>

<mat-dialog-content>
  <form [formGroup]="filenamesForm">
    <div>
      <mat-form-field appearance="outline" color="primary" class="dialog-input">
        <mat-label>From asset</mat-label>
        <input
          matInput
          type="number"
          step="1"
          formControlName="from"
          [min]="1"
          (change)="updateValidtors()"
          (keypress)="onlyPositiveIntegers($event)"
        />
        <mat-error *ngIf="getControlFormInArray('from')?.hasError('required')">
          Range from is required. Enter 1 for all assets
        </mat-error>
        <mat-error *ngIf="getControlFormInArray('from')?.hasError('min')">
          Minimum value is 1
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" color="primary" class="dialog-input">
        <mat-label>To asset</mat-label>
        <input
          matInput
          type="number"
          step="1"
          formControlName="to"
          [min]="1"
          (keypress)="onlyPositiveIntegers($event)"
        />
        <mat-icon
          matSuffix
          matTooltip="Leave the field empty to indicate unbounded range"
          matTooltipPosition="above"
        >
          info_outline
        </mat-icon>
        <mat-error *ngIf="getControlFormInArray('to')?.hasError('min')">
          Minimum value is 1
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" color="primary" class="dialog-input">
        <mat-label>Index offset</mat-label>
        <input matInput type="number" step="1" formControlName="indexOffset" />
        <mat-error
          *ngIf="getControlFormInArray('indexOffset')?.hasError('min')"
        >
          Offset places From range below 0 (
          {{
            getControlFormInArray("from").value +
              getControlFormInArray("indexOffset").value
          }})
        </mat-error>
      </mat-form-field>
    </div>

    <app-chip-autocomplete
      [allChips]="fileNameTokens"
      [formCtrl]="$any(filenamesForm).controls['tokenFormats']"
    ></app-chip-autocomplete>

    <div class="delete">
      <mat-button-toggle-group formControlName="casing">
        <mat-button-toggle [value]="casing.MATCH" matTooltip="Casing As is">
          <span class="material-symbols-outlined">match_case</span>
        </mat-button-toggle>
        <mat-button-toggle [value]="casing.UPPER" matTooltip="All Uppercase">
          <span class="material-symbols-outlined">uppercase</span>
        </mat-button-toggle>
        <mat-button-toggle [value]="casing.LOWER" matTooltip="All Lowercase">
          <span class="material-symbols-outlined">lowercase</span>
        </mat-button-toggle>
      </mat-button-toggle-group>
      <span class="spacer"></span>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="actions">
  <button mat-stroked-button (click)="onClose()">Cancel</button>
  <button mat-stroked-button color="success" (click)="onSubmit()">Save</button>
</mat-dialog-actions>
