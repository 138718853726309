import { Component, OnInit } from '@angular/core';
import Chart from 'chart.js/auto';
import { AuthService } from 'src/app/services/auth.service';
import { BatchesService } from 'src/app/services/batches.services';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent implements OnInit {
  public chart: any;
  firstLoad = true;
  chartRef!: Chart<'bar', any, string>;
  isStaff: boolean = false;
  aspectRatio: number = 5;

  constructor(
    private menuService: MenuService,
    private batchesService: BatchesService,
    private authService: AuthService
  ) {}
  ngOnInit(): void {
    this.isStaff = this.authService.getRole('staff');
    this.createChart();
  }

  ngAfterViewInit(): void {
    this.menuService.currentMenuStatus.subscribe((status) => {
      if (this.firstLoad) {
        this.firstLoad = false;
        return;
      }
      let chartCtn = document.getElementById('chartCtn');
      if (chartCtn) {
        chartCtn.style.height = chartCtn.clientWidth / this.aspectRatio + 'px';
      }
    });
  }

  createChart() {
    let data: any = {
      pending: [],
      active: [],
      rejected: [],
      complete: [],
    };
    let labels: string[] = [];
    let status: ('pending' | 'active' | 'rejected' | 'complete')[] = [
      'pending',
      'active',
      'rejected',
      'complete',
    ];
    this.batchesService.listBatches(this.isStaff, 5).subscribe((batches) => {
      if (batches.length === 0) {
        return;
      }
      let barThickness = 25;
      switch (true) {
        case window.innerWidth > 1400:
          barThickness = 25;
          break;
        case window.innerWidth > 1200:
          barThickness = 20;
          break;
        case window.innerWidth > 900:
          barThickness = 15;
          break;
        case window.innerWidth > 600:
          barThickness = 10;
          break;
        default:
          barThickness = 5;
          break;
      }
      switch (batches.length) {
        case 5:
          this.aspectRatio = 6;
          break;
        case 4:
          this.aspectRatio = 8;
          break;
        case 3:
          this.aspectRatio = 10;
          break;
        case 2:
          this.aspectRatio = 12;
          break;
        default:
          this.aspectRatio = 15;
          break;
      }
      batches.forEach((batch) => {
        let total = batch.productCount;
        data['complete'].push((batch.productsReleasedCount * 100) / total);
        data['pending'].push(
          ((total - batch.productsReleasedCount) * 100) / total
        );
        labels.push(batch.name);
      });
      this.chartRef = new Chart('myBarChart', {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Completed',
              data: data.complete,
              backgroundColor: '#1AA367',
              barThickness: barThickness,
            },
            {
              label: 'Active',
              data: data.active,
              backgroundColor: '#FFBE21',
              barThickness: barThickness,
            },
            {
              label: 'Rejected',
              data: data.rejected,
              backgroundColor: '#DD2C00',
              barThickness: barThickness,
            },
            {
              label: 'Pending',
              data: data.pending,
              backgroundColor: '#858C94',
              barThickness: barThickness,
            },
          ],
        },
        options: {
          aspectRatio: this.aspectRatio,
          indexAxis: 'y',
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              yAlign: 'bottom',
              xAlign: 'left',
              bodyAlign: 'center',
              callbacks: {
                label: function (context) {
                  switch (context.dataset.label) {
                    case 'Completed':
                      return `Complete: ${
                        batches[context.dataIndex].productsReleasedCount
                      }`;
                    case 'Active':
                      return `Active: ${
                        batches[context.dataIndex].clientReview?.active
                      }`;
                    case 'Rejected':
                      return `Rejected: ${
                        batches[context.dataIndex].clientReview?.rejected
                      }`;
                    default:
                      return `Pending: ${
                        batches[context.dataIndex].productCount -
                        batches[context.dataIndex].productsReleasedCount
                      }`;
                  }
                },
              },
            },
          },
          scales: {
            x: {
              stacked: true,
              ticks: {
                callback: (value) => {
                  return value + '%';
                },
              },
              max: 100,
            },
            y: {
              stacked: true,
              ticks: {
                callback: (value, index) => {
                  if (
                    (batches[index]?.jobNumber + ': ' + batches[index]?.name)
                      .length < 20
                  ) {
                    return (
                      batches[index]?.jobNumber + ': ' + batches[index]?.name
                    );
                  }
                  return (
                    (
                      batches[index]?.jobNumber +
                      ': ' +
                      batches[index]?.name
                    ).substring(0, 17) + '...'
                  );
                },
              },
            },
          },
        },
      });
    });
  }

  onResize(event: any) {
    let chartCtn = document.getElementById('chartCtn');
    if (chartCtn) {
      chartCtn.style.height = chartCtn.clientWidth / this.aspectRatio + 'px';
      switch (true) {
        case window.innerWidth > 1400:
          this.chartRef.data.datasets.forEach((asset) => {
            asset.barThickness = 25;
          });
          break;
        case window.innerWidth > 1200:
          this.chartRef.data.datasets.forEach((asset) => {
            asset.barThickness = 20;
          });
          break;
        case window.innerWidth > 900:
          this.chartRef.data.datasets.forEach((asset) => {
            asset.barThickness = 12;
          });
          break;
        case window.innerWidth > 600:
          this.chartRef.data.datasets.forEach((asset) => {
            asset.barThickness = 10;
          });
          break;
        default:
          this.chartRef.data.datasets.forEach((asset) => {
            asset.barThickness = 5;
          });
          break;
      }
    }
  }
}
