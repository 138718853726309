import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  EnhancementVariantCreate,
  EnhancementVariantDetail,
  EnhancementVariantPaging,
  IMasterSuite,
  MasterSuiteEnhancementVariantAssetsFile,
  IMasterSuiteTouch,
  IMasterSuiteTouchAsset,
  ISettingsVariant,
} from '../models/mastersuites/mastersuite';
import { IProduct } from '../models/products/product';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class MasterSuitesService {
  private masterSuite$ = new BehaviorSubject<IMasterSuite | any>({});
  public currentMasterSuite = this.masterSuite$.asObservable();
  constructor(private http: HttpClient) {}

  updateMasterSuiteState(masterSuite: IMasterSuite) {
    this.masterSuite$.next(masterSuite);
  }

  listMasterSuites(orgId: string | null): any {
    var url = `${environment.apiBase}/master-suites?organizationId=${orgId}`;
    console.log('Getting MasterSuites');
    return this.http.get<IMasterSuite[]>(url, httpOptions);
  }

  createMasterSuite(newMasterSuite: IMasterSuite): Observable<any> {
    var url = `${environment.apiBase}/master-suites`;
    console.log('Creating New Master Suite: ', newMasterSuite);
    return this.http.post<IMasterSuite>(url, newMasterSuite, httpOptions);
  }

  updateMasterSuite(masterSuite: IMasterSuite): Observable<{
    masterSuite: IMasterSuite;
    productsAffected: IProduct[];
  }> {
    var url = `${environment.apiBase}/master-suites/${masterSuite.id}`;
    console.log('Updating Master Suite: ', masterSuite);
    return this.http.put<{
      masterSuite: IMasterSuite;
      productsAffected: IProduct[];
    }>(url, masterSuite, httpOptions);
  }
  deleteMasterSuite(masterSuite: IMasterSuite): Observable<any> {
    console.log('Delete MasterSuite: ', masterSuite);
    var url = `${environment.apiBase}/master-suites/${masterSuite.id}`;
    return this.http.delete<any>(url, httpOptions);
  }

  detailMasterSuite(masterSuiteId: string): Observable<IMasterSuite> {
    var url = `${environment.apiBase}/master-suites/${masterSuiteId}`;
    console.log('Getting MasterSuite Details: ', masterSuiteId);
    return this.http.get<IMasterSuite>(url, httpOptions);
  }

  /*
  ACTIONS
  */

  masterSuiteTouchAssetEnhancementPlaceholderUpload(
    masterSuiteEnhancementVariantAssetId: string,
    file: File
  ) {
    var url = `${environment.apiBase}/enhancement-variant-assets/${masterSuiteEnhancementVariantAssetId}/placeholder`;
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<MasterSuiteEnhancementVariantAssetsFile>(
      url,
      formData
    );
  }
  masterSuiteTouchAssetShootPlaceholderUpload(
    masterSuiteTouchAssetId: string,
    file: File
  ) {
    var url = `${environment.apiBase}/master-suite-touch-asset/${masterSuiteTouchAssetId}/shoot-placeholder`;
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<IMasterSuiteTouchAsset>(url, formData);
  }

  updateMasterSuiteTouchNotes(id: string, notes: string) {
    var url = `${environment.apiBase}/master-suite-touch/${id}/notes`;
    return this.http.put<IMasterSuiteTouch>(
      url,
      {
        notes: notes,
      },
      httpOptions
    );
  }

  // Settings Variants
  listSettingsVariants(masterSuiteId: string) {
    console.log(masterSuiteId);
    var url = `${environment.apiBase}/master-suite/${masterSuiteId}/settings-variants`;
    console.log('Getting Settings Variants');
    return this.http.get<ISettingsVariant[]>(url, httpOptions);
  }

  createSettingsVariant(
    settingsVariant: ISettingsVariant
  ): Observable<ISettingsVariant> {
    var url = `${environment.apiBase}/master-suite/${settingsVariant.masterSuiteId}/settings-variant`;
    console.log('Creating New Settings Variant: ', settingsVariant);
    return this.http.post<ISettingsVariant>(url, settingsVariant, httpOptions);
  }

  updateSettingsVariant(settingsVariant: ISettingsVariant): Observable<any> {
    var url = `${environment.apiBase}/settings-variant/${settingsVariant.id}`;
    console.log('Updating Settings Variant: ', settingsVariant);
    return this.http.put<ISettingsVariant>(url, settingsVariant, httpOptions);
  }

  deleteSettingsVariant(settingsVariant: ISettingsVariant): Observable<any> {
    var url = `${environment.apiBase}/settings-variant/${settingsVariant.id}`;
    console.log('Updating Settings Variant: ', settingsVariant);
    return this.http.delete<ISettingsVariant>(url, httpOptions);
  }

  // Enhancement Variants

  createEnhancementVariant(
    data: {
      name: string;
      assets: {
        masterSuiteTouchAssetId: string;
        index: number;
      }[];
    },
    masterSuiteId: string
  ) {
    let url = `${environment.apiBase}/master-suite/${masterSuiteId}/enhancement-variants`;
    return this.http.post<EnhancementVariantCreate>(url, data);
  }
  updateEnhancementVariant(
    data: {
      name: string;
      assets: {
        masterSuiteTouchAssetId: string;
        index: number;
      }[];
    },
    enhancementVariantId: string
  ) {
    let url = `${environment.apiBase}/enhancement-variants/${enhancementVariantId}`;
    return this.http.put<any>(url, data);
  }

  getEnhancementVariantOfMasterSuite(params: {
    MasterSuiteId: string;
    Name?: string;
    PageSize?: number;
    PageNumber?: number;
  }) {
    let url = `${environment.apiBase}/enhancement-variants/filter`;
    return this.http.get<EnhancementVariantPaging>(url, { params: params });
  }

  deleteEnhancementVariantById(enhancementVariantId: string) {
    let url = `${environment.apiBase}/enhancement-variants/${enhancementVariantId}`;
    return this.http.delete<any>(url);
  }

  getEnhancementVariantById(enhancementVariantId: string) {
    let url = `${environment.apiBase}/enhancement-variants/${enhancementVariantId}`;
    return this.http.get<EnhancementVariantDetail>(url);
  }

  updateEnhancementVariantAssetsIndex(
    data: {
      updateId: string;
      startId?: string;
      endId?: string;
    },
    enhancementVariantId: string
  ) {
    let url = `${environment.apiBase}/enhancement-variants/${enhancementVariantId}/enhancement-variant-assets/order`;
    return this.http.post<any>(url, data);
  }

  applyEnhancementVariantForProductsAfterCreated(enhancementVariantId: string) {
    let url = `${environment.apiBase}/master-suite/actions/apply-enhancement-variant-after-created/${enhancementVariantId}`;
    return this.http.patch<EnhancementVariantCreate>(url, null);
  }

  getAllEnhancementVariantOfMasterSuite(masterSuiteId: string) {
    let url = `${environment.apiBase}/master-suite/${masterSuiteId}/enhancement-variants`;
    return this.http.get<EnhancementVariantDetail[]>(url);
  }
}
