<h1 mat-dialog-title class="title-warning">{{ data.header }}</h1>
<mat-dialog-content>{{ data.content }}</mat-dialog-content>
<mat-dialog-actions style="justify-content: end">
  <button mat-dialog-close mat-stroked-button *ngIf="data.showBtnNo">
    {{ data.titleButtonNo || "No" }}
  </button>
  <button mat-stroked-button cdkFocusInitial (click)="onOk()">
    {{ data.titleButtonOk || "OK" }}
  </button>
</mat-dialog-actions>
