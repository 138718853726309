import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subject, takeUntil, finalize } from 'rxjs';
import { DeleteModalComponent } from 'src/app/components/shared/delete-modal/delete-modal.component';
import { IBatch } from 'src/app/models/batches/batch';
import { IUser } from 'src/app/models/users/user';
import { OrganizationsService } from 'src/app/services/organizations.service';
import { UsersService } from 'src/app/services/users.service';
import { environment } from 'src/environments/environment';
import { UserCreateComponent } from '../user-create/user-create.component';
import { UserUpdateComponent } from '../user-update/user-update.component';
import { AuthService } from 'src/app/services/auth.service';
import { IPreference, IUserProfile } from 'src/app/models/users/userProfile';
import { SignalrService } from 'src/app/services/signalr.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit, OnDestroy {
  apiBase = environment.apiBase;
  private destroy$ = new Subject();

  // Service Constructor
  constructor(
    private router: Router,
    private usersService: UsersService,
    public dialog: MatDialog,
    public _alert: MatSnackBar,
    private authService: AuthService,
    private signalrService: SignalrService
  ) {}
  users: IUser[] = [];
  loading: boolean = true;
  serverErrorMessage: String = '';
  currentConfigDate!: IPreference;
  user!: IUserProfile | null;
  dataSource = new MatTableDataSource<IUser>();
  filter: any;
  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toUpperCase();
  }
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  displayedColumns: string[] = [
    'profileImage',
    'name',
    'emailAddress',
    'groups',
    'createdAt',
    'actions',
  ];

  ngOnInit() {
    this.user = this.authService.getAuthInformation();
    this.listUsers();
    this.authService.currentPreference.subscribe((res) => {
      this.currentConfigDate = res;
    });
    this.signalrService.connectState.subscribe({
      next: (data: boolean) => {
        if (data) {
          this.joinGroups();
        }
      },
    });
    this.subscribeToData();
  }

  listUsers() {
    this.users = [];
    this.dataSource = new MatTableDataSource<IUser>(this.users);
    this.usersService
      .listUsers()
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: (users: IUser[]) => {
          if (users) {
            console.log('Users Found: ', users);
            this.users = users;
            this.dataSource = new MatTableDataSource<IUser>(this.users);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.users.forEach((user: IUser) => {
              this.parseUser(user);
            });
          }
        },
        error: (error: any) => {
          this.serverErrorMessage =
            'There was an error loading the Users from the server. Please restart the system and try again';
        },
      });
  }

  parseUser(user: IUser) {
    user._groups = [];
    user.groups.forEach((group: string) => {
      if (group.includes('superAdmins')) {
        user._groups.push('Super Admin');
      } else if (group.includes('imageryTeam')) {
        user._groups.push('Imagery Team Member');
      } else if (group.includes('skyTeam')) {
        user._groups.push('SkyTeam Member');
      } else if (group.includes('localClientUsers')) {
        user._groups.push('Robot User');
      } else if (group.includes('localSetupClient')) {
        user._groups.push('Setup Admin');
      } else if (group.includes('orgUsers')) {
        user._groups.push('Organization User');
        user._groups.push(user.organizationName!);
      }
    });
  }

  updateUser(user: IUser) {
    const dialogRef = this.dialog.open(UserUpdateComponent, {
      data: user,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  deleteUser(user: IUser) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      data: {
        header: `Delete User: ${user.name}`,
        message:
          'Are you sure you want to delete this user, action cannot be un-done?',
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.confimDelete(user);
      }
    });
  }

  confimDelete(user: IUser) {
    this.loading = true;
    this.usersService
      .deleteUser(user)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: (data) => {
          this._alert.open('Successfully Deleted', 'close', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 2000,
            panelClass: ['success'],
          });
          // Fetch New Products
          this.listUsers();
        },
        error: (error: any) => {
          this.serverErrorMessage =
            'There was an error delete the batch from the server. Please restart the system and try again';
        },
      });
  }

  createUser() {
    const dialogRef = this.dialog.open(UserCreateComponent);
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      console.log("Refresh user list")
      this.listUsers();
    });
  }

  listGroup: string = '';
  joinGroups() {
    this.signalrService
      .joinStaticGroup('JoinUsersListGroup')
      ?.then((group: string) => (this.listGroup = group));
  }

  subscribeToData() {
    this.signalrService.usersData.subscribe({
      next: (data: any) => {
        if (data.id) {
          var index = this.users.findIndex((p) => p.id == data.id);
          if (index >= 0) {
            this.parseUser(data);
            this.users[index] = data;
            this.dataSource.data = this.users;
          } else {
            this.users.push(data);
            this.dataSource.data = this.users;
          }
        }
      },
    });
  }

  setPageSizePreference(event: any) {
    if (event.pageSize != this.currentConfigDate.pageSize) {
      this.currentConfigDate.pageSize = event.pageSize;
      let payload = {
        name: this.user?.name,
        preference: this.currentConfigDate,
      };
      this.authService.updatePreference(payload).subscribe((res) => {
        console.log(res);
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.signalrService.leaveGroup(this.listGroup);
  }
}
