import { TokenFormat } from 'src/app/models/organizations/form-setting';

export const filenameToken: TokenFormat[] = [
  {
    label: '$(barcode)',
    value: '{barcode}',
  },
  {
    label: '$(reference)',
    value: '{reference}',
  },
  {
    label: '$(File extension)',
    value: '{extension}',
  },
  {
    label: '$(Asset index)',
    value: '{index}',
  },
  {
    label: '$(Alphabetical index)',
    value: '{alphabetical-index}',
  },
];

export const formats = {
  image: [
    {
      label: 'PNG',
      value: 'Png',
    },
    {
      label: 'JPG',
      value: 'Jpg',
    },
    {
      label: 'TIFF',
      value: 'Tiff',
    },
    {
      label: 'WebP',
      value: 'WebP',
    },
  ],
  video: [
    {
      label: 'Mp4 H.264',
      value: 'Mp4H264',
    },
    {
      label: 'Mp4 H.265',
      value: 'Mp4H265',
    },
    {
      label: 'Mov H.265',
      value: 'MovH265',
    },
    {
      label: 'Mov H.264',
      value: 'MovH264',
    },
    {
      label: 'WebM',
      value: 'WebM',
    },
    {
      label: 'Gif',
      value: 'Gif',
    },
  ],
};
