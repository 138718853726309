import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsService } from 'src/app/services/products.service';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { EAssetType, IAsset, EStateAsset } from 'src/app/models/products/asset';
import { SignalrService } from 'src/app/services/signalr.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { WarningModalComponent } from 'src/app/components/shared/warning-modal/warning-modal.component';

enum EChannel {
  INTERNAL,
  EXTERNAL,
}
@Component({
  selector: 'app-review-actions',
  templateUrl: './review-actions.component.html',
  styleUrls: ['./review-actions.component.scss'],
})
export class ReviewActionsComponent implements OnInit, OnChanges {
  @Input() disabled!: boolean;
  @Input() assetDetail!: IAsset;
  @Output() onReload = new EventEmitter();
  assetState: EStateAsset = EStateAsset.IN_REVIEW;
  eState = EStateAsset;
  textMapping: any = {
    [EStateAsset.IN_REVIEW]: 'Approve',
    [EStateAsset.APPROVED]: 'Approved',
    [EStateAsset.REJECTED]: 'Rejected',
  };
  isExternal = false;
  isStaff = false;
  status: any = '';
  private destroy$ = new Subject();
  private groupName?: string;
  assetType = 'shoot-asset';

  constructor(
    private signalrService: SignalrService,
    private productService: ProductsService,
    private authService: AuthService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public _alert: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.isStaff = !this.authService.getRole('client');
    this.initialData();
    if (this.assetType == EAssetType.SHOOT_ASSETS) {
      this.productService.setPostAssetState(EChannel.INTERNAL);
    }
    this.productService.postAssetState.subscribe({
      next: (data: EChannel) => {
        this.isExternal = data === EChannel.EXTERNAL;
        this.signalrService.leaveGroup(this.groupName!);
        this.joinGroup();
        this.updateAssetState(this.assetDetail);
      },
    });
    this.signalrService.connectState.subscribe({
      next: (data: boolean) => {
        if (data) {
          this.joinGroup();
          this.productService.setConnectionId(this.signalrService.connectionId);
        }
      },
    });
    this.signalrService.assetData.subscribe({
      next: (data: IAsset) => {
        if (this.assetDetail.id === data.id) {
          this.updateAssetState(data);
          this.assetDetail = data;
        }
      },
    });
  }

  ngOnChanges(): void {
    this.initialData();
  }

  initialData() {
    this.assetType = this.route.snapshot.paramMap.get('assetType') as string;
    this.updateAssetState(this.assetDetail);
  }

  updateAssetState(data: any) {
    this.assetState =
      (this.isExternal ? data.clientReviewState : data.internalReviewState) ||
      EStateAsset.IN_REVIEW;
  }

  private joinGroup() {
    if (this.assetType === EAssetType.SHOOT_ASSETS) {
      this.signalrService
        .joinShootAssetGroup('JoinShootAssetReviewGroup', this.assetDetail.id)
        ?.then((group: string) => (this.groupName = group));
    } else {
      this.signalrService
        .joinPostAssetGroup(
          'JoinPostAssetReviewGroup',
          this.assetDetail.id,
          this.isExternal ? 'EXTERNAL' : 'INTERNAL'
        )
        ?.then((group: string) => (this.groupName = group));
    }
  }

  checkReject() {
    if (
      this.assetType === EAssetType.SHOOT_ASSETS &&
      this.assetDetail.isEnhanced
    ) {
      const dialogRef = this.dialog.open(WarningModalComponent, {
        data: {
          header: 'Warning',
          content:
            'This shoot asset has been enhanced, rejecting will also mark any post assets as rejected',
          titleButtonOk: 'Continue',
          titleButtonNo: 'Cancel',
          showBtnNo: true,
        },
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.onReview(this.eState.REJECTED);
        }
      });
    } else {
      this.onReview(this.eState.REJECTED);
    }
  }

  onReview(state: EStateAsset | null) {
    const lastState = this.assetState;
    this.assetState = state == null ? this.eState.IN_REVIEW : state;
    if (this.assetType === EAssetType.SHOOT_ASSETS) {
      this.productService
        .reviewShootAsset(this.assetDetail.id, state)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            this.onReload.emit();
          },
          error: (e) => {
            this.assetState = lastState;
            this.sendAlert(
              e?.error?.validationErrors[0]?.message,
              3000,
              'error'
            );
          },
        });
    } else {
      this.productService
        .reviewPostAsset(this.assetDetail.id, !this.isExternal, state)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            this.onReload.emit();
          },
          error: (e) => {
            this.assetState = lastState;
            this.sendAlert(
              e?.error?.validationErrors[0]?.message,
              3000,
              'error'
            );
          },
        });
    }
  }

  sendAlert(message: string, duration: number, alertType: string): void {
    this._alert.open(message, 'close', {
      duration: duration,
      horizontalPosition: 'center',
      panelClass: [alertType],
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.signalrService.leaveGroup(this.groupName!);
    this.signalrService.clearData();
  }
}
