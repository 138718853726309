import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { EStateAsset, IAsset } from 'src/app/models/products/asset';
import { ProductsService } from 'src/app/services/products.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-review-modal',
  templateUrl: './review-modal.component.html',
  styleUrls: ['./review-modal.component.scss'],
})
export class ReviewModalComponent {
  asset!: IAsset;
  state: EStateAsset | null = null;
  @Output() reviewClicked = new EventEmitter<any>();
  @Output() openReviewClicked = new EventEmitter<any>();
  @Output() nextAssetClicked = new EventEmitter<any>();
  @Output() previousAssetClicked = new EventEmitter<any>();
  EStateAsset = EStateAsset;
  isImageLoaded: boolean = false;
  fullscreen: boolean = false;
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ReviewModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.initialData(data);
  }

  initialData(data: any) {
    this.asset = data.asset;

    if (data.type == 'shootAssets') {
      this.state = this.asset.internalReviewState!;
    } else if (data.type == 'postAssets') {
      this.state = this.asset.clientReviewState!;
    }
  }
  loadImage() {
    this.isImageLoaded = true;
  }

  getLinkImage(url: string) {
    return ProductsService.getLinkImage(url);
  }

  openReview() {
    this.openReviewClicked.emit({
      id: this.asset.productTouchAssetId,
      masterSuiteTouchAssetId: this.asset.masterSuiteTouchAssetId,
    });
  }

  onReview(state: EStateAsset | null) {
    this.reviewClicked.emit({
      state: state,
      asset: this.asset,
    });
    this.state = state;
  }

  @HostListener('document:keydown', ['$event'])
  handleKey(event: KeyboardEvent) {
    if (event.key === 'ArrowRight') {
      this.nextAsset();
    }
    if (event.key === 'ArrowLeft') {
      this.previousAsset();
    }
  }
  nextAsset() {
    this.nextAssetClicked.emit({});
  }
  previousAsset() {
    this.previousAssetClicked.emit({});
  }
}
