<mat-card class="review-assets content">
  <div class="review-assets__wrapper">
    <div class="review-assets__title">
      <div class="content-header title">
        {{ title }}
        <button
          matSuffix
          mat-icon-button
          matTooltip="Page Link"
          matTooltipPosition="above"
          (click)="
            this.clipboardService.copyUrlAndTextToClipboard(
              this.urlService.getFullUrl(),
              this.pageLink
            )
          "
        >
          <mat-icon>link</mat-icon>
        </button>
      </div>

      <div>
        <h4>{{ label }}</h4>
        <h4 class="router-link" (click)="onBack()">Back to Product Details</h4>
      </div>
    </div>

    <div class="review-assets__content">
      <div
        class="review-assets__preview"
        [style]="{ width: assetType == 'output-asset' ? '100%' : '' }"
      >
        <div class="review-assets__img">
          <div class="review-assets__version">
            <div class="review-assets__old-version">
              <mat-tab-group
                [selectedIndex]="activeTab"
                (selectedIndexChange)="onChangeVersion($event)"
              >
                <ng-container *ngFor="let tab of assetData; let i = index">
                  <mat-tab>
                    <ng-template mat-tab-label>
                      <span *ngIf="i === 0 && tab.pinnedVersion"
                        >Pinned (v{{ tab.versionNumber }})</span
                      >
                      <span *ngIf="i === 0 && !tab.pinnedVersion"
                        >Latest (v{{ tab.versionNumber }})</span
                      >
                      <span *ngIf="i !== 0">v{{ tab.versionNumber }}</span>
                    </ng-template>
                  </mat-tab>
                </ng-container>
              </mat-tab-group>
            </div>
          </div>
          <div class="review-assets__img-overlay">
            <div class="review-assets__img-content">
              <app-compare-image
                *ngIf="isCompare"
                [src1]="getLinkImage(currentAsset)"
                [src2]="getLinkImage(compareAsset)"
                (stopCompare)="isCompare = false"
                (download)="downLoadImage()"
              ></app-compare-image>
              <div *ngIf="!isCompare" class="review-assets__img-compare">
                <div
                  #assetContainer
                  *ngIf="assetData[0] != undefined"
                  class="review-assets__compare-item"
                >
                  <div
                    *ngIf="activeTab !== 0 && !!assetData[activeTab]"
                    class="review-assets__compare-item-label left"
                  >
                    v{{ assetData[0].versionNumber }}
                  </div>
                  <img
                    #asset
                    [ngClass]="{ blur: drawing }"
                    [style]="{ 'background-image': background() }"
                    *ngIf="
                      assetData[0].type == 'Still' ||
                        assetData[0].reviewUri.includes('gif');
                      else displayVideo
                    "
                    [src]="getLinkImage(currentAsset) | secure | async"
                    [alt]="getLinkImage(currentAsset) | secure | async"
                    (mousedown)="drawing ? startDrawing($event) : ''"
                    (mousemove)="drawing ? keepDrawing($event) : ''"
                    (mouseup)="drawing ? stopDrawing() : ''"
                  />
                  <div
                    *ngIf="box || finalBox"
                    class="box-container"
                    [style]="{
                      'margin-top': checkMargin(false),
                      'margin-bottom': checkMargin(false),
                      'margin-left': checkMargin(true),
                      'margin-right': checkMargin(true)
                    }"
                  >
                    <div
                      *ngIf="box"
                      class="shape"
                      [style]="{
                        left: box.left * 100 + '%',
                        top: box.top * 100 + '%',
                        width: box.width * 100 + '%',
                        height: box.height * 100 + '%'
                      }"
                    ></div>
                    <div
                      *ngIf="
                        drawn && finalBox && assetData[0].id == activeVersionId
                      "
                      class="shape"
                      [style]="{
                        left: finalBox.left * 100 + '%',
                        top: finalBox.top * 100 + '%',
                        width: finalBox.width * 100 + '%',
                        height: finalBox.height * 100 + '%'
                      }"
                    ></div>
                  </div>
                  <button
                    style="position: absolute; left: 1%; top: 1%"
                    *ngIf="
                      activeTab == 0 && assetType == 'output-asset' && isStaff
                    "
                    mat-raised-button
                    [color]="
                      assetData[0].internalReviewState == 'Accepted'
                        ? 'buttonError'
                        : 'buttonSuccess'
                    "
                    (click)="
                      reviewOutputAsset(
                        assetData[0].id,
                        assetData[0].internalReviewState == 'Accepted'
                          ? 'Rejected'
                          : 'Accepted'
                      )
                    "
                  >
                    {{
                      assetData[0].internalReviewState == "Accepted"
                        ? "Reject"
                        : "Approve"
                    }}
                  </button>
                  <div
                    class="asset-actions"
                    *ngIf="assetData[0].type == 'Still'"
                  >
                    <button
                      *ngIf="
                        this.activeTab == 0 &&
                        assetData[this.activeTab].pinnedVersion
                      "
                      mat-icon-button
                      matTooltip="Un-Pin Version"
                      matTooltipPosition="above"
                      (click)="
                        pinAssetVersion(assetData[this.activeTab].id, false)
                      "
                    >
                      <mat-icon> push_pin </mat-icon>
                    </button>
                    <button
                      mat-icon-button
                      matTooltip="Highlight"
                      matTooltipPosition="above"
                      *ngIf="activeTab == 0 && assetType != 'output-asset'"
                      (click)="toggleDrawing()"
                    >
                      <mat-icon *ngIf="!drawing"> rate_review </mat-icon>
                      <mat-icon *ngIf="drawing"> cancel </mat-icon>
                    </button>
                    <button
                      matTooltip="Toggle Transparency"
                      matTooltipPosition="above"
                      mat-icon-button
                      *ngIf="activeTab == 0 && assetType != 'shoot-asset'"
                      (click)="transparentBackground = !transparentBackground"
                    >
                      <mat-icon *ngIf="transparentBackground">
                        invert_colors
                      </mat-icon>
                      <mat-icon *ngIf="!transparentBackground">
                        invert_colors_off
                      </mat-icon>
                    </button>
                    <button
                      matTooltip="Fullscreen"
                      matTooltipPosition="above"
                      mat-icon-button
                      *ngIf="activeTab == 0"
                      (click)="fullscreen = true"
                    >
                      <mat-icon> fullscreen </mat-icon>
                    </button>
                    <button
                      mat-icon-button
                      *ngIf="activeTab == 0"
                      (click)="downLoadImage()"
                      [disabled]="isDownloadFileLoading"
                    >
                      <mat-icon> file_download </mat-icon>
                    </button>
                  </div>

                  <ng-template #displayVideo>
                    <video
                      #asset
                      mat-card-image
                      [src]="getLinkImage(currentAsset) | secure | async"
                      controls
                      autoplay="1"
                    ></video>
                    <div class="asset-actions">
                      <button
                        *ngIf="
                          activeTab == 0 &&
                          assetType == 'output-asset' &&
                          isStaff
                        "
                        mat-raised-button
                        [color]="
                          assetData[0].internalReviewState == 'Accepted'
                            ? 'buttonError'
                            : 'buttonSuccess'
                        "
                        (click)="
                          reviewOutputAsset(
                            assetData[0].id,
                            assetData[0].internalReviewState == 'Accepted'
                              ? 'Rejected'
                              : 'Accepted'
                          )
                        "
                      >
                        {{
                          assetData[0].internalReviewState == "Accepted"
                            ? "Reject"
                            : "Approve"
                        }}
                      </button>
                      <button
                        mat-icon-button
                        *ngIf="activeTab == 0"
                        (click)="downLoadImage()"
                        [disabled]="isDownloadFileLoading"
                      >
                        <mat-icon> file_download </mat-icon>
                      </button>
                    </div>
                  </ng-template>
                </div>
                <div
                  *ngIf="activeTab !== 0 && !!assetData[activeTab]"
                  class="review-assets__compare-item"
                >
                  <div class="review-assets__compare-item-label left">
                    v{{ assetData[activeTab].versionNumber }}
                  </div>
                  <video
                    *ngIf="
                      assetData[activeTab].type !== 'Still';
                      else displayImage
                    "
                    mat-card-image
                    [src]="getLinkImage(compareAsset) | secure | async"
                    controls
                    autoplay="1"
                  ></video>
                  <ng-template #displayImage>
                    <img
                      [src]="getLinkImage(compareAsset) | secure | async"
                      [alt]="getLinkImage(compareAsset) | secure | async"
                    />
                    <div
                      class="box-container"
                      [style]="{
                        'margin-top': checkMargin(false),
                        'margin-bottom': checkMargin(false),
                        'margin-left': checkMargin(true),
                        'margin-right': checkMargin(true)
                      }"
                    >
                      <div
                        *ngIf="
                          drawn &&
                          finalBox &&
                          assetData[activeTab].id == activeVersionId
                        "
                        class="shape"
                        [style]="{
                          left: finalBox.left * 100 + '%',
                          top: finalBox.top * 100 + '%',
                          width: finalBox.width * 100 + '%',
                          height: finalBox.height * 100 + '%'
                        }"
                      ></div>
                    </div>
                  </ng-template>
                  <div class="asset-actions">
                    <button
                      mat-icon-button
                      matTooltip="Pin Version"
                      matTooltipPosition="above"
                      (click)="
                        pinAssetVersion(assetData[this.activeTab].id, true)
                      "
                    >
                      <mat-icon
                        *ngIf="!assetData[this.activeTab].pinnedVersion"
                      >
                        push_pin
                      </mat-icon>
                    </button>

                    <button
                      mat-icon-button
                      *ngIf="activeTab != 0 && assetData[0].type == 'Still'"
                      (click)="compareChange(true)"
                    >
                      <mat-icon> compare </mat-icon>
                    </button>
                    <button
                      mat-icon-button
                      (click)="downLoadImage()"
                      [disabled]="isDownloadFileLoading"
                    >
                      <mat-icon> file_download </mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="review-assets__navigate">
          <app-assets-navigate
            *ngIf="!!productId"
            #assetNavigate
            [productId]="productId"
            [assetType]="assetType"
            [fullscreen]="fullscreen"
            (change)="initialData()"
            (loaded)="onLoadedNavigate($event)"
          ></app-assets-navigate>
        </div>
      </div>
      <div class="review-assets__feedback" *ngIf="assetType != 'output-asset'">
        <div
          class="review-assets__chatbox"
          [ngClass]="{ 'hide-action': !showAction && isExternal }"
        >
          <app-chat-box
            *ngIf="assetData.length > 0 && !!productTouchAssetId"
            [organizationId]="activeAsset.organizationId"
            [productTouchAssetId]="productTouchAssetId"
            [masterSuiteTouchAssetId]="masterSuiteTouchAssetId"
            [currentAsset]="assetData[0]"
            [assetDetail]="assetData[activeTab]"
            [showExternal]="allowExternalComment"
            [commentBoundingBox]="drawing ? finalBox : null"
            (versionSelected)="onOpenVersion($event)"
            (commentHover)="commentHover($event)"
            (clearBoundingBox)="drawing ? toggleDrawing() : ''"
          ></app-chat-box>
        </div>
        <div
          *ngIf="assetData[activeTab] && showAction && !isExternal"
          class="review-assets__actions"
        >
          <app-review-actions
            [disabled]="assetData[0]?.id !== assetData[activeTab]?.id"
            [assetDetail]="assetData[activeTab]"
            (onReload)="onReload()"
          ></app-review-actions>
        </div>
      </div>
    </div>
  </div>
</mat-card>

<app-review-fullscreen
  *ngIf="fullscreen"
  [currentAsset]="getLinkImage(currentAsset)"
  (exit)="fullscreen = false"
></app-review-fullscreen>
