<div class="review-actions">
  <div
    *ngIf="!(isExternal && assetType === 'post-asset' && !isStaff)"
    class="review-actions__header"
  >
    {{ isExternal ? "EXTERNAL" : "INTERNAL" }} STATUS
  </div>
  <div
    class="review-actions__btn"
    [ngClass]="{
      disabled: disabled,
      'no-head': isExternal && assetType === 'post-asset' && !isStaff
    }"
  >
    <button
      *ngIf="assetState !== eState.IN_REVIEW"
      [disabled]="disabled"
      [ngClass]="{
        approved: assetState === eState.APPROVED,
        rejected: assetState === eState.REJECTED
      }"
      class="review-actions__action-state"
      mat-stroked-button
      color="success"
      disabled
    >
      {{ textMapping[assetState] }}
    </button>
    <button
      *ngIf="assetState === eState.IN_REVIEW"
      [disabled]="disabled"
      class="review-actions__action-approve"
      mat-stroked-button
      color="success"
      (click)="onReview(eState.APPROVED)"
    >
      Approve
    </button>
    <button
      *ngIf="assetState === eState.IN_REVIEW"
      [disabled]="disabled"
      class="review-actions__action-reject"
      mat-stroked-button
      color="error"
      (click)="checkReject()"
    >
      Reject
    </button>
    <button
      *ngIf="assetState !== eState.IN_REVIEW"
      [disabled]="disabled"
      class="review-actions__action-clear"
      mat-stroked-button
      color="primary"
      (click)="onReview(null)"
    >
      Clear
    </button>
  </div>
</div>
