<h1 mat-dialog-title style="font-size: 30px;">Batch Creation</h1>
<mat-dialog-content class="mat-typography">
    <div>
        <mat-form-field appearance="outline" color="primary" class="dialog-input">
            <mat-label>Job Number</mat-label>
            <input required [formControl]="jobNumberFormControl" matInput value="{{newBatch.jobNumber}}" [(ngModel)]="newBatch.jobNumber">
            <mat-error *ngIf="validatorProperties.includes('JobNumber')">
                {{validationMessage('JobNumber')}}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline"class="dialog-input">
            <mat-label>Client</mat-label>
            <mat-select required [formControl]="organizationFormControl" [(ngModel)] = "newBatch.organizationId">
                <mat-option *ngFor="let organization of organizations" [value]="organization.id">
                {{organization.name}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="validatorProperties.includes('OrganizationId')">
                {{validationMessage('OrganizationId')}}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" color="primary"class="dialog-input">
            <mat-label>Name</mat-label>
            <input required [formControl]="nameFormControl" matInput value="{{newBatch.name}}" [(ngModel)]="newBatch.name">
            <mat-error *ngIf="validatorProperties.includes('Name')">
                {{validationMessage('Name')}}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" color="primary"class="dialog-input">
            <mat-label>Description</mat-label>
            <input matInput value="{{newBatch.description}}" [(ngModel)]="newBatch.description">
        </mat-form-field>
    </div>  
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button mat-button (click)="createBatch()" #create color="buttonSuccess">Create</button>
</mat-dialog-actions>