import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { HubConnection } from '@microsoft/signalr';
import { IProduct } from '../models/products/product';

@Injectable({
  providedIn: 'root',
})
export class SignalrService {
  connection?: HubConnection;
  private connect$ = new BehaviorSubject<boolean>(false);
  public connectState = this.connect$.asObservable();
  public connectionId = '';

  private notification$ = new BehaviorSubject<any>({});
  public notificationData = this.notification$.asObservable();

  private chatBox$ = new BehaviorSubject<any>({});
  public chatBoxData = this.chatBox$.asObservable();

  private asset$ = new BehaviorSubject<any>({});
  public assetData = this.asset$.asObservable();

  private productsShot$ = new BehaviorSubject<any>({});
  public productsShotData = this.productsShot$.asObservable();

  private productsEnhanced$ = new BehaviorSubject<any>({});
  public productsEnhancedData = this.productsEnhanced$.asObservable();

  private productsReviewed$ = new BehaviorSubject<any>({});
  public productsReviewedData = this.productsReviewed$.asObservable();

  private products$ = new BehaviorSubject<any>({});
  public productsData = this.products$.asObservable();

  private shootAssets$ = new BehaviorSubject<any>({});
  public shootAssetsData = this.shootAssets$.asObservable();

  private postAssets$ = new BehaviorSubject<any>({});
  public postAssetsData = this.postAssets$.asObservable();

  private batches$ = new BehaviorSubject<any>({});
  public batchesData = this.batches$.asObservable();

  private packages$ = new BehaviorSubject<any>({});
  public packagesData = this.packages$.asObservable();

  private settings$ = new BehaviorSubject<any>({});
  public settingsData = this.settings$.asObservable();

  private masterSuites$ = new BehaviorSubject<any>({});
  public masterSuitesData = this.masterSuites$.asObservable();

  private users$ = new BehaviorSubject<any>({});
  public usersData = this.users$.asObservable();

  private organizations$ = new BehaviorSubject<any>({});
  public organizationsData = this.organizations$.asObservable();

  constructor() {}

  clearData() {
    this.products$.next({});
    this.asset$.next({});
    this.chatBox$.next({});
    this.productsShot$.next({});
    this.productsEnhanced$.next({});
    this.productsReviewed$.next({});
    this.batches$.next({});
    this.packages$.next({});
    this.settings$.next({});
    this.masterSuites$.next({});
    this.users$.next({});
    this.organizations$.next({});
  }

  joinStaticGroup(name: string): Promise<string> | undefined {
    try {
      if (!this.connect$.value) {
        console.log('Not Connect yet!');
        return undefined;
      }
      console.log('Joining group', name);
      return this.connection!.invoke(name).then((group: string) => {
        return group;
      });
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  joinIdGroup(name: string, id: string): Promise<string> | undefined {
    try {
      if (!this.connect$.value) {
        console.log('Not Connect yet!');
        return undefined;
      }
      console.log('Joining group', name);
      return this.connection!.invoke(name, id).then((group: string) => {
        return group;
      });
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  joinShootAssetGroup(name: string, id: string): Promise<string> | undefined {
    try {
      if (!this.connect$.value) {
        console.log('Not Connect yet!');
        return undefined;
      }
      console.log('Joining Shoot Asset group', name, id);
      return this.connection!.invoke(name, id).then((group: string) => {
        return group;
      });
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  joinPostAssetGroup(
    name: string,
    id: string,
    commentGroup?: string
  ): Promise<string> | undefined {
    try {
      if (!this.connect$.value) {
        console.log('Not Connect yet!');
        return undefined;
      }
      console.log('Joining Post Asset group', name, id, commentGroup);
      return this.connection!.invoke(name, id, commentGroup).then(
        (group: string) => {
          return group;
        }
      );
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  leaveGroup(group: string) {
    try {
      if (!this.connect$.value) {
        console.log('Not Connect yet!');
        return;
      }
      console.log('Leaving groupName', group);
      this.connection!.invoke('LeaveGroup', group).then((data: any) => {
        //console.log(data);
      });
    } catch (error) {
      console.log(error);
    }
  }

  connect(accessToken: string) {
    const signalR = require('@microsoft/signalr');
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(`${environment.apiBase}/messageHub`, {
        accessTokenFactory: () => accessToken,
      })
      .configureLogging(signalR.LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    this.connection!.start().then(
      () => {
        console.log('SignalR Connected.');
        this.connectionId = (<any>this.connection!).connection.connectionId;
        this.connect$.next(true);
      },
      (error: any) => {
        console.log('Connect Error!', error);
        this.connect$.next(false);
      }
    );

    this.connection!.on('onConnected', (data: any) => {
      console.log('onConnected', data);
    });
    this.connection!.on('onReceivedNotification', (data: any) => {
      console.log('onReceivedNotification', data);
      this.notification$.next(data);
    });

    // DashBoard
    this.connection!.on('onReceivedProductsShot', (data: any) => {
      console.log('onReceivedProductsShot', data);
      this.productsShot$.next(data);
    });
    this.connection!.on('onReceivedProductsEnhanced', (data: any) => {
      console.log('onReceivedProductsEnhanced', data);
      this.productsEnhanced$.next(data);
    });
    this.connection!.on('onReceivedProductsReviewed', (data: any) => {
      console.log('onReceivedProductsReviewed', data);
      this.productsReviewed$.next(data);
    });

    // Product Changes
    this.connection!.on('onReceivedProduct', (data: IProduct) => {
      console.log('onReceivedProduct', data);
      this.products$.next(data);
    });
    // Product Changes
    this.connection!.on(
      'onReceivedProducts',
      (data: { products: IProduct[] }) => {
        console.log('onReceivedProducts', data);
        data.products.forEach((product) => {
          this.products$.next(product);
        });
      }
    );

    this.connection!.on('onReceivedShootAsset', (data: IProduct) => {
      console.log('onReceivedShootAsset', data);
      this.shootAssets$.next(data);
    });

    this.connection!.on('onReceivedPostAsset', (data: IProduct) => {
      console.log('onReceivedPostAsset', data);
      this.postAssets$.next(data);
    });

    // Batch Changes
    this.connection!.on('onReceivedBatch', (data: any) => {
      console.log('onReceivedBatch', data);
      this.batches$.next(data);
    });

    // Package Changes
    this.connection!.on('onReceivedPackage', (data: any) => {
      console.log('onReceivedPackage', data);
      this.packages$.next(data);
    });

    // Settings Changes
    this.connection!.on('onReceivedSetting', (data: any) => {
      console.log('onReceivedSetting', data);
      this.settings$.next(data);
    });

    // Master Suites Changes
    this.connection!.on('onReceivedMasterSuite', (data: any) => {
      console.log('onReceivedMasterSuite', data);
      this.masterSuites$.next(data);
    });

    // User Changes
    this.connection!.on('onReceivedUser', (data: any) => {
      console.log('onReceivedUser', data);
      this.users$.next(data);
    });

    // organizations Changes
    this.connection!.on('onReceivedOrganization', (data: any) => {
      console.log('onReceivedOrganization', data);
      this.organizations$.next(data);
    });

    // Asset Review and Chat box
    this.connection!.on('onReceivedShootAssetComment', (data: any) => {
      console.log('onReceivedShootAssetComment', data);
      this.chatBox$.next(data);
    });
    this.connection!.on('onReceivedPostAssetExternalComment', (data: any) => {
      console.log('onReceivedPostAssetComment', data);
      this.chatBox$.next(data);
    });
    this.connection!.on('onReceivedPostAssetInternalComment', (data: any) => {
      console.log('onReceivedPostAssetComment', data);
      this.chatBox$.next(data);
    });

    this.connection!.on('onReceivedShootAssetReview', (data: any) => {
      console.log('onReceivedShootAssetReview', data);
      this.asset$.next(data);
    });
    this.connection!.on('onReceivedPostAssetInternalReview', (data: any) => {
      console.log('onReceivedPostAssetInternalReview', data);
      this.asset$.next(data);
    });
    this.connection!.on('onReceivedPostAssetExternalReview', (data: any) => {
      console.log('onReceivedPostAssetExternalReview', data);
      this.asset$.next(data);
    });
  }
}
