import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  IComment,
  ICommentPayload,
  ICommentResponse,
  IRequestComment,
} from '../models/comment';
import { DomSanitizer } from '@angular/platform-browser';
import { get } from 'lodash';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class CommentService {
  private avatar$ = new BehaviorSubject<any>({});
  public avatarData = this.avatar$.asObservable();

  connectionId = '';
  httpOptionsSignalR = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
  };

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

  setConnectionId(connectionId: string) {
    this.connectionId = connectionId;
    this.httpOptionsSignalR = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'x-connection-id': this.connectionId,
      }),
    };
  }

  getShootAssetComment(
    productTouchAssetId: string,
    payload: IRequestComment
  ): Observable<ICommentResponse> {
    let url = `${environment.apiBase}/productTouchAssets/${productTouchAssetId}/shootAssets/comments`;
    url += `?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}`;
    console.log('Get Comment for Shoot Asset: ', productTouchAssetId);
    return this.http.get<ICommentResponse>(url, httpOptions);
  }
  sentShootAssetComment(
    productTouchAssetId: string,
    comment: ICommentPayload
  ): Observable<IComment> {
    let url = `${environment.apiBase}/productTouchAssets/${productTouchAssetId}/shootAssets/comments`;
    console.log('Send Comment for Shoot Asset: ', productTouchAssetId);
    return this.http.post<IComment>(url, comment, this.httpOptionsSignalR);
  }

  getPostAssetComment(
    productTouchAssetId: string,
    payload: IRequestComment,
    isInternal: boolean,
    masterSuiteTouchAssetId: string
  ): Observable<ICommentResponse> {
    let url = `${environment.apiBase}/productTouchAssets/${productTouchAssetId}/postAssets/${masterSuiteTouchAssetId}/comments`;
    if (isInternal) {
      url += '/internal';
    }
    url += `?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}`;
    console.log('Get Comment for Post Asset: ', productTouchAssetId);
    return this.http.get<ICommentResponse>(url, httpOptions);
  }
  sentPostAssetComment(
    productTouchAssetId: string,
    comment: ICommentPayload,
    isInternal: boolean
  ): Observable<IComment> {
    let url = `${environment.apiBase}/productTouchAssets/${productTouchAssetId}/postAssets/comments`;
    if (isInternal) {
      url += '/internal';
    }
    console.log('Send Comment for Shoot Asset: ', productTouchAssetId);
    return this.http.post<IComment>(url, comment, this.httpOptionsSignalR);
  }

  filterUserByKey(key: string, organizationId?: string): Observable<any> {
    let url = `${environment.apiBase}/users/filter`;
    url += `?search=${key}`;
    if (organizationId) {
      url += `&organizationId=${organizationId}`;
    }
    return this.http.get<ICommentResponse>(url, httpOptions);
  }

  mappingAvatar(id: string) {
    return get(this.avatar$.value, id, '');
  }

  setAvatar(url: string, data: any) {
    const avatarData = {
      ...this.avatar$.value,
      [url]: data,
    };
    this.avatar$.next(avatarData);
    sessionStorage.setItem('avatar', JSON.stringify(avatarData));
  }

  getAvatar(url: string) {
    return this.http
      .get(url, { responseType: 'blob' })
      .pipe(
        map((val: any) =>
          this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val))
        )
      );
  }
}
