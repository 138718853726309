import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Injector,
  Input,
  OnInit,
  inject,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';

export interface TableComponentTypes {
  tableProps: TableComponentProps;
}
export class TableComponentProps {
  //properties
  datTable: any[] = [];
  headers: TableHeader[] = [];
}
// import the input file

export interface TableHeader {
  field: string;
  header: string;
  body?: any; // this property can contains any component
  columnType?: ColumnType;
}

export type ColumnType = 'text' | 'component';
export type Status = 'active' | 'inactive' | 'pending' | undefined;
@Component({
  selector: 'app-dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrl: './dynamic-table.component.scss',
})
export class DynamicTableComponent implements OnInit, TableComponentTypes {
  // this implements the tableProps property
  @Input() tableProps: TableComponentProps = new TableComponentProps();
  protected displayedColumns: string[] = [];
  // define the injector property (this can be replaced with the constructor)

  ngOnInit(): void {
    // here we define the displayed columns with the fields of the headers
    this.displayedColumns = this.tableProps.headers.map(
      (header: TableHeader) => header.field
    );
  }
}

@Component({
  selector: 'dynamic-table-dialog',
  templateUrl: 'dynamic-table-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicTableDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      content: TableComponentProps;
    }
  ) {
    console.log(this.data);
  }
}
