import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  IMasterSuiteTouchAsset,
  MasterSuiteEnhancementVariantAssetsFile,
} from 'src/app/models/mastersuites/mastersuite';
import { MasterSuitesService } from 'src/app/services/mastersuites.service';
import { ProductsService } from 'src/app/services/products.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-selected-asset-card',
  templateUrl: './selected-asset-card.component.html',
  styleUrl: './selected-asset-card.component.scss',
})
export class SelectedAssetCardComponent {
  @Input() data!: any;
  @Input() readonly = true;
  @Input() select: string = '';
  @Output() remove = new EventEmitter<any>();

  constructor(
    public dialog: MatDialog,
    private masterSuitesService: MasterSuitesService,
    private _alert: MatSnackBar
  ) {
    if (this.getLinkImage == null) {
      this.imageFile = 'assets/image-placeholder.jpg';
    }
  }
  imageFile;
  isImageLoaded = false;
  apiBase = environment.apiBase;

  getLinkImage() {
    var image = this.data.postAssetPlaceholderThumbnailUri !=null ? this.data.postAssetPlaceholderThumbnailUri : this.data.shootAssetPlaceholderThumbnailUri
    return ProductsService.getLinkImage(
      image
    );
  }

  checkImageLoaded() {
    this.isImageLoaded = true;
  }

  enhancementPlaceholderChange(
    fileInputEvent: FileList,
    asset: any,
    select: string
  ) {
    this.isImageLoaded = false;
    var file = fileInputEvent[0];
    this.masterSuitesService
      .masterSuiteTouchAssetEnhancementPlaceholderUpload(
        asset.masterSuiteEnhancementVariantAssetId!,
        file
      )
      .subscribe((touchAsset: MasterSuiteEnhancementVariantAssetsFile) => {
        if (touchAsset) {
          asset.postAssetPlaceholderRawUri = null;
          asset.postAssetPlaceholderReviewUri = null;
          asset.postAssetPlaceholderThumbnailUri = null;

          asset.postAssetPlaceholderRawUri =
            touchAsset.postAssetPlaceholderRawUri;
          asset.postAssetPlaceholderReviewUri =
            touchAsset.postAssetPlaceholderReviewUri;
          asset.postAssetPlaceholderThumbnailUri =
            touchAsset.postAssetPlaceholderThumbnailUri;
          this.isImageLoaded = true;
          this._alert.open(`Uploaded ${select} successfully!`, 'close', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 2000,
            panelClass: ['success'],
          });
        }
      });
  }
}
