<div class="comment-box">
  <div class="comment-box__header">
    <mat-tab-group
      *ngIf="isFirstLoaded"
      [selectedIndex]="currentChannel"
      (selectedTabChange)="onChangeChannel($event)"
    >
      <ng-container *ngIf="!onlyProperties"
        ><ng-container *ngFor="let channel of listChannel">
          <mat-tab [label]="channel.label"> </mat-tab> </ng-container
      ></ng-container>
      <mat-tab label="PROPERTIES"> </mat-tab>
    </mat-tab-group>
  </div>
  <div class="comment-box__list" *ngIf="loading">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <div
    class="comment-box__list"
    style="width: 100%"
    *ngIf="propertiesView && !noMetaData && !loading"
  >
    <ng-container *ngFor="let group of metaDataGroups">
      <table mat-table [dataSource]="group.metaData">
        <ng-container matColumnDef="key">
          <th mat-header-cell *matHeaderCellDef colspan="2">
            <b>{{ group.group }}</b>
          </th>
          <td
            style="max-width: 170px; line-break: anywhere"
            mat-cell
            *matCellDef="let element"
          >
            {{ element.key }}
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="value">
          <td
            style="max-width: 170px; line-break: anywhere"
            mat-cell
            *matCellDef="let element"
          >
            {{ element.value }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="['key']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['key', 'value']"></tr>
      </table>
    </ng-container>
  </div>

  <div
    class="comment-box__list"
    style="width: 100%"
    *ngIf="propertiesView && noMetaData && !loading"
  >
    <div style="display: grid; align-content: center; justify-content: center">
      <div style="margin-bottom: 4px">Asset has no exif data extracted</div>
      <button
        mat-raised-button
        (click)="generateExifData()"
        color="buttonSuccess"
      >
        Extract Exif
      </button>
    </div>
  </div>

  <div
    *ngIf="!propertiesView"
    #listCommentEl
    class="comment-box__list"
    (scroll)="onScroll($event)"
  >
    <div class="comment-box__spacer"></div>
    <ng-container *ngFor="let comment of listComment; let i = index">
      <div class="comment-box__item" [ngClass]="{ first: i === 0 }">
        <div class="comment-box__item-content">
          <app-comment-item
            [id]="comment.id"
            [data]="comment"
            (selected)="versionSelected.emit(comment)"
            (mouseenter)="
              comment.imageReference ? commentHover.emit(comment) : ''
            "
            (mouseleave)="commentHover.emit(null)"
          ></app-comment-item>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="comment-box__input" *ngIf="!propertiesView">
    <form class="example-form">
      <mat-form-field class="example-full-width">
        <mat-icon *ngIf="commentBoundingBox" matPrefix>rate_review</mat-icon>
        <textarea
          [(ngModel)]="commentText"
          [ngModelOptions]="{ standalone: true }"
          matInput
          class="form-control form-control-sm"
          placeholder="Type a comment.."
          cdkTextareaAutosize
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="5"
          [mention]="users"
          [mentionConfig]="{ triggerChar: '@', maxItems: 4, labelKey: 'name' }"
          (searchTerm)="onFilterUser($event)"
          (itemSelected)="onTagged($event)"
          (opened)="setOpenTagger()"
          (closed)="setCloseTagger()"
          (keydown)="triggerFunction($event)"
          (focus)="focusComment(true)"
          (focusout)="focusComment(false)"
        ></textarea>
        <mat-icon matSuffix class="cursor-pointer" (click)="sendComment()"
          >send</mat-icon
        >
      </mat-form-field>
    </form>
  </div>
</div>
