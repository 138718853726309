<div class="assets-navigate">
  <div class="assets-navigate__list" id="navigate" #navigate>
    <ng-container *ngIf="assetType == 'shoot-asset'; else PostAssets">
      <div *ngFor="let touch of listTouch" style="padding: 8px">
        <h3
          class="assets-navigate__label"
          matTooltip="{{ touch.name }}"
          matTooltipPosition="above"
        >
          {{ touch.name }}
        </h3>
        <mat-card class="assets-navigate__list-card">
          <div class="assets-navigate__list-asset">
            <ng-container *ngFor="let asset of touch.assets; let i = index">
              <div [id]="asset.id">
                <div class="flex-row">
                  <div class="assets-navigate__asset-title">
                    {{ asset.label }}
                  </div>
                  <div
                    *ngIf="
                      asset.assetState === eState.APPROVED ||
                      asset.assetState === eState.REJECTED
                    "
                    class="assets-navigate__asset-item-state"
                    [ngClass]="{
                      approved: asset.assetState === eState.APPROVED,
                      rejected: asset.assetState === eState.REJECTED
                    }"
                  ></div>
                </div>
                <div
                  class="assets-navigate__asset-item"
                  [ngClass]="{
                    active: currentMasterSuiteTouchAssetId === asset.masterSuiteTouchAssetId,
                  }"
                  (click)="onSelect(asset, true, touch)"
                >
                  <ng-container *ngIf="!!asset.thumbnailUri; else noImage">
                    <img
                      *ngIf="!asset.id"
                      class="blur"
                      [src]="getLinkImage(asset) | secure | async"
                      [alt]="getLinkImage(asset) | secure | async"
                      matTooltip="Pending"
                      matTooltipPosition="right"
                    />
                    <img
                      *ngIf="!!asset.id"
                      [ngClass]="{ blur: !asset.id,
                    active: currentMasterSuiteTouchAssetId === asset.masterSuiteTouchAssetId,
                  }"
                      [src]="getLinkImage(asset) | secure | async"
                      [alt]="getLinkImage(asset) | secure | async"
                      matTooltipPosition="above"
                    />
                  </ng-container>
                  <ng-template #noImage>
                    <div class="assets-navigate__no-img">
                      <img
                        [src]="'assets/image-placeholder.jpg'"
                        alt="Image Error"
                        matTooltipPosition="above"
                      />
                    </div>
                  </ng-template>
                </div>
              </div>
            </ng-container>
          </div>
        </mat-card>
      </div>
    </ng-container>
    <ng-template #PostAssets>
      <div
        *ngIf="assetType == 'post-asset'; else OutputAssets"
        style="padding: 8px"
      >
        <h3 class="assets-navigate__label" matTooltipPosition="above">
          Selects
        </h3>
        <mat-card class="assets-navigate__list-card">
          <div class="assets-navigate__list-asset">
            <ng-container *ngFor="let asset of listPostAssets; let i = index">
              <div [id]="asset.id">
                <div class="flex-row">
                  <div class="assets-navigate__asset-title">
                    {{ asset.label }}
                  </div>
                  <div
                    *ngIf="
                      asset.assetState === eState.APPROVED ||
                      asset.assetState === eState.REJECTED
                    "
                    class="assets-navigate__asset-item-state"
                    [ngClass]="{
                      approved: asset.assetState === eState.APPROVED,
                      rejected: asset.assetState === eState.REJECTED
                    }"
                  ></div>
                </div>
                <div
                  class="assets-navigate__asset-item"
                  [ngClass]="{
                  active: currentMasterSuiteTouchAssetId === asset.masterSuiteTouchAssetId,
                }"
                  (click)="onSelect(asset, true)"
                >
                  <ng-container *ngIf="!!asset.thumbnailUri; else noImage">
                    <img
                      *ngIf="!asset.id"
                      class="blur"
                      [src]="getLinkImage(asset) | secure | async"
                      [alt]="getLinkImage(asset) | secure | async"
                      matTooltip="Pending"
                      matTooltipPosition="right"
                    />
                    <img
                      *ngIf="!!asset.id"
                      [ngClass]="{ blur: !asset.id,
                  active: currentMasterSuiteTouchAssetId === asset.masterSuiteTouchAssetId,
                }"
                      [src]="getLinkImage(asset) | secure | async"
                      [alt]="getLinkImage(asset) | secure | async"
                      matTooltipPosition="above"
                    />
                  </ng-container>
                  <ng-template #noImage>
                    <div class="assets-navigate__no-img">
                      <img
                        matTooltip="Pending"
                        matTooltipPosition="right"
                        class="blur"
                        [src]="'assets/image-placeholder.jpg'"
                        alt="Image Error"
                        matTooltipPosition="above"
                      />
                    </div>
                  </ng-template>
                </div>
              </div>
            </ng-container>
          </div>
        </mat-card>
      </div>
    </ng-template>
    <ng-template #OutputAssets>
      <div *ngFor="let format of listOutputs" style="padding: 8px">
        <h3 class="assets-navigate__label" matTooltipPosition="above">
          {{ format.name }}
        </h3>
        <mat-card class="assets-navigate__list-card">
          <div class="assets-navigate__list-asset">
            <ng-container
              *ngFor="let asset of format.outputAssets; let i = index"
            >
              <div [id]="asset.id">
                <div class="flex-row">
                  <div class="assets-navigate__asset-title">
                    {{ asset.label }}
                  </div>
                  <div
                    class="assets-navigate__asset-item-state"
                    [ngClass]="{
                      approved: asset.internalReviewState === eState.APPROVED,
                      rejected: asset.internalReviewState === eState.REJECTED
                    }"
                  ></div>
                </div>
                <div
                  class="assets-navigate__asset-item"
                  [ngClass]="{
                  active: currentMasterSuiteTouchAssetId === asset.masterSuiteTouchAssetId &&
                  asset.outputFormatId === currentOutputFormatId,
                }"
                  (click)="onSelect(asset, true, undefined, format)"
                >
                  <ng-container *ngIf="!!asset.thumbnailUri; else noImage">
                    <img
                      *ngIf="!asset.id; else uploaded"
                      class="blur"
                      [src]="getLinkImage(asset) | secure | async"
                      [alt]="apiBase + asset.thumbnailUri"
                      matTooltip="Pending"
                      matTooltipPosition="right"
                    />
                    <ng-template #uploaded>
                      <img
                        *ngIf="
                          !!asset.id &&
                            format.stillFormat != null &&
                            format.stillFormat.format.toLowerCase() != 'tiff';
                          else invalidFormat
                        "
                        [ngClass]="{ active: currentMasterSuiteTouchAssetId === asset.masterSuiteTouchAssetId &&
                          asset.outputFormatId === currentOutputFormatId,
                      }"
                        [src]="getLinkImage(asset) | secure | async"
                        [alt]="'assets/image-placeholder.jpg'"
                        matTooltipPosition="above"
                      />
                    </ng-template>
                    <ng-template #invalidFormat>
                      <img
                        matTooltip="Invalid Preview"
                        matTooltipPosition="right"
                        [ngClass]="{
                          active:
                            currentMasterSuiteTouchAssetId === asset.masterSuiteTouchAssetId &&
                            asset.outputFormatId === currentOutputFormatId,
                        }"
                        [src]="'assets/image-placeholder.jpg'"
                        matTooltipPosition="above"
                      />
                    </ng-template>
                  </ng-container>
                  <ng-template #noImage>
                    <div class="assets-navigate__no-img">
                      <img
                        matTooltip="Pending"
                        matTooltipPosition="right"
                        class="blur"
                        [src]="'assets/image-placeholder.jpg'"
                        alt="Image Error"
                        matTooltipPosition="above"
                      />
                    </div>
                  </ng-template>
                </div>
              </div>
            </ng-container>
          </div>
        </mat-card>
      </div>
    </ng-template>
  </div>
</div>
