<mat-card class="card-item">
  <div class="card-item__thumb">
    <div
      class="card-item__thumb-img"
      [ngClass]="{ blur: data.sourceId != null }"
    >
      <img
        *ngIf="!!data.id && !!data.shootAssetPlaceholderThumbnailUri"
        [ngClass]="!readonly ? 'cursor-pointer' : ''"
        [src]="getLinkImage() | secure | async"
        [alt]="data.shootAssetPlaceholderThumbnailUri"
        [hidden]="!isImageLoaded"
        (load)="checkImageLoaded()"
      />
      <div
        *ngIf="!isImageLoaded || !data.shootAssetPlaceholderThumbnailUri"
        class="card-item__no-img"
      >
        <img
          [ngClass]="!readonly ? 'cursor-pointer' : ''"
          [src]="'assets/image-placeholder.jpg'"
          alt="Image Error"
        />
      </div>
    </div>
  </div>
  <mat-card-content>
    <div>
      <div class="card-item__label">
        <div>
          {{ data.label }}
        </div>
        <span class="spacer"></span>
        <mat-checkbox
          [disabled]="readonly"
          (change)="selectionChange.emit(!selected)"
          [checked]="selected"
          color="primary"
        ></mat-checkbox>
      </div>
      <div class="card-item__description">
        {{
          data.sourceId == null
            ? "Robot Angle: " +
              data.packageAssetRobotOutDegrees +
              " Table Angle: " +
              data.packageAssetTablePosition
            : "Additional Asset of " + data.label!.split(".")[0]
        }}
      </div>
    </div>
  </mat-card-content>
</mat-card>
