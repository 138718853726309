<div #videotecContainer class="videotec-container">
  <button
    *ngIf="!zoomed"
    mat-icon-button
    (click)="exit.emit()"
    [style]="{
      position: 'fixed',
      top: checkMargin(false),
      right: checkMargin(true)
    }"
  >
    <mat-icon> fullscreen_exit </mat-icon>
  </button>
  <!-- <div
    style="width: 100%; max-height: 100%; display: flex; align-items: center"
  > -->
  <img
    #videotecImage
    [src]="currentAsset"
    style="background-image: url('assets/transparent-background.png')"
    [ngClass]="zoomed ? 'zoomedIn' : 'zoomedOut'"
    [style]="{ height: !zoomed && checkMargin(true) != '20px' ? '100%' : '' }"
    (click)="onClick($event)"
  />
  <!-- </div> -->
</div>
