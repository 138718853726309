import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizationsService } from 'src/app/services/organizations.service';

@Component({
  selector: 'app-shopify-auth-result',
  templateUrl: './shopify-auth-result.component.html',
  styleUrl: './shopify-auth-result.component.scss',
})
export class ShopifyAuthResultComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private organizationsService: OrganizationsService
  ) {}
  processing: boolean = true;
  processingState: string = 'Validating Shopify Host Domain....';
  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const code = params['code'];
      const state = params['state'];
      const shop = params['shop'];
      var request: IAuthResultRequest = {
        code: code,
        state: state,
        shop: shop,
      };
      this.getAuthResult(request);
    });
  }

  getAuthResult(request: IAuthResultRequest) {
    this.organizationsService.shopifyAuthResult(request).subscribe((res) => {
      console.log(res);
      this.processingState = 'Valid Domain, Syncing Products....';
      this.organizationsService
        .shopifyProductsSync(res.id!)
        .subscribe((result) => {
          console.log(result);
        });
    });
  }
}

export interface IAuthResultRequest {
  code: string;
  state: string;
  shop: string;
}
