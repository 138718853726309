<div class="pd-card">
  <div class="pd-card__header">
    <div class="name">{{ data.barcode || data.reference }}</div>
    <div
      class="external-status"
      [ngClass]="{
        approved: data.expectedPostAssetCount > 0 && data.expectedPostAssetCount === data.approvedPostAssetCount,
        rejected: data.rejectedPostAssetCount > 0,
    }"
    ></div>
  </div>
  <div class="pd-card__thumb-img" (click)="onEdit()">
    <img
      class="zoom"
      [ngClass]="{ blur: !data.id }"
      [src]="getLinkImage() | secure | async"
      [alt]="getLinkImage() | secure | async"
      matTooltipPosition="above"
      [hidden]="!isImageLoaded"
      *ngIf="!!data.thumbnailUri"
      (load)="loadImage()"
    />

    <div class="pd-card__no-img" *ngIf="!data.thumbnailUri || !isImageLoaded">
      <img
        [src]="'assets/image-placeholder.jpg'"
        alt="Image Error"
        matTooltipPosition="above"
      />
    </div>
  </div>
  <div class="pd-card__actions">
    <div class="spacer"></div>
    <div class="action-ctn">
      <!-- <mat-checkbox style="padding: 4px;" [(ngModel)]="data.selected" (change)="selectProduct($event.checked)"
        color="primary"></mat-checkbox> -->
      <div
        class="action-ctn__sub-item"
        matTooltip="{{
          latestAction != null
            ? (latestAction | dateAgo : currentConfigDate : true | async)
            : (data.latestAction | dateAgo : currentConfigDate : true | async)
        }}"
      >
        {{
          latestAction != null
            ? (latestAction | dateAgo : currentConfigDate | async)
            : (data.latestAction | dateAgo : currentConfigDate | async)
        }}
      </div>
      <span class="spacer"></span>
      <button mat-icon-button (click)="onEdit()" color="info">
        <mat-icon>info</mat-icon>
      </button>
    </div>
  </div>
</div>
