<mat-dialog-content>
  <img
    *ngIf="asset.type == 'Still'"
    [src]="getLinkImage(asset.reviewUri) | secure | async"
    [hidden]="!isImageLoaded"
    (load)="loadImage()"
  />
  <video
    *ngIf="asset.type != 'Still'"
    controls
    autoplay="1"
    [src]="getLinkImage(asset.reviewUri) | secure | async"
  ></video>

  <div class="asset-actions" *ngIf="asset.type == 'Still'">
    <button mat-icon-button (click)="fullscreen = true">
      <mat-icon> fullscreen </mat-icon>
    </button>
  </div>
</mat-dialog-content>
<mat-dialog-actions style="height: 90px" *ngIf="data.type != 'outputAssets'">
  <button
    mat-icon-button
    color="white"
    class="action_button"
    (click)="previousAsset()"
  >
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>

  <span class="spacer"></span>

  <button
    *ngIf="state == null || state == EStateAsset.REJECTED"
    mat-icon-button
    color="error"
    class="action_button"
    (click)="onReview(EStateAsset.REJECTED)"
  >
    <mat-icon>cancel</mat-icon>
  </button>

  <button
    *ngIf="state == EStateAsset.APPROVED"
    mat-icon-button
    color="Required"
    class="action_button"
    (click)="onReview(null)"
  >
    <mat-icon>highlight_remove</mat-icon>
  </button>

  <button
    mat-icon-button
    color="white"
    class="action_button"
    (click)="openReview()"
  >
    <mat-icon>rate_review</mat-icon>
  </button>

  <button
    *ngIf="state == null || state == EStateAsset.APPROVED"
    mat-icon-button
    color="success"
    class="action_button"
    (click)="onReview(EStateAsset.APPROVED)"
  >
    <mat-icon>check_circle</mat-icon>
  </button>

  <button
    *ngIf="state == EStateAsset.REJECTED"
    mat-icon-button
    color="Required"
    class="action_button"
    (click)="onReview(null)"
  >
    <mat-icon>highlight_remove</mat-icon>
  </button>

  <span class="spacer"></span>

  <button
    mat-icon-button
    color="white"
    class="action_button"
    (click)="nextAsset()"
  >
    <mat-icon>keyboard_arrow_right</mat-icon>
  </button>
</mat-dialog-actions>

<app-review-fullscreen
  *ngIf="fullscreen"
  [currentAsset]="getLinkImage(asset.reviewUri)"
  (exit)="fullscreen = false"
></app-review-fullscreen>
