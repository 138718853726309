import { KeyValue, Setting, SettingValue } from './setting';

export class SettingNew implements Setting {
  id = null;
  organizationId = '';
  name = '';
  type = 'Stevie';
  still: SettingValue = new SettingValueStevie();
  video: SettingValue = new SettingValueStevie();
  createdAt = '';
  updatedAt = '';
  version = 0;
}

export class SettingValueStevie implements SettingValue {
  lights = [
    {
      key: 'colourTemperature',
      //_key:"Colour Temperature",
      value: 5600,
    },
    {
      key: 'backLeft',
      //_key:"Back Left",
      value: 0,
    },
    {
      key: 'backMiddle',
      // _key:"Back Middle",
      value: 0,
    },
    {
      key: 'backRight',
      //_key:"Back Right",
      value: 0,
    },
    {
      key: 'top',
      //_key:"Top",
      value: 0,
    },
    {
      key: 'left',
      //_key:"Left",
      value: 0,
    },
    {
      key: 'right',
      //_key:"Right",
      value: 0,
    },
    {
      key: 'table',
      //_key:"Table",
      value: 0,
    },
  ];
  camera = [
    {
      key: 'iso',
      //_key:"Iso",
      value: 200,
    },
    {
      key: 'shutter',
      //_key:"Shutter",
      value: '1/60',
    },
    {
      key: 'aperture',
      //_key:"Aperture",
      value: 14,
    },
  ];
}

export class SettingValueMax implements SettingValue {
  lights = [
    {
      key: 'colourTemperature',
      //_key:"Colour Temperature",
      value: 5600,
    },
    {
      key: 'TopFront',
      //_key:"Back Left",
      value: 0,
    },
    {
      key: 'TopBack',
      // _key:"Back Middle",
      value: 0,
    },
    {
      key: 'FrontLeft',
      //_key:"Back Right",
      value: 0,
    },
    {
      key: 'FrontRight',
      //_key:"Top",
      value: 0,
    },
    {
      key: 'MiddleLeft',
      //_key:"Left",
      value: 0,
    },
    {
      key: 'MiddleRight',
      //_key:"Right",
      value: 0,
    },
  ];
}
