import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDrag,
  CdkDropList,
  CdkDragEnter,
} from '@angular/cdk/drag-drop';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Location } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  Renderer2,
  ViewChild,
  inject,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { range } from 'lodash';
import { Subject, forkJoin, takeUntil } from 'rxjs';
import { ConfirmModalComponent } from 'src/app/components/shared/confirm-modal/confirm-modal.component';
import { UnsavedChangesWarningComponent } from 'src/app/components/shared/unsaved-changes-warning/unsaved-changes-warning.component';
import { WarningModalComponent } from 'src/app/components/shared/warning-modal/warning-modal.component';
import {
  BACK_FORM_CREATE_EV,
  IMasterSuite,
  MasterSuiteEnhancementVariantAssetsFile,
  IMasterSuiteTouchAsset,
  IMasterSuiteTouch,
} from 'src/app/models/mastersuites/mastersuite';
import { MasterSuitesService } from 'src/app/services/mastersuites.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-enhancement-variant-create',
  templateUrl: './enhancement-variant-create.component.html',
  styleUrls: ['./enhancement-variant-create.component.scss'],
})
export class EnhancementVariantCreateComponent implements OnInit {
  @ViewChild(CdkDropList) placeholder?: CdkDropList;
  loaded: boolean = false;
  masterSuite!: IMasterSuite;
  masterSuiteTouchAssetSelected: any[] = [];
  originalMasterSuiteTouchAssetSelected: any[] = [];
  apiBase = environment.apiBase;
  action: 'update' | 'create' = 'create';
  masterSuiteId = '';
  readonly: boolean = true;
  colPerScreen: number = 3;
  public enhancementVariantForm = new FormGroup({
    name: new FormControl<string>('', [Validators.required]),
  });

  public target?: CdkDropList;
  public targetIndex?: number;
  public source?: CdkDropList;
  public sourceIndex?: number;

  private cd = inject(ChangeDetectorRef);
  private renderer = inject(Renderer2);

  readonly breakpoint$ = this.breakpointObserver.observe([
    Breakpoints.XLarge,
    Breakpoints.Large,
    Breakpoints.Medium,
    Breakpoints.Small,
    Breakpoints.XSmall,
  ]);
  private destroy$ = new Subject();
  constructor(
    private activatedRoute: ActivatedRoute,
    private _location: Location,
    private masterSuitesService: MasterSuitesService,
    public dialog: MatDialog,
    private _alert: MatSnackBar,
    private router: Router,
    public breakpointObserver: BreakpointObserver
  ) {
    this.action = this.activatedRoute.snapshot.paramMap.get(
      'enhancementVariantId'
    )
      ? 'update'
      : 'create';
    this.masterSuiteId =
      this.activatedRoute.snapshot.paramMap.get('masterSuiteId') || '';
  }

  ngOnInit(): void {
    this.breakpoint$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.breakpointChanged();
    });
    if (this.action === 'update') {
      this.getData();
    } else {
      this.readonly = false;
      this.getMasterSuiteDetail();
    }

    setTimeout(() => {
      let phElement = this.placeholder?.element.nativeElement;
      this.renderer.setStyle(phElement, 'display', 'none');
      this.renderer.removeChild(phElement?.parentNode, phElement);
    });
  }

  getData() {
    const enhancementVariantId =
      this.activatedRoute.snapshot.paramMap.get('enhancementVariantId') || '';
    forkJoin(
      this.masterSuitesService.detailMasterSuite(this.masterSuiteId),
      this.masterSuitesService.getEnhancementVariantById(enhancementVariantId)
    ).subscribe({
      next: ([resMs, resEnhancement]) => {
        this.masterSuite = resMs;
        this.setupData();
        this.enhancementVariantForm.patchValue({ name: resEnhancement.name });
        resEnhancement.masterSuiteEnhancementVariantAssets.forEach((msta) => {
          this.masterSuite.masterSuiteTouches.forEach((touch, i) => {
            touch.masterSuiteTouchAssets.forEach((masterSuiteTouchAsset, j) => {
              if (masterSuiteTouchAsset.id === msta.masterSuiteTouchAssetId) {
                this.masterSuiteTouchAssetSelected.push({
                  ...masterSuiteTouchAsset,
                  masterSuiteEnhancementVariantAssetId: msta.id,
                  postAssetPlaceholderRawUri: msta.postAssetPlaceholderRawUri,
                  postAssetPlaceholderReviewUri:
                    msta.postAssetPlaceholderReviewUri,
                  postAssetPlaceholderThumbnailUri:
                    msta.postAssetPlaceholderThumbnailUri,
                  masterSuiteEnhancementVariantId:
                    msta.masterSuiteEnhancementVariantId,
                  assetName:
                    masterSuiteTouchAsset.packageAssetType === 'Still'
                      ? `Touch ${i + 1} (Angle ${j + 1})`
                      : `Touch ${i + 1} (Video ${
                          j + 1 - touch.packageStillCount
                        })`,
                  index: msta.index,
                });
              }
              masterSuiteTouchAsset.copies?.forEach(
                (masterSuiteTouchAssetCopy, k) => {
                  if (
                    masterSuiteTouchAssetCopy.id ===
                    msta.masterSuiteTouchAssetId
                  ) {
                    this.masterSuiteTouchAssetSelected.push({
                      ...masterSuiteTouchAssetCopy,
                      masterSuiteEnhancementVariantAssetId: msta.id,
                      shootAssetPlaceholderRawUri:
                        masterSuiteTouchAsset.shootAssetPlaceholderRawUri,
                      shootAssetPlaceholderReviewUri:
                        masterSuiteTouchAsset.shootAssetPlaceholderReviewUri,
                      shootAssetPlaceholderThumbnailUri:
                        masterSuiteTouchAsset.shootAssetPlaceholderThumbnailUri,
                      postAssetPlaceholderRawUri:
                        msta.postAssetPlaceholderRawUri,
                      postAssetPlaceholderReviewUri:
                        msta.postAssetPlaceholderReviewUri,
                      postAssetPlaceholderThumbnailUri:
                        msta.postAssetPlaceholderThumbnailUri,
                      masterSuiteEnhancementVariantId:
                        msta.masterSuiteEnhancementVariantId,
                      assetName:
                        masterSuiteTouchAsset.packageAssetType === 'Still'
                          ? `Touch ${i + 1} (Angle ${j + 1}.${k + 1})`
                          : `Touch ${i + 1} (Video ${
                              j + 1 - touch.packageStillCount
                            }.${k + 1})`,
                      index: msta.index,
                    });
                  }
                }
              );
            });
          });
        });
        this.loaded = true;
        this.originalMasterSuiteTouchAssetSelected = JSON.parse(
          JSON.stringify(this.masterSuiteTouchAssetSelected)
        );
      },
    });
  }

  setupData() {
    this.masterSuite.masterSuiteTouches.sort((a, b) => a.index - b.index);
    this.masterSuite.masterSuiteTouches.forEach((touch) => {
      touch.masterSuiteTouchAssets.sort(
        (a, b) => a.packageAssetIndex - b.packageAssetIndex
      );
      touch.packageStillCount = 0;
      touch.packageVideoCount = 0;

      touch.masterSuiteTouchAssets.forEach((touchAsset) => {
        //Calculate Counts
        if (touchAsset.packageAssetType == 'Still') {
          touch.packageStillCount = touch.packageStillCount + 1;
        } else {
          touch.packageVideoCount = touch.packageVideoCount + 1;
        }
      });
    });
  }

  getMasterSuiteDetail() {
    if (this.masterSuiteId) {
      this.masterSuitesService.detailMasterSuite(this.masterSuiteId).subscribe({
        next: (masterSuite) => {
          this.masterSuite = masterSuite;
          this.setupData();
          this.loaded = true;
        },
      });
    }
  }

  onBack() {
    if (this.checkChanges() && !this.readonly && this.action == 'update') {
      const dialogRef = this.dialog.open(UnsavedChangesWarningComponent);
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          sessionStorage.setItem(BACK_FORM_CREATE_EV, 'true');
          this.router.navigate([`mastersuites/${this.masterSuiteId}/update`]);
        }
      });
    } else {
      sessionStorage.setItem(BACK_FORM_CREATE_EV, 'true');
      this.router.navigate([`mastersuites/${this.masterSuiteId}/update`]);
    }
  }

  onSelectMasterSuite(state: boolean, mstAsset: any, touchIndex: number) {
    if (state) {
      let tmp = {
        ...mstAsset,
        assetName: `Touch ${touchIndex + 1} (${mstAsset.label})`,
      };
      this.masterSuiteTouchAssetSelected.push(tmp);
    } else {
      const index = this.masterSuiteTouchAssetSelected.findIndex(
        (e) => e.id === mstAsset.id
      );
      this.masterSuiteTouchAssetSelected.splice(index, 1);
    }
  }

  checkShow(masterSuiteTouchAssets: any[], type: 'Still' | 'Video'): boolean {
    const index = masterSuiteTouchAssets.findIndex(
      (m) => m.packageAssetType === type
    );
    return index !== -1;
  }

  create() {
    const check = this.checkBeforeSubmit();
    if (!check.status) {
      this.openConfirm(check.message);
      return;
    }
    let dataCreate = {
      name: this.enhancementVariantForm.value.name || '',
      assets: this.masterSuiteTouchAssetSelected.map((msta, index) => {
        return {
          masterSuiteTouchAssetId: msta.id,
          index: index * 1000,
        };
      }),
    };
    this.masterSuitesService
      .createEnhancementVariant(dataCreate, this.masterSuiteId)
      .subscribe({
        next: (res) => {
          const conformRef = this.dialog.open(ConfirmModalComponent, {
            data: {
              header: 'Apply Enhancement Variant',
              content:
                'Do you want to apply this to all products without an Enhancement Variant in this Master Suite?',
            },
          });
          conformRef.afterClosed().subscribe((status) => {
            if (status) {
              this.masterSuitesService
                .applyEnhancementVariantForProductsAfterCreated(res.id)
                .subscribe(() => {
                  console.log(`Applied Enhancement Variant`);
                });
            }
          });
          this.onBack();
        },
        error: (e) => {
          if (e.status === 422) {
            this.enhancementVariantForm.get('name')?.setErrors({
              sameName:
                'Enhancement Variant Name must be unique in a Master Suite.',
            });
          }
        },
      });
  }

  checkSelect(mstAsset: IMasterSuiteTouchAsset): boolean {
    if (this.masterSuiteTouchAssetSelected.length === 0) {
      return false;
    }
    for (const touch of this.masterSuiteTouchAssetSelected) {
      if (touch.id === mstAsset.id) {
        return true;
      }
    }
    return false;
  }

  unSelectAsset(mstAsset: any) {
    const index = this.masterSuiteTouchAssetSelected.findIndex(
      (m) => m.id === mstAsset.id
    );
    this.masterSuiteTouchAssetSelected.splice(index, 1);
  }

  update() {
    const check = this.checkBeforeSubmit();
    if (!check.status) {
      this.openConfirm(check.message);
      return;
    }
    const enhancementVariantId =
      this.activatedRoute.snapshot.paramMap.get('enhancementVariantId') ?? '';
    let dataUpdate = {
      name: this.enhancementVariantForm.value.name ?? '',
      assets: this.masterSuiteTouchAssetSelected.map((msta, index) => {
        return {
          masterSuiteTouchAssetId: msta.id,
          index: index * 1000,
        };
      }),
    };
    this.masterSuitesService
      .updateEnhancementVariant(dataUpdate, enhancementVariantId)
      .subscribe({
        next: (res) => {
          this.readonly = true;
          this._alert.open(
            'Successfully Updated Enhancement Variant',
            'close',
            {
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
              duration: 2000,
              panelClass: ['success'],
            }
          );
        },
        error: (e) => {
          if (e.status === 422) {
            this.enhancementVariantForm.get('name')?.setErrors({
              sameName:
                'Enhancement Variant Name must be unique in a Master Suite.',
            });
          }
        },
      });
  }

  checkBeforeSubmit(): {
    message: string;
    status: boolean;
  } {
    if (
      !this.enhancementVariantForm.value.name &&
      this.masterSuiteTouchAssetSelected.length === 0
    ) {
      return {
        message:
          'Please add the Name and pick the available assets for creating the Enhancement Variant!',
        status: false,
      };
    }
    if (!this.enhancementVariantForm.value.name) {
      return {
        message: 'Please add the Name for creating the Enhancement Variant!',
        status: false,
      };
    }
    if (this.masterSuiteTouchAssetSelected.length === 0) {
      return {
        message:
          'Please select at least 1 asset to create the Enhancement Variant!',
        status: false,
      };
    }
    return {
      message: ``,
      status: true,
    };
  }

  onCancel() {
    if (this.action == 'update') {
      this.cancel();
    } else {
      sessionStorage.setItem(BACK_FORM_CREATE_EV, 'true');
      this._location.back();
    }
  }

  checkWidth(): string {
    const elemWidth = document.getElementById('list')?.offsetWidth;
    return elemWidth! / this.colPerScreen - 20 + 'px';
  }

  drop() {
    if (!this.target) return;
    let phElement = this.placeholder?.element.nativeElement;
    let parent = phElement?.parentNode;
    this.renderer.setStyle(phElement, 'display', 'inline');
    this.renderer.removeChild(parent, phElement);
    this.renderer.insertBefore(
      parent,
      this.source?.element.nativeElement,
      parent?.children[this.sourceIndex as number]
    );
    if (this.sourceIndex != this.targetIndex) {
      moveItemInArray(
        this.masterSuiteTouchAssetSelected,
        this.sourceIndex as number,
        this.targetIndex as number
      );
    }
    this.target = undefined;
    this.source = undefined;
    this.targetIndex = undefined;
    this.sourceIndex = undefined;
    this.cd.detectChanges();
  }

  enter(e: CdkDragEnter) {
    const drag = e.item;
    const drop = e.container;

    if (drop === this.placeholder) return;

    let phElement = this.placeholder?.element.nativeElement;
    let dropElement = drop.element.nativeElement;
    let dragIndex =
      typeof this.sourceIndex === 'number'
        ? this.sourceIndex
        : __indexOf(
            dropElement.parentNode?.children,
            drag.dropContainer.element.nativeElement
          );
    let dropIndex = __indexOf(dropElement.parentNode?.children, dropElement);

    if (!this.source) {
      this.sourceIndex = dragIndex;
      this.source = drag.dropContainer;
      const sourceElement = this.source.element.nativeElement;

      this.renderer.removeChild(sourceElement.parentNode, sourceElement);
    }

    this.renderer.setStyle(phElement, 'display', '');
    if (typeof this.sourceIndex === 'number') {
      if (this.sourceIndex < dropIndex) {
        if (typeof this.targetIndex === 'number') {
          if (dropIndex < this.targetIndex) {
            this.renderer.insertBefore(
              dropElement.parentElement!,
              phElement,
              dropElement
            );
          } else {
            this.renderer.insertBefore(
              dropElement.parentElement!,
              phElement,
              dropElement.nextSibling
            );
          }
        } else {
          this.renderer.insertBefore(
            dropElement.parentElement!,
            phElement,
            dropElement.nextSibling
          );
        }
      } else {
        if (typeof this.targetIndex === 'number') {
          if (this.targetIndex < dropIndex) {
            this.renderer.insertBefore(
              dropElement.parentElement!,
              phElement,
              dropElement.nextSibling
            );
          } else {
            this.renderer.insertBefore(
              dropElement.parentElement!,
              phElement,
              dropElement
            );
          }
        } else {
          this.renderer.insertBefore(
            dropElement.parentElement!,
            phElement,
            dropElement
          );
        }
      }

      this.targetIndex = dropIndex;
      this.target = drop;

      this.placeholder?._dropListRef.enter(
        drag._dragRef,
        drag.element.nativeElement.offsetLeft,
        drag.element.nativeElement.offsetTop
      );

      this.cd.detectChanges();
    }
  }

  openConfirm(content: string) {
    this.dialog.open(WarningModalComponent, {
      data: {
        header: 'Warning',
        content: content,
      },
    });
  }

  breakpointChanged() {
    switch (true) {
      case this.breakpointObserver.isMatched('(min-width: 2200px)'):
        this.colPerScreen = 7;
        break;
      case this.breakpointObserver.isMatched(Breakpoints.XLarge):
        this.colPerScreen = 6;
        break;

      case this.breakpointObserver.isMatched(Breakpoints.Large):
        this.colPerScreen = 5;
        break;

      case this.breakpointObserver.isMatched(Breakpoints.Medium):
        this.colPerScreen = 4;
        break;

      case this.breakpointObserver.isMatched(Breakpoints.Small):
        this.colPerScreen = 3;
        break;

      case this.breakpointObserver.isMatched(Breakpoints.XSmall):
        this.colPerScreen = 2;
        break;
      default:
        this.colPerScreen = 1;
        break;
    }
  }

  parseAssets(touch: IMasterSuiteTouch): IMasterSuiteTouchAsset[] {
    var assets: IMasterSuiteTouchAsset[] = [];
    touch.masterSuiteTouchAssets.forEach((asset, i) => {
      asset.label =
        asset.packageAssetType == 'Still'
          ? `Angle ${i + 1}`
          : `Video ${i + 1 - touch.packageStillCount}`;
      assets.push(asset);
      asset.copies?.forEach((copy, j) => {
        copy.label = asset.label + `.${j + 1}`;
        copy.shootAssetPlaceholderThumbnailUri =
          asset.shootAssetPlaceholderThumbnailUri;
        copy.shootAssetPlaceholderReviewUri =
          asset.shootAssetPlaceholderReviewUri;
        assets.push(copy);
      });
    });
    return assets;
  }

  cancel() {
    if (this.checkChanges()) {
      const dialogRef = this.dialog.open(UnsavedChangesWarningComponent);
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.masterSuiteTouchAssetSelected = JSON.parse(
            JSON.stringify(this.originalMasterSuiteTouchAssetSelected)
          );
          this.setupData();
          this.readonly = true;
        }
      });
    } else {
      this.readonly = true;
    }
  }

  checkChanges(): boolean {
    return (
      JSON.stringify(this.masterSuiteTouchAssetSelected) !=
      JSON.stringify(this.originalMasterSuiteTouchAssetSelected)
    );
  }
}

const __indexOf = (collection: any, node: any) => {
  return Array.prototype.indexOf.call(collection, node);
};
