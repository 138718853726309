<content>
    <mat-form-field appearance="outline">
        <mat-label>Source MasterSuite</mat-label>
        <mat-select [formControl]="masterSuiteSrcFormControl">
            <mat-option *ngFor="let masterSuite of availableSrcMasterSuites" [value]="masterSuite"
                (onSelectionChange)="masterSuiteSrcChange(masterSuite, $event)">
                {{masterSuite.name}}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="masterSuiteSrcFormControl.hasError('required')">Selection required</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label>Destination MasterSuite</mat-label>
        <input type="text" placeholder="Pick one" aria-label="MasterSuite" matInput
            [formControl]="masterSuiteDstFormControl" [matAutocomplete]="auto">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngFor="let masterSuite of masterSuiteDstFilteredOptions | async" [value]="masterSuite"
                (onSelectionChange)="masterSuiteDstChange(masterSuite , $event)">
                {{masterSuite.name}}
            </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="masterSuiteDstFormControl.hasError('required')">Selection required</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label>Destination Variant</mat-label>
        <mat-select [formControl]="variantDstFormControl">
            <mat-option *ngFor="let variant of variants" [value]="variant"
                (onSelectionChange)="variantDstChange(variant , $event)">
                {{variant.name}}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="variantDstFormControl.hasError('required')">Selection required</mat-error>
    </mat-form-field>
</content>