import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GenerateOutputsCollectionComponent } from 'src/app/components/shared/generate-outputs-collection/generate-outputs-collection.component';
import { EStateAsset } from 'src/app/models/products/asset';
import { ProductSearch } from 'src/app/models/products/product';
import { IPreference } from 'src/app/models/users/userProfile';
import { AuthService } from 'src/app/services/auth.service';
import { ProductsService } from 'src/app/services/products.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-product-search-item',
  templateUrl: './product-search-item.component.html',
  styleUrls: ['./product-search-item.component.scss'],
})
export class ProductSearchItemComponent implements OnInit {
  @Input() data!: ProductSearch;
  @Input() filter: boolean = false;
  apiBase = environment.apiBase;
  eState = EStateAsset;
  isImageLoaded = false;
  currentConfigDate!: IPreference;
  isStaff: boolean = false;
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.isStaff = this.authService.getRole('staff');
    this.authService.currentPreference.subscribe((res) => {
      this.currentConfigDate = res;
    });
  }

  onEdit() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([
        `batches/${this.data.batchId}/products/${this.data.id}`,
      ])
    );
    window.open(url, '_blank');
  }

  downloadAssets() {
    const dialogRef = this.dialog.open(GenerateOutputsCollectionComponent, {
      data: {
        organizationId: this.data.organizationId,
        isBatch: false,
        productIds: [this.data.id],
      },
    });
  }

  getLinkImage(image: string) {
    if (!this.isStaff && !this.data.releasedInCollection) {
      return 'assets/image-placeholder.jpg';
    }
    return ProductsService.getLinkImage(image);
  }
}
