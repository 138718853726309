import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Subject, takeUntil } from 'rxjs';
import {
  IMasterSuite,
  ISettingsVariant,
} from 'src/app/models/mastersuites/mastersuite';
import {
  ETransferAction,
  ISettingsTransfer,
} from 'src/app/models/products/actions/settings/settingsTransfer';
import { ISettingsTransferValidate } from 'src/app/models/products/actions/settings/settingsTransferValidate';
import { IProduct } from 'src/app/models/products/product';
import { ProductsService } from 'src/app/services/products.service';
import { MatPaginator } from '@angular/material/paginator';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-product-transfer-settings',
  templateUrl: './product-transfer-settings.component.html',
  styleUrls: ['./product-transfer-settings.component.scss'],
})
export class ProductTransferSettingsComponent implements OnInit {
  @Input() products!: IProduct[];
  @Input() masterSuites!: IMasterSuite[];

  private destroy$ = new Subject();
  step = 0;
  constructor(
    public dialogRef: MatDialogRef<ProductTransferSettingsComponent>,
    private productsService: ProductsService
  ) {}

  ngOnInit(): void {}
  searchForm: FormControl<any> = new FormControl();
  selectedMasterSuiteSrc: IMasterSuite | null = null;
  setMasterSuiteSrc(masterSuite: IMasterSuite) {
    if (masterSuite != null) {
      this.availableProducts = this.products.filter(
        (p) => p.masterSuiteId === masterSuite.id
      );
    }
    this.selectedMasterSuiteSrc = masterSuite;
  }

  selectedMasterSuiteDst: IMasterSuite | null = null;
  setMasterSuiteDst(masterSuite: IMasterSuite) {
    this.selectedMasterSuiteDst = masterSuite;
  }

  selectedVariantDst: ISettingsVariant | null = null;
  setVariantDst(variant: ISettingsVariant) {
    this.selectedVariantDst = variant;
  }

  //#region Step 2: Products
  availableProducts: IProduct[] = [];
  availableProductsDataSource = new MatTableDataSource<IProduct>();
  @ViewChild(MatPaginator)
  availableProductsPaginator!: MatPaginator;
  selectedProducts = new SelectionModel<IProduct>(true, []);
  displayedColumns: string[] = [
    'select',
    'barcode',
    'reference',
    'description',
    'status',
  ];

  //#endregion

  nextStepProducts() {
    if (this.validateMasterSuites()) {
      this.availableProductsDataSource = new MatTableDataSource<IProduct>(
        this.availableProducts
      );
      this.availableProductsDataSource.paginator =
        this.availableProductsPaginator;
      this.step = 1;
    }
  }
  validateMasterSuites(): boolean {
    if (this.selectedMasterSuiteDst?.id == this.selectedMasterSuiteSrc?.id) {
      return false;
    }
    if (
      this.selectedMasterSuiteDst == null ||
      this.selectedMasterSuiteSrc == null ||
      this.selectedVariantDst == null
    ) {
      return false;
    } else {
      return true;
    }
  }

  prevStep() {
    if (this.step == 1) {
      this.selectedProducts.clear();
    }
    this.step--;
    this.validated = false;
    this.valid = false;
  }

  masterToggle() {
    console.log(this.isAllSelected());
    if (this.isAllSelected()) {
      this.selectedProducts.clear();
    } else {
      this.availableProductsDataSource.filteredData.forEach((row) =>
        this.selectedProducts.select(row)
      );
    }
  }

  public applyFilter(event: Event) {
    let filterValue = (event.target as HTMLInputElement).value;
    filterValue = filterValue.trim().toUpperCase();

    this.availableProductsDataSource.filter = filterValue.trim().toUpperCase();

    this.availableProductsDataSource.data
      .filter((item: any) => {
        const flag = Object.keys(item).some(
          (prop: string) =>
            item[prop] &&
            typeof item[prop] === 'string' &&
            item[prop].toUpperCase().includes(filterValue)
        );
        return flag;
      })
      .sort((a, b) => (b.barcode > a.barcode ? 1 : -1));
  }

  isAllSelected() {
    const numSelected = this.selectedProducts.selected.length;
    const numRows = this.availableProductsDataSource.filteredData.length;
    return numSelected >= numRows;
  }

  selectRow($event: any, row: IProduct) {
    if ($event.srcElement!.nodeName.toLowerCase() == 'td') {
      this.selectedProducts.toggle(row);
    }
  }

  settingsTransfer!: ISettingsTransfer;
  validated = false;
  valid = false;
  validateSettingsTransfer() {
    if (this.selectedProducts.selected.length > 0) {
      var payload: ISettingsTransferValidate = {
        masterSuiteSrcId: this.selectedMasterSuiteSrc!.id!,
        masterSuiteDstId: this.selectedMasterSuiteDst!.id!,
        masterSuiteVariantDstId: this.selectedVariantDst!.id!,
        productIds: [],
      };
      this.selectedProducts.selected.forEach((product) => {
        payload.productIds.push(product.id!);
      });

      // send request
      this.productsService
        .validateProductSettingsTransfer(payload)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (result) => {
            this.settingsTransfer = result;
            this.validated = true;
            this.step = 2;
            this.valid = this.settingsTransfer.validTransfer;
          },
          error: () => {},
        });
    }
  }

  inValidateTransfer() {
    this.valid = false;
  }

  confirmSettingsTrasnfer() {
    if (this.valid) {
      this.productsService
        .productSettingsTransfer(this.settingsTransfer)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (result) => {
            this.dialogRef.close(true);
          },
          error: () => {},
        });
    }
  }

  reValidating = false;
  reValidate() {
    this.reValidating = true;
    this.validated = false;
    this.settingsTransfer.masterSuitesTouchesMapping.forEach((map) => {
      if (map.masterSuiteTouchDst == null) {
        map.action = ETransferAction.REMOVE;
      }
      if (map.masterSuiteTouchDst != null) {
        map.action = ETransferAction.TRANSFER;
      }
    });

    this.productsService
      .validateManualProductSettingsTransfer(this.settingsTransfer)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (result) => {
          this.settingsTransfer = result;
          this.reValidating = false;
          this.valid = result.validTransfer;
          this.validated = true;
        },
        error: () => {},
      });
  }
}
