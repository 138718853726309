<content *ngFor="let touchMap of settingsTransfer.masterSuitesTouchesMapping;index as i">
    <mat-card *ngIf="touchMap.action == 'Remove';else transfer" style="margin-bottom: 10px;">
        <mat-grid-list cols="12" style="display: flex; align-items: center; margin: 0 12px;" rowHeight="100px">
            <mat-grid-tile colspan="3">
                <mat-icon color="error" style="width: 24px;">delete</mat-icon>
                <div style="display: block; width: 90%;">
                    <h2>Touch {{touchMap.masterSuiteTouchSrc.index/1000+1}}</h2>
                    <h3>{{touchMap.masterSuiteTouchSrc.name}}</h3>
                </div>
            </mat-grid-tile>
            <mat-grid-tile colspan="2">
                <mat-icon>arrow_forward</mat-icon>
            </mat-grid-tile>
            <mat-grid-tile colspan="7">
                <div style="display: block; width: 100%;">
                    <h2>Removed</h2>
                    <h3 *ngIf="affectedProductsDataSource[i].data.length != 0">Affected Products Below:</h3>
                    <h3 *ngIf="affectedProductsDataSource[i].data.length == 0">0 Products Affected</h3>
                </div>
            </mat-grid-tile>
        </mat-grid-list>
        <table *ngIf="affectedProductsDataSource[i].data.length != 0" mat-table
            [dataSource]="affectedProductsDataSource[i]" class="table">
            <!-- Barcode Column -->
            <ng-container matColumnDef="barcode">
                <th mat-header-cell *matHeaderCellDef> Barcode </th>
                <td mat-cell *matCellDef="let product"> {{product.barcode}} </td>
            </ng-container>

            <!-- Reference Column -->
            <ng-container matColumnDef="reference">
                <th mat-header-cell *matHeaderCellDef> Reference </th>
                <td mat-cell *matCellDef="let product"> {{product.reference}} </td>
            </ng-container>

            <!-- Description Column -->
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef> Description </th>
                <td mat-cell *matCellDef="let product"> {{product.description}} </td>
            </ng-container>

            <!-- ingestion Column -->
            <ng-container matColumnDef="ingestion">
                <th mat-header-cell *matHeaderCellDef> Ingested </th>
                <td mat-cell *matCellDef="let product"> {{product.ingestionData}} </td>
            </ng-container>

            <!-- production Column -->
            <ng-container matColumnDef="production">
                <th mat-header-cell *matHeaderCellDef> Shot </th>
                <td mat-cell *matCellDef="let product"> {{product.productionData}} </td>
            </ng-container>

            <!-- postProduction Column -->
            <ng-container matColumnDef="postProduction">
                <th mat-header-cell *matHeaderCellDef> Enhanced </th>
                <td mat-cell *matCellDef="let product"> {{product.postProductionData}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </mat-card>
    <ng-template #transfer>
        <ng-container *ngIf="touchMap.action == 'Transfer';else unknown">
            <mat-card style="margin-bottom: 10px;">
                <mat-grid-list cols="12" style="display: flex; align-items: center; margin: 0 12px;" rowHeight="100px">
                    <mat-grid-tile colspan="3">
                        <mat-icon color="success">alt_route</mat-icon>
                        <div style="display: block; width: 90%;">
                            <h2>Touch {{touchMap.masterSuiteTouchSrc.index/1000+1}}</h2>
                            <h3>{{touchMap.masterSuiteTouchSrc.name}}</h3>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile colspan="2">
                        <mat-icon>arrow_forward</mat-icon>
                    </mat-grid-tile>
                    <mat-grid-tile colspan="7">
                        <div style="display: block; width: 100%;">
                            <h2>Touch {{touchMap.masterSuiteTouchDst!.index/1000+1}}</h2>
                            <h3>{{touchMap.masterSuiteTouchDst!.name}}</h3>
                        </div>
                        <button mat-icon-button matTooltip="Remove Mapping" color="error" class="mat-elevation-z0"
                            (click)="removeMapping(touchMap)">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-grid-tile>
                </mat-grid-list>
            </mat-card>
        </ng-container>
    </ng-template>
    <ng-template #unknown>
        <mat-card style="margin-bottom: 10px;">
            <mat-grid-list cols="12" style="display: flex; align-items: center; margin: 0 12px;" rowHeight="100px">
                <mat-grid-tile colspan="3">
                    <mat-icon color="info">question_mark</mat-icon>
                    <div style="display: block; width: 100%;">
                        <h2>Touch {{touchMap.masterSuiteTouchSrc.index/1000+1}}</h2>
                        <h3>{{touchMap.masterSuiteTouchSrc.name}}</h3>
                    </div>
                </mat-grid-tile>
                <mat-grid-tile colspan="2">
                    <mat-icon>arrow_forward</mat-icon>
                </mat-grid-tile>
                <mat-grid-tile colspan="4">
                    <mat-form-field appearance="outline" subscriptSizing="dynamic" style="width: 100%;">
                        <mat-label>Destination Touch</mat-label>
                        <mat-select placeholder="Please Select Destination">
                            <mat-option (onSelectionChange)="setDst(touch,touchMap,$event)"
                                *ngFor="let touch of manualMappingTouchOptions(touchMap.masterSuiteTouchSrc)"
                                [value]="touch">
                                Touch {{touch.index/1000+1}}<i> ({{touch.name}})</i>
                            </mat-option>
                            <mat-option (onSelectionChange)="removeDst(touchMap,$event)" value="Remove">
                                Remove
                            </mat-option>
                        </mat-select>
                        <mat-error>Selection required</mat-error>
                    </mat-form-field>
                </mat-grid-tile>
            </mat-grid-list>
        </mat-card>
    </ng-template>
</content>