<div class="content">
  <div class="content-header">
    <div class="title">{{title}}</div>
    <span class="spacer"></span>
  </div>

  <div class="form">
    <mat-form-field [formGroup]="form" 
                    class="dialog-input"
                    appearance="outline"
    >
      <mat-label>{{formInput1}}</mat-label>

      <input  [formControlName]="formInput1"
              type="text"

              class="input"
              matInput
      >

      <mat-error *ngIf="name.errors?.['required']">
          Name is required.
      </mat-error>
    </mat-form-field>

    <ng-container *ngIf="requestInProgress">
      <mat-progress-spinner [mode]="'indeterminate'">
      </mat-progress-spinner>
    </ng-container>

    <div class="content-actions">
      <span class="spacer"></span>
      <button (click)="createOrganization()"
              [disabled]="!form.valid"
              
              class="mat-elevation-z0"
              mat-icon-button
      >
        <mat-icon matTooltip="submit" 
                  color="success"
        >
          send
        </mat-icon>
      </button>
    </div>    
  </div>
</div>
