import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsService } from 'src/app/services/products.service';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { ProductSearch } from 'src/app/models/products/product';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { EStateAsset } from 'src/app/models/products/asset';
import { IBatch } from 'src/app/models/batches/batch';
import { BatchesService } from 'src/app/services/batches.services';

@Component({
  selector: 'app-product-search',
  templateUrl: './product-search.component.html',
  styleUrls: ['./product-search.component.scss'],
})
export class ProductSearchComponent implements OnInit {
  @ViewChild('pdList') pdList!: ElementRef;
  @ViewChild('pdItem') pdItem!: ElementRef;
  private destroy$ = new Subject();
  isExternal = false;
  listProduct: ProductSearch[] = [];
  paging: any = {};
  keyword = '';
  key = '';
  isLoading = true;
  colPerScreen = 5;
  eState = EStateAsset;
  checkSameOrganization = true;
  isStaff = false;
  readonly breakpoint$ = this.breakpointObserver.observe([
    Breakpoints.XLarge,
    Breakpoints.Large,
    Breakpoints.Medium,
    Breakpoints.Small,
  ]);

  selectedFilter: any | null = null;
  preDefinedFilter: boolean = false;
  readonly preDefinedFilters = [
    {
      key: 'RecentlyIngested',
      value: 'Recently ',
      icon: 'dataset',
    },
    {
      key: 'RecentlyShot',
      value: 'Recently ',
      icon: 'camera_alt',
    },
    {
      key: 'RecentlyApprovedProduction',
      value: 'Recently Approved ',
      icon: 'camera_alt',
    },
    {
      key: 'RecentlyRejectedProduction',
      value: 'Recently Rejected ',
      icon: 'camera_alt',
    },
    {
      key: 'RecentlyEnhanced',
      value: 'Recently Enhanced',
      icon: 'auto_awesome',
    },
    {
      key: 'RecentlyApprovedExternalEnhancement',
      value: 'Recently Approved ',
      icon: 'auto_awesome',
    },
    {
      key: 'RecentlyRejectedExternalEnhancement',
      value: 'Recently Rejected ',
      icon: 'auto_awesome',
    },
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public breakpointObserver: BreakpointObserver,
    private productService: ProductsService,
    private batchesService: BatchesService,
    private authService: AuthService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.isStaff = this.authService.getRole('staff');
    this.breakpoint$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.breakpointChanged();
    });
    this.isExternal = this.authService.getRole('client');
    if (this.route.snapshot.routeConfig?.path?.includes('filter')) {
      // this is a pre defined filter
      this.route.queryParams.subscribe((params) => {
        this.key = params['key'];
      });
      this.preDefinedFilter = true;
      this.selectedFilter = this.preDefinedFilters.find(
        (x) => x.key == this.key
      );
      if (this.selectedFilter) {
        this.preDefinedFilters.splice(
          this.preDefinedFilters.indexOf(this.selectedFilter!),
          1
        );
        this.preDefinedFilters.unshift(this.selectedFilter!);
        this.keyword = this.selectedFilter.value;
        this.onSearch(this.key);
      } else {
        this.isLoading = false;
      }
    } else {
      this.keyword = this.route.snapshot.paramMap.get('keyword') as string;
      this.preDefinedFilter = false;
      this.productService.setCodeSearch(this.keyword);
      if (!this.keyword || this.keyword.length < 4) {
        this.router.navigateByUrl(`batches`);
      } else {
        this.onSearch(this.keyword);
      }
      this.productService.codeSearch$.pipe(takeUntil(this.destroy$)).subscribe({
        next: (value) => {
          if (this.isLoading) {
            return;
          }
          this.keyword = value;
          this.listProduct = [];
          this.isLoading = true;
          this.onSearch(value);
        },
      });
    }
  }
  onSearch(code: string, pageNumber: number = 1, pageSize: number = 30) {
    if (this.preDefinedFilter) {
      this.productService
        .searchByCode(
          undefined,
          pageNumber,
          pageSize,
          undefined,
          undefined,
          this.key,
          undefined
        )
        .subscribe((value) => {
          this.isLoading = false;
          this.listProduct = [...this.listProduct, ...value.results];
          this.paging = {
            pageNumber: value.pageNumber,
            pageSize: value.pageSize,
            total: value.total,
          };
        });
    } else {
      this.productService
        .searchByCode(code, pageNumber, pageSize)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (value: {
            pageNumber: number;
            pageSize: number;
            results: ProductSearch[];
            total: number;
          }) => {
            this.isLoading = false;
            this.listProduct = [...this.listProduct, ...value.results];
            this.paging = {
              pageNumber: value.pageNumber,
              pageSize: value.pageSize,
              total: value.total,
            };

            setTimeout(() => {
              if (this.listProduct.length > 0) {
                this.checkScroll();
              }
            }, 1000);
          },
          error: () => {
            this.isLoading = false;
          },
        });
    }
  }

  onPreDefinedFilterChange(key: any) {
    if (key != undefined) {
      this.listProduct = [];
      this.isLoading = true;
      this.key = key;
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          key: this.key,
        },
        queryParamsHandling: 'merge',
      });
      this.onSearch(this.key);
    }
  }

  breakpointChanged() {
    switch (true) {
      case this.breakpointObserver.isMatched(Breakpoints.XLarge):
        this.colPerScreen = 6;
        break;

      case this.breakpointObserver.isMatched(Breakpoints.Large):
        this.colPerScreen = 4;
        break;

      case this.breakpointObserver.isMatched(Breakpoints.Medium):
        this.colPerScreen = 3;
        break;

      case this.breakpointObserver.isMatched(Breakpoints.Small):
        this.colPerScreen = 2;
        break;

      default:
        this.colPerScreen = 1;
        break;
    }
  }

  checkScroll() {
    const wrapper = this.pdList.nativeElement.clientHeight;
    const childHeight = this.pdList.nativeElement.children[0].clientHeight;

    if (childHeight <= wrapper) {
      this.onCheckLoad();
    }
  }

  onCheckLoad() {
    if (this.paging.pageNumber * this.paging.pageSize < this.paging.total) {
      this.paging.pageNumber++;
      this.onSearch(this.keyword, this.paging.pageNumber, this.paging.pageSize);
    }
  }

  onScroll(event: any) {
    if (
      event.srcElement.scrollHeight ===
      this.pdList.nativeElement.clientHeight + event.srcElement.scrollTop
    ) {
      this.onCheckLoad();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
