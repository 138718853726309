import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { IMasterSuiteTouchAsset } from 'src/app/models/mastersuites/mastersuite';
import { MasterSuitesService } from 'src/app/services/mastersuites.service';
import { ProductsService } from 'src/app/services/products.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-available-asset-card',
  templateUrl: './available-asset-card.component.html',
  styleUrl: './available-asset-card.component.scss',
})
export class AvailableAssetCardComponent {
  @Input() data!: IMasterSuiteTouchAsset;
  @Input() readonly = true;
  @Input() selected = false;
  @Output() selectionChange = new EventEmitter<any>();

  constructor(public dialog: MatDialog) {}

  isImageLoaded = false;
  apiBase = environment.apiBase;

  getLinkImage() {
    return ProductsService.getLinkImage(
      this.data.shootAssetPlaceholderThumbnailUri!
    );
  }

  checkImageLoaded() {
    this.isImageLoaded = true;
  }
}
