import { IBase } from '../base';

export interface IBatch extends IBase {
  jobNumber: string;
  name: string;
  description: string;
  organizationName: string;
  productCount: number;
  productsReceivedCount: number;
  productsReceivedGoodCount: number;
  productsReceivedOkayCount: number;
  productsReceivedPoorCount: number;
  productsAssignedMasterSuiteCount: number;
  productsIngestedCount: number;
  productsShotCount: number;
  productsEnhancedCount: number;
  productsInternallyApprovedCount: number;
  productsInternallyRejectedCount: number;
  productsApprovedCount: number;
  productsRejectedCount: number;
  productsApprovedOutputsCount: number;
  productsRejectedOutputsCount: number;
  productsDispatchedCount: number;
  productsReleasedCount: number;

  clientReview?: ClientReview;
  status?: EStateEntity;
  tags: string[];
}
export interface ClientReview {
  pending: number;
  active: number;
  rejected: number;
  complete: number;
}

export enum EStateEntity {
  IN_Progress = 'InProgress',
  COMPLETE = 'Complete',
  PENDING = 'Pending',
}
