<!--
  Shared Toolbar
 -->
<mat-toolbar color="primary">
  <button
    mat-icon-button
    class="example-icon"
    aria-label="Example icon-button with menu icon"
    (click)="sidenav.toggle()"
  >
    <mat-icon class="menu-icon">menu</mat-icon>
  </button>
  <span class="spacer"></span>
  <img src="./assets/MMAPT_logo.png" class="logo" alt="" (click)="home()" />
  <div class="toolbar-content">Cloud</div>
  <span class="spacer"></span>
  <app-auto-suggestion style="height: 100%"></app-auto-suggestion>
  <a *ngIf="isStaff" routerLink="product-filter" class="filters">
    <mat-icon matTooltip="Filtered Search" class="icon"
      >filter_list_alt
    </mat-icon>
  </a>

  <app-notification [isStaff]="isStaff"></app-notification>
  <div *ngIf="user != null" class="nav-user" [matMenuTriggerFor]="menu">
    <div class="imageContainer">
      <img
        class="image"
        *ngIf="user.profileImageUrl !== null; else placeholder"
        [src]="apiBase + user.profileImageUrl | secure | async"
        alt=""
      />
      <ng-template #placeholder>
        <img class="image" src="./assets/profile-placeholder.png" alt="" />
      </ng-template>
    </div>
  </div>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="openMyPreference()">My Preference</button>
    <button mat-menu-item (click)="onLogoutClick()">Logout</button>
  </mat-menu>
</mat-toolbar>
<!--
  Shared Sidenav
 -->
<mat-sidenav-container class="sidenav-container" autosize>
  <mat-sidenav
    #sidenav
    [style]="{ 'z-index': mode == 'over' ? 3 : 0 }"
    class="sidenav"
    [mode]="mode"
    (openedChange)="openMenu($event)"
  >
    <div *ngIf="user != null" class="user">
      <div class="imageContainer">
        <img
          class="image"
          *ngIf="user.profileImageUrl !== null; else placeholder"
          [src]="apiBase + user.profileImageUrl | secure | async"
          alt=""
        />
        <ng-template #placeholder>
          <img class="image" src="./assets/profile-placeholder.png" alt="" />
        </ng-template>
      </div>
      <div class="user-details">
        <div class="user-name">
          {{ user.name.split(" ")[0] }}
        </div>
      </div>
    </div>
    <mat-list class="nav" *ngFor="let route of routeLinks">
      <a
        mat-list-item
        routerLinkActive="active-link"
        (click)="itemClicked()"
        class="hover"
        routerLink="{{ route.link }}"
      >
        <div class="route-item">
          <mat-icon>{{ route.icon }}</mat-icon>
          <p>{{ route.name }}</p>
        </div>
      </a>
    </mat-list>
    <div class="version-number">version {{ version }}: {{ dateTime }}</div>
  </mat-sidenav>
  <mat-sidenav-content style="display: flex; justify-content: center">
    <div *ngIf="!isLoading; else loadingRef" class="content-container">
      <router-outlet></router-outlet>
      <div class="r-bottom"></div>
    </div>
    <ng-template #loadingRef>
      <div class="loading-overlay">
        <mat-spinner></mat-spinner>
      </div>
    </ng-template>
  </mat-sidenav-content>
</mat-sidenav-container>
