import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
// Material Modules
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
// Services
import { ProductsService } from 'src/app/services/products.service';
// Models
import { IProduct } from 'src/app/models/products/product';
import { FormControl, Validators } from '@angular/forms';
import { ValidationErrors } from 'src/app/models/validationErrors';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-product-update',
  templateUrl: './product-update.component.html',
  styleUrls: ['./product-update.component.scss'],
})
export class ProductUpdateComponent implements OnInit, OnDestroy {
  product!: IProduct;
  productSubscription: any;
  errorState: Boolean = false;
  errorMessages: ValidationErrors[] = [];
  validatorProperties: string[] = [];
  serverErrorMessage: String = '';

  barcodeFormControl: FormControl;
  referenceFormControl: FormControl;
  descriptionFormControl: FormControl;
  private destroy$ = new Subject();
  loading = true;

  constructor(
    private productsService: ProductsService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ProductUpdateComponent>,
    private _alert: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) private data: IProduct
  ) {
    this.product = data;
    this.barcodeFormControl = new FormControl('', [Validators.required]);
    this.referenceFormControl = new FormControl('', [Validators.required]);
    this.descriptionFormControl = new FormControl('', [Validators.required]);
  }

  ngOnInit(): void {
    this.barcodeFormControl.disable();
    this.referenceFormControl.disable();
    this.descriptionFormControl.disable();
    this.productSubscription = this.productsService
      .detailsProduct(this.product.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (product) => {
          if (product) {
            this.product = product;
            this.loading = false;
            this.barcodeFormControl.enable();
            this.referenceFormControl.enable();
            this.descriptionFormControl.enable();
          }
        },
        (error) => {
          this.serverErrorMessage =
            'There was an error loading the Products from the server. Please restart the system and try again';
          console.log(error.message);
        }
      );
  }

  updateProduct() {
    this.productsService
      .updateProduct(this.product)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          this._alert.open('Successfully Updated Product', 'close', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 2000,
            panelClass: ['success'],
          });
          this.dialogRef.close(this.product);
        },
        (error) => {
          this.errorState = true;
          console.log(error);
          error.error.validationErrors.forEach((error: ValidationErrors) => {
            this.errorMessages.push(error);
            this.validatorProperties.push(error.property);
            this.barcodeFormControl.markAllAsTouched();
            if (error.property == 'Barcode') {
              this.barcodeFormControl.setErrors({
                serverErrors: 'validation errors',
              });
            }
            this.referenceFormControl.markAllAsTouched();
            if (error.property == 'Reference') {
              this.referenceFormControl.setErrors({
                serverErrors: 'validation errors',
              });
            }
            this.descriptionFormControl.markAllAsTouched();
            if (error.property == 'Description') {
              this.descriptionFormControl.setErrors({
                serverErrors: 'validation errors',
              });
            }
          });
        }
      );
  }

  validationMessage(property: string) {
    return this.errorMessages.find((error) => error.property == property)
      ?.message;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
