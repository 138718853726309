<div class="content">
    <div class="content-header">
        <div class="title">
            Settings Creation
        </div>
    </div>
    <div *ngIf="!!setting" style="justify-content: space-between; display: flex; align-items: center;">
        <mat-form-field appearance="outline" color="primary">
            <mat-label>Setting Name</mat-label>
            <input matInput required [formControl]="input" [(ngModel)]="setting.name">
            <mat-error *ngIf="validatorProperties.includes('Name')">
                {{validationMessage('Name')}}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" color="primary" style="width: 15%;">
            <mat-label>Setting Type</mat-label>
            <mat-select [(ngModel)]="setting.type" (selectionChange)="onSettingTypeChange()">
                <mat-option *ngFor="let type of types" [value]="type">
                    {{type}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-checkbox color="primary" class="example-margin" [checked]="stillCheck" [(ngModel)]="stillCheck">
            Stills
        </mat-checkbox>
        <mat-checkbox color="primary" class="example-margin" [checked]="videoCheck" [(ngModel)]="videoCheck">
            Videos
        </mat-checkbox>
    </div>

    <div style="justify-content: space-between; display: flex;">
        <div *ngIf="setting && setting.still && stillCheck" class="sub-content">
            <div style="justify-content: space-between; display: flex;">
                <div class="heading">Stills</div>
                <span class="spacer"></span>
                <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="camera_alt"
                    style="font-size: 50px; height: 50px; width: 50px;"></mat-icon>
            </div>
            <mat-card style="margin-bottom: 10px;">
                <mat-card-header>
                    <mat-card-title class="asset-heading">
                        Lights
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <mat-grid-list [cols]="setting.still.lights.length/2" rowHeight="100px">
                        <mat-grid-tile *ngFor="let keyValuePair of setting.still.lights" colspan="1">
                            <mat-form-field appearance="outline" subscriptSizing="dynamic">
                                <mat-label>{{keyValuePair.key | titlecase}}</mat-label>
                                <input *ngIf="keyValuePair.key === 'colourTemperature';else table" type="number"
                                    step="100" [min]="1700" [max]="9600" matInput [(ngModel)]="keyValuePair.value"
                                    (blur)="validateColourTemperature(keyValuePair)">
                                <ng-template #table>
                                    <input *ngIf="keyValuePair.key == 'table';else other" type="number" step="1"
                                        [min]="0" [max]="100" matInput [(ngModel)]="keyValuePair.value"
                                        (blur)="validate(keyValuePair,0,100)">
                                </ng-template>
                                <ng-template #other>
                                    <input type="number" step="1" [min]="0" [max]="255" matInput
                                        [(ngModel)]="keyValuePair.value" (blur)="validate(keyValuePair,0,255)">
                                </ng-template>
                            </mat-form-field>
                        </mat-grid-tile>
                    </mat-grid-list>
                </mat-card-content>
            </mat-card>

            <mat-card style="margin-bottom: 10px;" *ngIf="setting.still.camera != undefined">
                <mat-card-header>
                    <mat-card-title class="asset-heading">
                        Camera
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <mat-grid-list [cols]="setting.still.camera.length" rowHeight="100px">
                        <mat-grid-tile *ngFor="let keyValuePair of setting.still.camera" colspan="1">
                            <mat-form-field appearance="outline" subscriptSizing="dynamic">
                                <mat-label>{{keyValuePair.key | titlecase}}</mat-label>
                                <mat-select *ngIf="keyValuePair.key === 'iso';else shutter" type="number"
                                    [(ngModel)]="keyValuePair.value">
                                    <mat-option *ngFor="let iso of isoValues" [value]='iso'> {{iso}} </mat-option>
                                </mat-select>
                                <ng-template #shutter>
                                    <mat-select *ngIf="keyValuePair.key === 'shutter';else aperture" type="number"
                                        [(ngModel)]="keyValuePair.value">
                                        <mat-option *ngFor="let shutter of shutterValues" [value]='shutter'> {{shutter}}
                                        </mat-option>
                                    </mat-select>
                                </ng-template>
                                <ng-template #aperture>
                                    <input type="number" step="1" [min]="10" [max]="22" matInput
                                        [(ngModel)]="keyValuePair.value" (blur)="validate(keyValuePair,10,22)">
                                </ng-template>
                            </mat-form-field>
                        </mat-grid-tile>
                    </mat-grid-list>
                </mat-card-content>
            </mat-card>
        </div>
        <div *ngIf="setting && setting.video && videoCheck" class="sub-content">
            <div style="justify-content: space-between; display: flex;">
                <div class="heading">Videos</div>
                <span class="spacer"></span>
                <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="video_camera_back"
                    style="font-size: 50px; height: 50px; width: 50px;"></mat-icon>
            </div>

            <mat-card style="margin-bottom: 10px;">
                <mat-card-header>
                    <mat-card-title class="asset-heading">
                        Lights
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <mat-grid-list [cols]="setting.video.lights.length/2" rowHeight="100px">
                        <mat-grid-tile *ngFor="let keyValuePair of setting.video.lights" colspan="1">
                            <mat-form-field appearance="outline" subscriptSizing="dynamic">
                                <mat-label>{{keyValuePair.key | titlecase}}</mat-label>
                                <input *ngIf="keyValuePair.key === 'colourTemperature';else table" type="number"
                                    step="100" [min]="1700" [max]="9600" matInput [(ngModel)]="keyValuePair.value"
                                    (blur)="validateColourTemperature(keyValuePair)">
                                <ng-template #table>
                                    <input *ngIf="keyValuePair.key == 'table';else other" type="number" step="1"
                                        [min]="0" [max]="100" matInput [(ngModel)]="keyValuePair.value"
                                        (blur)="validate(keyValuePair,0,100)">
                                </ng-template>
                                <ng-template #other>
                                    <input type="number" step="1" [min]="0" [max]="255" matInput
                                        [(ngModel)]="keyValuePair.value" (blur)="validate(keyValuePair,0,255)">
                                </ng-template>
                            </mat-form-field>
                        </mat-grid-tile>
                    </mat-grid-list>
                </mat-card-content>
            </mat-card>
            <mat-card style="margin-bottom: 10px;" *ngIf="setting.video.camera != undefined">
                <mat-card-header>
                    <mat-card-title class="asset-heading">
                        Camera
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <mat-grid-list [cols]="setting.video.camera.length" rowHeight="100px">
                        <mat-grid-tile *ngFor="let keyValuePair of setting.video.camera" colspan="1">
                            <mat-form-field appearance="outline" subscriptSizing="dynamic">
                                <mat-label>{{keyValuePair.key | titlecase}}</mat-label>
                                <mat-select *ngIf="keyValuePair.key === 'iso';else shutter" type="number"
                                    [(ngModel)]="keyValuePair.value">
                                    <mat-option *ngFor="let iso of isoValues" [value]='iso'> {{iso}} </mat-option>
                                </mat-select>
                                <ng-template #shutter>
                                    <mat-select *ngIf="keyValuePair.key === 'shutter';else aperture" type="number"
                                        [(ngModel)]="keyValuePair.value">
                                        <mat-option *ngFor="let shutter of shutterValues" [value]='shutter'> {{shutter}}
                                        </mat-option>
                                    </mat-select>
                                </ng-template>
                                <ng-template #aperture>
                                    <input type="number" step="1" [min]="10" [max]="22" matInput
                                        [(ngModel)]="keyValuePair.value" (blur)="validate(keyValuePair,10,22)">
                                </ng-template>
                            </mat-form-field>
                        </mat-grid-tile>
                    </mat-grid-list>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <div class="content-actions">
        <span class="spacer"></span>
        <button mat-stroked-button (click)="backClicked()">Cancel</button>
        <button mat-button color="buttonSuccess" style="margin-left: 20px;" (click)="createSetting()"
            [disabled]="stillCheck == false && videoCheck == false">Create</button>
    </div>
</div>