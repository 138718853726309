<div class="content-list content">
  <div class="content-list__head">
    <div class="title">Master Suites</div>
    <span class="spacer"></span>

    <mat-form-field
      *ngIf="currentLoaded"
      appearance="outline"
      class="action-form"
      style="margin-right: 20px; width: 20%"
    >
      <mat-label>Client</mat-label>
      <input
        type="text"
        placeholder="Pick one"
        aria-label="Client"
        matInput
        [(ngModel)]="currentOrganization"
        [formControl]="organizationControl"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete
        autoActiveFirstOption
        #auto="matAutocomplete"
        [displayWith]="displayFn"
      >
        <mat-option
          *ngFor="let organization of organizationFilteredOptions | async"
          [value]="organization"
        >
          {{ organization.name }}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="invalidOrganization == true">
        {{ invalidOrganizationMessage }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" style="width: 20%">
      <mat-label>Search</mat-label>
      <input matInput type="text" (keyup)="applyFilter($event)" />
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
  </div>

  <div *ngIf="masterSuitesFound" class="break">
    <button
      mat-fab
      extended
      [color]="'buttonSuccess'"
      [disabled]="currentOrganization == null"
      (click)="createMasterSuite()"
      class="button"
    >
      <mat-icon>add</mat-icon>
      Create
    </button>
  </div>

  <div *ngIf="masterSuitesFound" class="content-container">
    <div class="content-list__list">
      <table mat-table [dataSource]="dataSource" matSort>
        <!-- Batch Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Master Suite Name
          </th>
          <td mat-cell *matCellDef="let mastersuite">
            {{ mastersuite.name }}
          </td>
        </ng-container>

        <!-- Batch Name Column -->
        <ng-container matColumnDef="touchCount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Touch Count</th>
          <td mat-cell *matCellDef="let mastersuite">
            {{ mastersuite.touchCount }}
          </td>
        </ng-container>

        <!-- Batch Name Column -->
        <ng-container matColumnDef="variantCount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Variant Count
          </th>
          <td mat-cell *matCellDef="let mastersuite">
            {{ mastersuite.settingsVariantCount }}
          </td>
        </ng-container>

        <!-- Batch Name Column -->
        <ng-container matColumnDef="enhancementVariantCount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>EV Count</th>
          <td mat-cell *matCellDef="let mastersuite">
            {{ mastersuite.enhancementVariantCount }}
          </td>
        </ng-container>

        <!-- Product Count Column -->
        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sort>
            Creation Date
          </th>
          <td mat-cell *matCellDef="let mastersuite">
            <div
              matTooltip="{{
                mastersuite.createdAt
                  | dateAgo : currentConfigDate : true
                  | async
              }}"
              matTooltipPosition="before"
            >
              {{ mastersuite.createdAt | dateAgo : currentConfigDate | async }}
            </div>
          </td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="Actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let mastersuite" style="text-align: right">
            <button
              mat-icon-button
              matTooltip="Info"
              color="info"
              class="mat-elevation-z0"
              (click)="updateMasterSuite(mastersuite)"
            >
              <mat-icon>info</mat-icon>
            </button>
            <button
              [matMenuTriggerFor]="actionsMenu"
              mat-icon-button
              matTooltip="More"
              color="primary"
              class="mat-elevation-z0"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #actionsMenu="matMenu">
              <button
                *ngIf="isAdmin"
                mat-menu-item
                (click)="deleteMasterSuite(mastersuite)"
              >
                <mat-icon matTooltip="Delete" color="error">delete</mat-icon>
                <span>Delete</span>
              </button>
              <button mat-menu-item (click)="copyMasterSuite(mastersuite)">
                <mat-icon matTooltip="Copy" color="primary"
                  >content_copy</mat-icon
                >
                <span>Copy</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <mat-paginator
      class="mat-paginator-sticky"
      [pageSizeOptions]="[10, 20, 50, 100]"
      showFirstLastButtons
      [pageSize]="currentConfigDate.pageSize"
      (page)="setPageSizePreference($event)"
    >
    </mat-paginator>
  </div>
</div>
