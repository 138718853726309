import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-unsaved-changes-warning',
  templateUrl: './unsaved-changes-warning.component.html',
  styleUrl: './unsaved-changes-warning.component.scss',
})
export class UnsavedChangesWarningComponent {
  constructor(public dialogRef: MatDialogRef<UnsavedChangesWarningComponent>) {}
  onOk() {
    this.dialogRef.close(true);
  }
}
