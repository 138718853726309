import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { IPackage } from '../models/packages/package';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class PackagesService {
  constructor(private http: HttpClient) {}

  listPackages(orgId: string | null): Observable<IPackage[]> {
    var url = `${environment.apiBase}/packages?org_id=${orgId}`;
    console.log('Getting Packages');
    return this.http.get<IPackage[]>(url, httpOptions);
  }

  detailPackage(id: string): Observable<IPackage> {
    console.log('Getting Package Detials: ', id);
    var url = `${environment.apiBase}/packages/${id}`;
    return this.http.get<IPackage>(url, httpOptions);
  }

  createPackage(newPackage: IPackage): Observable<any> {
    var url = `${environment.apiBase}/packages`;
    console.log('Creating New Package: ', newPackage);
    return this.http.post<IPackage>(url, newPackage, httpOptions);
  }

  deletePackage(_package: IPackage): Observable<any> {
    console.log('Deleting Package: ', _package);
    var url = `${environment.apiBase}/packages/${_package.id}`;
    return this.http.delete<any>(url, httpOptions);
  }

  updatePackage(updatePackage: IPackage): Observable<any> {
    var url = `${environment.apiBase}/packages/${updatePackage.id}`;
    console.log('Updating Package: ', updatePackage);
    return this.http.put<IPackage>(url, updatePackage, httpOptions);
  }
}
