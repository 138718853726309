<div class="pd-search">
  <div class="pd-search__header">
    <div class="name">{{ data.barcode || data.reference }}</div>
    <div
      class="external-status"
      [ngClass]="{
      approved: data.expectedPostAssetCount > 0 && data.expectedPostAssetCount === data.approvedPostAssetCount,
      rejected: data.rejectedPostAssetCount > 0,
    }"
    ></div>
  </div>
  <div class="pd-search__thumb-img" (click)="onEdit()">
    <img
      class="zoom"
      [ngClass]="{ blur: !data.id }"
      [src]="getLinkImage(data.thumbnailUri) | secure | async"
      [alt]="getLinkImage(data.thumbnailUri) | secure | async"
      matTooltipPosition="above"
      [hidden]="!isImageLoaded"
      *ngIf="!!data.thumbnailUri"
      (load)="isImageLoaded = true"
    />

    <div class="pd-search__no-img" *ngIf="!data.thumbnailUri || !isImageLoaded">
      <img
        [src]="'assets/image-placeholder.jpg'"
        alt="Image Error"
        matTooltipPosition="above"
      />
    </div>
  </div>
  <div class="pd-search__actions">
    <div class="spacer"></div>
    <div class="action-ctn">
      <div
        *ngIf="filter"
        class="action-ctn__sub-item"
        matTooltip="{{
          data.latestAction | dateAgo : currentConfigDate : true | async
        }}"
      >
        {{ data.latestAction | dateAgo : currentConfigDate | async }}
      </div>
      <span class="spacer"></span>
      <button mat-icon-button (click)="onEdit()" color="info">
        <mat-icon>info</mat-icon>
      </button>
      <button
        *ngIf="!filter"
        [matMenuTriggerFor]="actionsMenu"
        mat-icon-button
        color="primary"
        class="mat-elevation-z0"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #actionsMenu="matMenu" xPosition="before">
        <button
          mat-menu-item
          (click)="downloadAssets()"
          [disabled]="!data.externalApproved"
        >
          <span>Download Outputs</span>
          <mat-icon>download</mat-icon>
        </button>
      </mat-menu>
    </div>
  </div>
</div>
