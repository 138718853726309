import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-product-progress',
  templateUrl: './product-progress.component.html',
  styleUrls: ['./product-progress.component.scss'],
})
export class ProductProgressComponent {
  @Input() activeStep = '';
  @Input() config: any = [];
  @Output() change = new EventEmitter();

  indexActive = 0;

  ngOnInit() {
    this.setIndexActive();
  }

  ngOnChanges() {
    this.setIndexActive();
  }

  setIndexActive() {
    this.indexActive = (this.config).findIndex(
      (el: any) => el.id === this.activeStep
    );
  }

  onChange(id: string) {
    this.change.emit(id);
  }
}
