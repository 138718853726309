import { IBase as IBase } from '../base';
import { ProductTouch } from './actions/ingest/productTouch';
import { ProductReceival } from './actions/receive/productReceival';
import { EAssetType } from './asset';

export interface IProduct extends IBase {
  barcode: string;
  description: string;
  reference: string;
  key?: string;
  batchId: string | null;
  batchName: string;
  organizationName: string;
  masterSuiteId: string | null;
  masterSuiteName: string;
  settingsVariantId: string | null;
  settingsVariantName: string;
  masterSuiteEnhancementVariantId: string | null;
  productLink: string;
  notes: string;
  externalApproved: boolean;
  thumbnailUri?: string;
  externalShopifyId?: string;

  //Receivals
  receivedCount: number;
  receivedGoodCount: number;
  receivedOkayCount: number;
  receivedPoorCount: number;
  lastReceivedAt: string;

  //Ingestion
  expectedTouchCount: number;
  ingestedTouchCount: number;
  shotTouchCount: number;
  lastIngestedAt: number;

  //Shoot Assets
  expectedShootAssetCount: number;
  shootAssetCount: number;
  approvedShootAssetCount: number;
  rejectedShootAssetCount: number;
  lastShotAt: string;
  lastShootAssetApprovedAt: string;

  //Post Assets
  expectedPostAssetCount: number;
  expectedPostAssetVideosCount: number;
  expectedPostAssetStillsCount: number;
  postAssetCount: number;
  postAssetVideosCount: number;
  postAssetStillsCount: number;
  lastEnhancedAt: string;
  internallyApprovedPostAssetCount: number;
  internallyRejectedPostAssetCount: number;
  approvedPostAssetCount: number;
  rejectedPostAssetCount: number;
  lastPostAssetApprovedAt: string;

  //Output Assets
  expectedOutputAssetCount: number;
  expectedOutputAssetStillsCount: number;
  expectedOutputAssetVideosCount: number;
  outputAssetCount: number;
  approvedOutputAssetCount: number;
  rejectedOutputAssetCount: number;
  lastOutputAssetApprovedAt: string;
  lastOutputAssetRejectedAt: string;
  releasedInCollection: boolean;
  outdatedRelease: boolean;
  releasedAt: string;

  dispatchedCount: number;
  lastDispatchedAt: string;

  productReceivals: ProductReceival[];
  productTouches: ProductTouch[];
  loadedImage?: boolean;
  states?: IProductStates;
  latestAction?: string;
}

export interface ProductSearch extends IProduct {
  barcode: string;
  createdAt: string;
  notes: string;
  id: string;
  reference: string;
  thumbnailUri: string;
  organizationId: string;
  organizationName: string;
  selected?: boolean;
  latestAction?: string;
}

export interface IProductStates {
  receivalState: EProgressState[];
  masterSuiteState: EProgressState[];
  ingestionState: EProgressState[];
  productionState: EProgressState[];
  productionReviewState: EProgressState[];
  enhancementState: EProgressState[];
  reviewState: EProgressState[];
  outputsState: EProgressState[];
  dispatchState: EProgressState[];
}

export enum EProgressState {
  PENDING = 'Pending',
  ACTIVE = 'Active',
  COMPLETE = 'Complete',
  ERROR = 'Error',
  INFO = 'Info',
}

export interface IPreDefinedFilter {
  key: string;
  value: string;
  icon: string;
  results: ProductSearch[];
}
