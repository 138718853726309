import { Injectable } from '@angular/core';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root',
})
export class ClipboardService {
  constructor(private alertService: AlertService) {}

  copyUrlAndTextToClipboard(url: string, visibleText: string) {
    const htmlLink: string = `<a href="${url}">${visibleText}</a>`;

    console.info(`url: ${url}, visibleText: ${visibleText}`);
    const clipboardItem = new ClipboardItem({
      'text/html': new Blob([htmlLink], { type: 'text/html' }),
      'text/plain': new Blob([url], { type: 'text/plain' }),
    });

    navigator.clipboard
      .write([clipboardItem])
      .then(() => {
        this.alertService.infoToast(`Copied url and text to clipboard`);
      })
      .catch((err) => {
        this.alertService.errorToast(err.message);
      });
  }
}
