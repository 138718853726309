import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationExtras, Router } from '@angular/router';
import { DeleteModalComponent } from 'src/app/components/shared/delete-modal/delete-modal.component';
import { Setting } from 'src/app/models/settings/setting';
import { SettingsService } from 'src/app/services/setting.service';
import { OrganizationsService } from 'src/app/services/organizations.service';
import { IOrganization } from 'src/app/models/organizations/organization';
import { Observable, Subject, map, startWith, takeUntil } from 'rxjs';
import { FormControl } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { IPreference, IUserProfile } from 'src/app/models/users/userProfile';
import { SignalrService } from 'src/app/services/signalr.service';
import { SelectOrganizationComponent } from 'src/app/components/shared/select-organization/select-organization.component';

@Component({
  selector: 'app-setting-list',
  templateUrl: './setting-list.component.html',
  styleUrls: ['./setting-list.component.scss'],
})
export class SettingListComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private settingsService: SettingsService,
    private organizationsService: OrganizationsService,
    public dialog: MatDialog,
    public _alert: MatSnackBar,
    private authService: AuthService,
    private signalrService: SignalrService
  ) {
    this.initialData();
  }
  isAdmin: boolean = false;
  private destroy$ = new Subject();

  user!: IUserProfile | null;
  invalidOrganization: boolean = false;
  invalidOrganizationMessage: string = '';
  _currentOrganization: IOrganization | null = null;
  currentConfigDate!: IPreference;
  get currentOrganization() {
    return this._currentOrganization;
  }
  set currentOrganization(value: IOrganization | null) {
    if (value != null) {
      if (value.id != null) {
        this._currentOrganization = value;
        this.organizationsService.setCurrentOrg(value);
        this.organizationControl.markAsUntouched();
        this.signalrService.leaveGroup(this.listGroup);
        this.signalrService.connectState.subscribe({
          next: (data: boolean) => {
            if (data) {
              this.joinGroups(value.id!);
            }
          },
        });
        this.subscribeToData();
        this.listSettings(value);
      } else {
        this.invalidOrganization = true;
        this.invalidOrganizationMessage = 'Invalid Organization';
        this.organizationControl.markAllAsTouched();
        this.organizationControl.setErrors({
          serverErrors: this.invalidOrganizationMessage,
        });
      }
    }
  }
  displayFn(option: IOrganization | null) {
    return option ? option.name : '';
  }
  private _filter(name: string): IOrganization[] {
    const filterValue = name.toLowerCase();
    return this.organizations.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }
  parseOrganizationValue(value: any) {
    const name = typeof value === 'string' ? value : value?.name;
    return name ? this._filter(name as string) : this.organizations.slice();
  }
  organizations: IOrganization[] = [];
  organizationControl = new FormControl();
  organizationFilteredOptions!: Observable<IOrganization[]>;
  organizationsSubscription: any;
  settingsSubscription: any;
  settings: Setting[] = [];
  settingsFound: boolean = false;
  errorMessages: string[] = [];
  currentLoaded: boolean = false;

  displayedColumns: string[] = [
    'name',
    'asset',
    'settingType',
    'createdAt',
    'Actions',
  ];
  dataSource = new MatTableDataSource<Setting>();
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  serverErrorMessage: String = '';

  filter: any;
  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toUpperCase();
  }

  initialData() {
    this.isAdmin = this.authService.getRole('admin');
    this.currentOrganization = this.organizationsService.getCurrentOrg();
    this.currentLoaded = true;
  }

  ngOnInit(): void {
    this.user = this.authService.getAuthInformation();
    this.organizationsSubscription = this.organizationsService
      .listOrganizations()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (organizations: any) => {
          if (organizations) {
            this.organizations = organizations;
            console.log('Organizations Found: ', organizations);
            this.organizationFilteredOptions =
              this.organizationControl.valueChanges.pipe(
                startWith(''),
                map((value) => this.parseOrganizationValue(value))
              );
          }
        },
        (error) => {
          console.log(error);
        }
      );

    this.authService.currentPreference.subscribe((res) => {
      this.currentConfigDate = res;
    });
  }

  listSettings(organization: IOrganization) {
    if (organization.id != null) {
      this.settingsFound = true;
      this.settingsSubscription = this.settingsService
        .listSettings(organization.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (settings) => {
            if (settings) {
              console.log('Settings Found: ', settings);
              this.settings = settings;
              this.dataSource = new MatTableDataSource<Setting>(this.settings);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
            }
          },
          (error) => {
            this.settingsFound = false;
            console.log(error);
          }
        );
    } else {
      console.log('No Organization Selected');
    }
  }

  createSetting() {
    const navigationExtras: NavigationExtras = {
      state: { orgId: this.currentOrganization!.id },
    };
    this.router.navigate(['settings/create'], navigationExtras);
  }

  copySetting(settingOriginal: Setting) {
    const navigationExtras: NavigationExtras = {
      state: { orgId: this.currentOrganization!.id, setting: settingOriginal },
    };
    this.router.navigate(['settings/create'], navigationExtras);
  }

  copySettingAcrossOrganization(settingOriginal: Setting) {
    const dialogRef = this.dialog.open(SelectOrganizationComponent, {
      data: {
        organizations: this.organizations,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: any) => {
        if (result.confirmed) {
          this.organizationsService.setCurrentOrg(result.organization);
          const navigationExtras: NavigationExtras = {
            state: {
              orgId: result.organization.id,
              setting: settingOriginal,
            },
          };
          this.router.navigate(['settings/create'], navigationExtras);
        }
      });
  }

  updateSetting(setting: Setting) {
    this.router.navigate([`settings/${setting.id}/update`]);
  }

  deleteSetting(setting: Setting) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      data: {
        header: `Delete Setting: ${setting.name}`,
        message:
          'Are you sure you want to delete this package along with all information?',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.settingsService
            .deleteSetting(setting)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              (data) => {
                this._alert.open('Successfully Deleted', 'close', {
                  horizontalPosition: 'center',
                  verticalPosition: 'bottom',
                  duration: 2000,
                  panelClass: ['success'],
                });
                // Fetch New Products
                this.listSettings(this.currentOrganization!);
              },
              (response) => {
                console.log(response);
                this._alert.open(
                  'Validation Failure: ' +
                    response.error.validationErrors[0].message,
                  'close',
                  {
                    horizontalPosition: 'center',
                    verticalPosition: 'bottom',
                    duration: 10000,
                    panelClass: ['error'],
                  }
                );
              }
            );
        }
      });
  }

  listGroup: string = '';
  joinGroups(organizationId: string) {
    this.signalrService
      .joinIdGroup('JoinSettingsListGroup', organizationId)
      ?.then((group: string) => (this.listGroup = group));
  }

  subscribeToData() {
    this.signalrService.settingsData.subscribe({
      next: (data: any) => {
        if (data.id) {
          var index = this.settings.findIndex((p) => p.id == data.id);
          if (index >= 0) {
            this.settings[index] = data;
            this.dataSource.data = this.settings;
          } else {
            this.settings.push(data);
            this.dataSource.data = this.settings;
          }
        }
      },
    });
  }

  setPageSizePreference(event: any) {
    if (event.pageSize != this.currentConfigDate.pageSize) {
      this.currentConfigDate.pageSize = event.pageSize;
      let payload = {
        name: this.user?.name,
        preference: this.currentConfigDate,
      };
      this.authService.updatePreference(payload).subscribe((res) => {
        console.log(res);
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.signalrService.leaveGroup(this.listGroup);
  }
}
