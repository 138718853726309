<div class="download-asset-mode-title">
    <h1 class="title">Shopify Sync Output Assets</h1>
    <mat-spinner *ngIf="isLoading" class="spinner"></mat-spinner>
  </div>
  <mat-dialog-content class="download-asset-model">
    <div class="output-setting" *ngIf="!isLoading">
      <div class="header">
        <i class="material-icons">crop</i>
        <h3 class="title">Output Formats</h3>
      </div>
      <div class="item" *ngFor="let formats of outputForms">
        <div class="info">
          <mat-checkbox
            class="custom-checkbox"
            (change)="formats.get('checked')?.setValue($event.checked)"
            color="primary"
          ></mat-checkbox>
          <span class="name">{{ formats.get("name")?.value }} </span>
          <mat-checkbox
            [disabled]="!formats.get('checked')!.value"
            *ngIf="formats.get('configTextStills')?.value.length > 0"
            style="margin-left: 20px; width: 35%"
            class="custom-checkbox"
            (change)="formats.get('stills')?.setValue($event.checked)"
            color="primary"
          >
            <div
              class="configText"
              [innerHTML]="formats.get('configTextStills')?.value"
            ></div
          ></mat-checkbox>
          <mat-checkbox
            [disabled]="!formats.get('checked')!.value"
            *ngIf="formats.get('configTextVideos')?.value.length > 0"
            style="margin-left: 20px; width: 35%"
            class="custom-checkbox"
            (change)="formats.get('videos')?.setValue($event.checked)"
            color="primary"
          >
            <div
              class="configText"
              [innerHTML]="formats.get('configTextVideos')?.value"
            ></div>
          </mat-checkbox>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="action">
    <mat-checkbox
      [formControl]="overwrite"
      class="custom-checkbox"
      color="primary"
    >
      Overwrite
    </mat-checkbox>
    <span class="spacer"></span>
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button
      mat-stroked-button
      color="buttonSuccess"
      class="save"
      cdkFocusInitial
      (click)="generate()"
    >
      <mat-icon *ngIf="isDownload"
        ><mat-spinner color="accent" diameter="20"> </mat-spinner></mat-icon
      >Sync
    </button>
  </mat-dialog-actions>
