import { Component, OnInit, OnDestroy } from '@angular/core';
// Material Modules
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
// Services
import { BatchesService } from 'src/app/services/batches.services';
import { ProductsService } from 'src/app/services/products.service';
// Models
import { ProductNew } from 'src/app/models/products/productNew';
import { ValidationErrors } from 'src/app/models/validationErrors';
import { IBatch } from 'src/app/models/batches/batch';
import { FormControl, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-product-create',
  templateUrl: './product-create.component.html',
  styleUrls: ['./product-create.component.scss'],
})
export class ProductCreateComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  currentBatch!: IBatch;
  errorState: Boolean = false;
  errorMessages: ValidationErrors[] = [];
  validatorProperties: string[] = [];
  newProduct: ProductNew = new ProductNew();

  barcodeFormControl: FormControl;
  referenceFormControl: FormControl;
  descriptionFormControl: FormControl;

  constructor(
    private productsService: ProductsService,
    private batchesService: BatchesService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ProductCreateComponent>,
    private _alert: MatSnackBar
  ) {
    this.barcodeFormControl = new FormControl('', [Validators.required]);
    this.referenceFormControl = new FormControl('', [Validators.required]);
    this.descriptionFormControl = new FormControl('', [Validators.required]);
  }
  ngOnInit(): void {
    this.currentBatch = this.batchesService.getCurrentBatch();
  }

  createProduct() {
    this.errorMessages = [];
    this.newProduct.batchId = this.currentBatch.id;
    this.productsService
      .createProduct(this.newProduct)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          this._alert.open('Successfully Created Product', 'close', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 2000,
            panelClass: ['success'],
          });
          this.dialogRef.close();
        },
        (error) => {
          this.errorState = true;
          if (error.status === 422) {
            this.errorMessages = [];
            this.validatorProperties = [];
            error.error.validationErrors.forEach((error: ValidationErrors) => {
              this.errorMessages.push(error);
              this.validatorProperties.push(error.property);
              this.barcodeFormControl.markAllAsTouched();
              if (error.property == 'Barcode') {
                this.barcodeFormControl.setErrors({
                  serverErrors: 'validation errors',
                });
              }
              this.referenceFormControl.markAllAsTouched();
              if (error.property == 'Reference') {
                this.referenceFormControl.setErrors({
                  serverErrors: 'validation errors',
                });
              }
              this.descriptionFormControl.markAllAsTouched();
              if (error.property == 'Description') {
                this.descriptionFormControl.setErrors({
                  serverErrors: 'validation errors',
                });
              }
            });
          }
        }
      );
  }
  validationMessage(property: string) {
    return this.errorMessages.find((error) => error.property == property)
      ?.message;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
