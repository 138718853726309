import { Component, Input, OnInit, input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from '@microsoft/signalr';
import { combineLatest } from 'rxjs';
import { DeleteModalComponent } from 'src/app/components/shared/delete-modal/delete-modal.component';
import { WarningModalComponent } from 'src/app/components/shared/warning-modal/warning-modal.component';
import {
  EnhancementVariantDetail,
  IEnhancementVariantAsset,
  IMasterSuiteTouch,
  IMasterSuiteTouchAsset,
} from 'src/app/models/mastersuites/mastersuite';
import { MasterSuitesService } from 'src/app/services/mastersuites.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-enhancement-variant-content',
  templateUrl: './enhancement-variant-content.component.html',
  styleUrls: ['./enhancement-variant-content.component.scss'],
})
export class EnhancementVariantContentComponent implements OnInit {
  @Input() colPerScreen: number = 3;
  breakpoint = 1;
  masterSuiteId: string = '';
  enhancementVariants!: EnhancementVariantDetail[];
  private destroy$ = new Subject();
  public enhancementVariantName = new FormControl('', [Validators.required]);
  apiBase = environment.apiBase;

  constructor(
    private masterSuitesService: MasterSuitesService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private router: Router,
    private _alert: MatSnackBar
  ) {
    this.masterSuiteId =
      this.activatedRoute.snapshot.paramMap.get('masterSuiteId') || '';
  }

  ngOnInit(): void {
    this.onSettingBreakPoint(window.innerWidth);
    this.getListEnhancementVariant();
  }

  getListEnhancementVariant() {
    combineLatest(
      this.masterSuitesService.getAllEnhancementVariantOfMasterSuite(
        this.masterSuiteId
      ),
      this.masterSuitesService.currentMasterSuite
    ).subscribe(([resEnhancementVariant, masterSuite]) => {
      this.enhancementVariants = resEnhancementVariant;
      this.enhancementVariants.forEach((enhancementVariant) => {
        enhancementVariant.masterSuiteEnhancementVariantAssets.forEach(
          (touchAsset) => {
            masterSuite.masterSuiteTouches.forEach(
              (masterSuiteTouche: IMasterSuiteTouch, i: number) => {
                let videoIndex = 1;
                let angleIndex = 1;
                masterSuiteTouche.masterSuiteTouchAssets.forEach(
                  (masterSuiteTouchAsset, j) => {
                    let name = `Touch ${i + 1} / ${
                      masterSuiteTouchAsset.packageAssetType === 'Still'
                        ? 'Angle'
                        : 'Video'
                    } `;
                    if (masterSuiteTouchAsset.packageAssetType == 'Video') {
                      name += `${videoIndex}`;
                      videoIndex++;
                    } else {
                      name += `${angleIndex}`;
                      angleIndex++;
                    }
                    if (
                      masterSuiteTouchAsset.id ===
                      touchAsset.masterSuiteTouchAssetId
                    ) {
                      touchAsset.assetName = name;
                      touchAsset.shootAssetPlaceholderRawUri =
                        masterSuiteTouchAsset.shootAssetPlaceholderRawUri;
                      touchAsset.shootAssetPlaceholderReviewUri =
                        masterSuiteTouchAsset.shootAssetPlaceholderReviewUri;
                      touchAsset.shootAssetPlaceholderThumbnailUri =
                        masterSuiteTouchAsset.shootAssetPlaceholderThumbnailUri;
                    }
                    masterSuiteTouchAsset.copies?.forEach(
                      (masterSuiteTouchAssetCopy, indexCopy) => {
                        if (
                          masterSuiteTouchAssetCopy.id ===
                          touchAsset.masterSuiteTouchAssetId
                        ) {
                          touchAsset.assetName = `${name}.${indexCopy + 1}`;
                          touchAsset.shootAssetPlaceholderRawUri =
                            masterSuiteTouchAsset.shootAssetPlaceholderRawUri;
                          touchAsset.shootAssetPlaceholderReviewUri =
                            masterSuiteTouchAsset.shootAssetPlaceholderReviewUri;
                          touchAsset.shootAssetPlaceholderThumbnailUri =
                            masterSuiteTouchAsset.shootAssetPlaceholderThumbnailUri;
                        }
                      }
                    );
                  }
                );
              }
            );
          }
        );
      });
    });
  }

  onResize(event: any) {
    this.onSettingBreakPoint(event.target.innerWidth);
  }
  onSettingBreakPoint(innerWidth: number) {
    switch (true) {
      case innerWidth >= 2560:
        this.breakpoint = 8;
        break;

      case innerWidth >= 2260:
        this.breakpoint = 7;
        break;

      case innerWidth >= 1960:
        this.breakpoint = 6;
        break;

      case innerWidth >= 1660:
        this.breakpoint = 5;
        break;

      case innerWidth >= 1360:
        this.breakpoint = 4;
        break;

      case innerWidth >= 1060:
        this.breakpoint = 3;
        break;

      case innerWidth >= 760:
        this.breakpoint = 2;
        break;

      default:
        this.breakpoint = 1;
        break;
    }
  }

  editEnhancementVariant(enhancementVariant: EnhancementVariantDetail) {
    this.router.navigate([
      this.router.url,
      'enhancement-variant',
      enhancementVariant.id,
    ]);
  }
  deleteEnhancementVariant(enhancementVariant: EnhancementVariantDetail) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      data: {
        header: 'Delete',
        message: `Are you sure you want to delete this Enhancement Variant?`,
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.masterSuitesService
          .deleteEnhancementVariantById(enhancementVariant.id)
          .subscribe({
            next: (res) => {
              const index = this.enhancementVariants.findIndex(
                (e) => e.id === enhancementVariant.id
              );
              this.enhancementVariants.splice(index, 1);
              this._alert.open(
                `Delete ${enhancementVariant.name} successfully!`,
                'close',
                {
                  horizontalPosition: 'center',
                  verticalPosition: 'bottom',
                  duration: 2000,
                  panelClass: ['success'],
                }
              );
            },
            error: (e) => {
              if (e.status === 422) {
                this._alert.open(e.error.validationErrors[0].message, 'close', {
                  horizontalPosition: 'center',
                  verticalPosition: 'bottom',
                  duration: 2000,
                  panelClass: ['error'],
                });
              }
            },
          });
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
