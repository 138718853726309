import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IPreference } from 'src/app/models/users/userProfile';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-comment-item',
  templateUrl: './comment-item.component.html',
  styleUrls: ['./comment-item.component.scss']
})
export class CommentItemComponent implements OnInit{
  @Input() data: any = {};
  @Output() selected = new EventEmitter();
  currentConfigDate!: IPreference;

  constructor(private authService: AuthService){}

  ngOnInit(): void {
    this.authService.currentPreference.subscribe((res)=>{
      this.currentConfigDate = res;
    })
  }
}
