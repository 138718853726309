import { IMasterSuiteTouch } from 'src/app/models/mastersuites/mastersuite';

export interface ISettingsTransfer {
  validTransfer: boolean;
  masterSuiteSrcId: string;
  masterSuiteDstId: String;
  masterSuiteVariantDstId: string;
  productIds: string[];
  masterSuitesTouchesMapping: MasterSuiteTouchToTransfer[];
}

export interface MasterSuiteTouchToTransfer {
  action: ETransferAction;
  masterSuiteTouchSrc: IMasterSuiteTouch;
  masterSuiteTouchDst: IMasterSuiteTouch | null;
  affectedProducts: AffectedProduct[];
}

export enum ETransferAction {
  TRANSFER = 'Transfer',
  REMOVE = 'Remove',
  UNKNOWN = 'Unknown',
}

export interface MasterSuiteTouchToRemove {
  masterSuiteTouchRemoved: IMasterSuiteTouch;
}

export interface AffectedProduct {
  id: string;
  barcode: string;
  reference: string;
  description: string;
  ingestionData: boolean;
  productionData: boolean;
  postProductionData: boolean;
}
