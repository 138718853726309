import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      header: string;
      content: string;
      isOk?: boolean;
    },
    public dialogRef: MatDialogRef<ConfirmModalComponent>
  ) {}

  onConfirm(status: boolean) {
    this.dialogRef.close(status);
  }
}
