import { Component, Inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OutputFormat, StillOutputFormat, VideoOutputFormat } from 'src/app/models/organizations/form-setting';
import { OutputConfirmProductSearch } from 'src/app/models/products/productSearch';
import { DownloadOutputService } from 'src/app/services/download-output.service';
import { OrganizationsService } from 'src/app/services/organizations.service';
import { GenerateOutputsCollectionComponent } from '../generate-outputs-collection/generate-outputs-collection.component';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-generate-shopify-assets-sync',
  templateUrl: './generate-shopify-assets-sync.component.html',
  styleUrl: './generate-shopify-assets-sync.component.scss'
})
export class GenerateShopifyAssetsSyncComponent {
  fileNameSettings: OutputFormat[] = [];
  isLoading = true;
  isDownload = false;
  outputForm = new FormGroup({
    stills: new FormControl<string[]>([]),
    videos: new FormControl<string[]>([]),
  });
  overwrite: FormControl = new FormControl<boolean>(false);

  outputForms: FormGroup[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public dataDialog: OutputConfirmProductSearch,
    private organizationsService: OrganizationsService,
    private productsService: ProductsService,
    public dialogRef: MatDialogRef<GenerateOutputsCollectionComponent>,
    private downloadOutputService: DownloadOutputService,
    public dialog: MatDialog,
    private _alert: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.getOutputFormat();
  }
  getOutputFormat() {
    this.organizationsService
      .getOutputFormat(this.dataDialog?.organizationId)
      .subscribe({
        next: (res) => {
          this.fileNameSettings = res.map((el) => ({
            ...el,
            configText:
              this.textStillsOutputDownload(el.stillFormat) +
              ', ' +
              this.textVideosOutputDownload(el.videoFormat),
            configTextStills:
              el.stillFormat != null
                ? this.textStillsOutputDownload(el.stillFormat)
                : '',
            configTextVideos:
              el.videoFormat != null
                ? this.textVideosOutputDownload(el.videoFormat)
                : '',
          }));
          this.isLoading = false;
          this.fileNameSettings.forEach((outputFormat) => {
            this.outputForms.push(
              new FormGroup({
                id: new FormControl<string>(outputFormat.id),
                name: new FormControl<string>(outputFormat.name),
                configText: new FormControl<string>(outputFormat.configText!),
                configTextStills: new FormControl<string>(
                  outputFormat.configTextStills!
                ),
                configTextVideos: new FormControl<string>(
                  outputFormat.configTextVideos!
                ),
                checked: new FormControl<boolean>(false),
                stills: new FormControl<boolean>(
                  outputFormat.stillFormat != null
                ),
                videos: new FormControl<boolean>(
                  outputFormat.videoFormat != null
                ),
              })
            );
          });
        },
        error: () => {
          this.isLoading = false;
        },
      });
  }
  generate() {
    var outputFormatIds: {
      outputFormatId: string;
      type: string;
    }[] = [];

    var valid = true;
    this.outputForms.forEach((format) => {
      if (format.get('checked')!.value) {
        outputFormatIds.push({
          outputFormatId: format.get('id')?.value,
          type:
            format.get('stills')?.value && format.get('videos')?.value
              ? 'Combo'
              : !format.get('stills')?.value && format.get('videos')?.value
              ? 'Video'
              : 'Still',
        });
        if (!format.get('stills')?.value && !format.get('videos')?.value) {
          this._alert.open(
            'Selected Formats must have either one type or both selected!',
            'close',
            {
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
              duration: 2000,
              panelClass: ['error'],
            }
          );
          valid = false;
          return;
        }
      }
    });
    if (valid) {

      this.productsService
      .syncProductShopifyAssets(
        {
          productIds: this.dataDialog.productIds || [],
          outputFormats: outputFormatIds,
          overwrite: this.overwrite.value,
        }
      )
      .subscribe((res) => {
        console.log('Successly Queued');
        this._alert.open(
          `Success! ${res.productProcessingCount}/${res.productCount} Selected Products Queued!\n Output Assets will be synced shortly!`,
          'close',
          {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 10000,
            panelClass: ['success'],
          }
        );
        this.dialogRef.close();
      });


    }
  }
  textStillsOutputDownload(fileImage: StillOutputFormat) {
    if (fileImage == null) {
      return '';
    }
    return (
      '<b>Stills: </b>' +
      fileImage.format.toUpperCase() +
      (fileImage.width ? ' Width:' + fileImage.width : '') +
      (fileImage.height ? ' Height:' + fileImage.height : '') +
      (fileImage.dpi ? ' DPI:' + fileImage.dpi : '') +
      (fileImage.format === 'Jpg'
        ? ' Color Space:' +
          fileImage.colorSpace +
          (fileImage.quality ? ' Quality:' + fileImage.quality : '')
        : '') +
      (fileImage.format === 'WebP' && fileImage.lossless
        ? ' Lossless'
        : ' Quality:' + fileImage.quality) +
      ((fileImage.format === 'Png' || fileImage.format === 'Tiff') &&
      fileImage.bitDepth
        ? ' Bit-depth:' + fileImage.bitDepth
        : '')
    );
  }
  textVideosOutputDownload(fileVideo: VideoOutputFormat) {
    if (fileVideo == null) {
      return '';
    }
    return (
      '<b>Videos: </b>' +
      fileVideo.format.toUpperCase() +
      (fileVideo.width ? ' Width:' + fileVideo.width : '') +
      (fileVideo.height ? ' Height:' + fileVideo.height : '')
    );
  }

}
