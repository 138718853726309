import { Injectable } from '@angular/core';
import { Location } from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class UrlService {

  constructor(private location: Location) { }

  getFullUrl(): string {
    const baseUrl: string = window.location.origin;
    const currentUrl: string = this.location.path();
    const fullUrl: string = `${baseUrl}${currentUrl}`;
    return fullUrl;
  }
}
