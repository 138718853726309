import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  IMasterSuite,
  IMasterSuiteTouch,
  ISettingsVariant,
} from 'src/app/models/mastersuites/mastersuite';
import {
  MasterSuiteNew,
  MasterSuiteTouchAssetNew,
  MasterSuiteTouchNew,
  SettingsVariantNew,
  SettingsVariantTouchAssetNew,
  SettingsVariantTouchNew,
} from 'src/app/models/mastersuites/mastersuiteNew';
import { MasterSuitesService } from 'src/app/services/mastersuites.service';
import { Location } from '@angular/common';
import { PackagesService } from 'src/app/services/packages.service';
import { IPackage } from 'src/app/models/packages/package';
import { Setting } from 'src/app/models/settings/setting';
import { SettingsService } from 'src/app/services/setting.service';
import { DeleteModalComponent } from 'src/app/components/shared/delete-modal/delete-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ValidationErrors } from 'src/app/models/validationErrors';
import { FormControl, Validators } from '@angular/forms';
import { MasterSuiteFormControl } from 'src/app/models/mastersuites/masterSuiteFormControl';
import { Observable, map, startWith, Subject, takeUntil } from 'rxjs';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MastersuiteContentComponent } from '../mastersuite-content/mastersuite-content.component';

@Component({
  selector: 'app-mastersuite-create',
  templateUrl: './mastersuite-create.component.html',
  styleUrls: ['./mastersuite-create.component.scss'],
})
export class MasterSuiteCreateComponent {
  private destroy$ = new Subject();
  constructor(
    private router: Router,
    private masterSuitesService: MasterSuitesService,
    private packagesService: PackagesService,
    private settingsService: SettingsService,
    public _alert: MatSnackBar,
    private _location: Location,
    public dialog: MatDialog
  ) {
    this.initialData();
  }

  input: FormControl = new FormControl('', [Validators.required]);
  masterSuiteFormControl: MasterSuiteFormControl = {
    name: this.input,
    touches: [],
  };
  serverErrorMessage: String = '';
  errorMessages: ValidationErrors[] = [];
  validatorProperties: string[] = [];
  masterSuite: IMasterSuite = new MasterSuiteNew();
  packages: IPackage[] = [];
  packageSubscription: any;
  packagesLoaded: boolean = false;
  settings: Setting[] = [];
  settingsSubscription: any;
  settingsLoaded: boolean = false;
  collapsed: boolean = false;
  tabIndex: number = 0;
  @ViewChild(MastersuiteContentComponent) content!: any;
  initialData() {
    const navigation = this.router.getCurrentNavigation();
    if (navigation == null) {
      this.router.navigate(['mastersuites']);
    } else {
      const state = navigation!.extras.state as {
        masterSuite: IMasterSuite;
        orgId: string;
      };
      this.input = new FormControl('', [Validators.required]);
      if (state && state.masterSuite) {
        //Then it is a copy
        console.log(state.masterSuite);
        this.masterSuite = state.masterSuite;
        this.masterSuite.masterSuiteTouches.sort((a, b) => a.index - b.index);

        this.masterSuite.masterSuiteTouches.forEach((touch) => {
          this.masterSuiteFormControl.touches.push({
            Name: new FormControl('', [Validators.required]),
            Package: new FormControl('', [Validators.required]),
          });

          touch.masterSuiteTouchAssets.sort(
            (a, b) => a.packageAssetIndex - b.packageAssetIndex
          );
          touch.packageStillCount = 0;
          touch.packageVideoCount = 0;

          touch.masterSuiteTouchAssets.forEach((touchAsset) => {
            //Calculate Counts
            if (touchAsset.packageAssetType == 'Still') {
              touch.packageStillCount = touch.packageStillCount + 1;
            } else {
              touch.packageVideoCount = touch.packageVideoCount + 1;
            }
          });
        });

        // this.masterSuite.masterSuiteVariants.forEach((variant, i) => {
        //   this.masterSuiteFormControl.variants.push({
        //     Name: new FormControl('', [Validators.required]),
        //     Setting: [],
        //   });
        //   variant.variantTouches.sort(
        //     (a, b) => a.masterSuiteTouchIndex - b.masterSuiteTouchIndex
        //   );
        //   variant.variantTouches.forEach((variantTouch, index) => {
        //     this.masterSuiteFormControl.variants[i].Setting.push(
        //       new FormControl('', [Validators.required])
        //     );
        //     variantTouch.variantTouchAssets.forEach((variantTouchAsset) => {
        //       variantTouchAsset.packageAssetId =
        //         this.masterSuite.masterSuiteTouches
        //           .find((_) => _.id === variantTouch.masterSuiteTouchId)
        //           ?.masterSuiteTouchAssets.find(
        //             (_) => _.id === variantTouchAsset.masterSuiteTouchAssetId
        //           )!.packageAssetId!;
        //     });
        //   });
        // });

        console.log(this.masterSuite);
      } else {
        this.masterSuite = new MasterSuiteNew();
        this.masterSuite.organizationId = state.orgId;
      }
    }
  }
  backClicked() {
    this.router.navigate([`mastersuites`]);
  }

  createMasterSuite() {
    // Define the Sequence
    this.masterSuite.masterSuiteTouches.forEach((touch, i) => {
      touch.index = i * 1000;
      touch.masterSuiteTouchAssets.forEach((asset, i) => {
        if (asset.copies != null) {
          asset.copies.forEach((copy, i) => {
            copy.additionalAssetIndex = i;
          });
        }
      });
    });

    // Post to API
    this.masterSuitesService
      .createMasterSuite(this.masterSuite)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          console.log('Created MasterSuite: ', data);
          this._alert.open('Successfully Created Master Suite', 'close', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 2000,
            panelClass: ['success'],
          });
          this.router.navigate(['/mastersuites']);
        },
        (error) => {
          if (error.status === 422) {
            this.errorMessages = [];
            this.validatorProperties = [];
            error.error.validationErrors.forEach((error: ValidationErrors) => {
              this.errorMessages.push(error);
              this.validatorProperties.push(error.property);
              this.masterSuiteFormControl.name.markAllAsTouched();
              if (error.property == 'Name') {
                this.masterSuiteFormControl.name.setErrors({
                  serverErrors: 'validation errors',
                });
              }
              // else if (error.property == 'MasterSuiteVariants') {
              //   this._alert.open(error.message, 'close', {
              //     horizontalPosition: 'center',
              //     verticalPosition: 'bottom',
              //     panelClass: ['error'],
              //   });
              // }
            });
            this.masterSuiteFormControl.touches.forEach((form, index) => {
              form.Name.markAllAsTouched();
              form.Package.markAllAsTouched();
              if (
                this.errorMessages.find(
                  (error) =>
                    error.property == 'MasterSuiteTouches[' + index + '].Name'
                )
              ) {
                this.content.onStepSelected(0);
                form.Name.setErrors({ serverErrors: 'validation errors' });
              }
              if (
                this.errorMessages.find(
                  (error) =>
                    error.property ==
                    'MasterSuiteTouches[' + index + '].Package'
                )
              ) {
                this.content.onStepSelected(0);
                form.Package.setErrors({ serverErrors: 'validation errors' });
              }
            });
            // this.masterSuiteFormControl.variants.forEach((form, i) => {
            //   form.Name.markAllAsTouched();
            //   if (
            //     this.errorMessages.find(
            //       (error) =>
            //         error.property == 'MasterSuiteVariants[' + i + '].Name'
            //     )
            //   ) {
            //     this.content.onStepSelected(1);
            //     form.Name.setErrors({ serverErrors: 'validation errors' });
            //   }
            //   form.Setting.forEach((settingForm, j) => {
            //     settingForm.markAllAsTouched();
            //     if (
            //       this.errorMessages.find(
            //         (error) =>
            //           error.property ==
            //           'MasterSuiteVariants[' +
            //             i +
            //             '].VariantTouch[' +
            //             j +
            //             '].Setting'
            //       )
            //     ) {
            //       this.content.onStepSelected(1);
            //       settingForm.setErrors({ serverErrors: 'validation errors' });
            //     }
            //   });
            // });
          }
        }
      );
  }

  validationMessage(property: string) {
    return this.errorMessages.find((error) => error.property == property)
      ?.message;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
