<h1 mat-dialog-title class="title-confirm">{{ data.entity.name }}</h1>
<mat-dialog-content>
  <form>
    <mat-form-field
      class="example-chip-list"
      appearance="outline"
      subscriptSizing="dynamic"
    >
      <mat-label>Tags</mat-label>
      <mat-chip-grid #chipGrid aria-label="Fruit selection">
        <mat-chip-row
          *ngFor="let tag of data.entity.tags"
          (removed)="remove(tag)"
        >
          {{ tag }}
          <button matChipRemove [attr.aria-label]="'remove ' + tag">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      </mat-chip-grid>
      <input
        placeholder="New Tag..."
        #fruitInput
        [formControl]="fruitCtrl"
        [matChipInputFor]="chipGrid"
        [matAutocomplete]="auto"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)"
      />
      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="selected($event)"
      >
        <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
          {{ tag }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions style="justify-content: end">
  <button mat-stroked-button mat-dialog-close>Close</button>
</mat-dialog-actions>
