import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BACK_FORM_CREATE_EV,
  IMasterSuite,
} from 'src/app/models/mastersuites/mastersuite';
import { IPackage } from 'src/app/models/packages/package';
import { Setting } from 'src/app/models/settings/setting';
import { MasterSuitesService } from 'src/app/services/mastersuites.service';
import { PackagesService } from 'src/app/services/packages.service';
import { SettingsService } from 'src/app/services/setting.service';
import { MatDialog } from '@angular/material/dialog';
import { ValidationErrors } from 'src/app/models/validationErrors';
import { FormControl, Validators } from '@angular/forms';
import { MasterSuiteFormControl } from 'src/app/models/mastersuites/masterSuiteFormControl';
import { Observable, Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UnsavedChangesWarningComponent } from 'src/app/components/shared/unsaved-changes-warning/unsaved-changes-warning.component';

@Component({
  selector: 'app-mastersuite-update',
  templateUrl: './mastersuite-update.component.html',
  styleUrls: ['./mastersuite-update.component.scss'],
})
export class MastersuiteUpdateComponent {
  private destroy$ = new Subject();
  constructor(
    private router: Router,
    private masterSuitesService: MasterSuitesService,
    public _alert: MatSnackBar,
    public dialog: MatDialog,
    private route: ActivatedRoute
  ) {
    this.initialData();

    if (sessionStorage.getItem(BACK_FORM_CREATE_EV)) {
      this.tabIndex = 2;
    }
  }

  readonly: boolean = true;
  input: FormControl = new FormControl('', [Validators.required]);
  masterSuiteFormControl: MasterSuiteFormControl = {
    name: this.input,
    touches: [],
  };

  packagesFilteredOptions: Observable<IPackage[]>[] = [];
  _packageFilter(name: string): IPackage[] {
    const filterValue = name.toLowerCase();
    return this.packages.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }
  displayPackageFn(packageId: string) {
    if (packageId) {
      return this.packages.find((_) => _.id === packageId)?.name!;
    } else {
      return '';
    }
  }

  settingsFilteredOptions: Observable<Setting[]>[][] = [];

  apiBase = environment.apiBase;
  serverErrorMessage: String = '';
  errorMessages: ValidationErrors[] = [];
  validatorProperties: string[] = [];
  masterSuite!: IMasterSuite;
  masterSuiteOriginal!: IMasterSuite;

  packages: IPackage[] = [];
  packageSubscription: any;
  packagesLoaded: boolean = false;

  settings: Setting[] = [];
  settingsSubscription: any;
  settingsLoaded: boolean = false;

  breakpoint: number = 1;
  collapsed: boolean = false;

  loaded: boolean = false;
  tabIndex: number = 0;
  initialData() {
    this.getMasterSuiteDetail();
  }

  getMasterSuiteDetail() {
    const masterSuiteId = this.route.snapshot.paramMap.get('masterSuiteId');
    if (masterSuiteId) {
      this.masterSuitesService
        .detailMasterSuite(masterSuiteId)
        .pipe(takeUntil(this.destroy$))
        .subscribe((masterSuite) => {
          this.masterSuite = masterSuite;
          this.setupData();
          this.masterSuitesService.updateMasterSuiteState(masterSuite);
          this.loaded = true;
        });
    }
  }

  setupData() {
    this.masterSuiteFormControl.touches = [];
    this.input = new FormControl('', [Validators.required]);
    this.masterSuite.masterSuiteTouches.sort((a, b) => a.index - b.index);
    this.masterSuite.masterSuiteTouches.forEach((touch) => {
      this.masterSuiteFormControl.touches.push({
        Name: new FormControl('', [Validators.required]),
        Package: new FormControl('', [Validators.required]),
      });

      touch.masterSuiteTouchAssets.sort(
        (a, b) => a.packageAssetIndex - b.packageAssetIndex
      );
      touch.packageStillCount = 0;
      touch.packageVideoCount = 0;

      touch.masterSuiteTouchAssets.forEach((touchAsset) => {
        //Calculate Counts
        if (touchAsset.packageAssetType == 'Still') {
          touch.packageStillCount = touch.packageStillCount + 1;
        } else {
          touch.packageVideoCount = touch.packageVideoCount + 1;
        }
      });
    });
  }

  backClicked() {
    this.router.navigate([`mastersuites`]);
  }

  updateMasterSuite() {
    // Define the Sequence
    this.masterSuite.masterSuiteTouches.forEach((touch, i) => {
      touch.index = i * 1000;
      touch.masterSuiteTouchAssets.forEach((asset, i) => {
        if (asset.copies != null) {
          asset.copies.forEach((copy, i) => {
            copy.additionalAssetIndex = i;
          });
        }
      });
    });
    // Post to API
    this.masterSuitesService
      .updateMasterSuite(this.masterSuite)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          this.readonly = true;
          this.getMasterSuiteDetail();
          this._alert.open('Successfully Updated Master Suite', 'close', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 2000,
            panelClass: ['success'],
          });
        },
        (error) => {
          if (error.status === 422) {
            this.errorMessages = [];
            this.validatorProperties = [];
            error.error.validationErrors.forEach((error: ValidationErrors) => {
              this.errorMessages.push(error);
              this.validatorProperties.push(error.property);
              this.masterSuiteFormControl.name.markAllAsTouched();
              if (error.property == 'Name') {
                this.masterSuiteFormControl.name.setErrors({
                  serverErrors: 'validation errors',
                });
              } else {
                this._alert.open(error.message, 'close', {
                  horizontalPosition: 'center',
                  verticalPosition: 'bottom',
                  panelClass: ['error'],
                  duration: 10000,
                });
              }
            });

            this.masterSuiteFormControl.touches.forEach((form, index) => {
              form.Name.markAllAsTouched();
              form.Package.markAllAsTouched();
              if (
                this.errorMessages.find(
                  (error) =>
                    error.property ==
                    'Model.MasterSuiteTouches[' + index + '].Name'
                )
              ) {
                console.log('yes ', index);
                this.tabIndex = 0;
                form.Name.setErrors({ serverErrors: 'validation errors' });
              }
              if (
                this.errorMessages.find(
                  (error) =>
                    error.property ==
                    'Model.MasterSuiteTouches[' + index + '].Package'
                )
              ) {
                form.Package.setErrors({ serverErrors: 'validation errors' });
              }
            });
          }
        }
      );
  }

  validationMessage(property: string) {
    return this.errorMessages.find((error) => error.property == property)
      ?.message;
  }

  tabChanged(index: number) {
    if (this.tabIndex == 0 && !this.readonly && index != this.tabIndex) {
      this.cancel();
    }
    this.tabIndex = index;
  }

  edit() {
    this.readonly = false;
    this.masterSuiteOriginal = JSON.parse(JSON.stringify(this.masterSuite));
  }

  cancel() {
    if (this.checkChanges()) {
      const dialogRef = this.dialog.open(UnsavedChangesWarningComponent);
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.masterSuite = JSON.parse(
            JSON.stringify(this.masterSuiteOriginal)
          );
          this.setupData();
          this.readonly = true;
        } else {
          this.tabIndex = 0;
        }
      });
    } else {
      this.readonly = true;
    }
  }

  checkChanges(): boolean {
    console.log(this.masterSuite.masterSuiteTouches);
    console.log(this.masterSuiteOriginal.masterSuiteTouches);
    return (
      JSON.stringify(this.masterSuite.masterSuiteTouches) !=
        JSON.stringify(this.masterSuiteOriginal.masterSuiteTouches) ||
      this.masterSuite.name != this.masterSuiteOriginal.name
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    sessionStorage.removeItem(BACK_FORM_CREATE_EV);
  }
}
