import {Component, Inject} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {
  ConfigOption,
  ConfigOptions,
  IPackage,
} from 'src/app/models/packages/package';
import {IPackageAsset} from 'src/app/models/packages/packageAsset';

import {Location} from '@angular/common';
import {PackagesService} from 'src/app/services/packages.service';
import {
  PackageNew,
  StillsAssetNew,
  VideoAssetNew,
} from 'src/app/models/packages/packageNew';
import {DeleteModalComponent} from 'src/app/components/shared/delete-modal/delete-modal.component';
import {FormControl, Validators} from '@angular/forms';
import {ValidationErrors} from 'src/app/models/validationErrors';
import {
  CdkDragDrop,
  CdkDropList,
  CdkDrag,
  moveItemInArray,
} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-package-create',
  templateUrl: './package-create.component.html',
  styleUrls: ['./package-create.component.scss'],
})
export class PackageCreateComponent {
  constructor(
    private router: Router,
    private packagesService: PackagesService,
    public _alert: MatSnackBar,
    private _location: Location,
    public dialog: MatDialog
  ) {
    this.initialData();
  }

  input: FormControl = new FormControl('', [Validators.required]);
  serverErrorMessage: String = '';
  errorMessages: ValidationErrors[] = [];
  validatorProperties: string[] = [];
  package!: IPackage;
  stills: IPackageAsset[] = [];
  videos: IPackageAsset[] = [];

  configOptions: ConfigOptions = {
    sideLeft: {
      robotOutDegreesOptions: [30, 25, 15, 10, 0],
    },
    front: {
      robotOutDegreesOptions: [90, 45, 35, 25, 20, 15, 10, 0],
    },
  };
  config!: ConfigOption;

  initialData() {
    this.input = new FormControl('', [Validators.required]);
    const navigation = this.router.getCurrentNavigation();
    if (navigation == null) {
      this.router.navigate(['packages']);
    } else {
      const state = navigation!.extras.state as {
        package: IPackage;
        orgId: string;
      };

      if (state && state.package) {
        //Then it is a copy
        this.package = state.package;
        this.package.organizationId = state.orgId;
        this.package.name = '';
        this.package.assets.sort((a, b) => a.index - b.index);

        this.package.assets.forEach((asset) => {
          if (
            asset.distanceOffset != 1 ||
            asset.focusOffset != 1 ||
            asset.heightOffset != 1
          ) {
            asset.offsets = true;
          }

          if (asset.type.toLowerCase() == 'still') {
            this.stills.push(asset);
          } else {
            this.videos.push(asset);
          }
        });
      } else {
        this.package = new PackageNew();
        this.package.organizationId = state.orgId;
        this.stills = [new StillsAssetNew()];
        this.videos = [];
      }
    }
    this.setConfig();
  }

  setConfig() {
    console.log(this.package.robotPosition);

    if (this.package.robotPosition == 'SideLeft') {
      this.config = this.configOptions.sideLeft;
      this.package.cameraOrientation = 'Portrait';
    } else {
      this.config = this.configOptions.front;
      this.package.cameraOrientation = 'Landscape';
    }

    if (
      !this.config.robotOutDegreesOptions.includes(
        this.package.livePreview.robotOutDegrees
      )
    ) {
      this.package.livePreview.robotOutDegrees = 0;
    }

    this.stills.forEach((asset: IPackageAsset) => {
      if (!this.config.robotOutDegreesOptions.includes(asset.robotOutDegrees)) {
        asset.robotOutDegrees = 0;
      }
    });

    this.videos.forEach((asset: IPackageAsset) => {
      if (!this.config.robotOutDegreesOptions.includes(asset.robotOutDegrees)) {
        asset.robotOutDegrees = 0;
      }
    });
  }

  addStill() {
    this.stills.push(new StillsAssetNew());
  }

  removeStill(index: number) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      data: {
        header: `Delete Still: ${index + 1}`,
        message: 'Are you sure you want to delete this stills asset?',
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.stills.splice(index, 1);
      }
    });
  }

  addVideo() {
    this.videos.push(new VideoAssetNew());
  }

  removeVideo(index: number) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      data: {
        header: `Delete Video: ${index + 1}`,
        message: 'Are you sure you want to delete this video asset?',
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.videos.splice(index, 1);
      }
    });
  }

  dragDropStill(event: any) {
    console.log(event);
    moveItemInArray(this.stills, event.previousIndex, event.currentIndex);
  }

  dragDropVideo(event: any) {
    console.log(event);
    moveItemInArray(this.videos, event.previousIndex, event.currentIndex);
  }

  createPackage() {
    var stillPackage = false;
    var videoPackage = false;
    this.package.assets = [];

    if (this.stills.length > 0) {
      stillPackage = true;
      this.stills.forEach((still, i) => {
        still.index = i * 1000;
        this.package.assets.push(still);
      });
    }

    if (this.videos.length > 0) {
      videoPackage = true;
      this.videos.forEach((video, i) => {
        video.index = (i + this.stills.length) * 1000;
        this.package.assets.push(video);
      });
    }
    if (stillPackage && videoPackage) {
      this.package.externalReference =
        'Combo(' + this.stills.length + ',' + this.videos.length + ')';
    } else if (stillPackage == true && videoPackage == false) {
      this.package.externalReference = 'Stills(' + this.stills.length + ')';
    } else if (stillPackage == false && videoPackage == true) {
      this.package.externalReference = 'Video(' + this.videos.length + ')';
    }

    console.log(this.package);
    this.packagesService.createPackage(this.package).subscribe(
      (data) => {
        console.log(data);
        this._alert.open('Successfully Created Product', 'close', {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 2000,
          panelClass: ['success'],
        });
        this.router.navigate(['packages']);
      },
      (error) => {
        if (error.status === 422) {
          this.errorMessages = [];
          this.validatorProperties = [];
          error.error.validationErrors.forEach((error: ValidationErrors) => {
            this.errorMessages.push(error);
            this.validatorProperties.push(error.property);
            this.input.markAllAsTouched();
            if (error.property == 'Name') {
              this.input.setErrors({serverErrors: 'validation errors'});
            }
          });
        }
      }
    );
  }

  validationMessage(property: string) {
    return this.errorMessages.find((error) => error.property == property)
      ?.message;
  }

  backClicked() {
    this._location.back();
  }
}
