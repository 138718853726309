import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IOutputsCollection } from '../models/outputsCollection';
import { Observable } from 'rxjs';
import { IProduct } from '../models/products/product';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class OutputsCollectionsService {
  constructor(private http: HttpClient) {}

  createOutputsCollection(data: {
    productIds: string[];
    organizationId: string;
    batchId: string;
    outputFormats: {
      outputFormatId: string;
      type: string;
    }[];
  }) {
    const url = `${environment.apiBase}/outputs-collections`;
    return this.http.post<any>(url, data, httpOptions);
  }

  listOutputsCollection(): Observable<IOutputsCollection[]> {
    const url = `${environment.apiBase}/outputs-collections`;
    return this.http.get<IOutputsCollection[]>(url);
  }

  getOutputsCollection(id: string): Observable<IOutputsCollection> {
    const url = `${environment.apiBase}/outputs-collections/${id}`;
    return this.http.get<any>(url);
  }

  getOutputsCollectionProducts(id: string): Observable<IProduct[]> {
    const url = `${environment.apiBase}/outputs-collection/${id}/products`;
    return this.http.get<any>(url);
  }

  downloadOutputsCollection(id: string) {
    const url = `${environment.apiBase}/outputs-collection/${id}/download`;
    return this.http.get<any>(url);
  }

  deleteOutputsCollection(id: string) {
    const url = `${environment.apiBase}/outputs-collection/${id}`;
    return this.http.delete<any>(url);
  }
}
