<mat-card
  class="enhancement-variant"
  *ngFor="let enhancementVariant of enhancementVariants; index as i"
>
  <mat-card-header style="align-items: center">
    <mat-card-title class="title-heading">
      {{ enhancementVariant.name }} {{ i === 0 ? "(default)" : "" }}
    </mat-card-title>
    <span class="spacer"></span>
    <button
      mat-icon-button
      matTooltip="Info"
      color="info"
      class="mat-elevation-z0"
      (click)="editEnhancementVariant(enhancementVariant)"
    >
      <mat-icon>info</mat-icon>
    </button>
    <button
      mat-icon-button
      matTooltip="Delete"
      color="error"
      class="mat-elevation-z0"
      (click)="deleteEnhancementVariant(enhancementVariant)"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </mat-card-header>
  <mat-card-content>
    <div>
      <div
        class="asset-list"
        [ngStyle]="{
          'grid-template-columns': 'repeat(' + colPerScreen + ',minmax(0,1fr))'
        }"
        cdkDropListGroup
      >
        <ng-container
          *ngFor="
            let enhancementVariantAsset of enhancementVariant.masterSuiteEnhancementVariantAssets;
            let i = index
          "
        >
          <app-selected-asset-card
            [data]="enhancementVariantAsset"
            [readonly]="true"
            select="Select {{ i + 1 }}"
          >
          </app-selected-asset-card>
        </ng-container>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<ng-template #dontDisplayEnhancement>
  <img [src]="'assets/placeholder.png'" alt="placeholder" class="asset" />
</ng-template>
