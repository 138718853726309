<h1 mat-dialog-title class="title-confirm">Please Select Organization</h1>
<mat-dialog-content>
  <mat-form-field appearance="outline" class="action-form">
    <mat-label>Client</mat-label>
    <input
      type="text"
      placeholder="Pick one"
      aria-label="Organization"
      matInput
      [(ngModel)]="selectedOrganization"
      [formControl]="organizationControl"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete
      autoActiveFirstOption
      #auto="matAutocomplete"
      [displayWith]="displayFn"
    >
      <mat-option
        *ngFor="let organization of organizationFilteredOptions | async"
        [value]="organization"
      >
        {{ organization.name }}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="invalidOrganization == true">
      {{ invalidOrganizationMessage }}
    </mat-error>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions style="justify-content: end">
  <button mat-stroked-button cdkFocusInitial matDialogClose>Cancel</button>
  <button mat-raised-button color="buttonSuccess" (click)="onConfirm(true)">
    Continue
  </button>
</mat-dialog-actions>
