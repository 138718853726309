<div #container class="compare-image">
  <!-- <ngx-image-comparison-slider
    *ngIf="showCompare"
    [sliderShadow]="false"
    [sliderColor]="'black'"
    [sliderBallImageColor]="'white'"
    [sliderWidth]="2"
    [sliderBallSize]="20"
    [imageFit]="'contain'"
    [maxWidth]="maxWidth"
    [maxHeight]="maxHeight"
    [src1]="src1"
    [src2]="src2"
  >
  </ngx-image-comparison-slider> -->

  <img-comparison-slider
    *ngIf="showCompare"
    class="coloured-slider"
    [imageFit]="'contain'"
    [ngStyle]="{ width: maxWidth + 'px', height: maxHeight + 'px' }"
  >
    <div
      class="container"
      slot="first"
      [ngStyle]="{ width: maxWidth + 'px', height: maxHeight + 'px' }"
    >
      <img [src]="src1" />
    </div>
    <div
      class="container"
      slot="second"
      [ngStyle]="{ width: maxWidth + 'px', height: maxHeight + 'px' }"
    >
      <img [src]="src2" />
    </div>
  </img-comparison-slider>
  <div class="asset-actions">
    <button mat-icon-button (click)="stopCompare.emit(false)">
      <mat-icon> cancel </mat-icon>
    </button>
    <button mat-icon-button (click)="download.emit(true)">
      <mat-icon> file_download </mat-icon>
    </button>
  </div>
</div>
