import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { startWith, map, Observable } from 'rxjs';
import {
  IMasterSuite,
  ISettingsVariant,
} from 'src/app/models/mastersuites/mastersuite';
import { IProduct } from 'src/app/models/products/product';
import { MasterSuitesService } from 'src/app/services/mastersuites.service';

@Component({
  selector: 'app-select-mastersuites',
  templateUrl: './select-mastersuites.component.html',
  styleUrls: ['./select-mastersuites.component.scss'],
})
export class SelectMastersuitesComponent {
  @Input() products!: IProduct[];
  @Input() masterSuites!: IMasterSuite[];
  @Output() selectedMasterSuiteSrc = new EventEmitter<IMasterSuite>();
  @Output() selectedMasterSuiteDst = new EventEmitter<IMasterSuite>();
  @Output() selectedVaraintDst = new EventEmitter<ISettingsVariant>();
  constructor(private mastersuiteService: MasterSuitesService) {
    this.masterSuiteSrcFormControl = new FormControl('', [Validators.required]);
    this.masterSuiteDstFormControl = new FormControl('', [Validators.required]);
    this.variantDstFormControl = new FormControl('', [Validators.required]);
  }

  ngOnInit(): void {
    // Currently Used Master suites in batch
    this.availableSrcMasterSuites = this.masterSuites.filter((a) =>
      this.products.some((p) => p.masterSuiteId === a.id)
    );
    this.masterSuiteDstFilteredOptions =
      this.masterSuiteDstFormControl.valueChanges.pipe(
        startWith(''),
        map((value) => this.parseMasterSuiteValue(value))
      );
  }

  //SRC
  availableSrcMasterSuites!: IMasterSuite[];
  masterSuiteSrcFormControl: FormControl;
  masterSuiteSrcChange(masterSuite: IMasterSuite, event: any) {
    if (event.isUserInput) {
      // ignore on deselection of the previous option
      this.selectedMasterSuiteSrc.emit(masterSuite);
    }
  }

  //DST
  masterSuiteDstFormControl: FormControl;
  masterSuiteDstFilteredOptions!: Observable<IMasterSuite[]>;
  masterSuiteDstChange(masterSuite: IMasterSuite, event: any) {
    if (event.isUserInput) {
      // ignore on deselection of the previous option
      this.updateVariants(masterSuite);
    }
  }
  variants: ISettingsVariant[] = [];
  variantDstFormControl: FormControl;
  variantDstChange(variant: ISettingsVariant, event: any) {
    if (event.isUserInput) {
      // ignore on deselection of the previous option
      this.selectedVaraintDst.emit(variant);
    }
  }

  parseMasterSuiteValue(value: any) {
    const name = typeof value === 'string' ? value : value?.name;
    return name ? this._filter(name as string) : this.masterSuites.slice();
  }

  updateVariants(masterSuite: IMasterSuite) {
    if (this.masterSuiteDstFormControl.value != null) {
      this.mastersuiteService.detailMasterSuite(masterSuite.id!).subscribe(
        (data) => {
          if (data) {
            this.selectedMasterSuiteDst.emit(data);
            this.variants = data.settingsVariants!;
            this.variantDstFormControl.setValue(this.variants[0]);
            this.selectedVaraintDst.emit(this.variants[0]);
          }
        },
        (error: any) => {
          console.log(error.message);
        }
      );
    }
  }

  displayFn(option: IMasterSuite | null) {
    return option ? option.name : '';
  }
  private _filter(name: string): IMasterSuite[] {
    const filterValue = name.toLowerCase();
    return this.masterSuites.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }
}
