import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ResizedEvent } from 'angular-resize-event';
@Component({
  selector: 'app-dynamic-chip-set',
  templateUrl: './dynamic-chip-set.component.html',
  styleUrl: './dynamic-chip-set.component.scss',
})
export class DynamicChipSetComponent implements OnInit {
  @Input() chips: any = [];
  @Input() selectedChipKey: string = '';
  @Input() loading: boolean = false;
  @Input() flexEnd: boolean = false;
  @Output() chipSelected = new EventEmitter();
  columns: number = 10;
  resize: any;

  chipsList: any[] = [];
  chipsSubList: any[] = [];

  ngOnInit(): void {
    this.columns = this.chips.length;
    if (this.flexEnd) {
      console.log('asdsa');
      document.documentElement.style.setProperty('--postion', 'flex-end');
    }
  }

  breakpoints() {
    var value = Math.floor(document.getElementById('list')?.offsetWidth! / 173);
    if (value >= this.chips.length) {
      this.columns = this.chips.length;
    } else {
      this.columns = Math.floor(
        (document.getElementById('list')?.offsetWidth! - 93) / 173
      );
    }
    this.chipsList = this.chips.slice(0, this.columns);
    this.chipsSubList = this.chips.slice(this.columns);
  }

  change(key: any) {
    this.chipSelected.emit(key);
    if (key != undefined) {
      var chip = this.chips.find((x: any) => x.key == key);
      if (chip) {
        this.chips.splice(this.chips.indexOf(chip!), 1);
        this.chips.unshift(chip!);
      }

      if (this.chipsList.some((x: any) => x.key == key)) {
        let item = this.chipsList.find((x: any) => x.key == key);
        this.chipsList.splice(this.chipsList.indexOf(item!), 1);
        this.chipsList.unshift(item!);
      } else {
        let item1 = this.chipsSubList.find((x: any) => x.key == key);
        this.chipsSubList.splice(this.chipsSubList.indexOf(item1!), 1);
        console.log(item1);
        let item2 = this.chipsList[this.columns - 1];
        this.chipsList.splice(this.columns - 1, 1);

        this.chipsList.unshift(item1);
        this.chipsSubList.unshift(item2);
      }
    }
  }
}
