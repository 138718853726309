import { Component, Inject } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { IUser } from 'src/app/models/users/user';
import { UsersService } from 'src/app/services/users.service';
import { UserCreateComponent } from '../user-create/user-create.component';
import { ValidationErrors } from 'src/app/models/validationErrors';
import sha256 from 'crypto-js/sha256';
import { environment } from 'src/environments/environment';
import { UserUpdate } from 'src/app/models/users/userUpdate';
import { firstValueFrom } from 'rxjs';
import { IUserGroups } from 'src/app/models/users/userGroups';
import { timezoneList } from 'src/app/utils/timezone';
import { dateTimeFormat, defaultFormat } from 'src/app/utils/dateFormat';
import { get } from 'lodash';

@Component({
  selector: 'app-user-update',
  templateUrl: './user-update.component.html',
  styleUrls: ['./user-update.component.scss'],
})
export class UserUpdateComponent {
  apiBase = environment.apiBase;
  private destroy$ = new Subject();
  errorState: Boolean = false;
  timezoneListFiltered = timezoneList;
  dateTimeFormat = dateTimeFormat;
  formData = new UntypedFormGroup({});

  constructor(
    private usersService: UsersService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<UserCreateComponent>,
    private _alert: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) private data: IUser
  ) {
    this.user = data;
    this.nameFormControl = new FormControl('', [Validators.required]);
    this.nameFormControl.setValue(this.user.name);
    this.mmaptUserGroup = new FormControl('', [Validators.required]);
    this.pinFormControl = new FormControl('');
    this.pinFormControl.disable();
    this.changePinFormControl = new FormControl('');
    this.changePinFormControl.disable();
    // Sort User groups to display name
    this.initialData();
  }
  user: IUser;
  errorMessages: ValidationErrors[] = [];
  validatorProperties: string[] = [];
  nameFormControl: FormControl;
  mmaptUserGroup: FormControl;
  mmaptUserGroups: string[] = [
    'Super Admin',
    // 'Imagery Team Member',
    // 'SkyTeam Member',
    'Setup Admin',
    'Robot User',
  ];
  pinFormControl: FormControl;
  pinLabel: string = 'Pin';
  changePinFormControl: FormControl;
  profileImage: File | undefined;
  profileImageFile!: string | ArrayBuffer | null;
  currentGroups: string[] = [];

  initialData() {
    if (this.user.profileImageUrl == null) {
      this.profileImageFile = './assets/profile-placeholder.png';
    }

    this.user.groups.forEach((group: string) => {
      if (group.includes('superAdmins')) {
        this.currentGroups.push('Super Admin');
      } else if (group.includes('localClientUsers')) {
        this.currentGroups.push('Robot User');
        this.changePinFormControl.enable();
        this.pinLabel = 'Change Pin';
      } else if (group.includes('localSetupClient')) {
        this.currentGroups.push('Setup Admin');
      } else if (group.includes('imageryTeam')) {
        this.currentGroups.push('Imagery Team Member');
      } else if (group.includes('skyTeam')) {
        this.currentGroups.push('SkyTeam Member');
      }
    });
    this.mmaptUserGroup.setValue(this.currentGroups);

    const asAgo = get(this.user, 'preference.asAgo', true);
    const is24H = get(this.user, 'preference.is24H', true);
    const initTz = get(
      this.user,
      'preference.timezone',
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    const tz = timezoneList.find((el) => {
      const idx = el.utc.findIndex((tz) => tz === initTz);
      return idx > -1;
    });
    this.formData = new UntypedFormGroup({
      timezone: new FormControl<string>(tz ? tz.text : ''),
      dateFormat: new FormControl<string>(
        get(this.user, 'preference.dateTimeFormat', defaultFormat)
      ),
      is24H: new FormControl<boolean>(is24H),
      asAgo: new FormControl<boolean>(asAgo),
    });
  }

  onMmaptUserGroupChange() {
    if (this.mmaptUserGroup.value.includes('Robot User')) {
      this.changePinFormControl.enable();
      if (this.pinLabel == 'Pin') {
        this.changePinFormControl.setValue(true);
        this.onChangePin();
        this.pinFormControl.markAllAsTouched();
        this.pinFormControl.setErrors({
          validationErrors: 'validation errors',
        });
      }
    } else {
      this.changePinFormControl.disable();
      this.changePinFormControl.setValue(false);
      this.onChangePin();
    }
  }

  onChangePin() {
    if (this.changePinFormControl.value == true) {
      this.pinFormControl.enable();
    } else {
      this.pinFormControl.disable();
    }
  }

  updateUserProfileImage(fileInputEvent: FileList) {
    this.profileImage = fileInputEvent[0];
    this.extractImageFromFile();
  }

  extractImageFromFile(): void {
    let fileReader: FileReader = new FileReader();
    fileReader.readAsDataURL(this.profileImage!);
    fileReader.addEventListener(
      'load',
      () => {
        this.profileImageFile = fileReader.result;
      },
      false
    );
  }

  async update() {
    // Update Profile Picture if changed
    let profileResult = await this.updateProfileImage();
    if (profileResult == false) {
      return;
    }

    let groupsResult = await this.updateGroups();
    if (groupsResult == false) {
      return;
    }

    let userResult = await this.updateUser();
    if (userResult == false) {
      return;
    }

    this.dialogRef.close(true);
  }

  async updateProfileImage(): Promise<boolean> {
    if (this.profileImage != undefined) {
      console.log('Update Profile Image');
      // Update the profile image
      let result = await firstValueFrom(
        this.usersService.updateUserProfileImage(
          this.user.id,
          this.profileImage
        )
      ).catch((error) => {
        return false;
      });
      if (result == false) return result;
      this.profileImage = undefined;
      return true;
    } else {
      return true;
    }
  }

  async updateGroups(): Promise<boolean> {
    // If a mmapt user is changing groups
    if (
      this.user.organizationId == null &&
      this.currentGroups != this.mmaptUserGroup.value
    ) {
      // Check if user is changing the pin or needs to set pin
      if (
        (this.changePinFormControl.value == true &&
          this.pinFormControl.value.length != 4) ||
        (this.mmaptUserGroup.value.includes('Robot User') &&
          this.pinLabel == 'Pin' &&
          this.pinFormControl.value.length != 4)
      ) {
        console.log('Failed Pre-Validation');
        this.pinFormControl.markAllAsTouched();
        this.pinFormControl.setErrors({
          validationErrors: 'validation errors',
        });
        return false;
      }

      let userGroups: IUserGroups = {
        pinHash: undefined,
        groups: [],
      };
      this.mmaptUserGroup.value.forEach((group: string) => {
        if (group == 'Robot User') {
          userGroups.groups.push(environment.cognitoGroups.robotUser);
          if (this.changePinFormControl.value == true) {
            userGroups.pinHash = sha256(
              `${this.pinFormControl.value}-${this.user.id}`
            ).toString();
          }
        }
        if (group == 'Super Admin') {
          userGroups.groups.push(environment.cognitoGroups.superAdmin);
        }
        if (group == 'Setup Admin') {
          userGroups.groups.push(environment.cognitoGroups.setupAdmin);
        }
      });

      let result = await firstValueFrom(
        this.usersService.updateUserGroups(this.user.id, userGroups)
      ).catch((error) => {
        return false;
      });
      if (result == false) {
        return result;
      }
      return true;
    } else {
      return true;
    }
  }

  async updateUser(): Promise<boolean> {
    // If the use name changes, or if the user is changing pin if they are already in robot user group
    if (
      this.formData.dirty ||
      this.user.name != this.nameFormControl.value ||
      (this.pinLabel == 'Change Pin' && this.changePinFormControl.value == true)
    ) {
      console.log('Update User');
      let updateUserBody = new UserUpdate();
      updateUserBody = {
        name: this.nameFormControl.value,
      };
      if (
        this.mmaptUserGroup.value.includes('Robot User') &&
        this.pinLabel == 'Change Pin' &&
        this.changePinFormControl.value == true
      ) {
        if (this.pinFormControl.value.length == 4) {
          updateUserBody.pinHash = sha256(
            `${this.pinFormControl.value}-${this.user.id}`
          ).toString();
        } else {
          this.pinFormControl.markAllAsTouched();
          this.pinFormControl.setErrors({
            validationErrors: 'validation errors',
          });
          return false;
        }
      }

      const { timezone, dateFormat, asAgo, is24H } = this.formData.value;
      const tz = timezoneList.find((el) => el.text === timezone);
      updateUserBody.preference = {
        timezone: tz!.utc[0],
        dateTimeFormat: dateFormat,
        asAgo,
        is24H,
      };

      const result = await firstValueFrom(
        this.usersService.updateUser(this.user.id, updateUserBody)
      )
        .then(() => {
          this._alert.open('Successfully updated user profile', 'close', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 2000,
            panelClass: ['success'],
          });
        })
        .catch((error) => {
          return false;
        });
      if (result == false) {
        return result;
      }
      return true;
    } else {
      return true;
    }
  }

  onKey(e: any) {
    const key = e.target.value;
    this.timezoneListFiltered = timezoneList.filter((el) => {
      const flag = el.utc.some((el) =>
        el.toLocaleLowerCase().includes(key.toLocaleLowerCase())
      );
      return (
        flag || el.text.toLocaleLowerCase().includes(key.toLocaleLowerCase())
      );
    });
  }
}
