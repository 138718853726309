import {
  AfterViewInit,
  Component,
  ElementRef,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-compare-image',
  templateUrl: './compare-image.component.html',
  styleUrls: ['./compare-image.component.scss'],
})
export class CompareImageComponent implements AfterViewInit {
  @ViewChild('container') container!: ElementRef;
  @Input() src1: string = '';
  @Input() src2: string = '';
  @Output() stopCompare: EventEmitter<boolean> = new EventEmitter();
  @Output() download: EventEmitter<boolean> = new EventEmitter();

  maxWidth = 0;
  maxHeight = 0;
  showCompare = false;

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.maxWidth = this.container.nativeElement.offsetWidth;
      this.maxHeight = this.container.nativeElement.offsetHeight;
      this.showCompare = true;
    });
  }
}
