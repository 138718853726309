import { Component, ViewEncapsulation, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Setting, KeyValue } from 'src/app/models/settings/setting';
import { SettingsService } from 'src/app/services/setting.service';

import { Location } from '@angular/common';
import { FormControl, Validators } from '@angular/forms';

import { IsoValues } from 'src/app/models/settings/consts/iso';
import { ShutterValues } from 'src/app/models/settings/consts/shutter';
import {
  SettingNew,
  SettingValueStevie,
  SettingValueMax,
} from 'src/app/models/settings/settingNew';
import { ValidationErrors } from 'src/app/models/validationErrors';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-setting-create',
  templateUrl: './setting-create.component.html',
  styleUrls: ['./setting-create.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SettingCreateComponent implements OnDestroy {
  constructor(
    private router: Router,
    private settingsService: SettingsService,
    public _alert: MatSnackBar,
    private _location: Location
  ) {
    this.initialData();
  }
  input: FormControl = new FormControl('', [Validators.required]);
  serverErrorMessage: String = '';
  errorMessages: ValidationErrors[] = [];
  validatorProperties: string[] = [];

  setting!: Setting;
  types = ['Stevie', 'Max'];

  videoCheck: boolean = true;
  stillCheck: boolean = true;

  colourTemperatureControl: FormControl = new FormControl('', [
    Validators.max(9500),
    Validators.min(1700),
  ]);
  shutterValues = ShutterValues;
  isoValues = IsoValues;
  private destroy$ = new Subject();

  initialData() {
    const navigation = this.router.getCurrentNavigation();
    if (navigation == null) {
      this.router.navigate(['settings']);
    } else {
      const state = navigation!.extras.state as {
        setting: Setting;
        orgId: string;
      };
      if (state.setting) {
        //Then it is a copy
        this.setting = state.setting;
        this.setting.organizationId = state.orgId;
        this.setting.name = '';
      } else {
        this.setting = new SettingNew();
        this.setting.organizationId = state.orgId;
      }
    }
  }

  createSetting() {
    console.log(this.setting);
    if (this.videoCheck == false) {
      this.setting.video = null;
    }
    if (this.stillCheck == false) {
      this.setting.still = null;
    }
    this.settingsService
      .createSetting(this.setting)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          console.log(data);
          this._alert.open('Successfully Created Setting', 'close', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 2000,
            panelClass: ['success'],
          });
          this.router.navigate(['settings']);
        },
        (error) => {
          if (error.status === 422) {
            this.errorMessages = [];
            this.validatorProperties = [];
            error.error.validationErrors.forEach((error: ValidationErrors) => {
              this.errorMessages.push(error);
              this.validatorProperties.push(error.property);
              this.input.markAllAsTouched();
              if (error.property == 'Name') {
                this.input.setErrors({ serverErrors: 'validation errors' });
              }
            });
          }
        }
      );
  }

  onSettingTypeChange() {
    if (this.setting.type == 'Stevie') {
      (this.setting.still = new SettingValueStevie()),
        (this.setting.video = new SettingValueStevie());
    } else {
      (this.setting.still = new SettingValueMax()),
        (this.setting.video = new SettingValueMax());
    }
  }

  validateColourTemperature(keyValue: KeyValue) {
    if (typeof keyValue.value == 'number') {
      if (keyValue.value < 1700) {
        keyValue.value = 1700;
      } else if (keyValue.value > 9500) {
        keyValue.value = 9500;
      } else {
        if (keyValue.value % 100 != 0) {
          keyValue.value = 100 * Math.round(keyValue.value / 100 - 0.01);
        }
      }
    } else {
      console.log('Invalid Number Entered:' + keyValue.value);
      keyValue.value = 1700;
    }
  }

  validate(keyValue: KeyValue, min: number, max: number) {
    console.log(typeof keyValue.value);
    if (typeof keyValue.value == 'number') {
      if (keyValue.value < min) {
        keyValue.value = min;
      } else if (keyValue.value > max) {
        keyValue.value = max;
      } else {
        keyValue.value = Math.round(keyValue.value);
      }
    } else {
      console.log('Invalid Number Entered:' + keyValue.value);
      keyValue.value = min;
    }
  }

  backClicked() {
    this._location.back();
  }

  validationMessage(property: string) {
    return this.errorMessages.find((error) => error.property == property)
      ?.message;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
