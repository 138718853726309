import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { EAssetType, IAsset } from 'src/app/models/products/asset';
import { CommentService } from 'src/app/services/comment.service';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { IComment, ICommentResponse } from 'src/app/models/comment';
import { AuthService } from 'src/app/services/auth.service';
import { ProductsService } from 'src/app/services/products.service';
import { debounce, get, isEmpty } from 'lodash';
import { SignalrService } from 'src/app/services/signalr.service';
import { environment } from 'src/environments/environment';
import {
  IBoundingBox,
  IBoundingBoxPercentage,
} from 'src/app/models/boundingBox';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { AssetsService } from 'src/app/services/assets.service';
import { MetaData, MetaDataGroups } from 'src/app/models/assetMetaData';
import { MatTableDataSource } from '@angular/material/table';
import { E } from '@angular/cdk/keycodes';
import { KeyValue } from '@angular/common';
import { AlertService } from 'src/app/services/alert.service';
import { IAssetLabel } from 'src/app/models/assetLabel';
import {
  DynamicTableDialogComponent,
  TableComponentProps,
  TableHeader,
} from '../dynamic-table/dynamic-table.component';
import { MatDialog } from '@angular/material/dialog';

enum EChannel {
  INTERNAL,
  EXTERNAL,
}

@Component({
  selector: 'app-chat-box',
  templateUrl: './chat-box.component.html',
  styleUrls: ['./chat-box.component.scss'],
})
export class ChatBoxComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  @Input() currentAsset!: IAsset;
  @Input() assetDetail!: IAsset;
  @Input() showExternal = false;
  @Input() onlyProperties = false;
  @Input() isCurrent = false;
  @Input() productTouchAssetId = '';
  @Input() masterSuiteTouchAssetId = '';
  @Input() organizationId = '';
  @Input() commentBoundingBox: IBoundingBox | null = null;
  @Input() contentExtracting = false;
  @Output() versionSelected = new EventEmitter();
  @Output() commentHover = new EventEmitter();
  @Output() clearBoundingBox = new EventEmitter();
  @Output() propertiesSelected = new EventEmitter();
  @Output() labelViewEmitter = new EventEmitter();
  @Output() boundingBoxProportionEmitter =
    new EventEmitter<IBoundingBox | null>();
  @Output() contentExtractingEmitter = new EventEmitter<boolean>();
  @ViewChild('listCommentEl') private listCommentRef!: ElementRef;
  commentText = '';
  focusId = '';
  listChannel = [
    {
      value: 'internal',
      label: 'DISCUSSION',
    },
  ];

  listComment: IComment[] = [];
  pageNumber = 1;
  pageSize = 10;
  total = 10;
  assetId = '';
  assetType = '';
  currentChannel = EChannel.INTERNAL;
  userInfo: any = {};
  loading = false;
  private destroy$ = new Subject();
  users: any = [];
  isTagging = false;
  filterUser = debounce((value) => {
    this.commentServices
      .filterUserByKey(
        value,
        this.currentChannel === EChannel.EXTERNAL ? this.organizationId : ''
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (value: any) => {
          this.users = value.splice(0, 4);
        },
        error: () => {
          this.users = [];
        },
      });
  }, 500);
  taggedList: any = [];
  private groupName?: string;
  loadingAvatar: any = {};
  avatarData: any = {};
  apiBase = environment.apiBase;
  isFirstLoaded = false;
  constructor(
    private signalrService: SignalrService,
    private commentServices: CommentService,
    private authServices: AuthService,
    private route: ActivatedRoute,
    private productService: ProductsService,
    private assetsService: AssetsService,
    private alertService: AlertService,
    public dialog: MatDialog
  ) {
    this.assetId = this.route.snapshot.paramMap.get('assetId') as string;
    this.assetType = this.route.snapshot.paramMap.get('assetType') as string;
  }

  ngOnInit(): void {
    console.log(this.onlyProperties);
    if (this.onlyProperties) {
      this.isFirstLoaded = true;
      this.getAssetMetaData();
    } else {
      this.initialData();
      this.commentServices.avatarData.subscribe({
        next: (data) => {
          this.avatarData = data;
        },
      });
      this.signalrService.connectState.subscribe({
        next: (data: boolean) => {
          if (data) {
            this.joinGroup();
            this.commentServices.setConnectionId(
              this.signalrService.connectionId
            );
          }
        },
      });
    }
  }

  private joinGroup() {
    if (this.assetType === EAssetType.SHOOT_ASSETS) {
      this.signalrService
        .joinShootAssetGroup(
          'JoinShootAssetCommentGroup',
          this.productTouchAssetId
        )
        ?.then((group: string) => (this.groupName = group));
    } else {
      this.signalrService
        .joinPostAssetGroup(
          'JoinPostAssetCommentGroup',
          this.productTouchAssetId,
          this.currentChannel === EChannel.INTERNAL ? 'INTERNAL' : 'EXTERNAL'
        )
        ?.then((group: string) => (this.groupName = group));
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    let isAllFirstChange = true;
    console.log(this.productTouchAssetId);
    this.assetId = this.route.snapshot.paramMap.get('assetId') as string;
    for (const key in changes) {
      if (Object.prototype.hasOwnProperty.call(changes, key)) {
        const element = changes[key];
        if (!element.firstChange) {
          isAllFirstChange = false;
          break;
        }
      }
    }
    if (
      !isAllFirstChange &&
      ((!!changes['productTouchAssetId'] &&
        changes['productTouchAssetId'].previousValue &&
        changes['productTouchAssetId'].currentValue !==
          changes['productTouchAssetId'].previousValue) ||
        (get(changes, 'currentAsset.previousValue.id') !==
          get(changes, 'currentAsset.currentValue.id') &&
          !this.loading))
    ) {
      this.loading = true;
      if (this.onlyProperties || this.propertiesView) {
        this.getAssetMetaData();
      } else if (this.labelView) {
        this.getAssetLabel();
      } else {
        this.initialData();
        //Leave Orginal
        this.signalrService.leaveGroup(this.groupName!);

        //Join New
        this.joinGroup();
      }
    } else if (this.onlyProperties || this.propertiesView) {
      this.loading = true;
      this.getAssetMetaData();
    } else if (this.labelView) {
      this.loading = true;
      this.getAssetLabel();
    }
  }

  ngAfterViewInit(): void {
    if (this.showExternal) {
      this.listChannel.pop();
      this.listChannel.push({
        value: 'internal',
        label: 'INTERNAL DISCUSSION',
      });
      this.listChannel.push({
        value: 'external',
        label: 'EXTERNAL DISCUSSION',
      });
    }
  }

  getAvatar(authorProfileImageUrl: string) {
    if (!authorProfileImageUrl || this.loadingAvatar[authorProfileImageUrl]) {
      return;
    }
    this.loadingAvatar[authorProfileImageUrl] = true;
    this.commentServices
      .getAvatar(this.apiBase + authorProfileImageUrl)
      .subscribe({
        next: ({ changingThisBreaksApplicationSecurity }: any) => {
          this.commentServices.setAvatar(
            authorProfileImageUrl,
            changingThisBreaksApplicationSecurity
          );
          this.loadingAvatar[authorProfileImageUrl] = false;
        },
      });
  }

  initialData() {
    this.productService.setPostAssetState(this.currentChannel);
    this.productService.postAssetState.subscribe({
      next: (data: EChannel) => {
        this.currentChannel = data;
        const channel = get(this.route, 'snapshot.queryParams.channel', '');
        if (this.authServices.getRole('client') || channel === 'external') {
          this.currentChannel = EChannel.EXTERNAL;
        }
        this.isFirstLoaded = true;
      },
    });
    this.pageNumber = 1;
    this.pageSize = 10;
    this.listComment = [];
    this.isTagging = false;
    this.taggedList = [];
    this.loadComment();

    this.userInfo = this.authServices.getAuthInformation();

    this.signalrService.chatBoxData.subscribe({
      next: (data: IComment) => {
        if (isEmpty(data)) {
          return;
        }
        const idx = this.listComment.findIndex((el) => el.id === data.id);
        if (idx > -1) {
          return;
        }
        const avatar = this.avatarData[data.authorProfileImageUrl || ''];
        if (!avatar) {
          this.getAvatar(data.authorProfileImageUrl || '');
        }
        this.listComment.push({
          ...data,
          comment: this.replaceUserNameById({
            ...data,
            userTagIds: data.tagUsers,
          }),
          version:
            this.currentAsset.id ===
            data[
              this.assetType === EAssetType.SHOOT_ASSETS
                ? 'shootAssetId'
                : 'postAssetId'
            ]
              ? 'Current'
              : `v${
                  data[
                    this.assetType === EAssetType.SHOOT_ASSETS
                      ? 'shootAssetVersion'
                      : 'postAssetVersion'
                  ]
                }`,
        });
        this.scrollToBottom();
      },
    });
  }

  replaceUserNameById(data: IComment): string {
    let result = data.comment;
    const userData = data.userTagIds || [];
    for (let index = 0; index < userData.length; index++) {
      const element = userData[index];
      result = result.replaceAll(element.id, element.name);
    }
    return result;
  }

  loadComment() {
    this.loading = true;
    this.commentServices[
      this.assetType === EAssetType.SHOOT_ASSETS
        ? 'getShootAssetComment'
        : 'getPostAssetComment'
    ](
      this.assetType === EAssetType.SHOOT_ASSETS
        ? this.assetId
        : this.productTouchAssetId,

      {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      },
      this.currentChannel === EChannel.INTERNAL,
      this.assetType === EAssetType.POST_ASSETS
        ? this.masterSuiteTouchAssetId
        : ''
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: ICommentResponse) => {
          const results = data.results || [];
          this.listComment = [];
          this.listComment = [...results.reverse(), ...this.listComment].map(
            (el: IComment) => {
              const avatar = this.avatarData[el.authorProfileImageUrl || ''];
              if (!avatar) {
                this.getAvatar(el.authorProfileImageUrl || '');
              }
              return {
                ...el,
                comment: this.replaceUserNameById({
                  ...el,
                  userTagIds: el.tagUsers,
                }),
                userTagIds: el.tagUsers,
                version:
                  this.currentAsset.id ===
                  el[
                    this.assetType === EAssetType.SHOOT_ASSETS
                      ? 'shootAssetId'
                      : 'postAssetId'
                  ]
                    ? 'Current'
                    : `v${
                        el[
                          this.assetType === EAssetType.SHOOT_ASSETS
                            ? 'shootAssetVersion'
                            : 'postAssetVersion'
                        ]
                      }`,
              };
            }
          );
          this.loading = false;
          this.pageNumber = data.pageNumber;
          this.pageSize = data.pageSize;
          this.total = data.total;
          this.scrollToBottom();
        },
        error: () => {
          this.loading = true;
        },
      });
  }

  propertiesView: boolean = false;
  labelView: boolean = false;
  metaDataGroups: MetaDataGroups[] = [];
  onChangeChannel(tab: MatTabChangeEvent) {
    console.log('onChangeChannel', tab);
    console.log(this.assetDetail);
    if (tab.tab.textLabel == 'PROPERTIES') {
      this.getAssetMetaData();
    } else if (tab.tab.textLabel == 'LABEL') {
      this.getAssetLabel();
    } else {
      this.propertiesView = false;
      this.labelView = false;
      this.labelViewEmitter.emit(this.labelView);
      this.propertiesSelected.emit(this.propertiesView);
      this.currentChannel = tab.index;
      this.productService.setPostAssetState(this.currentChannel);
      this.listComment = [];
      this.loadComment();
      this.signalrService.leaveGroup(this.groupName!);
      this.joinGroup();
    }
  }

  noMetaData: boolean = false;
  getAssetMetaData() {
    this.propertiesView = true;
    this.labelView = false;
    this.propertiesSelected.emit(this.propertiesView);
    this.labelViewEmitter.emit(this.labelView);
    this.assetsService
      .getAssetMetaData(this.assetDetail.id, this.assetType)
      .subscribe((res) => {
        this.metaDataGroups = [];
        if (res.metaData != null) {
          this.noMetaData = false;
          var groups = this.groupBy<MetaData>(res.metaData, 'group');
          let property: keyof typeof groups;
          for (property in groups) {
            var group: MetaDataGroups = {
              group: property,
              metaData: new MatTableDataSource<MetaData>(groups[property]),
            };
            this.metaDataGroups.push(group);
          }
          this.metaDataGroups.sort((a, b) => (a.group > b.group ? 1 : -1));
        } else {
          this.noMetaData = true;
        }
        this.loading = false;
      });
  }

  assetLabel: IAssetLabel | null = null;
  assetLabelVersions: IAssetLabel[] = [];
  assetLabelResultData: MatTableDataSource<KeyValue<string, any>> =
    new MatTableDataSource<KeyValue<string, any>>();
  activeAssetLabelTab: number = 0;

  getAssetLabel() {
    this.propertiesView = false;
    this.labelView = true;
    this.loading = true;
    this.propertiesSelected.emit(this.propertiesView);
    this.labelViewEmitter.emit(this.labelView);
    this.assetLabelResultData = new MatTableDataSource<KeyValue<string, any>>(
      []
    );
    this.assetsService
      .getAssetLabel(this.assetDetail.id, this.assetType)
      .subscribe((res) => {
        console.log(res);
        this.assetLabelVersions = res;
        this.assetLabel = res[0];
        var current = res[0];
        if (res == null) {
          this.boundingBoxProportionEmitter.emit(null);
          this.loading = false;
          return;
        }

        console.log(current);
        this.parseContentsToTable(current);
      });
  }

  onLabelTabChange(event: number) {
    this.parseContentsToTable(this.assetLabelVersions[event]);
    this.activeAssetLabelTab = event;
  }

  parseContentsToTable(current: IAssetLabel) {
    if (current.label == null) {
      this.boundingBoxProportionEmitter.emit(null);
      this.loading = false;
      this.assetLabelResultData = new MatTableDataSource<
        KeyValue<string, any>
      >();
      return;
    }
    let result = Object.keys(current.label)
      .filter((key) => current.label[key] != null)
      .map(function (key) {
        if (
          key == 'additionalInfo' ||
          key == 'packaging' ||
          key == 'ingredients' ||
          key == 'nutritionFacts'
        ) {
          var value = Object.keys(current.label[key])
            .filter((key2) => current.label[key][key2] != null)
            .map(function (key2) {
              return {
                key: key2,
                value: current.label[key][key2],
              };
            });
          return { key: key, value: value };
        }
        if (
          (key == 'marketingCopy' || key == 'regulatoryInfo') &&
          current!.label[key] != null
        ) {
          var values: any = [];
          current!.label[key].forEach((item: any) => {
            var value = Object.keys(item)
              .filter((itemKey) => item[itemKey] != null)
              .map(function (itemKey) {
                return { key: itemKey, value: item[itemKey] };
              });
            values.push(value);
          });
          return { key: key, value: values };
        }
        return { key: key, value: current!.label[key] };
      });
    this.assetLabelResultData = new MatTableDataSource<KeyValue<string, any>>(
      result
        .filter((el) => el.value != null)
        .sort((a, b) => a.key.localeCompare(b.key))
    );
    console.log(result);
    const boundingBoxProportion: IBoundingBox = this.assetLabel!.labelRegion;
    this.boundingBoxProportionEmitter.emit(boundingBoxProportion);
    this.loading = false;
  }

  openTable(data: TableComponentProps): void {
    this.dialog.open(DynamicTableDialogComponent, {
      data: {
        content: data,
      },
    });
  }

  parseToTableProb(list: any[]) {
    var keys: TableHeader[] = Object.keys(list[0]).map((x) => {
      return { field: x, header: x };
    });

    var res: TableComponentProps = {
      headers: keys,
      datTable: list,
    };
    return res;
  }

  downloadExcel() {
    var s3Uri = this.assetLabel!.excelS3Uri;
    var download = s3Uri.split('/')[s3Uri.split('/').length - 1];
    this.productService
      .downloadSingleImage(s3Uri.split('//')[1])
      .subscribe((res) => {
        const blod: Blob = res.body as Blob;
        const a = document.createElement('a');
        const fileData = window.URL.createObjectURL(blod);
        a.href = fileData;
        a.download = download;
        a.click();
        window.URL.revokeObjectURL(fileData);
      });
  }

  stringify(value: any) {
    return JSON.stringify(value);
  }

  generateExifData() {
    this.assetsService
      .generateAssetMetaData(this.assetDetail.id, this.assetType)
      .subscribe((res) => {
        console.log(res);
      });
  }

  extractAssetContent() {
    this.assetsService
      .extractLabelBoundingBox(this.assetDetail.id, this.assetType)
      .subscribe({
        next: (result: any) => {
          this.alertService.successToast('Asset Content Queued for Extraction');
          this.contentExtracting = true;
          this.contentExtractingEmitter.emit(true);
          console.log(result);
        },
        error: (error: any) => console.error(error),
      });
  }

  parseAssetLabel() {
    this.assetsService
      .parseAssetLabel(this.assetDetail.id, this.assetType)
      .subscribe((res) => {
        console.log(res);
        this.assetLabel = res;
        let result = Object.keys(res.label).map(function (key) {
          return { key: key, value: res.label[key] };
        });
        this.assetLabelResultData = new MatTableDataSource<
          KeyValue<string, any>
        >(result.filter((el) => el.value != null));
        console.log(result);
        console.log(this.assetLabelResultData);
        this.loading = false;
      });
  }

  groupBy<T>(collection: T[], key: keyof T) {
    const groupedResult = collection.reduce((previous, current) => {
      if (!previous[current[key]]) {
        previous[current[key]] = [] as T[];
      }

      previous[current[key]].push(current);
      return previous;
    }, {} as any);
    return groupedResult;
  }

  parseCommentText() {
    const splitText = this.commentText.split('');
    let result = '';
    let i = 0;
    let pair = -1;
    while (i < splitText.length) {
      if (splitText[i] === '@') {
        if (pair === -1) {
          pair = i;
        } else {
          splitText[pair] = '';
        }
      } else if (splitText[i] === '#') {
        if (pair === -1) {
          splitText[i] = '';
        } else {
          pair = -1;
        }
      }
      if (i === splitText.length - 1 && pair !== -1) {
        splitText[pair] = '';
      }
      i++;
    }
    result = splitText.filter((el: string) => !!el).join('');
    return result;
  }

  removeTag(text: string) {
    const idx = this.commentText.indexOf(text);
    this.commentText =
      this.commentText.slice(0, idx - 1) + this.commentText.slice(idx);
    this.commentText =
      this.commentText.slice(0, idx - 1 + text.length) +
      this.commentText.slice(idx + text.length);
  }

  getUserTagged() {
    const rule = /@(.*?)#/;
    const userTagIds: any = [];
    let splitText = this.commentText.split(rule);
    let i = 1;
    while (i < splitText.length) {
      const user = this.taggedList.find((el: any) => splitText[i] === el.name);
      if (!!user) {
        userTagIds.push(user);
        this.commentText = this.commentText.replace(splitText[i], user.id);
      } else {
        this.removeTag(splitText[i]);
      }
      i += 2;
    }
    return userTagIds;
  }

  sendComment() {
    console.log('assetDetail', this.assetDetail);

    if (!this.commentText) {
      return;
    }
    const idTemp = Math.floor(Math.random() * 999999);

    this.commentText = this.parseCommentText();
    const tagUsers = this.getUserTagged();
    const userTagIds = tagUsers.map((el: any) => el.id);
    this.commentText = this.commentText.replaceAll('@', '<a>');
    this.commentText = this.commentText.replaceAll('#', '</a>');

    const now = new Date();
    this.listComment.push({
      id: idTemp.toString(),
      createdAt: now.toString(),
      version: `v${this.assetDetail.versionNumber}`,
      authorUserId: this.userInfo.id,
      authorUsername: this.userInfo.name,
      authorProfileImageUrl: this.userInfo.profileImageUrl,
      comment: this.replaceUserNameById({
        comment: this.commentText.trim().replaceAll('\n', '<br/>'),
        userTagIds: tagUsers,
      } as IComment),
      organizationId: this.organizationId,
      productTouchAssetId:
        this.assetType === EAssetType.SHOOT_ASSETS
          ? this.assetId
          : this.productTouchAssetId,
      shootAssetId: this.assetDetail.id,
      shootAssetVersion: this.assetDetail.versionNumber,
      shootAssetVersionDate: new Date().getTime().toString(),
      postAssetId: this.assetDetail.id,
      postAssetVersion: this.assetDetail.versionNumber,
      postAssetVersionDate: new Date().getTime().toString(),
      updatedAt: now.toString(),
      userTagIds,
      imageReference: this.commentBoundingBox != null ? true : false,
      boundingBoxReference: this.commentBoundingBox!,
    });

    let payload = {
      comment: this.commentText.trim().replaceAll('\n', '<br/>'),
      versionNumber: this.assetDetail.versionNumber,
      shootAssetId: this.assetDetail.id,
      postAssetId: this.assetDetail.id,
      userTagIds,
      imageReference: this.commentBoundingBox != null ? true : false,
      boundingBoxReference: this.commentBoundingBox,
    };
    console.log('send', payload);

    this.commentServices[
      this.assetType === EAssetType.SHOOT_ASSETS
        ? 'sentShootAssetComment'
        : 'sentPostAssetComment'
    ](
      this.assetType === EAssetType.SHOOT_ASSETS
        ? this.assetId
        : this.productTouchAssetId,
      payload,
      this.currentChannel === EChannel.INTERNAL
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (value: IComment) => {
          console.log('sent', value);
          const idx = this.listComment.findIndex(
            (el) => el.id === idTemp.toString()
          );
          if (idx > -1) {
            this.listComment[idx] = {
              ...value,
              comment: this.replaceUserNameById({
                ...value,
                userTagIds: value.tagUsers || tagUsers,
              }),
              userTagIds: value.tagUsers,
              version:
                this.currentAsset.id ===
                value[
                  this.assetType === EAssetType.SHOOT_ASSETS
                    ? 'shootAssetId'
                    : 'postAssetId'
                ]
                  ? 'Current'
                  : `v${
                      value[
                        this.assetType === EAssetType.SHOOT_ASSETS
                          ? 'shootAssetVersion'
                          : 'postAssetVersion'
                      ]
                    }`,
            };
          }
        },
      });
    this.clearBoundingBox.emit();
    this.commentText = '';
    this.focusId = '';
    this.isTagging = false;
    this.taggedList = [];
    this.scrollToBottom();
  }

  triggerFunction(event: any) {
    if (event.key === 'Enter' && !event.shiftKey && !this.isTagging) {
      event.preventDefault();
      this.sendComment();
    }
  }

  scrollToBottom(): void {
    try {
      setTimeout(() => {
        const el = document.getElementById(
          this.focusId
            ? this.focusId
            : this.listComment[this.listComment.length - 1]?.id
        );
        if (el) {
          el.scrollIntoView();
        }
      });
    } catch (err) {}
  }

  onScroll(event: any) {
    if (event.srcElement.scrollTop === 0) {
      if (this.pageNumber * this.pageSize < this.total) {
        this.pageNumber++;
        this.focusId = !!this.listComment[0] ? this.listComment[0].id : '';
        if (this.loading) {
          return;
        }
        this.loadComment();
      } else {
        this.focusId = '';
      }
    }
  }

  focusComment(status: boolean) {
    this.productService.updateIsFocusComment(status);
  }

  onFilterUser(value: string) {
    console.log('onFilterUser', value);
    if (value.length < 1) {
      this.users = [];
    } else {
      this.filterUser(value);
    }
  }

  setOpenTagger() {
    console.log('setOpenTagger');
    this.isTagging = true;
  }

  onTagged(value: any) {
    console.log('onTagged', value);
    this.taggedList.push(value);
  }

  setCloseTagger() {
    setTimeout(() => {
      console.log('setCloseTagger');
      this.isTagging = false;

      this.commentText += '#';
    });
  }

  ngOnDestroy(): void {
    this.signalrService.leaveGroup(this.groupName!);
    this.signalrService.clearData();
  }
}
