<h1 mat-dialog-title style="font-size: 30px">Product Update</h1>
<mat-dialog-content class="mat-typography">
  <div>
    <mat-form-field appearance="outline" color="primary" class="dialog-input">
      <mat-label>Product Barcode</mat-label>
      <input
        matInput
        [disabled]="loading"
        [formControl]="barcodeFormControl"
        value="{{ product.barcode }}"
        [(ngModel)]="product.barcode"
      />
      <mat-error *ngIf="validatorProperties.includes('Barcode')">
        {{ validationMessage("Barcode") }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" color="primary" class="dialog-input">
      <mat-label>Reference</mat-label>
      <input
        matInput
        [disabled]="loading"
        [formControl]="referenceFormControl"
        value="{{ product.reference }}"
        [(ngModel)]="product.reference"
      />
      <mat-error *ngIf="validatorProperties.includes('Reference')">
        {{ validationMessage("Reference") }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" color="primary" class="dialog-input">
      <mat-label>Description</mat-label>
      <input
        matInput
        [disabled]="loading"
        [formControl]="descriptionFormControl"
        value="{{ product.description }}"
        [(ngModel)]="product.description"
      />
      <mat-error *ngIf="validatorProperties.includes('Description')">
        {{ validationMessage("Description") }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" color="primary" class="dialog-input">
      <mat-label>Product Link</mat-label>
      <input
        matInput
        [disabled]="loading"
        value="{{ product.productLink }}"
        [(ngModel)]="product.productLink"
      />
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button mat-dialog-close>Cancel</button>
  <button mat-button (click)="updateProduct()" #create color="buttonSuccess">
    Update
  </button>
</mat-dialog-actions>
