<div class="preference">
  <div class="preference__avatar">
    <div class="preference__avatar-wrapper">
      <img
        class="image"
        *ngIf="
          userData.profileImageUrl !== null && !tmpAvatar;
          else placeholder
        "
        [src]="apiBase + userData.profileImageUrl | secure | async"
        alt=""
      />
      <ng-template #placeholder>
        <img class="image" [src]="tmpAvatar" />
      </ng-template>

      <div class="preference__edit-avatar" (click)="imageInput.click()">
        <mat-icon>edit</mat-icon>
      </div>
      <input
        #imageInput
        hidden="true"
        type="file"
        onclick="this.value=null"
        (change)="updateUserProfileImage(imageInput.files!)"
        accept=".jpg,.png"
      />
    </div>
  </div>

  <form class="form-setting" [formGroup]="formData">
    <div>
      <mat-form-field appearance="outline" color="primary" class="dialog-input">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" />
        <mat-error *ngIf="formData.get('name')?.hasError('required')"
          >Name is required</mat-error
        >
      </mat-form-field>

      <mat-form-field appearance="outline" color="primary" class="dialog-input">
        <mat-label>Email Address</mat-label>
        <input matInput formControlName="email" />
      </mat-form-field>

      <mat-form-field
        *ngIf="isStaff"
        appearance="outline"
        color="primary"
        class="dialog-input"
      >
        <mat-label>User Group</mat-label>
        <mat-select formControlName="group" multiple>
          <mat-option [value]="group.value" *ngFor="let group of groupList">{{
            group.text
          }}</mat-option>
        </mat-select>
        <mat-error *ngIf="formData.get('group')?.hasError('required')"
          >User Group is required</mat-error
        >
      </mat-form-field>
    </div>

    <div>
      <div class="preference__label">
        <mat-icon>schedule</mat-icon>
        <span>Preference settings</span>
      </div>
      <mat-form-field appearance="outline" color="primary" class="dialog-input">
        <mat-label>Timezone</mat-label>

        <input
          type="text"
          matInput
          formControlName="timezone"
          [matAutocomplete]="auto"
          (keyup)="onKey($event)"
        />
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option
            [value]="tz.text"
            *ngFor="let tz of timezoneListFiltered"
            >{{ tz.text }}</mat-option
          >
        </mat-autocomplete>
      </mat-form-field>
      <div class="inline-row">
        <div class="flex-grow-1">
          <mat-form-field
            appearance="outline"
            color="primary"
            class="dialog-input"
          >
            <mat-label>DateTime Format</mat-label>
            <mat-select formControlName="dateFormat">
              <mat-option
                [value]="el.value"
                *ngFor="let el of dateTimeFormat"
                >{{ el.text }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="full-row">
          <mat-checkbox
            class="example-margin"
            formControlName="is24H"
            color="primary"
          >
            24 hours time
          </mat-checkbox>
        </div>
      </div>
      <div>
        <mat-radio-group
          aria-label="Select an option"
          formControlName="asAgo"
          color="primary"
        >
          <mat-radio-button [value]="true">Days ago</mat-radio-button>
          <mat-radio-button [value]="false">Date/Time</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </form>

  <mat-dialog-actions align="end">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button
      mat-button
      [disabled]="loading"
      (click)="onSave()"
      color="buttonSuccess"
    >
      Save
    </button>
  </mat-dialog-actions>
</div>
