import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EAssetType, EStateAsset, IAsset } from 'src/app/models/products/asset';
import { ProductsService } from 'src/app/services/products.service';
import { environment } from 'src/environments/environment';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IPreference } from 'src/app/models/users/userProfile';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { OutputFormat } from 'src/app/models/organizations/form-setting';

@Component({
  selector: 'app-card-item',
  templateUrl: './card-item.component.html',
  styleUrls: ['./card-item.component.scss'],
})
export class CardItemComponent implements AfterContentInit, OnInit {
  @Input() showExternalStatus!: boolean;
  @Input() showParentInfo!: boolean;
  @Input() dataType!: any;
  @Input() data!: IAsset;
  @Input() editCallback!: any;
  @Input() touchId: string = '';
  @Input() releasedInCollection: boolean = false;
  @Input() outputFormat!: OutputFormat;
  @Output() done = new EventEmitter<any>();
  @Output() selectedCheckbox = new EventEmitter();
  isImageLoaded = false;

  EStateAsset = EStateAsset;
  statusMapping: any = {
    [EStateAsset.APPROVED]: 'Approved',
    [EStateAsset.RELEASED]: 'Released',
    [EStateAsset.REJECTED]: 'Rejected',
    [EStateAsset.COMPLETE]: 'Complete',
    [EStateAsset.PROCESSING]: 'Processing',
    [EStateAsset.OUT_DATED]: 'OutDated',
  };
  apiBase = environment.apiBase;
  currentConfigDate!: IPreference;
  isStaff: boolean = false;
  constructor(
    private productsService: ProductsService,
    private clipboard: Clipboard,
    private activeRoute: ActivatedRoute,
    private _alert: MatSnackBar,
    private authService: AuthService
  ) {}

  // Drag and drop upload
  hovering: boolean = false;
  uploaded: boolean = false;
  invalidFile: boolean = false;
  invalidReason: string = '';
  newOutputFile!: File;
  newOutputFileImage!: string | ArrayBuffer | null;
  fileDrop(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.uploaded = false;
    if (event.dataTransfer.files.length != 1) {
      this.invalidReason = 'Upload one file';
      this.invalidFile = true;
      return;
    }
    let file = event.dataTransfer.files[0];
    console.log(file);
    if (!this.validateFile(file)) {
      this.invalidReason = 'Invalid File Format';
      this.invalidFile = true;
      return;
    }
    this.invalidFile = false;
    this.newOutputFile = file;
    this.extractImageFromFile();
  }
  fileUpload(fileInputEvent: FileList) {
    this.newOutputFile = fileInputEvent[0];
    this.extractImageFromFile();
  }
  validateFile(file: File): boolean {
    switch (
      this.data.type == 'Still'
        ? this.outputFormat.stillFormat.format.toLowerCase()
        : this.outputFormat.videoFormat.format.toLowerCase()
    ) {
      case 'jpg':
        return file.type == 'image/jpg' || file.type == 'image/jpeg';
      case 'png':
        return file.type == 'image/png';
      case 'tiff':
        return file.type == 'image/tiff';
      case 'webp':
        return file.type == 'image/webp';
      case 'mp4h264':
        return file.type == 'video/mp4';
      case 'mp4h265':
        return file.type == 'video/mp4';
      case 'webm':
        return file.type == 'video/webm';
      case 'gif':
        return file.type == 'image/gif';
      default:
        return false;
    }
  }
  extractImageFromFile(): void {
    let fileReader: FileReader = new FileReader();
    fileReader.readAsDataURL(this.newOutputFile!);
    fileReader.addEventListener(
      'load',
      () => {
        this.newOutputFileImage = fileReader.result;
        this.uploaded = true;
      },
      false
    );
  }
  counter = 0;
  dragEnter() {
    this.counter++;
  }
  dragLeave() {
    this.counter--;
    if (this.counter === 0) {
      this.hovering = false;
    }
  }
  cancel() {
    this.hovering = false;
    this.uploaded = false;
  }
  confirm() {
    this.productsService
      .uploadOutputAsset(
        this.data.postAssetId!,
        this.data.outputFormatId!,
        this.newOutputFile
      )
      .subscribe((res) => {
        this.hovering = false;
        this.uploaded = false;
        this.data.id = res.id;
        this.data.rawUri =
          this.data.reviewUri =
          this.data.thumbnailUri =
            res.uri!;
        this.data.manuallyUploaded = true;
        this.data.createdAt = this.data.postProcessedDate = res.createdAt;
        this.data.internalReviewState = EStateAsset.APPROVED;
        this._alert.open('Successfully Uploaded Output Asset', 'close', {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 2000,
          panelClass: ['success'],
        });
      });
  }
  getFormat(output: OutputFormat): string {
    if (this.data.type == 'Still') {
      return '.' + output.stillFormat.format;
    }
    switch (output.videoFormat.format.toLowerCase()) {
      case 'mp4h264':
        return '.mp4';
      case 'mp4h265':
        return '.mp4';
      case 'webm':
        return '.webm';
      default:
        return '.' + output.videoFormat.format;
    }
  }

  copyToClipboard(text: string) {
    this.clipboard.copy(text);
    this._alert.open(`Copied Key: ${text}`, '', {
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      duration: 2000,
      panelClass: ['info'],
    });
  }

  get parentLabel() {
    return this.data.label?.split('.')[0];
  }

  invalidFormat: boolean = false;
  ngOnInit(): void {
    if (
      this.dataType == 'outputAssets' &&
      this.data.type == 'Still' &&
      this.outputFormat.stillFormat.format.toLowerCase() == 'tiff'
    ) {
      this.invalidFormat = true;
    }
    this.isStaff = this.authService.getRole('staff');
    this.authService.currentPreference.subscribe((res) => {
      this.currentConfigDate = res;
    });
    window.addEventListener(
      'dragover',
      function (e) {
        e = e || event;
        e.preventDefault();
      },
      false
    );
    window.addEventListener(
      'drop',
      function (e) {
        e = e || event;
        e.preventDefault();
      },
      false
    );
  }

  ngAfterContentInit(): void {
    this.done.emit();
  }

  onEdit() {
    if (this.editCallback) {
      this.editCallback.call(this, {
        id: this.data.productTouchAssetId,
        assetType:
          this.dataType == 'production'
            ? EAssetType.SHOOT_ASSETS
            : this.dataType == 'postProduction'
            ? EAssetType.POST_ASSETS
            : EAssetType.OUTPUT_ASSETS,
        masterSuiteTouchAssetId: this.data.masterSuiteTouchAssetId,
        outputFormatId: this.data.outputFormatId,
      });
    }
  }

  checkImageLoaded() {
    this.isImageLoaded = true;
  }

  onSelect() {
    this.selectedCheckbox.emit({ touchId: this.touchId, data: this.data });
  }

  getLinkDownload() {
    const tmp = this.data.rawUri!.split('?')[0].split('/');
    const fileName = tmp[tmp.length - 1].split('.');
    var download = `${fileName[0]}.${fileName[1]}`;
    if (this.dataType == 'postProduction') {
      download = `${fileName[0]}_${this.data.selectsIndex / 1000 + 1}.${
        fileName[1]
      }`;
    } else if (this.dataType == 'production' && this.data.enhancementFlag) {
      download = `${fileName[0]}_${this.data.enhancementIndex! / 1000 + 1}.${
        fileName[1]
      }`;
    }
    this.productsService
      .downloadSingleImage(this.data.rawUri!.split('//')[1])
      .subscribe((res) => {
        const blod: Blob = res.body as Blob;
        const a = document.createElement('a');
        const fileData = window.URL.createObjectURL(blod);
        a.href = fileData;
        a.download = download;
        a.click();
        window.URL.revokeObjectURL(fileData);
      });
  }

  downloadTextFile() {
    const productId = this.activeRoute.snapshot.paramMap.get('productId') || '';
    this.productsService
      .downloadTextFile(productId, this.data.masterSuiteTouchAssetId!)
      .subscribe((res) => {
        const blod: Blob = res.body as Blob;
        const a = document.createElement('a');
        const fileName = res.headers
          .get('content-disposition')
          ?.split(';')[1]
          .split('=')[1]
          .replace(/["]+/g, '');
        const fileData = window.URL.createObjectURL(blod);
        a.href = fileData;
        a.download = fileName || '';
        a.click();
        window.URL.revokeObjectURL(fileData);
      });
  }

  getLinkImage() {
    if (!this.isStaff && !this.releasedInCollection) {
      return 'assets/image-placeholder.jpg';
    }
    return ProductsService.getLinkImage(this.data.thumbnailUri);
  }
}
