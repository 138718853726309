import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-auto-suggestion',
  templateUrl: './auto-suggestion.component.html',
  styleUrls: ['./auto-suggestion.component.scss'],
})
export class AutoSuggestionComponent {
  suggestion = new FormControl('');
  suggestions: string[] = [];
  isExternal = true;
  isShowing = false;

  constructor(
    public _alert: MatSnackBar,
    private router: Router,
    private productService: ProductsService
  ) {}

  handleSuggestion(event: any) {
    if (event.key !== 'Enter') {
      return;
    }
    console.log('handleSuggestion', event, event.target.value);
    this.handleSearch(event.target.value);
  }
  search() {
    if (this.suggestion.value !== '') {
      this.handleSearch(this.suggestion.value || '');
    }
  }

  handleSearch(key: string) {
    const code = key.trim();

    this.suggestions = [];
    this.isShowing = !!code;
    if (!!code && code.toString().length >= 4) {
      this.isShowing = true;
      this.productService.setCodeSearch(code);
      this.router.navigateByUrl(`product-search/${code}`);
    } else {
      this._alert.open('Input at least 4 characters', 'close', {
        horizontalPosition: 'right',
        verticalPosition: 'top',
        duration: 3000,
        panelClass: ['error'],
      });
    }
  }
}
