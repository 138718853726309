import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { Location } from '@angular/common';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  Casing,
  FilenameFormat,
  TokenFormat,
} from 'src/app/models/organizations/form-setting';
import { filenameToken } from '../../organization.data';
import { WarningModalComponent } from 'src/app/components/shared/warning-modal/warning-modal.component';

@Component({
  selector: 'app-output-filename-update',
  templateUrl: './output-filename-update.component.html',
  styleUrl: './output-filename-update.component.scss',
})
export class OutputFilenameUpdateComponent implements OnInit {
  @Output() dataChange = new EventEmitter<any>();
  fileNameTokens: TokenFormat[] = filenameToken;
  filenamesFormat: FilenameFormat;
  filenamesForm: FormGroup;
  filenames: FilenameFormat[] = [];
  public casing = Casing;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: {
      filenameFormat: FilenameFormat;
      filenames: FilenameFormat[];
    },
    public dialogRef: MatDialogRef<OutputFilenameUpdateComponent>,
    public dialog: MatDialog,
    private location: Location,
    private formBuilder: FormBuilder
  ) {
    console.log(`data: ${JSON.stringify(data, null, 2)}`);
    this.filenamesFormat = data.filenameFormat;
    console.log(data.filenameFormat);
    this.filenamesForm = this.createFilename(this.filenamesFormat);
    this.filenames = data.filenames;

    console.log(this.filenamesForm.controls['tokenFormats'].value);
    if (this.filenamesForm.controls['tokenFormats'].value == null)
      console.log(this.filenamesFormat.tokenFormats);
    this.filenamesForm.controls['tokenFormats'].setValue(
      this.filenamesFormat.tokenFormats
    );
  }

  ngOnInit(): void {}

  createFilename(filename?: FilenameFormat): FormGroup {
    const form = this.formBuilder.group({
      from: [
        filename ? filename.from : 1,
        [Validators.required, Validators.min(1)],
      ],
      to: [filename ? filename.to : undefined, [Validators.min(1)]],
      indexOffset: [filename ? filename.indexOffset : 1, [Validators.required]],
      tokenFormats: [
        filename ? filename.tokenFormats : [],
        [Validators.required],
      ],
      casing: [
        filename ? filename.casing : this.casing.MATCH,
        [Validators.required],
      ],
    });

    console.log('Initial casing value:', form.get('casing')?.value);
    const casing = form
      .get('casing')
      ?.valueChanges.subscribe((value) => console.log(`casing: ${value}`));

    return form;
  }

  getControlFormInArray(controlName: string) {
    return this.filenamesForm.get(controlName)!;
  }

  updateValidtors() {
    let indexOffset = this.filenamesForm.get('indexOffset')!;
    indexOffset.clearValidators();
    indexOffset.addValidators(
      Validators.min(0 - this.filenamesForm.get('indexOffset')!.value)
    );
    indexOffset.addValidators(Validators.required);

    indexOffset.updateValueAndValidity();
  }

  onlyPositiveIntegers(event: KeyboardEvent) {
    return ['-', '+', '.', 'e'].every((el) => event.key !== el);
  }

  onSubmit() {
    const check = this.checkBeforeSubmit();
    if (!check.status) {
      this.openConfirm(check.message);
      return;
    }
    const updatedData = this.filenamesForm.value;
    console.log(`updatedData: ${JSON.stringify(updatedData, null, 2)}`);

    this.dialogRef.close(updatedData);
  }
  onClose() {
    this.dialogRef.close();
  }

  checkBeforeSubmit(): {
    message: string;
    status: boolean;
  } {
    //Validate file itself
    if (this.filenamesForm.invalid) {
      return {
        message: `Invalid inputs!`,
        status: false,
      };
    }

    if (
      this.filenamesForm.get('to')?.value != null &&
      this.filenamesForm.get('from')?.value >
        this.filenamesForm.get('to')?.value
    ) {
      return {
        message: `From input must be less than To`,
        status: false,
      };
    }
    if (this.filenames.length == 0) {
      return {
        message: ``,
        status: true,
      };
    }

    var message = '';
    var overlaps = this.filenames.some((filename) => {
      if (this.filenamesForm.get('from')?.value == filename.from) {
        message = `Filenames overlap!`;
        return true;
      }
      if (this.filenamesForm.get('to')?.value == filename.to) {
        message = `Filenames overlap!`;
        return true;
      }

      if (
        filename.from > this.filenamesForm.get('from')?.value &&
        this.filenamesForm.get('to')?.value == null
      ) {
        message = `Filenames overlap!`;
        return true;
      }
      if (filename.to == null && this.filenamesForm.get('to')?.value == null) {
        message = `Filenames overlap!`;
        return true;
      }
      return false;
    });
    if (overlaps) {
      return {
        message: message,
        status: true,
      };
    }

    return {
      message: ``,
      status: true,
    };
  }

  openConfirm(content: string) {
    this.dialog.open(WarningModalComponent, {
      data: {
        header: 'Warning',
        content: content,
      },
    });
  }
}
