export const environment = {
  production: false,
  apiBase: 'https://api.mmaptcontrol.dev',
  auth: {
    userPoolId: 'ap-southeast-2_OuqOXUpc1',
    clientId: '7aqsk1hnkn8vkg77hi8urmgc10',
    region: 'ap-southeast-2',
    scopes: [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin',
      'dev-api/cloud.api',
      'dev-api/cloud.micro.services.api',
    ],
    cookieDomain: '.mmaptcontrol.dev',
    redirectSignIn: 'https://mmaptcontrol.dev/signin',
    redirectSignOut: 'https://mmaptcontrol.dev/signout',
    responseType: 'code',
    domain: 'mmapt-dev.auth.ap-southeast-2.amazoncognito.com',
  },
  s3Base: 'https://assets.mmaptcontrol.dev/',
  cognitoGroups: {
    superAdmin: 'superAdmins-b7a5b14',
    setupAdmin: 'localSetupClientUsers-dbdb6c3',
    robotUser: 'localClientUsers-e50af2c',
    imageryTeamMember: 'imageryTeamMembers-8ae8dea',
    skyTeamMember: 'skyTeamMembers-a2eb141',
  },
};
