import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
// Material Modules
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
// Services
import { BatchesService } from 'src/app/services/batches.services';
// Dialogs
import { BatchCreateComponent } from '../batch-create/batch-create.component';
// Models
import { IBatch, EStateEntity } from 'src/app/models/batches/batch';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeleteModalComponent } from 'src/app/components/shared/delete-modal/delete-modal.component';
import { BatchUpdateComponent } from '../batch-update/batch-update.component';
import { OrganizationsService } from 'src/app/services/organizations.service';
import { finalize, Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { IPreference, IUserProfile } from 'src/app/models/users/userProfile';
import { Tag } from 'src/app/models/tag';
import { FormControl } from '@angular/forms';
import { TagsComponent } from 'src/app/components/shared/tags/tags.component';
import { TagsService } from 'src/app/services/tags.service';
import { SignalrService } from 'src/app/services/signalr.service';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-list-batches',
  templateUrl: './batch-list.component.html',
  styleUrls: ['./batch-list.component.scss'],
})
export class BatchListComponent implements OnInit, OnDestroy {
  isStaff: boolean = false;
  isAdmin: boolean = false;
  // Set Variables for table and filtering function for search
  updating: boolean = false;
  displayedColumns: string[] = [
    'favorite',
    'jobNumber',
    'name',
    'description',
    'createdAt',
    'Actions',
  ];
  dataSource = new MatTableDataSource<IBatch>();
  // Sort and paginator for the jobs table
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  user!: IUserProfile | null;
  batches: IBatch[] = [];
  filteredBatchtags: Tag[] = [];
  batchTags: Tag[] = [];
  serverErrorMessage: String = '';
  filter: any;
  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toUpperCase();
  }
  private destroy$ = new Subject();
  currentConfigDate!: IPreference;
  isFavorite: boolean = false;
  listBatchFavorite: (string | null)[] = [];
  statusMapping: any = {
    [EStateEntity.PENDING]: 'Pending',
    [EStateEntity.COMPLETE]: 'Complete',
    [EStateEntity.IN_Progress]: 'In Progress',
  };
  // Filters  controls
  includeToggleControl = new FormControl<boolean>(false);
  selectedFiltersControl = new FormControl<string[]>([]);
  // Service Constructor
  constructor(
    private router: Router,
    private batchesService: BatchesService,
    private productsService: ProductsService,
    private organizationsService: OrganizationsService,
    private tagsService: TagsService,
    public dialog: MatDialog,
    public _alert: MatSnackBar,
    private authService: AuthService,
    private signalrService: SignalrService
  ) {}

  // Get jobs from jobs service and sets to table datasource
  ngOnInit() {
    this.isStaff = this.authService.getRole('staff');
    this.isAdmin = this.authService.getRole('admin');
    if (this.isStaff) {
      this.displayedColumns.splice(4, 0, 'organizationName');
      this.displayedColumns.splice(6, 0, 'productCount');
      this.displayedColumns.splice(7, 0, 'states');
    } else {
      this.displayedColumns.splice(5, 0, 'productCount');
      this.displayedColumns.splice(6, 0, 'status');
    }
    this.getListBatches();
    this.getListBatchFavorite();
    this.authService.currentPreference.subscribe((res) => {
      this.currentConfigDate = res;
    });
    this.authService
      .getUserInformation()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (profile: IUserProfile) => {
          this.user = profile;
          this.includeToggleControl.setValue(profile.includeFilteredBatches);
          profile.filteredBatchTagIds.forEach((tagId) => {
            this.selectedFiltersControl.value?.push(tagId);
          });

          console.log(profile.includeFilteredBatches);
        },
      });

    this.signalrService.connectState.subscribe({
      next: (data: boolean) => {
        if (data) {
          this.joinGroups();
        }
      },
    });
    this.subscribeToData();
  }

  batchListGroup: string = '';
  joinGroups() {
    if (!this.isStaff) return;
    this.signalrService
      .joinStaticGroup('JoinBatchesListGroup')
      ?.then((group: string) => (this.batchListGroup = group));
  }

  subscribeToData() {
    if (!this.isStaff) return;
    this.signalrService.batchesData.subscribe({
      next: (data: any) => {
        if (data.id) {
          var index = this.batches.findIndex((p) => p.id == data.id);
          if (index >= 0) {
            this.batches[index] = data;
            this.dataSource.data = this.batches;
          } else {
            this.batches.push(data);
            this.dataSource.data = this.batches;
          }
        }
      },
    });
  }
  // Set current job when pressing manage
  manageBatch(batch: IBatch) {
    console.log('Manage Batch', batch);
    this.batchesService.setCurrentBatch(batch);
    if (this.isStaff) {
      this.organizationsService
        .getOrganization(batch.organizationId!)
        .subscribe((organization) => {
          this.organizationsService.setCurrentOrg(organization);
        });
    }
    this.productsService.resetLatestProductLists();
    this.router.navigate([`batches/${batch.id}/products`]);
  }

  // Set current job when pressing manage
  detailBatch(batch: IBatch) {
    console.log('detailJob', batch);
    this.batchesService.setCurrentBatch(batch);
    this.router.navigateByUrl(`batches/${batch.id}`);
  }

  parseBatchData() {
    if (!this.isStaff) {
      this.batches.map((batch) => {
        if (
          batch.productsApprovedCount == batch.productCount &&
          batch.productCount != 0
        )
          batch.status = EStateEntity.COMPLETE;
        else if (batch.productsApprovedCount > 0)
          batch.status = EStateEntity.IN_Progress;
        else batch.status = EStateEntity.PENDING;
      });
    }
    this.dataSource = new MatTableDataSource<IBatch>(
      this.batches.filter((el) =>
        this.isFavorite ? this.listBatchFavorite.includes(el.id) : true
      )
    );
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getListBatches() {
    this.updating = true;
    this.batches = [];
    this.dataSource = new MatTableDataSource<IBatch>(this.batches);
    this.batchesService
      .listBatches()
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.updating = false;
        })
      )
      .subscribe({
        next: (batches: any) => {
          if (batches) {
            console.log('Batches Found: ', batches);
            this.batches = batches;
            this.parseBatchData();
          }
        },
        error: (error: any) => {},
      });
    this.getBatchTags();
  }

  getBatchTags() {
    this.tagsService
      .listTags('batch')
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (tags) => {
          if (tags) {
            this.batchTags = tags;
            this.filteredBatchtags = tags;
          }
        },
        error: (error: any) => {},
      });
  }

  getListBatchFavorite() {
    this.batchesService
      .listBatches(true)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (batches: IBatch[]) => {
          if (batches) {
            this.listBatchFavorite = batches.map((batch) => batch.id);
            console.log(this.listBatchFavorite);
          }
        },
      });
  }

  // Jobs Create Dialog
  createBatch() {
    const dialogRef = this.dialog.open(BatchCreateComponent);
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  updateBatch(batch: IBatch) {
    const dialogRef = this.dialog.open(BatchUpdateComponent, {
      data: batch,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  deleteBatch(batch: IBatch) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      data: {
        header: `Delete Batch: ${batch.name}`,
        message:
          'Are you sure you want to delete this batch and all subsequent products?',
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.doDeleteBatch(batch);
      }
    });
  }

  doDeleteBatch(batch: IBatch) {
    this.updating = true;
    this.batchesService
      .deleteBatch(batch)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.updating = false;
        })
      )
      .subscribe({
        next: (data) => {
          this._alert.open('Successfully Deleted', 'close', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 2000,
            panelClass: ['success'],
          });
          // Fetch New Products
          this.getListBatches();
        },
        error: (error: any) => {
          this.serverErrorMessage =
            'There was an error delete the batch from the server. Please restart the system and try again';
        },
      });
  }

  addBatchToFavorite(batch: IBatch) {
    this.batchesService.favoriteBatch(batch).subscribe((res) => {
      if (this.listBatchFavorite.includes(batch.id)) {
        this.listBatchFavorite = this.listBatchFavorite.filter(
          (id) => id !== batch.id
        );
      } else {
        this.listBatchFavorite.push(batch.id);
      }
    });
  }

  filterBatchFavorite() {
    this.isFavorite = !this.isFavorite;
    this.parseBatchData();
  }

  public applyFilterBatchTags(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.trim();
    this.filteredBatchtags = this.batchTags.filter((tag) =>
      tag.tag.toLowerCase().includes(filterValue)
    );
    console.log(this.filteredBatchtags);
  }

  applyFilteredBatches() {
    this.batchesService
      .updateUsersBatchTags(
        this.selectedFiltersControl.value!,
        this.includeToggleControl.value!,
        this.user?.id!
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (result) => {
          if (result) {
            this.user!.includeFilteredBatches =
              this.includeToggleControl.value!;
            this.user!.filteredBatchTagIds = this.selectedFiltersControl.value!;
            this.getListBatches();
          }
        },
      });
  }

  addBatchTags(batch: IBatch) {
    const dialogRef = this.dialog.open(TagsComponent, {
      data: {
        entity: batch,
        entityType: 'batch',
        tags: this.batchTags,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      this.getBatchTags();
    });
  }

  setPageSizePreference(event: any) {
    if (event.pageSize != this.currentConfigDate.pageSize) {
      this.currentConfigDate.pageSize = event.pageSize;
      let payload = {
        name: this.user!.name,
        preference: this.currentConfigDate,
      };
      this.authService.updatePreference(payload).subscribe((res) => {
        console.log(res);
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.signalrService.leaveGroup(this.batchListGroup);
    this.signalrService.clearData();
  }
}
