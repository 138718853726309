import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { SelectionModel } from '@angular/cdk/collections';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
// Services
import { ProductsService } from 'src/app/services/products.service';
import { BatchesService } from 'src/app/services/batches.services';
import {
  EnhancementVariantDetail,
  IMasterSuite,
  ISettingsVariant,
} from 'src/app/models/mastersuites/mastersuite';
// Models
import {
  EProgressState,
  IPreDefinedFilter,
  IProduct,
  IProductStates,
} from 'src/app/models/products/product';
import {
  applyEnhancementVaraint,
  applyMasterSuite,
  applySettingsVaraint,
} from 'src/app/models/products/productSettings';
// Dialogs
import { ProductCreateComponent } from '../product-create/product-create.component';
import { ProductCreateUploadComponent } from '../product-create-upload/product-create-upload.component';
import { ProductUpdateComponent } from '../product-update/product-update.component';
// Material Modules
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProductUpdateWarningComponent } from './product-update-warning/product-update-warning.component';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { DeleteModalComponent } from 'src/app/components/shared/delete-modal/delete-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ValidationErrors } from 'src/app/models/validationErrors';
import { MasterSuitesService } from 'src/app/services/mastersuites.service';
import { IBatch } from 'src/app/models/batches/batch';
import { Subject, takeUntil } from 'rxjs';
import { ProductBulkIngestComponent } from './product-bulk-ingest/product-bulk-ingest.component';
import { ProductTransferSettingsComponent } from '../product-transfer-settings/product-transfer-settings.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { cloneDeep } from 'lodash';
import { GenerateOutputsCollectionComponent } from 'src/app/components/shared/generate-outputs-collection/generate-outputs-collection.component';
import { MasterSuiteDialogComponent } from 'src/app/components/shared/master-suite-dialog/master-suite-dialog.component';
import { WarningModalComponent } from 'src/app/components/shared/warning-modal/warning-modal.component';
import { AuthService } from 'src/app/services/auth.service';
import { IPreference, IUserProfile } from 'src/app/models/users/userProfile';
import { DownloadOutputService } from 'src/app/services/download-output.service';
import { SignalrService } from 'src/app/services/signalr.service';
import { environment } from 'src/environments/environment';
import { GenerateOutputsComponent } from 'src/app/components/shared/generate-outputs/generate-outputs.component';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { UrlService } from 'src/app/services/url.service';
//------------------------------------------------------------------------
// New Product Manage (List) Main Component
//------------------------------------------------------------------------
@Component({
  selector: 'app-manage-products',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
})
export class ProductListComponent implements OnInit, OnDestroy {
  public pageLink = '';
  currentOrganizationId = '';
  // Service Constructor
  constructor(
    private batchesService: BatchesService,
    public clipboardService: ClipboardService,
    private productsService: ProductsService,
    private mastersuiteService: MasterSuitesService,
    private downloadOutputService: DownloadOutputService,
    public breakpointObserver: BreakpointObserver,
    public datepipe: DatePipe,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,

    private _alert: MatSnackBar,
    private authService: AuthService,
    private signalrService: SignalrService,
    public urlService: UrlService
  ) {}
  private destroy$ = new Subject();
  view = 'list';
  colPerScreen = 5;
  readonly breakpoint$ = this.breakpointObserver.observe([
    Breakpoints.XLarge,
    Breakpoints.Large,
    Breakpoints.Medium,
    Breakpoints.Small,
  ]);
  girdList: IProduct[] = [];
  user!: IUserProfile | null;
  // MMAPT Jobs table columns and database variable assignment

  dataSource = new MatTableDataSource<IProduct>();
  selection = new SelectionModel<IProduct>(true, []);
  batch!: IBatch;
  productsSubscription: any;
  masterSuiteSubscription: any;
  products: IProduct[] = [];
  serverErrorMessage: String = '';
  errorMessages: string[] = [];
  warning: boolean = false;
  warningMessage: string = '';
  loaded: boolean = false;
  isStaff: boolean = false;
  isAdmin: boolean = false;
  currentConfigDate!: IPreference;
  searchForm: FormControl<any> = new FormControl();
  // Variants Variable assignment, will be dependent on mastersuite
  selectedVariant?: ISettingsVariant;
  variants: ISettingsVariant[] = [];
  enhancementVariants: EnhancementVariantDetail[] = [];
  // Filters
  public progressState = EProgressState;
  filter: IProductStates = {
    receivalState: [],
    masterSuiteState: [],
    ingestionState: [],
    productionState: [],
    productionReviewState: [],
    enhancementState: [],
    reviewState: [],
    outputsState: [],
    dispatchState: [],
  };
  readonly preDefinedFilters: IPreDefinedFilter[] = [
    {
      key: 'PendingStillsProduction',
      value: 'Pending Stills ',
      icon: 'camera_alt',
      results: [],
    },
    {
      key: 'PendingVideoProduction',
      value: 'Pending Videos ',
      icon: 'camera_alt',
      results: [],
    },
    {
      key: 'PendingApprovalsProduction',
      value: 'Pending Approvals',
      icon: 'camera_alt',
      results: [],
    },
    {
      key: 'PendingStillsEnhancement',
      value: 'Pending Stills ',
      icon: 'auto_awesome',
      results: [],
    },
    {
      key: 'PendingVideoEnhancement',
      value: 'Pending Videos ',
      icon: 'auto_awesome',
      results: [],
    },
    {
      key: 'PendingApprovalsExternalStillsEnhancement',
      value: 'Pending Stills ',
      icon: 'reviews',
      results: [],
    },
    {
      key: 'PendingApprovalsExternalVideosEnhancement',
      value: 'Pending Videos ',
      icon: 'reviews',
      results: [],
    },
  ];
  selectedfilter!: any;

  displayedColumns: string[] = [
    'Select',
    'barcode',
    'reference',
    'description',
    'Actions',
  ];

  // Mastersuite Variable assignment and form control with filtered otpions
  _selectedMastersuite: IMasterSuite | null = null;
  get selectedMastersuite() {
    return this._selectedMastersuite;
  }
  set selectedMastersuite(value: IMasterSuite | null) {
    console.log(value);
    if (value != null) {
      if (value.hasOwnProperty('id')) {
        this._selectedMastersuite = value;
        this.updateVariants(value);
        this.updateEnhancementVariantOfMasterSuite(value);
      } else {
        this.variants = [];
      }
    }
  }

  // When a mastersuite is selected, it updates the avaible variants to select
  updateVariants(masterSuite: IMasterSuite) {
    if (this.selectedMastersuite != null) {
      this.masterSuiteSubscription = this.mastersuiteService
        .detailMasterSuite(masterSuite.id!)
        .subscribe(
          (mastersuite) => {
            if (mastersuite) {
              this.variants = mastersuite.settingsVariants!;
              this.selectedVariant = this.variants[0];
              console.log(this.selectedVariant);
            }
          },
          (error: any) => {
            this.serverErrorMessage =
              'There was an error loading the Products from the server. Please restart the system and try again';
            console.log(error.message);
          }
        );
    }
  }

  updateEnhancementVariantOfMasterSuite(masterSuite: IMasterSuite) {
    this.mastersuiteService
      .getAllEnhancementVariantOfMasterSuite(masterSuite.id || '')
      .subscribe({
        next: (res) => {
          if (res) {
            this.enhancementVariants = res;
          }
        },
      });
  }

  masterSuites: IMasterSuite[] = [];
  mastersuiteControl = new FormControl();
  mastersuiteFilteredOptions!: Observable<IMasterSuite[]>;

  ngOnInit(): void {
    this.isStaff = this.authService.getRole('staff');
    this.isAdmin = this.authService.getRole('admin');
    this.authService.currentPreference.subscribe((res) => {
      this.currentConfigDate = res;
    });
    this.user = this.authService.getAuthInformation();
    if (this.isStaff) {
      this.displayedColumns.splice(4, 0, 'masterSuiteName');
      this.displayedColumns.splice(5, 0, 'states');
    } else {
      this.displayedColumns.splice(4, 0, 'status');
    }
    this.breakpoint$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.breakpointChanged();
    });
    if (!this.batch) {
      this.getBatchDetail();
    } else {
      this.initialData();
    }
    this.selection.changed.subscribe((change) => {
      change.added.forEach((product) => {
        this.currentOrganizationId = product.organizationId || '';
      });
    });

    if (
      this.productsService.latestProductsList.batchId ==
      this.route.snapshot.paramMap.get('batchId')
    ) {
      this.dataSource.filter = this.productsService.latestProductsList.search;
      this.searchForm.setValue(this.productsService.latestProductsList.search);
      this.pageIndex = this.productsService.latestProductsList.pageIndex;
      this.pageSize = this.productsService.latestProductsList.pageSize;
    } else {
      this.pageSize = this.currentConfigDate.pageSize!;
      this.productsService.latestProductsList = {
        batchId: '',
        search: '',
        pageIndex: 0,
        pageSize: this.currentConfigDate.pageSize,
        filter: this.filter,
      };
    }
  }

  batchProductsGroup: string = '';
  productsListGroup: string = '';
  joinGroups() {
    if (!this.isStaff) return;
    this.signalrService
      .joinIdGroup('JoinProductsListGroup', this.batch.id!)
      ?.then((group: string) => (this.batchProductsGroup = group));

    this.signalrService
      .joinIdGroup('JoinBatchDetailsGroup', this.batch.id!)
      ?.then((group: string) => (this.productsListGroup = group));
  }

  getBatchDetail() {
    const batchId = this.route.snapshot.paramMap.get('batchId');
    this.batch = this.batchesService.getCurrentBatch();
    if (this.batch == null || batchId != this.batch.id) {
      this.batchesService
        .detailsBatch(batchId!)
        .pipe(takeUntil(this.destroy$))
        .subscribe((batch) => {
          this.batch = batch;
          this.batchesService.setCurrentBatch(batch);
          this.initialData();
        });
    } else {
      this.initialData();
    }
  }

  parseMasterSuiteValue(value: any) {
    const name = typeof value === 'string' ? value : value?.name;
    return name ? this._filter(name as string) : this.masterSuites.slice();
  }

  onFilterPredicate = (data: any, filter: string) => {
    const accumulator = (currentTerm: string, key: string) => {
      return this.nestedFilterCheck(currentTerm, data, key);
    };
    const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
    const transformedFilter = filter.trim().toLowerCase();
    return dataStr.indexOf(transformedFilter) !== -1;
  };

  // Init, gets current job name, then products associated with that job
  initialData(): void {
    if (this.batch != undefined) {
      //Get products for job
      this.productsSubscription = this.productsService
        .listProducts(this.batch.id)
        .subscribe(
          (products: any) => {
            if (products) {
              this.products = this.getProductStates(products);
              this.dataSource = new MatTableDataSource<IProduct>(this.products);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              this.dataSource.filterPredicate = this.onFilterPredicate;
              if (
                this.productsService.latestProductsList.batchId ==
                this.route.snapshot.paramMap.get('batchId')
              ) {
                this.dataSource.filter =
                  this.productsService.latestProductsList.search;
                this.filter = this.productsService.latestProductsList.filter;
                this.filterProducts();
                if (
                  this.productsService.latestProductsList.preDefinedFilter !=
                  undefined
                ) {
                  this.onPreDefinedFilterChange(
                    this.productsService.latestProductsList.preDefinedFilter
                  );
                }
              }
              this.pageLink = this.updatePageLink();
              this.onViewChanged();
            }
          },
          (error: any) => {
            this.serverErrorMessage =
              'There was an error loading the Products from the server. Please restart the system and try again';
            console.log(error.message);
          }
        );
      // Get mastersuites for org;
      if (this.isStaff) {
        this.masterSuiteSubscription = this.mastersuiteService
          .listMasterSuites(this.batch.organizationId)
          .subscribe(
            (mastersuites: any) => {
              if (mastersuites) {
                this.masterSuites = mastersuites;
                this.mastersuiteFilteredOptions =
                  this.mastersuiteControl.valueChanges.pipe(
                    startWith(''),
                    map((value) => this.parseMasterSuiteValue(value))
                  );
              }
            },
            (error: any) => {
              this.serverErrorMessage =
                'There was an error loading the Mastersuites from the server. Please restart the system and try again';
              console.log(error.message);
            }
          );

        this.loaded = true;
      } else {
        this.loaded = true;
      }

      this.signalrService.connectState.subscribe({
        next: (data: boolean) => {
          if (data) {
            this.joinGroups();
          }
        },
      });
      this.subscribeToData();
    }
  }

  getProductStates(products: IProduct[]) {
    return products.map((p: IProduct) => {
      p.states = {
        receivalState:
          p.receivedPoorCount > 0
            ? [EProgressState.ERROR]
            : p.receivedOkayCount > 0
            ? [EProgressState.ACTIVE]
            : p.receivedGoodCount > 0
            ? [EProgressState.COMPLETE]
            : [EProgressState.PENDING],
        masterSuiteState:
          p.masterSuiteId != null && p.settingsVariantId != null
            ? [EProgressState.COMPLETE]
            : p.masterSuiteId != null && p.settingsVariantId == null
            ? [EProgressState.ACTIVE]
            : [EProgressState.PENDING],
        ingestionState:
          p.ingestedTouchCount == p.expectedTouchCount &&
          p.expectedTouchCount != 0
            ? [EProgressState.COMPLETE]
            : p.ingestedTouchCount > 0
            ? [EProgressState.ACTIVE]
            : [EProgressState.PENDING],
        productionState:
          p.shotTouchCount == p.expectedTouchCount && p.expectedTouchCount != 0
            ? [EProgressState.COMPLETE]
            : p.shotTouchCount > 0
            ? [EProgressState.ACTIVE]
            : [EProgressState.PENDING],
        productionReviewState:
          p.approvedShootAssetCount == p.expectedShootAssetCount &&
          p.expectedShootAssetCount != 0
            ? [EProgressState.COMPLETE]
            : p.rejectedShootAssetCount > 0
            ? [EProgressState.ERROR]
            : p.approvedShootAssetCount > 0
            ? [EProgressState.ACTIVE]
            : [EProgressState.PENDING],
        enhancementState:
          p.postAssetCount == p.expectedPostAssetCount &&
          p.expectedPostAssetCount > 0 &&
          p.internallyRejectedPostAssetCount == 0
            ? [EProgressState.COMPLETE]
            : p.postAssetCount > 0 && p.internallyRejectedPostAssetCount == 0
            ? [EProgressState.ACTIVE]
            : p.internallyRejectedPostAssetCount > 0
            ? [EProgressState.ERROR]
            : [EProgressState.PENDING],
        reviewState:
          p.approvedPostAssetCount == p.expectedPostAssetCount &&
          p.expectedPostAssetCount > 0
            ? [EProgressState.COMPLETE]
            : p.rejectedPostAssetCount > 0
            ? [EProgressState.ERROR]
            : p.approvedPostAssetCount > 0
            ? [EProgressState.ACTIVE]
            : [EProgressState.PENDING],
        outputsState: p.releasedInCollection
          ? [EProgressState.COMPLETE]
          : p.approvedOutputAssetCount == p.expectedOutputAssetCount &&
            p.expectedOutputAssetCount > 0 &&
            !p.releasedInCollection
          ? [EProgressState.INFO]
          : p.rejectedOutputAssetCount > 0
          ? [EProgressState.ERROR]
          : p.approvedOutputAssetCount > 0 && p.expectedOutputAssetCount > 0
          ? [EProgressState.ACTIVE]
          : [EProgressState.PENDING],
        dispatchState:
          p.dispatchedCount > 0
            ? [EProgressState.COMPLETE]
            : [EProgressState.PENDING],
      };
      return { ...p, loadedImage: false };
    });
  }

  subscribeToData() {
    if (!this.isStaff) return;
    this.signalrService.batchesData.pipe(takeUntil(this.destroy$)).subscribe({
      next: (data: any) => {
        if (data.id) {
          this.batch = data;
        }
      },
    });

    this.signalrService.productsData.pipe(takeUntil(this.destroy$)).subscribe({
      next: (data: any) => {
        if (data.id) {
          var index = this.products.findIndex((p) => p.id == data.id);
          var selected = this.selection.selected.find((p) => p.id == data.id);
          console.log(index);
          if (index >= 0) {
            var p = this.getProductStates([data])[0];
            this.products[index] = p;
            var dataSourceIndex = this.dataSource.data.findIndex(
              (p) => p.id == data.id
            );
            this.dataSource.data[dataSourceIndex] = p;
            if (selected != null || undefined) {
              this.selection.toggle(selected!);
              this.selection.toggle(this.products[index]);
            }
          } else {
            this.products.push(this.getProductStates([data])[0]);
            this.dataSource.data.push(this.getProductStates([data])[0]);
          }
        }
      },
    });
  }

  displayFn(option: IMasterSuite | null) {
    return option ? option.name : '';
  }
  private _filter(name: string): IMasterSuite[] {
    const filterValue = name.toLowerCase();
    return this.masterSuites.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  // MMAPT jobs table variables, for sorting and paginator
  paginator!: MatPaginator;
  sort!: MatSort;
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  // Applys search filter to the table
  public applyFilter(event: Event) {
    let filterValue = (event.target as HTMLInputElement).value;
    this.productsService.latestProductsList.batchId = this.batch.id;
    this.productsService.latestProductsList.search = filterValue;
    filterValue = filterValue.trim().toUpperCase();

    this.dataSource.filter = filterValue.trim().toUpperCase();
    this.girdList = cloneDeep(
      this.dataSource.data
        .filter((item: any) => {
          const flag = Object.keys(item).some(
            (prop: string) =>
              item[prop] &&
              typeof item[prop] === 'string' &&
              item[prop].toUpperCase().includes(filterValue)
          );
          return flag;
        })
        .sort((a, b) => (b.barcode > a.barcode ? 1 : -1))
    );
  }

  public applyStaticFilter(key: string) {
    this.dataSource.filter = key.trim().toUpperCase();
  }

  public nestedFilterCheck(search: string, data: any, key: string) {
    if (typeof data[key] === 'object') {
      for (const k in data[key]) {
        if (data[key][k] !== null) {
          search = this.nestedFilterCheck(search, data[key], k);
        }
      }
    } else {
      search += data[key];
    }
    return search;
  }

  allEmpty(obj: { [x: string]: any }): boolean {
    return Object.keys(obj).every((k) => obj[k].length == 0);
  }

  pageIndex: number = 0;
  pageSize: number = 0;
  pageNumberChange(event: any) {
    this.productsService.latestProductsList.batchId = this.batch.id;
    this.productsService.latestProductsList.pageIndex = event.pageIndex;
    this.productsService.latestProductsList.pageSize = event.pageSize;

    if (event.pageSize != this.currentConfigDate.pageSize) {
      this.currentConfigDate.pageSize = event.pageSize;
      let payload = {
        name: this.user?.name,
        preference: this.currentConfigDate,
      };
      this.authService.updatePreference(payload).subscribe((res) => {
        console.log(res);
      });
    }
  }
  setAll(obj: { [x: string]: any }, val: any) {
    Object.keys(obj).forEach((k) => (obj[k] = val));
  }

  clearFilters() {
    this.setAll(this.filter, []);
    this.filterProducts();
  }

  filterProductsEvent(event: IProductStates) {
    this.filter = event;
    this.filterProducts();
  }

  filterProducts() {
    console.log(this.filter);
    this.productsService.latestProductsList.batchId = this.batch.id;
    this.productsService.latestProductsList.filter = this.filter;
    this.girdList = cloneDeep(
      (this.dataSource.data = this.products
        .filter((item: IProduct) => {
          var checks = [];
          if (this.filter.receivalState.length > 0) {
            checks.push(
              this.filter.receivalState.includes(item.states!.receivalState[0])
            );
          }
          if (this.filter.masterSuiteState.length > 0) {
            checks.push(
              this.filter.masterSuiteState.includes(
                item.states!.masterSuiteState[0]
              )
            );
          }
          if (this.filter.ingestionState.length > 0) {
            checks.push(
              this.filter.ingestionState.includes(
                item.states!.ingestionState[0]
              )
            );
          }
          if (this.filter.productionState.length > 0) {
            checks.push(
              this.filter.productionState.includes(
                item.states!.productionState[0]
              )
            );
          }
          if (this.filter.productionReviewState.length > 0) {
            checks.push(
              this.filter.productionReviewState.includes(
                item.states!.productionReviewState[0]
              )
            );
          }
          if (this.filter.enhancementState.length > 0) {
            checks.push(
              this.filter.enhancementState.includes(
                item.states!.enhancementState[0]
              )
            );
          }
          if (this.filter.reviewState.length > 0) {
            checks.push(
              this.filter.reviewState.includes(item.states!.reviewState[0])
            );
          }
          if (this.filter.outputsState.length > 0) {
            checks.push(
              this.filter.outputsState.includes(item.states!.outputsState[0])
            );
          }

          if (this.filter.dispatchState.length > 0) {
            checks.push(
              this.filter.dispatchState.includes(item.states!.dispatchState[0])
            );
          }
          return checks.every((x) => x == true);
        })
        .sort((a, b) => (b.barcode > a.barcode ? 1 : -1)))
    );
  }

  onPreDefinedFilterChange(key: any) {
    this.productsService.latestProductsList.batchId = this.batch.id;
    this.productsService.latestProductsList.preDefinedFilter = key;
    this.selectedfilter = key;
    this.setAll(this.filter, []);
    if (this.selectedfilter != undefined) {
      let item = this.preDefinedFilters.find((x) => x.key == key);
      this.preDefinedFilters.splice(this.preDefinedFilters.indexOf(item!), 1);
      this.preDefinedFilters.unshift(item!);
      this.productsSubscription = this.productsService
        .searchByCode(
          undefined,
          1,
          this.products.length,
          undefined,
          undefined,
          this.selectedfilter,
          this.batch.id!
        )
        .subscribe((value) => {
          item!.results = value.results;
          this.girdList = cloneDeep(
            (this.dataSource.data = this.products.filter(
              (o1) => !value.results.every((o2) => o1.id != o2.id)
            ))
          );
        });
    } else {
      this.girdList = cloneDeep((this.dataSource.data = this.products));
    }
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.dataSource.filteredData.forEach((row) => this.selection.select(row));
    }
  }
  masterToggleGridList() {
    if (this.selection.selected.length >= this.girdList.length) {
      this.selection.clear();
    } else {
      this.girdList.forEach((row) => this.selection.select(row));
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected >= numRows;
  }

  selectRow($event: any, row: IProduct) {
    if ($event.srcElement!.nodeName.toLowerCase() == 'td') {
      this.selection.toggle(row);
    }
  }

  // Apply mastersuite/ variant to selected products, if none display warning
  applySettings() {
    if (this.selectedMastersuite == null || '') {
      this.warning = true;
      this.warningMessage = 'Please select mastersuite to apply to products';
      this.mastersuiteControl.markAllAsTouched();
      this.mastersuiteControl.setErrors({ serverErrors: this.warningMessage });
    } else if (this.selection.selected.length == 0) {
      this.warning = true;
      this.warningMessage = 'Please select products to apply settings';
      this.mastersuiteControl.markAllAsTouched();
      this.mastersuiteControl.setErrors({ serverErrors: this.warningMessage });
    } else {
      this.warning = false;
      var settingsWarning = false;
      this.mastersuiteControl.markAsUntouched();
      this.selection.selected.forEach((product) => {
        if (
          product.ingestedTouchCount > 0 &&
          product.masterSuiteId != this.selectedMastersuite!.id
        ) {
          settingsWarning = true;
        }
      });
      if (settingsWarning) {
        const dialogRef = this.dialog.open(ProductUpdateWarningComponent);
        dialogRef.afterClosed().subscribe((result) => {
          console.log('The dialog was closed');
          if (result == true) {
            console.log('Proceed');
            this.applyMultiProducts(this.selection.selected);
            this.selection.clear();
          } else {
            console.log('Cancel');
          }
        });
      } else {
        this.applyMultiProducts(this.selection.selected);
        //after mastersuite has been applied, clear selection.
        this.selection.clear();
      }
    }
  }

  applyMultiProducts(products: IProduct[]) {
    let productIds: string[] = [];
    products.forEach((product) => {
      productIds.push(product.id as string);
    });

    this.applyMasterSuite(productIds, this.selectedMastersuite?.id!).subscribe(
      (res) => {
        products.forEach((product) => {
          const updateProduct = res.find(
            (product2) => product2.id === product.id
          );
          console.log(product);
          product.version =
            updateProduct != null ? updateProduct.version : product.version;
          product.masterSuiteName = this.selectedMastersuite!.name;
          product.masterSuiteId = this.selectedMastersuite!.id;
          if (!this.selectedVariant) {
            product.states!.masterSuiteState! = [EProgressState.ACTIVE];
            product.settingsVariantName = '';
            product.settingsVariantId = null;
          } else {
            product.states!.masterSuiteState! = [EProgressState.COMPLETE];
          }
        });
        if (this.selectedVariant) {
          this.applySettingsVariant(productIds, this.selectedVariant.id!);
        }
      }
    );
  }

  applyMasterSuite(
    productIds: string[],
    masterSuiteId: string
  ): Observable<IProduct[]> {
    const body: applyMasterSuite = {
      masterSuiteId: masterSuiteId,
      productIds: productIds,
      version: 0,
    };
    return this.productsService.applyMasterSuiteOnProducts(body);
  }

  applySettingsVariant(productIds: string[], settingsVariantId: string) {
    const body: applySettingsVaraint = {
      settingsVariantId: settingsVariantId,
      productIds: productIds,
      version: 0,
    };

    this.productsService
      .applySettingsVariantOnProducts(body)
      .subscribe((result) => {
        this.products.map((product) => {
          const updateProduct = result.find(
            (product2) => product2.id === product.id
          );
          if (updateProduct) {
            product.version = updateProduct.version;
            product.settingsVariantName = this.selectedVariant!.name;
            product.settingsVariantId = this.selectedVariant!.id;
          }
        });

        if (this.enhancementVariants.length === 1) {
          this._alert.open(
            'Auto applied available enhancement variant successfully!',
            'close',
            {
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
              duration: 5000,
              panelClass: ['success'],
            }
          );
          this.applyEnhancementVariant(
            productIds,
            this.enhancementVariants[0].id
          );
        }
      });
  }

  applyEnhancementVariant(productIds: string[], enhancementVariantId: string) {
    const body: applyEnhancementVaraint = {
      enhancementVariantId: enhancementVariantId,
      productIds: productIds,
      version: 0,
    };

    this.productsService
      .applyEnhancementVariantOnProducts(body)
      .subscribe((result) => {
        this.products.map((product) => {
          const updateProduct = result.find(
            (product2) => product2.id === product.id
          );
          if (updateProduct) {
            product.masterSuiteEnhancementVariantId = enhancementVariantId;
          }
        });
      });
  }

  trasnferSettings() {
    const dialogRef = this.dialog.open(ProductTransferSettingsComponent, {
      disableClose: true,
    });
    // pass inputs
    let instance = dialogRef.componentInstance;
    instance.products = this.products;
    instance.masterSuites = this.masterSuites;

    dialogRef.afterClosed().subscribe((result) => {
      if (result == true) {
        this.getListProduct();
      }
    });
  }
  openProductUpdateDialog(product: IProduct) {
    const dialogRef = this.dialog.open(ProductUpdateComponent, {
      data: product,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      if (result) {
        product.barcode = result.barcode;
        product.reference = result.reference;
        product.description = result.description;
        product.productLink = result.productLink;
      }
    });
  }

  openProductCreateDialog() {
    const dialogRef = this.dialog.open(ProductCreateComponent);
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      this.getListProduct();
    });
  }

  openProductUploadDialog() {
    const dialogRef = this.dialog.open(ProductCreateUploadComponent);
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      if (result == true) {
        this.getListProduct();
      }
    });
  }

  deleteProduct(product: IProduct) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      data: {
        header: `Delete Product: ${product.barcode} (${product.reference})`,
        message:
          'Are you sure you want to delete this product along with all information?',
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.productsService.deleteProduct(product).subscribe(
          (data) => {
            this._alert.open('Successfully Deleted Product', 'close', {
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
              duration: 2000,
              panelClass: ['success'],
            });
            // Fetch New Products
            this.getListProduct();
          },
          (error: any) => {
            console.log(error);
            error.error.validationErrors.forEach((error: ValidationErrors) => {
              this.errorMessages.push(error.message);
            });
            this._alert.open('Failed To Delete', 'close', {
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
              duration: 2000,
              panelClass: ['error'],
            });
          }
        );
      }
    });
  }

  openProductReceivals(PRODUCT: IProduct) {
    const navigationExtras: NavigationExtras = { state: { product: PRODUCT } };
    this.router.navigate(['product/receivals'], navigationExtras);
  }

  getListProduct() {
    this.productsService
      .listProducts(this.batch.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (products: any) => {
          if (products) {
            this.products = this.getProductStates(products);
            this.dataSource.data = this.products;
            this.selection.clear();
          }
        },
        (error: any) => {
          this.serverErrorMessage =
            'There was an error loading the Products from the server. Please restart the system and try again';
          console.log(error.message);
        }
      );
  }

  bulkProductIngest() {
    const dialogRef = this.dialog.open(ProductBulkIngestComponent, {
      data: this.selection.selected,
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.getListProduct();
      }
    });
  }

  onViewChanged() {
    if (this.view === 'grid') {
      this.girdList = cloneDeep(this.dataSource.filteredData);
      this.girdList = this.girdList.sort((a, b) =>
        b.barcode > a.barcode ? 1 : -1
      );
    }
    this.selection.clear();
  }

  generating: boolean = false;
  generateOutputAssets() {
    this.dialog.open(GenerateOutputsComponent, {
      data: {
        organizationId: this.currentOrganizationId,
        batchId: this.batch.id,
        isBatch: false,
        productIds: this.selection.selected.map((s) => s.id),
      },
    });
  }

  createOutputsCollection() {
    let listProductCanNotDownload: IProduct[] = [];
    let listProductCanDownload: IProduct[] = [];

    let includesReviewActive: boolean = false;
    let includesReleasedProducts: boolean = false;

    this.selection.selected.forEach((s) => {
      if (s.approvedOutputAssetCount != 0 && s.expectedOutputAssetCount > 0) {
        listProductCanDownload.push(s);
      } else {
        includesReviewActive = true;
        listProductCanNotDownload.push(s);
      }

      //check if any have already been released
      if (s.releasedInCollection) {
        includesReleasedProducts = true;
      }
    });
    if (listProductCanDownload.length > 0) {
      if (includesReviewActive || includesReleasedProducts) {
        this.handleOutputsCollectionCreate(
          listProductCanDownload,
          listProductCanNotDownload,
          includesReviewActive,
          includesReleasedProducts
        );
      } else {
        this.dialog.open(GenerateOutputsCollectionComponent, {
          data: {
            organizationId: this.currentOrganizationId,
            batchId: this.batch.id,
            isBatch: false,
            productIds: listProductCanDownload.map((s) => s.id),
          },
        });
      }
    } else {
      this.dialog.open(WarningModalComponent, {
        data: {
          header: 'Warning',
          content: 'There are no approved products. Please check again!',
        },
      });
    }
  }

  openMasterSuite(product: IProduct) {
    this.mastersuiteService
      .detailMasterSuite(product.masterSuiteId!)
      .subscribe((res) => {
        var productIds = [product.id!];
        this.openMasterSuiteEnhancementVariantDialog(productIds, res);
      });
  }

  openMasterSuiteEnhancementVariantDialog(
    productIds: string[],
    masterSuite: IMasterSuite,
    existingEnhancementVariantId?: string
  ) {
    const dialogRef = this.dialog.open(MasterSuiteDialogComponent, {
      data: {
        masterSuiteId: masterSuite.id,
        masterSuiteName: masterSuite.name,
        productIds: productIds,
        masterSuiteEnhancementVariantId: existingEnhancementVariantId,
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res.enhancementVariants?.length === 0) {
        const dialogRef0 = this.dialog.open(WarningModalComponent, {
          data: {
            header: 'Warning',
            content:
              'Master suite has no enhancement variants, would you like to create one?',
            titleButtonOk: 'Yes',
            showBtnNo: true,
          },
        });
        dialogRef0.afterClosed().subscribe((res) => {
          if (res) {
            this.router.navigate(
              [`mastersuites/${masterSuite.id}/update/enhancement-variant`],
              {
                queryParams: {
                  applyAll: true,
                },
              }
            );
          }
        });
      }
      if (res && !res?.enhancementVariants) {
        this.productsService
          .applyEnhancementVariantOnProducts({
            productIds: productIds,
            enhancementVariantId: res.enhancementVariantSelected.id,
            version: 0,
          })
          .subscribe(() => {
            this._alert.open(
              'Selected enhancement variant applied successfully!',
              'close',
              {
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
                duration: 5000,
                panelClass: ['success'],
              }
            );
          });
      }
    });
  }

  handleOutputsCollectionCreate(
    listProductCanDownload: IProduct[],
    listProductCanNotDownload: IProduct[],
    incompleteProducts: boolean,
    includesReleasedProducts: boolean
  ) {
    var barcodes = listProductCanNotDownload.map((x) => x.barcode);
    if (incompleteProducts && includesReleasedProducts) {
      let dialogRef = this.dialog.open(WarningModalComponent, {
        data: {
          header: 'Warning',
          content: `1 or more products have not been included as they are not fully complete: ${barcodes.toString()}`,
          titleButtonOk: 'Continue',
          titleButtonNo: 'Cancel',
          showBtnNo: true,
        },
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res.ok == true) {
          let dialogRef2 = this.dialog.open(WarningModalComponent, {
            data: {
              header: 'Warning',
              content: `1 or more products selected have already been released, continue to re-release these as ammendments.`,
              titleButtonOk: 'Continue',
              titleButtonNo: 'Cancel',
              showBtnNo: true,
            },
          });
          dialogRef2.afterClosed().subscribe((res2) => {
            if (res2.ok == true) {
              this.dialog.open(GenerateOutputsCollectionComponent, {
                data: {
                  organizationId: this.currentOrganizationId,
                  batchId: this.batch.id,
                  isBatch: false,
                  productIds: listProductCanDownload.map((s) => s.id),
                },
              });
            }
          });
        }
      });
    } else if (incompleteProducts) {
      let dialogRef = this.dialog.open(WarningModalComponent, {
        data: {
          header: 'Warning',
          content: `1 or more products have not been included as they are not fully complete: ${barcodes.toString()}`,
          titleButtonOk: 'Continue',
          titleButtonNo: 'Cancel',
          showBtnNo: true,
        },
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res.ok == true) {
          this.dialog.open(GenerateOutputsCollectionComponent, {
            data: {
              organizationId: this.currentOrganizationId,
              batchId: this.batch.id,
              isBatch: false,
              productIds: listProductCanDownload.map((s) => s.id),
            },
          });
        }
      });
    } else if (includesReleasedProducts) {
      let dialogRef = this.dialog.open(WarningModalComponent, {
        data: {
          header: 'Warning',
          content: `1 or more products selected have already been released, continue to re-release these as ammendments.`,
          titleButtonOk: 'Continue',
          titleButtonNo: 'Cancel',
          showBtnNo: true,
        },
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res.ok == true) {
          this.dialog.open(GenerateOutputsCollectionComponent, {
            data: {
              organizationId: this.currentOrganizationId,
              batchId: this.batch.id,
              isBatch: false,
              productIds: listProductCanDownload.map((s) => s.id),
            },
          });
        }
      });
    }
  }

  breakpointChanged() {
    switch (true) {
      case this.breakpointObserver.isMatched(Breakpoints.XLarge):
        this.colPerScreen = 5;
        break;

      case this.breakpointObserver.isMatched(Breakpoints.Large):
        this.colPerScreen = 4;
        break;

      case this.breakpointObserver.isMatched(Breakpoints.Medium):
        this.colPerScreen = 3;
        break;

      case this.breakpointObserver.isMatched(Breakpoints.Small):
        this.colPerScreen = 2;
        break;

      default:
        this.colPerScreen = 1;
        break;
    }
  }

  getLinkImage(image: string, product: IProduct) {
    if (!this.isStaff && !product.releasedInCollection) {
      return 'assets/image-placeholder.jpg';
    }
    return ProductsService.getLinkImage(image);
  }

  updatePageLink() {
    const identifier: string = this.batch.name;
    const pageLink = `${identifier}`;

    return pageLink;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.signalrService.leaveGroup(this.productsListGroup);
    this.signalrService.leaveGroup(this.batchProductsGroup);
    this.signalrService.clearData();
  }
}
