<mat-expansion-panel class="custom-step" expanded="true" hideToggle disabled>
  <div class="custom-step__content">
    <ng-container *ngFor="let item of config; let i = index">
      <div
        *ngIf="i !== 0 && !item.hidden"
        class="custom-step__spacer"
        [ngClass]="{ active: indexActive >= i }"
      ></div>
      <div
        *ngIf="!item.hidden"
        class="custom-step__step"
        [ngClass]="{
          active: activeStep === item.id,
          disabled: indexActive < i,
          'dont-click': item.disable
        }"
        (click)="onChange(item.id)"
      >
        <div
          class="custom-step__step-icon"
          [ngClass]="{ active: activeStep === item.id }"
        >
          <div>{{ i + 1 }}</div>
        </div>
        <h3>{{ item.label }}</h3>
        <button
          *ngIf="
            (item.id == indexActive && item.action && item.id != 0) ||
            (!readonly && item.id == 0)
          "
          mat-icon-button
          (click)="item.action!.change()"
          [matTooltip]="item.tooltip"
          color="success"
          class="mat-elevation-z0"
        >
          <mat-icon>add_circle</mat-icon>
        </button>
      </div>
    </ng-container>
  </div>
</mat-expansion-panel>
