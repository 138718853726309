import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  IEnhancementVariant,
  EnhancementVariantDetail,
} from 'src/app/models/mastersuites/mastersuite';
import { MasterSuitesService } from 'src/app/services/mastersuites.service';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-master-suite-dialog',
  templateUrl: './master-suite-dialog.component.html',
  styleUrls: ['./master-suite-dialog.component.scss'],
})
export class MasterSuiteDialogComponent implements OnInit {
  enhancementVariants!: EnhancementVariantDetail[];
  isLoading = true;

  enhancementVariantSelected?: EnhancementVariantDetail;
  defaultEnhancementVariant?: EnhancementVariantDetail;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dataDialog: any,
    private masterSuitesService: MasterSuitesService,
    private productsService: ProductsService,
    private router: Router,
    public dialogRef: MatDialogRef<MasterSuiteDialogComponent>,
    private _alert: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.masterSuitesService
      .getAllEnhancementVariantOfMasterSuite(this.dataDialog.masterSuiteId)
      .subscribe({
        next: (res) => {
          this.enhancementVariants = res;
          if (res.length === 0) {
            this.dialogRef.close({
              enhancementVariants: [],
            });
            return;
          }
          if (res.length === 1) {
            this.enhancementVariantSelected = res[0];
          }
          if (this.dataDialog.masterSuiteEnhancementVariantId) {
            this.enhancementVariantSelected = this.enhancementVariants.find(
              (en) => en.id === this.dataDialog.masterSuiteEnhancementVariantId
            );
          }
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
          this.dialogRef.close();
        },
      });
  }

  openMasterSuite(enhancementVariant: EnhancementVariantDetail) {
    this.dialogRef.close();
    this.router.navigate([
      `mastersuites/${this.dataDialog.masterSuiteId}/update/enhancement-variant/${enhancementVariant.id}`,
    ]);
  }

  onSave() {
    if (this.dataDialog.isAutoApply) {
      this.dialogRef.close({
        enhancementVariantSelected: this.enhancementVariantSelected,
      });
    } else {
      this.productsService
        .applyEnhancementVariantOnProducts({
          productIds: this.dataDialog.productIds,
          enhancementVariantId: this.enhancementVariantSelected?.id || '',
          version: 0,
        })
        .subscribe({
          next: (res) => {
            this._alert.open(
              `Select Enhancement Variant ${this.enhancementVariantSelected?.name} successfully!`,
              'close',
              {
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
                duration: 2000,
                panelClass: ['success'],
              }
            );
            this.dialogRef.close({
              enhancementVariants: this.enhancementVariants,
              enhancementVariantSelected: this.enhancementVariantSelected,
            });
          },
          error: (e) => {
            this._alert.open(e.message, 'close', {
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
              panelClass: ['error'],
            });
            this.dialogRef.close();
          },
        });
    }
  }

  setDefaultEnhancementVariant() {
    this.enhancementVariantSelected = this.enhancementVariants[0];
    this.dialogRef.close({
      enhancementVariantSelected: this.enhancementVariantSelected,
    });
  }
}
