import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
// Material Modules
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
// import { MatSnackBar } from '@angular/material/snack-bar';;
// Services
import { BatchesService } from 'src/app/services/batches.services';
import { ProductsService } from 'src/app/services/products.service';
// Models
import {
  ProductUpload,
  ProductUploadResult,
} from 'src/app/models/products/productUploadResult';
import { IBatch } from 'src/app/models/batches/batch';
import { Subject, takeUntil } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-product-create-upload',
  templateUrl: './product-create-upload.component.html',
  styleUrls: ['./product-create-upload.component.scss'],
})
export class ProductCreateUploadComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  batch!: IBatch;
  errorState: Boolean = false;
  pendingState: Boolean = false;
  successState: boolean = false;
  errorMessages: string[] = [];
  uploadResults!: ProductUploadResult;
  creating: boolean = false;
  file: File | undefined;
  display: FormControl = new FormControl('', Validators.required);
  invalidDataSource = new MatTableDataSource<ProductUpload>();
  invalidDisplayedColumns: string[] = [
    'barcode',
    'reference',
    'description',
    'validation',
  ];

  uploadedDataSource = new MatTableDataSource<ProductUpload>();
  uploadedDisplayedColumns: string[] = ['barcode', 'reference', 'description'];

  constructor(
    private productsService: ProductsService,
    private batchesService: BatchesService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ProductCreateUploadComponent>
  ) // private _alert: MatSnackBar
  {}
  ngOnInit(): void {
    this.batch = this.batchesService.getCurrentBatch();
  }

  fileChange(event?: FileList) {
    if (event == null) {
      return;
    } else {
      let fileList: FileList = event;
      if (fileList.length < 1) {
        return;
      }
      this.file = fileList[0];
      console.log(this.file);
      this.display.patchValue(`${this.file.name}`);
    }
  }

  createProducts() {
    this.creating = true;
    this.errorMessages = [];
    this.pendingState = true;
    this.productsService
      .createProducts(this.file!, this.batch.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          console.log(data);
          this.invalidDataSource = new MatTableDataSource<ProductUpload>(
            data.invalidProducts
          );
          this.uploadedDataSource = new MatTableDataSource<ProductUpload>(
            data.uploadedProducts
          );
          this.uploadResults = data;
          /* this._alert.open('Successfully Created Products', 'close', {
        horizontalPosition: "center",
        verticalPosition: "bottom",
        //duration: 2000,
        panelClass: ['success']
      }); */
          this.creating = false;
          this.successState = true;
          this.pendingState = false;
        },
        (error) => {
          this.creating = false;
          this.errorState = true;
          console.log(error);
        }
      );
  }

  onClose(state: boolean) {
    this.dialogRef.close(state);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
