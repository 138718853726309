import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  IPreference,
  IUserProfile,
  IUserSetting,
} from '../models/users/userProfile';
import { BehaviorSubject, Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  role: any = {
    staff: false,
    client: false,
  };
  defaultPreference = {
    timezone: '',
    dateTimeFormat: '',
    asAgo: true,
    is24H: true,
    pageSize: 10,
  };
  cognitoGroups: string[] = [];
  authInformation!: IUserProfile;
  preference$ = new BehaviorSubject<IPreference>(this.defaultPreference);
  currentPreference = this.preference$.asObservable();

  avatar$ = new BehaviorSubject('');
  currentAvatar = this.avatar$.asObservable();

  constructor(private http: HttpClient) {}

  setAuthInformation(data: any) {
    this.authInformation = data;
  }

  getAuthInformation() {
    console.log(this.authInformation);
    return this.authInformation;
  }

  setCognitoGroup(cognitoGroups: string[]) {
    this.cognitoGroups = cognitoGroups;
  }

  getCognitoGroup(): string[] {
    return this.cognitoGroups;
  }

  setRole(type: string, value: boolean) {
    this.role[type] = value;
  }

  getRole(type: string): boolean {
    return this.role[type];
  }

  getUserInformation() {
    const url = `${environment.apiBase}/profile`;
    return this.http.get<IUserProfile>(url, httpOptions);
  }

  updatePreference(payload: {
    name?: string;
    preference: IPreference;
  }): Observable<IUserSetting> {
    const url = `${environment.apiBase}/users/me`;
    return this.http.put<IUserSetting>(url, payload, httpOptions);
  }

  setDateFormat(dateFormat: IPreference) {
    this.preference$.next(dateFormat);
  }
  setAvatar(data: string) {
    this.avatar$.next(data);
  }
}
