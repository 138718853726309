import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Subject, takeUntil } from 'rxjs';
import { IMasterSuite } from 'src/app/models/mastersuites/mastersuite';
import { ProductTouch } from 'src/app/models/products/actions/ingest/productTouch';
import { ProductTouchCreate } from 'src/app/models/products/actions/ingest/productTouchCreate';
import { IProduct } from 'src/app/models/products/product';
import { MasterSuitesService } from 'src/app/services/mastersuites.service';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-product-bulk-ingest',
  templateUrl: './product-bulk-ingest.component.html',
  styleUrls: ['./product-bulk-ingest.component.scss'],
})
export class ProductBulkIngestComponent {
  constructor(
    public dialogRef: MatDialogRef<ProductBulkIngestComponent>,
    private productsService: ProductsService,
    private masterSuitesService: MasterSuitesService,
    @Inject(MAT_DIALOG_DATA) private data: IProduct[]
  ) {
    this.initialData(this.data);
  }
  private destroy$ = new Subject();
  message: string = '';
  assumedMasterSuiteId: string | null = null;
  assumedMasterSuite: IMasterSuite | null = null;
  // Selected Products
  selectedProducts: IProduct[] = [];
  // Which Products have been ingested
  availableProductMasters: IProduct[] = [];
  // The assumed master
  productMaster: IProduct | null = null;
  invalidSelection: boolean = false;
  multipleProducts: boolean = false;
  state: string = 'primary';
  displayedColumns: string[] = [
    'barcode',
    'reference',
    'description',
    'actions',
  ];
  dataSource = new MatTableDataSource<IProduct>();
  productTouches: ProductTouchCreate[] = [];

  initialData(selectedProducts: IProduct[]) {
    this.selectedProducts = selectedProducts;
    // if none selected
    if (selectedProducts.length == 0) {
      this.state = '';
      this.invalidSelection = true;
      this.message = 'No Products have been selected';
      return;
    }
    selectedProducts.forEach((product) => {
      // No mastersuite applied, break
      if (product.masterSuiteId == null) {
        this.invalidSelection = true;
        this.state = '';
        this.message =
          'Master Suite is not applied to all selected products. Please Apply Master Suite before bulk ingestion.';
        return;
      }
      //Assume default from first product in selection.
      if (this.assumedMasterSuiteId == null) {
        this.assumedMasterSuiteId = product.masterSuiteId;
      }
      // if master suite is not the same, break
      if (product.masterSuiteId != this.assumedMasterSuiteId) {
        this.invalidSelection = true;
        this.state = '';
        this.message = 'In-consistent Master Suites in selected Products.';
        return;
      }
      if (product.ingestedTouchCount != 0) {
        this.availableProductMasters.push(product);
      }
    });

    if (this.invalidSelection == false) {
      if (this.availableProductMasters.length == 0) {
        this.invalidSelection = true;
        this.state = '';
        this.message =
          'No products from selection have been previously ingested.';
        return;
      } else if (this.availableProductMasters.length == 1) {
        this.detailsProduct(this.availableProductMasters[0].id!);
        this.detailsMasterSuite(this.assumedMasterSuiteId!);
        this.message = `Are you sure you want to copy ingestion details from ${
          this.availableProductMasters[0].reference.length > 0
            ? 'reference: ' + this.availableProductMasters[0].reference
            : 'barcode: ' + this.availableProductMasters[0].barcode
        }`;
        return;
      } else {
        this.dataSource = new MatTableDataSource<IProduct>(
          this.availableProductMasters
        );
        this.invalidSelection = true;
        this.multipleProducts = true;
        this.state = '';
        this.message =
          'Multiple ingested products found, please select product to copy from.';
        return;
      }
    }
  }

  selectProductMaster(product: IProduct) {
    this.productMaster = product;
    //Then get details
    this.detailsProduct(product.id!);
    this.detailsMasterSuite(this.assumedMasterSuiteId!);
    this.invalidSelection = false;
    this.multipleProducts = false;
    this.message = `Are you sure you want to copy ingestion details from reference: ${
      this.productMaster!.reference
    }?`;
    this.state = 'primary';
  }

  detailsProduct(id: string) {
    this.productsService
      .detailsProduct(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (product: IProduct) => {
          if (product) {
            this.productMaster = product;
          }
        },
      });
  }

  detailsMasterSuite(id: string) {
    this.masterSuitesService
      .detailMasterSuite(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (masterSuite: IMasterSuite) => {
          if (masterSuite) {
            this.assumedMasterSuite = masterSuite;
            console.log(this.assumedMasterSuite);
          }
        },
      });
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onYesClick() {
    console.log(this.assumedMasterSuite);
    this.selectedProducts.forEach((product) => {
      // No need to 'ingest' the master product
      if (product.id == this.productMaster?.id) {
        console.log('Skipping Master Product');
      }
      //For each other product, ingest
      else {
        this.productMaster?.productTouches.forEach((productTouch) => {
          // Given they are the same variant, variant touch just needs to change productId.
          this.productTouches.push({
            productId: product.id!,
            masterSuiteTouchId: productTouch.masterSuiteTouchId,
            dimensions: productTouch.dimensions,
            weight: productTouch.weight,
            lens: productTouch.lens,
            version: 0,
          });
        });
      }
    });
    console.log(this.productTouches);
    this.productsService
      .bulkIngestProductTouches(this.productTouches)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (productTouches: ProductTouch[]) => {
          if (productTouches) {
            console.log(productTouches);
            this.dialogRef.close(true);
          }
        },
      });
  }
}
