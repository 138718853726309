<table mat-table [dataSource]="tableProps.datTable" class="mat-elevation-z8">
  <ng-template ngFor let-column [ngForOf]="tableProps.headers" let-i="index">
    <ng-container [matColumnDef]="column.field">
      <th mat-header-cell *matHeaderCellDef>{{ column.header }}</th>

      <td mat-cell *matCellDef="let element" [ngSwitch]="column.columnType">
        <ng-template [ngSwitchCase]="'component'">
          <!--
              *ngComponentOutlet is used to render the component dynamically
              column.body is the component to be rendered
              createInjector is a method to create the injector for the component
            -->
          <ng-container *ngComponentOutlet="column.body"></ng-container>
        </ng-template>
        <ng-template [ngSwitchDefault]>
          {{ element[column.field] }}
        </ng-template>
      </td>
    </ng-container>
  </ng-template>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
