<div class="mater-suite-title">
  <h1 class="title">{{ this.dataDialog.masterSuiteName }}</h1>
  <mat-spinner *ngIf="isLoading" class="spinner"></mat-spinner>
</div>
<mat-dialog-content class="mater-suite-model">
  <mat-radio-group
    aria-label="Select an option"
    [(ngModel)]="enhancementVariantSelected"
    *ngIf="!isLoading"
  >
    <div
      class="mater-suite-item"
      *ngFor="let enhancementVariant of enhancementVariants; index as i"
    >
      <mat-radio-button [value]="enhancementVariant"
        >{{ enhancementVariant.name }}
        {{ i === 0 ? " (default)" : "" }}</mat-radio-button
      >
      <button mat-icon-button (click)="openMasterSuite(enhancementVariant)">
        <mat-icon color="info">info</mat-icon>
      </button>
    </div>
  </mat-radio-group>
</mat-dialog-content>
<mat-dialog-actions align="end" class="action">
  <button mat-stroked-button (click)="setDefaultEnhancementVariant()">
    Default
  </button>
  <button
    mat-stroked-button
    color="success"
    class="save"
    cdkFocusInitial
    (click)="onSave()"
    [disabled]="!enhancementVariantSelected"
  >
    Save
  </button>
</mat-dialog-actions>
