import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-warning-modal',
  templateUrl: './warning-modal.component.html',
  styleUrls: ['./warning-modal.component.scss'],
})
export class WarningModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      header: string;
      content: string;
      titleButtonOk?: string;
      titleButtonNo?: string;
      showBtnNo?: boolean;
    },
    public dialogRef: MatDialogRef<WarningModalComponent>
  ) {}

  onOk() {
    this.dialogRef.close({ ok: true });
  }
}
