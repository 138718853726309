import { Component, Inject } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  Casing,
  CurrentFormatsOptions,
  FilenameFormat,
  OutputFormat,
  TokenFormat,
} from 'src/app/models/organizations/form-setting';
import { OrganizationsService } from 'src/app/services/organizations.service';
import { filenameToken, formats } from '../organization.data';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WarningModalComponent } from 'src/app/components/shared/warning-modal/warning-modal.component';
import { random } from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { IOrganization } from 'src/app/models/organizations/organization';
import { OutputFilenameUpdateComponent } from './output-filename-update/output-filename-update.component';

@Component({
  selector: 'app-output-formats',
  templateUrl: './output-formats.component.html',
  styleUrl: './output-formats.component.scss',
})
export class OutputFormatsComponent {
  defaultFilenameFormat: FilenameFormat[] = [{
    id: null,
    from: 1,
    to: null,
    pattern: "string",
    indexOffset: 0,
    casing: Casing.MATCH,
    tokenFormats: [
      {
        label: '$(reference)',
        value: '{reference}',
      },
      {
        label: '_',
        value: '_',
      },
      {
        label: '$(Asset index)',
        value: '{index}',
      },
      {
        label: '.',
        value: '.',
      },
      {
        label: '$(File extension)',
        value: '{extension}',
      },

    ],
  }];

  fileNameTokens: TokenFormat[] = filenameToken;
  formats = formats;
  isLoading = false;
  isCorrectListAsset = false;
  colorSpaces = [
    {
      label: 'sRGB',
      value: 'sRGB',
    },
    {
      label: 'CMYK',
      value: 'CMYK',
    },
  ];

  bitDepths = [
    {
      label: '8',
      value: 8,
    },
    {
      label: '16',
      value: 16,
    },
  ];

  settingForm = new UntypedFormGroup({
    name: new FormControl<string>('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(20),
      Validators.pattern('^[A-Za-z0-9._.-]*$'),
    ]),
  });

  stillsCheck: FormControl = new FormControl();
  stillsForm = new UntypedFormGroup({
    width: new FormControl<number | null>(null, [
      Validators.min(100),
      Validators.max(8000),
    ]),
    height: new FormControl<number | null>(null, [
      Validators.min(100),
      Validators.max(8000),
    ]),
    useLongEdge: new FormControl<boolean>(false),
    longEdge: new FormControl<number | null>(null, [
      Validators.min(100),
      Validators.max(8000),
    ]),
    shortEdge: new FormControl<number | null>(null, [
      Validators.min(100),
      Validators.max(8000),
    ]),
    dpi: new FormControl<number>(36, [
      Validators.required,
      Validators.min(36),
      Validators.max(1200),
    ]),
    whitespacePercent: new FormControl<number | null>(null, [
      Validators.min(0),
      Validators.max(100),
    ]),
    cropSubject: new FormControl<boolean>(false),
    backgroundColour: new FormControl<string>('#fce4ec'),
    filenameFormats: new FormArray<FormGroup>([]),
  });
  videosCheck: FormControl = new FormControl();
  videosForm = new UntypedFormGroup({
    width: new FormControl<number | null>(null, [
      Validators.min(100),
      Validators.max(8000),
    ]),
    height: new FormControl<number | null>(null, [
      Validators.min(100),
      Validators.max(8000),
    ]),
    filenameFormats: new FormArray<FormGroup>([]),
  });

  currentFormats: { images: any[]; videos: any[] } = {
    images: [],
    videos: [],
  };
  e: any;
  color: any;

  public currentFormatsOptions = CurrentFormatsOptions;

  get fileNameFormatStillsList(): FormArray {
    return this.stillsForm.get('filenameFormats') as FormArray;
  }

  getFileNameFormat(
    filenameFormatArray: FormArray<FormGroup<any>>,
    index: number
  ) {
    return filenameFormatArray.at(index);
  }

  removeItem(
    filenameFormArray: FormArray<FormGroup<any>>,
    index: number
  ): void {
    filenameFormArray.removeAt(index);
  }

  get fileNameFormatVideosList(): FormArray {
    return this.videosForm.get('filenameFormats') as FormArray;
  }
  constructor(
    public dialog: MatDialog,
    private organizationsService: OrganizationsService,
    public _alert: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute
  ) {
    const navigation = this.router.getCurrentNavigation();
    const organizationId: string = this.route.snapshot.paramMap.get('id')!;
    if (navigation == null) {
      this.router.navigateByUrl(`organizations/${organizationId}/update`);
    }

    console.log(navigation);
    const state = navigation!.extras.state as { data: any };
    this.format = state.data;
  }

  isNew: boolean = false;
  format: {
    isNew: boolean;
    data: OutputFormat;
    organization: IOrganization;
  };

  ngOnInit(): void {
    console.log(this.format);
    if (!this.format.isNew) {
      this.stillsCheck.setValue(this.format.data.stillFormat != null);
      this.videosCheck.setValue(this.format.data.videoFormat != null);
      this.settingForm.patchValue({
        ...this.format.data,
      });
      this.stillsForm.patchValue({
        ...this.format.data.stillFormat,
      });
      this.videosForm.patchValue({
        ...this.format.data.videoFormat,
      });
      if (
        this.format.data.stillFormat != null &&
        this.format.data.stillFormat.lossless == true
      ) {
        this.getControl('quality')?.disable();
      }
    }
    this.addFormControl();

    this.stillsForm.valueChanges.subscribe((value) => {
      if (value.format === 'Png' && !value.quality) {
        this.stillsForm.patchValue({ quality: 1 });
      }
    });
  }

  updateFilenames(format: CurrentFormatsOptions, filenameFormat: FilenameFormat[]) {
    const dialogRef = this.dialog.open(OutputFilenameUpdateComponent, {
      data: {filenameFormats: filenameFormat}
    });

    dialogRef.afterClosed().subscribe((result) => {   
      this.currentFormats[format] = result.filenames;      
      
      if (format === CurrentFormatsOptions.IMAGES)
      {
        this.format.data.stillFormat.filenameFormats = result.filenames;
      }
      else
      {
        this.format.data.videoFormat.filenameFormats = result.filenames;
      }
      
      console.log(`updated filenameFormats this.currentFormats[${format}]: ${JSON.stringify(this.currentFormats['images'],null,2)} `);
      this.onUpdateFilenameFormat();
    });
  }
  editFileFormat(filenameFormArray: any, index: number) {
    const formGroup = filenameFormArray.at(index);
    this.fileNameTokens = formGroup;
  }

  saveFileFormat(filenameFormArray: any, index: number) {
    this.checkBeforeSubmit();
  }

  deleteFileFormat(filenameFormArray: any, index: number) {
    filenameFormArray.removeAt(index);
  }

  addFormControl() {
    this.stillsForm.addControl(
      'format',
      new FormControl<string>(
        this.format.data != null && this.format.data.stillFormat != null
          ? this.format.data.stillFormat.format
          : this.formats['image'][0].value,
        Validators.required
      )
    );
    this.videosForm.addControl(
      'format',
      new FormControl<string>(
        this.format.data != null && this.format.data.videoFormat != null
          ? this.format.data.videoFormat.format
          : this.formats['video'][0].value,
        Validators.required
      )
    );

    this.stillsForm.addControl(
      'colorSpace',
      new FormControl(
        this.format.data != null && this.format.data.stillFormat != null
          ? this.format.data.stillFormat.colorSpace
          : this.colorSpaces[0].value,
        [Validators.required]
      )
    );
    this.stillsForm.addControl(
      'quality',
      new FormControl(
        this.format.data != null && this.format.data.stillFormat != null
          ? this.format.data.stillFormat.quality
          : 1,
        [Validators.required, Validators.min(1), Validators.max(100)]
      )
    );
    this.stillsForm.addControl(
      'bitDepth',
      new FormControl(
        this.format.data != null && this.format.data.stillFormat != null
          ? this.format.data.stillFormat.bitDepth
          : this.bitDepths[0].value,
        [Validators.required]
      )
    );
    this.stillsForm.addControl(
      'lossless',
      new FormControl(
        this.format.data != null && this.format.data.stillFormat != null
          ? this.format.data.stillFormat.lossless
          : false,
        [Validators.required]
      )
    );

    if (this.format.isNew) {
      this.addNewFormat(true);
      this.addNewFormat(false);
    } else {
      if (this.format.data.stillFormat != null) {
        let index = 0;
        for (const fnFormat of this.format.data.stillFormat.filenameFormats) {
          fnFormat.id = index;
          console.log(fnFormat);
          this.currentFormats['images'].push(fnFormat);
          index++;
        }
      }
      if (this.format.data.videoFormat != null) {
        let index = 0;
        for (const fnFormat of this.format.data.videoFormat.filenameFormats) {
          fnFormat.id = index;
          console.log(fnFormat);
          this.currentFormats['videos'].push(fnFormat);
          index++;
        }
      }

      this.isCorrectListAsset = this.checkBeforeSubmit().status;
    }
  }

  getControl(controlName: string) {
    return this.stillsForm.get(controlName);
  }

  getControlFormInArray(controlName: string, form: any) {
    return form.get(controlName);
  }

  getFileNameFormatGroup(data?: any): FormGroup {
    if (data) {
      console.log(data);
      return new FormGroup({
        id: new FormControl(data.id),
        from: new FormControl(data.from, [
          Validators.required,
          Validators.min(1),
        ]),
        to: new FormControl(data.to, [Validators.min(1)]),
        indexOffset: new FormControl(data.indexOffset, [
          Validators.min(0 - data.from),
        ]),
        tokenFormats: new FormControl(
          [...data.tokenFormats],
          [Validators.required]
        ),
        isEdit: new FormControl(true),
        casing: new FormControl(data.casing, [Validators.required]),
      });
    }
    return new FormGroup({
      id: new FormControl(random()),
      from: new FormControl(1, [Validators.required, Validators.min(1)]),
      to: new FormControl(undefined, [Validators.min(1)]),
      indexOffset: new FormControl(0, [Validators.required]),
      tokenFormats: new FormControl([], [Validators.required]),
      isEdit: new FormControl(false),
      casing: new FormControl(Casing.MATCH, [Validators.required]),
    });
  }

  toggleQuality() {
    if (this.getControl('lossless')?.value == true) {
      this.getControl('quality')?.disable();
      this.getControl('quality')?.setValue(100);
    } else {
      this.getControl('quality')?.enable();
    }
  }

  addNewFormat(stills: boolean) {
    let newFileName: FormGroup<any> = this.getFileNameFormatGroup();
    this.getControlFormInArray('indexOffset', newFileName).addValidators(
      Validators.min(0 - this.getControlFormInArray('from', newFileName).value)
    );
    stills
      ? this.fileNameFormatStillsList.push(newFileName)
      : this.fileNameFormatVideosList.push(newFileName);
  }

  updateValidtors(data: any) {
    let indexOffset: FormControl<any> = this.getControlFormInArray(
      'indexOffset',
      data
    );
    indexOffset.clearValidators();

    indexOffset.addValidators(
      Validators.min(0 - this.getControlFormInArray('from', data).value)
    );
    indexOffset.addValidators(Validators.required);

    indexOffset.updateValueAndValidity();
  }

  private prepareData() {
    const check = this.checkBeforeSubmit();
    if (!check.status) {
      this.openConfirm(check.message);
      return;
    }
    this.isLoading = true;
    const { filenameFormats, stillFormat, videoFormat, ...dataSubmit } =
      this.settingForm.value;
    if (this.stillsCheck.value) {
      const { quality, colorSpace, bitDepth, lossless, ...stillsData } =
        this.stillsForm.value;
      if (stillsData.format === 'Jpg') {
        stillsData.quality = quality;
        stillsData.colorSpace = colorSpace;
      }
      if (stillsData.format === 'WebP') {
        stillsData.quality = quality;
        if (this.getControl('quality')?.disabled) {
          stillsData.quality = 100;
        }
        stillsData.lossless = lossless;
      }
      if (stillsData.format === 'Png' || 'Tiff') {
        stillsData.bitDepth = bitDepth;
      }

      dataSubmit.stillFormat = stillsData;
      dataSubmit.stillFormat.filenameFormats = this.currentFormats[
        'images'
      ].map((c) => {
        let pattern = '';
        for (const token of c.tokenFormats) {
          pattern += token.value;
        }
        const data: any = {
          from: c.from,
          pattern,
          indexOffset: c.indexOffset,
          casing: c.casing,
        };
        if (c.to) {
          data.to = c.to;
        }
        return data;
      });
      return dataSubmit;
    }
    if (this.videosCheck.value) {
      dataSubmit.videoFormat = this.videosForm.value;
      dataSubmit.videoFormat.filenameFormats = this.currentFormats[
        'videos'
      ].map((c) => {
        let pattern = '';
        for (const token of c.tokenFormats) {
          pattern += token.value;
        }
        const data: any = {
          from: c.from,
          pattern,
          indexOffset: c.indexOffset,
          casing: c.casing,
        };
        if (c.to) {
          data.to = c.to;
        }
        return data;
      });
    }
  }

  onUpdateFilenameFormat() {
    const dataSubmit = this.prepareData();
    console.log(dataSubmit);
    if (this.format.data) {
      this.organizationsService
        .updateOutputFormat(
          this.format.organization.id || '',
          this.format.data.id,
          dataSubmit
        )
        .subscribe({
          next: (res) => {
            this._alert.open(
              `Successfully ${
                this.format.isNew ? 'Created' : 'Updated'
              } Output Format`,
              'close',
              {
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
                duration: 2000,
                panelClass: ['success'],
              }
            );
            this.isLoading = false;
          },
          error: (e) => {
            this.sendAlert(
              e?.error?.validationErrors[0]?.message,
              3000,
              'error'
            );
            this.isLoading = false;
          },
        });
    }
  }

  onSubmit() {

    const dataSubmit = this.prepareData();
    console.log(dataSubmit);
    if (this.format.data) {
      this.organizationsService
        .updateOutputFormat(
          this.format.organization.id || '',
          this.format.data.id,
          dataSubmit
        )
        .subscribe({
          next: (res) => {
            this._alert.open(
              `Successfully ${
                this.format.isNew ? 'Created' : 'Updated'
              } Output Format`,
              'close',
              {
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
                duration: 2000,
                panelClass: ['success'],
              }
            );
            this.router.navigateByUrl(
              `organizations/${this.format.organization.id}/update`
            );
            this.isLoading = false;
          },
          error: (e) => {
            this.sendAlert(
              e?.error?.validationErrors[0]?.message,
              3000,
              'error'
            );
            this.isLoading = false;
          },
        });
    } else {
      this.organizationsService
        .createOutput(dataSubmit, this.format.organization.id || '')
        .subscribe({
          next: (res) => {
            this.router.navigateByUrl(
              `organizations/${this.format.organization.id}/update`
            );
            this.isLoading = false;
          },
          error: (e) => {
            this.sendAlert(
              e?.error?.validationErrors[0]?.message,
              3000,
              'error'
            );
            this.isLoading = false;
          },
        });
    }
  }
  onClose() {
    this.router.navigateByUrl(
      `organizations/${this.format.organization.id}/update`
    );
  }

  sendAlert(message: string, duration: number, alertType: string): void {
    this._alert.open(message, 'close', {
      duration: duration,
      horizontalPosition: 'center',
      panelClass: [alertType],
    });
  }

  getFileNameToken(token: string) {
    return this.fileNameTokens.filter((f) => f.value === token)[0].label;
  }

  removeToken(fn: string, fileNameGroup: any) {
    const tmpTokenFormats = fileNameGroup.value.tokenFormats;
    const index = tmpTokenFormats.indexOf(fn);

    if (index >= 0) {
      tmpTokenFormats.splice(index, 1);
    }
    fileNameGroup.patchValue({ tokenFormats: tmpTokenFormats });
  }

  editConfigRanges(current: any, stills: boolean) {
    const type = stills ? 'images' : 'videos';
    const tmp = stills
      ? this.stillsForm.value.filenameFormats
      : this.videosForm.value.filenameFormats;
    const index = tmp.findIndex((t: any) => t.isEdit === true);
    if (index === -1) {
      stills
        ? this.fileNameFormatStillsList.push(
            this.getFileNameFormatGroup(current)
          )
        : this.fileNameFormatVideosList.push(
            this.getFileNameFormatGroup(current)
          );
    } else {
      tmp[index] = {
        ...tmp[index],
        ...current,
      };
      stills
        ? this.stillsForm.patchValue({ filenameFormats: [...tmp] })
        : this.videosForm.patchValue({ filenameFormats: [...tmp] });
    }
    this.currentFormats[type].sort((a, b) => {
      if (a.from < b.from) {
        return -1;
      }
      if (a.from > b.from) {
        return 1;
      }
      return 0;
    });
    this.isCorrectListAsset = this.checkBeforeSubmit().status;
  }

  addFormat(index: number, stills: boolean) {
    if (!this.checkAddFormatToken(index, stills)) return;
    const type = stills ? 'images' : 'videos';
    const { isEdit, ...tmp } = stills
      ? this.fileNameFormatStillsList.value[index]
      : this.fileNameFormatVideosList.value[index];

    console.log(isEdit);
    if (isEdit) {
      console.log(tmp);
      const index = this.currentFormats[type].findIndex(
        (t: any) => t.id === tmp.id
      );
      console.log(index);
      this.currentFormats[type][index] = tmp;
    } else {
      this.currentFormats[type].push(tmp);
    }
    this.currentFormats[type].sort((a, b) => {
      if (a.from < b.from) {
        return -1;
      }
      if (a.from > b.from) {
        return 1;
      }
      return 0;
    });
    stills
      ? this.fileNameFormatStillsList.removeAt(index)
      : this.fileNameFormatVideosList.removeAt(index);
    this.isCorrectListAsset = this.checkBeforeSubmit().status;
  }

  onRemoveForm(index: number, stills: boolean) {
    stills
      ? this.fileNameFormatStillsList.removeAt(index)
      : this.fileNameFormatVideosList.removeAt(index);
  }

  deleteConfigToken(index: number, stills: boolean) {
    const type = stills ? 'images' : 'videos';
    const tmp = this.currentFormats[type][index];
    const indexForm = stills
      ? this.fileNameFormatStillsList.value.findIndex(
          (t: any) => t.id === tmp.id
        )
      : this.fileNameFormatVideosList.value.findIndex(
          (t: any) => t.id === tmp.id
        );
    if (indexForm !== -1) {
      stills
        ? this.fileNameFormatStillsList.removeAt(indexForm)
        : this.fileNameFormatVideosList.removeAt(indexForm);
    }
    this.currentFormats[type].splice(index, 1);
    this.isCorrectListAsset = this.checkBeforeSubmit().status;
  }

  checkAddFormatToken(index: number, stills: boolean): boolean {
    const { isEdit, ...tmp } = stills
      ? this.fileNameFormatStillsList.value[index]
      : this.fileNameFormatVideosList.value[index];
    if (tmp.to && tmp.to < tmp.from) {
      this.openConfirm('RangeTo cannot be smaller than Range From');
      return false;
    }
    if (tmp.tokenFormats.length === 0) {
      this.openConfirm('Please choose at least 1 token');
      return false;
    }
    let pattern = '';
    for (const token of tmp.tokenFormats) {
      pattern += token.value;
    }
    if (
      !pattern.includes('{index}') &&
      !pattern.includes('{alphabetical-index}') &&
      tmp.from !== tmp.to
    ) {
      this.openConfirm(
        'The index or alphabetical index token is required when targeting a range of assets'
      );
      return false;
    }
    if (!pattern.includes('.{extension}')) {
      this.openConfirm('.{extension} is  required');
      return false;
    }
    if (pattern.split('.{extension}')[1] !== '') {
      this.openConfirm('.{extension} should be at the end');
      return false;
    }
    return true;
  }

  checkBeforeSubmit(): {
    message: string;
    status: boolean;
  } {
    if (this.stillsCheck.value && this.currentFormats['images'].length === 0) {
      return {
        message: 'Please set up at least 1 filename format for stills!',
        status: false,
      };
    }
    if (this.videosCheck.value && this.currentFormats['videos'].length === 0) {
      return {
        message: 'Please set up at least 1 filename format for videos!',
        status: false,
      };
    }
    if (
      this.stillsForm.get(['width'])?.value === null &&
      this.stillsForm.get(['height'])?.value === null &&
      this.stillsCheck.value
    ) {
      return {
        message: 'Please set a specific width or height for stills',
        status: false,
      };
    }
    if (
      this.videosForm.get(['width'])?.value === null &&
      this.videosForm.get(['height'])?.value === null &&
      this.videosCheck.value
    ) {
      return {
        message: 'Please set a specific width or height for videos',
        status: false,
      };
    }

    if (
      this.stillsForm.get(['height'])?.value === null &&
      !this.stillsForm.get(['cropSubject'])?.value
    ) {
      return {
        message: 'Both Height and Width required if subject is not cropped',
        status: false,
      };
    }

    if (
      this.stillsForm.get(['width'])?.value === null &&
      !this.stillsForm.get(['cropSubject'])?.value
    ) {
      return {
        message: 'Both Height and Width required if subject is not cropped',
        status: false,
      };
    }

    for (let i = 0; i < this.currentFormats['images'].length; i++) {
      if (
        (!this.currentFormats['images'][i].to &&
          this.currentFormats['images'][i + 1]) ||
        this.currentFormats['images'][i].to >=
          this.currentFormats['images'][i + 1]?.from
      ) {
        return {
          message: 'The ranges of assets cannot be overlapped!',
          status: false,
        };
      }
      if (
        this.currentFormats['images'][i].to &&
        this.currentFormats['images'][i].to <
          this.currentFormats['images'][i + 1]?.from - 1
      ) {
        return {
          message: `The range from asset ${
            this.currentFormats['images'][i].to + 1
          } to ${this.currentFormats['images'][i + 1].from - 1} is required`,
          status: false,
        };
      }
      if (
        i === this.currentFormats['images'].length - 1 &&
        this.currentFormats['images'][i].to
      ) {
        return {
          message: `The range from asset ${
            this.currentFormats['images'][i].to + 1
          }  is required`,
          status: false,
        };
      }
      if (
        this.currentFormats['images'][i].from ===
        this.currentFormats['images'][i].to
      ) {
        let pattern = '';
        for (const token of this.currentFormats['images'][i].tokenFormats) {
          pattern += token.value;
        }
        if (pattern.includes('{index}')) {
          continue;
        }
        for (let j = i + 1; j < this.currentFormats['images'].length; j++) {
          if (
            this.currentFormats['images'][j].from ===
            this.currentFormats['images'][j].to
          ) {
            let patternJ = '';
            for (const token of this.currentFormats['images'][i].tokenFormats) {
              patternJ += token.value;
            }
            if (patternJ.includes('{index}')) {
              continue;
            }
            if (pattern === patternJ) {
              return {
                message: `Duplicated patterns detected: ${pattern}`,
                status: false,
              };
            }
          }
        }
      }
    }
    for (let i = 0; i < this.currentFormats['videos'].length; i++) {
      if (
        (!this.currentFormats['videos'][i].to &&
          this.currentFormats['videos'][i + 1]) ||
        this.currentFormats['videos'][i].to >=
          this.currentFormats['videos'][i + 1]?.from
      ) {
        return {
          message: 'The ranges of video assets cannot be overlapped!',
          status: false,
        };
      }
      if (
        this.currentFormats['videos'][i].to &&
        this.currentFormats['videos'][i].to <
          this.currentFormats['videos'][i + 1]?.from - 1
      ) {
        return {
          message: `The range from video assets ${
            this.currentFormats['videos'][i].to + 1
          } to ${this.currentFormats['videos'][i + 1].from - 1} is required`,
          status: false,
        };
      }
      if (
        i === this.currentFormats['videos'].length - 1 &&
        this.currentFormats['videos'][i].to
      ) {
        return {
          message: `The range from video assets ${
            this.currentFormats['videos'][i].to + 1
          }  is required`,
          status: false,
        };
      }
      if (
        this.currentFormats['videos'][i].from ===
        this.currentFormats['videos'][i].to
      ) {
        let pattern = '';
        for (const token of this.currentFormats['videos'][i].tokenFormats) {
          pattern += token.value;
        }
        if (pattern.includes('{index}')) {
          continue;
        }
        for (let j = i + 1; j < this.currentFormats['videos'].length; j++) {
          if (
            this.currentFormats['videos'][j].from ===
            this.currentFormats['videos'][j].to
          ) {
            let patternJ = '';
            for (const token of this.currentFormats['videos'][i].tokenFormats) {
              patternJ += token.value;
            }
            if (patternJ.includes('{index}')) {
              continue;
            }
            if (pattern === patternJ) {
              return {
                message: `Duplicated patterns detected: ${pattern}`,
                status: false,
              };
            }
          }
        }
      }
    }
    return {
      message: ``,
      status: true,
    };
  }

  openConfirm(content: string) {
    this.dialog.open(WarningModalComponent, {
      data: {
        header: 'Warning',
        content: content,
      },
    });
  }

  onlyPositiveIntegers(event: KeyboardEvent) {
    return ['-', '+', '.', 'e'].every((el) => event.key !== el);
  }

  test(event: any) {
    console.log(event);
    this.getControl('backgroundColour')?.setValue(event.target.value);
  }
}
