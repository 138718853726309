import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject, takeUntil } from 'rxjs';
import { IOutputsCollection } from 'src/app/models/outputsCollection';
import { IUser } from 'src/app/models/users/user';
import { IPreference, IUserProfile } from 'src/app/models/users/userProfile';
import { AuthService } from 'src/app/services/auth.service';
import { OutputsCollectionsService } from 'src/app/services/outputs-collections.service';
import { DeleteModalComponent } from '../../../shared/delete-modal/delete-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-output-collections-list',
  templateUrl: './output-collections-list.component.html',
  styleUrl: './output-collections-list.component.scss',
})
export class OutputCollectionsListComponent implements OnInit {
  private destroy$ = new Subject();
  constructor(
    private outputsCollectionsService: OutputsCollectionsService,
    private authService: AuthService,
    public dialog: MatDialog,
    public alert: MatSnackBar
  ) {}

  isStaff: boolean = false;
  isAdmin: boolean = false;
  user!: IUserProfile;
  currentConfigDate!: IPreference;
  collections: IOutputsCollection[] = [];
  displayedColumns: string[] = [
    'jobNumber',
    'jobName',
    'description',
    'releasedAt',
    'status',
    'Actions',
  ];
  dataSource = new MatTableDataSource<IOutputsCollection>();
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;

  ngOnInit() {
    this.isStaff = this.authService.getRole('staff');
    this.isAdmin = this.authService.getRole('admin');
    if (this.isStaff) {
      this.displayedColumns.splice(0, 0, 'organizationName');
    }
    this.listOutputsCollection();
    this.authService.currentPreference.subscribe((res) => {
      this.currentConfigDate = res;
    });
    this.authService
      .getUserInformation()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (profile: IUserProfile) => {
          this.user = profile;
        },
      });
  }

  listOutputsCollection() {
    this.outputsCollectionsService.listOutputsCollection().subscribe((res) => {
      this.collections = res;
      this.dataSource = new MatTableDataSource<IOutputsCollection>(
        this.collections
      );
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  downloadCollection(downloadUrl: string, id: string) {
    this.outputsCollectionsService
      .downloadOutputsCollection(id)
      .subscribe((res) => {
        console.log('Downloading');
      });
    window.open(downloadUrl, '_blank');
  }

  deleteOutputCollection(collection: IOutputsCollection) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      data: {
        header: `Delete Collection`,
        message: 'Are you sure you want to delete this collection?',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.outputsCollectionsService
            .deleteOutputsCollection(collection.id!)
            .subscribe((res) => {
              this.alert.open('Successfully Deleted', 'close', {
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
                duration: 2000,
                panelClass: ['success'],
              });
              this.listOutputsCollection();
            });
        }
      });
  }

  setPageSizePreference(event: any) {
    if (event.pageSize != this.currentConfigDate.pageSize) {
      this.currentConfigDate.pageSize = event.pageSize;
      let payload = {
        name: this.user!.name,
        preference: this.currentConfigDate,
      };
      this.authService.updatePreference(payload).subscribe((res) => {
        console.log(res);
      });
    }
  }
}
