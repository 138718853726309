import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsService } from 'src/app/services/products.service';
import { Observable, Subject, fromEvent, map, takeUntil } from 'rxjs';
import {
  EAssetType,
  IAsset,
  IAssetDetailResponse,
} from 'src/app/models/products/asset';
import { AuthService } from 'src/app/services/auth.service';
import { AssetsNavigateComponent } from './assets-navigate/assets-navigate.component';
import { cloneDeep, isEmpty } from 'lodash';
import { environment } from 'src/environments/environment';
import {
  IBoundingBox,
  IBoundingBoxPercentage,
  IDimensions,
} from 'src/app/models/boundingBox';
import { IComment } from 'src/app/models/comment';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { UrlService } from 'src/app/services/url.service';
import { AssetsService } from 'src/app/services/assets.service';
import { ValidationErrors } from 'src/app/models/validationErrors';
import { AlertService } from 'src/app/services/alert.service';
import { DeleteModalComponent } from 'src/app/components/shared/delete-modal/delete-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { MetaData } from 'src/app/models/assetMetaData';

@Component({
  selector: 'app-review-assets',
  templateUrl: './review-assets.component.html',
  styleUrls: ['./review-assets.component.scss'],
})
export class ReviewAssetsComponent implements OnInit, OnDestroy {
  public pageLink = '';
  @ViewChild('assetNavigate') assetNavigate!: AssetsNavigateComponent;
  @ViewChild('asset') asset!: ElementRef;
  @ViewChild('assetContainer') assetContainer!: ElementRef;
  private destroy$ = new Subject();
  isCompare = false;
  activeTab = 0;

  productId = '';
  assetId = '';
  masterSuiteTouchAssetId = '';
  outputFormatId = '';
  assetType = 'output-asset';
  versionNumber: number = 0;
  currentAsset = '';
  compareAsset = '';
  assetData: any = [];
  allAssetData: any = [];
  allowExternalComment = false;
  showAction = false;
  isExternal = false;
  label = '';
  title = '';
  isDownloadFileLoading = false;
  productTouchAssetId = '';
  activeAsset: any = {};
  selectIndex?: number;
  fullscreen: boolean = false;
  drawing: boolean = false;
  transparentBackground: boolean = false;
  propertiesView: boolean = false;
  isStaff: boolean;
  loading: boolean = true;
  initialized: boolean = false;
  public hideVideoSubjectBoundingBox: boolean = true;
  public subjectBoundingBoxPercentage: IBoundingBoxPercentage = {
    top: '',
    left: '',
    width: '',
    height: '',
  };
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    event: KeyboardEvent
  ) {
    this.fullscreen = false;
  }
  constructor(
    private productsService: ProductsService,
    private assetsService: AssetsService,
    private alertService: AlertService,
    private authService: AuthService,
    public clipboardService: ClipboardService,
    public urlService: UrlService,
    private dialog: MatDialog,
    private router: Router,

    private route: ActivatedRoute
  ) {
    this.isStaff = this.authService.getRole('staff');
    this.route.params.subscribe((routeParams) => {
      this.assetType = this.route.snapshot.paramMap.get('assetType') as string;
    });
    this.route.queryParams.subscribe((params) => {
      this.versionNumber = this.route.snapshot.queryParamMap.get(
        'version'
      ) as unknown as number;
    });
  }

  filteredAssets() {
    this.assetData = cloneDeep(this.allAssetData);
    if (!isEmpty(this.assetData[this.activeTab])) {
      var idx = this.assetData.findIndex(
        (x: IAsset) => x.index == this.versionNumber
      );
      if (idx > 0) {
        this.activeTab = idx;
      }
      const { reviewUri, internalReviewState } = this.assetData[this.activeTab];
      this.currentAsset = reviewUri;
      console.log(this.currentAsset);
      this.showAction = !this.isExternal || !!internalReviewState;
      if (
        this.assetType === EAssetType.POST_ASSETS ||
        this.assetType === EAssetType.OUTPUT_ASSETS
      ) {
        this.productTouchAssetId =
          this.assetData[this.activeTab].productTouchAssetId;
      } else {
        this.productTouchAssetId = this.assetId;
      }
    }
  }

  ngOnInit(): void {
    this.productId = this.route.snapshot.paramMap.get('productId') as string;
    this.assetType = this.route.snapshot.paramMap.get('assetType') as string;
    this.isExternal = this.authService.getRole('client');
  }
  @ViewChild('layout') canvasRef: any;
  initialData(): void {
    this.loading = true;
    this.isCompare = false;
    this.activeTab = 0;
    this.assetId = this.route.snapshot.paramMap.get('assetId') as string;
    if (this.assetType == EAssetType.POST_ASSETS) {
      this.masterSuiteTouchAssetId = this.route.snapshot.paramMap.get(
        'masterSuiteTouchAssetId'
      ) as string;
    }
    if (this.assetType == EAssetType.OUTPUT_ASSETS) {
      this.masterSuiteTouchAssetId = this.route.snapshot.paramMap.get(
        'masterSuiteTouchAssetId'
      ) as string;
      this.outputFormatId = this.route.snapshot.paramMap.get(
        'outputFormatId'
      ) as string;
    }
    this.getAssetDetails();
    this.allowExternalComment =
      this.authService.getRole('staff') &&
      this.assetType !== EAssetType.SHOOT_ASSETS;
  }

  getAssetDetails() {
    this.productsService[
      this.assetType === EAssetType.SHOOT_ASSETS
        ? 'getShootAssetDetails'
        : this.assetType === EAssetType.POST_ASSETS
        ? 'getPostAssetDetails'
        : 'getOutputAssetDetails'
    ](this.assetId, this.masterSuiteTouchAssetId, this.outputFormatId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: IAssetDetailResponse) => {
          this.allAssetData =
            data[
              this.assetType === EAssetType.SHOOT_ASSETS
                ? 'shootAssets'
                : this.assetType === EAssetType.POST_ASSETS
                ? 'postAssets'
                : 'outputAssets'
            ];
          this.productId = data.productId;
          this.title = data.barcode! || data.reference!;
          this.filteredAssets();
          console.log(`this.router.url: {this.router.url}`);
          this.pageLink = this.updatePageLink();
          this.loading = false;
          this.initialized = true;

          // if (this.isStaff && this.assetType === EAssetType.POST_ASSETS) {
          //   this.getSubjectBoundingBoxPercentage(
          //     this.assetData[this.activeTab].id,
          //     this.assetType
          //   ).subscribe(
          //     (result) => {
          //       this.subjectBoundingBoxPercentage = result;
          //     },
          //     (error) => {
          //       console.error(
          //         'Error getting subject bounding box percentage:',
          //         error
          //       );
          //     }
          //   );
          // }
        },
        error: () => {
          console.log('Invalid asset id');
          this.onBack();
        },
      });
  }

  private getSubjectBoundingBoxPercentage(
    currentAssetId: string,
    assetType: string
  ): Observable<IBoundingBoxPercentage> {
    return this.assetsService.getAssetMetaData(currentAssetId, assetType).pipe(
      map((result) => {
        const metaData = result.metaData;
        const subjectFilterKeys: string[] = [
          'SubjectTop',
          'SubjectLeft',
          'SubjectWidth',
          'SubjectHeight',
        ];
        const subjectBoundingBoxResult = this.getSpecificMetadata(
          metaData,
          subjectFilterKeys
        );
        const subjectBoundingBox: IBoundingBox = {
          top: subjectBoundingBoxResult.SubjectTop,
          left: subjectBoundingBoxResult.SubjectLeft,
          width: subjectBoundingBoxResult.SubjectWidth,
          height: subjectBoundingBoxResult.SubjectHeight,
        };
        console.log('subjectBoundingBox: ');
        console.log(subjectBoundingBox);

        const imageDimensionFilterKeys: string[] = [
          'ImageWidth',
          'ImageHeight',
        ];
        const imageDimensionsResult = this.getSpecificMetadata(
          metaData,
          imageDimensionFilterKeys
        );
        const imageDimensions: IDimensions = {
          width: imageDimensionsResult.ImageWidth,
          height: imageDimensionsResult.ImageHeight,
        };
        console.log('imageDimensions: ');
        console.log(imageDimensions);
        return { subjectBoundingBox, imageDimensions };
      }),
      map((result) => {
        const subjectBoundingBoxPercentage: IBoundingBoxPercentage =
          this.calculateSubjectBoundingBoxPercentage(
            result.subjectBoundingBox,
            result.imageDimensions
          );

        console.log('subjectBoundingBoxPercentage: ');
        console.log(subjectBoundingBoxPercentage);

        return subjectBoundingBoxPercentage;
      })
    );
  }

  private getSpecificMetadata(metaData: MetaData[], filterList: string[]): any {
    const resultArray: MetaData[] = metaData.filter((data: MetaData) =>
      filterList.includes(data.key)
    );
    const resultWithIntValues = resultArray.map((metaData: any) => {
      metaData.value = parseInt(metaData.value, 10);
      return metaData;
    });
    const resultObject: any = resultWithIntValues.reduce((acc: any, item) => {
      acc[item.key] = item.value;
      return acc;
    }, {});
    return resultObject;
  }

  private calculateSubjectBoundingBoxPercentage(
    subjectBoundingBox: IBoundingBox,
    objectSize: IDimensions
  ): IBoundingBoxPercentage {
    const res = subjectBoundingBox.top / objectSize.height;
    console.log(res);

    const subjectBoundingBoxPercentage: IBoundingBoxPercentage = {
      top: `${(subjectBoundingBox.top / objectSize.height) * 100}%`,
      left: `${(subjectBoundingBox.left / objectSize.width) * 100}%`,
      width: `${(subjectBoundingBox.width / objectSize.height) * 100}%`,
      height: `${(subjectBoundingBox.height / objectSize.height) * 100}%`,
    };

    return subjectBoundingBoxPercentage;
  }

  reviewOutputAsset(id: string, review: string) {
    this.productsService.reviewOutputAsset(id, review).subscribe((res) => {
      this.assetData[0].internalReviewState = review;
    });
  }

  compareChange(value: boolean, compareAsset: string) {
    this.isCompare = value;
    this.compareAsset = compareAsset || 'assets/image-placeholder.jpg';
  }

  onChangeVersion(index: number) {
    this.activeTab = index;
    if (index === 0) {
      this.isCompare = false;
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { version: null },
        queryParamsHandling: 'merge',
      });
    } else {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { version: this.assetData[index].index },
        queryParamsHandling: 'merge',
      });
      this.isCompare = false;
      this.compareAsset = this.assetData[index].reviewUri;
    }
  }

  openParentAsset(isPostAsset: boolean) {
    // clear Params
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { version: null },
      queryParamsHandling: 'merge',
    });

    var route = isPostAsset
      ? this.router.url
          .split('?')[0]
          .replace(this.assetType, EAssetType.SHOOT_ASSETS)
      : this.router.url.split('outputFormat')[0].concat(EAssetType.POST_ASSETS);

    const url = this.router.serializeUrl(
      this.router.createUrlTree([route], {
        queryParams: {
          version: isPostAsset
            ? this.assetData[this.activeTab].shootAssetVersionNumber
            : this.assetData[this.activeTab].postAssetVersionNumber,
        },
        queryParamsHandling: 'merge',
      })
    );
    window.open(url, '_blank');
  }

  onLoadedNavigate(value: any) {
    console.log('onLoadedNavigate', value);
    this.label = value.label;
    this.selectIndex = value.selectIndex;
    this.activeAsset = value.activeRecord;
    this.initialData();
  }

  onOpenVersion(data: any) {
    const assetType = this.route.snapshot.paramMap.get('assetType') as string;
    const idx = this.assetData.findIndex(
      (asset: IAsset) =>
        asset.id ===
        data[
          assetType === EAssetType.SHOOT_ASSETS ? 'shootAssetId' : 'postAssetId'
        ]
    );
    if (idx > -1) {
      this.onChangeVersion(idx);
    }
  }

  onReload() {
    this.getAssetDetails();
    this.assetNavigate.getData();
  }

  downLoadImage() {
    const tmp = this.assetData[this.activeTab].rawUri.split('?')[0].split('/');
    const fileName = tmp[tmp.length - 1].split('.');
    var download =
      this.assetType != 'output-asset'
        ? `${fileName[0]}-version-${
            this.assetData[this.activeTab].versionNumber
          }.${fileName[1]}`
        : `${fileName[0]}.${fileName[1]}`;
    console.log(this.assetType);
    console.log(this.selectIndex);
    if (
      this.activeTab == 0 &&
      this.assetType == 'post-asset' &&
      this.selectIndex != undefined
    ) {
      download = `${fileName[0]}_${this.selectIndex / 1000 + 1}.${fileName[1]}`;
    }

    this.productsService
      .downloadSingleImage(this.assetData[this.activeTab].rawUri.split('//')[1])
      .subscribe((res) => {
        const blod: Blob = res.body as Blob;
        const a = document.createElement('a');

        const fileData = window.URL.createObjectURL(blod);
        a.href = fileData;
        a.download = download;
        a.click();
        window.URL.revokeObjectURL(fileData);
      });
  }

  getLinkImage(source: string) {
    return ProductsService.getLinkImage(source);
  }

  box: IBoundingBox | null = null;
  initalTop: number = 0;
  initalLeft: number = 0;
  finalBox: IBoundingBox | null = null;
  drawn: boolean = false;
  activeVersionId: string = '';

  toggleDrawing() {
    this.finalBox = null;
    this.activeVersionId = !this.drawing ? this.assetData[0].id : '';
    this.drawing = !this.drawing;
  }

  startDrawing(evt: MouseEvent) {
    this.drawn = false;
    evt.preventDefault();
    this.initalTop = evt.offsetY / this.asset.nativeElement.clientHeight;
    this.initalLeft = evt.offsetX / this.asset.nativeElement.clientWidth;
    this.box = {
      left: evt.offsetX,
      top: evt.offsetY,
      width: 0,
      height: 0,
    };
  }

  keepDrawing(evt: MouseEvent) {
    if (this.box) {
      var w =
        evt.offsetX / this.asset.nativeElement.clientWidth - this.initalLeft;
      var h =
        evt.offsetY / this.asset.nativeElement.clientHeight - this.initalTop;
      this.box.width = Math.abs(w);
      this.box.height = Math.abs(h);

      this.box.top = h < 0 ? this.initalTop - this.box.height : this.initalTop;
      this.box.left =
        w < 0 ? this.initalLeft - this.box.width : this.initalLeft;
    }
  }

  stopDrawing() {
    this.finalBox = this.box;
    this.box = null;
    this.drawn = true;
  }

  checkMargin(x: boolean): string {
    if (!this.assetContainer || !this.asset) {
      return '0px';
    }

    if (x) {
      return (
        (this.assetContainer.nativeElement.clientWidth -
          this.asset.nativeElement.clientWidth) /
          2 +
        'px'
      );
    } else {
      return (
        (this.assetContainer.nativeElement.clientHeight -
          this.asset.nativeElement.clientHeight) /
          2 +
        'px'
      );
    }
  }

  commentHover(comment: IComment) {
    if (!this.drawing) {
      this.finalBox = comment == null ? null : comment.boundingBoxReference!;
      this.drawn = this.finalBox == null ? false : true;
      this.activeVersionId =
        comment == null
          ? ''
          : this.assetType == EAssetType.POST_ASSETS
          ? comment.postAssetId
          : comment.shootAssetId;
    }
  }

  pinAssetVersion(id: string, state: boolean) {
    this.assetsService.pinAsset(id, this.assetType, state).subscribe((res) => {
      console.log(res);
      const foundIdx = this.assetData.findIndex((el: IAsset) => el.id == id);
      const elm = this.assetData[foundIdx];
      elm.pinnedVersion = state;
      if (state) {
        this.assetData.splice(foundIdx, 1)[0];
        this.assetData.splice(0, 0, elm);
        this.currentAsset = this.assetData[0].reviewUri;
        this.onChangeVersion(0);
      } else {
        this.initialData();
      }
    });
  }

  deleteAssetDialog(id: string) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      data: {
        header: `Delete Version ${
          this.assetData[this.activeTab].versionNumber
        }`,
        message: `Are you sure you want to delete this ${this.assetType}`,
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.deleteAsset(id);
      }
    });
  }

  deleteAsset(id: string) {
    this.assetsService.deleteAsset(id, this.assetType).subscribe({
      next: (res) => {
        console.log(res);
        const foundIdx = this.assetData.findIndex((el: IAsset) => el.id == id);
        this.assetData.splice(foundIdx, 1);
        this.alertService.successToast(`${this.assetType} Deleted`);
        this.onChangeVersion(this.activeTab);
      },
      error: (error) => {
        if (error.status === 422) {
          error.error.validationErrors.forEach((err: ValidationErrors) => {
            this.alertService.errorToast(err.message);
          });
        }
      },
    });
  }

  background(): string {
    if (this.transparentBackground)
      return "url('assets/transparent-background.png')";
    return '';
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  onBack() {
    const productId = this.route.snapshot.paramMap.get('productId');
    const batchId = this.route.snapshot.paramMap.get('batchId');
    this.router.navigateByUrl(`batches/${batchId}/products/${productId}`);
  }

  updatePageLink() {
    const identifier: string = this.title;
    const touchNameAndAngleLabel: string = this.label;
    const pageLink = `${identifier} - ${touchNameAndAngleLabel}`;

    return pageLink;
  }
}
