<div class="content">
  <div class="content-header">
    <div class="title">Master Suite Creation</div>
  </div>

  <app-mastersuite-content
    [masterSuite]="masterSuite"
    [masterSuiteFormControl]="masterSuiteFormControl"
    [tabIndex]="tabIndex"
    [errorMessages]="errorMessages"
    [validatorProperties]="validatorProperties"
  >
  </app-mastersuite-content>
  <div class="content-actions" *ngIf="tabIndex === 0">
    <span class="spacer"></span>
    <button mat-stroked-button (click)="backClicked()">Cancel</button>
    <button
      mat-button
      color="buttonSuccess"
      style="margin-left: 20px"
      (click)="createMasterSuite()"
    >
      Create
    </button>
  </div>
</div>
