import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IPackage } from 'src/app/models/packages/package';
import { PackagesService } from 'src/app/services/packages.service';
import { DeleteModalComponent } from 'src/app/components/shared/delete-modal/delete-modal.component';
import { NavigationExtras, Router } from '@angular/router';
import { OrganizationsService } from 'src/app/services/organizations.service';
import { IOrganization } from 'src/app/models/organizations/organization';
import { FormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { IPreference, IUserProfile } from 'src/app/models/users/userProfile';
import { AuthService } from 'src/app/services/auth.service';
import { SignalrService } from 'src/app/services/signalr.service';
import { SelectOrganizationComponent } from 'src/app/components/shared/select-organization/select-organization.component';

@Component({
  selector: 'app-package-list',
  templateUrl: './package-list.component.html',
  styleUrls: ['./package-list.component.scss'],
})
export class PackageListComponent implements OnInit {
  // Service Constructor
  private destroy$ = new Subject();
  constructor(
    private router: Router,
    private packagesService: PackagesService,
    private organizationsService: OrganizationsService,
    public dialog: MatDialog,
    public _alert: MatSnackBar,
    private authService: AuthService,
    private signalrService: SignalrService
  ) {
    this.initialData();
  }
  isAdmin: boolean = false;
  invalidOrganization: boolean = false;
  invalidOrganizationMessage: string = '';
  _currentOrganization: IOrganization | null = null;
  user!: IUserProfile | null;
  currentConfigDate!: IPreference;
  get currentOrganization() {
    return this._currentOrganization;
  }
  set currentOrganization(value: IOrganization | null) {
    if (value != null) {
      if (value.id != null) {
        this._currentOrganization = value;
        this.organizationsService.setCurrentOrg(value);
        this.organizationControl.markAsUntouched();
        this.signalrService.leaveGroup(this.listGroup);
        this.signalrService.connectState.subscribe({
          next: (data: boolean) => {
            if (data) {
              this.joinGroups(value.id!);
            }
          },
        });
        this.subscribeToData();
        this.listPackages(value);
      } else {
        this.invalidOrganization = true;
        this.invalidOrganizationMessage = 'Invalid Organization';
        this.organizationControl.markAllAsTouched();
        this.organizationControl.setErrors({
          serverErrors: this.invalidOrganizationMessage,
        });
      }
    }
  }
  displayFn(option: IOrganization | null) {
    return option ? option.name : '';
  }
  private _filter(name: string): IOrganization[] {
    const filterValue = name.toLowerCase();
    return this.organizations.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }
  parseOrganizationValue(value: any) {
    const name = typeof value === 'string' ? value : value?.name;
    return name ? this._filter(name as string) : this.organizations.slice();
  }

  organizations: IOrganization[] = [];
  organizationControl = new FormControl();
  organizationFilteredOptions!: Observable<IOrganization[]>;
  organizationsSubscription: any;
  loaded: boolean = false;
  currentLoaded: boolean = false;

  packageSubscription: any;
  packages: IPackage[] = [];
  packagesFound: boolean = false;
  errorMessages: string[] = [];

  displayedColumns: string[] = [
    'name',
    'robotPosition',
    'cameraOrientation',
    'externalRefernce',
    'createdAt',
    'Actions',
  ];
  dataSource = new MatTableDataSource<IPackage>();
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  serverErrorMessage: String = '';

  filter: any;
  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toUpperCase();
  }

  initialData() {
    this.isAdmin = this.authService.getRole('admin');
    this.currentOrganization = this.organizationsService.getCurrentOrg();
    this.currentLoaded = true;
  }

  ngOnInit(): void {
    this.user = this.authService.getAuthInformation();
    this.organizationsSubscription = this.organizationsService
      .listOrganizations()
      .subscribe(
        (organizations: any) => {
          if (organizations) {
            this.organizations = organizations;
            console.log('Organizations Found: ', organizations);
            this.loaded = true;
            this.organizationFilteredOptions =
              this.organizationControl.valueChanges.pipe(
                startWith(''),
                map((value) => this.parseOrganizationValue(value))
              );
          }
        },
        (error) => {
          console.log(error);
        }
      );
    this.authService.currentPreference.subscribe((res) => {
      this.currentConfigDate = res;
    });
  }

  listPackages(organization: IOrganization) {
    if (organization.id != null) {
      this.packagesFound = true;
      this.organizationsService.setCurrentOrg(organization);
      this.packageSubscription = this.packagesService
        .listPackages(organization.id)
        .subscribe(
          (packages) => {
            if (packages) {
              console.log('Packages Found: ', packages);
              this.packages = packages;
              this.dataSource = new MatTableDataSource<IPackage>(this.packages);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
            }
          },
          (error) => {
            this.packagesFound = false;
            this.serverErrorMessage =
              'There was an error loading the Users from the server. Please restart the system and try again';
          }
        );
    } else {
      console.log('Fail');
    }
  }

  createPackage() {
    const navigationExtras: NavigationExtras = {
      state: { orgId: this.currentOrganization!.id },
    };
    this.router.navigate(['packages/create'], navigationExtras);
  }

  copyPackage(packageOriginal: IPackage) {
    this.packageSubscription = this.packagesService
      .detailPackage(packageOriginal.id!)
      .subscribe(
        (_package) => {
          if (_package) {
            const navigationExtras: NavigationExtras = {
              state: { orgId: this.currentOrganization!.id, package: _package },
            };
            this.router.navigate(['packages/create'], navigationExtras);
          }
        },
        (error) => {
          this.serverErrorMessage =
            'There was an error loading the Products from the server. Please restart the system and try again';
          console.log(error.message);
        }
      );
  }

  copyPackageAcrossOrganization(packageOriginal: IPackage) {
    const dialogRef = this.dialog.open(SelectOrganizationComponent, {
      data: {
        organizations: this.organizations,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: any) => {
        if (result.confirmed) {
          this.organizationsService.setCurrentOrg(result.organization);
          this.packageSubscription = this.packagesService
            .detailPackage(packageOriginal.id!)
            .subscribe(
              (_package) => {
                if (_package) {
                  const navigationExtras: NavigationExtras = {
                    state: {
                      orgId: result.organization.id,
                      package: _package,
                    },
                  };
                  this.router.navigate(['packages/create'], navigationExtras);
                }
              },
              (error) => {
                this.serverErrorMessage =
                  'There was an error loading the Products from the server. Please restart the system and try again';
                console.log(error.message);
              }
            );
        }
      });
  }

  updatePackage(packageOriginal: IPackage) {
    this.router.navigate([`packages/${packageOriginal.id}/update`]);
  }

  deletePackage(_package: IPackage) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      data: {
        header: `Delete Package: ${_package.name}`,
        message:
          'Are you sure you want to delete this package along with all information?',
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.packagesService.deletePackage(_package).subscribe(
          (data) => {
            this._alert.open('Successfully Deleted', 'close', {
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
              duration: 2000,
              panelClass: ['success'],
            });
            // Fetch New Products
            this.listPackages(this.currentOrganization!);
          },
          (response) => {
            console.log(response);
            this._alert.open(
              'Validation Failure: ' +
                response.error.validationErrors[0].message,
              'close',
              {
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
                duration: 10000,
                panelClass: ['error'],
              }
            );
          }
        );
      }
    });
  }

  listGroup: string = '';
  joinGroups(organizationId: string) {
    this.signalrService
      .joinIdGroup('JoinPackagesListGroup', organizationId)
      ?.then((group: string) => (this.listGroup = group));
  }

  subscribeToData() {
    this.signalrService.packagesData.subscribe({
      next: (data: any) => {
        if (data.id) {
          var index = this.packages.findIndex((p) => p.id == data.id);
          if (index >= 0) {
            this.packages[index] = data;
            this.dataSource.data = this.packages;
          } else {
            this.packages.push(data);
            this.dataSource.data = this.packages;
          }
        }
      },
    });
  }

  setPageSizePreference(event: any) {
    if (event.pageSize != this.currentConfigDate.pageSize) {
      this.currentConfigDate.pageSize = event.pageSize;
      let payload = {
        name: this.user?.name,
        preference: this.currentConfigDate,
      };
      this.authService.updatePreference(payload).subscribe((res) => {
        console.log(res);
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.signalrService.leaveGroup(this.listGroup);
  }
}
