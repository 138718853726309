import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IUser } from '../models/users/user';
import { Observable, map } from 'rxjs';
import { IUserGroups } from '../models/users/userGroups';
import { UserCreate } from '../models/users/userCreate';
import { UserUpdate } from '../models/users/userUpdate';
const httpOptions = {
  headers: new HttpHeaders({
  'Content-Type': 'application/json',
  Accept: 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(private http: HttpClient) { }

  listUsers():Observable<IUser[]>{
    const url = `${environment.apiBase}/users`;
    console.log('Getting Users');
    return this.http.get<IUser[]>(url, httpOptions)
    .pipe(
      map(users =>
        users.sort((userA: IUser,userB: IUser) => {
          const nameA : string = userA.name.toLowerCase();
          const nameB : string = userB.name.toLowerCase();
          return (nameA < nameB) ? -1 : 1;
        })
      )
    )
  }

  createUser(newUser:UserCreate):Observable<IUser>{
    const url = `${environment.apiBase}/users`
    return this.http.post<IUser>(url,newUser, httpOptions)
  }

  updateUser(id:string,updateBody:UserUpdate):Observable<IUser>{
    const url = `${environment.apiBase}/users/${id}`
    return this.http.put<IUser>(url,updateBody, httpOptions)
  }


  updateUserGroups(id:string,body:IUserGroups):Observable<IUser>{
    const url = `${environment.apiBase}/users/${id}/groups`;
    console.log('Adding User to Groups:', body.groups);
    return this.http.post<IUser>(url,body, httpOptions)
  }

  updateUserProfileImage(id: string, file: File):Observable<IUser>{
    const url = `${environment.apiBase}/users/ProfileImage/${id}`;
    console.log('Updating User Profile Image:', id);
    let formData: FormData = new FormData();
    formData.append("file", file);
    return this.http.put<IUser>(url, formData);
  }
  updateClientProfileImage(id: string, file: File):Observable<IUser>{
    const url = `${environment.apiBase}/users/ProfileImage/me`;
    let formData: FormData = new FormData();
    formData.append("file", file);
    return this.http.put<IUser>(url, formData);
  }

  deleteUser(user: IUser) {
    console.log('Deleting User ', user);
    var url = `${environment.apiBase}/users/${user.id}`;
    return this.http.delete(url, httpOptions);
  }

  getProfile():Observable<IUser>{
    var url = `${environment.apiBase}/profile`;
    return this.http.get<IUser>(url, httpOptions);
  }
}
