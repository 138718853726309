import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { IOrganization } from 'src/app/models/organizations/organization';
import { FormControl } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';

@Component({
  selector: 'app-select-organization',
  templateUrl: './select-organization.component.html',
  styleUrl: './select-organization.component.scss',
})
export class SelectOrganizationComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      organizations: IOrganization[];
    },
    public dialogRef: MatDialogRef<ConfirmModalComponent>
  ) {
    this.organizationFilteredOptions =
      this.organizationControl.valueChanges.pipe(
        startWith(''),
        map((value) => this.parseOrganizationValue(value))
      );
  }

  invalidOrganization: boolean = false;
  invalidOrganizationMessage: string = '';
  _selectedOrganization: IOrganization | null = null;
  get selectedOrganization() {
    return this._selectedOrganization;
  }
  set selectedOrganization(value: IOrganization | null) {
    if (value != null) {
      if (value.id != null) {
        this._selectedOrganization = value;
      } else {
        this.invalidOrganization = true;
        this.invalidOrganizationMessage = 'Invalid Organization';
        this.organizationControl.markAllAsTouched();
        this.organizationControl.setErrors({
          serverErrors: this.invalidOrganizationMessage,
        });
      }
    }
  }
  displayFn(option: IOrganization | null) {
    return option ? option.name : '';
  }
  private _filter(name: string): IOrganization[] {
    const filterValue = name.toLowerCase();
    return this.data.organizations.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }
  parseOrganizationValue(value: any) {
    const name = typeof value === 'string' ? value : value?.name;
    return name
      ? this._filter(name as string)
      : this.data.organizations.slice();
  }
  organizationControl = new FormControl();
  organizationFilteredOptions!: Observable<IOrganization[]>;

  onConfirm(status: boolean) {
    if (this.valid()) {
      var result = {
        confirmed: status,
        organization: this.selectedOrganization,
      };
      this.dialogRef.close(result);
    }
  }

  valid(): boolean {
    if (this.selectedOrganization != null)
      if (this.selectedOrganization.id != null) return true;

    this.invalidOrganization = true;
    this.invalidOrganizationMessage = 'Invalid Organization';
    this.organizationControl.markAllAsTouched();
    this.organizationControl.setErrors({
      serverErrors: this.invalidOrganizationMessage,
    });
    return false;
  }
}
