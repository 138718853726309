<div *ngIf="product" class="product-detail-item">
  <div class="product-detail-item__step">
    <div class="product-detail-item__step-icon">
      <mat-icon color="{{ config.state }}" matTooltip="{{ config.state }}">{{
        config.icon
      }}</mat-icon>
    </div>
  </div>
  <div class="product-detail-item__step-content">
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header style="height: 64px">
        <mat-panel-title>
          <h1>{{ config.label }}</h1>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div *ngIf="config.showAdditional">
        <div *ngFor="let item of config.additional; let idx = index">
          <div *ngIf="config.id == 'ingestion'" class="sub-heading">
            Touch {{ item.masterSuiteTouchIndex / 1000 + 1 }}:
            {{ item.masterSuiteTouchName }}
          </div>
          <mat-grid-list cols="6" rowHeight="60" gutterSize="20">
            <ng-container *ngFor="let itemConfig of config.additionalConfig">
              <mat-grid-tile [colspan]="itemConfig.col">
                <mat-form-field
                  *ngIf="itemConfig.type === 'text'"
                  appearance="outline"
                  color="primary"
                >
                  <mat-label>{{ itemConfig.label }}</mat-label>
                  <input
                    matInput
                    [readonly]="true"
                    value="{{ item[itemConfig.value] }}"
                  />
                  <button
                    *ngIf="itemConfig.label == 'Settings Variant'"
                    (click)="changeSettingsVariant()"
                    matSuffix
                    [disabled]="product.masterSuiteId == null"
                    mat-icon-button
                    color="success"
                    matTooltip="Change"
                    matTooltipPosition="above"
                    matBadge="!"
                    [matBadgeHidden]="product.settingsVariantId != null"
                  >
                    <mat-icon>lightbulb</mat-icon>
                  </button>

                  <button
                    *ngIf="itemConfig.label == 'Master Suite'"
                    (click)="viewMasterSuite()"
                    matSuffix
                    [disabled]="product.masterSuiteId == null"
                    mat-icon-button
                    color="info"
                    matTooltip="Details"
                    matTooltipPosition="above"
                  >
                    <mat-icon>info</mat-icon>
                  </button>
                </mat-form-field>

                <mat-form-field
                  *ngIf="itemConfig.type === 'number'"
                  appearance="outline"
                  color="primary"
                >
                  <mat-label>{{ itemConfig.label }}</mat-label>
                  <input
                    matInput
                    [readonly]="true"
                    value="{{ item['dimensions'][itemConfig.value] }}"
                  />
                </mat-form-field>

                <mat-form-field
                  *ngIf="itemConfig.type === 'date'"
                  appearance="outline"
                  color="primary"
                >
                  <mat-label>Date</mat-label>
                  <input
                    matInput
                    [readonly]="true"
                    value="{{
                      item[itemConfig.value]
                        | dateAgo : currentConfigDate : false
                        | async
                    }}"
                  />
                </mat-form-field>
              </mat-grid-tile>
            </ng-container>
          </mat-grid-list>
        </div>
      </div>
    </mat-expansion-panel>
  </div>
</div>
