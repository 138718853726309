<div class="content">
  <div class="content-header">
    <div class="title">Jobs</div>
    <span class="spacer"></span>
    <mat-spinner *ngIf="updating" diameter="50"></mat-spinner>
    <button
      *ngIf="isStaff"
      mat-icon-button
      [matMenuTriggerFor]="filtersMenu"
      matTooltip="Tags"
      matTooltipPosition="above"
      style="margin-right: 8px"
      color="primary"
    >
      <mat-icon>{{
        user?.filteredBatchTagIds?.length != 0
          ? "filter_list"
          : "filter_list_off"
      }}</mat-icon>
    </button>
    <mat-menu #filtersMenu="matMenu">
      <div
        style="width: 300px; padding: 2px 10px !important"
        (click)="$event.stopPropagation()"
      >
        <div style="display: flex; align-items: center">
          Tags
          <span class="spacer"></span>
          <button
            mat-button
            class="action-button"
            (click)="selectedFiltersControl.setValue([])"
          >
            Clear
          </button>
        </div>
        <mat-button-toggle-group
          style="width: -webkit-fill-available; margin: 8px 0"
          [formControl]="includeToggleControl"
        >
          <mat-button-toggle [value]="true" style="width: 50%"
            >Include</mat-button-toggle
          >
          <mat-button-toggle [value]="false" style="width: 50%"
            >Exclude</mat-button-toggle
          >
        </mat-button-toggle-group>
        <mat-form-field
          style="width: -webkit-fill-available; margin-bottom: 8px"
          subscriptSizing="dynamic"
          appearance="outline"
        >
          <mat-label>Search Tags</mat-label>
          <input
            matInput
            type="text"
            (keyup)="applyFilterBatchTags($event)"
            placeholder="Filter"
          />
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
        <mat-chip-listbox
          multiple
          class="mat-mdc-chip-set-stacked"
          style="max-height: 200px; overflow: auto; margin-bottom: 8px"
          [formControl]="selectedFiltersControl"
        >
          <mat-chip-option
            *ngFor="let tag of filteredBatchtags"
            [value]="tag.id"
            [selected]="selectedFiltersControl.value?.includes(tag.id!)"
            >{{ tag.tag }}</mat-chip-option
          >
        </mat-chip-listbox>
        <div style="display: flex; justify-content: end">
          <button
            mat-raised-button
            color="buttonSuccess"
            class="action-button"
            (click)="applyFilteredBatches()"
          >
            Apply
          </button>
        </div>
      </div>
    </mat-menu>
    <button
      mat-icon-button
      matTooltip="Favourites"
      matTooltipPosition="above"
      style="margin-right: 8px"
      (click)="filterBatchFavorite()"
      color="error"
    >
      <mat-icon>{{ isFavorite ? "favorite" : "favorite_border" }}</mat-icon>
    </button>
    <form>
      <mat-form-field subscriptSizing="dynamic" appearance="outline">
        <mat-label>Search</mat-label>
        <input
          matInput
          type="text"
          (keyup)="applyFilter($event)"
          placeholder="Filter"
        />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </form>
  </div>
  <div style="overflow-x: auto">
    <table mat-table [dataSource]="dataSource" matSort>
      <!-- Favorite batch Column -->
      <ng-container matColumnDef="favorite">
        <th mat-header-cell *matHeaderCellDef style="width: 30px"></th>
        <td mat-cell *matCellDef="let batch">
          <button
            *ngIf="!isStaff"
            mat-icon-button
            (click)="addBatchToFavorite(batch)"
          >
            <mat-icon
              [matTooltip]="
                listBatchFavorite.includes(batch.id)
                  ? 'UnFavourite'
                  : 'Favourite'
              "
              color="error"
              >{{
                listBatchFavorite.includes(batch.id)
                  ? "favorite"
                  : "favorite_border"
              }}</mat-icon
            >
          </button>
          <mat-icon *ngIf="isStaff" color="error">{{
            listBatchFavorite.includes(batch.id) ? "favorite" : ""
          }}</mat-icon>
        </td>
      </ng-container>

      <!-- Client Column -->
      <ng-container matColumnDef="organizationName" *ngIf="isStaff">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Organization</th>
        <td mat-cell *matCellDef="let batch">{{ batch.organizationName }}</td>
      </ng-container>

      <!-- Batch Number Column -->
      <ng-container matColumnDef="jobNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Job Number</th>
        <td mat-cell *matCellDef="let batch">{{ batch.jobNumber }}</td>
      </ng-container>

      <!-- Batch Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let batch">{{ batch.name }}</td>
      </ng-container>

      <!-- Batch Description Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
        <td mat-cell *matCellDef="let batch">{{ batch.description }}</td>
      </ng-container>

      <!-- Product Count Column -->
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sort>
          {{ isStaff ? "Creation Date" : "Start Date" }}
        </th>
        <td mat-cell *matCellDef="let batch">
          <div
            matTooltip="{{
              batch.createdAt | dateAgo : currentConfigDate : true | async
            }}"
            matTooltipPosition="before"
          >
            {{ batch.createdAt | dateAgo : currentConfigDate | async }}
          </div>
        </td>
      </ng-container>

      <!-- Product Count Column -->
      <ng-container matColumnDef="productCount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ isStaff ? "Product Count" : "SKU Count" }}
        </th>
        <td mat-cell *matCellDef="let batch">
          {{ batch.productCount }}
        </td>
      </ng-container>

      <!-- Client Status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let batch">
          <div
            class="status-text"
            [ngClass]="{
              complete:
                batch.productsReleasedCount == batch.productCount &&
                batch.productCount > 0,
              inProgress:
                batch.productsShotCount > 0 &&
                batch.productsApprovedOutputsCount != batch.productCount
            }"
          >
            <div class="external-status"></div>
            {{
              batch.productsReleasedCount == batch.productCount
                ? "Complete"
                : batch.productsShotCount > 0
                ? "In Progress"
                : "Pending"
            }}
          </div>
        </td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="states" *ngIf="isStaff">
        <th mat-header-cell *matHeaderCellDef style="width: 200px">Status</th>
        <td mat-cell *matCellDef="let batch" class="icon-ctn">
          <div
            *ngIf="isStaff"
            style="display: flex; justify-content: space-between"
          >
            <mat-icon
              fontIcon="receipt_long"
              color="{{
                batch.productsReceivedGoodCount +
                  batch.productsReceivedOkayCount ==
                  batch.productCount && batch.productCount > 0
                  ? 'success'
                  : batch.productsReceivedPoorCount > 0
                  ? 'error'
                  : batch.productsReceivedGoodCount +
                      batch.productsReceivedOkayCount >
                    0
                  ? 'active'
                  : 'pending'
              }}"
              matTooltip="{{ batch.productsReceivedCount }}/{{
                batch.productCount
              }} Products Received"
            ></mat-icon>
            <mat-icon
              fontIcon="lightbulb"
              color="{{
                batch.productsAssignedMasterSuiteCount == batch.productCount &&
                batch.productCount > 0
                  ? 'success'
                  : batch.productsAssignedMasterSuiteCount > 0
                  ? 'active'
                  : 'pending'
              }}"
              matTooltip="{{ batch.productsAssignedMasterSuiteCount }}/{{
                batch.productCount
              }} MasterSuites Applied"
            ></mat-icon>
            <mat-icon
              fontIcon="dataset"
              color="{{
                batch.productsIngestedCount == batch.productCount &&
                batch.productCount > 0
                  ? 'success'
                  : batch.productsIngestedCount > 0
                  ? 'active'
                  : 'pending'
              }}"
              matTooltip="{{ batch.productsIngestedCount }}/{{
                batch.productCount
              }} Products Ingested"
            ></mat-icon>
            <mat-icon
              fontIcon="camera_alt"
              color="{{
                batch.productsShotCount == batch.productCount &&
                batch.productCount > 0
                  ? 'success'
                  : batch.productsShotCount > 0
                  ? 'active'
                  : 'pending'
              }}"
              matTooltip="{{ batch.productsShotCount }}/{{
                batch.productCount
              }} Products Shot"
            ></mat-icon>
            <mat-icon
              fontIcon="palette"
              color="{{
                batch.productsEnhancedCount == batch.productCount &&
                batch.productCount > 0
                  ? 'success'
                  : batch.productsEnhancedCount > 0
                  ? 'active'
                  : 'pending'
              }}"
              matTooltip="{{ batch.productsEnhancedCount }}/{{
                batch.productCount
              }} Products Enhanced"
            ></mat-icon>
            <mat-icon
              fontIcon="reviews"
              color="{{
                batch.productsApprovedCount == batch.productCount &&
                batch.productCount > 0
                  ? 'success'
                  : batch.productsRejectedCount > 0
                  ? 'error'
                  : batch.productsApprovedCount > 0
                  ? 'active'
                  : 'pending'
              }}"
              matTooltip="{{ batch.productsApprovedCount }}/{{
                batch.productCount
              }} Products Approved"
            ></mat-icon>

            <mat-icon
              fontIcon="delivery_dining"
              color="{{
                batch.productsDispatchedCount == batch.productCount &&
                batch.productCount > 0
                  ? 'success'
                  : batch.productsDispatchedCount > 0
                  ? 'active'
                  : 'pending'
              }}"
              matTooltip="{{ batch.productsDispatchedCount }}/{{
                batch.productCount
              }} Products Returned"
            ></mat-icon>

            <mat-icon
              color="{{
                batch.productsReleasedCount == batch.productCount &&
                batch.productCount != 0
                  ? 'success'
                  : batch.productsRejectedOutputsCount > 0
                  ? 'error'
                  : batch.productsApprovedOutputsCount > 0
                  ? 'active'
                  : 'pending'
              }}"
              matTooltip="{{ batch.productsReleasedCount }}/{{
                batch.productCount
              }} Completed Outputs"
              fontIcon="filter"
            ></mat-icon>
          </div>
          <!--           <mat-icon fontIcon="delivery_dining" color="{{batch.states.dispatchedState}}"
            matTooltip="Return {{batch.states.dispatchedState}}"></mat-icon> -->
        </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef style="width: 150px"></th>
        <td mat-cell *matCellDef="let batch">
          <button
            mat-icon-button
            color="info"
            matTooltip="Details"
            (click)="manageBatch(batch)"
          >
            <mat-icon>view_list</mat-icon>
          </button>

          <button
            *ngIf="isStaff"
            [matMenuTriggerFor]="actionsMenu"
            mat-icon-button
            matTooltip="More"
            color="primary"
            class="mat-elevation-z0"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #actionsMenu="matMenu">
            <button *ngIf="isStaff" mat-menu-item (click)="updateBatch(batch)">
              <mat-icon matTooltip="Edit" color="success">edit</mat-icon>
              <span>Edit</span>
            </button>
            <button *ngIf="isStaff" mat-menu-item (click)="detailBatch(batch)">
              <mat-icon
                matTooltip="Details"
                matTooltipPosition="below"
                color="info"
                >info</mat-icon
              >
              <span>Details</span>
            </button>
            <button *ngIf="isStaff" mat-menu-item (click)="addBatchTags(batch)">
              <mat-icon>local_offer</mat-icon>
              <span>Add Tags</span>
            </button>

            <button mat-menu-item (click)="addBatchToFavorite(batch)">
              <mat-icon matTooltip="Favorite" color="error">{{
                listBatchFavorite.includes(batch.id)
                  ? "favorite"
                  : "favorite_border"
              }}</mat-icon>
              <span>{{
                listBatchFavorite.includes(batch.id)
                  ? "UnFavourite"
                  : "Favourite"
              }}</span>
            </button>
            <button *ngIf="isAdmin" mat-menu-item (click)="deleteBatch(batch)">
              <mat-icon matTooltip="Delete" color="error">delete</mat-icon>
              <span>Delete</span>
            </button>
          </mat-menu>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
  <mat-paginator
    [pageSizeOptions]="[10, 20, 50, 100]"
    showFirstLastButtons
    aria-label="Select page of Batch"
    [pageSize]="currentConfigDate.pageSize"
    (page)="setPageSizePreference($event)"
  >
  </mat-paginator>
  <div class="content-actions" *ngIf="isAdmin">
    <span class="spacer"></span>
    <button
      mat-raised-button
      color="buttonSuccess"
      class="action-button"
      (click)="createBatch()"
    >
      Create Batch
    </button>
  </div>
</div>
