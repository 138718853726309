import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './auth.guard';
/*
 Commponent Imports
*/
// Batches
import { BatchListComponent } from './components/home/batches/batch-list/batch-list.component';
import { BatchDetailsComponent } from './components/home/batches/batch-details/batch-details.component';
// Organisations
import { OrganizationListComponent } from './components/home/organizations/organization-list/organization-list.component';
import { OrganizationCreateComponent } from './components/home/organizations/organization-create/organization-create.component';
import { OrganizationUpdateComponent } from './components/home/organizations/organization-update/organization-update.component';
// Products
import { ProductListComponent } from './components/home/products/product-list/product-list.component';
import { ProductReceivalsComponent } from './components/home/products/product-receivals/product-receivals.component';
// Packages
import { PackageListComponent } from './components/home/packages/package-list/package-list.component';
import { PackageCreateComponent } from './components/home/packages/package-create/package-create.component';
import { PackageUpdateComponent } from './components/home/packages/package-update/package-update.component';
// Settings
import { SettingListComponent } from './components/home/setting/setting-list/setting-list.component';
import { SettingCreateComponent } from './components/home/setting/setting-create/setting-create.component';
import { SettingUpdateComponent } from './components/home/setting/setting-update/setting-update.component';
// Master Suites
import { MasterSuiteListComponent } from './components/home/mastersuites/mastersuite-list/mastersuite-list.component';
import { MasterSuiteCreateComponent } from './components/home/mastersuites/mastersuite-create/mastersuite-create.component';
import { MastersuiteUpdateComponent } from './components/home/mastersuites/mastersuite-update/mastersuite-update.component';
import { ProductDetailsPageComponent } from './components/home/products/product-details-page/product-details-page.component';
import { ReviewAssetsComponent } from './components/home/products/review-assets/review-assets.component';
import { ProductSearchComponent } from './components/home/products/product-search/product-search.component';
import { UserListComponent } from './components/home/users/user-list/user-list.component';
import { EnhancementVariantCreateComponent } from './components/home/mastersuites/enhancement-variant-create/enhancement-variant-create.component';
import { DashboardComponent } from './components/home/dashboard/dashboard.component';
import { ShopifyAuthResultComponent } from './components/home/organizations/shopify-auth-result/shopify-auth-result.component';
import { OutputFormatsComponent } from './components/home/organizations/organization-update/output-formats/output-formats.component';
import { OutputCollectionsListComponent } from './components/home/output-collections/output-collections-list/output-collections-list.component';
import { OutputCollectionsProductListComponent } from './components/home/output-collections/output-collections-product-list/output-collections-product-list.component';

const routes: Routes = [
  { path: 'signin', component: LoginComponent },
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'batches', component: BatchListComponent },
      { path: 'batches/:batchId', component: BatchDetailsComponent },
      { path: 'batches/:batchId/products', component: ProductListComponent },
      {
        path: 'batches/:batchId/products/:productId',
        component: ProductDetailsPageComponent,
      },
      {
        path: 'collections',
        component: OutputCollectionsListComponent,
      },
      {
        path: 'collections/:id/products',
        component: OutputCollectionsProductListComponent,
      },
      { path: 'product/receivals', component: ProductReceivalsComponent },
      { path: 'packages', component: PackageListComponent },
      { path: 'packages/create', component: PackageCreateComponent },
      { path: 'packages/:packageId/update', component: PackageUpdateComponent },
      { path: 'settings', component: SettingListComponent },
      { path: 'settings/create', component: SettingCreateComponent },
      { path: 'settings/:settingId/update', component: SettingUpdateComponent },
      { path: 'mastersuites', component: MasterSuiteListComponent },
      { path: 'mastersuites/create', component: MasterSuiteCreateComponent },
      {
        path: 'mastersuites/:masterSuiteId/update',
        component: MastersuiteUpdateComponent,
      },
      {
        path: 'mastersuites/:masterSuiteId/update/enhancement-variant',
        component: EnhancementVariantCreateComponent,
      },
      {
        path: 'mastersuites/:masterSuiteId/update/enhancement-variant/:enhancementVariantId',
        component: EnhancementVariantCreateComponent,
      },
      { path: 'organizations', component: OrganizationListComponent },
      { path: 'organizations/create', component: OrganizationCreateComponent },
      {
        path: 'organizations/:id/update',
        component: OrganizationUpdateComponent,
      },
      {
        path: 'organizations/:id/output-format/:outputFormatId',
        component: OutputFormatsComponent,
      },
      {
        path: 'organizations/auth-result',
        component: ShopifyAuthResultComponent,
      },
      {
        path: 'users',
        component: UserListComponent,
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      {
        path: 'batches/:batchId/products/:productId/productTouchAsset/:assetId/masterSuiteTouchAsset/:masterSuiteTouchAssetId/:assetType',
        component: ReviewAssetsComponent,
      },
      {
        path: 'batches/:batchId/products/:productId/productTouchAsset/:assetId/masterSuiteTouchAsset/:masterSuiteTouchAssetId/outputFormat/:outputFormatId/:assetType',
        component: ReviewAssetsComponent,
      },
      { path: 'product-search/:keyword', component: ProductSearchComponent },
      { path: 'product-filter', component: ProductSearchComponent },
    ],
  },
  { path: '**', redirectTo: '/dashboard' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
