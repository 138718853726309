import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IBatch } from 'src/app/models/batches/batch';
import { ValidationErrors } from 'src/app/models/validationErrors';
import { BatchesService } from 'src/app/services/batches.services';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-batch-update',
  templateUrl: './batch-update.component.html',
  styleUrls: ['./batch-update.component.scss'],
})
export class BatchUpdateComponent {
  private destroy$ = new Subject();
  batch!: IBatch;
  batchSubscription: any;
  errorState: Boolean = false;
  serverErrorMessage: String = '';
  errorMessages: ValidationErrors[] = [];
  validatorProperties: string[] = [];
  input: FormControl;

  constructor(
    private batchesService: BatchesService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<BatchUpdateComponent>,
    private _alert: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) private data: IBatch
  ) {
    this.batch = data;
    this.input = new FormControl('', [Validators.required]);
  }

  ngOnInit(): void {
    this.batchSubscription = this.batchesService
      .detailsBatch(this.batch?.id || '')
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (batch) => {
          if (batch) {
            console.log(batch);
            this.batch = batch;
          }
        },
        (error) => {
          this.serverErrorMessage =
            'There was an error loading the Products from the server. Please restart the system and try again';
          console.log(error.message);
        }
      );
  }

  updateBatch() {
    this.batchesService
      .updateBatch(this.batch)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          this._alert.open('Successfully Updated Product', 'close', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 2000,
            panelClass: ['success'],
          });
          this.dialogRef.close(true);
        },
        (error) => {
          this.errorState = true;
          if (error.status === 422) {
            error.error.validationErrors.forEach((error: ValidationErrors) => {
              this.errorMessages.push(error);
              this.validatorProperties.push(error.property);
              this.input.markAllAsTouched();
            });
          }
        }
      );
  }

  validationMessage(property: string) {
    return this.errorMessages.find((error) => error.property == property)
      ?.message;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
