import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
// Models
import { IBatch } from '../models/batches/batch';
import { BatchNew } from '../models/batches/batchNew';
import { Tag } from '../models/tag';
import { IUserProfile } from '../models/users/userProfile';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }),
};
@Injectable({
  providedIn: 'root',
})
export class BatchesService {
  // Observable string sources
  private batchData = new Subject<IBatch>();
  // Observable string streams
  batchData$ = this.batchData.asObservable();

  constructor(private http: HttpClient) {}
  currentBatch!: IBatch;
  private url = `${environment.apiBase}/batches`;

  // Set Current
  setCurrentBatch(batch: IBatch) {
    this.batchData.next(batch);
    this.currentBatch = batch;
  }
  // Get Current Job
  getCurrentBatch(): IBatch {
    return this.currentBatch;
  }

  detailsBatch(batchId: string): Observable<IBatch> {
    console.log('Getting Details for Batch: ', batchId);
    var url = `${environment.apiBase}/batches/${batchId}`;
    return this.http.get<any>(url, httpOptions);
  }

  // Create New
  createBatch(newJob: BatchNew): Observable<IBatch> {
    console.log('Creating New Job');
    return this.http.post<any>(this.url, newJob, httpOptions);
  }

  // Delete
  deleteBatch(batch: IBatch): Observable<IBatch> {
    console.log('Deleting Batch ', batch);
    var url = `${environment.apiBase}/batches/${batch.id}`;
    return this.http.delete<any>(url, httpOptions);
  }

  updateBatch(batch: IBatch): Observable<IBatch> {
    var url = `${environment.apiBase}/batches/${batch.id}`;
    console.log('Updating Product');
    return this.http.put<IBatch>(url, batch, httpOptions);
  }

  listBatches(isFavorite?: boolean, count?: number): Observable<IBatch[]> {
    let param = '';
    if (isFavorite) {
      param = param + '?isFavourite=true';
    }
    if (count) {
      param = param + (param !== '' ? `&count=${count}` : `?count=${count}`);
    }
    let url = `${environment.apiBase}/batches${param}`;
    console.log('Getting Batches');
    return this.http.get<IBatch[]>(url, httpOptions);
  }

  updateUsersBatchTags(
    tagIds: string[],
    include: boolean,
    userId: string
  ): Observable<IUserProfile> {
    let url = `${environment.apiBase}/users/${userId}/batchTags`;
    console.log('Getting Batch Tags');
    var body = {
      tagIds: tagIds,
      includeFilteredBatches: include,
    };
    return this.http.post<IUserProfile>(url, body, httpOptions);
  }

  favoriteBatch(batch: IBatch): Observable<any> {
    console.log('Favorite Batch: ', batch);
    let url = `${environment.apiBase}/users/favourites/batches/${batch.id}`;
    return this.http.post<any>(url, httpOptions);
  }
}
