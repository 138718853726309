import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  HostListener,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ProductsService } from 'src/app/services/products.service';
import { IProduct } from 'src/app/models/products/product';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ITouch } from 'src/app/models/products/touch';
import { AuthService } from 'src/app/services/auth.service';
import {
  EAssetType,
  EStateAsset,
  IAsset,
  latestOutputAssets,
} from 'src/app/models/products/asset';
import { MasterSuitesService } from 'src/app/services/mastersuites.service';
import { EnhancementVariantDetail } from 'src/app/models/mastersuites/mastersuite';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { MasterSuiteDialogComponent } from 'src/app/components/shared/master-suite-dialog/master-suite-dialog.component';
import { WarningModalComponent } from 'src/app/components/shared/warning-modal/warning-modal.component';
import { SignalrService } from 'src/app/services/signalr.service';
import { ProductUpdateComponent } from '../product-update/product-update.component';
import {
  OutputFormat,
  StillOutputFormat,
  VideoOutputFormat,
} from 'src/app/models/organizations/form-setting';
import { OrganizationsService } from 'src/app/services/organizations.service';
import { GenerateShopifyAssetsSyncComponent } from 'src/app/components/shared/generate-shopify-assets-sync/generate-shopify-assets-sync.component';
import { FormControl } from '@angular/forms';
import { GenerateOutputsComponent } from 'src/app/components/shared/generate-outputs/generate-outputs.component';
import { environment } from 'src/environments/environment';
import { AlertService } from 'src/app/services/alert.service';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { UrlService } from 'src/app/services/url.service';

@Component({
  selector: 'app-product-details-page',
  templateUrl: './product-details-page.component.html',
  styleUrls: ['./product-details-page.component.scss'],
})
export class ProductDetailsPageComponent implements OnInit {
  @ViewChild('cardItem') cardItemEl!: ElementRef;
  product!: IProduct;
  private destroy$ = new Subject();
  activeStep = 'preProduction';
  private activeStepLabel: string = '';
  stepperConfig = [
    {
      id: 'preProduction',
      label: 'pre-production',
    },
    {
      id: 'production',
      label: 'production',
    },
    {
      id: 'postProduction',
      label: 'post-production',
    },
    {
      id: 'outputAssets',
      label: 'output-assets',
    },
  ];
  stepperContent: any = {
    preProduction: [],
    production: [],
    postProduction: [],
    outputAssets: [],
  };
  listProduction: any = [];
  listPostProduction: any = [];
  listOutputAssets: any = [];
  rowHeight = 'fit';
  isLoading = false;
  colPerScreen = 3;
  isClient = false;
  enhancementVariantIdSelected: string | null = '';
  filterStatusOption = [
    {
      value: '',
      label: 'All',
    },
    {
      value: EStateAsset.APPROVED,
      label: 'Approved',
    },
    {
      value: EStateAsset.REJECTED,
      label: 'Rejected',
    },
  ];
  selectedAllProduct = {
    production: false,
    postProduction: false,
    outputAssets: false,
  };

  displaySelectTouch: { [key: string]: boolean } = {};
  selectedProducts = {
    production: 0,
    postProduction: 0,
    outputAssets: 0,
  };
  enhancementVariants!: EnhancementVariantDetail[];
  postView: string = 'selects';

  readonly breakpoint$ = this.breakpointObserver.observe([
    Breakpoints.XLarge,
    Breakpoints.Large,
    Breakpoints.Medium,
    Breakpoints.Small,
  ]);

  backToCollection: boolean = false;
  collectionId: string = '';
  public pageLink: string = '';

  constructor(
    private authService: AuthService,
    private productsService: ProductsService,
    public breakpointObserver: BreakpointObserver,
    private clipboard: Clipboard,
    public clipboardService: ClipboardService,
    private router: Router,
    private route: ActivatedRoute,
    private masterSuiteService: MasterSuitesService,
    private _alert: MatSnackBar,
    public dialog: MatDialog,
    private signalrService: SignalrService,
    public urlService: UrlService,
    private alertService: AlertService
  ) {
    this.route.queryParams.subscribe((res) => {
      console.log(res);
      if (res['collectionId'] != null) {
        this.backToCollection = true;
        this.collectionId = res['collectionId'];
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateRowHeight();
  }

  copyToClipboard(text: any, param: string) {
    this.clipboard.copy(text);
    this.alertService.infoToast(`Copied product ${param} to clipboard`);
  }

  ngOnInit(): void {
    this.breakpoint$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.breakpointChanged();
    });
    if (!this.product) {
      this.getProductDetail();
    } else {
      this.initialData();
    }
  }

  pageUpdate(id: string) {
    this.rowHeight = 'fit';
    this.isLoading = true;
    this.productsService.setStatePD('activeStep', id);
    this.productsService.setStatePD('filterBy', '');

    switch (id) {
      case 'preProduction':
        this.signalrService.leaveGroup(this.latestAssetsGroup);
        this.getPreProductionData();
        return;

      case 'production':
        this.signalrService.leaveGroup(this.latestAssetsGroup);
        this.getProductionData();
        this.getEnhancementVariant();
        return;

      case 'postProduction':
        this.signalrService.leaveGroup(this.latestAssetsGroup);
        this.getPostProductionData();
        return;

      case 'outputAssets':
        this.getOutputAssetsData();
        return;

      default:
        this.isLoading = false;
        return;
    }
  }

  initialData() {
    this.productsService.setStatePD('id', this.product.id);
    if (this.authService.getRole('client')) {
      this.productsService.setStatePD('activeStep', 'outputAssets');
    }
    // const { activeStep, filterBy } = this.productsService.getStatePD();
    // this.activeStep = activeStep;
    this.getRole();
    this.rowHeight = 'fit';
    this.joinDetailsGroups();
    this.subscribeToData();

    const queryParam = this.getExistingQueryParam();
    const defaultStepLabel = this.isClient ? 'output-assets' : 'production';
    this.activeStepLabel = queryParam ? queryParam : defaultStepLabel;
    this.navigationUpdate(this.activeStepLabel);
    console.log(`this.activeStep: ${this.activeStep}`);

    switch (this.activeStep) {
      case 'preProduction':
        this.getPreProductionData();
        return;

      case 'production':
        this.getProductionData();
        this.getEnhancementVariant();
        return;

      case 'postProduction':
        this.getPostProductionData();
        return;

      case 'outputAssets':
        this.getOutputAssetsData();
        return;

      default:
        this.isLoading = false;
        return;
    }
  }

  onBack() {
    const batchId = this.route.snapshot.paramMap.get('batchId');
    this.backToCollection
      ? this.router.navigateByUrl(`collections/${this.collectionId}/products`)
      : this.router.navigateByUrl(`batches/${batchId}/products`);
  }

  getRole() {
    this.isClient = this.authService.getRole('client');
    this.stepperConfig = this.stepperConfig
      .map((el) => ({
        ...el,
        hidden:
          this.isClient &&
          (el.id === 'production' ||
            el.id === 'preProduction' ||
            el.id === 'postProduction'),
      }))
      .filter((el) => !el.hidden);
  }

  parseAsset(assets: IAsset[]) {
    let angleIndex = 1;
    let videoIndex = 1;
    return assets.map((asset: IAsset) => {
      let label = `${asset.type === 'Still' ? 'Angle' : 'Video'} `;
      let subLabel = label;
      if (this.activeStep == 'production') {
        if (asset.additionalAssetIndex != null) {
          label += `${(asset.type === 'Still' ? angleIndex : videoIndex) - 1}.${
            asset.additionalAssetIndex + 1
          }`;
        } else if (asset.type === 'Still') {
          label += asset.packageAssetIndex / 1000 + 1;
          angleIndex++;
        } else {
          var touch = this.listProduction.find(
            (touch: ITouch) => touch.id == asset.masterSuiteTouchId
          );
          var imageCount = touch.assets.filter(
            (a: IAsset) => a.type == 'Still' && a.childIndex == null
          ).length;
          label += asset.packageAssetIndex / 1000 + 1 - imageCount;
          videoIndex++;
        }
      }

      if (
        this.activeStep == 'postProduction' ||
        this.activeStep == 'outputAssets'
      ) {
        label =
          asset.selectsIndex >= 0
            ? `Select ${asset.selectsIndex / 1000 + 1}`
            : 'Non-Select';
        if (asset.type === 'Still') {
          subLabel = `Touch ${
            asset.masterSuiteTouchIndex / 1000 + 1
          } / ${subLabel} ${asset.packageAssetIndex / 1000 + 1}`;
        } else {
          subLabel = `Touch ${
            asset.masterSuiteTouchIndex / 1000 + 1
          } / ${subLabel} ${asset.videoIndex}`;
        }

        if (asset.additionalAssetIndex != null) {
          subLabel += `.${asset.additionalAssetIndex + 1}`;
        }
      }
      if (this.activeStep == 'outputAssets' && asset.id) {
        label = `Select ${asset.selectsIndex / 1000 + 1}`;
        var parts = asset.reviewUri.split('/');
        subLabel = parts[parts.length - 1];
        subLabel = subLabel.split('?')[0];
      }

      asset.status = this.isClient
        ? asset.clientReviewState
        : asset.internalReviewState;

      return {
        ...asset,
        label,
        subLabel: subLabel,
      };
    });
  }

  getProductionData() {
    if (this.product.id) {
      this.joinLatestAssetsGroup('JoinProductShootAssetsGroup');
      this.listProduction = [];
      this.productsService
        .getListProduction(this.product.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (value: ITouch[]) => {
            this.listProduction = [];
            value.forEach((touch) => {
              const assetData: any = [];
              touch.assets.forEach((asset) => {
                assetData.push({
                  ...asset,
                  selected: false,
                });
                if (!!asset.copyAssets && asset.copyAssets.length > 0) {
                  asset.copyAssets.forEach((copyAsset, index) => {
                    assetData.push({
                      ...asset,
                      ...copyAsset,
                      childIndex: index + 1,
                      selected: false,
                    });
                  });
                }
              }),
                this.listProduction.push({
                  ...touch,
                  assets: assetData,
                  selectedTouch: false,
                  quantitySelected: 0,
                });
            });
            this.stepperContent['production'] = this.parseStepContentData(
              this.activeStep === 'production'
                ? this.listProduction
                : this.listPostProduction
            );
            this.listProduction.forEach((p: any) => {
              this.displaySelectTouch[p.id] = this.checkDisplaySelectTouch(
                p.id
              );
            });
            this.selectedAllProduct = {
              postProduction: false,
              production: false,
              outputAssets: false,
            };
            this.selectedProducts = {
              postProduction: 0,
              production: 0,
              outputAssets: 0,
            };
            this.isLoading = false;
          },
        });
    }
  }

  onViewChange() {
    if (this.postView == 'selects') {
      this.getPostProductionData();
    } else {
      this.getAllPostProductionData();
    }
  }

  getPostProductionData() {
    if (this.product.id) {
      this.joinLatestAssetsGroup('JoinProductPostAssetsGroup');
      this.listPostProduction = [];
      this.productsService
        .getListPostProduction(this.product.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (value: IAsset[]) => {
            this.listPostProduction = [];
            const assetData: any = [];
            value.forEach((asset) => {
              assetData.push({
                ...asset,
                selected: false,
              });
              if (!!asset.copyAssets && asset.copyAssets.length > 0) {
                asset.copyAssets.forEach((copyAsset, index) => {
                  assetData.push({
                    ...asset,
                    ...copyAsset,
                    childIndex: index + 1,
                    selected: false,
                  });
                });
              }
            }),
              this.listPostProduction.push({
                assets: assetData,
                selectedTouch: false,
                quantitySelected: 0,
              });

            this.stepperContent['postProduction'] = this.parseStepContentData(
              this.activeStep === 'production'
                ? this.listProduction
                : this.listPostProduction
            );
            this.listPostProduction.forEach((p: any) => {
              this.displaySelectTouch[p.id] = this.checkDisplaySelectTouch(
                p.id
              );
            });
            this.selectedAllProduct = {
              postProduction: false,
              production: false,
              outputAssets: false,
            };
            this.selectedProducts = {
              postProduction: 0,
              production: 0,
              outputAssets: 0,
            };
            this.isLoading = false;
          },
        });
    }
  }

  getAllPostProductionData() {
    if (this.product.id) {
      this.listProduction = [];
      this.productsService
        .getListAllPostProduction(this.product.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (value: ITouch[]) => {
            this.listProduction = [];
            value.forEach((touch) => {
              const assetData: any = [];
              touch.assets.forEach((asset) => {
                assetData.push({
                  ...asset,
                  selected: false,
                });
                if (!!asset.copyAssets && asset.copyAssets.length > 0) {
                  asset.copyAssets.forEach((copyAsset, index) => {
                    assetData.push({
                      ...asset,
                      ...copyAsset,
                      childIndex: index + 1,
                      selected: false,
                    });
                  });
                }
              }),
                this.listProduction.push({
                  ...touch,
                  assets: assetData,
                  selectedTouch: false,
                  quantitySelected: 0,
                });
            });
            this.stepperContent['postProduction'] = this.parseStepContentData(
              this.listProduction
            );
            this.listProduction.forEach((p: any) => {
              this.displaySelectTouch[p.id] = this.checkDisplaySelectTouch(
                p.id
              );
            });
            this.selectedAllProduct = {
              postProduction: false,
              production: false,
              outputAssets: false,
            };
            this.selectedProducts = {
              postProduction: 0,
              production: 0,
              outputAssets: 0,
            };
            this.isLoading = false;
          },
        });
    }
  }

  getOutputAssetsData() {
    if (this.product.id) {
      this.listOutputAssets = [];
      this.productsService
        .getListOutputAssets(this.product.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (value: latestOutputAssets[]) => {
            this.listOutputAssets = [];
            value.forEach((format) => {
              const assetData: any = [];
              format.configText =
                OrganizationsService.textConfigStills(format.stillFormat) +
                ' ' +
                OrganizationsService.textConfigVideos(format.videoFormat);
              format.outputAssets.forEach((asset) => {
                assetData.push({
                  ...asset,
                  selected: false,
                });
              }),
                this.listOutputAssets.push({
                  ...format,
                  assets: assetData,
                  selectedTouch: false,
                  quantitySelected: 0,
                });
            });
            this.stepperContent['outputAssets'] = this.parseStepContentData(
              this.listOutputAssets,
              true
            );
            this.listOutputAssets.forEach((p: any) => {
              this.displaySelectTouch[p.id] = this.checkDisplaySelectTouch(
                p.id
              );
            });

            this.selectedAllProduct = {
              postProduction: false,
              production: false,
              outputAssets: false,
            };
            this.selectedProducts = {
              postProduction: 0,
              production: 0,
              outputAssets: 0,
            };
            this.isLoading = false;
          },
        });
    }
  }

  textStillsOutputDownload(fileImage: StillOutputFormat) {
    return OrganizationsService.textConfigStills(fileImage);
  }
  textVideosOutputDownload(fileVideo: VideoOutputFormat) {
    return OrganizationsService.textConfigVideos(fileVideo);
  }

  getPreProductionData() {
    const settingConfig = [
      {
        label: 'Package',
        value: 'packageName',
        type: 'text',
        col: '3',
      },
      {
        label: 'Setting',
        value: 'settingName',
        type: 'text',
        col: '3',
      },
      {
        label: 'Width',
        value: 'width',
        type: 'number',
        col: '2',
      },
      {
        label: 'Depth',
        value: 'depth',
        type: 'number',
        col: '2',
      },
      {
        label: 'Height',
        value: 'height',
        type: 'number',
        col: '2',
      },
      {
        label: 'Lens',
        value: 'lens',
        type: 'text',
        col: '2',
      },
      {
        label: 'Weight',
        value: 'weight',
        type: 'text',
        col: '2',
      },
      {
        label: 'Ingestion Date',
        value: 'createdAt',
        type: 'date',
        col: '2',
      },
    ];
    const settingData = this.product.productTouches.sort(
      (a, b) => a.masterSuiteTouchIndex - b.masterSuiteTouchIndex
    );
    const settingVisible =
      this.product.productTouches && this.product.productTouches.length > 0;

    const preProductionContent = [
      {
        id: 'receivals',
        icon: 'receipt_long',
        label: 'Receivals',
        state: this.product.receivedCount > 0 ? 'Received' : 'Pending',
        showAdditional: this.product.productReceivals.length > 0,
        additional: this.product.productReceivals,
        additionalConfig: [
          {
            label: 'Container Code',
            value: 'containerId',
            type: 'text',
            col: '2',
          },
          {
            label: 'Condition',
            value: 'condition',
            type: 'text',
            col: '2',
          },
          {
            label: 'Date',
            value: 'createdAt',
            type: 'date',
            col: '2',
          },
        ],
      },
      {
        id: 'setting',
        icon: 'lightbulb',
        label: 'Settings',
        additional: [this.product],
        showAdditional: this.product.masterSuiteId !== null,
        additionalConfig: [
          {
            label: 'Master Suite',
            value: 'masterSuiteName',
            type: 'text',
            col: '2',
          },
          {
            label: 'Settings Variant',
            value: 'settingsVariantName',
            type: 'text',
            col: '2',
          },
          {
            label: 'Date',
            value: 'settingsDate',
            type: 'date',
            col: '2',
          },
        ],
        state:
          this.product.masterSuiteId != null &&
          this.product.settingsVariantId != null
            ? 'Applied'
            : this.product.settingsVariantId == null &&
              this.product.masterSuiteId != null
            ? 'Pending Settings'
            : 'Pending',
      },
      {
        id: 'ingestion',
        icon: 'dataset',
        label: 'Ingestion',
        showAdditional: settingVisible,
        additional: settingData,
        additionalConfig: settingConfig,
        state:
          this.product.ingestedTouchCount == this.product.expectedTouchCount &&
          this.product.expectedTouchCount != 0
            ? 'Ingested'
            : this.product.ingestedTouchCount > 0
            ? 'Partial'
            : 'Pending',
      },
    ];

    this.stepperContent.preProduction = preProductionContent;
    this.isLoading = false;
  }

  getProductDetail() {
    const productId = this.route.snapshot.paramMap.get('productId');
    if (productId) {
      this.productsService
        .detailsProduct(productId)
        .pipe(takeUntil(this.destroy$))
        .subscribe((product) => {
          this.product = product;
          if (product.productTouches) {
            this.product.productTouches.sort(
              (a, b) => a.masterSuiteTouchIndex - b.masterSuiteTouchIndex
            );
          }
          this.initialData();
        });
    }
  }

  getEnhancementVariant() {
    if (this.product.masterSuiteId) {
      this.masterSuiteService
        .getEnhancementVariantOfMasterSuite({
          MasterSuiteId: this.product.masterSuiteId,
        })
        .subscribe({
          next: (res) => {
            this.enhancementVariants = res.results;
            this.enhancementVariantIdSelected =
              this.product.masterSuiteEnhancementVariantId;
          },
        });
    }
  }

  updateRowHeight() {
    setTimeout(() => {
      if (!this.cardItemEl) {
        return;
      }
      this.rowHeight = `${this.cardItemEl.nativeElement.firstChild.offsetWidth}:${this.cardItemEl.nativeElement.firstChild.offsetHeight}`;
      this.isLoading = false;
    });
  }

  breakpointChanged() {
    switch (true) {
      case this.breakpointObserver.isMatched(Breakpoints.XLarge):
        this.colPerScreen = 5;
        break;

      case this.breakpointObserver.isMatched(Breakpoints.Large):
        this.colPerScreen = 4;
        break;

      case this.breakpointObserver.isMatched(Breakpoints.Medium):
        this.colPerScreen = 3;
        break;

      case this.breakpointObserver.isMatched(Breakpoints.Small):
        this.colPerScreen = 2;
        break;

      default:
        this.colPerScreen = 1;
        break;
    }
    this.updateRowHeight();
  }

  editCallback = (data: any) => {
    const productId = this.route.snapshot.paramMap.get('productId');
    const batchId = this.route.snapshot.paramMap.get('batchId');
    const { id, assetType, masterSuiteTouchAssetId, outputFormatId } = data;
    if (assetType === EAssetType.OUTPUT_ASSETS) {
      this.router.navigateByUrl(
        `batches/${batchId}/products/${productId}/productTouchAsset/${id}/masterSuiteTouchAsset/${masterSuiteTouchAssetId}/outputFormat/${outputFormatId}/${assetType}`
      );
    } else {
      this.router.navigateByUrl(
        `batches/${batchId}/products/${productId}/productTouchAsset/${id}/masterSuiteTouchAsset/${masterSuiteTouchAssetId}/${assetType}`
      );
    }
  };

  patchProductNotes(product: IProduct) {
    this.productsService.patchProductNotes(product).subscribe((result) => {
      if (result) {
        product.version = result.version;
      }
    });
  }

  onSelectedEnhancementVariant(event: any) {
    this.productsService
      .applyEnhancementVariantOnProducts({
        productIds: [this.product.id || ''],
        enhancementVariantId: this.enhancementVariantIdSelected || '',
        version: 0,
      })
      .subscribe({
        next: (res) => {
          console.log('test');
        },
      });
  }

  parseStepContentData(data: any[], outputAssets?: boolean) {
    if (outputAssets) {
      return data.map((format: any) => {
        const assets = this.parseAsset(format.outputAssets);
        return {
          ...format,
          assets: assets,
        };
      });
    }
    return data.map((touch: any) => {
      const assets = this.parseAsset(touch.assets);
      return {
        ...touch,
        assets: assets,
      };
    });
  }
  downloadMultiple() {
    const activeStepTmp =
      this.activeStep === 'production'
        ? 'production'
        : this.activeStep === 'postProduction'
        ? 'postProduction'
        : 'outputAssets';
    let count = 0;
    this.stepperContent[activeStepTmp].forEach((t: any) => {
      t.assets.forEach((asset: any, index: number) => {
        if (asset.selected) {
          setTimeout(() => {
            const tmp = asset.rawUri.split('?')[0].split('/');
            const fileName = tmp[tmp.length - 1].split('.');
            var download = `${fileName[0]}.${fileName[1]}`;
            if (this.activeStep == 'postProduction') {
              download = `${fileName[0]}_${asset.index / 1000 + 1}.${
                fileName[1]
              }`;
            }
            this.productsService
              .downloadSingleImage(asset.rawUri.split('//')[1])
              .subscribe((res) => {
                const blod: Blob = res.body as Blob;
                const a = document.createElement('a');
                const fileData = window.URL.createObjectURL(blod);
                a.href = fileData;
                a.download = download;
                a.click();
                window.URL.revokeObjectURL(fileData);
              });
          }, (count += 3000));
        }
      });
    });
  }

  setAll(completed: boolean) {
    let count = 0;
    const activeStepTmp =
      this.activeStep === 'production'
        ? 'production'
        : this.activeStep === 'postProduction'
        ? 'postProduction'
        : 'outputAssets';
    this.selectedAllProduct[activeStepTmp] = completed;
    this.stepperContent[activeStepTmp].forEach((t: any) => {
      t.quantitySelected = 0;
      t.selectedTouch = completed;
      t.assets.forEach((asset: any) => {
        if (asset.thumbnailUri && !asset.childIndex && asset.id) {
          asset.selected = completed;
          if (completed) {
            count++;
            t.quantitySelected++;
          }
        }
      });
    });
    this.selectedProducts[activeStepTmp] = count;
  }

  selectedAllTouch(completed: boolean, touch: any) {
    const activeStepTmp =
      this.activeStep === 'production' ? 'production' : 'postProduction';
    this.stepperContent[activeStepTmp].forEach((t: any) => {
      if (t.id === touch.id) {
        t.selectedTouch = completed;
        t.assets.forEach((asset: any) => {
          if (asset.thumbnailUri && !asset.childIndex && asset.id) {
            asset.selected = completed;
          }
        });
      }
    });
    this.onCheckSelectAllProduct(activeStepTmp);
    this.quantity();
  }

  selectedCard(card: { touchId: string; data: any }) {
    const activeStepTmp =
      this.activeStep === 'production'
        ? 'production'
        : this.activeStep === 'postProduction'
        ? 'postProduction'
        : 'outputAssets';
    for (const touch of this.stepperContent[activeStepTmp]) {
      if (touch.id === card.touchId) {
        for (const asset of touch.assets) {
          if (!asset.selected && !asset.childIndex && asset.id) {
            touch.selectedTouch = false;
            this.onCheckSelectAllProduct(activeStepTmp);
            this.quantity();
            return;
          }
        }
        touch.selectedTouch = true;
      }
    }
    this.onCheckSelectAllProduct(activeStepTmp);
    this.quantity();
  }

  onCheckSelectAllProduct(
    active: 'production' | 'postProduction' | 'outputAssets'
  ) {
    for (const touch of this.stepperContent[active]) {
      if (!touch.selectedTouch) {
        this.selectedAllProduct[active] = false;
        return;
      }
    }
    this.selectedAllProduct[active] = true;
  }

  disableButtonDownload() {
    const activeStepTmp =
      this.activeStep === 'production'
        ? 'production'
        : this.activeStep === 'postProduction'
        ? 'postProduction'
        : 'outputAssets';
    for (const touch of this.stepperContent[activeStepTmp]) {
      for (const asset of touch.assets) {
        if (asset.selected) {
          return false;
        }
      }
    }
    return true;
  }

  checkDisplaySelectTouch(touchId: string) {
    const activeStepTmp =
      this.activeStep === 'production' ? 'production' : 'postProduction';
    for (const touch of this.stepperContent[activeStepTmp]) {
      if (touch.id === touchId) {
        for (const asset of touch.assets) {
          if (asset.id) {
            return true;
          }
        }
      }
    }
    return false;
  }

  quantity() {
    let count = 0;
    const activeStepTmp =
      this.activeStep === 'production'
        ? 'production'
        : this.activeStep === 'postProduction'
        ? 'postProduction'
        : 'outputAssets';
    for (const touch of this.stepperContent[activeStepTmp]) {
      touch.quantitySelected = 0;
      for (const asset of touch.assets) {
        if (asset.selected && !asset.childIndex && asset.id) {
          count++;
          asset.selected && touch.quantitySelected++;
        }
      }
    }
    this.selectedProducts[activeStepTmp] = count;
  }

  openSelectEnhancementVariant() {
    const dialogRef = this.dialog.open(MasterSuiteDialogComponent, {
      data: {
        masterSuiteId: this.product.masterSuiteId,
        masterSuiteName: this.product.masterSuiteName,
        productIds: [this.product.id],
        masterSuiteEnhancementVariantId: this.enhancementVariantIdSelected,
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res?.enhancementVariants?.length === 0) {
        const dialogRef0 = this.dialog.open(WarningModalComponent, {
          data: {
            header: 'Warning',
            content:
              'Master suite has no enhancement variants, would you like to create one?',
            titleButtonOk: 'Yes',
            showBtnNo: true,
          },
        });
        dialogRef0.afterClosed().subscribe((res) => {
          if (res) {
            this.router.navigate(
              [
                `mastersuites/${this.product.masterSuiteId}/update/enhancement-variant`,
              ],
              {
                queryParams: {
                  applyAll: true,
                },
              }
            );
          }
        });
      }
      if (res && !res?.enhancementVariants) {
        this.productsService
          .applyEnhancementVariantOnProducts({
            productIds: [this.product.id || ''],
            enhancementVariantId: res.enhancementVariantSelected.id,
            version: 0,
          })
          .subscribe(() => {
            this._alert.open(
              'Selected enhancement variant default successfully!',
              'close',
              {
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
                duration: 5000,
                panelClass: ['success'],
              }
            );
          });
      }
      if (
        res?.enhancementVariantSelected?.id !=
          this.product.masterSuiteEnhancementVariantId &&
        res != undefined &&
        res?.enhancementVariantSelected
      ) {
        this.enhancementVariantIdSelected = res?.enhancementVariantSelected?.id;
        this.product.masterSuiteEnhancementVariantId =
          this.enhancementVariantIdSelected;
        this.isLoading = true;
        this.getProductionData();
      }
    });
  }

  transferProductAsset(
    type: 'shootAssets' | 'postAssets',
    touchAssetId: string
  ) {
    let body: { shootAssetIds?: string[]; postAssetIds?: string[] } = {};
    const activeStepTmp =
      this.activeStep === 'production' ? 'production' : 'postProduction';

    this.stepperContent[activeStepTmp].forEach((t: any) => {
      if (t.id === touchAssetId) {
        t.assets.forEach((asset: any) => {
          if (asset.selected) {
            if (type === 'shootAssets') {
              body.shootAssetIds
                ? body.shootAssetIds.push(asset.id)
                : (body.shootAssetIds = [asset.id]);
            } else {
              body.postAssetIds
                ? body.postAssetIds.push(asset.id)
                : (body.postAssetIds = [asset.id]);
            }
          }
        });
      }
    });
    this.productsService
      .transferAssetPlaceholder(body, type)
      .subscribe((res) => {
        this._alert.open('Transfer successfully!', 'close', {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 3000,
          panelClass: ['success'],
        });
      });
  }

  productDetailsGroup: string = '';
  latestAssetsGroup: string = '';
  joinDetailsGroups() {
    if (this.isClient) return;
    this.signalrService
      .joinIdGroup('JoinProductDetailsGroup', this.product.id!)
      ?.then((group: string) => (this.productDetailsGroup = group));
  }

  joinLatestAssetsGroup(group: string) {
    if (this.isClient) return;
    this.signalrService
      .joinIdGroup(group, this.product.id!)
      ?.then((group: string) => (this.latestAssetsGroup = group));
  }

  subscribeToData() {
    if (this.isClient) return;
    this.signalrService.productsData.pipe(takeUntil(this.destroy$)).subscribe({
      next: (data: any) => {
        if (data.id) {
          //this.product = data;
          //this.getPreProductionData();
        }
      },
    });

    this.signalrService.postAssetsData
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: any) => {
          if (data.id && this.activeStep == 'postProduction') {
            console.log(this.stepperContent['postProduction']);
            var index = this.stepperContent[
              'postProduction'
            ][0].assets.findIndex(
              (a: IAsset) =>
                a.masterSuiteTouchAssetId == data.masterSuiteTouchAssetId
            );
            if (index >= 0) {
              const assetData: any = [];
              assetData.push({
                ...data,
              });
              const asset = this.parseAsset(assetData)[0];
              if (
                this.stepperContent['postProduction'][0].assets[index].id ==
                asset.id
              ) {
                this.stepperContent['postProduction'][0].assets[
                  index
                ].clientReviewState = asset.clientReviewState;
                this.stepperContent['postProduction'][0].assets[
                  index
                ].internalReviewState = asset.internalReviewState;
              } else {
                this.stepperContent['postProduction'][0].assets[index] = asset;
              }
            }
          }
        },
      });

    this.signalrService.shootAssetsData
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: IAsset) => {
          var touchIndex = this.stepperContent['production'].findIndex(
            (x: any) => x.id == data.masterSuiteTouchId
          );
          if (touchIndex >= 0) {
            const assetData: any = [];
            assetData.push({
              ...data,
            });
            if (data.copyAssets != null) {
              data.copyAssets.forEach((copyAsset, index) => {
                assetData.push({
                  ...data,
                  ...copyAsset,
                  childIndex: index + 1,
                });
              });
            }
            const assets = this.parseAsset(assetData);
            assets.forEach((asset: any) => {
              var index = this.stepperContent['production'][
                touchIndex
              ].assets.findIndex(
                (a: any) =>
                  a.masterSuiteTouchAssetId == asset.masterSuiteTouchAssetId
              );
              if (index >= 0) {
                if (
                  this.stepperContent['production'][touchIndex].assets[index]
                    .id == asset.id
                ) {
                  this.stepperContent['production'][touchIndex].assets[
                    index
                  ].status = this.isClient
                    ? asset.clientReviewState
                    : asset.internalReviewState;
                } else {
                  this.stepperContent['production'][touchIndex].assets[index] =
                    asset;
                }
              }
            });
          }
        },
      });
  }

  syncProductAssets() {
    this.dialog.open(GenerateShopifyAssetsSyncComponent, {
      data: {
        organizationId: this.product.organizationId,
        batchId: this.product.batchId,
        isBatch: false,
        productIds: [this.product.id],
      },
    });
  }

  generateOutputAssets() {
    this.dialog.open(GenerateOutputsComponent, {
      data: {
        organizationId: this.product.organizationId,
        batchId: this.product.batchId,
        isBatch: false,
        productIds: [this.product.id],
      },
    });
  }

  openProductLink() {
    window.open(this.product.productLink, '_blank');
  }

  openProductUpdateDialog(product: IProduct) {
    const dialogRef = this.dialog.open(ProductUpdateComponent, {
      data: product,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  public onStepSelected(id: string) {
    this.activeStepLabel = this.getStepperLabel(id);
    this.navigationUpdate(this.activeStepLabel);
  }

  private navigationUpdate(activeStepLabel: string) {
    console.log(`this.activeStepLabel: ${activeStepLabel}`);

    this.activeStep = this.getStepperId(activeStepLabel);
    console.log(`this.activeStep: ${this.activeStep}`);
    this.pageUpdate(this.activeStep);
    console.log(`this.isClient: ${this.isClient}`);

    if (!this.isClient) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { stage: this.activeStepLabel },
        queryParamsHandling: 'merge',
      });
    }
    this.pageLink = this.updatePageLink();
  }

  private getExistingQueryParam(): string {
    let currentQueryParam: string = '';
    this.route.queryParams.subscribe((params) => {
      currentQueryParam = params['stage'] ? params['stage'] : '';
      return currentQueryParam;
    });
    return currentQueryParam;
  }

  private getStepperId(queryLabel: string): string {
    const foundIdentifiers = this.stepperConfig.filter((identifier) => {
      const label: string = identifier.label;
      return label === queryLabel;
    });

    const labelFound: boolean = foundIdentifiers.length === 1;
    const foundIdLabel = foundIdentifiers[0];

    const stepperId: string = labelFound ? foundIdLabel.id : '';

    return stepperId;
  }

  private getStepperLabel(id: string): string {
    const foundIdentifiers = this.stepperConfig.filter((identifier) => {
      const stepperConfigId: string = identifier.id;
      return id === stepperConfigId;
    });

    const labelFound: boolean = foundIdentifiers.length === 1;
    const foundIdLabel = foundIdentifiers[0];
    const stepperLabel: string = labelFound ? foundIdLabel.label : '';
    console.log(`getStepperLabel labelFound : ${labelFound}`);

    return stepperLabel;
  }

  private updatePageLink(): string {
    const identifier: string = this.product.barcode
      ? this.product.barcode
      : this.product.reference;
    const clientPageLink: string = `${identifier}`;
    const internalPageLink: string = `${identifier} - ${this.activeStepLabel}`;
    const pageLink = this.isClient ? clientPageLink : internalPageLink;
    return pageLink;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.signalrService.leaveGroup(this.productDetailsGroup);
    this.signalrService.leaveGroup(this.latestAssetsGroup);
  }
}
