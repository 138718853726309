import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { EStateAsset, IAsset } from 'src/app/models/products/asset';
import { IPreference } from 'src/app/models/users/userProfile';
import { AuthService } from 'src/app/services/auth.service';
import { ProductsService } from 'src/app/services/products.service';
import { environment } from 'src/environments/environment';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-qc-card',
  templateUrl: './qc-card.component.html',
  styleUrls: ['./qc-card.component.scss'],
})
export class QcCardComponent {
  @Input() showExternalStatus!: boolean;
  @Input() showParentInfo!: boolean;
  @Input() isShootAsset!: any;
  @Input() data!: any;
  @Input() touchId: string = '';
  @Output() done = new EventEmitter<any>();
  @Output() editCallback = new EventEmitter();
  @Output() openImageCallback = new EventEmitter();
  @Output() reviewCallback = new EventEmitter();
  isImageLoaded = false;
  isStaff: boolean = false;
  EStateAsset = EStateAsset;
  statusMapping: any = {
    [EStateAsset.APPROVED]: 'Approved',
    [EStateAsset.REJECTED]: 'Rejected',
  };
  apiBase = environment.apiBase;
  currentConfigDate!: IPreference;

  constructor(
    private productsService: ProductsService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.isStaff = this.authService.getRole('staff');
    this.authService.currentPreference.subscribe((res) => {
      this.currentConfigDate = res;
    });
  }

  ngAfterContentInit(): void {
    this.done.emit();
  }

  onEdit() {
    this.editCallback.emit({
      id: this.data.productTouchAssetId,
      isShootAsset: this.isShootAsset,
      masterSuiteTouchAssetId: this.data.masterSuiteTouchAssetId,
    });
  }

  open() {
    this.openImageCallback.emit(this.data);
  }

  onReview(state: EStateAsset | null) {
    this.reviewCallback.emit(state);
  }

  checkImageLoaded() {
    this.isImageLoaded = true;
  }

  getLinkDownload() {
    const tmp = this.data.rawUri!.split('/');
    const fileName = tmp[tmp.length - 1].split('.');
    var download = `${fileName[0]}.${fileName[1]}`;
    if (!this.isShootAsset) {
      download = `${fileName[0]}_${this.data.index / 1000 + 1}.${fileName[1]}`;
    } else if (this.isShootAsset && this.data.enhancementFlag) {
      download = `${fileName[0]}_${this.data.enhancementIndex! / 1000 + 1}.${
        fileName[1]
      }`;
    }
    this.productsService
      .downloadSingleImage(this.data.rawUri!.split('//')[1])
      .subscribe((res) => {
        const blod: Blob = res.body as Blob;
        const a = document.createElement('a');
        const fileData = window.URL.createObjectURL(blod);
        a.href = fileData;
        a.download = download;
        a.click();
        window.URL.revokeObjectURL(fileData);
      });
  }

  getLinkImage() {
    return  ProductsService.getLinkImage(this.data.thumbnailUri);
  }
}
