<div class="mater-suite-title">
  <h1 class="title">{{ this.dataDialog.masterSuiteName }}</h1>
  <mat-spinner *ngIf="isLoading" class="spinner"></mat-spinner>
</div>
<mat-dialog-content class="mater-suite-model">
  <mat-radio-group
    aria-label="Select an option"
    [(ngModel)]="settingsVariantSelected"
    *ngIf="!isLoading"
  >
    <div
      class="mater-suite-item"
      *ngFor="let settingsVariant of settingsVariants; index as i"
    >
      <mat-radio-button [value]="settingsVariant">{{
        settingsVariant.name
      }}</mat-radio-button>
      <button mat-icon-button (click)="openMasterSuite()">
        <mat-icon color="info">info</mat-icon>
      </button>
    </div>
  </mat-radio-group>
</mat-dialog-content>
<mat-dialog-actions align="end" class="action">
  <button mat-stroked-button matDialogClose>close</button>
  <button
    mat-stroked-button
    color="success"
    class="save"
    cdkFocusInitial
    (click)="onSave()"
    [disabled]="!settingsVariantSelected"
  >
    Save
  </button>
</mat-dialog-actions>
