import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DeleteModalComponent } from 'src/app/components/shared/delete-modal/delete-modal.component';
import { ProductReceival } from 'src/app/models/products/actions/receive/productReceival';
import { IProduct } from 'src/app/models/products/product';
import { ValidationErrors } from 'src/app/models/validationErrors';
import { ProductsService } from 'src/app/services/products.service';
import { Subject, takeUntil } from 'rxjs';
import { IPreference } from 'src/app/models/users/userProfile';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-product-receivals',
  templateUrl: './product-receivals.component.html',
  styleUrls: ['./product-receivals.component.scss'],
})
export class ProductReceivalsComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  product!: IProduct;
  constructor(
    private router: Router,
    private productsService: ProductsService,
    private _alert: MatSnackBar,
    public dialog: MatDialog,
    public authService: AuthService
  ) {
    this.initialData();
  }

  productReceivalsColumns: string[] = [
    'containerId',
    'condition',
    'quantity',
    'createdAt',
    'Actions',
    'delete',
  ];
  productsReceivedDataSource = new MatTableDataSource<ProductReceival>();
  errorMessages: string[] = [];
  currentConfigDate!: IPreference;
  initialData() {
    const navigation = this.router.getCurrentNavigation();
    const state = navigation!.extras.state as { product: IProduct };
    this.product = state.product;
  }

  ngOnInit(): void {
    this.authService.currentPreference.subscribe((res) => {
      this.currentConfigDate = res;
    });
    this.productsReceivedDataSource = new MatTableDataSource<ProductReceival>(
      this.product.productReceivals
    );
  }

  /* SUMMARY
  updates product Receival
  */
  updateProductReceival(productReceival: ProductReceival) {
    if (productReceival != null) {
      this.productsService
        .updateProductReceival(productReceival)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data) => {
            console.log(data);
            this._alert.open('Successfully Updated', 'close', {
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
              duration: 2000,
              panelClass: ['success'],
            });
          },
          (error) => {
            console.log(error);
            error.error.validationErrors.forEach((error: ValidationErrors) => {
              this.errorMessages.push(error.message);
            });
            this._alert.open('Successfully Updated', 'close', {
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
              duration: 2000,
              panelClass: ['success'],
            });
          }
        );
    }
  }

  getDetailProduct() {
    this.productsService
      .detailsProduct(this.product.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (product) => {
          if (product) {
            this.product = product;
            this.productsReceivedDataSource.data =
              this.product.productReceivals;
          }
        },
        (error) => {
          console.log(error.message);
        }
      );
  }

  onDelete(productReceival: ProductReceival) {
    this.productsService
      .deleteProductReceival(productReceival)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          this._alert.open('Successfully Deleted', 'close', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 2000,
            panelClass: ['success'],
          });

          this.getDetailProduct();
        },
        (error) => {
          console.log(error);
        }
      );
  }

  deleteProductReceival(productReceival: ProductReceival) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      data: {
        header: 'Delete Recevial',
        message:
          'Are you sure you want to delete this product receival for ' +
          this.product.barcode +
          '?',
      },
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.onDelete(productReceival);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
