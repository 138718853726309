<mat-expansion-panel
  class="product-progress"
  expanded="true"
  hideToggle
  disabled
>
  <div class="product-progress__content">
    <ng-container *ngFor="let item of config; let i = index">
      <div
        *ngIf="i !== 0 && !item.hidden"
        class="product-progress__spacer"
        [ngClass]="{ active: indexActive >= i }"
      ></div>
      <div
        *ngIf="!item.hidden"
        class="product-progress__step"
        [ngClass]="{ active: activeStep === item.id, disabled: indexActive < i }"
        (click)="onChange(item.id)"
      >
        <div
          class="product-progress__step-icon"
          [ngClass]="{ active: activeStep === item.id }"
        >
          <div>{{ i + 1 }}</div>
        </div>
        <h3>{{ item.label }}</h3>
      </div>
    </ng-container>
  </div>
</mat-expansion-panel>
