import { Component, EventEmitter, Input, Output, output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject, takeUntil } from 'rxjs';
import { IMasterSuiteTouchAsset } from 'src/app/models/mastersuites/mastersuite';
import { MasterSuitesService } from 'src/app/services/mastersuites.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mastersuite-touch-asset-card',
  templateUrl: './mastersuite-touch-asset-card.component.html',
  styleUrl: './mastersuite-touch-asset-card.component.scss',
})
export class MastersuiteTouchAssetCardComponent {
  @Input() data!: IMasterSuiteTouchAsset;
  @Input() readonly = true;
  @Output() remove = new EventEmitter<any>();
  @Output() add = new EventEmitter<any>();
  @Output() placeholder = new EventEmitter<any>();

  private destroy$ = new Subject();
  constructor(
    private masterSuitesService: MasterSuitesService,
    private _alert: MatSnackBar,
    public dialog: MatDialog
  ) {}

  isImageLoaded = false;
  apiBase = environment.apiBase;

  getLinkImage() {
    if (this.data.shootAssetPlaceholderThumbnailUri?.includes('https')) {
      return this.data.shootAssetPlaceholderThumbnailUri;
    }
    return environment.apiBase + this.data.shootAssetPlaceholderThumbnailUri;
  }

  checkImageLoaded() {
    this.isImageLoaded = true;
  }

  shootPlaceholderChange(
    fileInputEvent: FileList,
    masterSutieTouchAsset: IMasterSuiteTouchAsset,
    index: number,
    copyIndex?: number
  ) {
    var file = fileInputEvent[0];
    this.masterSuitesService
      .masterSuiteTouchAssetShootPlaceholderUpload(
        masterSutieTouchAsset.id!,
        file
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((touchAsset: IMasterSuiteTouchAsset) => {
        if (touchAsset) {
          masterSutieTouchAsset.shootAssetPlaceholderRawUri = null;
          masterSutieTouchAsset.shootAssetPlaceholderReviewUri = null;
          masterSutieTouchAsset.shootAssetPlaceholderThumbnailUri = null;

          masterSutieTouchAsset.shootAssetPlaceholderRawUri =
            touchAsset.shootAssetPlaceholderRawUri;
          masterSutieTouchAsset.shootAssetPlaceholderReviewUri =
            touchAsset.shootAssetPlaceholderReviewUri;
          masterSutieTouchAsset.shootAssetPlaceholderThumbnailUri =
            touchAsset.shootAssetPlaceholderThumbnailUri;
          masterSutieTouchAsset.version = touchAsset.version;
          this._alert.open(
            `Uploaded ${
              (masterSutieTouchAsset.packageAssetType === 'Still'
                ? 'Angel'
                : 'Video') + index
            }${copyIndex ? '.' + copyIndex : ''} successfully!`,
            'close',
            {
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
              duration: 2000,
              panelClass: ['success'],
            }
          );
        }
      });
  }
}
