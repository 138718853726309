import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class DownloadOutputService {
  constructor(private http: HttpClient) {}

  downloadOutputProducts(data: {
    outputFormatIds: string[];
    productIds: string[];
  }) {
    const url = `${environment.apiBase}/products/actions/download-output`;
    return this.http.post<any>(url, data, httpOptions);
  }
  downloadOutputBatch(batchId: string, outputFormatIds: string[]) {
    const url = `${environment.apiBase}/batches/${batchId}/download-output`;
    return this.http.post<any>(url, { outputFormatIds }, httpOptions);
  }

  generateOutputAssets(data: {
    productIds: string[];
    overwrite: boolean;
    outputFormats: {
      outputFormatId: string;
      type: string;
    }[];
  }) {
    const url = `${environment.apiBase}/products/generate-output-assets`;
    return this.http.post<any>(url, data, httpOptions);
  }
}
