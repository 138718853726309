<div class="content-list content enhancement-variant-create">
  <div class="content-list__head">
    <div class="content-header">
      <div class="title">
        Enhancement Variant {{ action === "create" ? "Creation" : "Update" }}
      </div>
      <span class="spacer"></span>
      <h4 class="router-link" (click)="onBack()">Back to List</h4>
      <button
        *ngIf="action === 'update'"
        [disabled]="!readonly"
        mat-icon-button
        matTooltip="Edit"
        [color]="readonly ? 'success' : 'disabled'"
        class="mat-elevation-z0"
        (click)="readonly = false"
      >
        <mat-icon>edit</mat-icon>
      </button>
    </div>
  </div>
  <div
    class="enhancement-variant-create__body"
    [formGroup]="enhancementVariantForm"
  >
    <mat-form-field appearance="outline" style="width: 100%">
      <mat-label>Enhancement Variant Name</mat-label>
      <input
        [readonly]="readonly && action == 'update'"
        matInput
        [formControlName]="'name'"
        autofocus
      />
      <mat-error
        *ngIf="enhancementVariantForm.get('name')?.hasError('required')"
        >Enhancement Variant Name is required</mat-error
      >
      <mat-error
        *ngIf="enhancementVariantForm.get('name')?.hasError('sameName')"
      >
        Enhancement Variant Name must be unique in a Master Suite.
      </mat-error>
    </mat-form-field>

    <div class="sub-content">
      <div
        class="content__tab"
        style="--readonly: {{ readonly ? '36px' : '0px' }}"
      >
        <h1 class="title">Selects</h1>

        <div class="list" cdkDropListGroup id="list">
          <div
            cdkDropList
            (cdkDropListEntered)="enter($event)"
            (cdkDropListDropped)="drop()"
            #placeholder
          ></div>
          <div
            cdkDropList
            *ngFor="let asset of masterSuiteTouchAssetSelected; let j = index"
            (cdkDropListEntered)="enter($event)"
            (cdkDropListDropped)="drop()"
          >
            <div cdkDrag [cdkDragDisabled]="readonly">
              <div class="box" [style]="{ width: checkWidth() }">
                <div *ngIf="!readonly" class="handle" cdkDragHandle>
                  <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"
                    ></path>
                    <path d="M0 0h24v24H0z" fill="none"></path>
                  </svg>
                </div>

                <!-- Display first previous row -->
                <app-selected-asset-card
                  [data]="asset"
                  [readonly]="readonly"
                  select="Select {{ j + 1 }}"
                  (remove)="unSelectAsset(asset)"
                >
                </app-selected-asset-card>
              </div>
            </div>
          </div>
        </div>

        <h1 class="title">Available Assets</h1>

        <div class="available-assets" *ngIf="loaded">
          <ng-container
            *ngFor="let touch of masterSuite.masterSuiteTouches; let t = index"
          >
            <div class="touch">
              <h3 class="touch-title">Touch {{ t + 1 }}</h3>
            </div>

            <div
              class="asset-list"
              [ngStyle]="{
                'grid-template-columns':
                  'repeat(' + colPerScreen + ',minmax(0,1fr))'
              }"
            >
              <div *ngFor="let asset of parseAssets(touch); let j = index">
                <app-available-asset-card
                  [data]="asset"
                  [readonly]="readonly"
                  [selected]="checkSelect(asset)"
                  (selectionChange)="onSelectMasterSuite($event, asset, t)"
                >
                </app-available-asset-card>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="content-actions">
        <span class="spacer"></span>
        <button
          mat-stroked-button
          (click)="onCancel()"
          *ngIf="action === 'create' || !readonly"
        >
          Cancel
        </button>
        <button
          mat-stroked-button
          color="buttonSuccess"
          style="margin-left: 20px"
          (click)="create()"
          *ngIf="action === 'create'"
        >
          Create
        </button>
        <button
          mat-stroked-button
          color="buttonSuccess"
          style="margin-left: 20px"
          (click)="update()"
          *ngIf="action === 'update' && !readonly"
        >
          Update
        </button>
      </div>
    </div>

    <ng-template #noId>
      <button mat-raised-button color="accent" disabled="true" class="asset">
        Please Save EV to Upload Asset
      </button>
    </ng-template>
  </div>
</div>
