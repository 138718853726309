<div class="set-list">
  <mat-chip-listbox
    (resized)="breakpoints()"
    hideSingleSelectionIndicator
    (change)="change($event.value)"
    id="list"
  >
    <mat-chip-option
      *ngFor="let chip of chipsList"
      class="chip-option"
      [value]="chip.key"
      [selected]="chip.key == selectedChipKey"
      [disabled]="loading"
    >
      <div style="display: flex; align-items: center">
        {{ chip.value }}
        <mat-icon style="scale: 0.7">{{ chip.icon }}</mat-icon>
      </div>
    </mat-chip-option>
    <mat-chip-option
      *ngIf="chips.length > columns"
      class="chip-option"
      style="--width-var: 85px"
      [selectable]="false"
      [disabled]="loading"
      [matMenuTriggerFor]="seeMore"
      [value]="null"
    >
      +{{ this.chips.length - columns }} More
    </mat-chip-option>
  </mat-chip-listbox>

  <mat-menu #seeMore="matMenu">
    <div class="see-more-menu">
      <mat-chip-listbox
        (resized)="breakpoints()"
        hideSingleSelectionIndicator
        (change)="change($event.value)"
      >
        <mat-chip-option
          *ngFor="let chip of chipsSubList"
          [value]="chip.key"
          class="chip-option"
          style="margin-left: 12px"
          [selected]="chip.key == selectedChipKey"
          [disabled]="loading"
        >
          <div style="display: flex; align-items: center">
            {{ chip.value }}
            <mat-icon style="scale: 0.7">{{ chip.icon }}</mat-icon>
          </div>
        </mat-chip-option>
      </mat-chip-listbox>
    </div>
  </mat-menu>
</div>
