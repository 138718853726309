import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forEach } from 'lodash';
import { Subject, takeUntil } from 'rxjs';
import {
  EAssetType,
  EStateAsset,
  IAsset,
  IAssetDetailResponse,
  IMasterSuiteProjection,
  IProductProjection,
} from 'src/app/models/products/asset';
import {
  IGroupedProjection,
  ILatestAssetResult,
} from 'src/app/models/products/latestResults';
import { ProductsService } from 'src/app/services/products.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ReviewModalComponent } from 'src/app/components/shared/review-modal/review-modal.component';
import { IProduct } from 'src/app/models/products/product';

@Component({
  selector: 'app-products-qc-view',
  templateUrl: './products-qc-view.component.html',
  styleUrls: ['./products-qc-view.component.scss'],
})
export class ProductsQcViewComponent implements OnInit {
  @Input() batchId!: string;
  @Input() products!: IProduct[];
  @Input() type!: string;
  @ViewChild('pdList') pdList!: ElementRef;
  private destroy$ = new Subject();
  constructor(
    private productService: ProductsService,
    public breakpointObserver: BreakpointObserver,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private _alert: MatSnackBar
  ) {}

  EStateAsset = EStateAsset;
  dataSource: ILatestAssetResult[] = [];
  previousProduct!: IAssetDetailResponse;
  pageNumber: number = 1;
  totalCount: number = 0;

  ngOnInit(): void {
    this.GetAssets();
  }

  GetAssets() {
    var ids = this.products.map((x) => x.id!);
    if (this.type == 'postAssets') {
      this.productService
        .getViewLatestPostAssets(this.batchId, ids, this.pageNumber, 10)
        .subscribe((res) => {
          this.totalCount = res.total;
          this.parsePostList(res.results);
          this.loadingPage = false;
        });
    } else {
      this.productService
        .getViewLatestShootAssets(this.batchId, ids, this.pageNumber, 10)
        .subscribe((res) => {
          this.totalCount = res.total;
          this.parseShootList(res.results);
          this.loadingPage = false;
        });
    }
  }

  maxWidth: number = 400;
  parsePostList(result: IAssetDetailResponse[]) {
    result.forEach((product) => {
      product.postAssets = this.parseAssets(product.postAssets!);
      if (product.postAssets.length * 400 > this.maxWidth) {
        this.maxWidth = product.postAssets.length * 400 - 20;
      }
      if (this.previousProduct == undefined) {
        let newEV: IGroupedProjection = {
          id: product.enhancementVariantId!,
          name: product.enhancementVariantName!,
          products: [],
        };
        newEV.products.push(product);
        let newMS: ILatestAssetResult = {
          id: product.masterSuiteId!,
          name: product.masterSuiteName!,
          grouped: [],
        };
        newMS.grouped.push(newEV);
        this.dataSource.push(newMS);
        this.previousProduct = product;
      } else if (
        product.enhancementVariantId ==
        this.previousProduct.enhancementVariantId
      ) {
        this.dataSource[this.dataSource.length - 1].grouped[
          this.dataSource[this.dataSource.length - 1].grouped.length - 1
        ].products.push(product);
        this.previousProduct = product;
      } else if (product.masterSuiteId == this.previousProduct.masterSuiteId) {
        let newEV: IGroupedProjection = {
          id: product.enhancementVariantId!,
          name: product.enhancementVariantName!,
          products: [],
        };
        newEV.products.push(product);
        this.dataSource[this.dataSource.length - 1].grouped.push(newEV);
        this.previousProduct = product;
      } else {
        let newEV: IGroupedProjection = {
          id: product.enhancementVariantId!,
          name: product.enhancementVariantName!,
          products: [],
        };
        newEV.products.push(product);
        let newMS: ILatestAssetResult = {
          id: product.masterSuiteId!,
          name: product.masterSuiteName!,
          grouped: [],
        };
        newMS.grouped.push(newEV);
        this.dataSource.push(newMS);
        this.previousProduct = product;
      }
    });
    console.log(this.maxWidth);
  }

  productShootAssetsResult: IMasterSuiteProjection[] = [];
  parseShootList(result: IMasterSuiteProjection[]) {
    result.forEach((ms) => {
      ms.products.forEach((product) => {
        product.touches.forEach((touch) => {
          touch.shootAssets = this.parseAssets(touch.shootAssets!);
          if (touch.shootAssets.length * 400 > this.maxWidth) {
            this.maxWidth = touch.shootAssets.length * 400 - 20;
          }
        });
      });
      if (this.productShootAssetsResult.length == 0) {
        this.productShootAssetsResult.push(ms);
      } else if (
        ms.masterSuiteId ==
        this.productShootAssetsResult[this.productShootAssetsResult.length - 1]
          .masterSuiteId
      ) {
        this.productShootAssetsResult[
          this.productShootAssetsResult.length - 1
        ].products = this.productShootAssetsResult[
          this.productShootAssetsResult.length - 1
        ].products.concat(ms.products);
      } else {
        this.productShootAssetsResult.push(ms);
      }
    });
    console.log(this.maxWidth);
  }

  parseAssets(assets: IAsset[]) {
    let angleIndex = 1;
    let videoIndex = 1;
    return assets.map((asset: IAsset) => {
      let label = `${asset.type === 'Still' ? 'Angle' : 'Video'} `;
      console.log(label);
      let subLabel = label;
      if (asset.type === 'Still') {
        label += angleIndex;
        angleIndex++;
      } else {
        label += videoIndex;
        videoIndex++;
      }
      if (this.type == 'postAssets') {
        label = `Select ${asset.selectsIndex / 1000 + 1}`;
      }
      return {
        ...asset,
        label,
        subLabel: subLabel,
      };
    });
  }

  editCallback(data: any, productId: string) {
    console.log(data);
    const batchId = this.route.snapshot.paramMap.get('batchId');
    const { id, isShootAsset, masterSuiteTouchAssetId } = data;
    const url = this.router.serializeUrl(
      this.router.createUrlTree([
        `batches/${batchId}/products/${productId}/productTouchAsset/${id}/masterSuiteTouchAsset/${masterSuiteTouchAssetId}/${
          isShootAsset ? EAssetType.SHOOT_ASSETS : EAssetType.POST_ASSETS
        }`,
      ])
    );
    window.open(url, '_blank');
  }

  openImageCallback(
    data: any,
    productId: string,
    msIndex: number,
    groupIndex: number,
    itemIndex: number,
    assetIndex: number
  ) {
    const dialogRef = this.dialog.open(ReviewModalComponent, {
      data: {
        asset: data,
        type: this.type,
      },
    });
    dialogRef.componentInstance.openReviewClicked.subscribe((result) => {
      result.isShootAsset = this.type == 'shootAssets';
      this.editCallback(result, productId);
    });
    dialogRef.componentInstance.reviewClicked.subscribe((result) => {
      this.reviewCallback(result.state, result.asset);
    });

    dialogRef.componentInstance.nextAssetClicked.subscribe((result) => {
      let length = 0;
      if (this.type != 'shootAssets') {
        length =
          this.dataSource[msIndex].grouped[groupIndex].products[itemIndex]
            .postAssets?.length!;
      } else {
        length =
          this.productShootAssetsResult[msIndex].products[groupIndex].touches[
            itemIndex
          ].shootAssets?.length!;
      }

      if (assetIndex >= length - 1) {
        assetIndex = 0;
      } else {
        assetIndex++;
      }
      if (this.type != 'shootAssets') {
        while (
          this.dataSource[msIndex].grouped[groupIndex].products[itemIndex]
            .postAssets![assetIndex].id == null
        ) {
          if (assetIndex >= length - 1) {
            assetIndex = 0;
          } else {
            assetIndex++;
          }
        }
        let data = {
          asset:
            this.dataSource[msIndex].grouped[groupIndex].products[itemIndex]
              .postAssets![assetIndex],
          type: this.type,
        };
        dialogRef.componentInstance.initialData(data);
      } else {
        while (
          this.productShootAssetsResult[msIndex].products[groupIndex].touches[
            itemIndex
          ].shootAssets![assetIndex].id == null
        ) {
          if (assetIndex >= length - 1) {
            assetIndex = 0;
          } else {
            assetIndex++;
          }
        }
        let data = {
          asset:
            this.productShootAssetsResult[msIndex].products[groupIndex].touches[
              itemIndex
            ].shootAssets![assetIndex],
          type: this.type,
        };
        dialogRef.componentInstance.initialData(data);
      }
    });

    dialogRef.componentInstance.previousAssetClicked.subscribe((result) => {
      let length = 0;
      if (this.type != 'shootAssets') {
        length =
          this.dataSource[msIndex].grouped[groupIndex].products[itemIndex]
            .postAssets?.length!;
      } else {
        length =
          this.productShootAssetsResult[msIndex].products[groupIndex].touches[
            itemIndex
          ].shootAssets?.length!;
      }
      if (assetIndex == 0) {
        assetIndex = length - 1;
      } else {
        assetIndex--;
      }
      if (this.type != 'shootAssets') {
        let data = {
          asset:
            this.dataSource[msIndex].grouped[groupIndex].products[itemIndex]
              .postAssets![assetIndex],
          type: this.type,
        };
        dialogRef.componentInstance.initialData(data);
      } else {
        let data = {
          asset:
            this.productShootAssetsResult[msIndex].products[groupIndex].touches[
              itemIndex
            ].shootAssets![assetIndex],
          type: this.type,
        };
        dialogRef.componentInstance.initialData(data);
      }
    });
  }

  onCheckLoad() {
    if (this.pageNumber * 10 < this.totalCount) {
      this.pageNumber++;
      this.GetAssets();
    }
  }

  loadingPage: boolean = false;
  onScroll(event: any) {
    console.log(
      this.pdList.nativeElement.clientHeight + event.srcElement.scrollTop
    );
    console.log(event.srcElement.scrollHeight);
    if (
      event.srcElement.scrollHeight - 600 <=
        this.pdList.nativeElement.clientHeight + event.srcElement.scrollTop &&
      !this.loadingPage
    ) {
      this.loadingPage = true;
      this.onCheckLoad();
    }
  }

  reviewCallback(state: EStateAsset, asset: any) {
    switch (state) {
      case EStateAsset.APPROVED:
        this.onReview(asset, state);
        break;
      case EStateAsset.REJECTED:
        this.onReview(asset, state);
        break;
      default:
        this.onReview(asset, null);
        break;
    }
  }

  onReview(asset: any, state: EStateAsset | null) {
    if (this.type == 'shootAssets') {
      this.productService
        .reviewShootAsset(asset.id, state)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            if (this.type == 'shootAssets') {
              asset.internalReviewState = state;
            } else {
              asset.clientReviewState = state;
            }
          },
          error: (e) => {
            this.sendAlert(
              e?.error?.validationErrors[0]?.message,
              3000,
              'error'
            );
          },
        });
    } else {
      this.productService
        .reviewPostAsset(asset.id, false, state)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            asset.clientReviewState = state;
          },
          error: (e) => {
            this.sendAlert(
              e?.error?.validationErrors[0]?.message,
              3000,
              'error'
            );
          },
        });
    }
  }

  sendAlert(message: string, duration: number, alertType: string): void {
    this._alert.open(message, 'close', {
      duration: duration,
      horizontalPosition: 'center',
      panelClass: [alertType],
    });
  }
}
