import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(public alert: MatSnackBar) {}

  successToast(message: string) {
    this.alert.open(message, 'close', {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: ['success'],
      duration: 5000,
    });
  }

  errorToast(message: string) {
    this.alert.open(message, 'close', {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: ['error'],
      duration: 10000,
    });
  }

  infoToast(message: string) {
    this.alert.open(message, '', {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      duration: 3000,
      panelClass: ['info'],
    });
  }
}
