<mat-card class="product-search content">
  <div *ngIf="!preDefinedFilter" class="product-search__head">
    <div class="product-search__title">
      <h1 class="title">
        {{ keyword }}
      </h1>
    </div>
    <span class="spacer"></span>
    <div class="product-search__count">
      {{ paging.total }} item{{ paging.total === 1 ? "" : "s" }}
    </div>
  </div>
  <div *ngIf="preDefinedFilter" class="product-search__head">
    <div class="product-search__title" style="width: 300px">
      <h1 class="title">Global Filter Search</h1>
    </div>
    <span class="spacer"></span>
    <app-dynamic-chip-set
      *ngIf="isStaff"
      style="width: 100%"
      [chips]="preDefinedFilters"
      [selectedChipKey]="key"
      [loading]="isLoading"
      [flexEnd]="true"
      (chipSelected)="onPreDefinedFilterChange($event)"
    >
    </app-dynamic-chip-set>
    <br />
  </div>

  <div *ngIf="!isLoading; else loadingRef" class="content-container">
    <div
      *ngIf="listProduct.length > 0; else noData"
      #pdList
      class="product-search__list"
      (scroll)="onScroll($event)"
    >
      <mat-grid-list [cols]="colPerScreen" [rowHeight]="'1:1'" gutterSize="10">
        <ng-container *ngFor="let item of listProduct">
          <mat-grid-tile [colspan]="1">
            <div class="product-search__item" #pdItem>
              <app-product-search-item
                [data]="item"
                [filter]="preDefinedFilter"
              ></app-product-search-item>
            </div>
          </mat-grid-tile>
        </ng-container>
      </mat-grid-list>
    </div>
  </div>

  <div *ngIf="preDefinedFilter" class="product-search__count">
    <span class="spacer"></span>
    {{ paging.total }} item{{ paging.total === 1 ? "" : "s" }}
  </div>

  <ng-template #loadingRef>
    <div class="loading-overlay">
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>

  <ng-template #noData>
    <div class="product-search__no-data">
      <img
        class="product-search__no-data-img"
        [src]="'assets/no-data-product.svg'"
        alt="No Data"
        matTooltipPosition="above"
      />
      <div *ngIf="!preDefinedFilter" class="product-search__no-data-label">
        We couldn’t find anything matching your search.
      </div>
      <div
        *ngIf="!preDefinedFilter"
        class="product-search__no-data-description"
      >
        Try again with a different term
      </div>

      <div *ngIf="preDefinedFilter" class="product-search__no-data-label">
        No filter selected, please select before searching.
      </div>
    </div>
  </ng-template>
</mat-card>
