<mat-card class="card-item">
  <div class="card-item__thumb">
    <div
      class="card-item__thumb-img"
      [ngClass]="{ blur: !data.id && !hovering && !uploaded }"
    >
      <img
        *ngIf="data.thumbnailUri && !hovering && !uploaded"
        [ngClass]="{
          blur: !data.id,
          'cursor-pointer':
            data.id &&
            (data.additionalAssetIndex == null ||
              dataType == 'postProduction' ||
              dataType == 'outputAssets')
        }"
        [src]="getLinkImage() | secure | async"
        [alt]="'assets/image-placeholder.jpg'"
        [hidden]="!isImageLoaded"
        (load)="checkImageLoaded()"
        (click)="
          data.id &&
          (data.additionalAssetIndex == null ||
            dataType == 'postProduction' ||
            dataType == 'outputAssets')
            ? onEdit()
            : ''
        "
        (dragover)="
          dataType == 'outputAssets' && isStaff ? (hovering = true) : ''
        "
      />
      <div
        class="file-upload"
        *ngIf="hovering && !uploaded"
        (dragenter)="dragEnter()"
        (dragleave)="dragLeave()"
        (drop)="fileDrop($event)"
      >
        <mat-icon class="icon"> cloud_upload </mat-icon>
        <div class="upload-title">
          Drop file here to create new output version
        </div>
        <div class="upload-sub-title">
          <i>accepts {{ getFormat(outputFormat).toLowerCase() }} only*</i>
        </div>
        <div *ngIf="invalidFile" class="invalid-upload">
          <mat-icon color="error"> error </mat-icon>
          <div class="upload-sub-title">{{ invalidReason }}</div>
        </div>
      </div>
      <ng-container *ngIf="uploaded">
        <img
          *ngIf="!invalidFormat; else invalid"
          [src]="newOutputFileImage"
          [alt]="newOutputFile.name"
        />
        <div class="uploaded-actions">
          <button
            mat-icon-button
            matTooltip="Cancel"
            color="pending"
            (click)="cancel()"
          >
            <mat-icon>cancel</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="Confirm"
            color="success"
            (click)="confirm()"
          >
            <mat-icon>check_circle</mat-icon>
          </button>
        </div>
      </ng-container>
      <ng-template #invalid>
        <div
          class="file-upload"
          (dragover)="
            dataType == 'outputAssets' && data.postAssetId
              ? (hovering = true)
              : ''
          "
        >
          <div class="upload-title">Unable to display due to format type</div>
          <div class="upload-sub-title">
            <i>{{ getFormat(outputFormat).toLowerCase() }} format*</i>
          </div>
        </div>
      </ng-template>
      <input
        type="file"
        #fileInput
        hidden
        onclick="this.value=null"
        (change)="fileUpload(fileInput.files!)"
        [accept]="dataType == 'outputAssets' ? getFormat(outputFormat) : ''"
      />
      <ng-container
        *ngIf="(!isImageLoaded || !data.thumbnailUri) && !hovering && !uploaded"
      >
        <div class="card-item__no-img" *ngIf="!invalidFormat; else invalid">
          <img
            [src]="'assets/image-placeholder.jpg'"
            alt="Image Error"
            matTooltipPosition="above"
            (dragover)="
              dataType == 'outputAssets' && data.postAssetId
                ? (hovering = true)
                : ''
            "
          />
        </div>
      </ng-container>
    </div>
  </div>
  <mat-card-content>
    <div class="content-item">
      <div>
        <div class="card-item__label" [title]="data.label">
          {{ data.label }}
        </div>
        <ng-container *ngIf="!!data.id; else Pending">
          <ng-container
            *ngIf="dataType == 'production' && isStaff; else PostAsset"
          >
            <div
              class="card-item__description"
              matTooltip="{{
                data.shotDate | dateAgo : currentConfigDate : true | async
              }} by {{ data.shotByUserName }}"
            >
              {{ data.shotDate | dateAgo : currentConfigDate | async }}
            </div>
          </ng-container>
          <ng-template #PostAsset>
            <div class="card-item__description">
              <div>
                {{ data.subLabel }}
              </div>
            </div>
          </ng-template>
        </ng-container>
        <ng-template #Pending>
          <div
            class="card-item__description"
            *ngIf="dataType != 'production' && isStaff"
          >
            {{ data.subLabel }}
          </div>
          <div *ngIf="dataType == 'production'" class="card-item__description">
            Pending
          </div>
        </ng-template>
      </div>
      <div class="spacer"></div>

      <div class="card-item__key">
        <div
          (click)="copyToClipboard(data.key)"
          *ngIf="data.key && isStaff"
          class="flex-row"
        >
          <mat-icon class="card-item__copy" (click)="copyToClipboard(data.key)"
            >content_copy</mat-icon
          >
          <div class="card-item__key--text">
            {{ data.key }}
          </div>
        </div>

        <div
          class="card-item__description"
          style="text-align: end"
          *ngIf="data.id && dataType != 'production'"
          matTooltip="{{
            data.postProcessedDate | dateAgo : currentConfigDate : true | async
          }} {{
            dataType == 'postProduction' ? 'by ' + data.postByUserName : ''
          }}"
        >
          {{ data.postProcessedDate | dateAgo : currentConfigDate | async }}
        </div>
        <div
          *ngIf="data.manuallyUploaded && isStaff"
          class="flex-row"
          style="justify-content: flex-end"
        >
          <div class="card-item__key--text">
            <i>manual upload*</i>
          </div>
        </div>
        <div
          class="card-item__description"
          style="text-align: end"
          *ngIf="!data.id && dataType != 'production'"
        >
          Pending
        </div>
      </div>
      <i class="material-icons sparkle" *ngIf="data.enhancementFlag"
        >auto_awesome</i
      >
    </div>
  </mat-card-content>
  <mat-card-actions>
    <div class="card-item__actions">
      <ng-container *ngIf="showParentInfo">
        <div class="card-item__sub-item">
          Additional Asset of {{ parentLabel }}
        </div>
      </ng-container>
      <ng-container *ngIf="!showParentInfo">
        <mat-checkbox
          class="selected-card"
          [(ngModel)]="data.selected"
          (ngModelChange)="onSelect()"
          [color]="'primary'"
          [disabled]="!data.thumbnailUri"
          *ngIf="!!data.id"
        ></mat-checkbox>
        <div
          *ngIf="showExternalStatus && isStaff && data.id"
          class="card-item__status"
        >
          <div
            class="card-item__status item ternal"
            [ngClass]="{
              approve: data.internalReviewState === EStateAsset.APPROVED,
              reject: data.internalReviewState === EStateAsset.REJECTED
            }"
            [matTooltip]="
              !!data.internalReviewState
                ? 'Internal ' + statusMapping[data.internalReviewState]
                : 'Internal Pending'
            "
          ></div>
          <div
            class="card-item__status item"
            [ngClass]="{
              approve: data.clientReviewState === EStateAsset.APPROVED,
              reject: data.clientReviewState === EStateAsset.REJECTED
            }"
            [matTooltip]="
              data.clientReviewState === EStateAsset.APPROVED
                ? 'External Approved'
                : data.clientReviewState === EStateAsset.REJECTED
                ? 'External Rejected'
                : 'External Pending'
            "
          ></div>
        </div>
        <div
          *ngIf="!showExternalStatus && !!statusMapping[data.status!]"
          class="card-item__status-text"
          [ngClass]="{
            approved:
              data.status === EStateAsset.APPROVED ||
              data.status == EStateAsset.COMPLETE,
            'in-review':
              data.status === EStateAsset.IN_REVIEW ||
              data.status == EStateAsset.OUT_DATED,
            rejected: data.status === EStateAsset.REJECTED,
            'in-progress':
              dataType == 'outputAssets' &&
              data.status === EStateAsset.APPROVED &&
              !data.released
          }"
          [matTooltip]="
            dataType == 'outputAssets' &&
            data.status === EStateAsset.APPROVED &&
            !data.released
              ? 'Pending Release'
              : ''
          "
          [matTooltipDisabled]="data.released"
        >
          {{
            data.status == EStateAsset.APPROVED && !isStaff
              ? "Complete"
              : data.released
              ? "Released"
              : statusMapping[data.status!]
          }}
        </div>
        <div class="card-item__spacer"></div>
        <div>
          <button
            *ngIf="data.id"
            mat-icon-button
            [disabled]="!data.thumbnailUri || data.label == 'Non-Select'"
            (click)="onEdit()"
            [matTooltip]="
              data.label == 'Non-Select'
                ? 'Cannot Review Non-Select'
                : 'Asset review'
            "
          >
            <mat-icon>rate_review</mat-icon>
          </button>
          <button
            [matMenuTriggerFor]="actionsMenu"
            *ngIf="
              (!!data.id ||
                (data.masterSuiteTouchAssetId && data.productTouchId) ||
                data.postAssetId) &&
              isStaff
            "
            mat-icon-button
            matTooltip="More"
            color="primary"
            class="mat-elevation-z0"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #actionsMenu="matMenu">
            <a
              (click)="getLinkDownload()"
              mat-menu-item
              *ngIf="!!data.id"
              [disabled]="!data.id"
              download
              >Download Asset</a
            >
            <button
              *ngIf="dataType == 'production'"
              mat-menu-item
              (click)="downloadTextFile()"
            >
              <span>Download Text File</span>
            </button>
            <button
              *ngIf="dataType == 'outputAssets' && !!data.postAssetId"
              mat-menu-item
              (click)="fileInput.click()"
            >
              <span>Upload New</span>
            </button>
          </mat-menu>
        </div>
      </ng-container>
    </div>
  </mat-card-actions>
</mat-card>
