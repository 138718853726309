<div
  class="qc-view"
  #pdList
  (scroll)="onScroll($event)"
  *ngIf="type == 'shootAssets'"
>
  <div
    *ngFor="let masterSuite of productShootAssetsResult; index as a"
    style="display: grid"
  >
    <h1>{{ masterSuite.masterSuiteName }}</h1>
    <div
      *ngFor="let product of masterSuite.products; index as b"
      class="product-details-page__list"
    >
      <div *ngFor="let touch of product.touches; index as c">
        <h2 *ngIf="b == 0">{{ touch.masterSuiteTouchName }}</h2>
        <div
          style="display: inline-flex; margin-bottom: 20px; margin-right: 10px"
        >
          <ng-container *ngFor="let asset of touch.shootAssets; index as d">
            <app-qc-card
              style="width: 380px; margin: 5px"
              [isShootAsset]="true"
              [data]="asset"
              (editCallback)="editCallback($event, product.id)"
              (openImageCallback)="
                openImageCallback($event, product.id, a, b, c, d)
              "
              (reviewCallback)="reviewCallback($event, asset)"
            ></app-qc-card>
          </ng-container>
        </div>
      </div>
    </div>
    <mat-divider [ngStyle]="{ 'min-width': maxWidth + 'px' }"></mat-divider>
  </div>
</div>

<div
  class="qc-view"
  #pdList
  (scroll)="onScroll($event)"
  *ngIf="type == 'postAssets'"
>
  <div *ngFor="let masterSuite of dataSource; index as a">
    <h1>{{ masterSuite.name }}</h1>
    <div *ngFor="let group of masterSuite.grouped; index as b">
      <h2>{{ group.name }}</h2>
      <div *ngFor="let product of group.products; index as c">
        <div
          *ngIf="type == 'postAssets'"
          class="product-details-page__list"
          style="margin-bottom: 20px; margin-right: 10px"
        >
          <ng-container *ngFor="let asset of product.postAssets; index as d">
            <app-qc-card
              style="width: 380px"
              [isShootAsset]="false"
              [data]="asset"
              (editCallback)="editCallback($event, product.productId)"
              (openImageCallback)="
                openImageCallback($event, product.productId, a, b, c, d)
              "
              (reviewCallback)="reviewCallback($event, asset)"
            ></app-qc-card>
          </ng-container>
        </div>
      </div>
      <mat-divider [ngStyle]="{ 'min-width': maxWidth + 'px' }"></mat-divider>
    </div>
  </div>
</div>
