import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  INotification,
  INotificationRequest,
  INotificationResponse,
} from '../models/notification';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private http: HttpClient) {}

  getNotification(payload: INotificationRequest) {
    let url = `${environment.apiBase}/Notifications/filter`;
    url += `?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}`;
    if (payload.state) {
      url += `&state=${payload.state}`;
    }
    if (payload.type) {
      url += `&type=${payload.type}`;
    }

    return this.http.get<INotificationResponse>(url, httpOptions);
  }
  markRead(id: string) {
    let url = `${environment.apiBase}/userNotification/${id}/actions/markAsRead`;
    return this.http.patch<INotification>(url, {id}, httpOptions);
  }

  markAllAsRead() {
    let url = `${environment.apiBase}/userNotification/actions/markAllAsRead`;
    return this.http.patch<INotification>(url, {}, httpOptions);
  }
}
