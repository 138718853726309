<form class="form-setting" [formGroup]="settingForm">
  <div class="header">
    <h1>Output Configuration</h1>
    <mat-spinner
      *ngIf="isLoading"
      style="height: 50px !important"
    ></mat-spinner>
  </div>
  <div class="input-info">
    <div style="width: 100%; display: flex">
      <mat-form-field
        appearance="outline"
        color="primary"
        class="dialog-input"
        style="width: 78%"
      >
        <mat-label>Name</mat-label>
        <input
          matInput
          formControlName="name"
          [minlength]="3"
          [maxlength]="20"
        />
        <mat-error *ngIf="getControl('name')?.hasError('required')"
          >Name is required</mat-error
        >
        <mat-error
          *ngIf="
            getControl('name')?.hasError('minlength') ||
            getControl('name')?.hasError('pattern')
          "
          >Name must be 3-20 characters, [A-Z, 0-9, -, _]</mat-error
        >
      </mat-form-field>
      <div style="width: 20%; display: flex; justify-content: space-around">
        <mat-checkbox color="primary" [formControl]="stillsCheck">
          Stills
        </mat-checkbox>
        <mat-checkbox color="primary" [formControl]="videosCheck">
          Videos
        </mat-checkbox>
      </div>
    </div>

    <div *ngIf="stillsCheck.value">
      <div class="heading">
        <i class="material-icons">crop</i>
        Stills Configuration
      </div>

      <form class="form-stills" [formGroup]="stillsForm">
        <mat-form-field
          appearance="outline"
          color="primary"
          class="dialog-input"
        >
          <mat-label>Format</mat-label>
          <mat-select formControlName="format">
            <mat-option
              [value]="fm.value"
              *ngFor="let fm of formats['image']"
              >{{ fm.label }}</mat-option
            >
          </mat-select>
          <mat-error *ngIf="getControl('format')?.hasError('required')"
            >Format is required</mat-error
          >
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          color="primary"
          class="dialog-input"
        >
          <mat-label>Width</mat-label>
          <input
            matInput
            type="number"
            step="1"
            formControlName="width"
            min="0"
            (keypress)="onlyPositiveIntegers($event)"
          />
          <mat-error *ngIf="getControl('width')?.hasError('min')"
            >Minimum width is 100</mat-error
          >
          <mat-error *ngIf="getControl('width')?.hasError('max')"
            >Maximum width is 8000</mat-error
          >
          <mat-hint *ngIf="!getControl('width')?.value"
            >If not set, aspect ratio will be preserved</mat-hint
          >
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          color="primary"
          class="dialog-input"
        >
          <mat-label>Height</mat-label>
          <input
            matInput
            type="number"
            step="1"
            formControlName="height"
            min="0"
            (keypress)="onlyPositiveIntegers($event)"
          />
          <mat-error *ngIf="getControl('height')?.hasError('min')"
            >Minimum height is 100</mat-error
          >
          <mat-error *ngIf="getControl('height')?.hasError('max')"
            >Maximum height is 8000</mat-error
          >
          <mat-hint *ngIf="!getControl('height')?.value"
            >If not set, aspect ratio will be preserved</mat-hint
          >
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          color="primary"
          class="dialog-input"
          *ngIf="getControl('format')?.value === 'Jpg'"
        >
          <mat-label>Color Space</mat-label>
          <mat-select formControlName="colorSpace">
            <mat-option
              [value]="colorSpace.value"
              *ngFor="let colorSpace of colorSpaces"
              >{{ colorSpace.label }}</mat-option
            >
          </mat-select>
          <mat-error *ngIf="getControl('colorSpace')?.hasError('required')"
            >Color Space is required</mat-error
          >
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          color="primary"
          class="dialog-input"
          *ngIf="
            getControl('format')?.value === 'Jpg' ||
            getControl('format')?.value === 'WebP'
          "
        >
          <mat-label>Quality</mat-label>
          <input
            matInput
            type="number"
            step="1"
            formControlName="quality"
            [min]="1"
            [max]="100"
            (keypress)="onlyPositiveIntegers($event)"
          />
          <mat-error *ngIf="getControl('quality')?.hasError('required')"
            >Quality is required</mat-error
          >
          <mat-error *ngIf="getControl('quality')?.hasError('min')"
            >Minimum value is 1</mat-error
          >
          <mat-error *ngIf="getControl('quality')?.hasError('max')"
            >Maximum value is 100</mat-error
          >
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          color="primary"
          class="dialog-input"
        >
          <mat-label>DPI</mat-label>
          <input
            matInput
            type="number"
            step="1"
            formControlName="dpi"
            min="36"
            max="1200"
            (keypress)="onlyPositiveIntegers($event)"
          />
          <mat-error *ngIf="getControl('dpi')?.hasError('required')"
            >DPI is required</mat-error
          >
          <mat-error *ngIf="getControl('dpi')?.hasError('min')"
            >Minimum value is 36</mat-error
          >
          <mat-error *ngIf="getControl('dpi')?.hasError('max')"
            >Maximum value is 1200</mat-error
          >
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          color="primary"
          class="dialog-input"
          *ngIf="
            getControl('format')?.value === 'Png' ||
            getControl('format')?.value === 'Tiff'
          "
        >
          <mat-label>Bit-depth</mat-label>
          <mat-select formControlName="bitDepth">
            <mat-option
              [value]="bitDepth.value"
              *ngFor="let bitDepth of bitDepths"
              >{{ bitDepth.label }}</mat-option
            >
          </mat-select>
          <mat-error *ngIf="getControl('bitDepth')?.hasError('required')"
            >Bit-depth is required</mat-error
          >
        </mat-form-field>

        <div style="width: 100%; display: flex; justify-content: center">
          <mat-checkbox
            formControlName="lossless"
            *ngIf="getControl('format')?.value === 'WebP'"
            color="primary"
            (change)="toggleQuality()"
          >
            Lossless Compression
          </mat-checkbox>
        </div>
        <div class="file-name__title">
          <h3>STILLS FILENAME FORMATS</h3>
          <i class="material-icons pointer" (click)="addNewFormat(true)"
            >add_circle_outline</i
          >
        </div>

        <div class="show-config-token">
          <div class="item">
            <div class="header">Assets</div>
            <div class="header format">Filename format</div>
          </div>
          <ng-container>
            <div
              class="item"
              *ngFor="let current of currentFormats['images']; let i = index"
            >
              <div class="show-asset" (click)="editConfigRanges(current, true)">
                {{ current.from + (current.to ? "-" + current.to : "+") }}
              </div>
              <div class="show-format">
                <div *ngFor="let item of current.tokenFormats">
                  {{
                    item.value === "characters" ? item.character : item.value
                  }}
                </div>
                <span class="spacer"></span>
                <i
                  class="material-icons pointer"
                  (click)="editConfigRanges(current, true)"
                  >edit</i
                >
                <i
                  class="material-icons pointer"
                  (click)="deleteConfigToken(i, true)"
                  >delete</i
                >
              </div>
            </div>
          </ng-container>
        </div>

        <div formArrayName="filenameFormats" class="file-name__body">
          <ng-container
            *ngFor="
              let fileNameGroup of fileNameFormatStillsList.controls;
              let i = index
            "
          >
            <div class="input-info" [formGroupName]="i">
              <div class="ranges">
                <mat-form-field
                  appearance="outline"
                  color="primary"
                  class="dialog-input"
                >
                  <mat-label>From asset</mat-label>
                  <input
                    matInput
                    type="number"
                    step="1"
                    formControlName="from"
                    [min]="1"
                    (change)="updateValidtors(fileNameGroup)"
                    (keypress)="onlyPositiveIntegers($event)"
                  />
                  <mat-error
                    *ngIf="
                      getControlFormInArray('from', fileNameGroup)?.hasError(
                        'required'
                      )
                    "
                    >Range from is required. Enter 1 for all assets</mat-error
                  >
                  <mat-error
                    *ngIf="
                      getControlFormInArray('from', fileNameGroup)?.hasError(
                        'min'
                      )
                    "
                    >Minimum value is 1</mat-error
                  >
                </mat-form-field>
                <mat-form-field
                  appearance="outline"
                  color="primary"
                  class="dialog-input"
                >
                  <mat-label>To asset</mat-label>
                  <input
                    matInput
                    type="number"
                    step="1"
                    formControlName="to"
                    [min]="1"
                    (keypress)="onlyPositiveIntegers($event)"
                  />
                  <mat-icon
                    matSuffix
                    matTooltip="Leave the field empty to indicate unbounded range"
                    matTooltipPosition="above"
                  >
                    info_outline
                  </mat-icon>
                  <mat-error
                    *ngIf="
                      getControlFormInArray('to', fileNameGroup)?.hasError(
                        'min'
                      )
                    "
                    >Minimum value is 1</mat-error
                  >
                </mat-form-field>
                <mat-form-field
                  appearance="outline"
                  color="primary"
                  class="dialog-input"
                >
                  <mat-label>Index offset</mat-label>
                  <input
                    matInput
                    type="number"
                    step="1"
                    formControlName="indexOffset"
                  />
                  <mat-error
                    *ngIf="
                      getControlFormInArray(
                        'indexOffset',
                        fileNameGroup
                      )?.hasError('min')
                    "
                    >Offset places From range below 0 (
                    {{
                      getControlFormInArray("from", fileNameGroup).value +
                        getControlFormInArray("indexOffset", fileNameGroup)
                          .value
                    }})</mat-error
                  >
                </mat-form-field>
              </div>

              <app-chip-autocomplete
                [allChips]="fileNameTokens"
                [formCtrl]="
                  getControlFormInArray('tokenFormats', fileNameGroup)
                "
              ></app-chip-autocomplete>
              <div class="action-format">
                <mat-button-toggle-group formControlName="casing">
                  <mat-button-toggle
                    [value]="'match'"
                    matTooltip="Casing As is"
                  >
                    <span class="material-symbols-outlined">match_case</span>
                  </mat-button-toggle>
                  <mat-button-toggle
                    [value]="'upper'"
                    matTooltip="All Uppercase"
                  >
                    <span class="material-symbols-outlined">uppercase</span>
                  </mat-button-toggle>
                  <mat-button-toggle
                    [value]="'lower'"
                    matTooltip="All Lowercase"
                  >
                    <span class="material-symbols-outlined">lowercase</span>
                  </mat-button-toggle>
                </mat-button-toggle-group>
                <span class="spacer"></span>
                <button
                  mat-icon-button
                  color="warn"
                  (click)="onRemoveForm(i, true)"
                >
                  <mat-icon>cancel</mat-icon>
                </button>
                <button
                  mat-icon-button
                  color="success"
                  (click)="addFormat(i, fileNameGroup.valid)"
                >
                  <mat-icon>check_circle</mat-icon>
                </button>
              </div>
            </div>
          </ng-container>
        </div>
      </form>
    </div>

    <div *ngIf="videosCheck.value">
      <div class="heading">
        <i class="material-icons">smart_display</i>
        Videos Configuration
      </div>
      <form class="form-stills" [formGroup]="videosForm">
        <mat-form-field
          appearance="outline"
          color="primary"
          class="dialog-input"
        >
          <mat-label>Format</mat-label>
          <mat-select formControlName="format">
            <mat-option
              [value]="fm.value"
              *ngFor="let fm of formats['video']"
              >{{ fm.label }}</mat-option
            >
          </mat-select>
          <mat-error *ngIf="getControl('format')?.hasError('required')"
            >Format is required</mat-error
          >
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          color="primary"
          class="dialog-input"
        >
          <mat-label>Width</mat-label>
          <input
            matInput
            type="number"
            step="1"
            formControlName="width"
            min="0"
            (keypress)="onlyPositiveIntegers($event)"
          />
          <mat-error *ngIf="getControl('width')?.hasError('min')"
            >Minimum width is 100</mat-error
          >
          <mat-error *ngIf="getControl('width')?.hasError('max')"
            >Maximum width is 8000</mat-error
          >
          <mat-hint *ngIf="!getControl('width')?.value"
            >If not set, aspect ratio will be preserved</mat-hint
          >
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          color="primary"
          class="dialog-input"
        >
          <mat-label>Height</mat-label>
          <input
            matInput
            type="number"
            step="1"
            formControlName="height"
            min="0"
            (keypress)="onlyPositiveIntegers($event)"
          />
          <mat-error *ngIf="getControl('height')?.hasError('min')"
            >Minimum height is 100</mat-error
          >
          <mat-error *ngIf="getControl('height')?.hasError('max')"
            >Maximum height is 8000</mat-error
          >
          <mat-hint *ngIf="!getControl('height')?.value"
            >If not set, aspect ratio will be preserved</mat-hint
          >
        </mat-form-field>
        <div class="file-name__title">
          <h3>VIDEOS FILENAME FORMATS</h3>
          <i class="material-icons pointer" (click)="addNewFormat(false)"
            >add_circle_outline</i
          >
        </div>

        <div class="show-config-token">
          <div class="item">
            <div class="header">Assets</div>
            <div class="header format">Filename format</div>
          </div>
          <ng-container>
            <div
              class="item"
              *ngFor="let current of currentFormats['videos']; let i = index"
            >
              <div
                class="show-asset"
                (click)="editConfigRanges(current, false)"
              >
                {{ current.from + (current.to ? "-" + current.to : "+") }}
              </div>
              <div class="show-format">
                <div *ngFor="let item of current.tokenFormats">
                  {{
                    item.value === "characters" ? item.character : item.value
                  }}
                </div>
                <span class="spacer"></span>
                <i
                  class="material-icons pointer"
                  (click)="editConfigRanges(current, false)"
                  >edit</i
                >
                <i
                  class="material-icons pointer"
                  (click)="deleteConfigToken(i, false)"
                  >delete</i
                >
              </div>
            </div>
          </ng-container>
        </div>

        <div formArrayName="filenameFormats" class="file-name__body">
          <ng-container
            *ngFor="
              let fileNameGroup of fileNameFormatVideosList.controls;
              let i = index
            "
          >
            <div class="input-info" [formGroupName]="i">
              <div class="ranges">
                <mat-form-field
                  appearance="outline"
                  color="primary"
                  class="dialog-input"
                >
                  <mat-label>From asset</mat-label>
                  <input
                    matInput
                    type="number"
                    step="1"
                    formControlName="from"
                    [min]="1"
                    (change)="updateValidtors(fileNameGroup)"
                    (keypress)="onlyPositiveIntegers($event)"
                  />
                  <mat-error
                    *ngIf="
                      getControlFormInArray('from', fileNameGroup)?.hasError(
                        'required'
                      )
                    "
                    >Range from is required. Enter 1 for all assets</mat-error
                  >
                  <mat-error
                    *ngIf="
                      getControlFormInArray('from', fileNameGroup)?.hasError(
                        'min'
                      )
                    "
                    >Minimum value is 1</mat-error
                  >
                </mat-form-field>
                <mat-form-field
                  appearance="outline"
                  color="primary"
                  class="dialog-input"
                >
                  <mat-label>To asset</mat-label>
                  <input
                    matInput
                    type="number"
                    step="1"
                    formControlName="to"
                    [min]="1"
                    (keypress)="onlyPositiveIntegers($event)"
                  />
                  <mat-icon
                    matSuffix
                    matTooltip="Leave the field empty to indicate unbounded range"
                    matTooltipPosition="above"
                  >
                    info_outline
                  </mat-icon>
                  <mat-error
                    *ngIf="
                      getControlFormInArray('to', fileNameGroup)?.hasError(
                        'min'
                      )
                    "
                    >Minimum value is 1</mat-error
                  >
                </mat-form-field>
                <mat-form-field
                  appearance="outline"
                  color="primary"
                  class="dialog-input"
                >
                  <mat-label>Index offset</mat-label>
                  <input
                    matInput
                    type="number"
                    step="1"
                    formControlName="indexOffset"
                  />
                  <mat-error
                    *ngIf="
                      getControlFormInArray(
                        'indexOffset',
                        fileNameGroup
                      )?.hasError('min')
                    "
                    >Offset places From range below 0 (
                    {{
                      getControlFormInArray("from", fileNameGroup).value +
                        getControlFormInArray("indexOffset", fileNameGroup)
                          .value
                    }})</mat-error
                  >
                </mat-form-field>
              </div>

              <app-chip-autocomplete
                [allChips]="fileNameTokens"
                [formCtrl]="
                  getControlFormInArray('tokenFormats', fileNameGroup)
                "
              ></app-chip-autocomplete>
              <div class="action-format">
                <mat-button-toggle-group formControlName="casing">
                  <mat-button-toggle
                    [value]="'match'"
                    matTooltip="Casing As is"
                  >
                    <span class="material-symbols-outlined">match_case</span>
                  </mat-button-toggle>
                  <mat-button-toggle
                    [value]="'upper'"
                    matTooltip="All Uppercase"
                  >
                    <span class="material-symbols-outlined">uppercase</span>
                  </mat-button-toggle>
                  <mat-button-toggle
                    [value]="'lower'"
                    matTooltip="All Lowercase"
                  >
                    <span class="material-symbols-outlined">lowercase</span>
                  </mat-button-toggle>
                </mat-button-toggle-group>
                <span class="spacer"></span>
                <button
                  mat-icon-button
                  color="warn"
                  (click)="onRemoveForm(i, false)"
                >
                  <mat-icon>cancel</mat-icon>
                </button>
                <button
                  mat-icon-button
                  color="success"
                  (click)="addFormat(i, false)"
                >
                  <mat-icon>check_circle</mat-icon>
                </button>
              </div>
            </div>
          </ng-container>
        </div>
      </form>
    </div>
  </div>

  <div class="action-ctn">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button
      mat-stroked-button
      (click)="onSubmit()"
      color="success"
      class="save"
    >
      Save
    </button>
  </div>
</form>
