import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Auth } from 'aws-amplify';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ValidationErrors } from '../models/validationErrors';

/**
 * This is used to logout the user, when the server responds with an unathorized status code.
 * Especially when the session token expires.
 * @export
 * @class ErrorInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(public _alert: MatSnackBar) {}

  /**
   * Intercepter intercepts the responses, and then process based on the recieved status code
   * Read more : http://jasonwatmore.com/post/2018/05/23/angular-6-jwt-authentication-example-tutorial
   * @param {HttpRequest<any>} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   * @memberof ErrorInterceptor
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        console.log(err);
        if (err.status === 401) {
          // auto logout if 401 response returned from api
          Auth.signOut({ global: true })
            .then((data) => console.log(data))
            .catch((err) => console.log(err));
        } else if (err.status === 0) {
          this._alert.open('Unknown Internal Server Error', 'close', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            panelClass: ['error'],
          });
        } else if (err.status === 500) {
          this._alert.open('Internal Server Error: ', 'close', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 5000,
            panelClass: ['error'],
          });
        } else if (err.status === 422) {
          /// Validation Errors from API
        } else {
          this._alert.open('Unknown Server Error: ', 'close', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 5000,
            panelClass: ['error'],
          });
        }

        // err.error is not null, if the ResponsenEntity contains an Exception
        // err.error.message will give the custom message send from the server
        const error = err;
        return throwError(error);
      })
    );
  }
}
