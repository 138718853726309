<h1 mat-dialog-title style="font-size: 30px">New User</h1>
<mat-dialog-content class="mat-typography">
  <div>
    <mat-form-field appearance="outline" color="primary" class="dialog-input">
      <mat-label>Full Name</mat-label>
      <input required [formControl]="nameFormControl" matInput />
      <mat-error *ngIf="validatorProperties.includes('Name')">
        {{ validationMessage("Name") }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" color="primary" class="dialog-input">
      <mat-label>Email Address</mat-label>
      <input type="email" required [formControl]="emailFormControl" matInput />
      <mat-error *ngIf="validatorProperties.includes('EmailAddress')">
        {{ validationMessage("EmailAddress") }}
      </mat-error>
    </mat-form-field>

    <mat-radio-group
      [(ngModel)]="userType"
      style="display: flex; justify-content: space-evenly"
      (change)="onUserTypeChange()"
    >
      <mat-radio-button color="primary" value="mmapt"
        >MMAPT User</mat-radio-button
      >
      <mat-radio-button color="primary" value="client"
        >Client User</mat-radio-button
      >
    </mat-radio-group>

    <div *ngIf="userType == 'mmapt'">
      <mat-form-field appearance="outline" color="primary" class="dialog-input">
        <mat-label>User Groups</mat-label>
        <mat-select
          [formControl]="mmaptUserGroup"
          multiple
          (selectionChange)="onMmaptUserGroupChange()"
        >
          <mat-option *ngFor="let group of mmaptUserGroups" [value]="group">{{
            group
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" color="primary" class="dialog-input">
        <mat-label>Pin</mat-label>
        <input
          [formControl]="pinFormControl"
          maxlength="4"
          minlength="4"
          type="tel"
          matInput
        />
        <mat-error *ngIf="validatorProperties.includes('PinHash')"> </mat-error>
        <mat-error *ngIf="pinFormControl.value.length < 4">
          Pin must be 4 digits
        </mat-error>
      </mat-form-field>
    </div>

    <div *ngIf="userType == 'client'">
      <mat-form-field appearance="outline" color="primary" class="dialog-input">
        <mat-label>Users Organization</mat-label>
        <input
          type="text"
          placeholder="Pick one"
          aria-label="Client"
          matInput
          [formControl]="organizationFormControl"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete
          autoActiveFirstOption
          #auto="matAutocomplete"
          [displayWith]="displayFn"
        >
          <mat-option
            *ngFor="let organization of organizationFilteredOptions | async"
            [value]="organization"
          >
            {{ organization.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <form class="form-setting" [formGroup]="formData">
      <div class="preference__label">
        <mat-icon>schedule</mat-icon>
        <span>Preference settings</span>
      </div>
      <mat-form-field appearance="outline" color="primary" class="dialog-input">
        <mat-label>Timezone</mat-label>
        <input
          type="text"
          matInput
          formControlName="timezone"
          [matAutocomplete]="auto"
          (keyup)="onKey($event)"
        />
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option
            [value]="tz.text"
            *ngFor="let tz of timezoneListFiltered"
            >{{ tz.text }}</mat-option
          >
        </mat-autocomplete>
      </mat-form-field>
      <div class="inline-row">
        <div class="flex-grow-1">
          <mat-form-field
            appearance="outline"
            color="primary"
            class="dialog-input"
          >
            <mat-label>DateTime Format</mat-label>
            <mat-select formControlName="dateFormat">
              <mat-option
                [value]="el.value"
                *ngFor="let el of dateTimeFormat"
                >{{ el.text }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="full-row">
          <mat-checkbox
            class="example-margin"
            formControlName="is24H"
            color="primary"
          >
            24 hours time
          </mat-checkbox>
        </div>
      </div>
      <div>
        <mat-radio-group
          aria-label="Select an option"
          formControlName="is24H"
          color="primary"
        >
          <mat-radio-button [value]="true">Days ago</mat-radio-button>
          <mat-radio-button [value]="false">Date/Time</mat-radio-button>
        </mat-radio-group>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button (click)="cancel()">Cancel</button>
  <button mat-button #create color="buttonSuccess" (click)="createUser()">
    Create
  </button>
</mat-dialog-actions>
