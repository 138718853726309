<div class="content" *ngIf="loaded">
  <div class="content-header">
    <div class="title" style="display: flex; align-items: center">
      {{ batch.name }}

      <mat-spinner *ngIf="generating" diameter="48"> </mat-spinner>

      <mat-checkbox
        (change)="$event ? masterToggleGridList() : null"
        [checked]="selection.hasValue() && isAllSelected()"
        color="primary"
        *ngIf="view === 'grid'"
        >Select all
      </mat-checkbox>
    </div>
    <button
      matSuffix
      mat-icon-button
      matTooltip="Page Link"
      matTooltipPosition="above"
      (click)="
        this.clipboardService.copyUrlAndTextToClipboard(
          this.urlService.getFullUrl(),
          this.pageLink
        )
      "
    >
      <mat-icon>link</mat-icon>
    </button>
    <span class="spacer"></span>

    <form>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label>Search</mat-label>
        <input
          [formControl]="searchForm"
          matInput
          type="text"
          (keyup)="applyFilter($event)"
          placeholder="Filter"
        />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </form>
    <div class="view-box">
      <mat-button-toggle-group
        [(ngModel)]="view"
        name="view"
        aria-label="View"
        (change)="onViewChanged()"
        hideSingleSelectionIndicator="true"
      >
        <mat-button-toggle value="grid">
          <mat-icon>grid_view</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="list">
          <mat-icon>view_list</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle *ngIf="isStaff" value="qc-production">
          <mat-icon>camera_alt</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle *ngIf="isStaff" value="qc">
          <mat-icon>palette</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>

  <div *ngIf="view == 'list'">
    <div [hidden]="view !== 'list'">
      <div style="overflow-x: auto">
        <table
          mat-table
          [dataSource]="dataSource"
          class="table"
          matSort
          matSortActive="barcode"
          matSortDirection="desc"
        >
          <!-- Check Box Column -->
          <ng-container matColumnDef="Select">
            <th mat-header-cell *matHeaderCellDef style="width: 30px">
              <mat-checkbox
                (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
                color="primary"
              >
              </mat-checkbox>
            </th>

            <td mat-cell *matCellDef="let product">
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(product) : null"
                [checked]="selection.isSelected(product)"
                color="primary"
              >
              </mat-checkbox>
            </td>
          </ng-container>

          <!-- Barcode Column -->
          <ng-container matColumnDef="barcode">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Barcode</th>
            <td mat-cell *matCellDef="let product">{{ product.barcode }}</td>
          </ng-container>

          <!-- Reference Column -->
          <ng-container matColumnDef="reference">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Reference</th>
            <td mat-cell *matCellDef="let product">{{ product.reference }}</td>
          </ng-container>

          <!-- Description Column -->
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Description
            </th>
            <td mat-cell *matCellDef="let product">
              {{ product.description }}
            </td>
          </ng-container>

          <!-- Master Suite Column -->
          <ng-container matColumnDef="masterSuiteName" *ngIf="isStaff">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Mastersuite (Variant)
            </th>
            <td mat-cell *matCellDef="let product">
              <div *ngIf="product.masterSuiteId != null">
                {{ product.masterSuiteName }}
                {{
                  product.settingsVariantName != null
                    ? "(" + product.settingsVariantName + ")"
                    : ""
                }}
              </div>
            </td>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="states" *ngIf="isStaff">
            <th mat-header-cell *matHeaderCellDef style="width: 224px">
              Status
            </th>
            <td mat-cell *matCellDef="let product" class="icon-ctn">
              <div style="display: flex; justify-content: space-between">
                <mat-icon
                  color="{{ product.states.receivalState }}"
                  matTooltip="Received Quantity {{ product.receivedCount }}"
                  fontIcon="receipt_long"
                ></mat-icon>
                <mat-icon
                  color="{{ product.states.masterSuiteState }}"
                  matTooltip="MasterSuite {{
                    product.masterSuiteId != null ? 'Applied' : 'Pending'
                  }}"
                  fontIcon="lightbulb"
                ></mat-icon>
                <mat-icon
                  color="{{ product.states.ingestionState }}"
                  matTooltip="{{ product.ingestedTouchCount }}/{{
                    product.expectedTouchCount
                  }} Touches Ingested"
                  fontIcon="dataset"
                ></mat-icon>
                <mat-icon
                  color="{{ product.states.productionState }}"
                  matTooltip="{{ product.shotTouchCount }}/{{
                    product.expectedTouchCount
                  }} Touches Shot"
                  fontIcon="camera_alt"
                ></mat-icon>
                <mat-icon
                  color="{{ product.states.enhancementState }}"
                  matTooltip="{{ product.postAssetCount }}/{{
                    product.expectedPostAssetCount
                  }} Assets Enhanced"
                  fontIcon="palette"
                ></mat-icon>
                <mat-icon
                  mat-icon-button
                  color="{{ product.states.reviewState }}"
                  matTooltip="{{ product.approvedPostAssetCount }}/{{
                    product.expectedPostAssetCount
                  }} Assets Approved"
                  fontIcon="reviews"
                ></mat-icon>
                <mat-icon
                  mat-icon-button
                  color="{{ product.states.outputsState }}"
                  [style]="{
                    opacity: product.outdatedRelease ? '40%' : '100%'
                  }"
                  matTooltip="{{
                    product.states.outputsState == progressState.INFO
                      ? 'Outputs Pending Release'
                      : product.outdatedRelease
                      ? 'Release Outdated ' +
                        (product.releasedAt
                          | dateAgo : currentConfigDate
                          | async)
                      : product.releasedInCollection
                      ? 'Outputs Released ' +
                        (product.releasedAt
                          | dateAgo : currentConfigDate
                          | async)
                      : product.approvedOutputAssetCount +
                        '/' +
                        product.expectedOutputAssetCount +
                        ' Completed Outputs'
                  }}"
                  fontIcon="filter"
                ></mat-icon>
                <mat-icon
                  fontIcon="delivery_dining"
                  color="{{
                    product.dispatchedCount > 0 ? 'success' : 'pending'
                  }}"
                  matTooltip="Dispatched Quantity {{ product.dispatchedCount }}"
                ></mat-icon>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="status" *ngIf="!isStaff">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              style="width: 200px"
            >
              Status
            </th>
            <td mat-cell *matCellDef="let product">
              <div
                class="status-text"
                [ngClass]="{
                      complete: product.releasedInCollection && product.approvedOutputAssetCount == product.expectedOutputAssetCount && product.expectedOutputAssetCount > 0,
                    }"
              >
                <div class="external-status"></div>
                {{
                  product.releasedInCollection &&
                  product.approvedOutputAssetCount ==
                    product.expectedOutputAssetCount &&
                  product.expectedOutputAssetCount > 0
                    ? "Complete"
                    : "Pending"
                }}
              </div>
            </td>
          </ng-container>

          <!-- Actions Column -->
          <ng-container matColumnDef="Actions">
            <th mat-header-cell *matHeaderCellDef style="width: 150px"></th>
            <td mat-cell *matCellDef="let product">
              <a
                routerLinkActive="active-link"
                routerLink="/batches/{{ product.batchId }}/products/{{
                  product.id
                }}"
                ><button
                  mat-icon-button
                  class="mat-elevation-z0"
                  matTooltip="Details"
                  matTooltipPosition="below"
                  color="info"
                >
                  <mat-icon
                    matBadge="!"
                    matBadgeSize="small"
                    [matBadgeHidden]="product.notes == null || !isStaff"
                    >info</mat-icon
                  >
                </button>
              </a>

              <button
                *ngIf="isStaff"
                [matMenuTriggerFor]="actionsMenu"
                mat-icon-button
                matTooltip="More"
                color="primary"
                matTooltipPosition="right"
                class="mat-elevation-z0"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #actionsMenu="matMenu">
                <button
                  *ngIf="isStaff"
                  mat-menu-item
                  (click)="openProductUpdateDialog(product)"
                >
                  <mat-icon matTooltip="Edit" color="success">edit</mat-icon>
                  <span>Edit</span>
                </button>

                <button
                  *ngIf="isStaff"
                  [disabled]="product.receivedCount == 0"
                  mat-menu-item
                  (click)="openProductReceivals(product)"
                >
                  <mat-icon matTooltip="View" color="success"
                    >receipt_long</mat-icon
                  >
                  <span>Receivals</span>
                </button>

                <a
                  *ngIf="isStaff"
                  [disabled]="
                    product.productLink == '' || product.productLink == null
                  "
                  mat-menu-item
                  href="{{ product.productLink }}"
                  target="_blank"
                >
                  <mat-icon matTooltip="View" color="info">link</mat-icon>
                  <span>Product Link</span>
                </a>
                <button
                  *ngIf="isStaff"
                  mat-menu-item
                  (click)="openMasterSuite(product)"
                  [disabled]="!product.masterSuiteId"
                >
                  <mat-icon color="success">auto_fix_high</mat-icon>
                  <span>Enhancement Variants</span>
                </button>

                <button
                  *ngIf="isAdmin"
                  mat-menu-item
                  (click)="deleteProduct(product)"
                >
                  <mat-icon matTooltip="Delete" color="error">delete</mat-icon>
                  <span>Delete</span>
                </button>
              </mat-menu>
            </td>
          </ng-container>

          <!-- Header row second group -->
          <ng-container matColumnDef="filters-row" *ngIf="isStaff">
            <th
              mat-header-cell
              *matHeaderCellDef
              [attr.colspan]="displayedColumns.length"
            >
              <div style="display: flex; align-items: center">
                <app-dynamic-chip-set
                  style="width: 100%"
                  [chips]="preDefinedFilters"
                  [loading]="
                    productsSubscription && !productsSubscription.closed
                  "
                  [selectedChipKey]="selectedfilter"
                  (chipSelected)="onPreDefinedFilterChange($event)"
                ></app-dynamic-chip-set>
                <span class="spacer"></span>
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="filtersMenu"
                  matTooltip="Filters"
                  matTooltipPosition="above"
                  color="primary"
                >
                  <mat-icon>{{
                    allEmpty(filter) ? "filter_list_off" : "filter_list"
                  }}</mat-icon>
                </button>
              </div>
            </th>
          </ng-container>

          <tr
            mat-header-row
            [style]="{
              display: isStaff ? 'contents' : 'none'
            }"
            *matHeaderRowDef="isStaff ? ['filters-row'] : []"
          ></tr>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            (click)="selectRow($event, row)"
          ></tr>
        </table>
      </div>
      <mat-paginator
        [pageSizeOptions]="[10, 20, 50, 100]"
        showFirstLastButtons
        aria-label="Select page of Products"
        [pageIndex]="pageIndex"
        [pageSize]="pageSize"
        (page)="pageNumberChange($event)"
      >
      </mat-paginator>
      <mat-progress-bar
        *ngIf="productsSubscription && !productsSubscription.closed"
        mode="query"
      ></mat-progress-bar>
    </div>

    <div class="content-actions" *ngIf="isAdmin">
      <span class="spacer"></span>
      <mat-form-field appearance="outline" style="width: 15%; padding: 0 10px">
        <mat-label>Mastersuite</mat-label>
        <input
          *ngIf="loaded"
          type="text"
          placeholder="Pick one"
          aria-label="Mastersuite"
          matInput
          [(ngModel)]="selectedMastersuite"
          [formControl]="mastersuiteControl"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete
          autoActiveFirstOption
          #auto="matAutocomplete"
          [displayWith]="displayFn"
        >
          <mat-option
            *ngFor="let mastersuite of mastersuiteFilteredOptions | async"
            [value]="mastersuite"
          >
            {{ mastersuite.name }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="warning == true">
          {{ warningMessage }}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" style="width: 15%; padding: 0 10px">
        <mat-label>Variant</mat-label>
        <mat-select [(ngModel)]="selectedVariant">
          <mat-option *ngFor="let variant of variants" [value]="variant">
            {{ variant.name }}
          </mat-option>
        </mat-select>
        <mat-hint
          *ngIf="(selectedMastersuite != null || '') && variants.length == 0"
        >
          No Settings Variants Available
        </mat-hint>
      </mat-form-field>

      <button
        mat-raised-button
        color="primary"
        class="action-button"
        (click)="applySettings()"
        style="margin-bottom: 27px"
      >
        Apply
        <mat-icon color="success">lightbulb</mat-icon>
      </button>

      <button
        [matMenuTriggerFor]="productsActionsMenu"
        mat-icon-button
        matTooltip="More"
        color="primary"
        matTooltipPosition="right"
        class="mat-elevation-z0"
        style="margin-bottom: 27px"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #productsActionsMenu="matMenu">
        <button
          mat-menu-item
          class="mat-elevation-z0"
          [disabled]="selection.selected.length === 0"
          (click)="bulkProductIngest()"
          color="primary"
        >
          <mat-icon>dataset</mat-icon>
          <span>Group Ingest ({{ selection.selected.length }})</span>
        </button>

        <button
          mat-menu-item
          class="mat-elevation-z0"
          (click)="trasnferSettings()"
        >
          <mat-icon color="info">alt_route</mat-icon>
          <span>Transfer Master Suite</span>
        </button>

        <button
          mat-menu-item
          class="mat-elevation-z0"
          (click)="openProductUploadDialog()"
        >
          <mat-icon color="success">upload</mat-icon>
          <span>Upload Products</span>
        </button>
        <button
          mat-menu-item
          class="mat-elevation-z0"
          (click)="openProductCreateDialog()"
        >
          <mat-icon color="success">add_circle</mat-icon>
          <span>Add Products</span>
        </button>

        <button
          *ngIf="!view.includes('qc')"
          mat-menu-item
          class="mat-elevation-z0"
          [disabled]="selection.selected.length === 0"
          (click)="createOutputsCollection()"
        >
          <mat-icon color="primary">download</mat-icon>
          <span>Release Collection ({{ selection.selected.length }})</span>
        </button>

        <button
          *ngIf="!generating"
          mat-menu-item
          class="mat-elevation-z0"
          [disabled]="selection.selected.length === 0"
          (click)="generateOutputAssets()"
        >
          <mat-icon color="success">auto_awesome</mat-icon>
          <span>Generate Outputs ({{ selection.selected.length }})</span>
        </button>
      </mat-menu>
    </div>
  </div>

  <div *ngIf="view == 'grid'" class="product-grid">
    <div *ngIf="isStaff" style="display: flex; align-items: center">
      <app-dynamic-chip-set
        *ngIf="view == 'grid'"
        style="width: 100%"
        [chips]="preDefinedFilters"
        [loading]="productsSubscription && !productsSubscription.closed"
        [selectedChipKey]="selectedfilter"
        (chipSelected)="onPreDefinedFilterChange($event)"
      ></app-dynamic-chip-set>
      <span class="spacer"></span>
      <button
        mat-icon-button
        [matMenuTriggerFor]="filtersMenu"
        matTooltip="Filters"
        matTooltipPosition="above"
        color="primary"
      >
        <mat-icon>{{
          allEmpty(filter) ? "filter_list_off" : "filter_list"
        }}</mat-icon>
      </button>
    </div>
    <mat-grid-list [cols]="colPerScreen" [rowHeight]="'1:1'" gutterSize="10">
      <ng-container *ngFor="let product of girdList">
        <mat-grid-tile [colspan]="1">
          <div class="product-grid-item">
            <div class="product-grid-item__label">
              <div class="text-ellipse">
                {{ product.barcode || product.reference }}
              </div>
              <div
                class="external-status"
                [ngClass]="{
                  approved:
                    product.approvedPostAssetCount ==
                      product.expectedPostAssetCount &&
                      product.expectedPostAssetCount > 0,
                  active: product.approvedPostAssetCount > 0 && product.approvedPostAssetCount !=
                      product.expectedPostAssetCount && product.rejectedPostAssetCount == 0,
                  rejected: product.rejectedPostAssetCount > 0,
                }"
              ></div>
            </div>
            <div
              class="product-grid-item__thumb-img"
              routerLinkActive="active-link"
              routerLink="{{ product.id }}"
            >
              <ng-container *ngIf="!!product.thumbnailUri; else noImage">
                <img
                  [src]="
                    getLinkImage(product.thumbnailUri, product) | secure | async
                  "
                  [alt]=""
                  matTooltipPosition="above"
                  (load)="product.loadedImage = true"
                  [hidden]="!product.loadedImage"
                />
              </ng-container>
              <ng-template #noImage>
                <div class="product-grid-item__no-img">
                  <img
                    [src]="'assets/image-placeholder.jpg'"
                    alt="Image Error"
                    matTooltipPosition="above"
                  />
                </div>
              </ng-template>
              <div
                class="product-grid-item__no-img"
                *ngIf="!!product.thumbnailUri && !product.loadedImage"
              >
                <img
                  [src]="'assets/image-placeholder.jpg'"
                  alt="Image Error"
                  matTooltipPosition="above"
                />
              </div>
            </div>
            <div class="text-ellipse">
              {{ product.description }}
            </div>
            <div class="action-ctn">
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(product) : null"
                [checked]="selection.isSelected(product)"
                color="primary"
              ></mat-checkbox>
              <div class="spacer"></div>
              <a routerLinkActive="active-link" routerLink="{{ product.id }}">
                <button
                  mat-icon-button
                  class="mat-elevation-z0"
                  matTooltip="Details"
                  matTooltipPosition="left"
                  color="info"
                >
                  <mat-icon *ngIf="product.notes == null; else notesBadge"
                    >info</mat-icon
                  >
                  <ng-template #notesBadge>
                    <mat-icon matBadge="!" matBadgeSize="small">info</mat-icon>
                  </ng-template>
                </button>
              </a>

              <button
                [matMenuTriggerFor]="actionsMenu"
                mat-icon-button
                matTooltip="More"
                color="primary"
                matTooltipPosition="right"
                class="mat-elevation-z0"
                *ngIf="isStaff"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #actionsMenu="matMenu">
                <button
                  mat-menu-item
                  (click)="openProductUpdateDialog(product)"
                >
                  <mat-icon matTooltip="Edit" color="success">edit</mat-icon>
                  <span>Edit</span>
                </button>

                <button
                  [disabled]="product.receivedCount == 0"
                  mat-menu-item
                  (click)="openProductReceivals(product)"
                >
                  <mat-icon matTooltip="View" color="success"
                    >receipt_long</mat-icon
                  >
                  <span>Receivals</span>
                </button>

                <a
                  [disabled]="
                    product.productLink == '' || product.productLink == null
                  "
                  mat-menu-item
                  href="{{ product.productLink }}"
                  target="_blank"
                >
                  <mat-icon matTooltip="View" color="info">link</mat-icon>
                  <span>Product Link</span>
                </a>
                <button
                  *ngIf="isStaff"
                  mat-menu-item
                  (click)="openMasterSuite(product)"
                  [disabled]="!product.masterSuiteId"
                >
                  <mat-icon matTooltip="Enhancement Variant" color="success"
                    >auto_fix_high</mat-icon
                  >
                  <span>Enhancement Variants</span>
                </button>
                <button
                  *ngIf="isAdmin"
                  mat-menu-item
                  (click)="deleteProduct(product)"
                >
                  <mat-icon matTooltip="Delete" color="error">delete</mat-icon>
                  <span>Delete</span>
                </button>
              </mat-menu>
            </div>
          </div>
        </mat-grid-tile>
      </ng-container>
    </mat-grid-list>
  </div>

  <app-products-qc-view
    *ngIf="view === 'qc-production'"
    [batchId]="batch.id!"
    [products]="dataSource.filteredData"
    type="shootAssets"
  ></app-products-qc-view>

  <app-products-qc-view
    *ngIf="view === 'qc'"
    [batchId]="batch.id!"
    [products]="dataSource.filteredData"
    type="postAssets"
  ></app-products-qc-view>
</div>

<mat-menu #filtersMenu="matMenu" (closed)="filterProducts()">
  <div
    style="width: 350px; padding: 2px 10px !important"
    (click)="$event.stopPropagation()"
  >
    <div style="display: flex; align-items: center; margin: 4px">
      Status Filters
      <span class="spacer"></span>
      <button mat-button class="action-button" (click)="clearFilters()">
        Clear
      </button>
    </div>
    <div class="filter-item">
      <div class="label">Receivals</div>
      <span class="spacer"></span>
      <mat-button-toggle-group
        class="filter-toggle-group"
        [(ngModel)]="filter.receivalState"
        multiple
        hideMultipleSelectionIndicator
      >
        <mat-button-toggle [value]="progressState.PENDING">
          <mat-icon
            color="pending"
            matTooltip="Pending"
            matTooltipPosition="above"
            >receipt_long</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="progressState.ERROR">
          <mat-icon
            color="error"
            matTooltip="Poor Condition"
            matTooltipPosition="above"
            >receipt_long</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="progressState.ACTIVE">
          <mat-icon
            color="warning"
            matTooltip="Okay Condition"
            matTooltipPosition="above"
            >receipt_long</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="progressState.COMPLETE">
          <mat-icon
            color="success"
            matTooltip="Good Condition"
            matTooltipPosition="above"
            >receipt_long</mat-icon
          >
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="filter-item">
      <div class="label">Master Suite</div>
      <span class="spacer"></span>
      <mat-button-toggle-group
        class="filter-toggle-group"
        [(ngModel)]="filter.masterSuiteState"
        multiple
        hideMultipleSelectionIndicator
      >
        <mat-button-toggle [value]="progressState.PENDING">
          <mat-icon
            color="pending"
            matTooltip="Pending"
            matTooltipPosition="above"
            >lightbulb</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="progressState.ACTIVE">
          <mat-icon
            color="warning"
            matTooltip="Pending Settings"
            matTooltipPosition="above"
            >lightbulb</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="progressState.COMPLETE">
          <mat-icon
            color="success"
            matTooltip="Applied"
            matTooltipPosition="above"
            >lightbulb</mat-icon
          >
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="filter-item">
      <div class="label">Ingestion</div>
      <span class="spacer"></span>
      <mat-button-toggle-group
        class="filter-toggle-group"
        [(ngModel)]="filter.ingestionState"
        multiple
        hideMultipleSelectionIndicator
      >
        <mat-button-toggle [value]="progressState.PENDING">
          <mat-icon
            color="pending"
            matTooltip="Pending"
            matTooltipPosition="above"
            >dataset</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="progressState.ACTIVE">
          <mat-icon
            color="warning"
            matTooltip="Partially Ingested"
            matTooltipPosition="above"
            >dataset</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="progressState.COMPLETE">
          <mat-icon
            color="success"
            matTooltip="Ingested"
            matTooltipPosition="above"
            >dataset</mat-icon
          >
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="filter-item">
      <div class="label">Production</div>
      <span class="spacer"></span>
      <mat-button-toggle-group
        class="filter-toggle-group"
        [(ngModel)]="filter.productionState"
        multiple
        hideMultipleSelectionIndicator
      >
        <mat-button-toggle [value]="progressState.PENDING">
          <mat-icon
            color="pending"
            matTooltip="Pending"
            matTooltipPosition="above"
            >camera_alt</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="progressState.ACTIVE">
          <mat-icon
            color="warning"
            matTooltip="Partially Shot"
            matTooltipPosition="above"
            >camera_alt</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="progressState.COMPLETE">
          <mat-icon color="success" matTooltip="Shot" matTooltipPosition="above"
            >camera_alt</mat-icon
          >
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="filter-item">
      <div class="label">Raw Reviews</div>
      <span class="spacer"></span>
      <mat-button-toggle-group
        class="filter-toggle-group"
        [(ngModel)]="filter.productionReviewState"
        multiple
        hideMultipleSelectionIndicator
      >
        <mat-button-toggle [value]="progressState.PENDING">
          <mat-icon
            color="pending"
            matTooltip="Pending"
            matTooltipPosition="above"
            >camera_enhance</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="progressState.ERROR">
          <mat-icon
            color="error"
            matTooltip="Rejected"
            matTooltipPosition="above"
            >camera_enhance</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="progressState.ACTIVE">
          <mat-icon
            color="warning"
            matTooltip="Partially Approved"
            matTooltipPosition="above"
            >camera_enhance</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="progressState.COMPLETE">
          <mat-icon
            color="success"
            matTooltip="Approved"
            matTooltipPosition="above"
            >camera_enhance</mat-icon
          >
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="filter-item">
      <div class="label">Enhancement</div>
      <span class="spacer"></span>
      <mat-button-toggle-group
        class="filter-toggle-group"
        [(ngModel)]="filter.enhancementState"
        multiple
        hideMultipleSelectionIndicator
      >
        <mat-button-toggle [value]="progressState.PENDING">
          <mat-icon
            color="pending"
            matTooltip="Pending"
            matTooltipPosition="above"
            >palette</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="progressState.ERROR">
          <mat-icon
            color="error"
            matTooltip="Internally Rejected"
            matTooltipPosition="above"
            >palette</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="progressState.ACTIVE">
          <mat-icon
            color="warning"
            matTooltip="Partially Enhanced"
            matTooltipPosition="above"
            >palette</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="progressState.COMPLETE">
          <mat-icon
            color="success"
            matTooltip="Enhanced"
            matTooltipPosition="above"
            >palette</mat-icon
          >
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="filter-item">
      <div class="label">Reviews</div>
      <span class="spacer"></span>
      <mat-button-toggle-group
        class="filter-toggle-group"
        [(ngModel)]="filter.reviewState"
        multiple
        hideMultipleSelectionIndicator
      >
        <mat-button-toggle [value]="progressState.PENDING">
          <mat-icon
            color="pending"
            matTooltip="Pending"
            matTooltipPosition="above"
            >reviews</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="progressState.ERROR">
          <mat-icon
            color="error"
            matTooltip="Rejected"
            matTooltipPosition="above"
            >reviews</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="progressState.ACTIVE">
          <mat-icon
            color="warning"
            matTooltip="Partially Approved"
            matTooltipPosition="above"
            >reviews</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="progressState.COMPLETE">
          <mat-icon
            color="success"
            matTooltip="Approved"
            matTooltipPosition="above"
            >reviews</mat-icon
          >
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="filter-item">
      <div class="label">Outputs</div>
      <span class="spacer"></span>
      <mat-button-toggle-group
        class="filter-toggle-group"
        [(ngModel)]="filter.outputsState"
        multiple
        hideMultipleSelectionIndicator
      >
        <mat-button-toggle [value]="progressState.PENDING">
          <mat-icon
            color="pending"
            matTooltip="Pending"
            matTooltipPosition="above"
            >filter</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="progressState.ERROR">
          <mat-icon
            color="error"
            matTooltip="Rejected Assets"
            matTooltipPosition="above"
            >filter</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="progressState.ACTIVE">
          <mat-icon
            color="warning"
            matTooltip="Partially Approved"
            matTooltipPosition="above"
            >filter</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="progressState.INFO">
          <mat-icon
            color="info"
            matTooltip="Ready for Release"
            matTooltipPosition="above"
            >filter</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="progressState.COMPLETE">
          <mat-icon
            color="success"
            matTooltip="Released"
            matTooltipPosition="above"
            >filter</mat-icon
          >
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="filter-item">
      <div class="label">Dispatched</div>
      <span class="spacer"></span>
      <mat-button-toggle-group
        class="filter-toggle-group"
        [(ngModel)]="filter.dispatchState"
        multiple
        hideMultipleSelectionIndicator
      >
        <mat-button-toggle [value]="progressState.PENDING">
          <mat-icon
            color="pending"
            matTooltip="Pending"
            matTooltipPosition="above"
            >delivery_dining</mat-icon
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="progressState.COMPLETE">
          <mat-icon
            color="success"
            matTooltip="Dispatched"
            matTooltipPosition="above"
            >delivery_dining</mat-icon
          >
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div style="display: flex; justify-content: end">
      <button
        mat-raised-button
        color="buttonSuccess"
        class="action-button"
        (click)="filterProducts()"
      >
        Apply
      </button>
    </div>
  </div>
</mat-menu>
