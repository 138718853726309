<div class="content">
  <div class="content-body">
    <div class="content-header">
      <div class="title">Output Formats</div>
      <mat-spinner
        *ngIf="isLoading"
        style="height: 50px !important"
      ></mat-spinner>
    </div>
    <form class="form-setting" [formGroup]="settingForm">
      <div class="input-info">
        <div style="width: 100%; display: flex">
          <mat-form-field
            appearance="outline"
            color="primary"
            class="dialog-input"
            style="width: 78%"
          >
            <mat-label>Name</mat-label>
            <input
              matInput
              formControlName="name"
              [minlength]="3"
              [maxlength]="20"
            />
            <mat-error *ngIf="getControl('name')?.hasError('required')"
              >Name is required</mat-error
            >
            <mat-error
              *ngIf="
                getControl('name')?.hasError('minlength') ||
                getControl('name')?.hasError('pattern')
              "
              >Name must be 3-20 characters, [A-Z, 0-9, -, _]</mat-error
            >
          </mat-form-field>
          <div style="width: 20%; display: flex; justify-content: space-around">
            <mat-checkbox color="primary" [formControl]="stillsCheck">
              Stills
            </mat-checkbox>
            <mat-checkbox color="primary" [formControl]="videosCheck">
              Videos
            </mat-checkbox>
          </div>
        </div>

        <div *ngIf="stillsCheck.value" class="format-type">
          <div class="heading">
            <i class="material-icons">crop</i>
            Stills Configuration
          </div>

          <form class="form-stills" [formGroup]="stillsForm">
            <mat-form-field
              appearance="outline"
              color="primary"
              class="dialog-input"
            >
              <mat-label>Format</mat-label>
              <mat-select formControlName="format">
                <mat-option
                  [value]="fm.value"
                  *ngFor="let fm of formats['image']"
                  >{{ fm.label }}</mat-option
                >
              </mat-select>
              <mat-error *ngIf="getControl('format')?.hasError('required')"
                >Format is required</mat-error
              >
            </mat-form-field>
            <mat-form-field
              appearance="outline"
              color="primary"
              class="dialog-input"
            >
              <mat-label>DPI</mat-label>
              <input
                matInput
                type="number"
                step="1"
                formControlName="dpi"
                min="36"
                max="1200"
                (keypress)="onlyPositiveIntegers($event)"
              />
              <mat-error *ngIf="getControl('dpi')?.hasError('required')"
                >DPI is required</mat-error
              >
              <mat-error *ngIf="getControl('dpi')?.hasError('min')"
                >Minimum value is 36</mat-error
              >
              <mat-error *ngIf="getControl('dpi')?.hasError('max')"
                >Maximum value is 1200</mat-error
              >
            </mat-form-field>
            <mat-form-field
              *ngIf="!getControl('useLongEdge')?.value"
              appearance="outline"
              color="primary"
              class="dialog-input"
            >
              <mat-label>Width</mat-label>
              <input
                matInput
                type="number"
                step="1"
                formControlName="width"
                min="0"
                (keypress)="onlyPositiveIntegers($event)"
              />
              <mat-error *ngIf="getControl('width')?.hasError('min')"
                >Minimum width is 100</mat-error
              >
              <mat-error *ngIf="getControl('width')?.hasError('max')"
                >Maximum width is 8000</mat-error
              >
              <mat-error *ngIf="getControl('height')?.hasError('required')"
                >Both Height and Width Required if subject not
                cropped</mat-error
              >
            </mat-form-field>
            <mat-form-field
              *ngIf="!getControl('useLongEdge')?.value"
              appearance="outline"
              color="primary"
              class="dialog-input"
            >
              <mat-label>Height</mat-label>
              <input
                matInput
                type="number"
                step="1"
                formControlName="height"
                min="0"
                (keypress)="onlyPositiveIntegers($event)"
              />
              <mat-error *ngIf="getControl('height')?.hasError('min')"
                >Minimum height is 100</mat-error
              >
              <mat-error *ngIf="getControl('height')?.hasError('max')"
                >Maximum height is 8000</mat-error
              >
              <mat-error *ngIf="getControl('height')?.hasError('required')"
                >Both Height and Width Required if subject not
                cropped</mat-error
              >
            </mat-form-field>

            <mat-form-field
              *ngIf="getControl('useLongEdge')?.value"
              appearance="outline"
              color="primary"
              class="dialog-input"
            >
              <mat-label>Long Edge</mat-label>
              <input
                matInput
                type="number"
                step="1"
                formControlName="longEdge"
                min="0"
                (keypress)="onlyPositiveIntegers($event)"
              />
              <mat-error *ngIf="getControl('longEdge')?.hasError('min')"
                >Minimum length is 100</mat-error
              >
              <mat-error *ngIf="getControl('longEdge')?.hasError('max')"
                >Maximum length is 8000</mat-error
              >
              <mat-error *ngIf="getControl('longEdge')?.hasError('required')"
                >Both Long and Short edges required if subject not
                cropped</mat-error
              >
            </mat-form-field>
            <mat-form-field
              *ngIf="getControl('useLongEdge')?.value"
              appearance="outline"
              color="primary"
              class="dialog-input"
            >
              <mat-label>Short Edge</mat-label>
              <input
                matInput
                type="number"
                step="1"
                formControlName="shortEdge"
                min="0"
                (keypress)="onlyPositiveIntegers($event)"
              />
              <mat-error *ngIf="getControl('shortEdge')?.hasError('min')"
                >Minimum Short is 100</mat-error
              >
              <mat-error *ngIf="getControl('shortEdge')?.hasError('max')"
                >Maximum Short is 8000</mat-error
              >
              <mat-error *ngIf="getControl('shortEdge')?.hasError('required')"
                >Both Long and Short edges required if subject not
                cropped</mat-error
              >
            </mat-form-field>

            <mat-form-field
              appearance="outline"
              color="primary"
              class="dialog-input"
              *ngIf="getControl('format')?.value === 'Jpg'"
            >
              <mat-label>Color Space</mat-label>
              <mat-select formControlName="colorSpace">
                <mat-option
                  [value]="colorSpace.value"
                  *ngFor="let colorSpace of colorSpaces"
                  >{{ colorSpace.label }}</mat-option
                >
              </mat-select>
              <mat-error *ngIf="getControl('colorSpace')?.hasError('required')"
                >Color Space is required</mat-error
              >
            </mat-form-field>

            <mat-form-field
              appearance="outline"
              class="dialog-input"
              color="primary"
              *ngIf="
                getControl('format')?.value === 'Jpg' ||
                getControl('format')?.value === 'WebP'
              "
            >
              <mat-label>Quality</mat-label>
              <input
                matInput
                type="number"
                step="1"
                formControlName="quality"
                [min]="1"
                [max]="100"
                (keypress)="onlyPositiveIntegers($event)"
              />

              <mat-error *ngIf="getControl('quality')?.hasError('required')"
                >Quality is required</mat-error
              >
              <mat-error *ngIf="getControl('quality')?.hasError('min')"
                >Minimum value is 1</mat-error
              >
              <mat-error *ngIf="getControl('quality')?.hasError('max')"
                >Maximum value is 100</mat-error
              >
            </mat-form-field>

            <mat-form-field
              appearance="outline"
              color="primary"
              class="dialog-input"
              *ngIf="
                getControl('format')?.value === 'Png' ||
                getControl('format')?.value === 'Tiff'
              "
            >
              <mat-label>Bit-depth</mat-label>
              <mat-select formControlName="bitDepth">
                <mat-option
                  [value]="bitDepth.value"
                  *ngFor="let bitDepth of bitDepths"
                  >{{ bitDepth.label }}</mat-option
                >
              </mat-select>
              <mat-error *ngIf="getControl('bitDepth')?.hasError('required')"
                >Bit-depth is required</mat-error
              >
            </mat-form-field>

            <mat-form-field
              appearance="outline"
              color="primary"
              class="dialog-input"
            >
              <mat-label>Additonal Whitespace %</mat-label>
              <input
                matInput
                type="number"
                step="1"
                formControlName="whitespacePercent"
                [min]="1"
                [max]="100"
                (keypress)="onlyPositiveIntegers($event)"
              />
              <mat-hint *ngIf="!getControl('whitespacePercent')?.value"
                >If not set, no additional white space will be added</mat-hint
              >
            </mat-form-field>

            <mat-form-field
              appearance="outline"
              color="primary"
              class="dialog-input"
            >
              <mat-label>Background Colour</mat-label>
              <input
                matInput
                formControlName="backgroundColour"
                (change)="test($event)"
              />
              <ngx-colors
                matSuffix
                style="
                  margin: 0 12px !important;
                  position: relative;
                  display: flex;
                "
                format="hex"
                ngx-colors-trigger
                formControlName="backgroundColour"
                (change)="getControl('backgroundColour')?.setValue($event)"
                [colorPickerControls]="
                  ['Jpg', 'Tiff'].includes(getControl('format')?.value)
                    ? 'no-alpha'
                    : 'default'
                "
              ></ngx-colors>
            </mat-form-field>

            <div style="width: 100%; display: flex; justify-content: center">
              <mat-checkbox
                formControlName="lossless"
                *ngIf="getControl('format')?.value === 'WebP'"
                color="primary"
                (change)="toggleQuality()"
              >
                Lossless Compression
              </mat-checkbox>

              <mat-checkbox formControlName="cropSubject" color="primary">
                Crop Subject
              </mat-checkbox>

              <mat-checkbox formControlName="useLongEdge" color="primary">
                Long Edge
              </mat-checkbox>
            </div>
            <div class="file-name__title">
              <h3>FILENAME FORMATS</h3>
              <i class="material-icons pointer" (click)="updateFilenames(currentFormatsOptions.IMAGES, this.format?.data?.stillFormat?.filenameFormats || defaultFilenameFormat)"c
                >edit</i
              >
            </div>

            <div class="show-config-token">
              <div class="item">
                <div class="header">Assets</div>
                <div class="header format">Filename format</div>
              </div>
              <ng-container>
                <div
                  class="item"
                  *ngFor="
                    let current of currentFormats['images'];
                    let i = index
                  "
                >
                  <div
                    class="show-asset"
                  >
                    {{ current.from + (current.to ? "-" + current.to : "+") }}
                  </div>
                  <div class="show-format">
                    <div *ngFor="let item of current.tokenFormats">
                      {{
                        item.value === "characters"
                          ? item.character
                          : item.value
                      }}
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </form>
        </div>

        <div *ngIf="videosCheck.value" class="format-type">
          <div class="heading">
            <i class="material-icons">smart_display</i>
            Videos Configuration
          </div>
          <form class="form-stills" [formGroup]="videosForm">
            <mat-form-field
              appearance="outline"
              color="primary"
              class="dialog-input"
            >
              <mat-label>Format</mat-label>
              <mat-select formControlName="format">
                <mat-option
                  [value]="fm.value"
                  *ngFor="let fm of formats['video']"
                  >{{ fm.label }}</mat-option
                >
              </mat-select>
              <mat-error *ngIf="getControl('format')?.hasError('required')"
                >Format is required</mat-error
              >
            </mat-form-field>
            <mat-form-field
              appearance="outline"
              color="primary"
              class="dialog-input"
            >
              <mat-label>Width</mat-label>
              <input
                matInput
                type="number"
                step="1"
                formControlName="width"
                min="0"
                (keypress)="onlyPositiveIntegers($event)"
              />
              <mat-error *ngIf="getControl('width')?.hasError('min')"
                >Minimum width is 100</mat-error
              >
              <mat-error *ngIf="getControl('width')?.hasError('max')"
                >Maximum width is 8000</mat-error
              >
              <mat-hint *ngIf="!getControl('width')?.value"
                >If not set, aspect ratio will be preserved</mat-hint
              >
            </mat-form-field>
            <mat-form-field
              appearance="outline"
              color="primary"
              class="dialog-input"
            >
              <mat-label>Height</mat-label>
              <input
                matInput
                type="number"
                step="1"
                formControlName="height"
                min="0"
                (keypress)="onlyPositiveIntegers($event)"
              />
              <mat-error *ngIf="getControl('height')?.hasError('min')"
                >Minimum height is 100</mat-error
              >
              <mat-error *ngIf="getControl('height')?.hasError('max')"
                >Maximum height is 8000</mat-error
              >
              <mat-hint *ngIf="!getControl('height')?.value"
                >If not set, aspect ratio will be preserved</mat-hint
              >
            </mat-form-field>
            <div class="file-name__title">
              <h3>FILENAME FORMATS</h3>
              <i class="material-icons pointer" (click)="updateFilenames(currentFormatsOptions.VIDEOS, this.format?.data?.videoFormat?.filenameFormats || defaultFilenameFormat)"
                >edit</i
              >
            </div>

            <div class="show-config-token">
              <div class="item">
                <div class="header">Assets</div>
                <div class="header format">Filename format</div>
              </div>
              <ng-container>
                <div
                  class="item"
                  *ngFor="
                    let current of currentFormats['videos'];
                    let i = index
                  "
                >
                  <div
                    class="show-asset"
                  >
                    {{ current.from + (current.to ? "-" + current.to : "+") }}
                  </div>
                  <div class="show-format">
                    <div *ngFor="let item of current.tokenFormats">
                      {{
                        item.value === "characters"
                          ? item.character
                          : item.value
                      }}
                    </div>
                    <span class="spacer"></span>
                  </div>
                </div>
              </ng-container>
            </div>
          </form>
        </div>
      </div>

      <div class="action-ctn">
        <button mat-stroked-button (click)="onClose()">Cancel</button>
        <button
          mat-stroked-button
          (click)="onSubmit()"
          color="success"
          class="save"
        >
          Save
        </button>
      </div>
    </form>
  </div>
</div>
