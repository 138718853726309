import {
  Component,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  OnInit,
  AfterViewInit,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { SelectionModel } from '@angular/cdk/collections';
// Services
import { ProductsService } from 'src/app/services/products.service';
import { BatchesService } from 'src/app/services/batches.services';
import {
  EnhancementVariantDetail,
  IMasterSuite,
  ISettingsVariant,
} from 'src/app/models/mastersuites/mastersuite';
// Models
import {
  EProgressState,
  IPreDefinedFilter,
  IProduct,
  IProductStates,
} from 'src/app/models/products/product';
// Dialogs
import { ProductCreateComponent } from '../../home/products/product-create/product-create.component';
import { ProductCreateUploadComponent } from '../../home/products/product-create-upload/product-create-upload.component';
import { ProductUpdateComponent } from '../../home/products/product-update/product-update.component';
// Material Modules
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { DeleteModalComponent } from 'src/app/components/shared/delete-modal/delete-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ValidationErrors } from 'src/app/models/validationErrors';
import { MasterSuitesService } from 'src/app/services/mastersuites.service';
import { IBatch } from 'src/app/models/batches/batch';
import { Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { cloneDeep } from 'lodash';
import { MasterSuiteDialogComponent } from 'src/app/components/shared/master-suite-dialog/master-suite-dialog.component';
import { WarningModalComponent } from 'src/app/components/shared/warning-modal/warning-modal.component';
import { AuthService } from 'src/app/services/auth.service';
import { IPreference, IUserProfile } from 'src/app/models/users/userProfile';

@Component({
  selector: 'app-products-table',
  templateUrl: './products-table.component.html',
  styleUrl: './products-table.component.scss',
})
export class ProductsTableComponent implements OnInit, AfterViewInit {
  currentOrganizationId = '';
  @Input() products: IProduct[] = [];
  @Input() selection: SelectionModel<IProduct> = new SelectionModel<IProduct>(
    true,
    []
  );
  @Input() filter: IProductStates = {
    receivalState: [],
    masterSuiteState: [],
    ingestionState: [],
    productionState: [],
    productionReviewState: [],
    enhancementState: [],
    reviewState: [],
    outputsState: [],
    dispatchState: [],
  };
  private searchFilterSubscription!: Subscription;
  @Input() searchFilter!: Observable<string>;
  @Input() collectionId: string = '';
  @Output() filterProductList = new EventEmitter<IProductStates>();
  @Output() preDefinedFilterProductList = new EventEmitter<string>();
  @Output() updateProductList = new EventEmitter();
  // Service Constructor
  constructor(
    private productsService: ProductsService,
    private mastersuiteService: MasterSuitesService,
    public breakpointObserver: BreakpointObserver,
    public datepipe: DatePipe,
    public dialog: MatDialog,
    private router: Router,
    private _alert: MatSnackBar,
    private authService: AuthService
  ) {}
  dataSource = new MatTableDataSource<IProduct>();
  private destroy$ = new Subject();
  view = 'list';
  colPerScreen = 5;
  readonly breakpoint$ = this.breakpointObserver.observe([
    Breakpoints.XLarge,
    Breakpoints.Large,
    Breakpoints.Medium,
    Breakpoints.Small,
  ]);
  girdList: IProduct[] = [];
  user!: IUserProfile | null;
  // MMAPT Jobs table columns and database variable assignment
  displayedColumns: string[] = [
    'Select',
    'barcode',
    'reference',
    'description',
    'Actions',
  ];
  batch!: IBatch;
  productsSubscription: any;
  masterSuiteSubscription: any;
  serverErrorMessage: String = '';
  errorMessages: string[] = [];
  warning: boolean = false;
  warningMessage: string = '';
  loaded: boolean = false;
  isStaff: boolean = false;
  isAdmin: boolean = false;
  currentConfigDate!: IPreference;
  searchForm: FormControl<any> = new FormControl();
  // Variants Variable assignment, will be dependent on mastersuite
  selectedVariant?: ISettingsVariant;
  variants: ISettingsVariant[] = [];
  enhancementVariants: EnhancementVariantDetail[] = [];
  // Filters
  public progressState = EProgressState;
  readonly preDefinedFilters: IPreDefinedFilter[] = [
    {
      key: 'PendingStillsProduction',
      value: 'Pending Stills ',
      icon: 'camera_alt',
      results: [],
    },
    {
      key: 'PendingVideoProduction',
      value: 'Pending Videos ',
      icon: 'camera_alt',
      results: [],
    },
    {
      key: 'PendingApprovalsProduction',
      value: 'Pending Approvals',
      icon: 'camera_alt',
      results: [],
    },
    {
      key: 'PendingStillsEnhancement',
      value: 'Pending Stills ',
      icon: 'auto_awesome',
      results: [],
    },
    {
      key: 'PendingVideoEnhancement',
      value: 'Pending Videos ',
      icon: 'auto_awesome',
      results: [],
    },
    {
      key: 'PendingApprovalsExternalStillsEnhancement',
      value: 'Pending Stills ',
      icon: 'reviews',
      results: [],
    },
    {
      key: 'PendingApprovalsExternalVideosEnhancement',
      value: 'Pending Videos ',
      icon: 'reviews',
      results: [],
    },
  ];
  selectedfilter!: any;

  ngOnInit(): void {
    this.isStaff = this.authService.getRole('staff');
    this.isAdmin = this.authService.getRole('admin');
    this.authService.currentPreference.subscribe((res) => {
      this.currentConfigDate = res;
    });
    this.user = this.authService.getAuthInformation();
    if (this.isStaff) {
      this.displayedColumns.splice(4, 0, 'masterSuiteName');
      this.displayedColumns.splice(5, 0, 'states');
    } else {
      this.displayedColumns.splice(4, 0, 'status');
    }

    this.breakpoint$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.breakpointChanged();
    });
    this.searchFilterSubscription = this.searchFilter.subscribe(
      (event: string) => this.applyFilter(event)
    );
    this.selection.changed.subscribe((change) => {
      change.added.forEach((product) => {
        this.currentOrganizationId = product.organizationId || '';
      });
    });
  }

  ngAfterViewInit(): void {
    console.log(this.products);
    this.dataSource = new MatTableDataSource<IProduct>(this.products);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = this.onFilterPredicate;
  }

  // MMAPT jobs table variables, for sorting and paginator
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;

  onFilterPredicate = (data: any, filter: string) => {
    const accumulator = (currentTerm: string, key: string) => {
      return this.nestedFilterCheck(currentTerm, data, key);
    };
    const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
    const transformedFilter = filter.trim().toLowerCase();
    return dataStr.indexOf(transformedFilter) !== -1;
  };

  // Applys search filter to the table
  public applyFilter(event: string) {
    let filterValue = event;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filter = filterValue.trim().toUpperCase();
    this.dataSource.data
      .filter((item: any) => {
        const flag = Object.keys(item).some(
          (prop: string) =>
            item[prop] &&
            typeof item[prop] === 'string' &&
            item[prop].toUpperCase().includes(filterValue)
        );
        return flag;
      })
      .sort((a, b) => (b.barcode > a.barcode ? 1 : -1));
  }

  public applyStaticFilter(key: string) {
    this.dataSource.filter = key.trim().toUpperCase();
  }

  public nestedFilterCheck(search: string, data: any, key: string) {
    if (typeof data[key] === 'object') {
      for (const k in data[key]) {
        if (data[key][k] !== null) {
          search = this.nestedFilterCheck(search, data[key], k);
        }
      }
    } else {
      search += data[key];
    }
    return search;
  }

  allEmpty(obj: { [x: string]: any }): boolean {
    return Object.keys(obj).every((k) => obj[k].length == 0);
  }

  pageIndex: number = 0;
  pageSize: number = 0;
  pageNumberChange(event: any) {
    this.productsService.latestProductsList.batchId = this.batch.id;
    this.productsService.latestProductsList.pageIndex = event.pageIndex;
    this.productsService.latestProductsList.pageSize = event.pageSize;

    if (event.pageSize != this.currentConfigDate.pageSize) {
      this.currentConfigDate.pageSize = event.pageSize;
      let payload = {
        name: this.user?.name,
        preference: this.currentConfigDate,
      };
      this.authService.updatePreference(payload).subscribe((res) => {
        console.log(res);
      });
    }
  }
  setAll(obj: { [x: string]: any }, val: any) {
    Object.keys(obj).forEach((k) => (obj[k] = val));
  }

  clearFilters() {
    this.setAll(this.filter, []);
    this.filterProducts();
  }

  filterProducts() {
    this.filterProductList.emit(this.filter);
    console.log(this.filter);
  }

  onPreDefinedFilterChange(key: any) {
    this.preDefinedFilterProductList.emit(key);
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.dataSource.filteredData.forEach((row) => this.selection.select(row));
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected >= numRows;
  }

  selectRow($event: any, row: IProduct) {
    if ($event.srcElement!.nodeName.toLowerCase() == 'td') {
      this.selection.toggle(row);
    }
  }

  openProductUpdateDialog(product: IProduct) {
    const dialogRef = this.dialog.open(ProductUpdateComponent, {
      data: product,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      if (result) {
        product.barcode = result.barcode;
        product.reference = result.reference;
        product.description = result.description;
        product.productLink = result.productLink;
      }
    });
  }

  openProductCreateDialog() {
    const dialogRef = this.dialog.open(ProductCreateComponent);
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      this.updateProductList.emit();
    });
  }

  openProductUploadDialog() {
    const dialogRef = this.dialog.open(ProductCreateUploadComponent);
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      if (result == true) {
        this.updateProductList.emit();
      }
    });
  }

  deleteProduct(product: IProduct) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      data: {
        header: `Delete Product: ${product.barcode} (${product.reference})`,
        message:
          'Are you sure you want to delete this product along with all information?',
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.productsService.deleteProduct(product).subscribe(
          (data) => {
            this._alert.open('Successfully Deleted Product', 'close', {
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
              duration: 2000,
              panelClass: ['success'],
            });
            // Fetch New Products
            this.updateProductList.emit();
          },
          (error: any) => {
            console.log(error);
            error.error.validationErrors.forEach((error: ValidationErrors) => {
              this.errorMessages.push(error.message);
            });
            this._alert.open('Failed To Delete', 'close', {
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
              duration: 2000,
              panelClass: ['error'],
            });
          }
        );
      }
    });
  }

  openProductReceivals(PRODUCT: IProduct) {
    const navigationExtras: NavigationExtras = { state: { product: PRODUCT } };
    this.router.navigate(['product/receivals'], navigationExtras);
  }

  openMasterSuite(product: IProduct) {
    this.mastersuiteService
      .detailMasterSuite(product.masterSuiteId!)
      .subscribe((res) => {
        var productIds = [product.id!];
        this.openMasterSuiteEnhancementVariantDialog(productIds, res);
      });
  }

  openMasterSuiteEnhancementVariantDialog(
    productIds: string[],
    masterSuite: IMasterSuite,
    existingEnhancementVariantId?: string
  ) {
    const dialogRef = this.dialog.open(MasterSuiteDialogComponent, {
      data: {
        masterSuiteId: masterSuite.id,
        masterSuiteName: masterSuite.name,
        productIds: productIds,
        masterSuiteEnhancementVariantId: existingEnhancementVariantId,
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res.enhancementVariants?.length === 0) {
        const dialogRef0 = this.dialog.open(WarningModalComponent, {
          data: {
            header: 'Warning',
            content:
              'Master suite has no enhancement variants, would you like to create one?',
            titleButtonOk: 'Yes',
            showBtnNo: true,
          },
        });
        dialogRef0.afterClosed().subscribe((res) => {
          if (res) {
            this.router.navigate(
              [`mastersuites/${masterSuite.id}/update/enhancement-variant`],
              {
                queryParams: {
                  applyAll: true,
                },
              }
            );
          }
        });
      }
      if (res && !res?.enhancementVariants) {
        this.productsService
          .applyEnhancementVariantOnProducts({
            productIds: productIds,
            enhancementVariantId: res.enhancementVariantSelected.id,
            version: 0,
          })
          .subscribe(() => {
            this._alert.open(
              'Selected enhancement variant applied successfully!',
              'close',
              {
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
                duration: 5000,
                panelClass: ['success'],
              }
            );
          });
      }
    });
  }

  breakpointChanged() {
    switch (true) {
      case this.breakpointObserver.isMatched(Breakpoints.XLarge):
        this.colPerScreen = 5;
        break;

      case this.breakpointObserver.isMatched(Breakpoints.Large):
        this.colPerScreen = 4;
        break;

      case this.breakpointObserver.isMatched(Breakpoints.Medium):
        this.colPerScreen = 3;
        break;

      case this.breakpointObserver.isMatched(Breakpoints.Small):
        this.colPerScreen = 2;
        break;

      default:
        this.colPerScreen = 1;
        break;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
