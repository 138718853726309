<mat-dialog-content class="mat-typography">
    <div>
        <div class="imageContainer">
            <img class="image" *ngIf="user.profileImageUrl !== null && profileImage == undefined;else placeholder"
                [src]="apiBase+user.profileImageUrl | secure | async" alt="">
            <ng-template #placeholder>
                <img class="image" [src]="profileImageFile">
            </ng-template>
            <button mat-mini-fab class="edit" color="buttonSuccess" (click)="imageInput.click()">
                <mat-icon matTooltip="Update">edit</mat-icon>
            </button>
            <input #imageInput hidden="true" type="file" onclick="this.value=null"
                (change)="updateUserProfileImage(imageInput.files!)" accept=".jpg,.png" />
        </div>
        <mat-form-field appearance="outline" color="primary" class="dialog-input">
            <mat-label>Full Name</mat-label>
            <input required [formControl]="nameFormControl" matInput>
            <mat-error *ngIf="validatorProperties.includes('Name')">
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" color="primary" class="dialog-input">
            <mat-label>Email Address</mat-label>
            <input type="email" disabled matInput [(ngModel)]="user.emailAddress">
        </mat-form-field>

        <div *ngIf="user.organizationId == null">
            <mat-form-field appearance="outline" color="primary" class="dialog-input">
                <mat-label>User Groups</mat-label>
                <mat-select [formControl]="mmaptUserGroup" multiple (selectionChange)="onMmaptUserGroupChange()">
                    <mat-option *ngFor="let group of mmaptUserGroups" [value]="group">{{group}}</mat-option>
                </mat-select>
            </mat-form-field>
            <div style="display: flex;">
                <mat-form-field appearance="outline" color="primary" class="dialog-input">
                    <mat-label>{{pinLabel}}</mat-label>
                    <input [formControl]="pinFormControl" maxlength="4" minlength="4" type="password" matInput>
                    <mat-error *ngIf="validatorProperties.includes('PinHash')">
                    </mat-error>
                    <mat-error *ngIf="pinFormControl.value.length < 4">
                        Pin must be 4 digits
                    </mat-error>
                </mat-form-field>
                <mat-checkbox color="primary" [formControl]="changePinFormControl" (change)="onChangePin()"
                    matTooltip="Change Pin"></mat-checkbox>
            </div>

        </div>

        <form class="form-setting" [formGroup]="formData">
            <div class="preference__label">
              <mat-icon>schedule</mat-icon>
              <span>Preference settings</span>
            </div>
            <mat-form-field appearance="outline" color="primary" class="dialog-input">
              <mat-label>Timezone</mat-label>
              <input type="text" matInput formControlName="timezone"
                [matAutocomplete]="auto" (keyup)="onKey($event)">
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option [value]="tz.text" *ngFor="let tz of timezoneListFiltered">{{
                  tz.text
                }}</mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <div class="inline-row">
              <div class="flex-grow-1">
                <mat-form-field
                  appearance="outline"
                  color="primary"
                  class="dialog-input"
                >
                  <mat-label>DateTime Format</mat-label>
                  <mat-select formControlName="dateFormat">
                    <mat-option
                      [value]="el.value"
                      *ngFor="let el of dateTimeFormat"
                      >{{ el.text }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="full-row">
                <mat-checkbox
                  class="example-margin"
                  formControlName="is24H"
                  color="primary"
                >
                  24 hours time
                </mat-checkbox>
              </div>
            </div>
            <div>
              <mat-radio-group
                aria-label="Select an option"
                formControlName="asAgo"
                color="primary"
              >
                <mat-radio-button [value]="true">Days ago</mat-radio-button>
                <mat-radio-button [value]="false">Date/Time</mat-radio-button>
              </mat-radio-group>
            </div>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button mat-button #create color="buttonSuccess" (click)="update()">Save</button>
</mat-dialog-actions>