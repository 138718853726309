import { IOrganization } from './organization';

export interface FormSetting {
  isNew: boolean;
  data: any;
  organization: IOrganization;
}

export interface TokenFormat {
  label: string;
  value: string;
}
export interface OutputFormat {
  id: string;
  name: string;
  configText?: string;
  configTextStills?: string;
  configTextVideos?: string;
  type?: string;
  stillFormat: StillOutputFormat;
  videoFormat: VideoOutputFormat;
}

export interface StillOutputFormat {
  width: number;
  height: number;
  useLongEdge: boolean;
  longEdge: number;
  shortEdge: number;
  format: string;
  quality?: number;
  lossless?: boolean;
  colorSpace?: string;
  dpi: string;
  bitDepth?: string;
  cropSubject?: boolean;
  whitespacePercent?: number;
  backgroundColour?: string;
  filenameFormats: [filenameFormat: FilenameFormat];
}

export interface FilenameFormat {
  id: number | null;
  from: number;
  to: number | null;
  pattern: string;
  indexOffset: number;
  casing: string;
  tokenFormats: TokenFormat[];
}

export enum Casing {
  MATCH = 'Match',
  UPPER = 'Upper',
  LOWER = 'Lower',
}

export enum CurrentFormatsOptions {
  IMAGES = 'images',
  VIDEOS = 'videos',
}

export interface VideoOutputFormat {
  width: number;
  height: number;
  format: string;
  filenameFormats: [filenameFormat: FilenameFormat];
}

export interface OutputFormatSearch {
  id: string;
  name: string;
  width: number;
  height: number;
  filenameFormats: [
    {
      id: number;
      from: number;
      to: number;
      pattern: string;
    }
  ];
  format: string;
  quality?: number;
  colorSpace?: string;
}
