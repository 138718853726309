<div class="auto-suggestion">
  <input
    matInput
    type="text"
    id="search"
    [ngClass]="{ showing: isShowing }"
    [formControl]="suggestion"
    placeholder="Search a product"
    (keyup)="handleSuggestion($event)"
    #input
  />
  <i class="material-icons icon" (click)="search()">search</i>
  <!-- <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="handleSuggestion($event)"
  >
    <mat-option *ngFor="let item of suggestions" [value]="item">
      {{ item }}
    </mat-option>
  </mat-autocomplete> -->
</div>
