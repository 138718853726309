import { Component } from '@angular/core';
// Services
import { BatchesService } from 'src/app/services/batches.services';
// Models
import { IBatch } from 'src/app/models/batches/batch';
import { Subject, takeUntil } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizationsService } from 'src/app/services/organizations.service';

@Component({
  selector: 'app-batch-details',
  templateUrl: './batch-details.component.html',
  styleUrls: ['./batch-details.component.scss'],
})
export class BatchDetailsComponent {
  currentBatch!: IBatch;
  batchSubscription: any;
  errorState: Boolean = false;
  errorMessages: string[] = [];
  serverErrorMessage: String = '';
  loaded: boolean = false;
  private destroy$ = new Subject();

  constructor(
    private batchesService: BatchesService,
    private router: Router,
    private route: ActivatedRoute,
    private organizationsService: OrganizationsService
  ) {}

  ngOnInit(): void {
    const batch = this.batchesService.getCurrentBatch();
    const batchId = this.route.snapshot.paramMap.get('batchId');
    console.log('batchId', batchId);
    if (!batch && batchId) {
      this.batchesService
        .detailsBatch(batchId)
        .pipe(takeUntil(this.destroy$))
        .subscribe((batch) => {
          this.currentBatch = batch;
          this.loaded = true;
        });
    } else {
      this.loaded = true;
      this.currentBatch = batch;
    }
  }

  // Set current job when pressing manage
  manageBatch(batch: IBatch) {
    this.batchesService.setCurrentBatch(batch);
    this.organizationsService
      .getOrganization(batch.organizationId!)
      .pipe()
      .subscribe((organization) => {
        this.organizationsService.setCurrentOrg(organization);
      });
    this.router.navigateByUrl(`batches/${batch.id}/products`);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
