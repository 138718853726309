import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Setting } from '../models/settings/setting';

const httpOptions = {
  headers: new HttpHeaders({
  'Content-Type': 'application/json',
  Accept: 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private http: HttpClient) { }

  listSettings(orgId:string | null):Observable<Setting[]>{
    var url = `${environment.apiBase}/settings?org_id=${orgId}`;
    console.log('Getting Settings');
    return this.http.get<Setting[]>(url, httpOptions)
  }

  createSetting(newSetting:Setting):Observable<any>{
    var url = `${environment.apiBase}/settings`;
    console.log('Creating New Setting: ', newSetting);
    return this.http.post<Setting>(url, newSetting, httpOptions)
  }

  deleteSetting(setting:Setting):Observable<any>{
    console.log('Deleting Setting: ', setting);
    var url = `${environment.apiBase}/settings/${setting.id}`;
    return this.http.delete<any>(url, httpOptions)
  }

  updateSetting(updateSetting:Setting):Observable<any>{
    var url = `${environment.apiBase}/settings/${updateSetting.id}`;
    console.log('Updating Setting: ', updateSetting);
    return this.http.put<Setting>(url, updateSetting, httpOptions)
  }

  detailSetting(id:string):Observable<Setting>{
    var url = `${environment.apiBase}/settings/${id}`;
    return this.http.get<Setting>(url, httpOptions)
  }
}
