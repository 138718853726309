import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { IBatch } from 'src/app/models/batches/batch';
import { IOutputsCollection } from 'src/app/models/outputsCollection';
import { IProduct, ProductSearch } from 'src/app/models/products/product';
import { AuthService } from 'src/app/services/auth.service';
import { OutputsCollectionsService } from 'src/app/services/outputs-collections.service';
import { ProductsService } from 'src/app/services/products.service';
import { SignalrService } from 'src/app/services/signalr.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  activeJobs: IBatch[] = [];
  colPerScreen = 5;
  recentlyShotList: IProduct[] = [];
  recentlyShotGroup: string = '';

  recentlyEnhancedList: IProduct[] = [];
  recentlyEnhancedGroup: string = '';

  recentlyReviewedList: IProduct[] = [];
  recentlyReviewedGroup: string = '';
  isStaff: boolean = false;

  latestCollcetions: IOutputsCollection[] = [];
  private destroy$ = new Subject();

  readonly breakpoint$ = this.breakpointObserver.observe([
    Breakpoints.XLarge,
    Breakpoints.Large,
    Breakpoints.Medium,
    Breakpoints.Small,
    Breakpoints.XSmall,
  ]);

  constructor(
    private outputsCollectionsService: OutputsCollectionsService,
    private productService: ProductsService,
    public breakpointObserver: BreakpointObserver,
    private router: Router,
    private authService: AuthService,
    private signalrService: SignalrService
  ) {}

  ngOnInit() {
    this.isStaff = this.authService.getRole('staff');
    this.breakpoint$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.breakpointChanged();
    });

    if (this.isStaff) {
      this.getRecentlyShot();
      this.getRecentlyEnhanced();
      this.getRecentlyReviewed();

      this.signalrService.connectState.subscribe({
        next: (data: boolean) => {
          if (data) {
            this.joinGroups();
          }
        },
      });
      this.subscribeToData();
    } else {
      this.listOutputsCollection();
    }
  }

  listOutputsCollection() {
    this.outputsCollectionsService.listOutputsCollection().subscribe((res) => {
      this.latestCollcetions = res.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
      var index = 0;
      for (let x of this.latestCollcetions) {
        x.products = [];
        if (index >= 2) {
          break;
        }
        this.outputsCollectionsService
          .getOutputsCollectionProducts(x.id!)
          .subscribe((products) => {
            x.products = products;
          });
        index++;
      }
    });
  }

  joinGroups() {
    this.signalrService
      .joinStaticGroup('JoinProductsShotGroup')
      ?.then((group: string) => (this.recentlyShotGroup = group));

    this.signalrService
      .joinStaticGroup('JoinProductsEnhancedGroup')
      ?.then((group: string) => (this.recentlyEnhancedGroup = group));

    this.signalrService
      .joinStaticGroup('JoinProductsReviewedGroup')
      ?.then((group: string) => (this.recentlyReviewedGroup = group));
  }

  subscribeToData() {
    this.signalrService.productsShotData.subscribe({
      next: (data: ProductSearch) => {
        console.log(typeof data);
        if (data.id) {
          var existing = this.recentlyShotList.find((x) => x.id == data.id);
          if (existing) {
            this.recentlyShotList.unshift(data);
            var index = this.recentlyShotList.indexOf(existing);
            this.recentlyShotList.splice(index, 1);
          } else {
            this.recentlyShotList.unshift(data);
            this.recentlyShotList.pop();
          }
        }
      },
    });

    this.signalrService.productsEnhancedData.subscribe({
      next: (data: ProductSearch) => {
        console.log(typeof data);
        if (data.id) {
          var existing = this.recentlyEnhancedList.find((x) => x.id == data.id);
          if (existing) {
            this.recentlyEnhancedList.unshift(data);
            var index = this.recentlyEnhancedList.indexOf(existing);
            this.recentlyEnhancedList.splice(index, 1);
          } else {
            this.recentlyEnhancedList.unshift(data);
            this.recentlyEnhancedList.pop();
          }
        }
      },
    });

    this.signalrService.productsReviewedData.subscribe({
      next: (data: ProductSearch) => {
        console.log(typeof data);
        if (data.id) {
          var existing = this.recentlyReviewedList.find((x) => x.id == data.id);
          if (existing) {
            this.recentlyReviewedList.unshift(data);
            var index = this.recentlyReviewedList.indexOf(existing);
            this.recentlyReviewedList.splice(index, 1);
          } else {
            this.recentlyReviewedList.unshift(data);
            this.recentlyReviewedList.pop();
          }
        }
      },
    });
  }

  breakpointChanged() {
    switch (true) {
      case this.breakpointObserver.isMatched(Breakpoints.XLarge):
        this.colPerScreen = 7;
        break;
      case this.breakpointObserver.isMatched(Breakpoints.Large):
        this.colPerScreen = 6;
        break;
      case this.breakpointObserver.isMatched(Breakpoints.Medium):
        this.colPerScreen = 5;
        break;
      case this.breakpointObserver.isMatched(Breakpoints.Small):
        this.colPerScreen = 3;
        break;
      case this.breakpointObserver.isMatched(Breakpoints.XSmall):
        this.colPerScreen = 2;
        break;
      default:
        this.colPerScreen = 1;
        break;
    }
  }

  getRecentlyShot() {
    this.productService
      .searchByCode(
        undefined,
        undefined,
        7,
        undefined,
        undefined,
        'RecentlyShot'
      )
      .subscribe((res) => {
        this.recentlyShotList = res.results;
      });
  }
  getRecentlyEnhanced() {
    this.productService
      .searchByCode(
        undefined,
        undefined,
        7,
        undefined,
        undefined,
        'RecentlyEnhanced'
      )
      .subscribe((res) => {
        this.recentlyEnhancedList = res.results;
      });
  }
  getRecentlyReviewed() {
    this.productService
      .searchByCode(
        undefined,
        undefined,
        7,
        undefined,
        undefined,
        'RecentlyApprovedExternalEnhancement'
      )
      .subscribe((res) => {
        this.recentlyReviewedList = res.results;
      });
  }

  viewMore(filter: string) {
    this.router.navigateByUrl(`product-filter?key=${filter}`);
  }

  manageCollection(id: string) {
    this.router.navigateByUrl(`/collections/${id}/products`);
  }

  downloadCollection(downloadUrl: string, id: string) {
    this.outputsCollectionsService
      .downloadOutputsCollection(id)
      .subscribe((res) => {
        console.log('Downloading');
      });
    window.open(downloadUrl, '_blank');
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.leaveGroups();
  }

  leaveGroups() {
    this.signalrService.leaveGroup(this.recentlyShotGroup);
    this.signalrService.leaveGroup(this.recentlyEnhancedGroup);
    this.signalrService.leaveGroup(this.recentlyReviewedGroup);
    this.signalrService.clearData();
  }
}
