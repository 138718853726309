<div *ngFor="let variant of settingsVariants; let i = index">
  <mat-expansion-panel
    class="box"
    [ngClass]="{
      warning:
        variant.settingsVariantTouches.length !=
        masterSuite.masterSuiteTouches.length
    }"
    [disabled]="variant.id == null"
    [expanded]="displayed[i]"
    *ngIf="settingsLoaded"
    (closed)="closeVariant(i)"
  >
    <mat-expansion-panel-header
      style="height: 70px"
      [ngStyle]="{
        height: displayed[i] ? '110px' : '70px'
      }"
    >
      <mat-panel-title class="title-heading" *ngIf="readonly[i]">
        {{ variant.name }}
        <span class="spacer"></span>

        <button
          *ngIf="readonly[i]"
          mat-icon-button
          matTooltip="Edit"
          color="success"
          class="mat-elevation-z0"
          (click)="$event.stopPropagation()"
          (click)="editVariant(variant, i)"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button
          *ngIf="readonly[i]"
          mat-icon-button
          matTooltip="Delete"
          color="error"
          class="mat-elevation-z0"
          (click)="$event.stopPropagation()"
          (click)="removeVariant(variant, i, $event)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </mat-panel-title>
      <mat-panel-title *ngIf="!readonly[i]">
        <mat-form-field
          (click)="$event.stopPropagation()"
          appearance="outline"
          matBadge="!"
          matBadgeColor="warn"
          matBadgeSize="small"
          [matBadgeHidden]="
            variant.settingsVariantTouches.length ==
            masterSuite.masterSuiteTouches.length
          "
          style="padding-top: 27px"
        >
          <mat-label>Settings Variant Name</mat-label>
          <input
            required
            type="text"
            matInput
            [formControl]="settingsVariantsFormControl[i].Name"
            [(ngModel)]="variant.name"
            (keydown.Space)="$event.stopImmediatePropagation()"
            [readonly]="readonly[i]"
          />
          <mat-error
            *ngIf="
              validatorProperties.includes(
                'MasterSuiteVariants[' + i + '].Name'
              )
            "
          >
            {{ validationMessage("MasterSuiteVariants[" + i + "].Name") }}
          </mat-error>
        </mat-form-field>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div
      *ngFor="let variantTouch of variant.settingsVariantTouches; let j = index"
    >
      <div class="asset-heading">
        Touch {{ variantTouch.masterSuiteTouchIndex / 1000 + 1 }}:
        <i>{{ variantTouch.masterSuiteTouchName }}</i>
      </div>
      <mat-grid-list cols="12" rowHeight="100px">
        <mat-grid-tile colspan="12">
          <mat-form-field appearance="outline" class="action-form">
            <mat-label>Setting</mat-label>
            <input
              type="text"
              matInput
              [(ngModel)]="variantTouch.settingId"
              [formControl]="settingsVariantsFormControl[i].Setting[j]"
              [matAutocomplete]="auto"
              [readonly]="readonly[i]"
            />
            <mat-autocomplete
              autoActiveFirstOption
              #auto="matAutocomplete"
              [displayWith]="displaySettingFn.bind(this)"
            >
              <mat-option
                *ngFor="let setting of settingsFilteredOptions[i][j] | async"
                [value]="setting.id"
              >
                {{ setting.name }}
              </mat-option>
            </mat-autocomplete>
            <button
              matSuffix
              [disabled]="variantTouch.settingId == null"
              mat-icon-button
              color="info"
              matTooltip="Details"
              matTooltipPosition="above"
              (click)="viewSetting(variantTouch.settingId)"
            >
              <mat-icon>info</mat-icon>
            </button>
            <mat-error
              *ngIf="
                validatorProperties.includes(
                  'MasterSuiteVariants[' +
                    i +
                    '].SettingsVariantTouch[' +
                    j +
                    '].Setting'
                )
              "
            >
              {{
                validationMessage(
                  "MasterSuiteVariants[" +
                    i +
                    "].SettingsVariantTouch[" +
                    j +
                    "].Setting"
                )
              }}
            </mat-error>
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
    <div class="content-actions" *ngIf="!readonly[i]">
      <span class="spacer"></span>
      <button
        mat-stroked-button
        (click)="
          variant.id == null
            ? cancelVariantCreate(variant, i, $event)
            : closeVariant(i)
        "
      >
        Cancel
      </button>
      <button
        mat-button
        color="buttonSuccess"
        style="margin-left: 20px"
        (click)="saveVariant(variant, i)"
      >
        {{ variant.id == null ? "Create" : "Update" }}
      </button>
    </div>
  </mat-expansion-panel>

  <!-- <mat-card
    *ngIf="settingsLoaded"
    class="box"
    [ngClass]="{
      warning:
        variant.settingsVariantTouches.length !=
        masterSuite.masterSuiteTouches.length
    }"
  >
    <mat-card-header style="align-items: center">
      <mat-form-field
        (click)="$event.stopPropagation()"
        appearance="outline"
        matBadge="!"
        matBadgeColor="warn"
        matBadgeSize="small"
        [matBadgeHidden]="
          variant.settingsVariantTouches.length ==
          masterSuite.masterSuiteTouches.length
        "
      >
        <mat-label>Settings Variant Name</mat-label>
        <input
          required
          type="text"
          matInput
          [formControl]="settingsVariantsFormControl[i].Name"
          [(ngModel)]="variant.name"
          (keydown.Space)="$event.stopImmediatePropagation()"
          [readonly]="readonly[i]"
        />
        <mat-error
          *ngIf="
            validatorProperties.includes('MasterSuiteVariants[' + i + '].Name')
          "
        >
          {{ validationMessage("MasterSuiteVariants[" + i + "].Name") }}
        </mat-error>
      </mat-form-field>

      <span class="spacer"></span>

      <button
        *ngIf="readonly[i]"
        mat-icon-button
        matTooltip="Edit"
        color="success"
        class="mat-elevation-z0"
        (click)="editVariant(variant, i)"
      >
        <mat-icon>edit</mat-icon>
      </button>

      <button
        *ngIf="readonly[i]"
        mat-icon-button
        matTooltip="Delete"
        color="error"
        class="mat-elevation-z0"
        (click)="removeVariant(variant, i, $event)"
      >
        <mat-icon>delete</mat-icon>
      </button>

      <button
        *ngIf="!readonly[i]"
        mat-icon-button
        matTooltip="Cancel"
        color="primary"
        class="mat-elevation-z0"
        (click)="cancelVariantCreate(variant, i, $event)"
      >
        <mat-icon>close</mat-icon>
      </button>
      <button
        *ngIf="!readonly[i]"
        mat-icon-button
        matTooltip="Save"
        class="mat-elevation-z0"
        color="success"
        (click)="saveVariant(variant, i)"
      >
        <mat-icon>save</mat-icon>
      </button>
    </mat-card-header>
    <mat-card-content>
      <div
        *ngFor="
          let variantTouch of variant.settingsVariantTouches;
          let j = index
        "
      >
        <div class="asset-heading">
          Touch {{ variantTouch.masterSuiteTouchIndex / 1000 + 1 }}:
          <i>{{ variantTouch.masterSuiteTouchName }}</i>
        </div>
        <mat-grid-list cols="12" rowHeight="100px">
          <mat-grid-tile colspan="12">
            <mat-form-field appearance="outline" class="action-form">
              <mat-label>Setting</mat-label>
              <input
                type="text"
                matInput
                [(ngModel)]="variantTouch.settingId"
                [formControl]="settingsVariantsFormControl[i].Setting[j]"
                [matAutocomplete]="auto"
                [readonly]="readonly[i]"
              />
              <mat-autocomplete
                autoActiveFirstOption
                #auto="matAutocomplete"
                [displayWith]="displaySettingFn.bind(this)"
              >
                <mat-option
                  *ngFor="let setting of settingsFilteredOptions[i][j] | async"
                  [value]="setting.id"
                >
                  {{ setting.name }}
                </mat-option>
              </mat-autocomplete>
              <button
                matSuffix
                [disabled]="variantTouch.settingId == null"
                mat-icon-button
                color="info"
                matTooltip="Details"
                matTooltipPosition="above"
                (click)="viewSetting(variantTouch.settingId)"
              >
                <mat-icon>info</mat-icon>
              </button>
              <mat-error
                *ngIf="
                  validatorProperties.includes(
                    'MasterSuiteVariants[' +
                      i +
                      '].VariantTouch[' +
                      j +
                      '].Setting'
                  )
                "
              >
                {{
                  validationMessage(
                    "MasterSuiteVariants[" +
                      i +
                      "].VariantTouch[" +
                      j +
                      "].Setting"
                  )
                }}
              </mat-error>
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </mat-card-content>
  </mat-card> -->
</div>
