import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ConfigStep } from 'src/app/models/custom-step';

@Component({
  selector: 'app-custom-step',
  templateUrl: './custom-step.component.html',
  styleUrls: ['./custom-step.component.scss'],
})
export class CustomStepComponent {
  @Input() activeStep = 0;
  @Input() config: ConfigStep[] = [];
  @Input() readonly: boolean = true;
  @Output() change = new EventEmitter();

  indexActive = 0;

  ngOnInit() {
    this.setIndexActive();
  }

  ngOnChanges() {
    this.setIndexActive();
  }

  setIndexActive() {
    this.indexActive = this.config.findIndex(
      (el: any) => el.id === this.activeStep
    );
  }

  onChange(id: number) {
    this.change.emit(id);
  }
}
