<div *ngIf="packagesLoaded">
  <app-custom-step
    [activeStep]="tabIndex"
    [readonly]="readonly"
    [config]="stepperConfig"
    (change)="onStepSelected($event)"
  ></app-custom-step>
  <div class="sub-content" *ngIf="tabIndex === 0">
    <div
      class="content__tab"
      style="--readonly: {{ readonly ? '66px' : '0px' }}"
    >
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: baseline;
        "
      >
        <mat-form-field
          *ngIf="!!masterSuite"
          appearance="outline"
          color="primary"
          style="width: 90%"
        >
          <mat-label>Master Suite Name</mat-label>
          <input
            [readonly]="readonly"
            required
            [formControl]="masterSuiteFormControl.name"
            matInput
            [(ngModel)]="masterSuite.name"
          />
          <mat-error *ngIf="validatorProperties.includes('Name')">
            {{ validationMessage("Name") }}
          </mat-error>
        </mat-form-field>
        <mat-slide-toggle [(ngModel)]="collapsed" style="padding: 5px"
          >Collapse Assets</mat-slide-toggle
        >
      </div>
      <div
        cdkDropList
        [cdkDropListDisabled]="readonly"
        (cdkDropListDropped)="dragDropTouch($event)"
        class="list"
      >
        <mat-card
          *ngFor="let touch of masterSuite.masterSuiteTouches; let i = index"
          cdkDrag
          style="margin-bottom: 10px"
          class="box"
        >
          <mat-card-header style="align-items: center">
            <div *ngIf="!readonly" class="handle" cdkDragHandle>
              <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                <path
                  d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"
                ></path>
                <path d="M0 0h24v24H0z" fill="none"></path>
              </svg>
            </div>
            <mat-card-title class="sub-heading">
              Touch {{ i + 1 }}
            </mat-card-title>
            <span class="spacer"></span>
            <button
              *ngIf="!readonly"
              mat-icon-button
              matTooltip="Delete"
              color="error"
              class="mat-elevation-z0"
              (click)="removeTouch(touch, i)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </mat-card-header>

          <mat-card-content>
            <div class="row">
              <mat-form-field appearance="outline">
                <mat-label>Touch Name</mat-label>
                <input
                  [readonly]="readonly"
                  required
                  type="text"
                  [formControl]="masterSuiteFormControl.touches[i].Name"
                  matInput
                  [(ngModel)]="touch.name"
                />
                <mat-error
                  *ngIf="
                    validatorProperties.includes(
                      'MasterSuiteTouches[' + i + '].Name'
                    )
                  "
                >
                  {{ validationMessage("MasterSuiteTouches[" + i + "].Name") }}
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" class="action-form">
                <mat-label>Package</mat-label>
                <input
                  [readonly]="readonly"
                  type="text"
                  placeholder="Pick one"
                  aria-label="Package"
                  matInput
                  [(ngModel)]="touch.packageId"
                  [formControl]="masterSuiteFormControl.touches[i].Package"
                  [matAutocomplete]="auto"
                />
                <mat-autocomplete
                  autoActiveFirstOption
                  #auto="matAutocomplete"
                  [displayWith]="displayPackageFn.bind(this)"
                >
                  <mat-option
                    *ngFor="let package of packagesFilteredOptions[i] | async"
                    [value]="package.id"
                    (onSelectionChange)="onTouchPackageSelection(package, i)"
                  >
                    {{ package.name }}
                  </mat-option>
                </mat-autocomplete>

                <button
                  matSuffix
                  [disabled]="touch.packageId == null"
                  mat-icon-button
                  color="info"
                  matTooltip="Details"
                  matTooltipPosition="above"
                  (click)="viewPackage(touch.packageId)"
                >
                  <mat-icon>info</mat-icon>
                </button>

                <mat-error
                  *ngIf="
                    validatorProperties.includes(
                      'MasterSuiteTouches[' + i + '].Package'
                    )
                  "
                >
                  {{
                    validationMessage("MasterSuiteTouches[" + i + "].Package")
                  }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field appearance="outline" class="action-form">
                <mat-label>Processing Notes</mat-label>
                <textarea
                  [readonly]="readonly"
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  [(ngModel)]="touch.notes"
                  matInput
                  cdkAutosizeMinRows="1"
                ></textarea>
                <button
                  *ngIf="!readonly"
                  matSuffix
                  [disabled]="touch.notes == null"
                  mat-icon-button
                  color="success"
                  matTooltip="Save Notes"
                  matTooltipPosition="above"
                  (click)="updateNotes(touch)"
                >
                  <mat-icon>save</mat-icon>
                </button>
                <mat-hint *ngIf="touch.notesUpdatedBy != null">
                  Last updated by {{ touch.notesUpdatedByName }},
                  {{
                    touch.notesUpdatedAt
                      | dateAgo : currentConfigDate : false
                      | async
                  }}
                </mat-hint>
              </mat-form-field>
            </div>
            <div
              *ngIf="collapsed == false"
              class="asset-list"
              [ngStyle]="{
                'grid-template-columns':
                  'repeat(' + colPerScreen + ',minmax(0,1fr))'
              }"
            >
              <div *ngFor="let asset of parseAssets(touch)">
                <app-mastersuite-touch-asset-card
                  [data]="asset"
                  [readonly]="readonly"
                  (add)="addAdditionalAsset(asset)"
                  (remove)="removeAdditionalAsset(touch, asset)"
                  (placeholder)="shootPlaceholderChange($event, asset)"
                >
                </app-mastersuite-touch-asset-card>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>

  <div class="sub-content" *ngIf="tabIndex === 1">
    <div class="content__tab" style="--readonly: 46px">
      <app-settings-variant-content
        [masterSuite]="masterSuite"
      ></app-settings-variant-content>
    </div>
  </div>

  <div class="sub-content" *ngIf="tabIndex === 2">
    <div class="content__tab" style="--readonly: 46px">
      <app-enhancement-variant-content
        [colPerScreen]="colPerScreen"
      ></app-enhancement-variant-content>
    </div>
  </div>
</div>
