import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';

import { from, Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SignalrService } from '../services/signalr.service';

/**
 * This will append jwt token for the http requests.
 *
 * @export
 * @class JwtInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private signalrService: SignalrService) {}
  auth: any;

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.includes(environment.s3Base)) {
      request = request.clone({
        withCredentials: true,
      });
      return next.handle(request);
    }
    return from(Auth.currentSession()).pipe(
      switchMap((auth) => {
        // switchMap() is used instead of map().
        this.auth = auth;
        let accessToken = auth.getAccessToken();
        let jwt = accessToken.getJwtToken();

        let with_auth_request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${jwt}`,
            'x-connection-id':this.signalrService.connectionId.toString(),
          },
        });
        return next.handle(with_auth_request);
      }),
      catchError((err) => {
        if (err.status === 401) {
          return next.handle(request);
        } else {
          let accessToken = this.auth.getAccessToken();
          let jwt = accessToken.getJwtToken();
          let with_auth_request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${jwt}`,
            },
          });
          //console.log("Cloned",with_auth_request);
          return next.handle(with_auth_request);
        }
      })
    );
  }
}
