<mat-progress-bar *ngIf="creating == true" mode="query"></mat-progress-bar>
<h1 mat-dialog-title style="font-size: 30px;">Product Upload</h1>
<mat-dialog-content class="mat-typography">
    <div>
        <mat-form-field appearance="outline" color="primary" subscriptSizing="dynamic" class="dialog-input">
            <mat-label>File Input</mat-label>
            <button mat-icon-button matPrefix (click)="f_input.click()">
                <mat-icon>attach_file</mat-icon>
            </button>
            <input type="text" readonly matInput [formControl]="display" />
            <input type="file" #f_input (change)="fileChange(f_input.files!)" hidden placeholder="Upload File"
                accept=".xml,.xlsx">
        </mat-form-field>

        <div *ngIf="successState == true">

            <mat-expansion-panel hideToggle [disabled]="uploadResults.uploadedCount == 0" style="margin-top: 10px;">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Products Uploaded: {{uploadResults.uploadedCount}}
                    </mat-panel-title>
                    <mat-panel-description style="flex-grow: 0;">
                        <mat-icon color="Complete">check</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div style="overflow-x: auto;">
                    <table mat-table [dataSource]="uploadedDataSource" class="table" matSort matSortActive="barcode"
                        matSortDirection="desc">

                        <!-- Barcode Column -->
                        <ng-container matColumnDef="barcode">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Barcode </th>
                            <td mat-cell *matCellDef="let product"> {{product.barcode}} </td>
                        </ng-container>

                        <!-- Reference Column -->
                        <ng-container matColumnDef="reference">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Reference </th>
                            <td mat-cell *matCellDef="let product"> {{product.reference}} </td>
                        </ng-container>

                        <!-- Description Column -->
                        <ng-container matColumnDef="description">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
                            <td mat-cell *matCellDef="let product"> {{product.description}} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="uploadedDisplayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: uploadedDisplayedColumns;"></tr>
                    </table>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel hideToggle [disabled]="uploadResults.invalidCount == 0" style="margin-top: 10px;">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Invalid Products: {{uploadResults.invalidCount}}
                    </mat-panel-title>
                    <mat-panel-description style="flex-grow: 0;">
                        <mat-icon color="warn">warning</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div style="overflow-x: auto;">
                    <table mat-table [dataSource]="invalidDataSource" class="table" matSort matSortActive="barcode"
                        matSortDirection="desc">

                        <!-- Barcode Column -->
                        <ng-container matColumnDef="barcode">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Barcode </th>
                            <td mat-cell *matCellDef="let product"> {{product.barcode}} </td>
                        </ng-container>

                        <!-- Reference Column -->
                        <ng-container matColumnDef="reference">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Reference </th>
                            <td mat-cell *matCellDef="let product"> {{product.reference}} </td>
                        </ng-container>

                        <!-- Description Column -->
                        <ng-container matColumnDef="description">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
                            <td mat-cell *matCellDef="let product"> {{product.description}} </td>
                        </ng-container>

                        <!-- Validation Column -->
                        <ng-container matColumnDef="validation">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Validation </th>
                            <td mat-cell *matCellDef="let product">
                                <div *ngFor="let error of product.validationErrors">
                                    {{error.message}}
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="invalidDisplayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: invalidDisplayedColumns;"></tr>
                    </table>
                </div>
            </mat-expansion-panel>
        </div>
    </div>

</mat-dialog-content>
<mat-dialog-actions *ngIf="successState == true">
    <button mat-stroked-button (click)="onClose(successState)">Done</button>
</mat-dialog-actions>

<mat-dialog-actions *ngIf="successState == false">
    <button mat-stroked-button (click)="onClose(successState)">Close</button>
    <button *ngIf="pendingState == true" mat-button (click)="createProducts()" #create disabled="true"
        color="Pending">Upload</button>
    <button *ngIf="pendingState == false" mat-button (click)="createProducts()" #create
        color="buttonSuccess">Upload</button>
</mat-dialog-actions>