<div class="content">
  <div class="content-header">
    <div class="title">Output Collections</div>
    <span class="spacer"></span>
  </div>
  <div style="overflow-x: auto">
    <table mat-table [dataSource]="dataSource" matSort>
      <!-- Client Column -->
      <ng-container matColumnDef="organizationName" *ngIf="isStaff">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Organization</th>
        <td mat-cell *matCellDef="let collection">
          {{ collection.organizationName }}
        </td>
      </ng-container>

      <!-- collection Number Column -->
      <ng-container matColumnDef="jobNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Job Number</th>
        <td mat-cell *matCellDef="let collection">
          {{ collection.jobNumber }}
        </td>
      </ng-container>

      <!-- collection Name Column -->
      <ng-container matColumnDef="jobName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Job Name</th>
        <td mat-cell *matCellDef="let collection">{{ collection.jobName }}</td>
      </ng-container>

      <!-- collection Description Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
        <td mat-cell *matCellDef="let collection">
          <b
            >{{ collection.amends ? "Amends Collection " : "Collection " }}
            {{
              collection.amends
                ? collection.amendsIndex + 1
                : collection.index + 1
            }}</b
          >
          - Product Count: {{ collection.productIds.length }}, Asset Count:
          {{ collection.outputAssetsCount }}
        </td>
      </ng-container>

      <!-- Product Count Column -->
      <ng-container matColumnDef="releasedAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sort>
          Released Date
        </th>
        <td mat-cell *matCellDef="let collection">
          <div
            matTooltip="{{
              collection.createdAt | dateAgo : currentConfigDate : true | async
            }} by {{ collection.createdByUserName }}"
            matTooltipPosition="before"
          >
            {{ collection.createdAt | dateAgo : currentConfigDate | async }}
          </div>
        </td>
      </ng-container>

      <!-- Client Status Column -->
      <ng-container matColumnDef="status">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          style="width: 190px"
        >
          Status
        </th>
        <td mat-cell *matCellDef="let collection">
          <div
            class="status-text"
            matTooltipPosition="above"
            [ngClass]="{
                  complete: collection.downloadArchiveUrl != null,
                  info: collection.downloadArchiveUrl == null,
                }"
          >
            {{
              collection.downloadArchiveUrl == null
                ? "Zipping Collection"
                : "Ready To Download"
            }}
          </div>
        </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef style="width: 150px"></th>
        <td mat-cell *matCellDef="let collection">
          <button
            mat-icon-button
            matTooltip="Download Collection"
            [disabled]="collection.downloadArchiveUrl == null"
            (click)="
              downloadCollection(collection.downloadArchiveUrl, collection.id)
            "
          >
            <mat-icon>download</mat-icon>
          </button>
          <button
            *ngIf="!isAdmin"
            mat-icon-button
            routerLink="/collections/{{ collection.id }}/products"
          >
            <mat-icon color="info">view_list</mat-icon>
          </button>
          <button
            *ngIf="isAdmin"
            [matMenuTriggerFor]="actionsMenu"
            mat-icon-button
            matTooltip="More"
            color="primary"
            matTooltipPosition="right"
            class="mat-elevation-z0"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #actionsMenu="matMenu">
            <button
              mat-menu-item
              routerLink="/collections/{{ collection.id }}/products"
            >
              <mat-icon color="info">view_list</mat-icon>
              <span>Products</span>
            </button>
            <button mat-menu-item (click)="deleteOutputCollection(collection)">
              <mat-icon matTooltip="Delete" color="error">delete</mat-icon>
              <span>Delete</span>
            </button>
          </mat-menu>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
  <mat-paginator
    [pageSizeOptions]="[10, 20, 50, 100]"
    showFirstLastButtons
    aria-label="Select page of collection"
    [pageSize]="currentConfigDate.pageSize"
    (page)="setPageSizePreference($event)"
  >
  </mat-paginator>
</div>
