<mat-card class="card-item">
  <div class="card-item__thumb">
    <div
      class="card-item__thumb-img"
      [ngClass]="{ blur: data.sourceId != null }"
    >
      <img
        *ngIf="!!data.id && !!data.shootAssetPlaceholderThumbnailUri"
        [ngClass]="!readonly && data.sourceId == null ? 'cursor-pointer' : ''"
        [src]="getLinkImage() | secure | async"
        [alt]="data.shootAssetPlaceholderThumbnailUri"
        [hidden]="!isImageLoaded"
        [matTooltipDisabled]="data.sourceId != null || readonly"
        matTooltip="Change Placeholder"
        (load)="checkImageLoaded()"
        (click)="!readonly && data.sourceId == null ? imageInput.click() : ''"
      />
      <input
        #imageInput
        hidden="true"
        type="file"
        onclick="this.value=null"
        accept=".jpg,.png"
        (change)="placeholder.emit(imageInput.files!)"
      />
      <div
        *ngIf="!isImageLoaded && !data.shootAssetPlaceholderThumbnailUri"
        class="card-item__no-img"
      >
        <img
          [matTooltipDisabled]="data.sourceId != null || readonly"
          matTooltip="Add Placeholder"
          [ngClass]="!readonly && data.sourceId == null ? 'cursor-pointer' : ''"
          [src]="'assets/image-placeholder.jpg'"
          alt="Image Error"
          (click)="!readonly && data.sourceId == null ? imageInput.click() : ''"
        />
      </div>
    </div>
  </div>
  <mat-card-content>
    <div class="content-item">
      <div>
        <div class="card-item__label" [title]="data.label">
          {{ data.label }}
        </div>
        <div class="card-item__description">
          {{
            data.sourceId == null
              ? "Robot Angle: " +
                data.packageAssetRobotOutDegrees +
                " Table Angle: " +
                data.packageAssetTablePosition
              : "Additional Asset of " + data.label!.split(".")[0]
          }}
        </div>
      </div>
    </div>
  </mat-card-content>
  <button
    *ngIf="data.sourceId == null && !readonly"
    matTooltip="Add Additional Asset"
    mat-icon-button
    color="success"
    class="action-btn"
    (click)="add.emit()"
  >
    <mat-icon>add_circle</mat-icon>
  </button>
  <button
    *ngIf="data.sourceId != null && !readonly"
    matTooltip="Remove Additional Asset"
    mat-icon-button
    color="error"
    class="action-btn"
    (click)="remove.emit()"
  >
    <mat-icon>close</mat-icon>
  </button>
</mat-card>
