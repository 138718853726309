import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
//Models
import {
  IProduct,
  IProductStates,
  ProductSearch,
} from '../models/products/product';
import { ProductNew } from '../models/products/productNew';
import { ProductUploadResult } from '../models/products/productUploadResult';
import {
  applyEnhancementVaraint,
  applyMasterSuite,
  applySettingsVaraint,
  ProductSettings,
} from '../models/products/productSettings';
import { ProductReceival } from '../models/products/actions/receive/productReceival';
import { ITouch } from '../models/products/touch';
import { ProductTouchCreate } from '../models/products/actions/ingest/productTouchCreate';
import { ProductTouch } from '../models/products/actions/ingest/productTouch';
import {
  EAssetType,
  IAsset,
  IAssetDetailResponse,
  IMasterSuiteProjection,
  IProductProjection,
  latestOutputAssets,
} from '../models/products/asset';
import { ISettingsTransferValidate } from '../models/products/actions/settings/settingsTransferValidate';
import { ISettingsTransfer } from '../models/products/actions/settings/settingsTransfer';

//------------------------------------------------------------------------
// Product Service to Manage and hold the current selected Product
//------------------------------------------------------------------------
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  private codeSearch = new Subject<string>();
  codeSearch$ = this.codeSearch.asObservable();
  currentProduct!: IProduct;
  productDetailState: any = {
    id: '',
    filterBy: '',
    activeStep: 'preProduction',
  };
  private isFocusComment = new BehaviorSubject<boolean>(false);
  public currentIsFocusComment = this.isFocusComment.asObservable();
  connectionId = '';
  httpOptionsSignalR = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
  };

  private $postAssetState = new BehaviorSubject<number>(0);
  public postAssetState = this.$postAssetState.asObservable();

  filter: IProductStates = {
    receivalState: [],
    masterSuiteState: [],
    ingestionState: [],
    productionState: [],
    productionReviewState: [],
    enhancementState: [],
    reviewState: [],
    outputsState: [],
    dispatchState: [],
  };
  public latestProductsList: any = {
    batchId: '',
    search: '',
    filter: this.filter,
    preDefinedFilter: undefined,
    pageIndex: 0,
    pageSize: 10,
  };

  constructor(private http: HttpClient) {}

  setPostAssetState(state: number) {
    this.$postAssetState.next(state);
  }

  setConnectionId(connectionId: string) {
    this.connectionId = connectionId;
    this.httpOptionsSignalR = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'x-connection-id': this.connectionId,
      }),
    };
  }

  setStatePD(prop: string, value: any) {
    if (prop === 'id' && value !== this.productDetailState[prop]) {
      this.resetStatePD();
    }
    this.productDetailState[prop] = value;
  }

  resetStatePD() {
    this.productDetailState = {
      filterBy: '',
      activeStep: 'preProduction',
    };
  }

  getStatePD() {
    return this.productDetailState;
  }

  resetLatestProductLists() {
    this.latestProductsList = {
      batchId: '',
      search: '',
      filter: this.filter,
      preDefinedFilter: undefined,
      pageIndex: 0,
      pageSize: 10,
    };
  }

  // Set Current
  setCodeSearch(code: string) {
    this.codeSearch.next(code);
  }

  detailsProduct(productId: string | null): Observable<IProduct> {
    console.log('Getting Product Details: ', productId);
    var url = `${environment.apiBase}/products/${productId}`;
    return this.http.get<IProduct>(url, httpOptions);
  }

  createProduct(newProduct: ProductNew): Observable<any> {
    var url = `${environment.apiBase}/products`;
    console.log('Creating New Job');
    return this.http.post<ProductNew>(url, newProduct, httpOptions);
  }

  createProducts(
    file: File,
    batchId: string | null
  ): Observable<ProductUploadResult> {
    console.log(file);
    const formData = new FormData();
    formData.append('file', file);
    var url = `${environment.apiBase}/products/files?batch_id=${batchId}`;
    console.log('Uploading Files');
    return this.http.post<ProductUploadResult>(url, formData);
  }

  deleteProduct(product: IProduct): Observable<any> {
    console.log('Deleting Product: ', product);
    var url = `${environment.apiBase}/products/${product.id}`;
    return this.http.delete<any>(url, httpOptions);
  }

  listProducts(batchId: string | null): any {
    var url = `${environment.apiBase}/products?batch_id=${batchId}`;
    console.log('Getting Products');
    return this.http.get<IProduct[]>(url, httpOptions);
  }

  updateProduct(product: IProduct): Observable<any> {
    var url = `${environment.apiBase}/products/${product.id}`;
    console.log('Updating Product');
    return this.http.put<IProduct>(url, product, httpOptions);
  }

  //------------------------------------------------------------------------
  // Actions
  //------------------------------------------------------------------------

  updateProductReceival(productReceival: ProductReceival): Observable<any> {
    var url = `${environment.apiBase}/product/actions/receive/${productReceival.id}`;
    return this.http.put<ProductReceival>(url, productReceival, httpOptions);
  }

  deleteProductReceival(productReceival: ProductReceival): Observable<any> {
    var url = `${environment.apiBase}/product/actions/receive/${productReceival.id}`;
    return this.http.delete<ProductReceival>(url, httpOptions);
  }

  applyMasterSuiteOnProducts(body: applyMasterSuite): Observable<IProduct[]> {
    let url = `${environment.apiBase}/products/apply/master-suite`;
    return this.http.patch<IProduct[]>(url, body, httpOptions);
  }

  applySettingsVariantOnProducts(
    body: applySettingsVaraint
  ): Observable<IProduct[]> {
    let url = `${environment.apiBase}/products/apply/settings-variant`;
    return this.http.patch<IProduct[]>(url, body, httpOptions);
  }

  applyEnhancementVariantOnProducts(
    body: applyEnhancementVaraint
  ): Observable<IProduct[]> {
    let url = `${environment.apiBase}/products/apply/enhancement-variant`;
    return this.http.patch<IProduct[]>(url, body, httpOptions);
  }

  bulkIngestProductTouches(
    body: ProductTouchCreate[]
  ): Observable<ProductTouch[]> {
    var url = `${environment.apiBase}/product/actions/bulk/ingest`;
    return this.http.post<ProductTouch[]>(url, body, httpOptions);
  }

  validateManualProductSettingsTransfer(body: ISettingsTransfer) {
    var url = `${environment.apiBase}/product/actions/settings/transfer/validate/manual`;
    return this.http.post<ISettingsTransfer>(url, body, httpOptions);
  }

  validateProductSettingsTransfer(body: ISettingsTransferValidate) {
    var url = `${environment.apiBase}/product/actions/settings/transfer/validate`;
    return this.http.post<ISettingsTransfer>(url, body, httpOptions);
  }

  productSettingsTransfer(body: ISettingsTransfer) {
    var url = `${environment.apiBase}/product/actions/settings/transfer`;
    return this.http.post<IProduct[]>(url, body, httpOptions);
  }

  //------------------------------------------------------------------------
  // Approvals
  //------------------------------------------------------------------------

  clientProductApprovals(
    id: string,
    type: string,
    version: number
  ): Observable<IProduct> {
    var url = `${environment.apiBase}/product/${id}/actions/client/${type}`;
    var versionBody = {
      version: version,
    };
    return this.http.patch<IProduct>(url, versionBody, httpOptions);
  }

  internalProductApprovals(
    id: string,
    type: string,
    version: number
  ): Observable<IProduct> {
    var url = `${environment.apiBase}/product/${id}/actions/internal/${type}`;
    var versionBody = {
      version: version,
    };
    return this.http.patch<IProduct>(url, versionBody, httpOptions);
  }

  patchProductNotes(product: IProduct): Observable<IProduct> {
    var url = `${environment.apiBase}/product/${product.id}/actions/notes`;
    var versionBody = {
      version: product.version,
      notes: product.notes,
    };
    return this.http.patch<IProduct>(url, versionBody, httpOptions);
  }

  //------------------------------------------------------------------------
  // Review Assets
  //------------------------------------------------------------------------

  getViewLatestShootAssets(
    batchId: string,
    productIds: string[],
    pageNumber: number,
    pageSize: number
  ) {
    const url = `${environment.apiBase}/products/view/latest/shootAssets`;
    var body = {
      batchId: batchId,
      productIds: productIds,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    return this.http.post<{
      pageNumber: number;
      pageSize: number;
      results: IMasterSuiteProjection[];
      total: number;
    }>(url, body);
  }

  getViewLatestPostAssets(
    batchId: string,
    productIds: string[],
    pageNumber: number,
    pageSize: number
  ) {
    const url = `${environment.apiBase}/products/view/latest/postAssets`;
    var body = {
      batchId: batchId,
      productIds: productIds,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    return this.http.post<{
      pageNumber: number;
      pageSize: number;
      results: IAssetDetailResponse[];
      total: number;
    }>(url, body);
  }

  getAssetById(id: string, type: string): Observable<IAsset> {
    console.log(id);
    console.log(type);
    const url = `${environment.apiBase}/${
      type === EAssetType.SHOOT_ASSETS ? 'shootAssets' : 'postAssets'
    }/${id}`;
    console.log(url);
    return this.http.get<IAsset>(url, httpOptions);
  }

  getListProduction(id: string) {
    const url = `${environment.apiBase}/products/${id}/shoot-assets/latest`;
    return this.http.get<ITouch[]>(url);
  }

  getListPostProduction(id: string) {
    const url = `${environment.apiBase}/products/${id}/post-assets/latest`;
    return this.http.get<IAsset[]>(url);
  }

  getListAllPostProduction(id: string) {
    const url = `${environment.apiBase}/products/${id}/post-assets/all`;
    return this.http.get<ITouch[]>(url);
  }

  getListOutputAssets(id: string) {
    const url = `${environment.apiBase}/products/${id}/output-assets/latest`;
    return this.http.get<latestOutputAssets[]>(url);
  }

  getOutputAssetDetails(
    productTouchAssetId: string,
    masterSuiteTouchAssetId: string,
    outputFormatId: string
  ): Observable<IAssetDetailResponse> {
    const url = `${environment.apiBase}/product-touch-asset/${productTouchAssetId}/master-suite-touch-asset/${masterSuiteTouchAssetId}/output-format/${outputFormatId}/output-assets`;
    return this.http.get<IAssetDetailResponse>(url, httpOptions);
  }

  getPostAssetDetails(
    productTouchAssetId: string,
    masterSuiteTouchAssetId: string
  ): Observable<IAssetDetailResponse> {
    const url = `${environment.apiBase}/product-touch-asset/${productTouchAssetId}/master-suite-touch-asset/${masterSuiteTouchAssetId}/post-assets`;
    return this.http.get<IAssetDetailResponse>(url, httpOptions);
  }

  getShootAssetDetails(
    productTouchAssetId: string
  ): Observable<IAssetDetailResponse> {
    const url = `${environment.apiBase}/product-touch-asset/${productTouchAssetId}/shoot-assets`;
    return this.http.get<IAssetDetailResponse>(url, httpOptions);
  }

  reviewShootAsset(id: string, state: string | null) {
    const url = `${environment.apiBase}/shoot-asset/${id}/review/${state}`;
    return this.http.patch<any>(url, this.httpOptionsSignalR);
  }

  reviewPostAsset(
    id: string,
    isInternal: boolean,
    state: string | null
  ): Observable<any> {
    const url = `${environment.apiBase}/post-asset/${id}/review${
      isInternal ? '/internal' : '/client'
    }/${state}`;
    return this.http.patch<any>(url, this.httpOptionsSignalR);
  }

  reviewOutputAsset(id: string, review: string): Observable<any> {
    const url = `${environment.apiBase}/output-asset/${id}/review/${review}`;
    return this.http.patch<any>(url, this.httpOptionsSignalR);
  }

  searchByCode(
    code?: string,
    pageNumber?: number,
    pageSize?: number,
    organizationId?: string,
    isOrganizationMember?: boolean,
    type?: string,
    batchId?: string
  ) {
    let param = '';
    if (code) {
      param += `search=${code}`;
    }
    if (pageNumber) {
      param +=
        param !== '' ? `&pageNumber=${pageNumber}` : `pageNumber=${pageNumber}`;
    }
    if (pageSize) {
      param += param !== '' ? `&pageSize=${pageSize}` : `pageSize=${pageSize}`;
    }
    if (organizationId) {
      param +=
        param !== ''
          ? `&OrganizationId=${organizationId}`
          : `OrganizationId=${organizationId}`;
    }
    if (isOrganizationMember !== undefined) {
      param +=
        param !== ''
          ? `&IsOrganizationMember=${isOrganizationMember}`
          : `IsOrganizationMember=${isOrganizationMember}`;
    }
    if (type) {
      param += param !== '' ? `&Type=${type}` : `Type=${type}`;
    }
    if (batchId) {
      param += param !== '' ? `&BatchId=${batchId}` : `BatchId=${batchId}`;
    }
    const url = `${environment.apiBase}/products/filter?${param}`;
    return this.http.get<{
      pageNumber: number;
      pageSize: number;
      results: ProductSearch[];
      total: number;
    }>(url, httpOptions);
  }

  uploadOutputAsset(
    postAssetId: string,
    outputFormatId: string,
    asset: File
  ): Observable<IAsset> {
    const url = `${environment.apiBase}/post-asset/${postAssetId}/output-format/${outputFormatId}`;
    const formData = new FormData();
    formData.append('file', asset);
    return this.http.post<IAsset>(url, formData);
  }

  syncProductShopifyAssets(data: {
    productIds: string[];
    overwrite: boolean;
    outputFormats: {
      outputFormatId: string;
      type: string;
    }[];
  }): Observable<any> {
    const url = `${environment.apiBase}/organization/products/shopify-sync-assets-queue`;
    return this.http.put(url, data);
  }

  //------------------------------------------------------------------------
  // Download file
  //------------------------------------------------------------------------

  downloadImage(type: string, id: string) {
    const url = `${environment.apiBase}/${type}/${id}/download/raw`;
    return this.http.post(url, null, {
      responseType: 'blob',
      observe: 'response',
    });
  }

  updateIsFocusComment(status: boolean) {
    this.isFocusComment.next(status);
  }

  downloadImages(urls: string[]) {
    const promises = urls.map((url) => {
      return this.http
        .get(`${environment.s3Base + url}`, {
          responseType: 'blob',
          observe: 'response',
        })
        .toPromise();
    });
    return Promise.all(promises);
  }

  downloadSingleImage(url: string) {
    return this.http.get(`${environment.s3Base + url}`, {
      responseType: 'blob',
      observe: 'response',
    });
  }

  downloadTextFile(productId: string, masterSuiteTouchAssetId: string) {
    const url = `${environment.apiBase}/products/${productId}/masterSuiteTouchAsset/${masterSuiteTouchAssetId}/download/sidecar`;
    return this.http.post(url, null, {
      responseType: 'blob',
      observe: 'response',
    });
  }

  transferAssetPlaceholder(
    body: { shootAssetIds?: string[]; postAssetIds?: string[] },
    type: 'shootAssets' | 'postAssets'
  ) {
    const url = `${environment.apiBase}/${type}/transfer-placeholder`;
    return this.http.post(url, body, httpOptions);
  }

  public static getLinkImage(image: string | null) {
    if (image == null) {
      return 'assets/image-placeholder.jpg';
    }
    var res = undefined;
    if (image.includes('https')) {
      res = image;
    }
    if (image.includes('s3://')) {
      res = image.replace('s3://', environment.s3Base);
    }
    if (!res) {
      res = environment.apiBase + image;
    }

    return res;
  }
  pinAsset(id: string, entity: string, state:boolean) {
    const url = `${environment.apiBase}/${entity}/${id}/pin/${state}`;
    return this.http.patch(url, httpOptions);
  }
}
