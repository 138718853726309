export const dateTimeFormat = [
  {
    text: 'dd MMM yyyy h:mm a',
    value: 'dd MMM yyyy h:mm a',
  },
  {
    text: 'dd/MM/yyyy h:mm a',
    value: 'dd/MM/yyyy h:mm a',
  },
  {
    text: 'yyyy-MM-dd h:mm a',
    value: 'yyyy-MM-dd h:mm a',
  },
  {
    text: 'dd-MM-yyyy h:mm a',
    value: 'dd-MM-yyyy h:mm a',
  },
];

export const defaultFormat = 'dd/MM/yyyy h:mm a';
