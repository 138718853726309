<div class="comment-item">
  <div *ngIf="data.authorUsername || data.authorProfileImageUrl">
    <app-avatar
      [name]="data.authorUsername"
      [url]="data.authorProfileImageUrl"
    ></app-avatar>
  </div>
  <div class="comment-item__content">
    <div *ngIf="data.authorUsername" class="comment-item__name">
      {{ data.authorUsername }}
      <mat-icon *ngIf="data.imageReference">rate_review</mat-icon>
    </div>
    <div [innerHTML]="data.comment" class="comment-item__comment-text"></div>
  </div>
  <div>
    <div
      class="comment-item__date"
      matTooltip="{{
        data.createdAt | dateAgo : currentConfigDate : true | async
      }}"
    >
      {{ data.createdAt | dateAgo : currentConfigDate | async }}
    </div>
    <div class="comment-item__version" (click)="selected.emit()">
      {{ data.version }}
    </div>
  </div>
</div>
