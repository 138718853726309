import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {
  ConfigOption,
  ConfigOptions,
  IPackage,
} from 'src/app/models/packages/package';
import {IPackageAsset} from 'src/app/models/packages/packageAsset';
import {Location} from '@angular/common';
import {PackagesService} from 'src/app/services/packages.service';
import {ActivatedRoute, Router} from '@angular/router';
import {
  StillsAssetNew,
  VideoAssetNew,
} from 'src/app/models/packages/packageNew';
import {DeleteModalComponent} from 'src/app/components/shared/delete-modal/delete-modal.component';
import {ValidationErrors} from 'src/app/models/validationErrors';
import {FormControl, Validators} from '@angular/forms';
import {moveItemInArray} from '@angular/cdk/drag-drop';
import {Subject, takeUntil} from 'rxjs';
import {
  UnsavedChangesWarningComponent
} from 'src/app/components/shared/unsaved-changes-warning/unsaved-changes-warning.component';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-package-update',
  templateUrl: './package-update.component.html',
  styleUrls: ['./package-update.component.scss'],
})
export class PackageUpdateComponent {
  constructor(
    private router: Router,
    private packagesService: PackagesService,
    private alertService: AlertService,
    private _location: Location,
    public dialog: MatDialog,
    private route: ActivatedRoute
  ) {
    this.initialData();
  }

  readonly: boolean = true;
  private destroy$ = new Subject();
  packageSubscription: any;
  input: FormControl = new FormControl('', [Validators.required]);
  serverErrorMessage: String = '';
  errorMessages: ValidationErrors[] = [];
  validatorProperties: string[] = [];

  package!: IPackage;
  packageOriginal!: IPackage;

  stills: IPackageAsset[] = [];
  videos: IPackageAsset[] = [];

  configOptions: ConfigOptions = {
    sideLeft: {
      robotOutDegreesOptions: [30, 25, 15, 10, 0],
    },
    front: {
      robotOutDegreesOptions: [90, 45, 35, 25, 20, 15, 10, 0],
    },
  };
  config!: ConfigOption;

  loaded: boolean = false;

  initialData() {
    this.readonly = true;
    this.getPackageDetail();
  }

  getPackageDetail() {
    const packageId = this.route.snapshot.paramMap.get('packageId');
    if (packageId) {
      this.packagesService
        .detailPackage(packageId)
        .pipe(takeUntil(this.destroy$))
        .subscribe((_package) => {
          this.package = _package;
          this.packageOriginal = JSON.parse(JSON.stringify(this.package));
          this.packageOriginal.assets.sort((a, b) => a.index - b.index);
          this.setupData();
          this.setConfig();
          this.loaded = true;
        });
    }
  }

  setupData() {
    this.stills = [];
    this.videos = [];
    this.package.assets.sort((a, b) => a.index - b.index);
    this.package.assets.forEach((asset) => {
      if (
        asset.distanceOffset != 1 ||
        asset.focusOffset != 1 ||
        asset.heightOffset != 1
      ) {
        asset.offsets = true;
      }

      if (asset.type.toLowerCase() == 'still') {
        this.stills.push(asset);
      } else {
        this.videos.push(asset);
      }
    });
  }

  setConfig() {
    console.log(this.package.robotPosition);

    if (this.package.robotPosition == 'SideLeft') {
      this.config = this.configOptions.sideLeft;
      this.package.cameraOrientation = 'Portrait';
    } else {
      this.config = this.configOptions.front;
      this.package.cameraOrientation = 'Landscape';
    }

    if (
      !this.config.robotOutDegreesOptions.includes(
        this.package.livePreview.robotOutDegrees
      )
    ) {
      this.package.livePreview.robotOutDegrees = 0;
    }

    this.stills.forEach((asset: IPackageAsset) => {
      if (!this.config.robotOutDegreesOptions.includes(asset.robotOutDegrees)) {
        asset.robotOutDegrees = 0;
      }
    });

    this.videos.forEach((asset: IPackageAsset) => {
      if (!this.config.robotOutDegreesOptions.includes(asset.robotOutDegrees)) {
        asset.robotOutDegrees = 0;
      }
    });
  }

  addStill() {
    this.stills.push(new StillsAssetNew());
  }

  removeStill(index: number) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      data: {
        header: `Delete Still ${index + 1}`,
        message:
          'All master suite touch assets, master suite touch additional assets, and enhancement variants related to this package will be removed as well!',
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.stills.splice(index, 1);
      }
    });
  }

  addVideo() {
    this.videos.push(new VideoAssetNew());
  }

  removeVideo(index: number) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      data: {
        header: `Delete Video ${index + 1}`,
        message:
          'All master suite touch assets, master suite touch additional assets, and enhancement variants related to this package will be removed as well!',
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.videos.splice(index, 1);
      }
    });
  }

  dragDropStill(event: any) {
    console.log(event);
    moveItemInArray(this.stills, event.previousIndex, event.currentIndex);
  }

  dragDropVideo(event: any) {
    console.log(event);
    moveItemInArray(this.videos, event.previousIndex, event.currentIndex);
  }

  updatePackage() {
    var stillPackage = false;
    var videoPackage = false;
    this.package.assets = [];
    console.log(this.stills.length);
    console.log(this.videos.length);

    if (this.stills.length > 0) {
      stillPackage = true;
      this.stills.forEach((still, i) => {
        still.index = i * 1000;
        this.package.assets.push(still);
      });
    }

    if (this.videos.length > 0) {
      videoPackage = true;
      this.videos.forEach((video, i) => {
        video.index = (i + this.stills.length) * 1000;
        this.package.assets.push(video);
      });
    }

    if (stillPackage && videoPackage) {
      this.package.externalReference =
        'Combo(' + this.stills.length + ',' + this.videos.length + ')';
    } else if (stillPackage == true && videoPackage == false) {
      this.package.externalReference = 'Stills(' + this.stills.length + ')';
    } else if (stillPackage == false && videoPackage == true) {
      this.package.externalReference = 'Video(' + this.videos.length + ')';
    }

    console.log(this.package);
    this.packagesService.updatePackage(this.package).subscribe(
      (data) => {
        this.alertService.successToast('Package Updated')
        this.initialData();
      },
      (error) => {
        if (error.status === 422) {
          this.errorMessages = [];
          this.validatorProperties = [];
          error.error.validationErrors.forEach((error: ValidationErrors) => {
            this.errorMessages.push(error);
            this.validatorProperties.push(error.property);
            this.input.markAllAsTouched();
            if (error.property == 'Name') {
              this.input.setErrors({serverErrors: 'validation errors'});
            }
            if (error.property == 'Assets') {
              this.alertService.errorToast(error.message);
            }
          });
        }
      }
    );
  }

  validationMessage(property: string) {
    return this.errorMessages.find((error) => error.property == property)
      ?.message;
  }

  cancel() {
    if (this.checkChanges()) {
      const dialogRef = this.dialog.open(UnsavedChangesWarningComponent);
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.package = JSON.parse(JSON.stringify(this.packageOriginal));
          this.setupData();
          this.setConfig();
          this.readonly = true;
        }
      });
    } else {
      this.readonly = true;
    }
  }

  backClicked() {
    if (this.checkChanges()) {
      const dialogRef = this.dialog.open(UnsavedChangesWarningComponent);
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this._location.back();
        }
      });
    } else {
      this._location.back();
    }
  }

  checkChanges(): boolean {
    return (
      JSON.stringify(this.package) != JSON.stringify(this.packageOriginal) ||
      this.stills.length + this.videos.length <
      this.packageOriginal.assets.length ||
      this.stills.some((x) => x.id == null) ||
      this.videos.some((x) => x.id == null)
    );
  }
}
