import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { Location } from '@angular/common';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Casing, FilenameFormat, TokenFormat } from 'src/app/models/organizations/form-setting';
import { filenameToken } from '../../organization.data';
import { WarningModalComponent } from 'src/app/components/shared/warning-modal/warning-modal.component';

@Component({
  selector: 'app-output-filename-update',
  templateUrl: './output-filename-update.component.html',
  styleUrl: './output-filename-update.component.scss'
})
export class OutputFilenameUpdateComponent implements OnInit {
  @Output() dataChange = new EventEmitter<any>();
  fileNameTokens: TokenFormat[] = filenameToken;
  filenamesFormats : FilenameFormat[];
  filenamesForm: FormGroup;
  public casing = Casing;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { filenameFormats: FilenameFormat[] },
    public dialogRef: MatDialogRef<OutputFilenameUpdateComponent>,
    public dialog: MatDialog,
    private location: Location,
    private formBuilder: FormBuilder
  ) {
    console.log(`data: ${JSON.stringify(data,null,2)}`);
    this.filenamesFormats = data.filenameFormats;
    this.filenamesForm = this.formBuilder.group({
      filenames: this.formBuilder.array([],
        [Validators.required, Validators.minLength(1),]),
    });
  }

  ngOnInit(): void {
    this.populateFormArray();
  }

  get filenames(): FormArray {
    return this.filenamesForm.get('filenames') as FormArray;
  }
  
  populateFormArray(): void {
    const fileNameFormatGroups: FormGroup<any>[] = this.filenamesFormats.map((filenameFormat: FilenameFormat) => 
      this.createFilename(filenameFormat));
    console.log(`fileNameFormatGroups length: ${fileNameFormatGroups.length}`)
    fileNameFormatGroups.map((fileNameFormatGroup: FormGroup) => 
      this.filenames.push(fileNameFormatGroup));
    console.log(`filenames: ${JSON.stringify(this.filenames,null,2)}`);
  }

  createFilename(filename?: FilenameFormat): FormGroup {
    const form = this.formBuilder.group({
      from: [filename ? filename.from : 1,
        [Validators.required, Validators.min(1)]],
      to: [filename ? filename.to : undefined,
        [Validators.min(1)]],
      indexOffset: [filename ? filename.indexOffset : 1,
        [Validators.required]],
      tokenFormats: this.formBuilder.array(filename ? filename.tokenFormats : [],
        [Validators.required]),
      casing: [filename ? filename.casing : this.casing.MATCH,
      [Validators.required]],
    });

    console.log('Initial casing value:', form.get('casing')?.value);
    const casing = form.get('casing')?.valueChanges.subscribe(value => console.log(`casing: ${value}`));
    
    return form;
  }


  addFilename(existingFilename?: FilenameFormat): void {
    console.log("adding filename");
    
    const filename = this.createFilename(existingFilename);
    this.filenames.push(filename);
  }

  removeFilename(index: number): void {
    this.filenames.removeAt(index);
  }

  getControlFormInArray(controlName: string, form: any) {
    return form.get(controlName);
  }

  updateValidtors(data: any) {
    let indexOffset: FormControl<any> = this.getControlFormInArray(
      'indexOffset',
      data
    );
    indexOffset.clearValidators();

    indexOffset.addValidators(
      Validators.min(0 - this.getControlFormInArray('from', data).value)
    );
    indexOffset.addValidators(Validators.required);

    indexOffset.updateValueAndValidity();
  }
  
  onlyPositiveIntegers(event: KeyboardEvent) {
    return ['-', '+', '.', 'e'].every((el) => event.key !== el);
  }


  onSubmit() {
    
    const check = this.checkBeforeSubmit();
    if (!check.status) {
      this.openConfirm(check.message);
      return;
    }
    const updatedData = this.filenamesForm.value
    console.log(`updatedData: ${JSON.stringify(updatedData,null,2)}`);
    
    this.dialogRef.close(updatedData);

  }
  onClose() {
    this.dialogRef.close();
  }

  checkBeforeSubmit(): {
    message: string;
    status: boolean;
  } {
  
    for (let i = 0; i < this.filenames.length; i++) {
      if (
        (!this.filenames.value[i].to &&
          this.filenames.value[i + 1]) ||
        this.filenames.value[i].to >=
          this.filenames.value[i + 1]?.from
      ) {
        return {
          message: 'The ranges of assets cannot be overlapped!',
          status: false,
        };
      }
      if (
        this.filenames.value[i].to &&
        this.filenames.value[i].to <
          this.filenames.value[i + 1]?.from - 1
      ) {
        return {
          message: `The range from asset ${
            this.filenames.value[i].to + 1
          } to ${this.filenames.value[i + 1].from - 1} is required`,
          status: false,
        };
      }
      if (
        i === this.filenames.value.length - 1 &&
        this.filenames.value[i].to
      ) {
        return {
          message: `The range from asset ${
            this.filenames.value[i].to + 1
          }  is required`,
          status: false,
        };
      }
      if (
        this.filenames.value[i].from ===
        this.filenames.value[i].to
      ) {
        let pattern = '';
        for (const token of this.filenames.value[i].tokenFormats) {
          pattern += token.value;
        }
        if (pattern.includes('{index}')) {
          continue;
        }
        for (let j = i + 1; j < this.filenames.value.length; j++) {
          if (
            this.filenames.value[j].from ===
            this.filenames.value[j].to
          ) {
            let patternJ = '';
            for (const token of this.filenames.value[i].tokenFormats) {
              patternJ += token.value;
            }
            if (patternJ.includes('{index}')) {
              continue;
            }
            if (pattern === patternJ) {
              return {
                message: `Duplicated patterns detected: ${pattern}`,
                status: false,
              };
            }
          }
        }
      }
    }

    return {
      message: ``,
      status: true,
    };
  }

  openConfirm(content: string) {
    this.dialog.open(WarningModalComponent, {
      data: {
        header: 'Warning',
        content: content,
      },
    });
  }
}
