import { Component, OnInit, ViewChild } from '@angular/core';
//Services
import { OrganizationsService } from 'src/app/services/organizations.service';
// Material Modules
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
// Models
import { IOrganization } from '../../../../models/organizations/organization';
import { environment } from 'src/environments/environment';
import { Subject, takeUntil } from 'rxjs';
import { SignalrService } from 'src/app/services/signalr.service';
import { IUserProfile, IPreference } from 'src/app/models/users/userProfile';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-organization-list',
  templateUrl: './organization-list.component.html',
  styleUrls: ['./organization-list.component.scss'],
})
export class OrganizationListComponent implements OnInit {
  private destroy$ = new Subject();
  apiBase = environment.apiBase;
  constructor(
    private organizationsService: OrganizationsService,
    private signalrService: SignalrService,
    private authService: AuthService
  ) {}
  user!: IUserProfile | null;
  currentConfigDate!: IPreference;
  displayedColumns: string[] = ['profileImage', 'name', 'actions'];
  @ViewChild(MatPaginator)
  matPaginator!: MatPaginator;
  dataSource = new MatTableDataSource<IOrganization>();
  profileImages: string[] = [];
  title: string = 'Organizations';
  organizations: IOrganization[] = [];
  ngOnInit(): void {
    this.authService.currentPreference.subscribe((res) => {
      this.currentConfigDate = res;
    });
    this.user = this.authService.getAuthInformation();
    this.listOrganizations();
    this.signalrService.connectState.subscribe({
      next: (data: boolean) => {
        if (data) {
          this.joinGroups();
        }
      },
    });
    this.subscribeToData();
  }

  filter: any;
  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toUpperCase();
  }

  setTableElements(): void {
    this.dataSource.data = this.organizations;
    this.dataSource.paginator = this.matPaginator;
  }

  listOrganizations(): void {
    this.organizationsService
      .listOrganizations()
      .pipe(takeUntil(this.destroy$))
      .subscribe((organizationsRetrieved) => {
        this.organizations = organizationsRetrieved;
        this.setTableElements();
      });
  }

  listGroup: string = '';
  joinGroups() {
    this.signalrService
      .joinStaticGroup('JoinOrganizationsListGroup')
      ?.then((group: string) => (this.listGroup = group));
  }

  subscribeToData() {
    this.signalrService.organizationsData.subscribe({
      next: (data: any) => {
        if (data.id) {
          var index = this.organizations.findIndex((p) => p.id == data.id);
          if (index >= 0) {
            this.organizations[index] = data;
            this.dataSource.data = this.organizations;
          } else {
            this.organizations.push(data);
            this.dataSource.data = this.organizations;
          }
        }
      },
    });
  }

  setPageSizePreference(event: any) {
    if (event.pageSize != this.currentConfigDate.pageSize) {
      this.currentConfigDate.pageSize = event.pageSize;
      let payload = {
        name: this.user?.name,
        preference: this.currentConfigDate,
      };
      this.authService.updatePreference(payload).subscribe((res) => {
        console.log(res);
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.signalrService.leaveGroup(this.listGroup);
  }
}
