<div class="content">
  <div class="content-body" *ngIf="loaded">
    <div class="left">
      <!-- TODO find Material styling -->
      <div class="imageContainer">
        <img
          class="image"
          *ngIf="
            organization.profileImageUrl !== null && file == undefined;
            else placeholder
          "
          [src]="apiBase + organization.profileImageUrl | secure | async"
          alt=""
        />
        <ng-template #placeholder>
          <img class="image" [src]="currentProfileImageUrl" />
        </ng-template>
        <button
          mat-mini-fab
          class="edit"
          color="buttonSuccess"
          (click)="file_input.click()"
        >
          <mat-icon matTooltip="Update">edit</mat-icon>
        </button>
        <input
          type="file"
          #file_input
          (change)="onFileChange(file_input.files)"
          class="hidden-file-input"
          accept=".jpg,.png,.jpeg,.jfif"
        />
      </div>

      <div class="updateForm">
        <mat-form-field appearance="outline" class="formField">
          <mat-label>Name</mat-label>
          <input [formControl]="nameFormControl" type="text" matInput />
          <mat-error *ngIf=""> Name is required. </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" color="primary" class="formField">
          <mat-label>Shopify Domain</mat-label>
          <input [formControl]="shopifyFormControl" type="text" matInput />
          <mat-hint *ngIf="organization.lastSyncedAt != null">
            Last Synced on {{ organization.lastSyncedAt }}
          </mat-hint>
          <button
            mat-icon-button
            matSuffix
            (click)="shopifyLogin()"
            [disabled]="shopifyFormControl.untouched"
          >
            <mat-icon>sync</mat-icon>
          </button>
        </mat-form-field>

        <ng-container *ngIf="requestInProgress">
          <mat-progress-spinner [mode]="'indeterminate'">
          </mat-progress-spinner>
        </ng-container>
      </div>
    </div>
    <div class="right">
      <h1 class="title">
        Output Formats Configuration
        <i
          class="material-icons pointer"
          style="margin-left: 12px"
          (click)="onCreateNewOutputFormat()"
          >add_circle_outline</i
        >
      </h1>
      <div class="output-setting">
        <div class="item" *ngFor="let fileImage of fileNameSettings">
          <div class="info" (click)="onSelectConfig(fileImage)">
            <i class="material-icons">check</i>
            <span class="name">{{ fileImage.name }} </span>
            <span class="show-config" [innerHTML]="fileImage.configText"></span>
          </div>
          <i
            class="material-icons pointer icon-color"
            (click)="onDelete(fileImage)"
            >delete</i
          >
        </div>
      </div>
    </div>
  </div>
  <div class="content-actions">
    <span class="spacer"></span>
    <button mat-stroked-button (click)="goBack()" style="margin-right: 10px">
      Cancel
    </button>
    <button
      (click)="updateOrganization()"
      color="buttonSuccess"
      [disabled]="!nameFormControl.valid || !shopifyFormControl.valid"
      mat-button
    >
      Save
    </button>
  </div>
</div>
