import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-review-fullscreen',
  templateUrl: './review-fullscreen.component.html',
  styleUrl: './review-fullscreen.component.scss',
})
export class ReviewFullscreenComponent {
  @Input() currentAsset!: string;
  @Output() exit: EventEmitter<any> = new EventEmitter();
  zoomed: boolean = false;
  scrolled: boolean = false;
  xPosition!: number;
  yPosition!: number;
  @ViewChild('videotecImage', { static: true }) videotecImage!: ElementRef;
  @ViewChild('videotecContainer', { static: true })
  videotecContainer!: ElementRef;

  onClick(event: any) {
    const xMargin =
      (this.videotecContainer.nativeElement.clientWidth -
        this.videotecImage.nativeElement.clientWidth) /
      2;
    const yMargin =
      (this.videotecContainer.nativeElement.clientHeight -
        this.videotecImage.nativeElement.clientHeight) /
      2;

    const xRelative = event.x - xMargin;
    const yRelative = event.y - yMargin;

    const xScale = xRelative / this.videotecImage.nativeElement.clientWidth;

    const yScale = yRelative / this.videotecImage.nativeElement.clientHeight;

    this.xPosition =
      this.videotecImage.nativeElement.clientWidth * xScale * 3 -
      this.videotecContainer.nativeElement.clientWidth / 2;
    this.yPosition =
      this.videotecImage.nativeElement.clientHeight * yScale * 3 -
      this.videotecContainer.nativeElement.clientHeight / 2;
    this.zoomImage();
  }

  ngAfterViewChecked() {
    if (this.zoomed && !this.scrolled) {
      this.videotecContainer.nativeElement.scrollTo(
        this.xPosition,
        this.yPosition
      );
      this.scrolled = true;
    }
  }
  zoomImage() {
    if (this.zoomed && this.scrolled) this.scrolled = false;
    this.zoomed = !this.zoomed;
  }

  checkMargin(x: boolean): string {
    if (x) {
      return (
        (this.videotecContainer.nativeElement.clientWidth -
          this.videotecImage.nativeElement.clientWidth) /
          2 +
        20 +
        'px'
      );
    } else {
      return (
        (this.videotecContainer.nativeElement.clientHeight -
          this.videotecImage.nativeElement.clientHeight) /
          2 +
        20 +
        'px'
      );
    }
  }
}
