import { moveItemInArray } from '@angular/cdk/drag-drop';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable, Subject, map, startWith, takeUntil } from 'rxjs';
import { DeleteModalComponent } from 'src/app/components/shared/delete-modal/delete-modal.component';
import { ConfigStep } from 'src/app/models/custom-step';
import { MasterSuiteFormControl } from 'src/app/models/mastersuites/masterSuiteFormControl';
import {
  IMasterSuite,
  IMasterSuiteTouch,
  IMasterSuiteTouchAsset,
} from 'src/app/models/mastersuites/mastersuite';
import {
  MasterSuiteTouchNew,
  MasterSuiteTouchAssetNew,
} from 'src/app/models/mastersuites/mastersuiteNew';
import { IPackage } from 'src/app/models/packages/package';
import { ValidationErrors } from 'src/app/models/validationErrors';
import { PackagesService } from 'src/app/services/packages.service';
import { SettingsVariantContentComponent } from './settings-variant-content/settings-variant-content.component';
import { MasterSuitesService } from 'src/app/services/mastersuites.service';
import { IPreference } from 'src/app/models/users/userProfile';
import { AuthService } from 'src/app/services/auth.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { WarningModalComponent } from 'src/app/components/shared/warning-modal/warning-modal.component';

@Component({
  selector: 'app-mastersuite-content',
  templateUrl: './mastersuite-content.component.html',
  styleUrls: ['./mastersuite-content.component.scss'],
})
export class MastersuiteContentComponent implements OnInit {
  @Input() masterSuite!: IMasterSuite;
  @Input() masterSuiteFormControl!: MasterSuiteFormControl;
  @Input() tabIndex: number = 0;
  @Output() tabChanged = new EventEmitter<any>();
  @Input() errorMessages: ValidationErrors[] = [];
  @Input() validatorProperties: string[] = [];
  @Input() readonly!: boolean;

  @ViewChild(SettingsVariantContentComponent) settingsVariants:
    | SettingsVariantContentComponent
    | undefined;
  currentConfigDate!: IPreference;
  stepperConfig: ConfigStep[] = [
    {
      id: 0,
      label: 'Touches',
      tooltip: '',
      action: {
        icon: 'add_circle',
        change: () => {
          this.addTouch();
        },
      },
    },
    {
      id: 1,
      label: 'Settings Variant',
      tooltip: '',
      action: {
        icon: 'add_circle',
        change: () => {
          this.settingsVariants!.addVariant();
        },
      },
    },
    {
      id: 2,
      label: 'Enhancement Variant',
      tooltip: '',
      action: {
        icon: 'add_circle',
        change: () => {
          this.openCreateEnhancement();
        },
      },
    },
  ];

  readonly breakpoint$ = this.breakpointObserver.observe([
    Breakpoints.XLarge,
    Breakpoints.Large,
    Breakpoints.Medium,
    Breakpoints.Small,
    Breakpoints.XSmall,
  ]);

  private destroy$ = new Subject();
  colPerScreen: number = 3;
  constructor(
    private packagesService: PackagesService,
    private masterSuitesService: MasterSuitesService,
    public breakpointObserver: BreakpointObserver,
    private authService: AuthService,
    public _alert: MatSnackBar,
    public dialog: MatDialog,
    private router: Router
  ) {
    if (this.router.url.includes('create')) {
      this.stepperConfig[2].disable = true;
      this.stepperConfig[1].disable = true;
    }
  }

  input: FormControl = new FormControl('', [Validators.required]);
  packagesFilteredOptions: Observable<IPackage[]>[] = [];
  _packageFilter(name: string): IPackage[] {
    const filterValue = name.toLowerCase();
    return this.packages.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }
  displayPackageFn(packageId: string) {
    if (packageId) {
      return this.packages.find((_) => _.id === packageId)?.name!;
    } else {
      return '';
    }
  }

  serverErrorMessage: String = '';
  packages: IPackage[] = [];
  packageSubscription: any;
  packagesLoaded: boolean = false;
  collapsed: boolean = false;

  parsePackageValue(value: any) {
    const name = typeof value === 'string' ? value : value?.name;
    return name ? this._packageFilter(name as string) : this.packages.slice();
  }

  ngOnInit(): void {
    this.breakpoint$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.breakpointChanged();
    });
    this.authService.currentPreference.subscribe((res) => {
      this.currentConfigDate = res;
    });
    this.listPackages();
  }

  listPackages() {
    this.packageSubscription = this.packagesService
      .listPackages(this.masterSuite.organizationId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (packages) => {
          if (packages) {
            this.packages = packages;
            this.masterSuite.masterSuiteTouches.forEach((touch, i) => {
              this.packagesFilteredOptions.push(
                this.masterSuiteFormControl.touches[
                  i
                ].Package.valueChanges.pipe(
                  startWith(''),
                  map((value) => this.parsePackageValue(value))
                )
              );
            });
            this.packagesLoaded = true;
          }
        },
        (error) => {
          this.serverErrorMessage =
            'There was an error loading the Users from the server. Please restart the system and try again';
          this._alert.open('Server Error: ' + error.message, 'close', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            panelClass: ['error'],
          });
        }
      );
  }

  addTouch() {
    console.log('first', this.masterSuite);
    const index = this.masterSuite.masterSuiteTouches.length;
    // Push New Touch
    this.masterSuite.masterSuiteTouches.push(new MasterSuiteTouchNew());
    // Push New Form Control for Touch
    this.masterSuiteFormControl.touches.push({
      Name: new FormControl('', [Validators.required]),
      Package: new FormControl('', [Validators.required]),
    });
    // Push New Filteroptions on form
    this.packagesFilteredOptions.push(
      this.masterSuiteFormControl.touches[index].Package.valueChanges.pipe(
        startWith(''),
        map((value) => this.parsePackageValue(value))
      )
    );
  }

  removeTouch(touch: IMasterSuiteTouch, index: number) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      data: {
        header: `Delete Touch: ${touch.name}`,
        message: 'Are you sure you want to delete this master suite touch?',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.masterSuite.masterSuiteTouches.splice(index, 1);
          this.masterSuiteFormControl.touches.splice(index, 1);
          this.packagesFilteredOptions.splice(index, 1);

          // this.masterSuite.settingsVariants.forEach((variant, i) => {
          //   variant.variantTouches.splice(index, 1);
          //   this.masterSuiteFormControl.variants[i].Setting.splice(index, 1);
          //   this.settingsFilteredOptions[i].splice(index, 1);
          // });
        }
      });
  }

  dragDropTouch(event: any) {
    moveItemInArray(
      this.masterSuite.masterSuiteTouches,
      event.previousIndex,
      event.currentIndex
    );
    moveItemInArray(
      this.masterSuiteFormControl.touches,
      event.previousIndex,
      event.currentIndex
    );
  }

  // onTouchNameChange(touch: MasterSuiteTouch, index: number) {
  //   this.masterSuite.settingsVariants.forEach((variant) => {
  //     variant.variantTouches[index].masterSuiteTouchName = touch.name;
  //   });
  // }

  onTouchPackageSelection(_package: IPackage, index: number) {
    this.packageSubscription = this.packagesService
      .detailPackage(_package.id!)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (packageDetails) => {
          if (packageDetails) {
            //Set back to zero
            this.masterSuite.masterSuiteTouches[index].masterSuiteTouchAssets =
              [];
            this.masterSuite.masterSuiteTouches[index].packageStillCount = 0;
            this.masterSuite.masterSuiteTouches[index].packageVideoCount = 0;
            // this.masterSuite.settingsVariants.forEach((variant) => {
            //   variant.variantTouches[index].variantTouchAssets = [];
            // });

            //Sort assets by index
            packageDetails.assets.sort((a, b) => a.index - b.index);
            packageDetails.assets.forEach((asset) => {
              var newMasterSuiteTouchAsset = new MasterSuiteTouchAssetNew();
              newMasterSuiteTouchAsset.packageAssetId = asset.id;
              newMasterSuiteTouchAsset.packageAssetType = asset.type;
              newMasterSuiteTouchAsset.packageAssetIndex = asset.index;
              //Calculate Counts
              if (asset.type == 'Still') {
                this.masterSuite.masterSuiteTouches[
                  index
                ].packageStillCount += 1;
              } else {
                this.masterSuite.masterSuiteTouches[
                  index
                ].packageVideoCount += 1;
              }

              //Retreive Angle from packes for display
              newMasterSuiteTouchAsset.packageAssetRobotOutDegrees =
                asset.robotOutDegrees;
              newMasterSuiteTouchAsset.packageAssetTablePosition =
                asset.tablePosition;

              // Added MasterSuiteTouchAsset to touch
              this.masterSuite.masterSuiteTouches[
                index
              ].masterSuiteTouchAssets.push(newMasterSuiteTouchAsset);
            });
          }
          return _package;
        },
        (error) => {
          this.serverErrorMessage =
            'There was an error loading the Users from the server. Please restart the system and try again';
          this._alert.open('Server Error: ' + error.message, 'close', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            panelClass: ['error'],
          });
        }
      );
  }

  onStepSelected(id: number) {
    this.tabIndex = id;
    this.tabChanged.emit(this.tabIndex);
  }

  validationMessage(property: string) {
    return this.errorMessages.find((error) => error.property == property)
      ?.message;
  }

  openCreateEnhancement() {
    this.router.navigate([this.router.url, 'enhancement-variant']);
  }

  viewPackage(id: string) {
    var url = `/packages/${id}/update`;
    window.open(url, '_blank');
  }

  updateNotes(touch: IMasterSuiteTouch) {
    this.masterSuitesService
      .updateMasterSuiteTouchNotes(touch.id!, touch.notes!)
      .subscribe((res) => {
        console.log(res);
        touch.notes = res.notes;
        touch.notesUpdatedBy = res.notesUpdatedBy;
        touch.notesUpdatedByName = this.authService.authInformation.name;
        touch.notesUpdatedAt = res.notesUpdatedAt;
        touch.version = res.version;
      });
  }

  breakpointChanged() {
    switch (true) {
      case this.breakpointObserver.isMatched('(min-width: 2200px)'):
        this.colPerScreen = 7;
        break;

      case this.breakpointObserver.isMatched(Breakpoints.XLarge):
        this.colPerScreen = 6;
        break;

      case this.breakpointObserver.isMatched(Breakpoints.Large):
        this.colPerScreen = 5;
        break;

      case this.breakpointObserver.isMatched(Breakpoints.Medium):
        this.colPerScreen = 4;
        break;

      case this.breakpointObserver.isMatched(Breakpoints.Small):
        this.colPerScreen = 3;
        break;

      case this.breakpointObserver.isMatched(Breakpoints.XSmall):
        this.colPerScreen = 2;
        break;
      default:
        this.colPerScreen = 1;
        break;
    }
  }

  parseAssets(touch: IMasterSuiteTouch): IMasterSuiteTouchAsset[] {
    var assets: IMasterSuiteTouchAsset[] = [];
    touch.masterSuiteTouchAssets.forEach((asset, i) => {
      asset.label =
        asset.packageAssetType == 'Still'
          ? `Angle ${i + 1}`
          : `Video ${i + 1 - touch.packageStillCount}`;
      assets.push(asset);
      asset.copies?.forEach((copy, j) => {
        copy.label = asset.label + `.${j + 1}`;
        copy.shootAssetPlaceholderThumbnailUri =
          asset.shootAssetPlaceholderThumbnailUri;
        copy.shootAssetPlaceholderReviewUri =
          asset.shootAssetPlaceholderReviewUri;
        assets.push(copy);
      });
    });
    return assets;
  }

  addAdditionalAsset(touchAsset: IMasterSuiteTouchAsset) {
    const { id, copies, ...tmp } = touchAsset;
    if (touchAsset.copies) {
      touchAsset.copies.push({
        id: '',
        ...tmp,
        sourceId: id ?? '',
      });
    } else {
      touchAsset.copies = [
        {
          id: '',
          ...tmp,
          sourceId: id ?? '',
        },
      ];
    }
  }

  removeAdditionalAsset(
    touch: IMasterSuiteTouch,
    additionalAsset: IMasterSuiteTouchAsset
  ) {
    var asset = touch.masterSuiteTouchAssets.find(
      (x) => x.id == additionalAsset.sourceId
    );

    if (additionalAsset.id) {
      const dialogRef = this.dialog.open(WarningModalComponent, {
        data: {
          header: 'Warning',
          content:
            'Please note removing this additional asset will remove any selects asociated with it!',
          titleButtonNo: 'cancel',
          showBtnNo: true,
        },
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          const index = asset!.copies!.findIndex(
            (t: any) => t.id === additionalAsset.id
          );
          asset!.copies!.splice(index, 1);
        }
      });
      return;
    }
    const index = asset!.copies!.findIndex(
      (t: any) => t.id === additionalAsset.id
    );
    asset!.copies!.splice(index, 1);
  }

  shootPlaceholderChange(
    fileInputEvent: FileList,
    asset: IMasterSuiteTouchAsset
  ) {
    var file = fileInputEvent[0];
    this.masterSuitesService
      .masterSuiteTouchAssetShootPlaceholderUpload(asset.id!, file)
      .pipe(takeUntil(this.destroy$))
      .subscribe((touchAsset: IMasterSuiteTouchAsset) => {
        if (touchAsset) {
          asset.shootAssetPlaceholderRawUri = null;
          asset.shootAssetPlaceholderReviewUri = null;
          asset.shootAssetPlaceholderThumbnailUri = null;

          asset.shootAssetPlaceholderRawUri =
            touchAsset.shootAssetPlaceholderRawUri;
          asset.shootAssetPlaceholderReviewUri =
            touchAsset.shootAssetPlaceholderReviewUri;
          asset.shootAssetPlaceholderThumbnailUri =
            touchAsset.shootAssetPlaceholderThumbnailUri;
          asset.version = touchAsset.version;
          this._alert.open(
            `Uploaded to ${asset.label} successfully!`,
            'close',
            {
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
              duration: 2000,
              panelClass: ['success'],
            }
          );
        }
      });
  }
}
