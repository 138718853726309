<div class="comment-box">
  <div class="comment-box__header">
    <mat-tab-group
      *ngIf="isFirstLoaded"
      [selectedIndex]="currentChannel"
      (selectedIndexChange)="onChangeChannel($event)"
    >
      <ng-container *ngFor="let channel of listChannel">
        <mat-tab>
          <ng-template mat-tab-label>
            <span>{{ channel.label }}</span>
          </ng-template>
        </mat-tab>
      </ng-container>
    </mat-tab-group>
  </div>

  <div #listCommentEl class="comment-box__list" (scroll)="onScroll($event)">
    <div class="comment-box__spacer"></div>
    <ng-container *ngFor="let comment of listComment; let i = index">
      <div class="comment-box__item" [ngClass]="{ first: i === 0 }">
        <div class="comment-box__item-content">
          <app-comment-item
            [id]="comment.id"
            [data]="comment"
            (selected)="versionSelected.emit(comment)"
            (mouseenter)="
              comment.imageReference ? commentHover.emit(comment) : ''
            "
            (mouseleave)="commentHover.emit(null)"
          ></app-comment-item>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="comment-box__input">
    <form class="example-form">
      <mat-form-field class="example-full-width">
        <mat-icon *ngIf="commentBoundingBox" matPrefix>rate_review</mat-icon>
        <textarea
          [(ngModel)]="commentText"
          [ngModelOptions]="{ standalone: true }"
          matInput
          class="form-control form-control-sm"
          placeholder="Type a comment.."
          cdkTextareaAutosize
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="5"
          [mention]="users"
          [mentionConfig]="{ triggerChar: '@', maxItems: 4, labelKey: 'name' }"
          (searchTerm)="onFilterUser($event)"
          (itemSelected)="onTagged($event)"
          (opened)="setOpenTagger()"
          (closed)="setCloseTagger()"
          (keydown)="triggerFunction($event)"
          (focus)="focusComment(true)"
          (focusout)="focusComment(false)"
        ></textarea>
        <mat-icon matSuffix class="cursor-pointer" (click)="sendComment()"
          >send</mat-icon
        >
      </mat-form-field>
    </form>
  </div>
</div>
