import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { OutputConfirmProductSearch } from 'src/app/models/products/productSearch';
import { OrganizationsService } from 'src/app/services/organizations.service';
import { forkJoin } from 'rxjs';
import {
  OutputFormat,
  StillOutputFormat,
  VideoOutputFormat,
} from 'src/app/models/organizations/form-setting';
import { FormControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DownloadOutputService } from 'src/app/services/download-output.service';
import { WarningModalComponent } from '../warning-modal/warning-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OutputsCollectionsService } from 'src/app/services/outputs-collections.service';

@Component({
  selector: 'app-generate-outputs-collection',
  templateUrl: './generate-outputs-collection.component.html',
  styleUrls: ['./generate-outputs-collection.component.scss'],
})
export class GenerateOutputsCollectionComponent {
  fileNameSettings: OutputFormat[] = [];
  isLoading = true;
  isDownload = false;
  outputForm = new FormGroup({
    stills: new FormControl<string[]>([]),
    videos: new FormControl<string[]>([]),
  });

  outputForms: FormGroup[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public dataDialog: OutputConfirmProductSearch,
    private organizationsService: OrganizationsService,
    public dialogRef: MatDialogRef<GenerateOutputsCollectionComponent>,
    private downloadOutputService: DownloadOutputService,
    private outputsCollectionService: OutputsCollectionsService,
    public dialog: MatDialog,
    private _alert: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.getOutputFormat();
  }

  getOutputFormat() {
    this.organizationsService
      .getOutputFormat(this.dataDialog?.organizationId)
      .subscribe({
        next: (res) => {
          this.fileNameSettings = res.map((el) => ({
            ...el,
            configText:
              this.textStillsOutputDownload(el.stillFormat) +
              ', ' +
              this.textVideosOutputDownload(el.videoFormat),
            configTextStills:
              el.stillFormat != null
                ? this.textStillsOutputDownload(el.stillFormat)
                : '',
            configTextVideos:
              el.videoFormat != null
                ? this.textVideosOutputDownload(el.videoFormat)
                : '',
          }));
          this.isLoading = false;
          this.fileNameSettings.forEach((outputFormat) => {
            this.outputForms.push(
              new FormGroup({
                id: new FormControl<string>(outputFormat.id),
                name: new FormControl<string>(outputFormat.name),
                configText: new FormControl<string>(outputFormat.configText!),
                configTextStills: new FormControl<string>(
                  outputFormat.configTextStills!
                ),
                configTextVideos: new FormControl<string>(
                  outputFormat.configTextVideos!
                ),
                checked: new FormControl<boolean>(true),
                stills: new FormControl<boolean>(
                  outputFormat.stillFormat != null
                ),
                videos: new FormControl<boolean>(
                  outputFormat.videoFormat != null
                ),
              })
            );
          });
        },
        error: () => {
          this.isLoading = false;
        },
      });
  }

  create() {
    var outputFormatIds: {
      outputFormatId: string;
      type: string;
    }[] = [];

    var valid = true;
    this.outputForms.forEach((format) => {
      if (format.get('checked')!.value) {
        outputFormatIds.push({
          outputFormatId: format.get('id')?.value,
          type:
            format.get('stills')?.value && format.get('videos')?.value
              ? 'Combo'
              : !format.get('stills')?.value && format.get('videos')?.value
              ? 'Video'
              : 'Still',
        });
        if (!format.get('stills')?.value && !format.get('videos')?.value) {
          this._alert.open(
            'Selected Formats must have either one type or both selected!',
            'close',
            {
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
              duration: 2000,
              panelClass: ['error'],
            }
          );
          valid = false;
          return;
        }
      }
    });
    if (valid) {
      this.outputsCollectionService
        .createOutputsCollection({
          productIds: this.dataDialog.productIds || [],
          outputFormats: outputFormatIds,
          organizationId: this.dataDialog.organizationId!,
          batchId: this.dataDialog.batchId!,
        })
        .subscribe({
          next: (res: any) => {
            this.downloadOutputHandler(res);
          },
          error: () => {
            this.isDownload = false;
          },
        });
    }
  }

  downloadOutputHandler(res: any) {
    this.isDownload = false;
    if (res.isValid === false && res.isSuccess === false) {
      this.dialog.open(WarningModalComponent, {
        data: {
          header: 'Warning',
          content: res.errorMessage,
        },
      });
    } else if (res.isValid === false && res.isSuccess === true) {
      const dialogWarning = this.dialog.open(WarningModalComponent, {
        data: {
          header: 'Warning',
          content: res.errorMessage,
        },
      });

      dialogWarning.afterClosed().subscribe(() => {
        this.showToastSuccess();
      });
    } else {
      this.showToastSuccess();
    }
    this.dialogRef.close();
  }

  textStillsOutputDownload(fileImage: StillOutputFormat) {
    if (fileImage == null) {
      return '';
    }
    return (
      '<b>Stills: </b>' +
      fileImage.format.toUpperCase() +
      (fileImage.width ? ' Width:' + fileImage.width : '') +
      (fileImage.height ? ' Height:' + fileImage.height : '') +
      (fileImage.dpi ? ' DPI:' + fileImage.dpi : '') +
      (fileImage.format === 'Jpg'
        ? ' Color Space:' +
          fileImage.colorSpace +
          (fileImage.quality ? ' Quality:' + fileImage.quality : '')
        : '') +
      (fileImage.format === 'WebP' && fileImage.lossless
        ? ' Lossless'
        : ' Quality:' + fileImage.quality) +
      ((fileImage.format === 'Png' || fileImage.format === 'Tiff') &&
      fileImage.bitDepth
        ? ' Bit-depth:' + fileImage.bitDepth
        : '')
    );
  }
  textVideosOutputDownload(fileVideo: VideoOutputFormat) {
    if (fileVideo == null) {
      return '';
    }
    return (
      '<b>Videos: </b>' +
      fileVideo.format.toUpperCase() +
      (fileVideo.width ? ' Width:' + fileVideo.width : '') +
      (fileVideo.height ? ' Height:' + fileVideo.height : '')
    );
  }

  showToastSuccess() {
    this._alert.open(
      'Successfully! The download archive link has been sent to your email',
      'close',
      {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 2000,
        panelClass: ['success'],
      }
    );
  }
}
