import { Component } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  Observable,
  Subject,
  firstValueFrom,
  map,
  startWith,
  takeUntil,
} from 'rxjs';
import { IOrganization } from 'src/app/models/organizations/organization';
import { IUserGroups } from 'src/app/models/users/userGroups';
import { UserCreate } from 'src/app/models/users/userCreate';
import { ValidationErrors } from 'src/app/models/validationErrors';
import { OrganizationsService } from 'src/app/services/organizations.service';
import { UsersService } from 'src/app/services/users.service';
import { environment } from 'src/environments/environment';
import sha256 from 'crypto-js/sha256';
import { IUser } from 'src/app/models/users/user';
import { timezoneList } from 'src/app/utils/timezone';
import { dateTimeFormat, defaultFormat } from 'src/app/utils/dateFormat';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss'],
})
export class UserCreateComponent {
  private destroy$ = new Subject();
  errorState: Boolean = false;
  timezoneListFiltered = timezoneList;
  dateTimeFormat = dateTimeFormat;
  formData = new UntypedFormGroup({});

  constructor(
    private usersService: UsersService,
    private organizationsService: OrganizationsService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<UserCreateComponent>,
    private _alert: MatSnackBar
  ) {
    this.nameFormControl = new FormControl('', [Validators.required]);
    this.emailFormControl = new FormControl('', [Validators.required]);

    this.mmaptUserGroup = new FormControl('', [Validators.required]);
    this.pinFormControl = new FormControl('', [Validators.pattern('^[0-9]*$')]);
    this.pinFormControl.disable();
    this.organizationFormControl = new FormControl('', [Validators.required]);
  }

  newUser: UserCreate = new UserCreate();
  errorMessages: ValidationErrors[] = [];
  validatorProperties: string[] = [];
  nameFormControl: FormControl;
  emailFormControl: FormControl;
  mmaptUserGroup: FormControl;
  mmaptUserGroups: string[] = [
    'Super Admin',
    // 'Imagery Team Member',
    // 'SkyTeam Member',
    'Setup Admin',
    'Robot User',
  ];
  pinFormControl: FormControl;
  userType: string = 'mmapt';
  organizations: IOrganization[] = [];
  organizationFormControl: FormControl;
  organizationFilteredOptions!: Observable<IOrganization[]>;

  ngOnInit() {
    const initTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const tz = timezoneList.find((el) => {
      const idx = el.utc.findIndex((tz) => tz === initTz);
      return idx > -1;
    });
    this.formData = new UntypedFormGroup({
      timezone: new FormControl<string>(tz ? tz.text : ''),
      dateFormat: new FormControl<string>(defaultFormat),
      is24H: new FormControl<boolean>(true),
      asAgo: new FormControl<boolean>(true),
    });
  }

  onMmaptUserGroupChange() {
    if (this.mmaptUserGroup.value.includes('Robot User')) {
      this.pinFormControl.enable();
      this.pinFormControl.markAllAsTouched();
      this.pinFormControl.setErrors({ validationErrors: 'validation errors' });
    } else {
      this.pinFormControl.disable();
    }
  }

  onUserTypeChange() {
    if (this.userType == 'client') {
      this.listOrganizations();
    }
  }

  //Organizations List and filters
  listOrganizations() {
    if (this.organizations.length == 0) {
      this.organizationsService
        .listOrganizations()
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (organizations: any) => {
            if (organizations) {
              this.organizations = organizations;
              console.log('Organizations Found: ', organizations);
              this.organizationFilteredOptions =
                this.organizationFormControl.valueChanges.pipe(
                  startWith(''),
                  map((value) => this.parseOrganizationValue(value))
                );
            }
          },
          error: (error) => {
            console.log(error);
          },
        });
    }
  }
  private _filter(name: string): IOrganization[] {
    const filterValue = name.toLowerCase();
    return this.organizations.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }
  parseOrganizationValue(value: any) {
    const name = typeof value === 'string' ? value : value?.name;
    return name ? this._filter(name as string) : this.organizations.slice();
  }
  displayFn(option: IOrganization | null) {
    return option ? option.name : '';
  }

  //display validation messages
  validationMessage(property: string) {
    return this.errorMessages.find((error) => error.property == property)
      ?.message;
  }

  userCreated: boolean = false;
  createdUser!: IUser;
  async createUser() {
    if (this.userCreated == false) {
      var createResult = await this.create();
      if (createResult == undefined) {
        return;
      } else {
        this.userCreated = true;
        this.createdUser = createResult;
      }
    }

    var groupsResult = await this.updateGroups();
    if (groupsResult == false) {
      return;
    }

    this.dialogRef.close(true);
  }

  async create(): Promise<void | IUser> {
    const { timezone, dateFormat, asAgo, is24H } = this.formData.value;
    const tz = timezoneList.find((el) => el.text === timezone);
    this.newUser = {
      name: this.nameFormControl.value,
      emailAddress: this.emailFormControl.value,
      preference: {
        timezone: tz!.utc[0],
        dateTimeFormat: dateFormat,
        asAgo,
        is24H,
      },
    };
    // if client add org id
    if (this.userType == 'client') {
      this.newUser.organizationId = this.organizationFormControl.value.id;
    }

    var result = await firstValueFrom(
      this.usersService.createUser(this.newUser)
    )
      .then((response) => {
        this._alert.open('Successfully created user profile', 'close', {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 2000,
          panelClass: ['success'],
        });
        return response;
      })
      .catch((error) => {
        this.errorState = true;
        if (error.status === 422) {
          this.errorMessages = [];
          this.validatorProperties = [];
          error.error.validationErrors.forEach((error: ValidationErrors) => {
            this.errorMessages.push(error);
            this.validatorProperties.push(error.property);
            this.nameFormControl.markAllAsTouched();
            if (error.property == 'Name') {
              this.nameFormControl.setErrors({
                serverErrors: 'validation errors',
              });
            }
            this.emailFormControl.markAllAsTouched();
            if (error.property == 'EmailAddress') {
              this.emailFormControl.setErrors({
                serverErrors: 'validation errors',
              });
            }
          });
        }
      });
    return result;
  }

  async updateGroups(): Promise<boolean> {
    // Now Add user to groups
    var userGroups: IUserGroups = {
      pinHash: undefined,
      groups: [],
    };
    if (
      this.mmaptUserGroup.value.includes('Robot User') &&
      this.pinFormControl.value.length != 4
    ) {
      this.pinFormControl.markAllAsTouched();
      this.pinFormControl.setErrors({ serverErrors: 'validation errors' });
      return false;
    }
    if (this.userType == 'mmapt') {
      this.mmaptUserGroup.value.forEach((group: string) => {
        if (group == 'Robot User') {
          userGroups.groups.push(environment.cognitoGroups.robotUser);
          userGroups.pinHash = sha256(
            `${this.pinFormControl.value}-${this.createdUser.id}`
          ).toString();
        }
        if (group == 'Super Admin') {
          userGroups.groups.push(environment.cognitoGroups.superAdmin);
        }
        if (group == 'Setup Admin') {
          userGroups.groups.push(environment.cognitoGroups.setupAdmin);
        }
        if (group == 'Imagery Team Member') {
          userGroups.groups.push(environment.cognitoGroups.imageryTeamMember);
        }
        if (group == 'SkyTeam Member') {
          userGroups.groups.push(environment.cognitoGroups.skyTeamMember);
        }
      });
    } else if (this.userType == 'client') {
      userGroups.groups.push(
        this.organizationFormControl.value.cognitoGroupUsers
      );
    }

    var result = await firstValueFrom(
      this.usersService.updateUserGroups(this.createdUser.id, userGroups)
    ).catch((error) => {
      this.errorState = true;
      if (error.status === 422) {
        this.errorMessages = [];
        this.validatorProperties = [];
        error.error.validationErrors.forEach((error: ValidationErrors) => {
          this.errorMessages.push(error);
          this.validatorProperties.push(error.property);
          this.pinFormControl.markAllAsTouched();
          if (error.property == 'Pin') {
            this.pinFormControl.setErrors({
              serverErrors: 'validation errors',
            });
          }
        });
      }
      return false;
    });
    if (result == false) {
      return result;
    }
    return true;
  }

  cancel() {
    if (this.userCreated == true) {
      this.usersService.deleteUser(this.createdUser).subscribe((data) => {
        this.dialogRef.close();
      });
    } else {
      this.dialogRef.close();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  onKey(e: any) {
    const key = e.target.value;
    this.timezoneListFiltered = timezoneList.filter((el) => {
      const flag = el.utc.some((el) =>
        el.toLocaleLowerCase().includes(key.toLocaleLowerCase())
      );
      return (
        flag || el.text.toLocaleLowerCase().includes(key.toLocaleLowerCase())
      );
    });
  }
}
