import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-product-update-warning',
  templateUrl: './product-update-warning.component.html',
  styleUrls: ['./product-update-warning.component.scss']
})
export class ProductUpdateWarningComponent {
  constructor(
    public dialogRef: MatDialogRef<ProductUpdateWarningComponent>,
  ) {}

  onNoClick(): void {
    this.dialogRef.close(false);
  }
  onYesClick() {
    this.dialogRef.close(true);
  }
}
