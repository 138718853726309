<div class="content" *ngIf="loaded">
  <div class="content-header">
    <div class="title">Settings Update</div>
    <span class="spacer"></span>
    <h4 class="router-link" (click)="backClicked()">Back to Settings</h4>
    <button
      [disabled]="!readonly"
      mat-icon-button
      matTooltip="Edit"
      [color]="readonly ? 'success' : 'disabled'"
      class="mat-elevation-z0"
      (click)="readonly = false"
    >
      <mat-icon>edit</mat-icon>
    </button>
  </div>
  <div
    style="justify-content: space-between; display: flex; align-items: center"
  >
    <mat-form-field *ngIf="!!setting" appearance="outline" color="primary">
      <mat-label>Setting Name</mat-label>
      <input
        matInput
        required
        [formControl]="input"
        [(ngModel)]="setting.name"
        [readonly]="readonly"
      />
      <mat-error *ngIf="validatorProperties.includes('Name')">
        {{ validationMessage("Name") }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" color="primary" style="width: 15%">
      <mat-label>Setting Type</mat-label>
      <input
        matInput
        [disabled]="true"
        [readonly]="readonly"
        [(ngModel)]="setting.type"
      />
    </mat-form-field>
    <mat-checkbox
      color="primary"
      class="example-margin"
      [checked]="stillCheck"
      [(ngModel)]="stillCheck"
      [disabled]="readonly"
    >
      Still
    </mat-checkbox>
    <mat-checkbox
      color="primary"
      class="example-margin"
      [checked]="videoCheck"
      [(ngModel)]="videoCheck"
      [disabled]="readonly"
    >
      Video
    </mat-checkbox>
  </div>

  <div style="justify-content: space-between; display: flex">
    <div *ngIf="!!setting && !!setting.still && stillCheck" class="sub-content">
      <div style="justify-content: space-between; display: flex">
        <div class="heading">Stills</div>
        <span class="spacer"></span>
        <mat-icon
          aria-hidden="false"
          aria-label="Example home icon"
          fontIcon="camera_alt"
          style="font-size: 50px; height: 50px; width: 50px"
        ></mat-icon>
      </div>
      <mat-card style="margin-bottom: 10px">
        <mat-card-header>
          <mat-card-title class="asset-heading"> Lights </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-grid-list
            [cols]="setting.still.lights.length / 2"
            rowHeight="100px"
          >
            <mat-grid-tile
              *ngFor="let keyValuePair of setting.still.lights"
              colspan="1"
            >
              <mat-form-field appearance="outline" subscriptSizing="dynamic">
                <mat-label>{{ keyValuePair.key | titlecase }}</mat-label>
                <input
                  *ngIf="keyValuePair.key === 'colourTemperature'; else table"
                  type="number"
                  step="100"
                  [min]="1700"
                  [max]="9600"
                  matInput
                  [(ngModel)]="keyValuePair.value"
                  (blur)="validateColourTemperature(keyValuePair)"
                  [readonly]="readonly"
                />
                <ng-template #table>
                  <input
                    *ngIf="keyValuePair.key == 'table'; else other"
                    type="number"
                    step="1"
                    [min]="0"
                    [max]="100"
                    matInput
                    [(ngModel)]="keyValuePair.value"
                    (blur)="validate(keyValuePair, 0, 100)"
                    [readonly]="readonly"
                  />
                </ng-template>
                <ng-template #other>
                  <input
                    type="number"
                    step="1"
                    [min]="0"
                    [max]="255"
                    matInput
                    [(ngModel)]="keyValuePair.value"
                    (blur)="validate(keyValuePair, 0, 255)"
                    [readonly]="readonly"
                  />
                </ng-template>
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>
        </mat-card-content>
      </mat-card>

      <mat-card
        style="margin-bottom: 10px"
        *ngIf="setting.still.camera != undefined"
      >
        <mat-card-header>
          <mat-card-title class="asset-heading"> Camera </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-grid-list [cols]="setting.still.camera.length" rowHeight="100px">
            <mat-grid-tile
              *ngFor="let keyValuePair of setting.still.camera"
              colspan="1"
            >
              <mat-form-field appearance="outline" subscriptSizing="dynamic">
                <mat-label>{{ keyValuePair.key | titlecase }}</mat-label>
                <input
                  *ngIf="readonly"
                  [readonly]="readonly"
                  matInput
                  [(ngModel)]="keyValuePair.value"
                />
                <mat-select
                  *ngIf="keyValuePair.key === 'iso' && !readonly; else shutter"
                  type="number"
                  [(ngModel)]="keyValuePair.value"
                >
                  <mat-option *ngFor="let iso of isoValues" [value]="iso">
                    {{ iso }}
                  </mat-option>
                </mat-select>
                <ng-template #shutter>
                  <mat-select
                    *ngIf="
                      keyValuePair.key === 'shutter' && !readonly;
                      else aperture
                    "
                    type="number"
                    [(ngModel)]="keyValuePair.value"
                  >
                    <mat-option
                      *ngFor="let shutter of shutterValues"
                      [value]="shutter"
                    >
                      {{ shutter }}
                    </mat-option>
                  </mat-select>
                </ng-template>
                <ng-template #aperture>
                  <input
                    *ngIf="!readonly"
                    type="number"
                    step="1"
                    [min]="10"
                    [max]="22"
                    matInput
                    [(ngModel)]="keyValuePair.value"
                    (blur)="validate(keyValuePair, 10, 22)"
                  />
                </ng-template>
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>
        </mat-card-content>
      </mat-card>
    </div>
    <div *ngIf="!!setting && !!setting.video && videoCheck" class="sub-content">
      <div style="justify-content: space-between; display: flex">
        <div class="heading">Videos</div>
        <span class="spacer"></span>
        <mat-icon
          aria-hidden="false"
          aria-label="Example home icon"
          fontIcon="video_camera_back"
          style="font-size: 50px; height: 50px; width: 50px"
        ></mat-icon>
      </div>

      <mat-card style="margin-bottom: 10px">
        <mat-card-header>
          <mat-card-title class="asset-heading"> Lights </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-grid-list
            [cols]="setting.video.lights.length / 2"
            rowHeight="100px"
          >
            <mat-grid-tile
              *ngFor="let keyValuePair of setting.video.lights"
              colspan="1"
            >
              <mat-form-field appearance="outline" subscriptSizing="dynamic">
                <mat-label>{{ keyValuePair.key | titlecase }}</mat-label>
                <input
                  *ngIf="keyValuePair.key === 'colourTemperature'; else table"
                  type="number"
                  step="100"
                  [min]="1700"
                  [max]="9600"
                  matInput
                  [(ngModel)]="keyValuePair.value"
                  (blur)="validateColourTemperature(keyValuePair)"
                  [readonly]="readonly"
                />
                <ng-template #table>
                  <input
                    *ngIf="keyValuePair.key == 'table'; else other"
                    type="number"
                    step="1"
                    [min]="0"
                    [max]="100"
                    matInput
                    [(ngModel)]="keyValuePair.value"
                    (blur)="validate(keyValuePair, 0, 100)"
                    [readonly]="readonly"
                  />
                </ng-template>
                <ng-template #other>
                  <input
                    type="number"
                    step="1"
                    [min]="0"
                    [max]="255"
                    matInput
                    [(ngModel)]="keyValuePair.value"
                    (blur)="validate(keyValuePair, 0, 255)"
                    [readonly]="readonly"
                  />
                </ng-template>
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>
        </mat-card-content>
      </mat-card>
      <mat-card
        style="margin-bottom: 10px"
        *ngIf="setting.video.camera != undefined"
      >
        <mat-card-header>
          <mat-card-title class="asset-heading"> Camera </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-grid-list [cols]="setting.video.camera.length" rowHeight="100px">
            <mat-grid-tile
              *ngFor="let keyValuePair of setting.video.camera"
              colspan="1"
            >
              <mat-form-field appearance="outline" subscriptSizing="dynamic">
                <mat-label>{{ keyValuePair.key | titlecase }}</mat-label>
                <input
                  *ngIf="readonly"
                  [readonly]="readonly"
                  matInput
                  [(ngModel)]="keyValuePair.value"
                />
                <mat-select
                  *ngIf="keyValuePair.key === 'iso' && !readonly; else shutter"
                  type="number"
                  [(ngModel)]="keyValuePair.value"
                >
                  <mat-option *ngFor="let iso of isoValues" [value]="iso">
                    {{ iso }}
                  </mat-option>
                </mat-select>
                <ng-template #shutter>
                  <mat-select
                    *ngIf="
                      keyValuePair.key === 'shutter' && !readonly;
                      else aperture
                    "
                    type="number"
                    [(ngModel)]="keyValuePair.value"
                  >
                    <mat-option
                      *ngFor="let shutter of shutterValues"
                      [value]="shutter"
                    >
                      {{ shutter }}
                    </mat-option>
                  </mat-select>
                </ng-template>
                <ng-template #aperture>
                  <input
                    *ngIf="!readonly"
                    type="number"
                    step="1"
                    [min]="10"
                    [max]="22"
                    matInput
                    [(ngModel)]="keyValuePair.value"
                    (blur)="validate(keyValuePair, 10, 22)"
                  />
                </ng-template>
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class="content-actions" *ngIf="!readonly">
    <span class="spacer"></span>
    <button mat-stroked-button (click)="cancel()">Cancel</button>
    <button
      mat-button
      color="buttonSuccess"
      style="margin-left: 20px"
      (click)="updateSetting()"
    >
      Update
    </button>
  </div>
</div>
