<div class="login">
  <div class="card-ctn">
    <mat-card class="card-login elevation-z8">
      <mat-card-title class="title">MMAPT Cloud</mat-card-title>
      <mat-card-content>
        <div class="button">
          <button type="submit" color="primary" mat-raised-button id="sendlogin" (click)="onLoginClick()">Login</button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
