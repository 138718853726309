import { Component, Input, OnInit } from '@angular/core';
import { CommentService } from 'src/app/services/comment.service';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {
  @Input() name = '';
  @Input() url = '';

  label = '';
  data = '';

  constructor(private commentServices: CommentService) {}

  ngOnInit(): void {
    const splitName = this.name.split(' ');
    this.label = splitName
      .map((el) => {
        const char = el.split('')[0];
        return !!char ? char.toString().toUpperCase() : '';
      })
      .join('');

    this.commentServices.avatarData.subscribe({
      next: (data) => {
        this.data = data[this.url];
      },
    });
  }
}
