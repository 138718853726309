import {
  Component,
  HostListener,
  ViewChild,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable, Subject, takeUntil } from 'rxjs';
import { versions } from '../../../environments/versions';
import Auth from '@aws-amplify/auth';
import { AuthService } from 'src/app/services/auth.service';
import { IUserProfile } from 'src/app/models/users/userProfile';
import { SignalrService } from 'src/app/services/signalr.service';
import { environment } from 'src/environments/environment';
import { PreferenceComponent } from './preference/preference.component';
import { MatDialog } from '@angular/material/dialog';
import { MenuService } from 'src/app/services/menu.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  mode!: MatDrawerMode;

  user: IUserProfile | null = null;
  isStaff: boolean = false;
  apiBase = environment.apiBase;
  isLoading = true;
  private screenWidth$ = new BehaviorSubject<number>(window.innerWidth);
  private destroy$ = new Subject();
  version: string = versions.revision;
  dateTime: string = versions.dateTime;

  @ViewChild('sidenav') matSidenav!: MatSidenav;

  public routeLinks = [
    { link: 'dashboard', name: 'Dashboard', icon: 'home' },
    { link: 'batches', name: 'Jobs', icon: 'format_list_bulleted' },
    { link: 'collections', name: 'Collections', icon: 'collections' },
    // {name:"Products"},
    //{ link: "dashboard", name: "Admin", icon: "admin_panel_settings" },
  ];

  constructor(
    public dialog: MatDialog,
    private authService: AuthService,
    private signalrService: SignalrService,
    private menuService: MenuService,
    private router: Router
  ) {}

  ngOnInit() {
    this.isStaff = this.authService.getRole('staff');
    Auth.currentAuthenticatedUser()
      .then((user) => {
        console.log(user);
        this.setUserInformation(user);
        const accessToken = user.signInUserSession.accessToken.jwtToken;
        this.signalrService.connect(accessToken);
      })
      .catch(() => {
        console.log('Not signed in');
        this.isLoading = false;
      });

    this.getScreenWidth()
      .pipe(takeUntil(this.destroy$))
      .subscribe((width: number) => {
        if (width < 1650) {
          this.matSidenav && this.matSidenav.close();
          this.mode = 'over';
        } else if (width > 1650) {
          this.mode = 'side';
        }
      });

    this.authService.currentAvatar.subscribe((data: string) => {
      if (data && this.user) {
        this.user.profileImageUrl = data;
      }
    });
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth$.next(event.target.innerWidth);
  }
  getScreenWidth(): Observable<number> {
    return this.screenWidth$.asObservable();
  }

  setUserInformation(user: any) {
    try {
      const cognitoGroups =
        user['signInUserSession']['accessToken']['payload']['cognito:groups'];

      const isStaff = cognitoGroups.some(
        (role: string) =>
          role.includes('superAdmins') ||
          role.includes('imageryTeamMember') ||
          role.includes('skyTeamMemeber')
      );
      const isAdmin = cognitoGroups.some((role: string) =>
        role.includes('superAdmins')
      );
      this.isStaff = isStaff;
      if (isStaff) {
        this.routeLinks.push(
          { link: 'packages', name: 'Packages', icon: 'photo_camera' },
          { link: 'settings', name: 'Settings', icon: 'lightbulb' },
          { link: 'mastersuites', name: 'Master Suites', icon: 'style' }
        );
      }

      if (isAdmin) {
        this.routeLinks.push(
          { link: 'organizations', name: 'Organizations', icon: 'business' },
          { link: 'users', name: 'Users', icon: 'people_alt' }
        );
      }

      const isClient = cognitoGroups.some((role: string) =>
        role.includes('orgUsers')
      );
      this.authService.setCognitoGroup(cognitoGroups);

      this.authService.setRole('staff', isStaff);
      this.authService.setRole('client', isClient);
      this.authService.setRole('admin', isAdmin);

      this.authService
        .getUserInformation()
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (profile: IUserProfile) => {
            this.authService.setAuthInformation(profile);
            this.user = profile;
            this.authService.setDateFormat(profile.preference);
            this.isLoading = false;
          },
        });
    } catch (error) {
      console.log(error);
    }
  }

  home() {
    this.router.navigate(['dashboard']);
  }

  onLogoutClick() {
    console.log('Logout Clicked');
    Auth.signOut({ global: true })
      .then((data) => {
        this.user = null;
      })
      .catch((err) => console.log(err));
  }

  openMenu(event: boolean) {
    this.menuService.updateMenuStatus(event);
  }
  itemClicked() {
    if (this.mode == 'over') {
      this.matSidenav.close();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  openMyPreference() {
    const dialogRef = this.dialog.open(PreferenceComponent, {
      data: this.user,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log(`Dialog result: ${result}`);
        this.user = result;
      }
    });
  }
}
