<mat-form-field class="chip-autocomplete" appearance="outline">
  <mat-label>Filename Format</mat-label>
  <mat-chip-grid #chipGrid aria-label="chip selection">
    <mat-chip-row *ngFor="let chip of formCtrl.value" (removed)="remove(chip)" (click)="selectedChip(chip)">
      {{chip.label}}
      <button matChipRemove [attr.aria-label]="'remove ' + chip.label">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
  </mat-chip-grid>
  <input [placeholder]="formCtrl!.value.length === 0 ?'Please choose at least 1 token' : ''" #chipInput
    [formControl]="chipCtrl" [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event)" />
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let chip of allChips" [value]="chip">
      {{chip.label}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
<mat-error *ngIf="chipCtrl?.hasError('pattern')" style="margin: -15px 0 8px 5px;">Token must
  be [A-Z, 0-9, -, _]</mat-error>
