import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MaterialModule } from './modules/material.module';
import { BatchesService } from './services/batches.services';
import { ProductsService } from './services/products.service';
import { NotificationService } from './services/notification.service';
import { SignalrService } from './services/signalr.service';
import { CommentService } from './services/comment.service';

import { DashboardComponent } from './components/home/dashboard/dashboard.component';
import { ProductListComponent } from './components/home/products/product-list/product-list.component';
import { BatchListComponent } from './components/home/batches/batch-list/batch-list.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { BatchDetailsComponent } from './components/home/batches/batch-details/batch-details.component';
import { BatchCreateComponent } from './components/home/batches/batch-create/batch-create.component';
import { ProductCreateComponent } from './components/home/products/product-create/product-create.component';
import { ProductCreateUploadComponent } from './components/home/products/product-create-upload/product-create-upload.component';
import { ProductUpdateComponent } from './components/home/products/product-update/product-update.component';
import { ProductUpdateWarningComponent } from './components/home/products/product-list/product-update-warning/product-update-warning.component';
import { ProductReceivalsComponent } from './components/home/products/product-receivals/product-receivals.component';
import { DeleteModalComponent } from './components/shared/delete-modal/delete-modal.component';

// interceptors & auth-gaurd
import { HttpInterceptorProviders } from './interceptors/interceptor-provider';
import { AuthGuard } from './auth.guard';
import { PackageListComponent } from './components/home/packages/package-list/package-list.component';
import { PackageCreateComponent } from './components/home/packages/package-create/package-create.component';
import { PackageUpdateComponent } from './components/home/packages/package-update/package-update.component';
import { SettingListComponent } from './components/home/setting/setting-list/setting-list.component';
import { SettingCreateComponent } from './components/home/setting/setting-create/setting-create.component';

import { TitleCasePipe } from '@angular/common';
import { MasterSuiteListComponent } from './components/home/mastersuites/mastersuite-list/mastersuite-list.component';
import { MasterSuiteCreateComponent } from './components/home/mastersuites/mastersuite-create/mastersuite-create.component';
import { SettingUpdateComponent } from './components/home/setting/setting-update/setting-update.component';
import { MastersuiteUpdateComponent } from './components/home/mastersuites/mastersuite-update/mastersuite-update.component';
import { BatchUpdateComponent } from './components/home/batches/batch-update/batch-update.component';
import { ProductDetailsPageComponent } from './components/home/products/product-details-page/product-details-page.component';
import { ProductDetailItemComponent } from './components/home/products/product-details-page/product-detail-item/product-detail-item.component';
import { ProductProgressComponent } from './components/home/products/product-details-page/product-progress/product-progress.component';
import { ReviewAssetsComponent } from './components/home/products/review-assets/review-assets.component';
import { ChatBoxComponent } from './components/shared/chat-box/chat-box.component';
import { CompareImageComponent } from './components/shared/compare-image/compare-image.component';
import { AssetsNavigateComponent } from './components/home/products/review-assets/assets-navigate/assets-navigate.component';
import { CardItemComponent } from './components/shared/card-item/card-item.component';
import { SecurePipe } from './pipes/secure-asset.pipe';

import { OrganizationListComponent } from './components/home/organizations/organization-list/organization-list.component';
import { OrganizationCreateComponent } from './components/home/organizations/organization-create/organization-create.component';
import { OrganizationUpdateComponent } from './components/home/organizations/organization-update/organization-update.component';
import { ReviewActionsComponent } from './components/home/products/review-assets/review-actions/review-actions.component';
import { ProductBulkIngestComponent } from './components/home/products/product-list/product-bulk-ingest/product-bulk-ingest.component';
import { CommentItemComponent } from './components/shared/chat-box/comment-item/comment-item.component';
import { AvatarComponent } from './components/shared/avatar/avatar.component';
import { MastersuiteTouchAssetCardComponent } from './components/home/mastersuites/mastersuite-content/mastersuite-touch-asset-card/mastersuite-touch-asset-card.component';
import { AvailableAssetCardComponent } from './components/home/mastersuites/enhancement-variant-create/available-asset-card/available-asset-card.component';
import { AutoSuggestionComponent } from './components/shared/auto-suggestion/auto-suggestion.component';
import { ProductSearchComponent } from './components/home/products/product-search/product-search.component';
import { ProductSearchItemComponent } from './components/home/products/product-search/product-search-item/product-search-item.component';
import { MentionModule } from 'angular-mentions';

import { UserListComponent } from './components/home/users/user-list/user-list.component';
import { UserCreateComponent } from './components/home/users/user-create/user-create.component';
import { UserUpdateComponent } from './components/home/users/user-update/user-update.component';
import { FormSettingComponent } from './components/home/organizations/organization-update/form-setting/form-setting.component';
import { ChipAutocompleteComponent } from './components/shared/chip-autocomplete/chip-autocomplete.component';
import { NotificationComponent } from './components/shared/notification/notification.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { GenerateOutputsCollectionComponent } from './components/shared/generate-outputs-collection/generate-outputs-collection.component';
import { ProductTransferSettingsComponent } from './components/home/products/product-transfer-settings/product-transfer-settings.component';
import { SelectMastersuitesComponent } from './components/home/products/product-transfer-settings/select-mastersuites/select-mastersuites.component';
import { ValidateTransferComponent } from './components/home/products/product-transfer-settings/validate-transfer/validate-transfer.component';
import { MastersuiteContentComponent } from './components/home/mastersuites/mastersuite-content/mastersuite-content.component';
import { EnhancementVariantContentComponent } from './components/home/mastersuites/mastersuite-content/enhancement-variant-content/enhancement-variant-content.component';
import { MasterSuiteDialogComponent } from './components/shared/master-suite-dialog/master-suite-dialog.component';
import { CustomStepComponent } from './components/shared/custom-step/custom-step.component';
import { EnhancementVariantCreateComponent } from './components/home/mastersuites/enhancement-variant-create/enhancement-variant-create.component';
import { WarningModalComponent } from './components/shared/warning-modal/warning-modal.component';
import { ConfirmModalComponent } from './components/shared/confirm-modal/confirm-modal.component';
import { PreferenceComponent } from './components/home/preference/preference.component';
import { BarChartComponent } from './components/home/dashboard/bar-chart/bar-chart.component';
import { DashboardCardComponent } from './components/home/dashboard/dashboard-card/dashboard-card.component';

import { ProductsQcViewComponent } from './components/home/products/product-list/products-qc-view/products-qc-view.component';
import { QcCardComponent } from './components/home/products/product-list/products-qc-view/qc-card/qc-card.component';
import { ReviewModalComponent } from './components/shared/review-modal/review-modal.component';
import { ReviewFullscreenComponent } from './components/shared/review-fullscreen/review-fullscreen.component';
import { SettingsVariantContentComponent } from './components/home/mastersuites/mastersuite-content/settings-variant-content/settings-variant-content.component';
import { MasterSuiteSettingsVaraintDialogComponent } from './components/shared/master-suite-settings-varaint-dialog/master-suite-settings-varaint-dialog.component';
import { TagsComponent } from './components/shared/tags/tags.component';
import { SelectOrganizationComponent } from './components/shared/select-organization/select-organization.component';
import { UnsavedChangesWarningComponent } from './components/shared/unsaved-changes-warning/unsaved-changes-warning.component';
import { SelectedAssetCardComponent } from './components/home/mastersuites/enhancement-variant-create/selected-asset-card/selected-asset-card.component';
import { DynamicChipSetComponent } from './components/shared/dynamic-chip-set/dynamic-chip-set.component';
import { AngularResizeEventModule } from 'angular-resize-event';
import { ShopifyAuthResultComponent } from './components/home/organizations/shopify-auth-result/shopify-auth-result.component';
import { OutputFormatsComponent } from './components/home/organizations/organization-update/output-formats/output-formats.component';
import { OutputFilenameUpdateComponent } from './components/home/organizations/organization-update/output-formats/output-filename-update/output-filename-update.component';
import { OutputsCollectionsService } from './services/outputs-collections.service';
import { ProductsTableComponent } from './components/shared/products-table/products-table.component';
import { OutputCollectionsListComponent } from './components/home/output-collections/output-collections-list/output-collections-list.component';
import { OutputCollectionsProductListComponent } from './components/home/output-collections/output-collections-product-list/output-collections-product-list.component';
import { GenerateOutputsComponent } from './components/shared/generate-outputs/generate-outputs.component';
import { NgxColorsModule } from 'ngx-colors';
import { GenerateShopifyAssetsSyncComponent } from './components/shared/generate-shopify-assets-sync/generate-shopify-assets-sync.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    ProductListComponent,
    BatchListComponent,
    LoginComponent,
    HomeComponent,
    BatchDetailsComponent,
    BatchCreateComponent,
    ProductCreateComponent,
    ProductCreateUploadComponent,
    ProductUpdateComponent,
    ProductUpdateWarningComponent,
    ProductReceivalsComponent,
    DeleteModalComponent,
    DateAgoPipe,
    PackageListComponent,
    PackageCreateComponent,
    PackageUpdateComponent,
    SettingListComponent,
    SettingCreateComponent,
    MasterSuiteListComponent,
    MasterSuiteCreateComponent,
    SettingUpdateComponent,
    MastersuiteUpdateComponent,
    BatchUpdateComponent,
    ProductDetailsPageComponent,
    ProductDetailItemComponent,
    ProductProgressComponent,
    ReviewAssetsComponent,
    ChatBoxComponent,
    CompareImageComponent,
    AssetsNavigateComponent,
    CardItemComponent,
    SecurePipe,
    OrganizationListComponent,
    OrganizationCreateComponent,
    OrganizationUpdateComponent,
    ReviewActionsComponent,
    ProductBulkIngestComponent,
    CommentItemComponent,
    AvatarComponent,
    MastersuiteTouchAssetCardComponent,
    AvailableAssetCardComponent,
    SelectedAssetCardComponent,
    AutoSuggestionComponent,
    ProductSearchComponent,
    ProductSearchItemComponent,
    UserListComponent,
    UserCreateComponent,
    UserUpdateComponent,
    FormSettingComponent,
    ChipAutocompleteComponent,
    NotificationComponent,
    GenerateOutputsCollectionComponent,
    ProductTransferSettingsComponent,
    SelectMastersuitesComponent,
    ValidateTransferComponent,
    MastersuiteContentComponent,
    EnhancementVariantContentComponent,
    MasterSuiteDialogComponent,
    CustomStepComponent,
    EnhancementVariantCreateComponent,
    WarningModalComponent,
    ConfirmModalComponent,
    SelectOrganizationComponent,
    PreferenceComponent,
    BarChartComponent,
    DashboardCardComponent,
    ProductsQcViewComponent,
    QcCardComponent,
    ReviewModalComponent,
    SettingsVariantContentComponent,
    MasterSuiteSettingsVaraintDialogComponent,
    TagsComponent,
    UnsavedChangesWarningComponent,
    DynamicChipSetComponent,
    ReviewFullscreenComponent,
    ShopifyAuthResultComponent,
    OutputFormatsComponent,
    OutputFilenameUpdateComponent,
    OutputCollectionsListComponent,
    OutputCollectionsProductListComponent,
    ProductsTableComponent,
    GenerateOutputsComponent,
    GenerateShopifyAssetsSyncComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    MaterialModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    ReactiveFormsModule,
    MentionModule,
    ClipboardModule,
    AngularResizeEventModule,
    NgxColorsModule,
  ],
  providers: [
    AuthGuard,
    HttpInterceptorProviders,
    BatchesService,
    ProductsService,
    NotificationService,
    OutputsCollectionsService,
    SignalrService,
    CommentService,
    DatePipe,
    SecurePipe,
    TitleCasePipe,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
