import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { timezoneList } from 'src/app/utils/timezone';
import { get } from 'lodash';
import { dateTimeFormat, defaultFormat } from 'src/app/utils/dateFormat';
import { AuthService } from 'src/app/services/auth.service';
import { IUserSetting } from 'src/app/models/users/userProfile';
import { Subject, firstValueFrom, takeUntil } from 'rxjs';
import { OrganizationsService } from 'src/app/services/organizations.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-preference',
  templateUrl: './preference.component.html',
  styleUrls: ['./preference.component.scss'],
})
export class PreferenceComponent {
  private destroy$ = new Subject();
  userData: any = {};
  apiBase = environment.apiBase;
  formData = new UntypedFormGroup({});
  timezoneListFiltered = timezoneList;
  dateTimeFormat = dateTimeFormat;
  groupList = [
    {
      value: 'superAdmins',
      text: 'Super Admin',
    },
    {
      value: 'setupAdmins',
      text: 'Setup Admin',
    },
    {
      value: 'robotUsers',
      text: 'Robot User',
    },
    {
      value: 'orgUsers',
      text: 'Client',
    },
  ];
  cognitoGroups: string[] = [];
  defaultAvatar = './assets/profile-placeholder.png';
  tmpAvatar: any;
  tmpAvatarFile: any;
  loading = false;
  isStaff: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private usersService: UsersService,
    public _alert: MatSnackBar,
    private authService: AuthService,
    private organizationsService: OrganizationsService,
    private cd: ChangeDetectorRef,
    public dialogRef: MatDialogRef<PreferenceComponent>
  ) {
    this.userData = data;
    console.log('userData', this.userData);
  }

  pageSize: number = 0;
  ngOnInit() {
    this.isStaff = this.authService.getRole('staff');
    const asAgo = get(this.userData, 'preference.asAgo', true);
    const is24H = get(this.userData, 'preference.is24H', true);
    this.pageSize = get(this.userData, 'preference.pageSize', 10);
    this.cognitoGroups = this.authService.getCognitoGroup();
    this.cognitoGroups = this.cognitoGroups.map((el) => el.split('-')[0]);
    // client can't get list orgs
    // const isClient = this.authService.getRole('client');
    // if (isClient) {
    //   this.listOrganizations();
    // }

    if (!this.userData.profileImageUrl) {
      this.tmpAvatar = this.defaultAvatar;
    }

    const initTz = get(
      this.userData,
      'preference.timezone',
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    const tz = timezoneList.find((el) => {
      const idx = el.utc.findIndex((tz) => tz === initTz);
      return idx > -1;
    });
    console.log(tz);

    if (this.userData)
      this.formData = new UntypedFormGroup({
        name: new FormControl<string>(this.userData.name, [
          Validators.required,
        ]),
        email: new FormControl<string>({
          value: this.userData.emailAddress,
          disabled: true,
        }),
        group: new FormControl<string[]>(
          { value: this.cognitoGroups || '', disabled: true },
          [Validators.required]
        ),

        timezone: new FormControl<string>(tz ? tz.text : ''),
        dateFormat: new FormControl<string>(
          get(this.userData, 'preference.dateTimeFormat', defaultFormat)
        ),
        is24H: new FormControl<boolean>(is24H),
        asAgo: new FormControl<boolean>(asAgo),
      });
  }

  listOrganizations() {
    this.organizationsService
      .listOrganizations()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (organizations: any) => {
          this.groupList = organizations.map((el: any) => ({
            ...el,
            value: el.cognitoGroupUsers,
          }));
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  async onSave() {
    if (this.tmpAvatar && this.tmpAvatar !== this.defaultAvatar) {
      this.loading = true;
      let profileResult = await this.updateProfileImage();
      if (profileResult == false) {
        this._alert.open('Error!', 'close', {
          horizontalPosition: 'right',
          verticalPosition: 'bottom',
          panelClass: ['error'],
        });
        this.loading = false;
        return;
      }
    }
    const { name, timezone, dateFormat, asAgo, is24H } = this.formData.value;
    const tz = timezoneList.find((el) => el.text === timezone);
    const payload = {
      name,
      preference: {
        timezone: tz!.utc[0],
        dateTimeFormat: dateFormat,
        asAgo,
        is24H,
        pageSize: this.pageSize,
      },
    };
    this.authService
      .updatePreference(payload)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: IUserSetting) => {
          this.authService.setAvatar(res.profileImageUrl);
          this.authService.setDateFormat(res.preference);
          this.dialogRef.close(res);
          this._alert.open('Successfully updated user profile', 'close', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 2000,
            panelClass: ['success'],
          });
          this.loading = false;
        },
        error: () => {
          this.loading = false;
          this._alert.open('Error!', 'close', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            panelClass: ['error'],
          });
        },
      });
  }

  async updateProfileImage(): Promise<boolean> {
    if (this.tmpAvatarFile != undefined) {
      const isClient = this.authService.getRole('client');
      // Update the profile image
      let result = await firstValueFrom(
        this.usersService[
          !isClient ? 'updateUserProfileImage' : 'updateClientProfileImage'
        ](this.userData.id, this.tmpAvatarFile)
      ).catch((error) => {
        return false;
      });
      if (result == false) return result;
      this.tmpAvatarFile = undefined;
      return true;
    } else {
      return true;
    }
  }

  updateUserProfileImage(fileInputEvent: FileList) {
    this.tmpAvatarFile = fileInputEvent[0];
    this.extractImageFromFile();
  }

  extractImageFromFile(): void {
    let fileReader: FileReader = new FileReader();
    fileReader.readAsDataURL(this.tmpAvatarFile!);
    fileReader.addEventListener(
      'load',
      () => {
        this.tmpAvatar = fileReader.result;
      },
      false
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  onKey(e: any) {
    const key = e.target.value;
    this.timezoneListFiltered = timezoneList.filter((el) => {
      const flag = el.utc.some((el) =>
        el.toLocaleLowerCase().includes(key.toLocaleLowerCase())
      );
      return (
        flag || el.text.toLocaleLowerCase().includes(key.toLocaleLowerCase())
      );
    });
  }
}
