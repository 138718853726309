import { IPackage } from './package';
import { IPackageAsset } from './packageAsset';

export class StillsAssetNew implements IPackageAsset {
  id = null;
  organizationId = null;
  createdAt = '';
  updatedAt = '';
  type = 'still';
  index = 0;
  robotOutDegrees = 0;
  tablePosition = 0;
  heightOffset = 1;
  distanceOffset = 1;
  focusOffset = 1;
  offsets = false;
  version = 0;
}

export class VideoAssetNew implements IPackageAsset {
  id = null;
  organizationId = null;
  createdAt = '';
  updatedAt = '';
  type = 'video';
  index = 0;
  robotOutDegrees = 0;
  tablePosition = 0;
  heightOffset = 1;
  distanceOffset = 1;
  focusOffset = 1;
  tableSpinDistance = 360;
  offsets = false;
  version = 0;
}

export class PackageNew implements IPackage {
  name = '';
  livePreview = {
    robotOutDegrees: 90,
    tablePosition: 0,
    heightOffset: 1,
    distanceOffset: 1,
    focusOffset: 1,
  };
  externalReference = '';
  assets = [];
  id = null;
  cameraOrientation = 'Landscape';
  robotPosition = 'Front';
  organizationId = '';
  createdAt = '';
  updatedAt = '';
  version = 0;
}
