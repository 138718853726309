import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {
  IMasterSuite,
  IMasterSuiteTouch,
} from 'src/app/models/mastersuites/mastersuite';
import {
  AffectedProduct,
  ETransferAction,
  ISettingsTransfer,
  MasterSuiteTouchToTransfer,
} from 'src/app/models/products/actions/settings/settingsTransfer';

@Component({
  selector: 'app-validate-transfer',
  templateUrl: './validate-transfer.component.html',
  styleUrls: ['./validate-transfer.component.scss'],
})
export class ValidateTransferComponent implements OnInit {
  @Input() settingsTransfer!: ISettingsTransfer;
  @Input() masterSuiteDst!: IMasterSuite;
  @Output() valid = new EventEmitter<Boolean>();
  constructor() {}
  affectedProductsDataSource: MatTableDataSource<AffectedProduct>[] = [];
  displayedColumns: string[] = [
    'barcode',
    'reference',
    'description',
    'ingestion',
    'production',
    'postProduction',
  ];

  loaded = false;
  ngOnInit(): void {
    this.initialData();
  }

  initialData(): void {
    this.settingsTransfer.masterSuitesTouchesMapping.sort(
      (a, b) => a.masterSuiteTouchSrc.index - b.masterSuiteTouchSrc.index
    );
    this.affectedProductsDataSource = [];
    this.settingsTransfer.masterSuitesTouchesMapping.forEach(
      (touchToRemove) => {
        this.affectedProductsDataSource.push(
          new MatTableDataSource<AffectedProduct>(
            touchToRemove.affectedProducts
          )
        );
      }
    );
    this.loaded = true;
    console.log(this.masterSuiteDst);
  }

  manualMappingTouchOptions(
    masterSuiteTouchSrc: IMasterSuiteTouch
  ): IMasterSuiteTouch[] {
    var options = this.masterSuiteDst.masterSuiteTouches.filter(
      (touch) => touch.packageId == masterSuiteTouchSrc.packageId
    );
    this.settingsTransfer.masterSuitesTouchesMapping.forEach((map) => {
      if (
        map.masterSuiteTouchDst != null &&
        map.masterSuiteTouchSrc.id != masterSuiteTouchSrc.id
      ) {
        if (options.find((t) => map.masterSuiteTouchDst!.id == t.id) != null) {
          var index = options.findIndex(
            (t) => map.masterSuiteTouchDst!.id == t.id
          );
          options.splice(index, 1);
        }
      }
    });
    return options;
  }

  setDst(
    dstTouch: IMasterSuiteTouch,
    touchMap: MasterSuiteTouchToTransfer,
    event: any
  ) {
    if (event.isUserInput) {
      // ignore on deselection of the previous option
      touchMap.masterSuiteTouchDst = dstTouch;
    }
  }

  removeDst(touchMap: MasterSuiteTouchToTransfer, event: any) {
    if (event.isUserInput) {
      // ignore on deselection of the previous option
      touchMap.masterSuiteTouchDst = null;
    }
  }

  removeMapping(touchMap: MasterSuiteTouchToTransfer) {
    this.settingsTransfer.validTransfer = false;
    this.valid.emit(false);
    touchMap.action = ETransferAction.UNKNOWN;
    touchMap.masterSuiteTouchDst = null;
  }
}
