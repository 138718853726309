import { SafeUrl } from '@angular/platform-browser';
import { IBase } from '../base';

export interface IMasterSuite extends IBase {
  name: string;
  enhancementLink: string;
  masterSuiteTouches: IMasterSuiteTouch[];
  settingsVariants?: ISettingsVariant[];
  touchCount?: number;
  settingsVariantCount?: number;
  enhancementVariantCount?: number;
  version: number;
}

export interface IMasterSuiteTouch extends IBase {
  masterSuiteId: string;
  name: string;
  processingLink: string;
  index: number;
  packageId: string;
  packageName: string;
  packageStillCount: number;
  packageVideoCount: number;
  notes?: string;
  notesUpdatedBy?: string;
  notesUpdatedByName?: string;
  notesUpdatedAt?: string;
  masterSuiteTouchAssets: IMasterSuiteTouchAsset[];
  version: number;
}

export interface IMasterSuiteTouchAsset extends IBase {
  masterSuiteTouchId: string;
  packageAssetId: string | null;
  packageAssetIndex: number;
  packageAssetRobotOutDegrees: number;
  packageAssetTablePosition: Number;
  packageAssetType: string;
  enhancementFlag: boolean;
  shootAssetPlaceholderRawUri: null | string;
  shootAssetPlaceholderReviewUri: null | string;
  shootAssetPlaceholderThumbnailUri: null | string;
  version: number;
  copies?: IMasterSuiteTouchAsset[];
  sourceId?: string;
  additionalAssetIndex?: number | null;
  label?: string;
}
// export interface MasterSuiteTouchAssetCopy extends IBase {
//   masterSuiteTouchId: string;
//   packageAssetId: string | null;
//   packageAssetIndex: number;
//   packageAssetRobotOutDegrees: number;
//   packageAssetTablePosition: Number;
//   packageAssetType: string;
//   enhancementFlag: boolean;
//   shootAssetPlaceholderRawUri: null | string;
//   shootAssetPlaceholderReviewUri: null | string;
//   shootAssetPlaceholderThumbnailUri: null | string;
//   version: number;
//   SourceId: string;
//   additionalAssetIndex?: number | null;
// }

export interface ISettingsVariant extends IBase {
  masterSuiteId: string;
  name: string;
  settingsVariantTouches: ISettingsVariantTouch[];
  version: number;
}

export interface ISettingsVariantTouch extends IBase {
  settingsVariantId: string;
  masterSuiteTouchId: string | null;
  masterSuiteTouchName: string;
  masterSuiteTouchIndex: number;
  settingId: string;
  settingsName: string;
  settingsVariantTouchAssets: ISettingsVariantTouchAsset[];
  version: number;
}

export interface ISettingsVariantTouchAsset extends IBase {
  settingsVariantTouchId: string;
  masterSuiteTouchAssetId: string;
  packageAssetId: string | null;
  version: number;
}

export interface EnhancementVariantCreate {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  version: number;
  organizationId: string;
  name: string;
  masterSuiteId: string;
}

export interface EnhancementVariantPaging {
  pageNumber: number;
  pageSize: number;
  total: number;
  results: EnhancementVariantDetail[];
}

export interface IEnhancementVariant {
  name: string;
  masterSuiteId: string;
  organizationId: string;
  id: string;
  createdAt: Date;
  updatedAt: Date;
  version: number;
}

export interface EnhancementVariantDetail {
  id: string;
  name: string;
  masterSuiteId: string;
  masterSuiteEnhancementVariantAssets: IEnhancementVariantAsset[];
}

export interface IEnhancementVariantAsset {
  id: string;
  masterSuiteEnhancementVariantId: string;
  masterSuiteTouchAssetId: string;
  index: number;
  postAssetPlaceholderRawUri: string | null;
  postAssetPlaceholderReviewUri: string | null;
  postAssetPlaceholderThumbnailUri: string | null;
  shootAssetPlaceholderRawUri: null | string;
  shootAssetPlaceholderReviewUri: null | string;
  shootAssetPlaceholderThumbnailUri: null | string;
  masterSuiteTouchAsset: MasterSuiteTouchAssetUpdate;
  assetName?: string;
}

export interface MasterSuiteTouchAssetUpdate {
  key: null;
  masterSuiteTouchId: string;
  packageAssetId: string;
  shootAssetPlaceholderRawUri: null | string;
  shootAssetPlaceholderReviewUri: null | string;
  shootAssetPlaceholderThumbnailUri: null | string;
  type: string;
  id: string;
  parentShootAssetPlaceholderRawUri: null | string;
  parentShootAssetPlaceholderReviewUri: null | string;
  parentShootAssetPlaceholderThumbnailUri: null | string;
}

export interface MasterSuiteEnhancementVariantAssetsFile {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  version: number;
  organizationId: string;
  masterSuiteEnhancementVariantId: string;
  masterSuiteTouchAssetId: string;
  index: number;
  postAssetPlaceholderRawUri: string;
  postAssetPlaceholderReviewUri: string;
  postAssetPlaceholderThumbnailUri: string;
}

export const BACK_FORM_CREATE_EV = 'backFormCreateEnhancementVariant';
