import { SafeUrl } from '@angular/platform-browser';
import {
  IMasterSuite,
  IMasterSuiteTouch,
  IMasterSuiteTouchAsset,
  ISettingsVariant,
  ISettingsVariantTouch,
  ISettingsVariantTouchAsset,
} from './mastersuite';

export class MasterSuiteNew implements IMasterSuite {
  name = '';
  enhancementLink = '';
  masterSuiteTouches: MasterSuiteTouchNew[] = [];
  //masterSuiteVariants: MasterSuiteVariantNew[] = [];
  id = null;
  organizationId = '';
  createdAt = '';
  updatedAt = '';
  version = 0;
}

export class MasterSuiteTouchNew implements IMasterSuiteTouch {
  masterSuiteId = '';
  name = '';
  processingLink = '';
  packageId = '';
  packageName = '';
  id = null;
  index = 0;
  organizationId = '';
  createdAt = '';
  updatedAt = '';
  masterSuiteTouchAssets: IMasterSuiteTouchAsset[] = [];
  packageStillCount = 0;
  packageVideoCount = 0;
  version = 0;
}

export class MasterSuiteTouchAssetNew implements IMasterSuiteTouchAsset {
  packageAssetRobotOutDegrees = 0;
  packageAssetTablePosition = 0;
  packageAssetType = '';
  packageAssetIndex = 0;
  masterSuiteTouchId = '';
  packageAssetId: string | null = null;
  enhancementFlag = false;
  shootAssetPlaceholderRawUri: null | string = null;
  shootAssetPlaceholderReviewUri: null | string = null;
  shootAssetPlaceholderThumbnailUri: null | string = null;
  shootPlaceholderUri: string | null = null;
  shootPlaceholderThumbnailUri: string | null = null;
  id = null;
  organizationId = '';
  createdAt = '';
  updatedAt = '';
  version = 0;
}

export class SettingsVariantNew implements ISettingsVariant {
  masterSuiteId = '';
  name = '';
  settingsVariantTouches: ISettingsVariantTouch[] = [];
  id = null;
  organizationId = '';
  createdAt = '';
  updatedAt = '';
  version = 0;
}

export class SettingsVariantTouchNew implements ISettingsVariantTouch {
  settingsVariantId = '';
  masterSuiteTouchId: string | null = null;
  settingsVariantTouchAssets: ISettingsVariantTouchAsset[] = [];
  masterSuiteTouchName = '';
  masterSuiteTouchIndex = 0;
  settingId = '';
  settingsName = '';
  id = null;
  organizationId = '';
  createdAt = '';
  updatedAt = '';
  version = 0;
}

export class SettingsVariantTouchAssetNew
  implements ISettingsVariantTouchAsset
{
  settingsVariantTouchId: string = '';
  masterSuiteTouchAssetId: string = '';
  packageAssetId: string | null = '';
  id: string | null = null;
  organizationId: string | null = '';
  createdAt = '';
  updatedAt = '';
  version = 0;
}
