import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IBatch } from '../models/batches/batch';
import { Tag } from '../models/tag';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class TagsService {
  constructor(private http: HttpClient) {}
  private url = `${environment.apiBase}/batches`;

  listTags(type: string) {
    let url = `${environment.apiBase}/${type}/tags`;
    console.log('Getting Batch Tags');
    return this.http.get<Tag[]>(url, httpOptions);
  }

  tag(type: string, id: string, tag: string): Observable<string[]> {
    let url = `${environment.apiBase}/${type}/${id}/tag/${tag}`;
    return this.http.patch<string[]>(url, httpOptions);
  }

  removeTag(type: string, id: string, tag: string): Observable<string[]> {
    let url = `${environment.apiBase}/${type}/${id}/tag/${tag}`;
    return this.http.delete<string[]>(url, httpOptions);
  }
}
