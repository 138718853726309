<div *ngIf="!product" class="loading-overlay">
  <mat-spinner></mat-spinner>
</div>
<mat-card *ngIf="product" class="product-details-page content">
  <div class="content-header title">
    <div class="flex-row">
      <div
        style="cursor: pointer"
        (click)="
          this.clipboardService.copyUrlAndTextToClipboard(
            this.urlService.getFullUrl(),
            this.pageLink
          )
        "
      >
        <div matTooltip="Page Link" matTooltipPosition="right">
          {{
            product.barcode.length == 0 ? product.reference : product.barcode
          }}
          <mat-icon>link</mat-icon>
        </div>
      </div>

      <div class="spacer"></div>
      <h4 class="router-link" (click)="onBack()">
        {{ backToCollection ? "Back to Collection" : "Back to Job" }}
      </h4>
    </div>
  </div>
  <div class="minor-header"></div>

  <mat-expansion-panel expanded="true" hideToggle disabled>
    <br *ngIf="isClient" />
    <mat-expansion-panel-header
      *ngIf="!isClient"
      style="overflow: visible; align-items: center"
    >
      <div
        class="product-details-page__key"
        (click)="copyToClipboard(product.key, 'key')"
      >
        <mat-icon
          *ngIf="product.key"
          class="product-details-page__copy"
          (click)="copyToClipboard(product.key, 'key')"
          >content_copy</mat-icon
        >
        {{ product.key }}
      </div>
      <span class="spacer"></span>
      <button
        mat-icon-button
        matbadgeColor="warn"
        color="primary"
        matTooltip="Notes"
        matTooltipPosition="above"
        [matMenuTriggerFor]="notes"
      >
        <mat-icon
          matBadge="!"
          matBadgePosition="above after"
          [matBadgeHidden]="product.notes == null || product.notes.length == 0"
          >edit_note</mat-icon
        >
      </button>
      <button
        mat-icon-button
        color="success"
        matTooltip="Update"
        matTooltipPosition="above"
        (click)="openProductUpdateDialog(product)"
      >
        <mat-icon color="success">edit</mat-icon>
      </button>
      <mat-menu #notes="matMenu" class="menu">
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <h1 style="padding: 0 10px 0 10px; margin: 0px">Notes</h1>
          <button
            style="margin-right: 10px"
            mat-icon-button
            (click)="patchProductNotes(product)"
            color="success"
          >
            <mat-icon>save</mat-icon>
          </button>
        </div>

        <div style="width: 320px" (click)="$event.stopPropagation()">
          <mat-form-field
            appearance="outline"
            style="width: 300px; padding: 10px"
            subscriptSizing="dynamic"
          >
            <textarea
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              [(ngModel)]="product.notes"
              matInput
              cdkAutosizeMinRows="1"
            ></textarea>
          </mat-form-field>
        </div>
      </mat-menu>
    </mat-expansion-panel-header>
    <mat-grid-list cols="2" rowHeight="60" gutterSize="20">
      <mat-grid-tile class="w-100">
        <mat-form-field
          appearance="outline"
          color="primary"
          class="w-100"
          subscriptSizing="dynamic"
        >
          <mat-label>Barcode</mat-label>
          <input
            matInput
            [readonly]="true"
            value="{{ product.barcode }}"
            class="w-100"
          />
          <button
            *ngIf="product.barcode"
            matSuffix
            mat-icon-button
            (click)="copyToClipboard(product.barcode, 'barcode')"
            matTooltip="Copy"
            matTooltipPosition="above"
          >
            <mat-icon class="product-details-page__copy">content_copy</mat-icon>
          </button>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile class="w-100">
        <mat-form-field
          appearance="outline"
          color="primary"
          subscriptSizing="dynamic"
        >
          <mat-label>Reference</mat-label>
          <input matInput [readonly]="true" value="{{ product.reference }}" />
          <button
            *ngIf="product.reference"
            matSuffix
            mat-icon-button
            (click)="copyToClipboard(product.reference, 'reference')"
            matTooltip="Copy"
            matTooltipPosition="above"
          >
            <mat-icon class="product-details-page__copy">content_copy</mat-icon>
          </button>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile colspan="2" class="w-100">
        <mat-form-field
          appearance="outline"
          color="primary"
          subscriptSizing="dynamic"
        >
          <mat-label>Description</mat-label>
          <input matInput [readonly]="true" value="{{ product.description }}" />
          <button
            matSuffix
            [disabled]="
              product.productLink == null || product.productLink.length == 0
            "
            mat-icon-button
            (click)="openProductLink()"
            matTooltip="Product Link"
            matTooltipPosition="above"
            [color]="
              product.productLink == null || product.productLink.length == 0
                ? 'disabled'
                : 'info'
            "
          >
            <mat-icon>link</mat-icon>
          </button>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile class="w-100">
        <mat-form-field
          appearance="outline"
          color="primary"
          subscriptSizing="dynamic"
        >
          <mat-label>Batch</mat-label>
          <input matInput [readonly]="true" value="{{ product.batchName }}" />
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile class="w-100">
        <mat-form-field
          appearance="outline"
          color="primary"
          subscriptSizing="dynamic"
        >
          <mat-label>Organization</mat-label>
          <input
            matInput
            [readonly]="true"
            value="{{ product.organizationName }}"
          />
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-expansion-panel>

  <div class="product-details-page__content">
    <app-product-progress
      [activeStep]="activeStep"
      [config]="stepperConfig"
      (change)="onStepSelected($event)"
      *ngIf="!isClient"
    ></app-product-progress>

    <div *ngIf="isLoading" class="product-details-page__loading-layout">
      <mat-spinner style="height: 50px !important"></mat-spinner>
    </div>

    <div
      *ngIf="activeStep !== 'preProduction' && !isLoading"
      class="filter-box"
    >
      <div class="action-group">
        <mat-checkbox
          (change)="setAll($event.checked)"
          [checked]="
            selectedAllProduct[
              activeStep === 'production'
                ? 'production'
                : activeStep === 'postProduction'
                ? 'postProduction'
                : 'outputAssets'
            ]
          "
          *ngIf="!isClient || product.releasedInCollection"
          [color]="'primary'"
          >Select all</mat-checkbox
        >
        <button
          *ngIf="!isClient"
          mat-stroked-button
          class="download-multiple"
          (click)="downloadMultiple()"
          [disabled]="disableButtonDownload()"
        >
          <mat-icon>file_download</mat-icon>Download{{
            "(" +
              selectedProducts[
                activeStep === "production"
                  ? "production"
                  : activeStep === "postProduction"
                  ? "postProduction"
                  : "outputAssets"
              ] +
              ")"
          }}
        </button>
        <button
          *ngIf="isClient && product.releasedInCollection"
          mat-stroked-button
          class="download-multiple"
          (click)="downloadMultiple()"
          [disabled]="disableButtonDownload()"
        >
          <mat-icon>file_download</mat-icon>Download Outputs
          {{ "(" + selectedProducts["outputAssets"] + ")" }}
        </button>
      </div>
      <div class="filter-group">
        <button
          *ngIf="activeStep == 'production' || activeStep == 'postProduction'"
          mat-icon-button
          class="apply-enhancement"
          [disabled]="!product.masterSuiteId"
          (click)="openSelectEnhancementVariant()"
        >
          <mat-icon *ngIf="enhancementVariantIdSelected; else noEVSelected"
            >auto_fix_high</mat-icon
          >
          <ng-template #noEVSelected>
            <mat-icon matBadge="i" matBadgeSize="small">auto_fix_high</mat-icon>
          </ng-template>
        </button>

        <mat-form-field
          *ngIf="activeStep == 'postProduction'"
          subscriptSizing="dynamic"
          appearance="outline"
        >
          <mat-label>View</mat-label>
          <mat-select [(ngModel)]="postView" (selectionChange)="onViewChange()">
            <mat-option value="selects"> Selects </mat-option>
            <mat-option value="all"> All </mat-option>
          </mat-select>
        </mat-form-field>

        <button
          *ngIf="
            activeStep == 'outputAssets' &&
            product.externalShopifyId != null &&
            !isClient
          "
          mat-icon-button
          class="apply-enhancement"
          matTooltip="Sync Assets to Shopify"
          (click)="syncProductAssets()"
        >
          <mat-icon>shopify</mat-icon>
        </button>

        <button
          *ngIf="activeStep == 'outputAssets' && !isClient"
          mat-icon-button
          class="apply-enhancement"
          matTooltip="Generate Outputs"
          (click)="generateOutputAssets()"
        >
          <mat-icon>sync</mat-icon>
        </button>
      </div>
    </div>

    <ng-container
      *ngFor="let item of stepperContent[activeStep]; let i = index"
    >
      <div [ngClass]="{ loading: isLoading && activeStep !== 'preProduction' }">
        <app-product-detail-item
          *ngIf="activeStep === 'preProduction'"
          [product]="product"
          [config]="item"
        ></app-product-detail-item>
        <mat-expansion-panel
          *ngIf="activeStep !== 'preProduction'"
          expanded="true"
        >
          <mat-expansion-panel-header>
            <mat-panel-title
              *ngIf="activeStep === 'production'"
              style="justify-content: space-between"
            >
              <div class="touch">
                <div>
                  <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="selectedAllTouch($event.checked, item)"
                    [checked]="item.selectedTouch"
                    [color]="'primary'"
                    *ngIf="displaySelectTouch[item.id]"
                  ></mat-checkbox>
                </div>
                <div class="touch-info">
                  <div class="touch-title">Touch {{ i + 1 }}</div>
                  <div class="touch-description">{{ item.name }}</div>
                </div>
              </div>
              <div
                [matTooltip]="
                  item.quantitySelected === 0
                    ? 'Select Assets to transfer to MS Placeholders'
                    : 'Transfer ' +
                      item.quantitySelected +
                      ' Selected Assets to MS Placeholders'
                "
                (click)="$event.stopPropagation()"
              >
                <button
                  mat-icon-button
                  (click)="transferProductAsset('shootAssets', item.id)"
                  [disabled]="item.quantitySelected === 0"
                >
                  <mat-icon>move_up</mat-icon>
                </button>
              </div>
            </mat-panel-title>
            <mat-panel-title
              *ngIf="activeStep === 'postProduction'"
              style="justify-content: space-between"
            >
              <div class="touch">
                <div class="touch-info">
                  <div class="touch-title">Selects</div>
                </div>
              </div>
              <div
                *ngIf="!isClient"
                [matTooltip]="
                  item.quantitySelected === 0
                    ? 'Select Assets to transfer to EV Placeholders'
                    : 'Transfer ' +
                      item.quantitySelected +
                      ' Selected Assets to EV Placeholders'
                "
                (click)="$event.stopPropagation()"
              >
                <button
                  mat-icon-button
                  (click)="transferProductAsset('postAssets', item.id)"
                  [disabled]="item.quantitySelected === 0"
                >
                  <mat-icon>move_up</mat-icon>
                </button>
              </div>
            </mat-panel-title>
            <mat-panel-title
              *ngIf="activeStep === 'outputAssets'"
              style="justify-content: space-between"
            >
              <div class="touch">
                <div>
                  <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="selectedAllTouch($event.checked, item)"
                    [checked]="item.selectedTouch"
                    [color]="'primary'"
                    *ngIf="displaySelectTouch[item.id]"
                  ></mat-checkbox>
                </div>
                <div class="touch-info">
                  <div class="touch-title">{{ item.name }}</div>
                  <div
                    class="touch-description"
                    [innerHTML]="item.configText"
                  ></div>
                </div>
              </div>
              <div
                [matTooltip]="
                  item.quantitySelected === 0
                    ? 'Select Assets to transfer to MS Placeholders'
                    : 'Transfer ' +
                      item.quantitySelected +
                      ' Selected Assets to MS Placeholders'
                "
                (click)="$event.stopPropagation()"
              >
                <button
                  *ngIf="activeStep != 'outputAssets'"
                  mat-icon-button
                  (click)="transferProductAsset('shootAssets', item.id)"
                  [disabled]="item.quantitySelected === 0"
                >
                  <mat-icon>move_up</mat-icon>
                </button>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div
            class="product-details-page__list"
            [ngStyle]="{
              'grid-template-columns':
                'repeat(' + colPerScreen + ',minmax(0,1fr))'
            }"
          >
            <ng-container *ngFor="let asset of item.assets">
              <app-card-item
                [showExternalStatus]="
                  activeStep === 'postProduction' && !isClient
                "
                [showParentInfo]="
                  !!asset.childIndex && activeStep === 'production'
                "
                [dataType]="activeStep"
                [data]="asset"
                [editCallback]="editCallback"
                [touchId]="item.id"
                [outputFormat]="activeStep == 'outputAssets' ? item : ''"
                [releasedInCollection]="product.releasedInCollection"
                (done)="updateRowHeight()"
                (selectedCheckbox)="selectedCard($event)"
              ></app-card-item>
            </ng-container>
          </div>
          <div
            *ngIf="
              activeStep === 'postProduction' &&
              !isClient &&
              product.masterSuiteEnhancementVariantId == null
            "
            class="no-selects-text"
          >
            No Enhancement Variant is Applied
          </div>
        </mat-expansion-panel>
      </div>
    </ng-container>
  </div>
</mat-card>
