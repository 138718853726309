<div class="content-list content">
  <div class="content-list__head">
    <div class="title">Packages</div>
    <span class="spacer"></span>

    <mat-form-field
      *ngIf="currentLoaded"
      appearance="outline"
      class="action-form"
      style="margin-right: 20px; width: 20%"
    >
      <mat-label>Client</mat-label>
      <input
        type="text"
        placeholder="Pick one"
        aria-label="Client"
        matInput
        [(ngModel)]="currentOrganization"
        [formControl]="organizationControl"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete
        autoActiveFirstOption
        #auto="matAutocomplete"
        [displayWith]="displayFn"
      >
        <mat-option
          *ngFor="let organization of organizationFilteredOptions | async"
          [value]="organization"
        >
          {{ organization.name }}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="invalidOrganization == true">
        {{ invalidOrganizationMessage }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" style="width: 20%">
      <mat-label>Search</mat-label>
      <input matInput type="text" (keyup)="applyFilter($event)" />
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
  </div>

  <div *ngIf="packagesFound" class="break">
    <button
      mat-fab
      extended
      [color]="'buttonSuccess'"
      (click)="createPackage()"
      class="button"
    >
      <mat-icon>add</mat-icon>
      Create
    </button>
  </div>

  <div *ngIf="packagesFound" class="content-container">
    <div class="content-list__list">
      <table mat-table [dataSource]="dataSource" matSort>
        <!-- Batch Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Package Name
          </th>
          <td mat-cell *matCellDef="let package">{{ package.name }}</td>
        </ng-container>

        <!-- Robot Position Column -->
        <ng-container matColumnDef="robotPosition">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Robot Position
          </th>
          <td mat-cell *matCellDef="let package">
            {{ package.robotPosition }}
          </td>
        </ng-container>

        <!-- Batch Name Column -->
        <ng-container matColumnDef="cameraOrientation">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Camera Orientation
          </th>
          <td mat-cell *matCellDef="let package">
            {{ package.cameraOrientation }}
          </td>
        </ng-container>

        <!-- Batch Name Column -->
        <ng-container matColumnDef="externalRefernce">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Reference</th>
          <td mat-cell *matCellDef="let package">
            {{ package.externalReference }}
          </td>
        </ng-container>

        <!-- Product Count Column -->
        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sort>
            Creation Date
          </th>
          <td mat-cell *matCellDef="let package">
            <div
              matTooltip="{{
                package.createdAt | dateAgo : currentConfigDate : true | async
              }}"
              matTooltipPosition="before"
            >
              {{ package.createdAt | dateAgo : currentConfigDate | async }}
            </div>
          </td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="Actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let package" style="text-align: right">
            <button
              mat-icon-button
              matTooltip="Info"
              color="info"
              class="mat-elevation-z0"
              (click)="updatePackage(package)"
            >
              <mat-icon>info</mat-icon>
            </button>
            <button
              [matMenuTriggerFor]="actionsMenu"
              mat-icon-button
              matTooltip="More"
              color="primary"
              class="mat-elevation-z0"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #actionsMenu="matMenu">
              <button
                *ngIf="isAdmin"
                mat-menu-item
                (click)="deletePackage(package)"
              >
                <mat-icon matTooltip="Delete" color="error">delete</mat-icon>
                <span>Delete</span>
              </button>
              <button mat-menu-item (click)="copyPackage(package)">
                <mat-icon matTooltip="Copy" color="primary"
                  >content_copy</mat-icon
                >
                <span>Copy</span>
              </button>
              <button
                mat-menu-item
                (click)="copyPackageAcrossOrganization(package)"
              >
                <mat-icon matTooltip="Copy" color="primary">copy_all</mat-icon>
                <span>Copy to Organization</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <mat-paginator
      class="mat-paginator-sticky"
      [pageSizeOptions]="[10, 20, 50, 100]"
      showFirstLastButtons
      [pageSize]="currentConfigDate.pageSize"
      (page)="setPageSizePreference($event)"
    >
    </mat-paginator>
  </div>
</div>
