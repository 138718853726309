<div class="content-list content">
  <div class="content-list__head">
    <div class="title">Users</div>
    <span class="spacer"></span>
    <mat-spinner *ngIf="loading" diameter="50"></mat-spinner>
    <form>
      <mat-form-field appearance="outline">
        <mat-label>Search</mat-label>
        <input
          matInput
          type="text"
          (keyup)="applyFilter($event)"
          placeholder="Filter"
        />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </form>
  </div>

  <div class="break">
    <button
      mat-fab
      extended
      [color]="'buttonSuccess'"
      (click)="createUser()"
      class="button"
    >
      <mat-icon>add</mat-icon>
      Create
    </button>
  </div>

  <div class="content-container">
    <div class="content-list__list">
      <table mat-table [dataSource]="dataSource" matSort>
        <!-- Prifle Image Column -->
        <ng-container matColumnDef="profileImage">
          <th mat-header-cell *matHeaderCellDef style="width: 52px"></th>
          <td mat-cell *matCellDef="let user">
            <div class="imageContainer" (click)="updateUser(user)">
              <img
                class="image"
                *ngIf="user.profileImageUrl !== null; else placeholder"
                [src]="apiBase + user.profileImageUrl | secure | async"
                alt=""
              />
              <ng-template #placeholder>
                <img class="image" src="./assets/profile-placeholder.png" />
              </ng-template>
            </div>
          </td>
        </ng-container>

        <!-- Batch Number Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let user">{{ user.name }}</td>
        </ng-container>

        <!-- Batch Name Column -->
        <ng-container matColumnDef="emailAddress">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
          <td mat-cell *matCellDef="let user">{{ user.emailAddress }}</td>
        </ng-container>

        <!-- Batch Description Column -->
        <ng-container matColumnDef="groups">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Groups</th>
          <td mat-cell *matCellDef="let user">{{ user._groups.join(", ") }}</td>
        </ng-container>

        <!-- Product Count Column -->
        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sort>
            Creation Date
          </th>
          <td mat-cell *matCellDef="let user">
            <div
              matTooltip="{{
                user.createdAt | dateAgo : currentConfigDate : true | async
              }}"
              matTooltipPosition="before"
            >
              {{ user.createdAt | dateAgo : currentConfigDate | async }}
            </div>
          </td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef style="width: 150px"></th>
          <td mat-cell *matCellDef="let user">
            <button
              [matMenuTriggerFor]="actionsMenu"
              mat-icon-button
              matTooltip="More"
              color="primary"
              class="mat-elevation-z0"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #actionsMenu="matMenu">
              <button mat-menu-item (click)="updateUser(user)">
                <mat-icon color="success">edit</mat-icon>
                <span>Edit</span>
              </button>
              <button mat-menu-item (click)="deleteUser(user)">
                <mat-icon color="error">delete</mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <mat-paginator
      class="mat-paginator-sticky"
      [pageSizeOptions]="[10, 20, 50, 100]"
      showFirstLastButtons
      [pageSize]="currentConfigDate.pageSize"
      (page)="setPageSizePreference($event)"
    >
    </mat-paginator>
  </div>
</div>
