import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from './environments/environment';
import { AppModule } from './app/app.module';
import { enableProdMode } from '@angular/core';
import 'img-comparison-slider';
import { Amplify } from 'aws-amplify';

import { Auth } from '@aws-amplify/auth';

/**
 * This is a hack to fix the issue with the Amplify library treating all incoming urls as OAuth responses.
 * https://github.com/aws-amplify/amplify-js/issues/9208#issuecomment-1309890756*
 */
// @ts-ignore
const _handleAuthResponse = Auth._handleAuthResponse.bind(Auth);
// @ts-ignore
Auth._handleAuthResponse = (url: string) => {
  const configuration = Auth.configure();
  // @ts-ignore
  if (!url.includes(configuration.oauth?.redirectSignIn)) return;
  return _handleAuthResponse(url);
};

Amplify.configure({
  aws_project_region: environment.auth.region,
  aws_cognito_region: environment.auth.region,
  aws_user_pools_id: environment.auth.userPoolId,
  aws_user_pools_web_client_id: environment.auth.clientId,
  cookieStorage: {
    domain: environment.auth.cookieDomain,
    path: '/',
    expires: 365,
    sameSite: 'none',
    secure: true,
  },
  oauth: {
    domain: environment.auth.domain,
    scope: environment.auth.scopes,
    redirectSignIn: environment.auth.redirectSignIn,
    redirectSignOut: environment.auth.redirectSignOut,
    responseType: environment.auth.responseType,
  },
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
