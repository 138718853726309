<h1 mat-dialog-title style="font-size: 30px;">Batch Update</h1>
<mat-dialog-content class="mat-typography">
    <div>
        <mat-form-field appearance="outline" color="primary" class="dialog-input">
            <mat-label>Name</mat-label>
            <input required [formControl]="input" matInput value="{{batch.name}}" [(ngModel)]="batch.name">
            <mat-error *ngIf="validatorProperties.includes('Name')">
                {{validationMessage('Name')}}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" color="primary" class="dialog-input">
            <mat-label>Description</mat-label>
            <input matInput value="{{batch.description}}" [(ngModel)]="batch.description">
        </mat-form-field>
    </div>  
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button mat-button (click)="updateBatch()" #create color="buttonSuccess">Update</button>
</mat-dialog-actions>