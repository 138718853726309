import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { environment } from 'src/environments/environment';
import * as uuid from 'uuid';

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  constructor(private router: Router, private zone: NgZone) {
    this.onListenLoginEvent();
    this.checkCurrentAuthenticated();
  }

  onListenLoginEvent() {
    // Used for listening to login events
    Hub.listen('auth', ({ payload: { event } }) => {
      this.handleLoginEvent(event);
    });
  }

  handleLoginEvent(event: any) {
    if (event === 'cognitoHostedUI' || event === 'signedIn') {
      console.log(event);
      this.zone.run(() => this.router.navigate(['/dashboard']));
    }
  }

  checkCurrentAuthenticated() {
    //currentAuthenticatedUser: when user comes to login page again
    Auth.currentAuthenticatedUser()
      .then(() => {
        this.router.navigate(['/dashboard'], { replaceUrl: true });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onLoginClick() {
    //Auth.federatedSignIn() // Currently Handling Scopes Incorrectly
    window.location.assign(
      `https://${
        environment.auth.domain
      }/oauth2/authorize?redirect_uri=${encodeURIComponent(
        environment.auth.redirectSignIn
      )}&response_type=${environment.auth.responseType}&client_id=${
        environment.auth.clientId
      }&identity_provider=COGNITO&scopes=${encodeURIComponent(
        environment.auth.scopes.join(',')
      )}&state=${uuid.v4()}`
    );
  }
}
