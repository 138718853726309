import {
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  Input,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import {
  EAssetType,
  EStateAsset,
  IAsset,
  latestOutputAssets,
} from 'src/app/models/products/asset';
import { ITouch } from 'src/app/models/products/touch';
import { AuthService } from 'src/app/services/auth.service';
import { ProductsService } from 'src/app/services/products.service';
import { SignalrService } from 'src/app/services/signalr.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-assets-navigate',
  templateUrl: './assets-navigate.component.html',
  styleUrls: ['./assets-navigate.component.scss'],
})
export class AssetsNavigateComponent implements OnInit {
  @Input() productId = '';
  @Input() assetType = 'shoot-asset';
  @Input() fullscreen: boolean = false;
  @Output() change = new EventEmitter();
  @Output() loaded = new EventEmitter();

  private destroy$ = new Subject();
  id = '';
  current = '';
  currentMasterSuiteTouchAssetId = '';
  currentOutputFormatId = '';
  listTouch: ITouch[] = [];
  listPostAssets: IAsset[] = [];
  listOutputs: latestOutputAssets[] = [];
  apiBase = environment.apiBase;
  typingTimeoutRef: any = null;
  isFocusComment = false;
  isExternal = false;
  eState = EStateAsset;

  constructor(
    private signalrService: SignalrService,
    private productService: ProductsService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe((routeParams) => {
      const assetIdChanged = this.route.snapshot.paramMap.get(
        'assetId'
      ) as string;

      const masterSuiteTouchAssetIdChanged = this.route.snapshot.paramMap.get(
        'masterSuiteTouchAssetId'
      ) as string;

      const outputFormatIdChanged = this.route.snapshot.paramMap.get(
        'outputFormatId'
      ) as string;

      if (
        this.current !== assetIdChanged ||
        this.currentMasterSuiteTouchAssetId !==
          masterSuiteTouchAssetIdChanged ||
        this.currentOutputFormatId != outputFormatIdChanged
      ) {
        this.current = assetIdChanged;
        this.currentMasterSuiteTouchAssetId = masterSuiteTouchAssetIdChanged;
        this.currentOutputFormatId = outputFormatIdChanged;
        if (
          (this.route.snapshot.paramMap.get('assetType') as string) ==
          EAssetType.POST_ASSETS
        ) {
          this.parseListPostProductionData(this.listPostAssets);
        } else if (
          (this.route.snapshot.paramMap.get('assetType') as string) ==
          EAssetType.OUTPUT_ASSETS
        ) {
          this.parseListOutputsData(this.listOutputs);
        } else {
          this.parseListProductionData(this.listTouch);
        }
      }
      if (
        this.productId !=
          (this.route.snapshot.paramMap.get('productId') as string) ||
        this.assetType !=
          (this.route.snapshot.paramMap.get('assetType') as string)
      ) {
        this.ngOnInit();
      }
    });
  }

  ngOnInit(): void {
    this.getData();
    this.isExternal = this.authService.getRole('client');
    this.productService.currentIsFocusComment
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.isFocusComment = status;
      });

    this.signalrService.assetData.subscribe({
      next: (data: IAsset) => {
        this.listTouch.forEach((touch: ITouch, idx: number) => {
          touch.assets.forEach((asset: IAsset, idx2: number) => {
            if (asset.id === data.id) {
              this.listTouch[idx].assets[idx2].assetState =
                (this.isExternal
                  ? data.clientReviewState
                  : data.internalReviewState) || EStateAsset.IN_REVIEW;
            }
          });
        });
      },
    });
  }

  parseAsset(assets: IAsset[]) {
    let angleIndex = 1;
    let videoIndex = 1;
    return assets.map((asset: IAsset) => {
      let label = `${asset.type === 'Still' ? 'Angle' : asset.type} `;
      if (asset.type === 'Still') {
        label += angleIndex;
        angleIndex++;
      } else {
        label += videoIndex;
        videoIndex++;
      }

      if (
        this.assetType === EAssetType.POST_ASSETS ||
        this.assetType === EAssetType.OUTPUT_ASSETS
      ) {
        asset.label = label = `Select ${asset.selectsIndex / 1000 + 1}`;
      }

      console.log(asset.internalReviewState);
      return {
        ...asset,
        label,
        assetState:
          (this.isExternal
            ? asset.clientReviewState
            : asset.internalReviewState) || EStateAsset.IN_REVIEW,
      };
    });
  }

  getData() {
    const assetType = this.route.snapshot.paramMap.get('assetType') as string;
    this.current = this.route.snapshot.paramMap.get('assetId') as string;
    const productId = this.route.snapshot.paramMap.get('productId');
    if (this.productId) {
      if (assetType === EAssetType.SHOOT_ASSETS) {
        this.productService
          .getListProduction(productId!)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: (value: ITouch[]) => {
              this.parseListProductionData(value);
              this.scrollToItem();
            },
          });
      } else if (assetType === EAssetType.POST_ASSETS) {
        this.productService
          .getListPostProduction(productId!)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: (value: IAsset[]) => {
              console.log(value);
              this.parseListPostProductionData(value);
              this.scrollToItem();
            },
          });
      } else {
        this.productService
          .getListOutputAssets(productId!)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: (value: latestOutputAssets[]) => {
              console.log(value);
              this.parseListOutputsData(value);
              this.scrollToItem();
            },
          });
      }
    }
  }
  parseListProductionData(value: ITouch[]) {
    this.listTouch = value
      .map((touch: ITouch) => {
        let assets = this.parseAsset(touch.assets);
        const indexActiveRecord = assets.findIndex(
          (asset) => asset.productTouchAssetId === this.current
        );
        if (indexActiveRecord > -1) {
          this.id = assets[indexActiveRecord].id;
          this.loaded.emit({
            label: `${touch.name} / ${assets[indexActiveRecord].label}`,
            activeRecord: assets[indexActiveRecord],
          });
        }
        return {
          ...touch,
          assets: assets.filter((el) => !!el.thumbnailUri),
        };
      })
      .filter((el) => el.assets.length > 0);
  }

  parseListPostProductionData(value: IAsset[]) {
    let assets = this.parseAsset(value);
    const indexActiveRecord = assets.findIndex(
      (asset) =>
        asset.masterSuiteTouchAssetId === this.currentMasterSuiteTouchAssetId
    );
    if (indexActiveRecord > -1) {
      this.id = assets[indexActiveRecord].id;
      console.log(assets[indexActiveRecord]);
      let label = `${
        assets[indexActiveRecord].type === 'Still'
          ? 'Angle'
          : assets[indexActiveRecord].type
      } `;
      if (assets[indexActiveRecord].additionalAssetIndex) {
        this.loaded.emit({
          label: `Touch ${
            assets[indexActiveRecord].masterSuiteTouchIndex / 1000 + 1
          } / ${label} ${
            assets[indexActiveRecord].packageAssetIndex / 1000 + 1
          }.${assets[indexActiveRecord].additionalAssetIndex! + 1}  (${
            assets[indexActiveRecord].label
          })`,
          selectIndex: assets[indexActiveRecord].selectsIndex,
          activeRecord: assets[indexActiveRecord],
        });
      } else {
        this.loaded.emit({
          label: `Touch ${
            assets[indexActiveRecord].masterSuiteTouchIndex / 1000 + 1
          } / ${label} ${
            assets[indexActiveRecord].packageAssetIndex / 1000 + 1
          }  (${assets[indexActiveRecord].label})`,
          selectIndex: assets[indexActiveRecord].selectsIndex,
          activeRecord: assets[indexActiveRecord],
        });
      }
    }
    this.listPostAssets = assets;
  }
  parseListOutputsData(value: latestOutputAssets[]) {
    this.listOutputs = value
      .map((format: latestOutputAssets) => {
        let assets = this.parseAsset(format.outputAssets);
        console.log(assets);
        const indexActiveRecord = assets.findIndex(
          (asset) =>
            asset.masterSuiteTouchAssetId ===
              this.currentMasterSuiteTouchAssetId &&
            asset.outputFormatId === this.currentOutputFormatId
        );
        if (indexActiveRecord > -1) {
          this.id = assets[indexActiveRecord].id;
          this.loaded.emit({
            label: `${format.name} / ${assets[indexActiveRecord].label}`,
            activeRecord: assets[indexActiveRecord],
          });
        }
        return {
          ...format,
          assets: assets,
        };
      })
      .filter((el) => el.assets.length > 0);
    console.log(this.listOutputs);
  }

  scrollToItem() {
    setTimeout(() => {
      if (window.innerWidth < 1440) {
        this.scrollToCurrentLR('ArrowRight');
      } else {
        this.scrollToCurrentUD('ArrowDown');
      }
    }, 500);
  }

  getLinkImage(asset: IAsset) {
    return ProductsService.getLinkImage(asset.thumbnailUri);
  }

  onSelect(
    asset: IAsset,
    isClick: boolean = false,
    touch?: ITouch,
    format?: latestOutputAssets
  ) {
    if (!asset.id) {
      return;
    }
    this.id = asset.id;
    this.current = asset.productTouchAssetId!;
    this.currentMasterSuiteTouchAssetId = asset.masterSuiteTouchAssetId!;
    const batchId = this.route.snapshot.paramMap.get('batchId');
    const productId = this.route.snapshot.paramMap.get('productId');
    if (this.current) {
      const assetType = this.route.snapshot.paramMap.get('assetType');
      if (assetType === EAssetType.OUTPUT_ASSETS) {
        this.currentOutputFormatId = format!.id;
        this.router.navigateByUrl(
          `batches/${batchId}/products/${productId}/productTouchAsset/${this.current}/masterSuiteTouchAsset/${this.currentMasterSuiteTouchAssetId}/outputFormat/${asset.outputFormatId}/${assetType}`
        );
      } else {
        this.router.navigateByUrl(
          `batches/${batchId}/products/${productId}/productTouchAsset/${this.current}/masterSuiteTouchAsset/${this.currentMasterSuiteTouchAssetId}/${assetType}`
        );
      }

      setTimeout(() => {
        if (touch != null && assetType === EAssetType.SHOOT_ASSETS) {
          this.loaded.emit({
            label: `${touch.name} / ${asset.label}`,
            activeRecord: asset,
          });
        } else if (format != null && assetType === EAssetType.OUTPUT_ASSETS) {
          console.log('sdfsdf');
          this.loaded.emit({
            label: `${format.name} / ${asset.label}`,
            activeRecord: asset,
          });
        } else {
          let label = `${asset.type === 'Still' ? 'Angle' : asset.type} `;
          if (asset.additionalAssetIndex) {
            this.loaded.emit({
              label: `Touch ${
                asset.masterSuiteTouchIndex / 1000 + 1
              } / ${label} ${asset.packageAssetIndex / 1000 + 1}.${
                asset.additionalAssetIndex + 1
              }  (${asset.label})`,
              selectIndex: asset.selectsIndex,
              activeRecord: asset,
            });
          } else {
            this.loaded.emit({
              label: `Touch ${
                asset.masterSuiteTouchIndex / 1000 + 1
              } / ${label} ${asset.packageAssetIndex / 1000 + 1}  (${
                asset.label
              })`,
              selectIndex: asset.selectsIndex,
              activeRecord: asset,
            });
          }
        }
      });
    }
    if (isClick) {
      const navigateTmp = document.getElementById('navigate');
      const eCurrentTmp = document.getElementById(this.current);
      if (navigateTmp && eCurrentTmp) {
        if (
          window.innerWidth > 1440 &&
          navigateTmp?.getBoundingClientRect()?.bottom <
            eCurrentTmp?.getBoundingClientRect()?.bottom
        ) {
          navigateTmp.scrollTop +=
            eCurrentTmp?.getBoundingClientRect().bottom -
            navigateTmp?.getBoundingClientRect().bottom;
        } else {
          eCurrentTmp?.scrollIntoView();
        }
      }
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (this.fullscreen) {
      return;
    }
    if (
      (event.key === 'ArrowRight' || event.key === 'ArrowLeft') &&
      !this.isFocusComment &&
      window.innerWidth < 1440
    ) {
      const status = this.handleNextAsset(event.key);
      this.scrollToCurrentLR(event.key, status);
      event.preventDefault();
    }

    if (
      (event.key === 'ArrowUp' || event.key === 'ArrowDown') &&
      !this.isFocusComment &&
      window.innerWidth > 1440
    ) {
      const status = this.handleNextAsset(event.key);
      this.scrollToCurrentUD(event.key, status);
      event.preventDefault();
    }
  }

  scrollToCurrentLR(
    key: 'ArrowRight' | 'ArrowLeft',
    status?: {
      isFirstLeft: boolean;
      isFinalRight: boolean;
    }
  ) {
    const navigateTmp = document.getElementById('navigate');
    const eCurrentTmp = document.getElementById(this.id);
    if (navigateTmp && eCurrentTmp) {
      if (
        (key === 'ArrowLeft' || status?.isFinalRight) &&
        navigateTmp?.getBoundingClientRect().left >
          eCurrentTmp?.getBoundingClientRect().left
      ) {
        navigateTmp.scrollLeft -=
          navigateTmp?.getBoundingClientRect().left -
          eCurrentTmp?.getBoundingClientRect().left;
      }
      if (
        (key === 'ArrowRight' || status?.isFirstLeft) &&
        navigateTmp?.getBoundingClientRect().right <
          eCurrentTmp?.getBoundingClientRect().right
      ) {
        navigateTmp.scrollLeft +=
          eCurrentTmp?.getBoundingClientRect().right -
          navigateTmp?.getBoundingClientRect().right;
      }
    }
  }

  scrollToCurrentUD(
    key: 'ArrowUp' | 'ArrowDown',
    status?: {
      isFirstLeft: boolean;
      isFinalRight: boolean;
    }
  ) {
    const navigateTmp = document.getElementById('navigate');
    const eCurrentTmp = document.getElementById(this.id);
    if (navigateTmp && eCurrentTmp) {
      if (
        (key === 'ArrowUp' || status?.isFinalRight) &&
        navigateTmp?.getBoundingClientRect().top >
          eCurrentTmp?.getBoundingClientRect().top
      ) {
        navigateTmp.scrollTop -=
          navigateTmp?.getBoundingClientRect().top -
          eCurrentTmp?.getBoundingClientRect().top;
      }
      if (
        (key === 'ArrowDown' || status?.isFirstLeft) &&
        navigateTmp?.getBoundingClientRect().bottom <
          eCurrentTmp?.getBoundingClientRect().bottom
      ) {
        navigateTmp.scrollTop +=
          eCurrentTmp?.getBoundingClientRect().bottom -
          navigateTmp?.getBoundingClientRect().bottom;
      }
    }
  }

  handleNextAsset(key: 'ArrowRight' | 'ArrowLeft' | 'ArrowUp' | 'ArrowDown'): {
    isFirstLeft: boolean;
    isFinalRight: boolean;
  } {
    if (this.assetType === EAssetType.POST_ASSETS) {
      let indexCurrent: number = 0;
      for (let j = 0; j < this.listPostAssets.length; j++) {
        if (this.listPostAssets[j].productTouchAssetId === this.current) {
          indexCurrent = j;
        }
      }
      if (key === 'ArrowRight' || key === 'ArrowDown') {
        if (this.listPostAssets[indexCurrent + 1] === undefined) {
          this.onSelect(this.listPostAssets[0], false);
          return {
            isFirstLeft: true,
            isFinalRight: false,
          };
        } else if (this.listPostAssets[indexCurrent + 1].id === null) {
          this.onSelect(this.listPostAssets[0], false);
          return {
            isFirstLeft: true,
            isFinalRight: false,
          };
        } else {
          this.onSelect(this.listPostAssets[indexCurrent + 1], false);
        }
      }

      if (key === 'ArrowLeft' || key === 'ArrowUp') {
        if (this.listPostAssets[indexCurrent - 1] === undefined) {
          let index = this.listPostAssets.length;
          while (index-- && !this.listPostAssets[index].id);
          this.onSelect(this.listPostAssets[index], false);
          return {
            isFirstLeft: false,
            isFinalRight: true,
          };
        } else if (this.listPostAssets[indexCurrent - 1].id === null) {
          let index = this.listPostAssets.length;
          while (index-- && !this.listPostAssets[index].id);
          this.onSelect(this.listPostAssets[index], false);
          return {
            isFirstLeft: true,
            isFinalRight: false,
          };
        } else {
          this.onSelect(this.listPostAssets[indexCurrent - 1], false);
        }
      }
    }
    if (this.assetType === EAssetType.SHOOT_ASSETS) {
      const indexCurrent: number[] = [];
      for (let i = 0; i < this.listTouch.length; i++) {
        for (let j = 0; j < this.listTouch[i].assets.length; j++) {
          if (
            this.listTouch[i].assets[j].productTouchAssetId === this.current
          ) {
            indexCurrent.push(i);
            indexCurrent.push(j);
          }
        }
      }
      if (key === 'ArrowRight' || key === 'ArrowDown') {
        if (
          this.listTouch[indexCurrent[0]].assets[indexCurrent[1] + 1] !==
          undefined
        ) {
          this.onSelect(
            this.listTouch[indexCurrent[0]].assets[indexCurrent[1] + 1],
            false,
            this.listTouch[indexCurrent[0]]
          );
        } else if (indexCurrent[0] < this.listTouch.length - 1) {
          this.onSelect(
            this.listTouch[indexCurrent[0] + 1].assets[0],
            false,
            this.listTouch[indexCurrent[0] + 1]
          );
        } else {
          this.onSelect(this.listTouch[0].assets[0], false, this.listTouch[0]);
          return {
            isFirstLeft: false,
            isFinalRight: true,
          };
        }
      }
      if (key === 'ArrowLeft' || key === 'ArrowUp') {
        if (
          this.listTouch[indexCurrent[0]].assets[indexCurrent[1] - 1] !==
          undefined
        ) {
          this.onSelect(
            this.listTouch[indexCurrent[0]].assets[indexCurrent[1] - 1],
            false,
            this.listTouch[indexCurrent[0]]
          );
        } else if (indexCurrent[0] !== 0) {
          this.onSelect(
            this.listTouch[indexCurrent[0] - 1].assets[
              this.listTouch[indexCurrent[0] - 1].assets.length - 1
            ],
            false,
            this.listTouch[indexCurrent[0] - 1]
          );
        } else {
          const listTouchLength = this.listTouch.length;
          const assetsFinalLength =
            this.listTouch[listTouchLength - 1].assets.length;
          this.onSelect(
            this.listTouch[listTouchLength - 1].assets[assetsFinalLength - 1],
            false,
            this.listTouch[listTouchLength - 1]
          );
          return {
            isFirstLeft: true,
            isFinalRight: false,
          };
        }
      }
    }
    return {
      isFirstLeft: false,
      isFinalRight: false,
    };
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.signalrService.clearData();
  }
}
