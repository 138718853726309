import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  startWith,
  map,
  takeUntil,
  Subject,
  Observable,
  delay,
  interval,
} from 'rxjs';
import { DeleteModalComponent } from 'src/app/components/shared/delete-modal/delete-modal.component';
import { SettingsVariantsFormControl } from 'src/app/models/mastersuites/masterSuiteFormControl';
import {
  IMasterSuite,
  ISettingsVariant,
} from 'src/app/models/mastersuites/mastersuite';
import {
  SettingsVariantNew,
  SettingsVariantTouchNew,
  SettingsVariantTouchAssetNew,
} from 'src/app/models/mastersuites/mastersuiteNew';
import { Setting } from 'src/app/models/settings/setting';
import { ValidationErrors } from 'src/app/models/validationErrors';
import { MasterSuitesService } from 'src/app/services/mastersuites.service';
import { SettingsService } from 'src/app/services/setting.service';

@Component({
  selector: 'app-settings-variant-content',
  templateUrl: './settings-variant-content.component.html',
  styleUrls: ['./settings-variant-content.component.scss'],
})
export class SettingsVariantContentComponent implements OnInit {
  @Input() masterSuite!: IMasterSuite;
  private destroy$ = new Subject();
  constructor(
    private settingsService: SettingsService,
    private masterSuitesService: MasterSuitesService,
    public _alert: MatSnackBar,
    public dialog: MatDialog
  ) {}
  settingsSubscription: any;
  serverErrorMessage: String = '';
  ngOnInit(): void {
    this.listSettingsVariants();
  }
  listSettingsVariants() {
    console.log(this.masterSuite);
    this.masterSuitesService
      .listSettingsVariants(this.masterSuite.id!)
      .pipe(takeUntil(this.destroy$))
      .subscribe((settingsVariants) => {
        this.settingsVariants = settingsVariants;
        this.settingsVariants.forEach((variant, i) => {
          this.readonly.push(true);
          this.displayed.push(false);
          this.settingsVariantsFormControl.push({
            Name: new FormControl('', [Validators.required]),
            Setting: [],
          });
          variant.settingsVariantTouches.sort(
            (a, b) => a.masterSuiteTouchIndex - b.masterSuiteTouchIndex
          );
          variant.settingsVariantTouches.forEach(() => {
            this.settingsVariantsFormControl[i].Setting.push(
              new FormControl('', [Validators.required])
            );
          });
        });
        this.listSettings();
      });
  }

  errorMessages: ValidationErrors[] = [];
  validatorProperties: string[] = [];
  settingsVariantsFormControl: SettingsVariantsFormControl[] = [];
  settingsVariants: ISettingsVariant[] = [];
  settings: Setting[] = [];
  settingsFilteredOptions: Observable<Setting[]>[][] = [];
  settingsLoaded: boolean = false;
  displayed: boolean[] = [];
  readonly: boolean[] = [];
  _settingFilter(name: string): Setting[] {
    const filterValue = name.toLowerCase();
    return this.settings.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }
  displaySettingFn(settingId: string) {
    if (settingId) {
      return this.settings.find((_) => _.id === settingId)?.name!;
    } else {
      return '';
    }
  }
  parseSettingValue(value: any) {
    const name = typeof value === 'string' ? value : value?.name;
    return name ? this._settingFilter(name as string) : this.settings.slice();
  }
  listSettings() {
    this.settingsSubscription = this.settingsService
      .listSettings(this.masterSuite.organizationId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (settings) => {
          if (settings) {
            this.settings = settings;
            this.settingsVariants.forEach((variant, i) => {
              this.settingsFilteredOptions.push([]);
              variant.settingsVariantTouches.forEach((variantTouch, j) => {
                this.settingsFilteredOptions[i].push(
                  this.settingsVariantsFormControl[i].Setting[
                    j
                  ].valueChanges.pipe(
                    startWith(''),
                    map((value) => this.parseSettingValue(value))
                  )
                );
              });
            });
            console.log(this.settingsFilteredOptions);
            this.settingsLoaded = true;
          }
        },
        (error) => {
          this.serverErrorMessage =
            'There was an error loading the Users from the server. Please restart the system and try again';
          this._alert.open('Server Error: ' + error.message, 'close', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            panelClass: ['error'],
          });
        }
      );
  }
  addVariant() {
    var variant = new SettingsVariantNew();
    const index = this.settingsVariants.length;
    this.readonly.push(false);
    this.displayed.push(true);
    this.settingsVariantsFormControl.push({
      Name: new FormControl('', [Validators.required]),
      Setting: [],
    });

    this.settingsFilteredOptions.push([]);

    this.masterSuite.masterSuiteTouches.forEach((touch, i) => {
      var variantTouch = new SettingsVariantTouchNew();
      variantTouch.masterSuiteTouchName = touch.name;
      variantTouch.masterSuiteTouchId = touch.id;
      variantTouch.masterSuiteTouchIndex = touch.index;
      this.settingsVariantsFormControl[index].Setting.push(
        new FormControl('', [Validators.required])
      );
      this.settingsFilteredOptions[index].push(
        this.settingsVariantsFormControl[index].Setting[i].valueChanges.pipe(
          startWith(''),
          map((value) => this.parseSettingValue(value))
        )
      );
      variant.settingsVariantTouches.push(variantTouch);
    });
    this.settingsVariants.push(variant);
  }
  removeVariant(variant: ISettingsVariant, index: number, $event: any) {
    $event.stopPropagation();
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      data: {
        header: `Delete Variant: ${variant.name}`,
        message: 'Are you sure you want to delete this variant?',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.masterSuitesService.deleteSettingsVariant(variant).subscribe(
            (data) => {
              this.settingsVariants.splice(index, 1);
              this.settingsVariantsFormControl.splice(index, 1);
              this.settingsFilteredOptions.splice(index, 1);
              this.readonly.splice(index, 1);
              this.successDialog('Successfully Deleted Settings Variant');
            },
            (error) => {
              this._alert.open(
                'Validation Failure: ' +
                  error.error.validationErrors[0].message,
                'close',
                {
                  horizontalPosition: 'center',
                  verticalPosition: 'bottom',
                  duration: 10000,
                  panelClass: ['error'],
                }
              );
            }
          );
        }
      });
  }
  editVariant(variant: ISettingsVariant, index: number) {
    this.displayed[index] = true;
    if (
      variant.settingsVariantTouches.length !=
      this.masterSuite.masterSuiteTouches.length
    ) {
      this.masterSuite.masterSuiteTouches.forEach((touch, i) => {
        var found = variant.settingsVariantTouches.find(
          (svt) => svt.masterSuiteTouchId == touch.id
        );
        if (!found) {
          var variantTouch = new SettingsVariantTouchNew();
          variantTouch.masterSuiteTouchName = touch.name;
          variantTouch.masterSuiteTouchIndex = touch.index;
          variantTouch.masterSuiteTouchId = touch.id;
          this.settingsVariantsFormControl[index].Setting.splice(
            touch.index / 1000,
            0,
            new FormControl('', [Validators.required])
          );
          this.settingsFilteredOptions[index].splice(
            touch.index / 1000,
            0,
            this.settingsVariantsFormControl[index].Setting[
              i
            ].valueChanges.pipe(
              startWith(''),
              map((value) => this.parseSettingValue(value))
            )
          );
          variant.settingsVariantTouches.splice(
            touch.index / 1000,
            0,
            variantTouch
          );
        }
      });
    }

    setTimeout(() => {
      this.readonly[index] = false;
    }, 100);
  }
  closeVariant(index: number) {
    this.displayed[index] = false;
    this.readonly[index] = true;
  }
  saveVariant(variant: ISettingsVariant, index: number) {
    if (variant.id == null) {
      variant.masterSuiteId = this.masterSuite.id!;
      this.masterSuitesService.createSettingsVariant(variant).subscribe(
        (data) => {
          console.log('Create Settings Variant: ', data);
          this.successDialog('Successfully Created Settings Variant');
          this.readonly[index] = true;
          variant.id = data.id;
          variant.version = data.version;
          variant.settingsVariantTouches.forEach((svt) => {
            var newSvt = data.settingsVariantTouches.find(
              (n) => n.masterSuiteTouchId == svt.masterSuiteTouchId
            );
            svt.id = newSvt!.id;
          });
        },
        (error) => {
          this.validateResult(error, index);
        }
      );
    } else {
      this.masterSuitesService.updateSettingsVariant(variant).subscribe(
        (data: ISettingsVariant) => {
          console.log('Update Settings Variant: ', data);
          this.successDialog('Successfully Updated Settings Variant');
          this.readonly[index] = true;
          this.displayed[index] = false;
          variant.version = data.version;
          variant.settingsVariantTouches.forEach((svt) => {
            svt.version = data.settingsVariantTouches.find(
              (x) => x.id == svt.id
            )!.version;
          });
        },
        (error) => {
          this.validateResult(error, index);
        }
      );
    }
  }
  cancelVariantCreate(variant: ISettingsVariant, index: number, $event: any) {
    if (variant.id == null) {
      $event.stopPropagation();
      this.settingsVariants.splice(index, 1);
      this.settingsVariantsFormControl.splice(index, 1);
      this.settingsFilteredOptions.splice(index, 1);
      this.readonly.splice(index, 1);
    } else {
      this.readonly[index] = true;
      variant.settingsVariantTouches.forEach((svt, i) => {
        if (svt.id == null) {
          variant.settingsVariantTouches.splice(i, 1);
          this.settingsVariantsFormControl[index].Setting.splice(i, 1);
          this.settingsFilteredOptions[index].splice(i, 1);
        }
      });
    }
  }

  validateResult(error: any, index: number) {
    if (error.status === 422) {
      this.errorMessages = [];
      this.validatorProperties = [];
      error.error.validationErrors.forEach((error: ValidationErrors) => {
        error.property = 'MasterSuiteVariants[' + index + '].' + error.property;
        this.errorMessages.push(error);
        this.validatorProperties.push(error.property);
      });
    }

    this.settingsVariantsFormControl.forEach((form, i) => {
      form.Name.markAllAsTouched();
      if (
        this.errorMessages.find(
          (error) => error.property == 'MasterSuiteVariants[' + i + '].Name'
        )
      ) {
        form.Name.setErrors({ serverErrors: 'validation errors' });
      }
      form.Setting.forEach((settingForm, j) => {
        settingForm.markAllAsTouched();
        if (
          this.errorMessages.find(
            (error) =>
              error.property ==
              'MasterSuiteVariants[' +
                i +
                '].SettingsVariantTouch[' +
                j +
                '].Setting'
          )
        ) {
          settingForm.setErrors({ serverErrors: 'validation errors' });
        }
      });
    });
  }

  validationMessage(property: string) {
    return this.errorMessages.find((error) => error.property == property)
      ?.message;
  }

  successDialog(message: string) {
    this._alert.open(message, 'close', {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      duration: 2000,
      panelClass: ['success'],
    });
  }

  viewSetting(id: string) {
    var url = `/settings/${id}/update`;
    window.open(url, '_blank');
  }
}
