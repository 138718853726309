export const ShutterValues =
[
    "10\"",
    //"10\" (1/3)",
    "8\"",
    //"6\" (1/3)",
    "6\"",
    "5\"",
    "4\"",
    "3\"2",
    //"3\"",
    "2\"5",
    "2\"",
    "1\"6",
    //"1\"5",
    "1\"3",
    "1\"",
    "0\"8",
    //"0\"7",
    "0\"6",
    "0\"5",
    "0\"4",
    "0\"3",
    //"0\"3 (1/3)",
    "/1/4",
    "1/5",
    "1/6",
    //"1/6 (1/3)",
    "1/8",
    //"1/10 (1/3)",
    "1/10",
    "1/13",
    "1/15",
    //"1/20 (1/3)",
    "1/20",
    "1/25",
    "1/30",
    "1/40",
    //"1/45",
    "1/50",
    "1/60",
    "1/80",
    //"1/90",
    "1/100",
    "1/125",
    "1/160",
    //"1/180"
]