<div class="content">
  <div class="content-header">
    <div class="title">Master Suite Update</div>
    <span class="spacer"></span>

    <h4 class="router-link" (click)="backClicked()">Back to Master Suites</h4>
    <button
      [disabled]="!readonly || tabIndex != 0"
      mat-icon-button
      matTooltip="Edit"
      [color]="readonly && tabIndex == 0 ? 'success' : 'disabled'"
      class="mat-elevation-z0"
      (click)="edit()"
    >
      <mat-icon>edit</mat-icon>
    </button>
    <!-- <mat-form-field
      *ngIf="!!masterSuite"
      appearance="outline"
      color="primary"
      style="width: 20%"
    >
      <mat-label>Enhancement Guide</mat-label>
      <input matInput [(ngModel)]="masterSuite.enhancementLink" />
      <mat-hint *ngIf="validatorProperties.includes('Name')"> </mat-hint>
    </mat-form-field> -->
  </div>
  <ng-container *ngIf="!!masterSuite">
    <app-mastersuite-content
      [masterSuite]="masterSuite"
      [masterSuiteFormControl]="masterSuiteFormControl"
      [tabIndex]="tabIndex"
      [errorMessages]="errorMessages"
      [validatorProperties]="validatorProperties"
      [readonly]="readonly"
      (tabChanged)="tabChanged($event)"
    >
    </app-mastersuite-content>
  </ng-container>
  <div class="content-actions" *ngIf="!readonly">
    <span class="spacer"></span>
    <button mat-stroked-button (click)="cancel()">cancel</button>
    <button
      *ngIf="tabIndex === 0"
      mat-button
      color="buttonSuccess"
      style="margin-left: 20px"
      (click)="updateMasterSuite()"
    >
      Update
    </button>
  </div>
</div>
