import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAssetMetaData } from '../models/assetMetaData';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IProduct } from '../models/products/product';
import { IAssetLabel } from '../models/assetLabel';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class AssetsService {
  constructor(private http: HttpClient) {}

  getAssetMetaData(
    assetId: string,
    assetType: string
  ): Observable<IAssetMetaData> {
    var url = `${environment.apiBase}/${assetType}/${assetId}/meta-data`;
    return this.http.get<IAssetMetaData>(url, httpOptions);
  }

  generateAssetMetaData(assetId: string, assetType: string): Observable<any> {
    var url = `${environment.apiBase}/${assetType}/${assetId}/meta-data/generate`;
    return this.http.patch(url, httpOptions);
  }

  pinAsset(id: string, entity: string, state: boolean) {
    const url = `${environment.apiBase}/${entity}/${id}/pin/${state}`;
    return this.http.patch(url, httpOptions);
  }

  deleteAsset(assetId: string, assetType: string): Observable<any> {
    var url = `${environment.apiBase}/${assetType}/${assetId}`;
    return this.http.delete(url, httpOptions);
  }

  parseAssetLabel(assetId: string, assetType: string): Observable<any> {
    var url = `${environment.apiBase}/${assetType}/${assetId}/asset-label`;
    return this.http.post(url, httpOptions);
  }

  getAssetLabel(assetId: string, assetType: string): Observable<IAssetLabel[]> {
    var url = `${environment.apiBase}/${assetType}/${assetId}/asset-label`;
    return this.http.get<IAssetLabel[]>(url, httpOptions);
  }

  extractLabelBoundingBox(assetId: string, assetType: string): Observable<any> {
    var url = `${environment.apiBase}/${assetType}/${assetId}/extract-content`;
    return this.http.get(url, httpOptions);
  }
}
