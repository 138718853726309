import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { IOutputsCollection } from 'src/app/models/outputsCollection';
import { IProduct, EProgressState } from 'src/app/models/products/product';
import { AuthService } from 'src/app/services/auth.service';
import { OutputsCollectionsService } from 'src/app/services/outputs-collections.service';

@Component({
  selector: 'app-output-collections-product-list',
  templateUrl: './output-collections-product-list.component.html',
  styleUrl: './output-collections-product-list.component.scss',
})
export class OutputCollectionsProductListComponent {
  constructor(
    private route: ActivatedRoute,
    private outputsCollectionsService: OutputsCollectionsService
  ) {
    const outputsCollectionId = this.route.snapshot.paramMap.get('id');
    this.outputsCollectionsService
      .getOutputsCollection(outputsCollectionId!)
      .subscribe((res) => {
        this.outputsCollection = res;
        this.outputsCollectionsService
          .getOutputsCollectionProducts(outputsCollectionId!)
          .subscribe((products) => {
            this.products = this.getProductStates(products);
            this.loading = false;
          });
      });
  }

  outputsCollection!: IOutputsCollection;
  products: IProduct[] = [];
  searchFilter: Subject<string> = new Subject<string>();
  loading: boolean = true;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;

  public applyFilter(event: Event) {
    let filterValue = (event.target as HTMLInputElement).value;
    filterValue = filterValue.trim().toUpperCase();
    this.searchFilter.next(filterValue);
  }

  getProductStates(products: IProduct[]) {
    return products.map((p: IProduct) => {
      p.states = {
        receivalState:
          p.receivedPoorCount > 0
            ? [EProgressState.ERROR]
            : p.receivedOkayCount > 0
            ? [EProgressState.ACTIVE]
            : p.receivedGoodCount > 0
            ? [EProgressState.COMPLETE]
            : [EProgressState.PENDING],
        masterSuiteState:
          p.masterSuiteId != null && p.settingsVariantId != null
            ? [EProgressState.COMPLETE]
            : p.masterSuiteId != null && p.settingsVariantId == null
            ? [EProgressState.ACTIVE]
            : [EProgressState.PENDING],
        ingestionState:
          p.ingestedTouchCount == p.expectedTouchCount &&
          p.expectedTouchCount != 0
            ? [EProgressState.COMPLETE]
            : p.ingestedTouchCount > 0
            ? [EProgressState.ACTIVE]
            : [EProgressState.PENDING],
        productionState:
          p.shotTouchCount == p.expectedTouchCount && p.expectedTouchCount != 0
            ? [EProgressState.COMPLETE]
            : p.shotTouchCount > 0
            ? [EProgressState.ACTIVE]
            : [EProgressState.PENDING],
        productionReviewState:
          p.approvedShootAssetCount == p.expectedShootAssetCount &&
          p.expectedShootAssetCount != 0
            ? [EProgressState.COMPLETE]
            : p.rejectedShootAssetCount > 0
            ? [EProgressState.ERROR]
            : p.approvedShootAssetCount > 0
            ? [EProgressState.ACTIVE]
            : [EProgressState.PENDING],
        enhancementState:
          p.postAssetCount == p.expectedPostAssetCount &&
          p.expectedPostAssetCount > 0 &&
          p.internallyRejectedPostAssetCount == 0
            ? [EProgressState.COMPLETE]
            : p.postAssetCount > 0 && p.internallyRejectedPostAssetCount == 0
            ? [EProgressState.ACTIVE]
            : p.internallyRejectedPostAssetCount > 0
            ? [EProgressState.ERROR]
            : [EProgressState.PENDING],
        reviewState:
          p.approvedPostAssetCount == p.expectedPostAssetCount &&
          p.expectedPostAssetCount > 0
            ? [EProgressState.COMPLETE]
            : p.rejectedPostAssetCount > 0
            ? [EProgressState.ERROR]
            : p.approvedPostAssetCount > 0
            ? [EProgressState.ACTIVE]
            : [EProgressState.PENDING],
        outputsState: p.releasedInCollection
          ? [EProgressState.COMPLETE]
          : p.approvedOutputAssetCount == p.expectedOutputAssetCount &&
            p.expectedOutputAssetCount > 0 &&
            !p.releasedInCollection
          ? [EProgressState.INFO]
          : p.rejectedOutputAssetCount > 0
          ? [EProgressState.ERROR]
          : p.approvedOutputAssetCount > 0 && p.expectedOutputAssetCount > 0
          ? [EProgressState.ACTIVE]
          : [EProgressState.PENDING],
        dispatchState:
          p.dispatchedCount > 0
            ? [EProgressState.COMPLETE]
            : [EProgressState.PENDING],
      };
      return { ...p, loadedImage: false };
    });
  }
}
