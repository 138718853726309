import { Pipe, PipeTransform } from '@angular/core';
import { IPreference } from '../models/users/userProfile';
import { defaultFormat } from '../utils/dateFormat';

import {
  ZonedDateTime,
  ZoneId,
  DateTimeFormatter,
  Duration,
} from '@js-joda/core';
import '@js-joda/timezone';

import { Locale } from '@js-joda/locale_en';
import { Observable, map, timer } from 'rxjs';

@Pipe({
  name: 'dateAgo',
  pure: true,
})
export class DateAgoPipe implements PipeTransform {
  transform(
    value: any,
    preference: IPreference,
    isToolTip?: boolean
  ): Observable<string> {
    return timer(0, 1000).pipe(
      map(() => {
        if (value && preference.timezone.length > 0) {
          let formatType = preference.asAgo
            ? defaultFormat
            : preference.dateTimeFormat;
          formatType = formatType.replaceAll(
            'h:mm',
            preference.is24H ? 'HH:mm' : 'hh:mm'
          );
          if (preference.is24H) {
            formatType = formatType.replaceAll(' a', '');
          }

          let isAsAgo = preference.asAgo;
          isAsAgo = isToolTip ? !isAsAgo : isAsAgo;

          if (isAsAgo) {
            const nowZDT = ZonedDateTime.now();
            const timeValue = ZonedDateTime.parse(value).withZoneSameInstant(
              ZoneId.of(preference.timezone)
            );
            const seconds = Duration.between(timeValue, nowZDT).seconds();
            if (seconds < 29)
              // less than 30 seconds ago will show as 'Just now'
              return 'Just now';
            const intervals = {
              year: 31536000,
              month: 2592000,
              week: 604800,
              day: 86400,
              hour: 3600,
              minute: 60,
              second: 1,
            };
            let counter;
            for (const i in intervals) {
              counter = Math.floor(
                seconds / intervals[i as keyof typeof intervals]
              );
              if (counter > 0) {
                return `${counter} ${i}${counter > 1 ? 's' : ''} ago`;
              }
            }
          } else {
            return ZonedDateTime.parse(value)
              .withZoneSameInstant(ZoneId.of(preference.timezone))
              .format(
                DateTimeFormatter.ofPattern(formatType).withLocale(
                  Locale.ENGLISH
                )
              );
          }
        }
        return value;
      })
    );
  }
}
